import React from 'react'
import { Box, Stack, Typography } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const SeasonalSalesTrends = () => {
    const series = [
        {
            name: 'Seasonal Sales',
            type: 'area',
            data: [400, 420, 680, 160, 200, 380, 450, 180, 230, 530, 500, 600],
        }
    ];
    
    const options = {
        chart: {
            height: 350,
            type: 'line',
            background: 'transparent',
            toolbar: {
                show: false,
            },
        },
        colors: ['#5F55EE'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        fill: {
          type: 'gradient',
          opacity: [0.45, 1],
          gradient: {
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: [0.38, 1],
            opacityTo: [0.05, 1],
            shade: 'dark',
            type: 'vertical',
            stops: [0, 100],
          },
        },
        xaxis: {
            categories: ['', 'Winter', '', '', 'Spring', '', '', 'Summer', '', '', 'Fall', ''],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        yaxis: {
            min: 0, 
            max: 800,
            tickAmount: 8,
            labels: {
                formatter: (val) => `${val}K`,
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        tooltip: {
            enabled: false,
            shared: true,
            intersect: false,
            y: {
                formatter: (val) => `${val}K`,
            },
        },
        theme: {
            mode: 'dark',
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        markers: {
          size: 2,
          colors: undefined,
          strokeWidth: 0,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "square",
          radius: 5,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
    };
    
    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            border: '1px solid',
            borderColor: (theme) => theme.palette.color.strockborder,
            p: '20px',
            borderRadius: '12px',
            my: 2,
        }}>
            <Stack direction="row" pl={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Seasonal Sales Trends
                </Typography>
                <Stack direction="row" gap={2}>
                    <RoundFormSelect
                        options={[{ value: "seasonal", label: "Seasonal" }]}
                        defaultValue="seasonal"
                    />
                </Stack>
            </Stack>
            <ReactApexChart options={options} series={series} type="line" height={350} />
        </Box>
    );
}

export default SeasonalSalesTrends;
