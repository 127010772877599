import { useTheme } from "@mui/material";
import * as React from "react";

const DemographicIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12.0001 18.6391C15.6667 18.6391 18.6391 15.6667 18.6391 12.0001C18.6391 8.33347 15.6667 5.36108 12.0001 5.36108C8.33347 5.36108 5.36108 8.33347 5.36108 12.0001C5.36108 15.6667 8.33347 18.6391 12.0001 18.6391Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12 2V5.361"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M19.6604 5.57202L17.0857 7.73244"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12 18.6389V21.9999"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M11.9999 11.3704C12.7983 11.3704 13.4456 10.7231 13.4456 9.92469C13.4456 9.12626 12.7983 8.479 11.9999 8.479C11.2015 8.479 10.5542 9.12626 10.5542 9.92469C10.5542 10.7231 11.2015 11.3704 11.9999 11.3704Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.70793 15.5209H14.2919C14.7998 15.5209 15.1911 15.0841 14.9539 14.635C14.605 13.9743 13.8095 13.1855 11.9999 13.1855C10.1904 13.1855 9.39485 13.9743 9.04591 14.635C8.8087 15.0841 9.19998 15.5209 9.70793 15.5209Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DemographicIcon;