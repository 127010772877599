import { useTheme } from "@mui/material";
import * as React from "react";

const OverviewIcon = ({ fill, ...props }) => {
  const theme = useTheme();
  const fillColor = fill || theme.palette.color.secondary;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3983_177067)">
        <mask
          id="mask0_3983_177067"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={24}
          height={24}
        >
          <path
            d="M0.75 23.25V0.75H23.25V23.25H0.75Z"
            fill="white"
            stroke="white"
            strokeWidth="1.5"
          />
        </mask>
        <g mask="url(#mask0_3983_177067)">
          <path
            d="M17.25 20H6.75C5.2334 20 4 18.7666 4 17.25V6.75C4 5.2334 5.2334 4 6.75 4H17.25C18.7666 4 20 5.2334 20 6.75V17.25C20 18.7666 18.7666 20 17.25 20ZM6.75 5.5C6.06055 5.5 5.5 6.06055 5.5 6.75V17.25C5.5 17.9395 6.06055 18.5 6.75 18.5H17.25C17.9395 18.5 18.5 17.9395 18.5 17.25V6.75C18.5 6.06055 17.9395 5.5 17.25 5.5H6.75Z"
            fill={fillColor}
          />
          <path
            d="M8.75 16C8.33594 16 8 15.6641 8 15.25V9.75C8 9.33594 8.33594 9 8.75 9C9.16406 9 9.5 9.33594 9.5 9.75V15.25C9.5 15.6641 9.16406 16 8.75 16Z"
            fill={fillColor}
          />
          <path
            d="M15.25 16C14.8359 16 14.5 15.6641 14.5 15.25V9.75C14.5 9.33594 14.8359 9 15.25 9C15.6641 9 16 9.33594 16 9.75V15.25C16 15.6641 15.6641 16 15.25 16Z"
            fill={fillColor}
          />
          <path
            d="M12 16C11.5859 16 11.25 15.6641 11.25 15.25V12.75C11.25 12.3359 11.5859 12 12 12C12.4141 12 12.75 12.3359 12.75 12.75V15.25C12.75 15.6641 12.4141 16 12 16Z"
            fill={fillColor}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3983_177067">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OverviewIcon;