import React, { useEffect } from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import NavGroup from "./NavGroup";
import { BiPlus } from "react-icons/bi";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { toggleOpen } from "../../../slices/openModalSlice";
import { getChildNavItem } from "../../../utils/getNavData";
import SideBar from "../../Firm/Customers/SingleCustomer/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import NavItem2 from "./NavItem2";
import NavTabs from "./NavTabs";
import NavGroup1 from "./NavGroup1";
import AddCampign from "../../Affiliates/Programs/addCampign";
import HomeIcon from "../../Icons/Home";
import NavGroup2 from "./NavGroup2";

export default function SideBar1() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { navItem: parentItem, childItem } = getChildNavItem(pathname);

  const [navItem, setNavItem] = React.useState(
    childItem?.children ? childItem : parentItem
  );

  useEffect(() => {
    setNavItem(childItem?.children ? childItem : parentItem);
  }, [childItem, parentItem]);

  const navigate = useNavigate();

  return pathname.startsWith("/firm/customer/") ? (
    <SideBar />
  ) : (
    <Box
      sx={{
        display: { xs: "none", lg: "flex" },
        justifyContent: "space-between",
        alignItems: "start",
        flexGrow: "0",
        flexShrink: "0",
        flexDirection: "column",
        gap: "0",
        overflow: "visible",
        p: "0",
        width: "min-content",
        backgroundColor: (theme) => theme.palette.color.bg2,
        position: "sticky",
        top: 0,
      }}
    >
      {childItem?.backurl && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1",
            p: "16px",
            pb: "8px",
          }}
          onClick={() => navigate(childItem.backurl)}
        >
          <ChevronLeft
            sx={{
              fontSize: "16px",
              color: (theme) => theme.palette.color.active,
            }}
          />
          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
              color: (theme) => theme.palette.color.active,
            }}
          >
            Back to page
          </Typography>
        </Box>
      )}
      {navItem?.children && !childItem?.noSidebar && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // height: "100%",
            // minHeight: "100vh",
            // overflowY: "auto",

            py: 2,
            color: (theme) => theme.palette.darktext2,
            backgroundColor: (theme) => theme.palette.color,
            borderLeft: (theme) => `1px solid ${theme.palette.color.border}`,
            width: "275px",
          }}
        >
          <Box
            sx={{
              gap: "12px",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "100%",
            }}
          >
            {!childItem?.children && (
              <Stack
                direction="row"
                spacing={0.4}
                alignItems="center"
                sx={{
                  pl: "12px",
                  pr: "6px",
                  position: "sticky",
                  top: 0,
                  background: (theme) => theme.palette.color.bg2,
                  zIndex: 1,
                  overflow: "hidden",
                }}
              >
                {/* <Typography variant="body3">Home</Typography> */}
                <Box sx={{ flexShrink: 0 }}>
                  <HomeIcon />
                </Box>
                <ChevronRight
                  sx={{
                    fontSize: "16px",
                    color: (theme) => theme.palette.color.secondary,
                  }}
                />
                <Typography
                  variant="body3"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {parentItem?.title}
                </Typography>
                <ChevronRight
                  sx={{
                    fontSize: "16px",
                    color: (theme) => theme.palette.color.secondary,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {childItem?.title}
                </Typography>
              </Stack>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2",
                // overflowY: "auto",
                // height: "100%",
              }}
            >
              <div>
                {navItem?.tabs ? (
                  <NavTabs navItem={navItem} />
                ) : navItem.type2 ? (
                  <NavGroup2 navItem={navItem} />
                ) : (
                  navItem?.children.map((child, index) =>
                    child.children ? (
                      navItem.nestedChildren ? (
                        <NavGroup1 key={child.title} navItem={child} />
                      ) : (
                        <NavGroup key={child?.title} navItem={child} />
                      )
                    ) : (
                      <Box sx={{ px: "12px" }}>
                        <NavItem2 key={index} item={child} />
                      </Box>
                    )
                  )
                )}
              </div>
              {childItem?.children3 && (
                <Stack spacing={1} sx={{ p: "12px" }}>
                  {childItem.children3.map((child, index) => (
                    <Stack spacing={0.5}>
                      {child.map((c) =>
                        c.children ? (
                          c.tabs ? (
                            <NavTabs key={c?.title} navItem={c} />
                          ) : (
                            <NavGroup key={c?.title} navItem={c} />
                          )
                        ) : (
                          <Box sx={{ px: "12px" }}>
                            <NavItem2 key={index} item={c} />
                          </Box>
                        )
                      )}
                      <Divider sx={{ my: "8px !important" }} />
                    </Stack>
                  ))}
                </Stack>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {/* <AddCampign /> */}
      <Stack
        sx={{
          pb: "12px",
        }}
      >
        {childItem?.button && (
          <Button
            variant="contained"
            sx={{
              mx: 2,
              fontSize: "13px",
              background: (theme) => theme.palette.color.borderpure,
              color: (theme) => theme.palette.color.primary,
              borderRadius: "18px",
              width: "230px",
              ":hover": {
                background: (theme) => theme.palette.color.borderpure,
                color: (theme) => theme.palette.color.primary,
              },
            }}
            onClick={() => dispatch(toggleOpen())}
            startIcon={
              <BiPlus
                size={18}
                sx={{ color: (theme) => theme.palette.lightText }}
              />
            }
          >
            {childItem.button}
          </Button>
        )}
      </Stack>
    </Box>
  );
}
