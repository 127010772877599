import React from "react";
import { Box, Card, InputBase, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import ClearIcon from "../Icons/Clear";
import StatisticsIcon from "../Icons/Statistics";

const Input = styled(InputBase)(({ theme }) => ({
  padding: "6px 12px",
  background: "transparent",
  border: "none",
  outline: "none",
  color: theme.palette.color.primary,
  fontSize: "13px",
  "&::placeholder": {
    fontSize: "13px",
    color: theme.palette.color.secondary,
    fontWeight: "500",
  },
  width: "100%",
}));

const Chat = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        padding: "12px 20px",
        background: theme.palette.color.gray,
        borderRadius: "18px",
        minHeight: isSmall ? "70%" : "582px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          paddingTop: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h3">AI Assistant</Typography>
        <ClearIcon />
      </Box>
      <Box display="flex" gap={1} alignItems="center" mt={isSmall ? 2 : 0}>
        <img src="/logo.avif" alt="logo" width="18px" />
        <Typography variant="h4" sx={{ fontWeight: "500", color: theme.palette.color.secondary }}>
          Ask me anything.
        </Typography>
      </Box>
      <Box
        sx={{
          height: "288px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 3,
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          {Array.from({ length: 2 }).map((_, index) => (
            <Card
              key={index}
              sx={{
                padding: "20px 12px",
                borderRadius: "18px",
                background: theme.palette.color.lightgray,
                display: "flex",
                width: "100%",
                minHeight: "164px",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  height: "30px",
                  width: "30px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "8px",
                  bgcolor: theme.palette.color.active,
                }}
              >
                <StatisticsIcon />
              </Box>

              <Typography variant="body1" sx={{ mt: "10px", fontSize: "16px", fontWeight: "500"}}>Title prompt</Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: theme.palette.color.primary,
                  display: "-webkit-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                Automate trades based on user-defined criteria, using AI algorithms to time trades optimally.
              </Typography>
            </Card>
          ))}
        </Box>
      </Box>


      <Stack direction="row" gap={1}>
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            width: "100%",
            padding: "2px 5px",
            background: theme.palette.color.bg5,
            borderRadius: "30px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Input placeholder="Ex. who is our best trader?" />
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "42px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            background: theme.palette.color.bg5,
            right: "4px",
            padding: "12px",
            cursor: "pointer",
          }}
        >
          <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.19769 3.58194L9.26019 0.818196C10.2187 0.489696 11.1352 1.4062 10.8067 2.3647L8.04294 10.4272C7.68294 11.4772 6.22044 11.5372 5.77569 10.5202L4.44894 7.48945C4.38819 7.3492 4.27569 7.2367 4.13544 7.17595L1.10469 5.8492C0.087686 5.40445 0.147686 3.94194 1.19769 3.58194Z" fill="#5F55EE" />
          </svg>
        </Box>
      </Stack>
    </Box>
  );
};

export default Chat;