import ReactApexChart from "react-apexcharts";
import { Box, Stack, styled, Typography } from "@mui/material";

const TicketsByCategoryChart = () => {
  const ChartContainer = styled(Box)({
    width: "100%",
    height: "100%",
    "& .apexcharts-toolbar": {
      display: "none !important",
    },
    padding: "20px 0px 0px 20px",
  });

  const series = [
    {
      name: "Profit",
      data: [9, 12, 11],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "20%",
        spacing: 10,
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
        // distributed: true,
        // horizontal: true,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#2FE0A2", "#5F55EE"],
    },
    xaxis: {
      categories: ["Technical issues", "Billing", "General Questions"],
      labels: {
        style: {
          colors: ["#8A96A6", "#8A96A6", "#8A96A6"],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      max: 14,
      title: {
        text: "Number of Tickets",
        style: {
          color: "#8A96A6",
        },
      },
      labels: {
        formatter: (val) => `${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    fill: {
      opacity: 1,
      colors: ["#1D232B", "#1D232B"],
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: ["#1D232B"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
  };

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        color: "white",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "0 auto",
        width: "100%",
        mt: 3,
      }}
    >
      <Stack
        direction="row"
        p={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5">Tickets by Category</Typography>
      </Stack>
      <ChartContainer>
        <ReactApexChart
          options={options}
          series={series.map(({ name, data }, index) => ({
            name,
            data,
            type: "column",
            fillColor:
              index === 0
                ? {
                    colors: ["#2FE0A2"], // Gradient color for profit
                    borderColor: "#2FE0A2",
                    backgroundBarColors: ["#2FE0A2"],
                    backgroundBarOpacity: 1,
                  }
                : {
                    colors: ["#5F55EE"], // Gradient color for loss
                    borderColor: "#5F55EE",
                    backgroundBarColors: ["#5F55EE"],
                    backgroundBarOpacity: 1,
                  },
          }))}
          type="bar"
          height={350}
        />
      </ChartContainer>
    </Box>
  );
};

export default TicketsByCategoryChart;
