import { alpha, Box, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RoundFormSelect = ({ options, defaultValue, sx={}, ...props }) => {
    return (
        <Box>
            <Select
                defaultValue={defaultValue}
                {...props}
                sx={{
                    ...sx,
                    borderRadius: "50px",
                    "& .MuiSelect-outlined": {
                        bgcolor: (theme) => theme.palette.color.bg5,
                        color: (theme) => theme.palette.color.secondary,
                        fontSize: "11.5px",
                        padding: "7px 11.8px",
                        border: (theme) =>
                            `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                        borderRadius: "50px",
                        ":hover": {
                            bgcolor: (theme) => theme.palette.color.bg,
                            border: (theme) =>
                                `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                            cursor: "pointer",
                            borderRadius: "50px",
                            margin: "0px !important",
                        },
                        minWidth: "0",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                }}
                IconComponent={() => (
                    <ExpandMoreIcon
                        sx={{
                            color: (theme) => theme.palette.color.secondary,
                            fontSize: "15px",
                            position: "absolute",
                            right: "8px",
                            top: "50%",
                            transform: "translateY(-50%)",
                        }}
                    />
                )}
                inputProps={{
                    sx: {
                        bgcolor: "transparent",
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

export default RoundFormSelect;