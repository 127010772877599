import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Done, Edit } from "@mui/icons-material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import CustomTable from "../../../components/Firm/Orders/Table";
import { useDispatch } from "react-redux";
import { toggleOpen } from "../../../slices/openModalSlice";
import dayjs from "dayjs";
import SearchInput from "../../../components/ui/NewSearch";
import CalendarPopup from "../../../components/ui/CalendarPopup";
import DisplayColumns from "../../../components/ui/DisplayColumns";
import PlusIcon from "../../../components/Icons/Plus";
import VisibilityMenu from "../../../components/Firm/Docs/VisibilityMenu";
import PublishedMenu from "../../../components/Firm/Docs/publishedMenu";
import AddAnnouncement from "../../../components/Firm/Content-management/addAnnouncement";
import {
  useChangeAnnouncementAudienceMutation,
  useChangeAnnouncementStatusMutation,
  useGetAnnouncementsQuery,
} from "../../../slices/api/announcementSlice";
import moment from "moment";

const publishedTypes = [
  {
    value: "published",
    label: "Published",
    description: "Publish item to now",
    selectedDescription: "Item is live now",
    color: "green",
    icon: Done,
  },
  {
    value: "unpublished",
    label: "Unpublished",
    description: "Hide article from audience",
    selectedDescription: "Hide article from audience",
    color: "red",
  },
  {
    value: "scheduled",
    label: "Scheduled",
    description: "Publish item on a scheduled date",
    selectedDescription: "Item is schedule on " + dayjs().format("DD MMM YYYY"),
    color: "secondary",
    icon: EditCalendarIcon,
  },
  {
    value: "draft",
    label: "Draft",
    description: "Save for further editing",
    selectedDescription: "Item is saved for future editing",
    color: "orange",
    icon: Edit,
  },
];

const Announcement = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const handleChangePage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const dispatch = useDispatch();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [changeVisibility] = useChangeAnnouncementAudienceMutation();
  const [changeStatus] = useChangeAnnouncementStatusMutation();

  const headcells = [
    {
      id: "name",
      label: "Name",
      getCell: (row) => row.title,
      default: true,
    },
    {
      id: "createdAt",
      label: "Created",
      getCell: (row) => moment(row.created_at).format("HH:mm DD MMM, YYYY"),
      default: true,
    },

    {
      id: "scheduled",
      label: "Scheduled",
      getCell: (row) => moment(row.scheduled_at).format("HH:mm DD MMM, YYYY"),
      default: true,
    },
    {
      id: "modified",
      label: "Modified",
      getCell: (row) => moment(row.updated_at).format("HH:mm DD MMM, YYYY"),
      default: true,
    },

    {
      id: "published",
      label: "Published",
      getCell: (row) =>
        row.published_on
          ? moment(row.published_on).format("HH:mm DD MMM, YYYY")
          : "-",
      default: true,
    },
    {
      id: "audience",
      label: "Audience",
      getCell: (row) => {
        return (
          <VisibilityMenu
            value={row.audience}
            changeVisibility={changeVisibility}
            id={row.id}
          />
        );
      },
      default: true,
    },
    {
      id: "status",
      label: "Status",
      getCell: (row) => {
        const type = publishedTypes.find((type) => type.value === row.status);
        return (
          <PublishedMenu
            types={publishedTypes}
            type={type}
            changeStatus={changeStatus}
            id={row.id}
          />
        );
      },
      default: true,
    },
  ];

  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(
    headcells.filter((cell) => cell.default).map((cell) => cell.id)
  );
  const { data } = useGetAnnouncementsQuery();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...(data?.data?.data || [])].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  const filteredData = sortedData.filter((d) => true);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    Math.min(currentPage * itemsPerPage, filteredData.length)
  );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Paper
      sx={{
        p: "24px 12px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          // mt: "24px",
          fontSize: "11.5px",
          color: (theme) => theme.palette.color.secondary,
          px: { xs: "0px", lg: "24px" },
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "flex-start", lg: "space-between" },
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography variant="h4">Announcement</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />

          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
          <DisplayColumns
            columns={columns}
            setColumns={setColumns}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
          <Divider orientation="vertical" flexItem sx={{ px: "6px" }} />
          <IconButton
            sx={{
              p: "4px",
            }}
            onClick={() => dispatch(toggleOpen())}
          >
            <PlusIcon />
          </IconButton>
        </Stack>
      </Box>
      <CustomTable
        headcells={columns.filter((cell) => heads.includes(cell.id))}
        rows={paginatedData}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        fontSize="11.5px"
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        handleChangePage={handleChangePage}
        totalPages={totalPages}
        setItemsPerPage={setItemsPerPage}
      />

      <AddAnnouncement />
    </Paper>
  );
};

export default Announcement;
