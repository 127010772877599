import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import DotVerticalIcon from "../Icons/Dotvertical";
import CopyIcon from "../Icons/Copy";

export default function TableMenu({ items }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div onMouseLeave={handleClose}>
      <IconButton
        sx={{
          p: "6px",
        }}
        onClick={handleClick}
      >
        <DotVerticalIcon fill={theme.palette.color.primary} />
      </IconButton>
      <Box
        sx={{
          opacity: 0,
          display: "none",
          transition: "all 300ms ease-in-out",
          ...(open && {
            opacity: 1,
            display: "block",
          }),
          right: "70px",
          position: "absolute",
          width: "198px",
          background: (theme) => theme.palette.color.bg5,
          zIndex: 10,
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        {items.map((item) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              padding: "12px",
              background: "transparent",
              ":hover": {
                background: (theme) => theme.palette.color.border,
              },
            }}
          >
            {
              <item.icon
                stroke={theme.palette.color[item.color || "secondary"]}
              />
            }
            <Typography
              variant="caption"
              sx={{
                color: (theme) =>
                  theme.palette.color[item.color || "secondary"],
              }}
            >
              {item.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </div>
  );
}
