import { Box, Stack, styled, Typography } from '@mui/material';
import React from 'react'
import RoundFormSelect from '../../ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const ComplianceChart = () => {
    const ChartContainer = styled(Box)({
        width: '100%',
        height: '100%',
        '& .apexcharts-toolbar': {
            display: 'none !important',
        },
    });

    const series = [
        {
            name: 'Account Type A',
            data: [5, 3, 4, 3, 4, 5, 3],
        },
        {
            name: 'Account Type B',
            data: [4, 6, 4, 2, 2, 2, 6],
        },
        {
            name: 'Account Type C',
            data: [2, 3, 4, 6, 3, 4, 5],
        },
    ];

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false,
            },
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                columnWidth: '20%',
                spacing: 10,
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top',
                },
            },
        },
        colors: ['#2FE0A2', '#5F55EE', '#ED55D4'],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: ['Austria', 'Switzerland', 'Denmark', 'Hungary', 'Spain', 'Germany', 'France'],
            labels: {
                style: {
                    colors: ['#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6'],
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            min: 0,
            max: 14,
            title: {
                text: "Compliance Rates",
                style: {
                    color: '#8A96A6',
                },
            },
            labels: {
                formatter: (val) => `${val}`,
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        tooltip: {
            enabled: false,
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 1,
                },
            },
        },
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            border: '1px solid',
            borderColor: (theme) => theme.palette.color.strockborder,
            p: '10px',
            borderRadius: '16px',
        }}>
            <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Compliance with Trading rules and Risk parameters
                </Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account type A</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account type B</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#ED55D4', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account type C</Typography>
                    </Box>
                    <RoundFormSelect
                        options={[{ value: "country", label: "Country" }]}
                        defaultValue="country"
                    />
                </Stack>
            </Stack>
            <ChartContainer>
                <ReactApexChart
                    options={options}
                    series={series.map(({ name, data }, index) => ({
                        name,
                        data,
                        type: 'column'
                    }))}
                    type="bar"
                    height={350}
                />
            </ChartContainer>
        </Box>
    );
}

export default ComplianceChart