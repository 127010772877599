import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  alpha,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import CopyIcon from "../Icons/Copy";

export default function CustomMenu({ options, children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {/* <IoFilterOutline size={16} /> */}
        {children}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        sx={{
          "& .MuiMenu-paper": {
            width: "200px",
            marginRight: "10px",
          },
        }}
      >
        {options.map((option, index) => (
          <>
            {option.map((item) => (
              <MenuItem
                onClick={handleClose}
                sx={{
                  padding: "12px",

                  "&:hover": {
                    background: (theme) => theme.palette.color.bg3,
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: (theme) =>
                      theme.palette.color[item.color || "secondary"],
                    minWidth: "26px !important",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <Typography
                  sx={{
                    color: (theme) =>
                      theme.palette.color[item.color || "secondary"],
                    fontWeight: "400",
                  }}
                  variant="caption_500"
                >
                  {item.label}
                </Typography>
              </MenuItem>
            ))}
            <Divider
              sx={{
                margin: "0px !important",
              }}
            />
          </>
        ))}
      </Menu>
    </div>
  );
}
