import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomTable from '../../Firm/Orders/Table';
import FlagIcon from '../../Icons/Flag';

const PerformanceAndBenchmarksTable = () => {

  const data = [
    {
      status: "inactive",
      stage: "Phase 1",
      accountNumber: "102911050",
      accountType: "100k",
      country: "Switzerland",
      countryFlag: "/images/country-icons/chf.svg",
      age: 20,
    },
    {
      status: "inactive",
      stage: "Phase 2",
      accountNumber: "102911051",
      accountType: "200k",
      country: "USA",
      countryFlag: "/images/country-icons/usd.svg",
      age: 20,
    },
    {
      status: "active",
      stage: "Live accounts",
      accountNumber: "102911052",
      accountType: "400k",
      country: "Austria",
      countryFlag: "/images/country-icons/aus.svg",
      age: 20,
    },
    {
      status: "flagged",
      stage: "Phase 1",
      accountNumber: "102911053",
      accountType: "300k",
      country: "Switzerland",
      countryFlag: "/images/country-icons/chf.svg",
      age: 20,
    },
    {
      status: "inactive",
      stage: "Phase 1",
      accountNumber: "102911054",
      accountType: "400k",
      country: "Spain",
      countryFlag: "/images/country-icons/es.svg",
      age: 20,
    },
  ];

  const headcells = [
    {
      id: "status",
      label: "Status",
      getCell: (row) => (
        row.status === "active" ?
          <Typography sx={{ color: (theme) => theme.palette.color.green, fontSize: "20px" }}>•</Typography> :
          row.status === "inactive" ?
            <Typography sx={{ color: (theme) => theme.palette.color.red, fontSize: "20px" }}>•</Typography> :
            <Stack direction="row" alignItems="center" spacing={0.5} sx={{ fontSize: "12px"}} >
              <FlagIcon stroke={'#F55F82'}/>
              <Typography sx={{ color: (theme) => theme.palette.color.red, fontSize: "12px" }}>
                Flagged
              </Typography>
            </Stack>
      ),
    },
    {
      id: "stage",
      label: "Stage",
      getCell: (row) => (
        <Typography sx={{ color: row.stage === "Live accounts" ? (theme) => theme.palette.color.green : "inherit" }}>
          {row.stage}
        </Typography>
      ),
    },
    {
      id: "accountNumber",
      label: "Account ID",
      getCell: (row) => `{{ Account ID }}`,
    },
    {
      id: "accountType",
      label: "Account Type",
      getCell: (row) => `{{ account type }}`,
    },
    {
      id: "country",
      label: "Country",
      getCell: (row) => (
        <Stack direction="row" alignItems="center">
          <Avatar src={row.countryFlag} sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography>{row.country}</Typography>
        </Stack>
      ),
    },
    {
      id: "age",
      label: "Age",
      getCell: (row) => row.age,
    },
  ];

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        p: '10px',
        borderRadius: '16px',
        mt: 3,
      }}
    >
      <Box sx={{ overflowX: "auto", width: "100%" }}>
        <CustomTable
          headcells={headcells}
          rows={data}
        />
      </Box>
    </Box>
  );
};

export default PerformanceAndBenchmarksTable;
