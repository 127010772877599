import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import LogIcon from "../../../Icons/Log";

const details = [
  {
    id: "11.504.150.14",
    address: "Amsterdam, The Netherlands",
    time: "11:05 24 Apr",
  },
  {
    id: "11.504.150.14",
    address: "Utrecht, The Netherlands",
    time: "11:05 24 Apr",
  },
  {
    id: "11.504.150.14",
    address: "Den Haag, The Netherlands",
    time: "11:05 24 Apr",
  },
  { id: "11.504.150.14", address: "Limassol, Cyprus", time: "11:05 24 Apr" },
  { id: "5.1505.13.12", address: "Limassol, Cyprus", time: "11:05 24 Apr" },
];

const BillDetails1 = () => {
  return (
    <Stack spacing={3} sx={{ padding: "24px" }}>
      <Typography variant="h6" sx={{ px: "24px" }}>
        Bill Details
      </Typography>
      <Stack
        spacing={2}
        sx={{
          mt: "24px",
          padding: "24px",
          background: (theme) => theme.palette.color.borderpure,
          borderRadius: "24px",
        }}
      >
        {details.map((detail) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <LogIcon />
            <Typography
              variant="caption"
              sx={{
                fontWeight: "400",
                "& span": {
                  color: (theme) => theme.palette.color.active,
                  fontWeight: "500",
                },
                "& span:nth-of-type(3)": {
                  color: (theme) => theme.palette.color.primary,
                  fontWeight: "400",
                },
              }}
            >
              User logged in with <span>{detail.id}</span> from{" "}
              <span>{detail.address}</span> at <span>{detail.time}</span>
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default BillDetails1;
