import {
  Box,
  Divider,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Close from "@mui/icons-material/Close";
import KeyboardCommandKey from "@mui/icons-material/KeyboardCommandKey";

const items = [
  {
    title: "Navigation",
    shortcuts: [
      { action: "Go to account information", shortcut: "⌥ + Z" },
      { action: "Go to positions", shortcut: "⌥ + P" },
      { action: "Go to charts", shortcut: "⌥ + C" },
      { action: "Go to dashboard", shortcut: "⌥ + D" },
      { action: "Create alert", shortcut: "⌥ + A" },
    ],
  },
  {
    title: "On chart trading",
    shortcuts: [
      { action: "Open position", shortcut: "⌥ + ⌘ + O" },
      { action: "Close all", shortcut: "⌥ + ⌘ + C" },
      { action: "Break even", shortcut: "⌥ + ⌘ + B" },
      { action: "Undo", shortcut: "⌘ + Z" },
    ],
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "623px",
  border: (theme) => `1px solid ${theme.palette.color.border}`,
  background: (theme) => theme.palette.color.bg2,
  borderRadius: "12px",
  overflow: "hidden",
};

export default function KeyBoardShortcuts({ open, setOpen }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper sx={style}>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "24px 12px",
              background: (theme) => theme.palette.color.gray,
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <KeyboardCommandKey
                sx={{ color: (theme) => theme.palette.color.secondary }}
              />
              <Typography variant="h4" sx={{ fontWeight: "500" }}>
                Keyboard Shortcuts
              </Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Stack
            spacing={2}
            sx={{ padding: "24px", maxHeight: "450px", overflowY: "auto" }}
          >
            {items.map((item) => (
              <Stack spacing={2} key={item.title}>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  {item.title}
                </Typography>
                <Stack spacing={1.5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="caption" sx={{ fontWeight: "400" }}>
                      Action
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: "400" }}>
                      Shortcut
                    </Typography>
                  </Box>
                  <Divider />
                  {item.shortcuts.map((shortcut, index) => (
                    <Stack spacing={2} key={shortcut.action + index}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "400" }}
                        >
                          {shortcut.action}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                          }}
                        >
                          {shortcut.shortcut.split(" + ").map((key) => (
                            <Box
                              sx={{
                                background: (theme) =>
                                  theme.palette.color.lightgray,
                                width: "24px",
                                height: "24px",
                                borderRadius: "4px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="caption_500">
                                {key}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      <Divider />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Box
            sx={{
              background:
                "linear-gradient(rgba(12, 13, 15, 0) 0%, rgb(13, 14, 16) 100%)",
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "50px",
            }}
          />
        </Box>
      </Paper>
    </Modal>
  );
}
