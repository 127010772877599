import {
  Box,
  Card,
  Paper,
  Stack,
  Tooltip,
  Typography,
  Grid,
  useMediaQuery,
  alpha,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import React, { useState } from "react";
import CustomTable from "../../components/Firm/Orders/Table";
import { PiIntersectThree } from "react-icons/pi";

import { useDispatch } from "react-redux";
import { toggleOpen } from "../../slices/openModalSlice";
import SmallSearchBar from "../../components/Affiliates/smallSearchBar";
import ExportSection from "../../components/ui/ExportSection";
import CalendarPopup from "../../components/ui/CalendarPopup";
import DisplayColumns from "../../components/ui/DisplayColumns";
import FilterPopup from "../../components/ui/FilterPopup";
import dayjs from "dayjs";
import ReviewFlaggedTraders from "../../components/Trading-accounts/ReviewFlaggedTraders";

const Items = [
  {
    title: "Total Accounts",
    tooltip: "Active accounts that are doing a evaluation, funded are on trial",
    value: "1841",
    key: "active",
  },
  {
    title: "Total active accounts",
    tooltip: "All active accounts",
    value: "340",
    key: "inactive",
  },
  {
    title: "Total flagged",
    tooltip: "Total flagged",
    value: "120",
    key: "inactive",
  },
];

const statutes = [
  { value: "ongoing", label: "Ongoing", color: "secondary" },
  { value: "passed", label: "Passed", color: "green" },
  { value: "breached", label: "Breached", color: "red" },
];

const headcells = [
  {
    id: "status",
    label: "Status",
    getCell: (row) => {
      const status = statutes.find((statute) => statute.value === row.status);
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            py: "8px",
            borderRadius: "20px",
            width: "fit-content",
          }}
        >
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              background: (theme) => theme.palette.color[status.color],
            }}
          ></Box>
          <Typography
            sx={{
              fontSize: "11.5px",
              color: (theme) => theme.palette.color[status.color],
              fontWeight: 600,
            }}
          >
            {status.label}
          </Typography>
        </Stack>
      );
    },
  },
  {
    id: "phase",
    label: "Phase",
    getCell: (row) => row.phase,
  },
  {
    id: "id",
    label: "Acc. number",
    getCell: (row) => `#${row.id}`,
  },
  {
    id: "id",
    label: "Acc. size",
    getCell: (row) => "400K",
  },
  {
    id: "id",
    label: "Challenge",
    getCell: (row) => "Type",
  },
  {
    id: "platform",
    label: "Platform",
    getCell: (row) => (
      <Stack direction="row" spacing={1} alignItems="center">
        <PiIntersectThree size={16} />
        <p>{row.platform}</p>
      </Stack>
    ),
  },
  {
    id: "balance",
    label: "Balance",
    getCell: (row) => "$105,200.10",
  },
  {
    id: "date",
    label: "Creation date",
    getCell: (row) => row.date,
  },
  {
    id: "date",
    label: "Customer ID",
    getCell: (row) => "#ID",
  },
];

const data = [
  {
    id: "102911050",
    name: "Jens V.",
    platform: "Metatrader",
    phase: "Phase 1",
    program: "25K Evaluation Program",
    date: "25 Apr, 2024 11:05 AM",
    status: "ongoing",
  },
  {
    id: "102911050",
    name: "Jens V.",
    platform: "Metatrader",
    phase: "Phase 1",
    program: "25K Evaluation Program",
    date: "1 Apr, 2024 11:05 AM",
    status: "passed",
  },
  {
    id: "102911050",
    name: "Jens V.",
    platform: "Metatrader",
    phase: "Phase 1",
    program: "25K Evaluation Program",
    date: "5 May, 2024 11:05 AM",
    status: "breached",
  },
  {
    id: "102911050",
    name: "Jens V.",
    platform: "Metatrader",
    phase: "Phase 1",
    program: "25K Evaluation Program",
    date: "1 May, 2024 11:05 AM",
    status: "ongoing",
  },
  {
    id: "102911050",
    name: "Jens V.",
    platform: "Metatrader",
    phase: "Phase 1",
    program: "25K Evaluation Program",
    date: "1 May, 2024 11:05 AM",
    status: "passed",
  },
  {
    id: "102911050",
    name: "Jens V.",
    platform: "Metatrader",
    phase: "Phase 1",
    program: "25K Evaluation Program",
    date: "1 May, 2024 11:05 AM",
    status: "breached",
  },
];

const TradingAccounts = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [status, setStatus] = useState("");
  const [heads, setHeads] = React.useState(
    headcells.filter((cell) => cell.default).map((cell) => cell.id)
  );
  const [selectedColumns, setSelectedColumns] = useState(headcells);
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [rangeValue, setRangeValue] = useState([20, 37]);
  const [selectedCustomerids, setSelectedCustomerids] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedPurchase, setSelectedPurchase] = useState([]);

  const [duration, setDuration] = useState("thisWeek");
  const isLarger = useMediaQuery("(max-width:1320px)");
  const dispatch = useDispatch();
  return (
    <Paper
      sx={{
        p: "24px",
        width: "100%",
      }}
    >
      <Grid container spacing={1}>
        {Items.map((item, index) => (
          <Grid
            item
            xs={index === 2 ? 12 : 6}
            md={4}
            lg={4}
            spacing={2}
            key={index}
          >
            <Card
              // onClick={() => setStatus(item.key)}
              sx={{
                width: "100%",
                border: (theme) =>
                  `1px solid ${
                    item.key === status
                      ? theme.palette.color.green
                      : theme.palette.color.border
                  }`,
                backgroundImage:
                  index === 0 ? 'url("/svg/statsCardBg.svg")' : "none",
                backgroundSize: "cover",
                opacity: 0.8,
                borderRadius: "24px",
                overflow: "hidden",
                padding: "24px",
                cursor: "pointer",
                ...(status &&
                  item.key !== status && {
                    opacity: 0.35,
                  }),
                ...(item.key === status && {
                  transition: "all 0.4s ease-in",
                }),
              }}
            >
              <Stack spacing={5}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    variant="caption"
                    sx={{
                      ...(item.key === status && {
                        color: (theme) => theme.palette.color.green,
                      }),
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Tooltip title={item.tooltip} placement="top">
                    <HelpIcon
                      sx={{
                        fontSize: "15px",
                        cursor: "pointer",
                        color: (theme) =>
                          theme.palette.color[
                            item.key === status ? "green" : "secondary"
                          ],
                      }}
                    />
                  </Tooltip>
                </Stack>

                <Typography
                  variant="h1"
                  sx={{
                    ...(item.key === status && {
                      color: (theme) => theme.palette.color.green,
                    }),
                    fontWeight: "600",
                  }}
                >
                  {item.value}
                </Typography>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Stack
        direction={isSmallScreen ? "column" : "row"}
        justifyContent={isSmallScreen ? "flex-start" : "space-between"}
        alignItems={isSmallScreen ? "flex-start" : "center"}
        gap={2}
        mt={4}
      >
        <Box
          sx={{
            ml: "15px",
            display: "flex",
            alignItems: "start",
          }}
        >
          <Typography variant="subtitle1" mb={2}>
            Accounts
          </Typography>
        </Box>
        <Stack
          direction="row"
          spacing={isSmallScreen ? 1 : 2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            mb: "24px",
            fontSize: "11.5px",
            pr: "24px",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <SmallSearchBar />
          <ExportSection />
          <ExportSection isImport={true} />
          <CalendarPopup
            mainStartDate={startDate}
            mainEndDate={endDate}
            setMainStartDate={setStartDate}
            setMainEndDate={setEndDate}
          />
          <DisplayColumns
            columns={selectedColumns}
            setColumns={setSelectedColumns}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
          <FilterPopup
            rangeFilter={{
              label: "Countries",
              ariaLabel: "Countries",
              value: rangeValue,
              onChange: setRangeValue,
            }}
            accordions={[
              {
                title: "Accounts",
                defaultExpanded: true,
                items: ["0 - 10", "10 - 30", "30 - 50"],
                selectedItems: selectedAccounts,
                onChange: setSelectedAccounts,
              },
              {
                title: "Purchase",
                defaultExpanded: true,
                items: ["0 - 10", "10 - 30", "30 - 50"],
                selectedItems: selectedPurchase,
                onChange: setSelectedPurchase,
              },
            ]}
          />
        </Stack>
      </Stack>

      <CustomTable
        headcells={headcells}
        // rows={data.filter((data) => {
        //   if (!filterData(duration, data.date)) return false;
        //   if (status === "") return true;
        //   if (status === "active") return data.status === "ongoing";
        //   if (status === "inactive")
        //     return data.status === "passed" || data.status === "breached";
        //   return false;
        // })}
        rows={data}
        onRowClick={() => {
          dispatch(toggleOpen());
        }}
      />
      <ReviewFlaggedTraders />
    </Paper>
  );
};

export default TradingAccounts;
