import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import StepperCard from "./StepperCard";
import StepperHeading from "./StepperHeading";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomSwitch from "../../ui/Switch";
import FormInput from "../../ui/FormInput";
import { Settings, Wallet, WalletOutlined } from "@mui/icons-material";

export default function ChallengeSettingsTab({
  accountSizes,
  setAccountSizes,
  formValues,
  setFormValues,
}) {
  const [selectedTab, setSelectedTab] = useState("1");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  return (
    <Box
      sx={{
        overflowY: "auto",
        maxHeight: "70vh",
        pb: "100px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "sticky",
          top: 0,
          zIndex: 2,
          background: (theme) => theme.palette.color.bg2,
        }}
      >
        <Typography
          variant="h3"
          component="h2"
          mb={2}
          sx={{
            fontSize: 16,
            fontWeight: "600",
          }}
        >
          Challenge Settings
        </Typography>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="rules and objective"
          >
            <Tab
              value="1"
              icon={
                <WalletOutlined
                  sx={{
                    fontSize: "18px",
                  }}
                />
              }
              label="Pricing"
              iconPosition="start"
            />
            <Tab
              value="2"
              label="Settings"
              icon={
                <Settings
                  sx={{
                    fontSize: "18px",
                  }}
                />
              }
              iconPosition="start"
            />
          </Tabs>
        </Stack>
      </Box>
      {selectedTab === "1" && (
        <Stack rowGap={1} mt={2}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Typography
              variant="body3"
              sx={{
                display: "block",
                fontSize: 16,
                fontWeight: 400,
                color: "#ffffff",
                lineHeight: "18px",
              }}
              mt={1}
            >
              Pricing Plan
            </Typography>
            <FormControlLabel
              control={
                <CustomSwitch
                  value={formValues.monthly_recurring === 1}
                  onChange={() => {
                    setFormValues({
                      ...formValues,
                      monthly_recurring:
                        formValues.monthly_recurring === 1 ? 0 : 1,
                    });
                  }}
                  name="recurring"
                />
              }
              label="Monthly recurring"
              className="switch_with_label"
            />
          </Stack>
          <FormControlLabel
            control={
              <CustomSwitch
                value={formValues.refundable_fee === 1}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    refundable_fee: !formValues.refundable_fee,
                  });
                }}
                name="refundable"
              />
            }
            label="Refundable fee"
            className="switch_with_label"
          />
          <Typography
            variant="body3"
            sx={{
              display: "block",
              fontSize: 12,
              fontWeight: 400,
              lineHeight: "18px",
            }}
            mt={1}
          >
            The user will receive back his one-time fee when passing challenge
          </Typography>
          <Stack spacing={2}>
            {accountSizes.map((item, index) => (
              <Accordion
                defaultExpanded={index === 0}
                className="custom_accordion"
                sx={{
                  background: (theme) =>
                    theme.palette.color.borderpure + " !important",
                  "&::before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        fontSize: "18px",
                      }}
                    />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Stack
                    direction={"row"}
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt={"country"}
                      src={`/images/country-icons/${item.currency.toLowerCase()}.svg`}
                      sx={{
                        width: 18,
                        height: 18,
                        marginRight: "5px",
                      }}
                    >
                      {item.currency}
                    </Avatar>
                    <Typography
                      variant="body3"
                      align="center"
                      sx={{
                        display: "block",
                        fontSize: 14,
                        fontWeight: 600,
                        color: "#fff",
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack gap={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Typography variant="body3">Account size</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body3">Price per month</Typography>
                      </Grid>
                    </Grid>
                    <Divider
                      variant="fullWidth"
                      sx={{
                        my: 1,
                        borderColor: "rgba(33, 37, 41, 0.40)",
                      }}
                    />
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {item.sizes.map((size, index1) => (
                        <>
                          <Grid item xs={8}>
                            <Typography
                              variant="subtitle1_500"
                              sx={{
                                "& span": {
                                  color: (theme) =>
                                    theme.palette.color.secondary,
                                },
                              }}
                            >
                              <span>{item.code || "$"}</span> {size.value}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <FormInput
                              type="number"
                              sx={{
                                width: "100%",
                                borderRadius: "18px",
                                mx: "auto",
                                fontSize: "13px",
                                height: "fit-content",
                                "& input": {
                                  padding: "6px 12px !important",
                                  background: (theme) =>
                                    theme.palette.color.gray,
                                },
                                // mt:
                              }}
                              value={size.price}
                              onChange={(e) => {
                                setAccountSizes(
                                  accountSizes.map((s, i) =>
                                    i === index
                                      ? {
                                          ...s,
                                          sizes: s.sizes.map((s1, i1) =>
                                            i1 === index1
                                              ? {
                                                  ...s1,
                                                  price: parseInt(
                                                    e.target.value
                                                  ),
                                                }
                                              : s1
                                          ),
                                        }
                                      : s
                                  )
                                );
                              }}
                              startAdornment={
                                <InputAdornment position="end">
                                  <Typography
                                    sx={{
                                      ml: "4px",
                                      color: (theme) =>
                                        theme.palette.color.secondary,
                                    }}
                                  >
                                    $
                                  </Typography>
                                </InputAdornment>
                              }
                              placeholder="Enter price"
                            />
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </Stack>
      )}
      {selectedTab === "2" && (
        <Stack rowGap={1} mt={2}>
          <StepperCard horizontalAlign="flex-start">
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <StepperHeading
                heading={"Scaling Plan"}
                subHeading={
                  "Specify the criteria for increasing the user's account size, such as reaching profit targets, adhering to risk management rules, or meeting other performance metrics"
                }
                align="flex-start"
                subHeadingSize="12px"
                mainHeadingSize="16px"
              />
              <CustomSwitch
                checked={formValues.scaling_plan === 1}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    scaling_plan: formValues.scaling_plan === 0 ? 1 : 0,
                  });
                }}
                {...label}
              />
            </Stack>
          </StepperCard>
          <StepperCard horizontalAlign="flex-start">
            <Stack
              rowGap={1}
              sx={{
                width: "60%",
              }}
            >
              <StepperHeading
                heading={"Profit Split"}
                subHeading={
                  "The user will receive {{ percentage }}% from the total payout"
                }
                align="flex-start"
                subHeadingSize="12px"
                mainHeadingSize="16px"
              />
              <Divider />
              <TextField
                name="challenge"
                type="number"
                label=""
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                placeholder="0"
                className="textField_with_icon"
                value={formValues.profit_split}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    profit_split: Number(e.target.value),
                  });
                }}
              />
            </Stack>
          </StepperCard>
        </Stack>
      )}
    </Box>
  );
}
