import React from 'react';
import { Typography, Button, IconButton, Box, Stack, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TrashIcon from '../../components/Icons/Trash';

const Webhooks = () => {
    const handleAddWebhook = () => {};
    const handleDeleteWebhook = (index) => { };

    const webhooks = [
        {
            url: 'https://webhook.com/test',
            events: '1 event',
            color: 'green',
        },
        {
            url: 'https://webhook.com/another',
            events: '2 events',
            color: 'red',
        },
    ];

    return (
        <Box sx={{ padding: '20px', width: "100%" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"end"}>
                <Box>
                    <Typography variant="h3" gutterBottom>
                        Webhooks
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                        Use webhooks to listen for events and trigger reactions
                    </Typography>
                </Box>
                <Button size='small' sx={{ height: "35px", borderRadius: "32px", bgcolor: (theme) => theme.palette.color.active, color: "white", ":hover": { background: (theme) => theme.palette.color.active } }} startIcon={<AddIcon />} onClick={handleAddWebhook}>
                    Webhook
                </Button>
            </Stack>
            <TableContainer style={{ marginTop: '30px', color: (theme) => theme.palette.color.border }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '60%' }}>URL</TableCell>
                            <TableCell style={{ width: '15%' }}>Status</TableCell>
                            <TableCell style={{ width: '15%' }}>Listening for</TableCell>
                            <TableCell style={{ width: '10%' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {webhooks.map((webhook, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{color: (theme) => theme.palette.color.secondary}}>{webhook.url}</TableCell>
                                <TableCell>
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            background: (theme) => theme.palette.color[webhook.color],
                                            my: "14px",
                                            mx: "12px",
                                        }}
                                    ></Box>
                                </TableCell>
                                <TableCell sx={{color: (theme) => theme.palette.color.secondary}}>{webhook.events}</TableCell>
                                <TableCell>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteWebhook(index)}>
                                        <TrashIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Webhooks;