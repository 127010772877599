import React, { useState } from 'react';
import { Box, Stack, Typography, Grid, useMediaQuery } from '@mui/material';
import dayjs from "dayjs";
import ExportSection from '../../components/ui/ExportSection';
import CalendarPopup from '../../components/ui/CalendarPopup';
import ConversationRate from '../../components/Affiliates/Reports/conversationRate';
import Clicks from '../../components/Affiliates/Reports/clicks';
import GroupedBarChart from '../../components/Affiliates/Reports/GroupedBarChart';
import PayoutsChart from '../../components/Affiliates/Reports/payoutsChart';
import ReportCard from '../../components/Affiliates/Reports/reportCard';
import StatCard from '../../components/Affiliates/Reports/statCard';
import TotalReferralsChart from '../../components/Affiliates/Reports/totalReferralsChart';

const Reports = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [startDate, setStartDate] = useState(dayjs().startOf("week"));
    const [endDate, setEndDate] = useState(dayjs().endOf("week"));

    return (
        <Box sx={{ width: '100%' }} p={3}>

            <Stack direction="row" justifyContent="space-between" py={isSmall ? 0 : 1} bgcolor={(theme) => theme.palette.color.bg} ml={isSmall ? 1: 3} position={"sticky"} zIndex={10} top={0}>
                <Typography variant="h4">Report</Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                        fontSize: "11.5px",
                        color: (theme) => theme.palette.color.secondary,
                    }}
                >
                    <ExportSection />
                    <CalendarPopup
                        mainEndDate={endDate}
                        mainStartDate={startDate}
                        setMainEndDate={setEndDate}
                        setMainStartDate={setStartDate}
                    />
                </Stack>
            </Stack>

            <Grid container spacing={3} mt={1} px={isSmall ? 1 : 3}>
                <Grid item xs={12} md={4} sx={{ height: '270px' }}>
                <ReportCard />
                </Grid>
                <Grid item xs={12} md={4} sx={{ height: '270px' }}>
                <StatCard title="Total Visit" tooltip="Please Choose Status" value="130" />
                </Grid>
                <Grid item xs={12} md={4} sx={{ height: '270px' }}>
                <TotalReferralsChart />
                </Grid>
            </Grid>

            <Box sx={{ p: 3 }}>
                <PayoutsChart />
                <GroupedBarChart />
                <Stack display={"flex"} direction={isSmall? "column" : "row"} gap={5} mt={3}>
                    <Box flex={3}>
                        <ConversationRate />
                    </Box>
                    <Box flex={2}>
                        <Clicks />
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

export default Reports;