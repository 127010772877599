import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import RoundFormSelect from "../../ui/RoundFormSelect";
import ReactApexChart from "react-apexcharts";

const TicketsResolvedChart = () => {
  const theme = useTheme();
  const ChartContainer = styled(Box)({
    width: "100%",
    height: "100%",
    "& .apexcharts-toolbar": {
      display: "none !important",
    },
  });

  const series = [
    {
      name: "Resolved Within SLA",
      data: [95, 70, 85],
    },
    {
      name: "Resolved Outside SLA",
      data: [5, 30, 15],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 2,
        barHeight: "30%",
        dataLabels: {
          position: "center",
        },
      },
    },
    colors: [theme.palette.color.active, theme.palette.color.cyan],
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      style: {
        colors: ["#0B0D0F"],
        fontSize: "12px",
      },
    },
    xaxis: {
      categories: ["Technical Issues", "Billing", "Customer Support"],
      labels: {
        formatter: (val) => `${val}%`,
        style: {
          colors: ["#8A96A6", "#8A96A6", "#8A96A6"],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      max: 100,

      labels: {
        style: {
          colors: "#8A96A6",
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.strockborder,
        p: "10px",
        borderRadius: "16px",
        mt: 3,
      }}
    >
      <Stack
        direction="row"
        p={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5">
          Percentage of Tickets Resolved within SLA
        </Typography>
        <Stack direction="row" gap={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 14,
                height: 14,
                backgroundColor: (theme) => theme.palette.color.active,
                borderRadius: "50%",
                mr: 1,
              }}
            />
            <Typography variant="caption_400">Resolved Within SLA</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 14,
                height: 14,
                backgroundColor: (theme) => theme.palette.color.cyan,
                borderRadius: "50%",
                mr: 1,
              }}
            />
            <Typography variant="caption_400">Resolved Outside SLA</Typography>
          </Box>
        </Stack>
      </Stack>
      <ChartContainer>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </ChartContainer>
    </Box>
  );
};

export default TicketsResolvedChart;
