// import React, { useEffect, useRef } from 'react';
// import { Box, Typography } from '@mui/material';
// import { styled } from '@mui/system';

import { Box, styled, Typography, useTheme } from "@mui/material";

// const getColor = (percentage) => {
//     if (percentage > 75) return '#00FF7F';
//     if (percentage > 35) return '#FFA500';
//     return '#A9A9A9';
// };

// const getStatus = (percentage) => {
//     if (percentage > 75) return 'High';
//     if (percentage > 35) return 'Medium';
//     return 'Low';
// };

// const SemiCircleProgressWrapper = styled(Box)(({ theme }) => ({
//     position: 'relative',
//     width: '160px',
//     height: '80px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     margin: '20px',
// }));

// const BarOverflow = styled(Box)(({ theme }) => ({
//     position: 'relative',
//     overflow: 'hidden',
//     width: '160px',
//     height: '80px',
//     border: '1px solid #2C2C2C',
//     borderBottom: '0px',
//     borderRadius: '80px 80px 0 0',
// }));

// const InnerBorder = styled(Box)(({ theme }) => ({
//     position: 'absolute',
//     top: '5px',
//     left: '5px',
//     width: '150px',
//     height: '70px',
//     border: '1px solid #2C2C2C',
//     borderBottom: '0px',
//     borderRadius: '75px 75px 0 0',
// }));

// const Bar = styled(Box)(({ percentage }) => ({
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '160px',
//     height: '160px',
//     borderRadius: '50%',
//     boxSizing: 'border-box',
//     border: '5px solid transparent',
//     borderTopColor: getColor(percentage),
//     borderLeftColor: getColor(percentage),
//     transition: 'transform 1.5s ease',
// }));

// const Text = styled(Typography)(({ theme }) => ({
//     position: 'absolute',
//     textAlign: 'center',
//     fontWeight: 'bold',
// }));

// const MatchStrength = ({ percentage }) => {
//     const barRef = useRef(null);
//     const status = getStatus(percentage);
//     const color = getColor(percentage);

//     useEffect(() => {
//         const $bar = barRef.current;
//         const perc = parseInt(percentage, 10);
//         $bar.style.transform = `rotate(${180 + perc * 2.52}deg)`;
//     }, [percentage]);

//     return (
//         <SemiCircleProgressWrapper>
//             <BarOverflow>
//                 <InnerBorder />
//                 <Bar ref={barRef} percentage={percentage} />
//             </BarOverflow>
//             <Text variant="body1" style={{ fontSize: "8px", top: '30px' }}>Match Strength</Text>
//             <Text variant="subtitle1" color={color} style={{ fontSize: "18px", top: '50px' }}>{status}</Text>
//         </SemiCircleProgressWrapper>
//     );
// };

// export default MatchStrength;

const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-flex",
  height: "200px",
  overflow: "hidden",
  "& svg": {
    // position: "absolute",
    top: "-50px",
  },
}));

const strengths = [
  { range: [0, 30], color: "secondary", label: "Low" },
  { range: [31, 69], color: "orange", label: "Medium" },
  { range: [70, 100], color: "greenShade", label: "High" },
];

const MatchStrength = ({ percentage }) => {
  const theme = useTheme();
  const value = (percentage * 65) / 100;
  const strength = strengths.find(
    ({ range }) => percentage >= range[0] && percentage <= range[1]
  );
  const radius = 28;
  return (
    <Container>
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r={radius - 1}
          strokeWidth={0.3}
          strokeDasharray={`${2 * Math.PI * (radius - 1)}`}
          strokeDashoffset={`${2 * Math.PI * (radius - 1) * (1 - 65 / 100)}`}
          fill="none"
          stroke={theme.palette.color.lightgray}
          transform="rotate(153 50 50)"
        />
        <circle
          cx="50"
          cy="50"
          r={radius}
          strokeWidth={2}
          fill="none"
          stroke={theme.palette.color[strength?.color || "greenShade"]}
          strokeDasharray={`${2 * Math.PI * radius}`}
          strokeDashoffset={`${2 * Math.PI * radius * (1 - value / 100)}`}
          strokeLinecap="round"
          transform="rotate(153 50 50)"
        />
        <circle
          cx="50"
          cy="50"
          r={radius + 1}
          strokeWidth={0.3}
          strokeDasharray={`${2 * Math.PI * (radius + 1)}`}
          strokeDashoffset={`${2 * Math.PI * (radius + 1) * (1 - 65 / 100)}`}
          fill="none"
          stroke={theme.palette.color.lightgray}
          transform="rotate(153 50 50)"
        />
      </svg>

      <Box
        sx={{
          top: "-12px",
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "6px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "9px",
          }}
        >
          Match strength
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.color[strength?.color || "greenShade"],
          }}
        >
          {strength?.label || "Low"}
        </Typography>
      </Box>
    </Container>
  );
};

export default MatchStrength;
