import { Avatar, Box, Grid, Stack, styled, Typography, useTheme } from '@mui/material';
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import LineChart from '../../components/Charts/LineChart';
import RoundFormSelect from '../../components/ui/RoundFormSelect';
import CustomTable from '../../components/Firm/Orders/Table';

const FinancialStatistics = () => {
    return (
        <Box sx={{ width: '100%' }} p={3} pb={10}>
            <TotalProfitLossChart />
            <AverageMonthlyProfitLossChart />

            <Box sx={{
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
                p: 3,
                my: 3,
            }}>
                <Stack direction="row" pl={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Processing Time for Payouts
                </Typography>
                <Stack direction="row" gap={2}>
                    <RoundFormSelect
                        options={[{ value: "accountType", label: "Account Type" }]}
                        defaultValue="accountType"
                    />
                </Stack>
            </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Box sx={{ width: '40%' }}>
                        <PayoutsPieChart />
                    </Box>
                    <Box sx={{ width: '70%' }}>
                        <SuspiciousActivitiesTable />
                    </Box>
                </Stack>
            </Box>
            <ProcessedPayoutsChart />
            <ProcessingTimeChart />
        </Box>
    )
}

export default FinancialStatistics

const TotalProfitLossChart = () => {
    const ChartContainer = styled(Box)({
        width: '100%',
        height: '100%',
        '& .apexcharts-toolbar': {
            display: 'none !important',
        },
    });

    const series = [
        {
            name: 'Profit',
            data: [9, 12, 11],
        },
        {
            name: 'Loss',
            data: [12, 10, 8],
        },
    ];

    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                columnWidth: '20%',
                spacing: 10,
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#2FE0A2', '#5F55EE'],
        },
        xaxis: {
            categories: ['Account type A', 'Account type B', 'Account type C'],
            labels: {
                style: {
                    colors: ['#8A96A6', '#8A96A6', '#8A96A6'],
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            min: 0,
            max: 14,
            title: {
                text: "Amount",
                style: {
                    color: '#8A96A6',
                },
            },
            labels: {
                formatter: (val) => `${val}`,
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        fill: {
            opacity: 1,
            colors: ['#1D232B', '#1D232B'],
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: "vertical",
                shadeIntensity: 1,
                gradientToColors: ['#1D232B'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        tooltip: {
            enabled: false,
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 1,
                },
            },
        },
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
            p: 2,
            mt: 3,
        }}>
            <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Total profits and loss
                </Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#98EFCC', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Profit</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#604EF6', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Loss</Typography>
                    </Box>
                    <RoundFormSelect
                        options={[{ value: "accountType", label: "Account Type" }]}
                        defaultValue="accountType"
                    />
                </Stack>
            </Stack>
            <ChartContainer>
                <ReactApexChart
                    options={options}
                    series={series.map(({ name, data }, index) => ({
                        name,
                        data,
                        type: 'column',
                        fillColor: index === 0 ? {
                            colors: ['#2FE0A2'], // Gradient color for profit
                            borderColor: '#2FE0A2',
                            backgroundBarColors: ['#2FE0A2'],
                            backgroundBarOpacity: 1,
                        } : {
                            colors: ['#5F55EE'], // Gradient color for loss
                            borderColor: '#5F55EE',
                            backgroundBarColors: ['#5F55EE'],
                            backgroundBarOpacity: 1,
                        },
                    }))}
                    type="bar"
                    height={350}
                />
            </ChartContainer>
        </Box>
    );
};

const AverageMonthlyProfitLossChart = () => {
    const theme = useTheme();
    const options = {
        markers: {
            size: 4,
            colors: undefined,
            strokeWidth: 0,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 0.8,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        yaxis: {
            tickAmount: 4,
            title: {
                text: "Average Profit/Loss",
                style: {
                    color: '#8A96A6',
                    fontWeight: 400,
                },
            },
            labels: {
                style: {
                    colors: theme.palette.color.secondary,
                    fontSize: "13px",
                },
                formatter: function (val) {
                    return val;
                },
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                    colors: theme.palette.color.secondary,
                    fontSize: "13px",
                },
            },
            axisTicks: {
                show: false,
            },
            tickAmount: 12,
        },
        stroke: {
            curve: "straight",
            width: 4,
        },
    };

    const series = [
        {
            name: 'Account type A',
            data: [1500, 2300, 3000, 2500, 3500, 2900, 3200, 3100, 2800, 3300, 3700, 3600],
        },
        {
            name: 'Account type B',
            data: [1300, 2000, 2500, 2200, 3000, 2700, 2800, 2600, 2500, 2700, 3100, 3000],
        },
        {
            name: 'Account type C',
            data: [1100, 1500, 2000, 1800, 2500, 2300, 2400, 2300, 2200, 2400, 2700, 2600],
        },
    ];

    return (
        <Grid item xs={12} lg={6} mt={3}
            sx={{
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
            }}
        >
            <Stack spacing={3} sx={{ p: "24px" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",

                        color: (theme) => theme.palette.color.secondary,
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h5">Average Monthly Profit/Loss</Typography>
                    </Stack>

                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            alignItems: "center",
                        }}
                    >
                        <Stack direction="row" spacing={0.7} alignItems="center">
                            <Box
                                sx={{
                                    width: "12px",
                                    height: "12px",
                                    borderRadius: "50%",
                                    backgroundColor: "#5EEDF8",
                                }}
                            />
                            <Typography variant="caption">Account Type A</Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.7} alignItems="center">
                            <Box
                                sx={{
                                    width: "12px",
                                    height: "12px",
                                    borderRadius: "50%",
                                    backgroundColor: "#5F55EE",
                                }}
                            />
                            <Typography variant="caption">Account Type B</Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.7} alignItems="center">
                            <Box
                                sx={{
                                    width: "12px",
                                    height: "12px",
                                    borderRadius: "50%",
                                    backgroundColor: "#2FE0A2",
                                }}
                            />
                            <Typography variant="caption">Account Type C</Typography>
                        </Stack>
                        <RoundFormSelect
                            options={[{ value: "accountType", label: "Account Type" }]}
                            defaultValue="accountType"
                        />
                    </Stack>
                </Box>
                <LineChart
                    height="300"
                    categories={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                    colors={[
                        "#5EEDF8",
                        "#5F55EE",
                        "#2FE0A2",
                    ]}
                    options={options}
                    series={series}
                />
            </Stack>
        </Grid>
    );
};


const PayoutsPieChart = () => {
    const options = {
        labels: ['Account Type A', 'Account Type B', 'Account Type C'],
        colors: ['#5F55EE', '#5EEDF8', '#2FE0A2'], 
        legend: {
            show: false,
        },
        stroke: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return opts.w.config.labels[opts.seriesIndex] + ': 30%';
            },
            style: {
                fontSize: '15px',
                fontFamily: 'Arial, sans-serif',
                fontWeight: '500',
                colors: ['#8A96A6'],
            },
            dropShadow: {
                enabled: false,
            },
        },
        plotOptions: {
            pie: {
                customScale: 0.6,
                dataLabels: {
                    offset: 98,
                    minAngleToShowLabel: 10,
                },
            },
        },
    };

    const series = [30, 30, 30];

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="pie" height={350} />
        </div>
    );
};


const ProcessedPayoutsChart = () => {
    const ChartContainer = styled(Box)({
        width: '100%',
        height: '100%',
        '& .apexcharts-toolbar': {
            display: 'none !important',
        },
    });

    const series = [
        {
            name: 'Account type A',
            data: [30, 20, 22, 20],
        },
        {
            name: 'Account type B',
            data: [25, 40, 35, 40],
        },
        {
            name: 'Account type C',
            data: [40, 36, 15, 37],
        },
    ];

    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                columnWidth: '30%',
                spacing: 10,
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#2FE0A2', '#5F55EE'],
        },
        xaxis: {
            categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
            labels: {
                style: {
                    colors: ['#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6'],
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            min: 0,
            max: 50,
            tickAmount: 5,
            title: {
                text: "Number of Payouts",
                style: {
                    color: '#8A96A6',
                },
            },
            labels: {
                formatter: (val) => `${val}`,
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        fill: {
            opacity: 1,
            colors: ['#1D232B', '#1D232B'],
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: "vertical",
                shadeIntensity: 1,
                gradientToColors: ['#1D232B'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        tooltip: {
            enabled: false,
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 1,
                },
            },
        },
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
            p: 2,
            mt: 3,
        }}>
            <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Number of Processed Payouts
                </Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account type A</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account type B</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account type C</Typography>
                    </Box>
                    <RoundFormSelect
                        options={[{ value: "accountType", label: "Account Type" }]}
                        defaultValue="accountType"
                    />
                </Stack>
            </Stack>
            <ChartContainer>
                <ReactApexChart
                    options={options}
                    series={series.map(({ name, data }, index) => ({
                        name,
                        data,
                        type: 'column',
                        fillColor: index === 0 ? {
                            colors: ['#5EEDF8'],
                            borderColor: '#5EEDF8', 
                            backgroundBarColors: ['#5EEDF8'],
                            backgroundBarOpacity: 1,
                        } : index === 1 ? {
                            colors: ['#5F55EE'],
                            borderColor: '#5F55EE',
                            backgroundBarColors: ['#5F55EE'],
                            backgroundBarOpacity: 1,
                        } : {
                            colors: ['#2FE0A2'],
                            borderColor: '#2FE0A2',
                            backgroundBarColors: ['#2FE0A2'],
                            backgroundBarOpacity: 1,
                        },
                    }))}
                    type="bar"
                    height={350}
                />
            </ChartContainer>
        </Box>
    );
};

const ProcessingTimeChart = () => {
    const series = [
        {
            name: 'Account type A',
            type: 'line',
            data: [20, 25, 15, 22, 28, 32, 27],
        },
        {
            name: 'Account type B',
            type: 'line',
            data: [18, 20, 25, 19, 24, 28, 25],
        },
        {
            name: 'Account type C',
            type: 'line',
            data: [15, 18, 22, 17, 20, 24, 22],
        },
    ];

    const options = {
        chart: {
            height: 350,
            type: 'line',
            background: 'transparent',
            toolbar: {
                show: false,
            },
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
            },
        },
        colors: ['#5F55EE', '#5EEDF8', '#2FE0A2'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: [2, 2, 2],
        },
        xaxis: {
            categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            legend: {
                show: false,
            },
            labels: {
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        yaxis: {
            min: 5,
            max: 35,
            title: {
                text: "Processing Time ( Hours)",
                style: {
                    color: '#8A96A6',
                },
            },
            labels: {
                formatter: (val) => `${val}`,
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        tooltip: {
            enabled: false,
            shared: true,
            intersect: false,
            y: {
                formatter: (val) => `$${val}`,
            },
        },
        theme: {
            mode: 'dark',
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        markers: {
            size: 0,
        },
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
            p: 2,
            mt: 3,
        }}>
            <Stack direction="row" pl={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Processing Time for Payouts
                </Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ backgroundColor: "#5EEDF8", width: 14, height: 14, borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account type A</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ backgroundColor: "#5F55EE", width: 14, height: 14, borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account type B</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ backgroundColor: "#2FE0A2", width: 14, height: 14, borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account type C</Typography>
                    </Box>
                    <RoundFormSelect
                        options={[{ value: "accountType", label: "Account Type" }]}
                        defaultValue="accountType"
                    />
                </Stack>
            </Stack>
            <ReactApexChart options={options} series={series} type="line" height={350} />
        </Box>
    );
};


const SuspiciousActivitiesTable = () => {

    const data = [
        {
            accountType: "Account type A",
            age: "20",
            country: "Switzerland",
            countryFlag: "/images/country-icons/czk.svg",
            payoutAmount: "489.90 US$"
        },
        {
            accountType: "Account type B",
            age: "20",
            country: "Switzerland",
            countryFlag: "/images/country-icons/chf.svg",
            payoutAmount: "489.90 US$"
        },
        {
            accountType: "Account type C",
            age: "20",
            country: "USA",
            countryFlag: "/images/country-icons/usd.svg",
            payoutAmount: "489.90 US$"
        },
        {
            accountType: "Account type C",
            age: "20",
            country: "Austria",
            countryFlag: "/images/country-icons/aus.svg",
            payoutAmount: "489.90 US$"
        },
        {
            accountType: "Account type C",
            age: "20",
            country: "Switzerland",
            countryFlag: "/images/country-icons/czk.svg",
            payoutAmount: "489.90 US$"
        },
        {
            accountType: "Account type C",
            age: "20",
            country: "Spain",
            countryFlag: "/images/country-icons/chf.svg",
            payoutAmount: "489.90 US$"
        },
    ];

    const headcells = [
        {
            id: "accountType",
            label: "Account Type",
            getCell: (row) => row.accountType,
        },
        {
            id: "country",
            label: "Country",
            getCell: (row) => (
                <Stack direction="row" alignItems="center">
                    <Avatar src={row.countryFlag} sx={{ width: 20, height: 20, mr: 1 }} />
                    <Typography>{row.country}</Typography>
                </Stack>
            ),
        },
        {
            id: "age",
            label: "Age",
            getCell: (row) => row.age,
        },
        {
            id: "payoutAmount",
            label: "Payout Amount",
            getCell: (row) => row.payoutAmount,
        },
    ];

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.color.bg5,
                border: '1px solid',
                borderColor: (theme) => theme.palette.color.strockborder,
                p: '10px',
                borderRadius: '16px',
                mt: 3,
            }}
        >
            <Box sx={{ overflowX: "auto", width: "100%" }}>
                <CustomTable
                    headcells={headcells}
                    rows={data}
                    minWidth={200}
                />
            </Box>
        </Box>
    );
};