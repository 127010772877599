import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';

const ChallengeTypeAllocation = () => {
  const theme = useTheme();

  const options = {
    chart: {
      type: 'donut',
      background: 'transparent',
    },
    labels: ['Repeat Clicks', 'Unique Clicks', 'Declined Clicks', 'Active Clicks'],
    colors: ['#8CADFF', '#6554F6', '#52B694', '#2D3E60'],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val) + '%'
      },
      style: {
        fontSize: '12px',
        colors: [theme.palette.color.lightgray],
        fontWeight: 'bold',
      },
      background: {
        enabled: true,
        foreColor: theme.palette.color.secondary,
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#000',
        background: {
          enabled: true,
          color: '#1D232B',
        },
        opacity: 1,
      },
      dropShadow: {
        enabled: false
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: false,
          },
        },
        dataLabels: {
          offset: 25, 
          minAngleToShowLabel: 10
        }
      }
    },
    stroke: {
      width: 3,
      colors: ['#000000'],
    },
    tooltip: {
      enabled: false,
      y: {
        formatter: (val) => `${val}%`,
      },
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    }
  };

  const series = [40, 10, 20, 30];

  return (
    <Box sx={{
      background: (theme) => theme.palette.color.bg5,
      border: '1px solid',
      borderColor: (theme) => theme.palette.color.strockborder,
      borderRadius: "18px",
      color: 'white',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      height: '100%'
    }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Challenge Type Allocation
        </Typography>
      </Box>
      <Box sx={{ 
        flex: 1,
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
      }}>
        <Chart options={options} series={series} type="donut" width="100%" />
        <Box sx={{ width: '100%', mt: 3 }}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 14, height: 14, backgroundColor: '#8CADFF', borderRadius: '50%', mr: 1 }} />
                <Typography variant="body2">Challenge Type</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 2 }}>
                <Box sx={{ width: 14, height: 14, backgroundColor: '#6554F6', borderRadius: '50%', mr: 1 }} />
                <Typography variant="body2">Challenge Type</Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 14, height: 14, backgroundColor: '#52B694', borderRadius: '50%', mr: 1 }} />
                <Typography variant="body2">Challenge Type</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 2 }}>
                <Box sx={{ width: 14, height: 14, backgroundColor: '#2D3E60', borderRadius: '50%', mr: 1 }} />
                <Typography variant="body2">Challenge Type</Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ChallengeTypeAllocation;
