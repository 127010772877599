import React from "react";

import { useGetArticlesQuery } from "../../../../slices/api/articleSlice";
import Articles from "../../../../components/Firm/Docs/Articles";

const AllArticles = () => {
  const { data } = useGetArticlesQuery();

  return <Articles rows={[...(data?.data?.data || [])]} />;
};

export default AllArticles;
