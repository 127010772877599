import { Avatar, Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

import ChevronRightIcon from "../../../components/Icons/ChevronRight";
import HomeIcon from "../../../components/Icons/Home";
import CustomTable from "../../../components/Firm/Orders/Table";
import EyeOpenIcon from "../../../components/Icons/EyeOpen";
import { useSearchParams } from "react-router-dom";
import { useGetCustomerSolvedTicketsQuery } from "../../../slices/api/customerSlice";

const statuses = [
  { label: "Complete", value: "completed", color: "green" },
  { label: "Failed", value: "failed", color: "red" },
  { label: "Pending", value: "pending", color: "secondary" },
];

const headcells = [
  {
    id: "status",
    label: "",
    getCell: (row) => {
      const status = statuses.find((statute) => statute.value === row.status);
      return (
        <Box
          sx={{
            width: "9px",
            height: "9px",
            borderRadius: "50%",
            background: (theme) => theme.palette.color[status.color],
          }}
        ></Box>
      );
    },
  },
  {
    id: "solvedBy",
    label: "Solved by",
    getCell: (row) => {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box sx={{ position: "relative" }}>
            <Avatar
              src="/images/person.avif"
              sx={{
                width: "28px",
                height: "28px",
              }}
            ></Avatar>
            <Box
              sx={{
                position: "absolute",

                right: "0",
                bottom: "0",
                borderRadius: "50%",
                background: (theme) => theme.palette.color.green,
                width: "10px",
                height: "10px",
              }}
            ></Box>
          </Box>
          <Typography variant="body3">{row.solvedBy}</Typography>
        </Stack>
      );
    },
  },
  {
    id: "subject",
    label: "Subject",
    getCell: (row) => row.subject,
  },
  {
    id: "from",
    label: "From",
    getCell: (row) => row.from,
  },
  {
    id: "createdAt",
    label: "Creation date",
    getCell: (row) => row.createdAt,
  },
  {
    id: "closedDate",
    label: "Close date",
    getCell: (row) => row.closedDate,
  },
  {
    id: "ticketId",
    label: "Ticket ID",
    getCell: (row) => row.ticketId,
  },
  {
    id: "action",
    label: "",
    getCell: (row) => {
      return <EyeOpenIcon />;
    },
  },
];

const data = [
  {
    status: "completed",
    solvedBy: "John Doe",
    subject: "Issue A",
    from: "User A",
    createdAt: "10:00 01 Jan, 2024",
    closedDate: "14:00 02 Jan, 2024",
    ticketId: "#00100",
  },
  {
    status: "failed",
    solvedBy: "Jane Smith",
    subject: "Issue B",
    from: "User B",
    createdAt: "11:00 03 Jan, 2024",
    closedDate: "15:00 04 Jan, 2024",
    ticketId: "#00101",
  },
  {
    status: "pending",
    solvedBy: "Emily Davis",
    subject: "Issue C",
    from: "User C",
    createdAt: "12:00 05 Jan, 2024",
    closedDate: "16:00 06 Jan, 2024",
    ticketId: "#00102",
  },
  {
    status: "completed",
    solvedBy: "Michael Brown",
    subject: "Issue D",
    from: "User D",
    createdAt: "13:00 07 Jan, 2024",
    closedDate: "17:00 08 Jan, 2024",
    ticketId: "#00103",
  },
  {
    status: "failed",
    solvedBy: "Chris Johnson",
    subject: "Issue E",
    from: "User E",
    createdAt: "14:00 09 Jan, 2024",
    closedDate: "18:00 10 Jan, 2024",
    ticketId: "#00104",
  },
  {
    status: "pending",
    solvedBy: "Patricia Williams",
    subject: "Issue F",
    from: "User F",
    createdAt: "15:00 11 Jan, 2024",
    closedDate: "19:00 12 Jan, 2024",
    ticketId: "#00105",
  },
  {
    status: "completed",
    solvedBy: "Robert Jones",
    subject: "Issue G",
    from: "User G",
    createdAt: "16:00 13 Jan, 2024",
    closedDate: "20:00 14 Jan, 2024",
    ticketId: "#00106",
  },
  {
    status: "failed",
    solvedBy: "Linda Garcia",
    subject: "Issue H",
    from: "User H",
    createdAt: "17:00 15 Jan, 2024",
    closedDate: "21:00 16 Jan, 2024",
    ticketId: "#00107",
  },
  {
    status: "pending",
    solvedBy: "Barbara Martinez",
    subject: "Issue I",
    from: "User I",
    createdAt: "18:00 17 Jan, 2024",
    closedDate: "22:00 18 Jan, 2024",
    ticketId: "#00108",
  },
  {
    status: "completed",
    solvedBy: "Richard Rodriguez",
    subject: "Issue J",
    from: "User J",
    createdAt: "19:00 19 Jan, 2024",
    closedDate: "23:00 20 Jan, 2024",
    ticketId: "#00109",
  },
];

const CustomerTickets = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const { data } = useGetCustomerSolvedTicketsQuery(id);

  const theme = useTheme();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...(data?.data || [])].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  return (
    <Paper
      sx={{
        width: "100%",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          p: "24px",
          background: (theme) => theme.palette.color.active,
          width: "100%",
        }}
      >
        <Typography variant="h1">Tickets ({sortedData.length})</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <HomeIcon
            width="20px"
            height="20px"
            fill={theme.palette.color.secondary}
          />
          <ChevronRightIcon />
          <Typography variant="body3">Your Firm</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Customers</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">11020022</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Tickets</Typography>
        </Stack>
      </Stack>

      <Box
        sx={{
          p: "24px",
        }}
      >
        <CustomTable
          headcells={headcells}
          rows={sortedData}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          fontSize="11.5px"
        />
      </Box>
    </Paper>
  );
};

export default CustomerTickets;
