import React, { useState } from 'react';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import DailyTradingActivities from '../../components/Trading-accounts/Metrics/DailyTradingActivities';
import TradedPositions from '../../components/Trading-accounts/Metrics/TradedPositions';
import TradeSizeFrequency from '../../components/Trading-accounts/Metrics/TradeSizeFrequency';
import SmallSearchBar from '../../components/Affiliates/smallSearchBar';
import ExportSection from '../../components/ui/ExportSection';
import CalendarPopup from '../../components/ui/CalendarPopup';
import DailyTradingActivitiesPie from '../../components/Trading-accounts/Metrics/DailyTradingActivitiesPie';

const Activity = () => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    return (
        <Box sx={{ padding: '16px' }}>

            <Stack
                direction={isSmallScreen ? "column" : "row"}
                justifyContent={isSmallScreen ? "flex-start" : "space-between"}
                alignItems={isSmallScreen ? "flex-start" : "center"}
                gap={2}
                pr={2}
            >
                <Box
                    sx={{
                        ml: "15px",
                        display: "flex",
                        alignItems: "start",
                    }}
                >
                    <Typography variant="h3" mb={2} sx={{ fontWeight: "500", fontSize: "18px" }}>
                        Activity
                    </Typography>
                </Box>
                <Stack
                    direction="row"
                    spacing={isSmallScreen ? 1 : 2}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                        mb: "24px",
                        fontSize: "11.5px",
                        pr: "24px",
                        color: (theme) => theme.palette.color.secondary,
                    }}
                >
                    <SmallSearchBar />
                    <ExportSection />
                    <ExportSection isImport={true} />
                    <CalendarPopup
                        mainStartDate={startDate}
                        mainEndDate={endDate}
                        setMainStartDate={setStartDate}
                        setMainEndDate={setEndDate}
                    />
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 1.3125V10.6875" stroke="#CAD0DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.6875 6H1.3125" stroke="#CAD0DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Stack>
            </Stack>

            <Grid container spacing={3} px={2}>
                <Grid item xs={12} md={6}>
                    <DailyTradingActivities />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DailyTradingActivitiesPie />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TradedPositions />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TradeSizeFrequency />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Activity;