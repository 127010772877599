import { useTheme } from "@mui/material";
import * as React from "react";

const TrashIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  return (
    <svg width="24" height="24" fill="none" {...props}>
      <path
        stroke={stroke || theme.palette.color.primary}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m6.75 7.75.841 9.673a2 2 0 0 0 1.993 1.827h4.832a2 2 0 0 0 1.993-1.827l.841-9.673m-7.5-.25v-.75a2 2 0 0 1 2-2h.5a2 2 0 0 1 2 2v.75M5 7.75h14"
      />
    </svg>
  );
};

export default TrashIcon;
