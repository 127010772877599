import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import RoundFormSelect from '../../ui/RoundFormSelect';

const AverageTimeToCompletePhases = () => {
  const series = [
    {
      name: 'Account Type A',
      data: [5, 7]
    },
    {
      name: 'Account Type B',
      data: [4, 6]
    },
    {
      name: 'Account Type C',
      data: [6, 7]
    }
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      },
      background: 'transparent'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '45%',
        endingShape: 'rounded',
        borderRadius: 5
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#5EEDF8', '#5F55EE', '#2FE0A2']
    },
    xaxis: {
      categories: ['Phase 1', 'Phase 2'],
      labels: {
        style: {
          colors: '#8A96A6'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          colors: '#8A96A6'
        }
      },
      min: 0,
      max: 10,
      tickAmount: 5
    },
    fill: {
      type: 'gradient',
      opacity: [0.15, 0.15, 0.15],
      gradient: {
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: [0.18, 0.18, 0.18],
        opacityTo: [0.05, 0.05, 0.05],
        type: 'vertical',
        stops: [0, 100, 100],
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false
    },
    annotations: {
      points: [{
        x: 'Phase 2',
        y: 6.5,
        marker: {
          size: 6,
          fillColor: '#FF69B4',
          strokeColor: '#FF69B4',
          radius: 2
        },
        label: {
          borderColor: '#14181F',
          style: {
            color: '#fff',
            background: '#14181F',
          },
          text: 'Average Time : 6.5'
        }
      }]
    }
  };

  return (
    <Box sx={{
      background: (theme) => theme.palette.color.bg5,
      border: '1px solid',
      borderColor: (theme) => theme.palette.color.strockborder,
      borderRadius: "18px",
      p: 3,
      color: 'white'
    }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Average time to complete Phases</Typography>
        <RoundFormSelect
          options={[{ value: "accountType", label: "Account Type" }]}
          defaultValue="accountType"
        />
      </Box>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
      <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 14, height: 14, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
          <Typography variant="body2">Account Type A</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 14, height: 14, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
          <Typography variant="body2">Account Type B</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 14, height: 14, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
          <Typography variant="body2">Account Type C</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <svg width="12" height="4" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="2" r="2" fill="#ED55D4" />
            <rect y="1.5" width="12" height="1" fill="#ED55D4" />
          </svg>
          <Typography variant="body2" pl={1}>Average Time</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default AverageTimeToCompletePhases;