import { useTheme } from "@mui/material";
import * as React from "react";

const ShoppingBag = ({ fill, ...props }) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            {...props}
        >
            <path
                d="M5.24586 5.41878C5.26949 4.99689 5.61873 4.66675 6.04081 4.66675H13.9591C14.3812 4.66675 14.7305 4.99689 14.7541 5.41865L15.2582 14.4925C15.2832 14.95 14.92 15.3334 14.4633 15.3334H5.53664C5.07991 15.3334 4.71667 14.9499 4.74171 14.4926L5.24586 5.41878ZM9.99998 8.85175C9.64464 8.85175 9.20331 8.5062 9.20331 7.88258V7.40758C9.20331 6.77352 8.68987 6.25925 8.05581 6.25925C7.42133 6.25925 6.90747 6.77311 6.90747 7.40758V7.88258C6.90747 9.58882 8.19923 11.1484 9.99998 11.1484C11.8007 11.1484 13.0925 9.58882 13.0925 7.88258V7.40758C13.0925 6.77311 12.5786 6.25925 11.9441 6.25925C11.3109 6.25925 10.7958 6.77269 10.7958 7.40758V7.88258C10.7958 8.50662 10.3549 8.85175 9.99998 8.85175Z"
                fill={fill || theme.palette.color.secondary}
                stroke={fill || theme.palette.color.secondary}
            />
        </svg>
    );
};

export default ShoppingBag;