import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ApexCharts from 'react-apexcharts';
import RoundFormSelect from '../../ui/RoundFormSelect';

export const RevenueChart = () => {
    const series = [
        {
            name: 'Gross Revenue',
            type: 'area',
            data: [250, 350, 130, 220, 340, 200, 150, 230, 270, 200, 150],
        },
        {
            name: 'Net Revenue',
            type: 'line',
            data: [300, 250, 270, 250, 220, 220, 180, 250, 200, 150, 100],
        },
    ];

    const options = {
        chart: {
            height: 350,
            type: 'line',
            background: 'transparent',
            toolbar: {
                show: false,
            },
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
            },
        },
        colors: ['#5F55EE', '#2FE0A2'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: [2, 2],
        },
        fill: {
            type: 'gradient',
            opacity: [0.15, 1],
            gradient: {
                shadeIntensity: 0.2,
                inverseColors: false,
                opacityFrom: [0.38, 1],
                opacityTo: [0.05, 1],
                shade: 'dark',
                type: 'vertical',
                stops: [0, 100],
            },
        },
        xaxis: {
            categories: ['12 May', '13 May', '14 May', '15 May', '16 May', '17 May', '18 May', '19 May', '20 May', '21 May', '22 May'],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        yaxis: {
            min: 0,
            max: 400,
            tickAmount: 8,
            labels: {
                formatter: (val) => `$${val}`,
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        tooltip: {
            enabled: false,
            shared: true,
            intersect: false,
            y: {
                formatter: (val) => `$${val}`,
            },
        },
        theme: {
            mode: 'dark',
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        markers: {
            size: 0,
        },
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            border: '1px solid',
            borderColor: (theme) => theme.palette.color.strockborder,
            p: '20px',
            borderRadius: '12px',
        }}>
            <Stack direction="row" pl={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Overview
                </Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 16, height: 16, background: '#5F55EE', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Gross Revenue</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 16, height: 16, background: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Net Revenue</Typography>
                    </Box>
                    <RoundFormSelect
                        options={[{ value: "week", label: "Week" }, { value: "month", label: "Month" }, { value: "year", label: "Year" }]}
                        defaultValue="week"
                    />
                    <RoundFormSelect
                        options={[{ value: "accountType", label: "Account Type" }]}
                        defaultValue="accountType"
                    />
                </Stack>
            </Stack>
            <ApexCharts options={options} series={series} type="line" height={350} />
        </Box>
    );
};