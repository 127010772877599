import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const Certificates = () => {
  return (
    <Box p={4} width="100%">
      <Typography variant="h3" mb={1}>Certificates</Typography>
      <Typography variant="subtitle2">Use webhooks to listen for events and trigger reactions</Typography>

      <Grid container spacing={3} mt={4}>
        <Grid item xs={12} sm={6} md={4}>
          <CertificateCard title="Certificate 1" description="This is a description of the integration" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CertificateCard title="Certificate 2" description="This is a description of the integration" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CertificateCard title="Integration" description="This is a description of the integration" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CertificateCard title="Integration" description="This is a description of the integration" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CertificateCard title="Integration" description="This is a description of the integration" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CertificateCard title="Integration" description="This is a description of the integration" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Certificates;

const CertificateCard = ({ title, description }) => {
  return (
    <Box sx={{ p: "26px 24px", borderRadius: "16px", border: "1px solid", borderColor: (theme) => theme.palette.color.strockborder,  width: "100%", flex: 1, bgcolor: (theme) => theme.palette.color.bg5 }}>
      <Typography variant="h5" mb={1}>{title}</Typography>
      <Typography variant="subtitle2">{description}</Typography>
    </Box>
  );
}