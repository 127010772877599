import { Add } from "@mui/icons-material";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import PlusIcon from "../../../Icons/Plus";
import EditIcon from "../../../Icons/Edit";
import MailIcon from "../../../Icons/Mail";
import PasswordIcon from "../../../Icons/Password";
import BrowseIcon from "../../../Icons/Browse";
import SaleIcon from "../../../Icons/Sale";
import AddUserIcon from "../../../Icons/AddUser";

const items = [
  { title: "Trading account", icon: PlusIcon },
  { title: "Edit customer", icon: EditIcon },
  { title: "Send email", icon: MailIcon },
  { title: "Forget password", icon: PasswordIcon },
  { title: "Refund", icon: BrowseIcon },
  { title: "Create coupon", icon: SaleIcon },

  { title: "Invite as affiliate", icon: AddUserIcon },
  { title: "Add to program", icon: PlusIcon },
];

const Actions = () => {
  const theme = useTheme();
  return (
    <Stack spacing={0.5} sx={{ py: "12px" }}>
      <Typography sx={{ px: "24px" }} variant="h6">
        Actions
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          px: "12px",
          width: "100%",
        }}
      >
        {items.map((item) => (
          <Grid item xs={12} sm={6} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: (theme) => theme.palette.color.borderpure,
                border: (theme) => `1px solid ${theme.palette.color.lightgray}`,
                borderRadius: "12px",
                p: "20px",
                gap: 1,
              }}
            >
              <item.icon
                stroke={theme.palette.color.secondary}
                width="24px"
                height="24px"
              />
              <Typography variant="caption_500">{item.title}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Actions;
