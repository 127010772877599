import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

import ChevronRightIcon from "../../../components/Icons/ChevronRight";
import HomeIcon from "../../../components/Icons/Home";
import CustomTable from "../../../components/Firm/Orders/Table";
import { useSearchParams } from "react-router-dom";
import { useGetCustomerOrdersQuery } from "../../../slices/api/customerSlice";

const statuses = [
  { label: "Complete", value: "completed", color: "green" },
  { label: "Closed", value: "closed", color: "red" },
  { label: "Open", value: "open", color: "yellow" },
];

const headcells = [
  {
    id: "status",
    label: "Status",
    getCell: (row) => {
      const status = statuses.find((statute) => statute.value === row.status);
      return (
        <Box
          sx={{
            width: "9px",
            height: "9px",
            borderRadius: "50%",
            background: (theme) => theme.palette.color[status.color],
          }}
        ></Box>
      );
    },
  },
  {
    id: "id",
    label: "ID",
    getCell: (row) => row.id,
  },
  {
    id: "name",
    label: "Name",
    getCell: (row) => row.name,
  },

  {
    id: "platform",
    label: "Platform",
    getCell: (row) => row.platform,
  },
  {
    id: "challenge",
    label: "Challenge",
    getCell: (row) => row.challenge,
  },
  {
    id: "customerId",
    label: "Customer ID",
    getCell: (row) => row.customerId,
  },
  {
    id: "size",
    label: "Size",
    getCell: (row) => row.size,
  },
  {
    id: "value",
    label: "Value",
    getCell: (row) => row.value,
  },
];

const data = [
  {
    status: "completed",
    id: "#22100",
    name: "Product A",
    platform: "Platform X",
    challenge: "Challenge A",
    customerId: "Cust-001",
    size: "Large",
    value: "$100",
  },
  {
    status: "closed",
    id: "#22101",
    name: "Product B",
    platform: "Platform Y",
    challenge: "Challenge B",
    customerId: "Cust-002",
    size: "Medium",
    value: "$50",
  },
  {
    status: "open",
    id: "#22102",
    name: "Product C",
    platform: "Platform Z",
    challenge: "Challenge C",
    customerId: "Cust-003",
    size: "Small",
    value: "$20",
  },
  {
    status: "completed",
    id: "#22103",
    name: "Product D",
    platform: "Platform X",
    challenge: "Challenge D",
    customerId: "Cust-004",
    size: "Large",
    value: "$150",
  },
  {
    status: "closed",
    id: "#22104",
    name: "Product E",
    platform: "Platform Y",
    challenge: "Challenge E",
    customerId: "Cust-005",
    size: "Medium",
    value: "$70",
  },
  {
    status: "open",
    id: "#22105",
    name: "Product F",
    platform: "Platform Z",
    challenge: "Challenge F",
    customerId: "Cust-006",
    size: "Small",
    value: "$30",
  },
  {
    status: "completed",
    id: "#22106",
    name: "Product G",
    platform: "Platform X",
    challenge: "Challenge G",
    customerId: "Cust-007",
    size: "Large",
    value: "$200",
  },
  {
    status: "closed",
    id: "#22107",
    name: "Product H",
    platform: "Platform Y",
    challenge: "Challenge H",
    customerId: "Cust-008",
    size: "Medium",
    value: "$90",
  },
  {
    status: "open",
    id: "#22108",
    name: "Product I",
    platform: "Platform Z",
    challenge: "Challenge I",
    customerId: "Cust-009",
    size: "Small",
    value: "$40",
  },
  {
    status: "completed",
    id: "#22109",
    name: "Product J",
    platform: "Platform X",
    challenge: "Challenge J",
    customerId: "Cust-010",
    size: "Large",
    value: "$250",
  },
];

const CustomerOrders = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data } = useGetCustomerOrdersQuery(id);

  const theme = useTheme();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...(data?.data || [])].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  return (
    <Paper
      sx={{
        width: "100%",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          p: "24px",
          background: (theme) => theme.palette.color.active,
          width: "100%",
        }}
      >
        <Typography variant="h1">Orders ({sortedData.length})</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <HomeIcon
            width="20px"
            height="20px"
            fill={theme.palette.color.secondary}
          />
          <ChevronRightIcon />
          <Typography variant="body3">Your Firm</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Customers</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">11020022</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Orders</Typography>
        </Stack>
      </Stack>

      <Box
        sx={{
          p: "24px",
        }}
      >
        <CustomTable
          headcells={headcells}
          rows={sortedData}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          fontSize="11.5px"
        />
      </Box>
    </Paper>
  );
};

export default CustomerOrders;
