import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import HomeIcon from "../../../components/Icons/Home";
import ChevronRightIcon from "../../../components/Icons/ChevronRight";
import ReportCard from "../../../components/Affiliates/Reports/reportCard";
import MetricCard from "../../../components/ui/MetricCard";
import InfoBox from "../../../components/ui/InfoBox";
import { useSearchParams } from "react-router-dom";
import { useGetCustomerStatisticsQuery } from "../../../slices/api/customerSlice";

const CustomerStatistics = () => {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data } = useGetCustomerStatisticsQuery(id);
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          p: "24px",
          background: (theme) => theme.palette.color.active,
          width: "100%",
        }}
      >
        <Typography variant="h1">Statistics</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <HomeIcon
            width="20px"
            height="20px"
            fill={theme.palette.color.secondary}
          />
          <ChevronRightIcon />
          <Typography variant="body3">Your Firm</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Customers</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">11020022</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Statistics</Typography>
        </Stack>
      </Stack>

      <Grid container spacing={3} sx={{ p: "24px", width: "100%" }}>
        <Grid item xs={12} lg={4}>
          <ReportCard />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MetricCard
            title="Total accounts"
            value={data?.data?.accounts?.length || 0}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MetricCard
            title="Lifetime Value"
            value="$15,000.00
"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InfoBox
            item={{
              title: "Total accounts",
              value: "54,000",
              tooltip: "Amount of payouts",
            }}
            sx={{
              height: "150px",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InfoBox
            item={{
              title: "Av. payout value",
              value: "-",
              tooltip: "Amount of payouts",
            }}
            sx={{
              height: "150px",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InfoBox
            item={{
              title: "Total Refunds",
              value: "-",
              tooltip: "Amount of payouts",
            }}
            sx={{
              height: "150px",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InfoBox
            item={{
              title: "Av. Winrate",
              value: "61%",
              tooltip: "Amount of payouts",
            }}
            sx={{
              height: "150px",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InfoBox
            item={{
              title: "Av. TTB",
              value: "14 days",
              tooltip: "Amount of payouts",
            }}
            sx={{
              height: "150px",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InfoBox
            item={{
              title: "Av. holding time",
              value: "15m 30s",
              tooltip: "Amount of payouts",
            }}
            sx={{
              height: "150px",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerStatistics;
