import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';

const HFTChart = ({ title }) => {
  const theme = useTheme();

  const series = [
    [33, 34, 33],
    [30, 20, 50],
    [40, 35, 25]
  ];
  const labels = ['Account type A', 'Account type B', 'Account type C'];
  const colors = ['#5EEDF8', '#5F55EE', '#2FE0A2'];

  const createOptions = (title) => ({
    chart: {
      type: 'donut',
      background: 'transparent',
    },
    colors: colors,
    labels: labels,
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val) + '%'
      },
      style: {
        fontSize: '12px',
        colors: [theme.palette.color.lightgray],
        fontWeight: 'bold',
      },
      background: {
        enabled: true,
        foreColor: theme.palette.color.secondary,
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#000',
        background: {
          enabled: true,
          color: '#1D232B',
        },
        opacity: 1,
      },
      dropShadow: {
        enabled: false
      },
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: false,
        donut: {
          size: '65%',
        },
        dataLabels: {
          offset: 20, 
          minAngleToShowLabel: 10
        }
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: 'dark',
    },
  });

  return (
    <Box sx={{
      background: (theme) => theme.palette.color.bg5,
      border: '1px solid',
      borderColor: (theme) => theme.palette.color.strockborder,
      p: '20px',
      borderRadius: '16px',
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">{title}</Typography>
        <Stack direction="row" spacing={2}>
          {labels.map((label, index) => (
            <Box key={label} sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <Box sx={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: colors[index], mr: 1 }} />
              <Typography variant="caption" sx={{ color: theme.palette.color.text }}>{label}</Typography>
            </Box>
          ))}
          <RoundFormSelect
            options={[{ value: "accountType", label: "Account Type" }]}
            defaultValue="accountType"
          />
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
        {['Phase 1', 'Phase 2', 'Live accounts'].map((phase, index) => (
          <Box key={phase} sx={{ width: '30%' }}>
            <ReactApexChart
              options={createOptions(phase)}
              series={series[index]}
              type="donut"
              height={200}
            />
            <Typography variant="subtitle2" align="center" sx={{ mt: 2, color: theme.palette.color.text }}>
              {phase}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HFTChart;