import { Grid, Paper, Box } from "@mui/material";
import React, { useState } from "react";
import ChatList from "../../../components/Customer-service/YourConversations/ChatList";
import CustomerDetails from "../../../components/Customer-service/Conversations/CustomerDetails";
import ClientConversation from "../../../components/Customer-service/Conversations/ClientConversation";

const customerData = {
  verified: true,
  platform: "Dashboard",
  firstName: "Jens",
  lastName: "van der Blij",
  country: "Netherlands",
  city: "Hardenberg",
  zip: "7772NM",
  email: "sales@vuetra.com",
  phone: "+3160000000",
  timezone: "+1 CET",
  activeAccounts: 1,
  kyc: "Successful",
};

const YourConversations = () => {
  const [showCustomerInfo, setShowCustomerInfo] = useState(true);
  const [detailedView, setDetailedView] = useState(true);

  const handleDetailedView = () => {
    setDetailedView(!detailedView);
  };

  const handleShowClientInfo = () => {
    setShowCustomerInfo(!showCustomerInfo);
  };

  return (
    <Box width={"100%"}>
      {/* <Grid container spacing={2}> */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <ChatList
          detailedView={detailedView}
          onDetailView={handleDetailedView}
          onShowClientInfo={handleShowClientInfo}
        />
        <ClientConversation />
        <CustomerDetails
          showCustomerInfo={showCustomerInfo}
          customer={customerData}
        />
      </Box>
      {/* <Grid item xs={detailedView ? 3 : 1}>
          <Paper elevation={3}>
            <ChatList
              detailedView={detailedView}
              onDetailView={handleDetailedView}
              onShowClientInfo={handleShowClientInfo}
            />
          </Paper>
        </Grid> */}
      {/* <Grid
          item
          className="conversation_grid_cont"
          xs={
            !detailedView && !showCustomerInfo
              ? 11
              : !showCustomerInfo
              ? 9
              : !detailedView
              ? 8
              : 6
          }
        >
          <Paper elevation={3}>
            <ClientConversation />
          </Paper>
        </Grid> */}
      {/* {showCustomerInfo && (
          <Grid item xs={3} className="conversation_grid_cont" >
            <Paper elevation={3}>
              <CustomerDetails customer={customerData} />
            </Paper>
          </Grid>
      // </Grid>
        )} */}
    </Box>
  );
};

export default YourConversations;
