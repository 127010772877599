import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { alpha, styled } from "@mui/material/styles";
import FormInput from "../../components/ui/FormInput";
import FormSelect from "../../components/ui/FormSelect1";
import CustomSwitch from "../../components/ui/Switch";
import { backend_url } from "../../static/backendurl";
import {
  useGetEmailSettingsQuery,
  useUpdateEmailSettingsMutation,
} from "../../slices/api/settingSlice";
import { useGetCountriesQuery } from "../../slices/api/generalSlice";

const EmailSettings = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    company: "",
    address: "",
    city: "",
    country_id: 0,
    email: "",
    open_tracking: 0,
  });
  const { data } = useGetCountriesQuery();
  const [updateEmailSettings] = useUpdateEmailSettingsMutation();
  const { data: emailSettings } = useGetEmailSettingsQuery();

  useEffect(() => {
    setFormValues({
      name: emailSettings?.data?.name || "",
      company: emailSettings?.data?.company || "",
      address: emailSettings?.data?.address || "",
      city: emailSettings?.data?.city || "",
      country_id: emailSettings?.data?.country_id || 0,
      email: emailSettings?.data?.email || "",
      open_tracking: emailSettings?.data?.open_tracking || 0,
    });
  }, [emailSettings]);

  const handleSubmit = async () => {
    await updateEmailSettings(formValues);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "24px",
          py: "10px",
        }}
      >
        <Box>
          <Typography variant="h3" sx={{ m: "3px", mb: "3px" }} gutterBottom>
            Email settings
          </Typography>
          <Typography variant="caption" gutterBottom>
            View and update your affiliate settings
          </Typography>
        </Box>
        <Button
          size="small"
          sx={{
            p: "5px",
            px: "10px",
            borderRadius: "32px",
            bgcolor: (theme) => theme.palette.color.active,
            color: "white",
            ":hover": { background: (theme) => theme.palette.color.active },
          }}
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </Box>

      <Divider />
      <Box
        sx={{
          p: "24px",
        }}
      >
        <Grid container spacing={2} rowSpacing={3}>
          {/* <Grid item xs={12} md={6}></Grid> */}
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={0.5} direction={"row"}>
              <Typography variant="heading" pt={0.5}>
                Company / Organization name
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: (theme) => theme.palette.color.red,
                }}
              >
                *
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <FormInput
                placeholder="example@site.com"
                value={formValues.name}
                onChange={(e) => {
                  setFormValues({ ...formValues, name: e.target.value });
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack pr={2}>
              <Stack spacing={0.5} direction={"row"}>
                <Typography variant="heading" pt={0.5}>
                  Address
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: (theme) => theme.palette.color.red,
                  }}
                >
                  *
                </Typography>
              </Stack>
              <Typography variant="caption" sx={{ fontWeight: "500", mt: 1 }}>
                Required by law to comply with the CAN-SPAM act and
                international anti-spam laws
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack>
              <FormInput
                placeholder="Enter Address"
                sx={{
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                value={formValues.address}
                onChange={(e) => {
                  setFormValues({ ...formValues, address: e.target.value });
                }}
              />
              <Stack direction={"row"}>
                <FormInput
                  placeholder="Enter City"
                  sx={{
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    height: "100%",
                  }}
                  value={formValues.city}
                  onChange={(e) => {
                    setFormValues({ ...formValues, city: e.target.value });
                  }}
                />
                <CountrySelect
                  countries={[...(data?.data || [])]}
                  country_id={formValues.country_id}
                  onChange={(value) => {
                    setFormValues({ ...formValues, country_id: value });
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack>
              <Stack spacing={0.5} direction={"row"}>
                <Typography variant="heading" pt={0.5}>
                  Default sender
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: (theme) => theme.palette.color.red,
                  }}
                >
                  *
                </Typography>
              </Stack>
              <Typography variant="caption" sx={{ fontWeight: "500", mt: 1 }}>
                The name your emails will come from
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack direction={"row"}>
              <FormInput
                placeholder="Enter Company"
                sx={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                value={formValues.company}
                onChange={(e) => {
                  setFormValues({ ...formValues, company: e.target.value });
                }}
              />
              <FormSelect
                placeholder="info@email.com"
                defaultValue="info@email.com"
                sx={{
                  bgcolor: (theme) => theme.palette.color.gray,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                options={[
                  { value: "info@email.com", label: "info@email.com" },
                  { value: "contact@email.com", label: "contact@email.com" },
                  { value: "test@email.com", label: "test@email.com" },
                ]}
                fullWidth
                value={formValues.email}
                onChange={(e) => {
                  setFormValues({ ...formValues, email: e.target.value });
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack>
              <Stack spacing={0.5} direction={"row"}>
                <Typography variant="heading" pt={0.5}>
                  Track opens
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: (theme) => theme.palette.color.red,
                  }}
                >
                  *
                </Typography>
              </Stack>
              <Typography variant="caption" sx={{ fontWeight: "500", mt: 1 }}>
                Track opens with an invisible beacon embedded in your emails
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <CustomSwitch
                checked={formValues.open_tracking === 1}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    open_tracking: formValues.open_tracking === 1 ? 0 : 1,
                  });
                }}
              />
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Enable open-tracking
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EmailSettings;

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: "13px",
    color: theme.palette.color.secondary,
    fontWeight: "500",
    paddingRight: "32px !important",
    outline: "none !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.color.gray,
    borderRadius: "0 0 14px 14px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.color.gray,
    borderRadius: "0 0 14px 14px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.color.gray,
    borderRadius: "0 0 14px 14px",
  },
  "& .MuiAutocomplete-clearIndicator": {
    display: "none",
  },
  "& fieldset": {
    border: "none",
  },
}));

const CountrySelect = ({ countries, country_id, onChange, sx }) => {
  const selectedCountry = countries.find(
    (country) => country.id === country_id
  );

  return (
    <StyledAutocomplete
      options={countries}
      value={
        selectedCountry
          ? {
              ...selectedCountry,
              label: selectedCountry?.name,
            }
          : null
      }
      onChange={(event, newValue) => {
        onChange(newValue.id);
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            display: "flex",
            alignItems: "center",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <img
            src={`${backend_url}storage/${option.flag}`}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: 8 }}
          />

          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select country"
          InputProps={{
            ...params.InputProps,
            value: selectedCountry?.name,
            startAdornment: selectedCountry && (
              <img
                src={`${backend_url}storage/${selectedCountry?.flag}`}
                alt=""
                style={{ width: "20px", height: "20px", marginRight: 8 }}
              />
            ),
          }}
          sx={{
            background: (theme) => theme.palette.color.gray,
            borderRadius: "0 0 14px 0px",
            ...sx,
          }}
        />
      )}
      popupIcon={
        <ExpandMoreIcon
          sx={{
            color: (theme) => theme.palette.color.secondary,
            fontSize: "15px",
          }}
        />
      }
      sx={{
        ...sx,
      }}
      fullWidth
    />
  );
};
