import { useTheme } from "@mui/material";
import * as React from "react";

const PerformanceAndBenchmarksIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_3983_32762"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={18}
        height={18}
      >
        <path d="M3 3H21V21H3V3Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3983_32762)">
        <path
          d="M5.03906 16.1092V18.543"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.67969 12.5323V18.543"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3203 14.9075V18.543"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9609 10.8577V18.543"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.375 12.0469C6.375 12.7847 5.77689 13.3828 5.03906 13.3828C4.30124 13.3828 3.70312 12.7847 3.70312 12.0469C3.70312 11.3091 4.30124 10.7109 5.03906 10.7109C5.77689 10.7109 6.375 11.3091 6.375 12.0469Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0156 8.46875C11.0156 9.20657 10.4175 9.80469 9.67969 9.80469C8.94186 9.80469 8.34375 9.20657 8.34375 8.46875C8.34375 7.73093 8.94186 7.13281 9.67969 7.13281C10.4175 7.13281 11.0156 7.73093 11.0156 8.46875Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6562 10.8438C15.6562 11.5816 15.0581 12.1797 14.3203 12.1797C13.5825 12.1797 12.9844 11.5816 12.9844 10.8438C12.9844 10.1059 13.5825 9.50781 14.3203 9.50781C15.0581 9.50781 15.6562 10.1059 15.6562 10.8438Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2969 6.79688C20.2969 7.5347 19.6988 8.13281 18.9609 8.13281C18.2231 8.13281 17.625 7.5347 17.625 6.79688C17.625 6.05905 18.2231 5.46094 18.9609 5.46094C19.6988 5.46094 20.2969 6.05905 20.2969 6.79688Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.09717 11.2305L8.62146 9.28482"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9541 7.67188L15.3267 9.96484"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1308 10.2344L10.8691 9.07679"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default PerformanceAndBenchmarksIcon;