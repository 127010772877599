import * as React from "react";

const DedicatedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.75 4.75H7.75C7.21957 4.75 6.71086 4.96071 6.33579 5.33579C5.96071 5.71086 5.75 6.21957 5.75 6.75V10.25M12.75 4.75V8.25C12.75 8.78043 12.9607 9.28914 13.3358 9.66421C13.7109 10.0393 14.2196 10.25 14.75 10.25H18.25M12.75 4.75L18.25 10.25M18.25 10.25V17.25C18.25 17.7804 18.0393 18.2891 17.6642 18.6642C17.2891 19.0393 16.7804 19.25 16.25 19.25H12.75M9.25 12.75H8.75C7.95435 12.75 7.19129 13.0661 6.62868 13.6287C6.06607 14.1913 5.75 14.9544 5.75 15.75V16.25C5.75 17.0456 6.06607 17.8087 6.62868 18.3713C7.19129 18.9339 7.95435 19.25 8.75 19.25H9.25M4.75 16H8.25"
      stroke="#8A96A6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DedicatedIcon;