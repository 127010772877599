import {
  alpha,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoBox from "../../components/ui/InfoBox";
import LineChart from "../../components/Charts/LineChart";
import ExportSection from "../../components/ui/ExportSection";
import CalendarPopup from "../../components/ui/CalendarPopup";
import ReportCard from "../../components/Affiliates/Reports/reportCard";
import StatCard from "../../components/Affiliates/Reports/statCard";
import dayjs from "dayjs";
import Breached from "../../components/Statistics/Traders/Breached";
import Chart from 'react-apexcharts';
import InfoIcon2 from "../../components/Icons/Info2";

const Traders = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const theme = useTheme();

  const revenueData = {
    series: [{
      data: [600, 800, 440, 400, 810, 450]
    }],
    options: {
      chart: {
        type: 'bar',
        toolbar: { show: false }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 10,
          barHeight: '50%',
          distributed: true,
          colors: {
            backgroundBarColors: ['#0D1117'],
            backgroundBarOpacity: 1,
          },
        },
      },
      legend: {
        show: false
      },
      colors: ['#8CADFF', '#2FE0A2', '#8CADFF', '#8CADFF', '#8CADFF', '#8CADFF'],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        min: 0,
        max: 1600,
        tickAmount: 8,
        categories: ['250K', '200K', '150K', '100K', '50K', '25K'],
        labels: {
          show: true,
          formatter: function (val) {
            return val + "K";
          },
          style: {
            colors: ['#8A96A6'],
            padding: { top: 10, bottom: 10 }
          }
        },
        title: {
          text: "Revenue",
          style: {
            color: '#8A96A6',
            padding: { bottom: 10 },
          },
        },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: ['#8A96A6'],
            padding: { top: 10, bottom: 10 }
          }
        },
        title: {
          text: "Account type",
          style: {
            color: '#8A96A6',
            padding: { right: 10 },
          },
        },
      },
      grid: {
        show: false
      },
      tooltip: {
        enabled: false
      },
    }
  };


  return (
    <Box
      sx={{
        width: "100%",
        py: "8px",
      }}
    >
      <Stack bgcolor={(theme) => theme.palette.color.bg} direction={isSmall ? "column" : "row"} justifyContent="space-between" pl={1} position={"sticky"} gap={1} zIndex={10} top={0} py={1} px={isSmall ? 2 : 3}>
        <Stack direction="column" spacing={1}>
          <Typography variant="h5">Traders</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            fontSize: "11.5px",
            color: (theme) => theme.palette.color.secondary,
            flexWrap: "wrap",
          }}
        >
          <ExportSection />
          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
          <FormSelect
            options={[{ value: "all", label: "All challenges" }]}
            defaultValue="all"
          />
          <FormSelect
            options={[{ value: "all", label: "All sizes" }]}
            defaultValue="all"
          />
          <Button variant="contained" size="small" disableElevation
            sx={{
              py: "2px",
              bgcolor: (theme) => theme.palette.color.lightgray,
              borderRadius: "32px",
              border: "1px solid",
              borderColor: (theme) => theme.palette.color.border,
              color: (theme) => theme.palette.color.secondary,
              ":hover": {
                bgcolor: (theme) => theme.palette.color.lightgray,
              }
            }}>
            <img src="/svg/stars.svg" alt="Stars" style={{ width: 24, height: 24, marginRight: 8 }} />
            AI Analyze
          </Button>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 2 }} />

      <Box px={3}>

        <Grid container spacing={3} mt={1}>
          <Grid item sm={12} md={4}>
            <ReportCard />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <StatCard title="Passed" tooltip="112" value="130" reverseImage={true} />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Breached />
          </Grid> 
        </Grid>

        <Grid container spacing={3} mt={1}>
          <Grid item xs={6} sm={6} md={4}>
            <InfoBox
              item={{
                title: "Breached %",
                value: "95.5%",
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <InfoBox
              item={{
                title: "Order to Payout",
                value: "41",
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoBox
              item={{
                title: "Average Payout",
                value: "$1,500.00",
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={1}>
          <Grid item xs={6} sm={6}>
            <InfoBox
              item={{
                title: "Highest breach type",
                value: "Challenge Type",
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InfoBox
              item={{
                title: "Highest breach size",
                value: "200K",
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
        </Grid>

        <Grid container mt={3}
          sx={{
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
          }}
        >
          <Grid item xs={12}>
            <Stack direction="row" m={3}>
              <Typography variant="h5">Average Breach Point</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <LineChart
              height="300"
              categories={["0", "3d", "6d", "9d", "12d", "15d", "18d"]}
              colors={[
                theme.palette.color.green,
                theme.palette.color.purple,
                theme.palette.color.darkblue,
                theme.palette.color.secondary,
              ]}
              options={{
                chart: {
                  type: "donut",
                  foreColor: "#ffffff",
                },
                grid: {
                  borderColor: "#EF3252",
                },
                labels: ["Phase 1", "Phase 2", "Funded", "Payout"],
                dropShadow: {
                  enabled: true,
                  top: 0,
                  left: 0,
                  blur: 3,
                  opacity: 0.5,
                },
                stroke: {
                  show: false,
                  width: 0,
                },
                plotOptions: {
                  pie: {
                    borderRadius: 10,
                    donut: {
                      size: "50%",
                      labels: {
                        show: true,
                        name: {
                          show: true,
                          fontSize: "18px",
                          fontFamily: "Inter, sans-serif",
                          color: theme.palette.color.secondary,
                          fontWeight: "600",
                          formatter: function (w) {
                            return w === "Total" ? "Product name" : w;
                          },
                        },
                        value: {
                          show: true,
                          fontSize: "22px",
                          fontFamily: "Inter, sans-serif",
                          color: theme.palette.color.purple,
                          fontWeight: "600",
                        },
                        total: {
                          show: true,
                          fontSize: "16px",
                          fontFamily: "Inter, sans-serif",
                          color: theme.palette.color.secondary,
                          fontWeight: "600",
                        },
                      },
                    },
                  },
                },
                datasets: [
                  {
                    borderColor: ["#EF3252"],
                  },
                ],
              }}
              series={[55, 35, 9, 1]}
              type="donut"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: "24px",
                height: "300px",
                borderTop: (theme) => `1px solid ${theme.palette.color.border}`,
                borderLeft: (theme) => `1px solid ${theme.palette.color.border}`,
              }}
            >
              <Stack spacing={3} sx={{ pt: "32px" }}>
                {[
                  {
                    title: "Phase 1",
                    value: "55%",
                    color: "green",
                  },
                  {
                    title: "Phase 2",
                    value: "35%",
                    color: "purple",
                  },
                  {
                    title: "Funded",
                    value: "9%",
                    color: "darkblue",
                  },
                  {
                    title: "Payout",
                    value: "1%",
                    color: "secondary",
                  },
                ].map((item, index) => (
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1.5} alignItems="center">
                      <Box
                        sx={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: theme.palette.color[item.color],
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "13.56px",
                          fontWeight: "500",
                          color: (theme) => theme.palette.color.primary,
                          pr: "4px",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "500",
                        color: (theme) => theme.palette.color.secondary,
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Grid>
        </Grid>


        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12} md={6}>
            <InfoBox
              item={{
                title: "ATF Ratio",
                value: "1 / 400",
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InfoBox
              item={{
                title: "FTP Ratio",
                value: "1 / 1200",
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6} mt={3}
          sx={{
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
          }}
        >
          <Stack spacing={3} sx={{ p: "24px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",

                color: (theme) => theme.palette.color.secondary,
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">Time to breach</Typography>
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  alignItems: "center",
                }}
              >
                <Stack direction="row" spacing={0.7} alignItems="center">
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: theme.palette.color.green,
                    }}
                  />
                  <Typography variant="caption">Phase 1</Typography>
                </Stack>
                <Stack direction="row" spacing={0.7} alignItems="center">
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: "rgb(126, 131, 255)",
                    }}
                  />
                  <Typography variant="caption">Phase 2</Typography>
                </Stack>
                <Stack direction="row" spacing={0.7} alignItems="center">
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: theme.palette.color.secondary,
                    }}
                  />
                  <Typography variant="caption">Funded</Typography>
                </Stack>
              </Stack>
            </Box>
            <LineChart
              height="300"
              categories={["0", "3d", "6d", "9d", "12d", "15d", "18d"]}
              colors={[
                theme.palette.color.green,
                "rgb(126, 131, 255)",
                "#8A96A6",
              ]}
              options={{
                markers: {
                  size: 10,
                  colors: undefined,
                  strokeWidth: 0,
                  strokeOpacity: 0.9,
                  strokeDashArray: 0,
                  fillOpacity: 1,
                  discrete: [],
                  shape: "circle",
                  radius: 2,
                  offsetX: 0,
                  offsetY: 0,
                  onClick: undefined,
                  onDblClick: undefined,
                  showNullDataPoints: true,
                  hover: {
                    size: undefined,
                    sizeOffset: 3,
                  },
                },
                yaxis: {
                  tickAmount: 4,
                  labels: {
                    style: {
                      colors: theme.palette.color.secondary,
                      fontSize: "13px",
                    },
                    formatter: function (val) {
                      return val + 10 + "%";
                    },
                  },
                },
                xaxis: {
                  categories: ["0", "3d", "6d", "9d", "12d", "15d", "18d"],
                  axisBorder: {
                    show: false,
                  },
                  labels: {
                    style: {
                      colors: theme.palette.color.secondary,
                      fontSize: "13px",
                    },
                  },
                  axisTicks: {
                    show: false,
                  },
                  tickAmount: 7,
                },
                stroke: {
                  curve: "straight",
                  width: 2,
                },
              }}
              series={[
                {
                  name: "Eval 1",
                  data: [null, null, 17, 50, 60, null],
                },
                {
                  name: "Eval 2",
                  data: [null, 23, 35, 56, 70, null, null],
                },
                {
                  name: "Funded",
                  data: [20, 53, 70, null, null, null, null],
                },
              ]}
            />
          </Stack>
        </Grid>

        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12} md={6}>
            <InfoBox
              item={{
                title: "Total Payouts",
                value: "41",
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InfoBox
              item={{
                title: "Average Payout",
                value: "$1,500.00",
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={1} pb={5}>
          <Grid item xs={12} sm={12} md={8}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              p: "24px",
              width: "100%",
              height: "100%",
              background: (theme) => theme.palette.color.bg5,
              borderRadius: "18px",
              border: "1px solid",
              borderColor: (theme) => theme.palette.color.border,
            }}>
              <Typography variant="h6" gutterBottom>Revenue by age Group</Typography>
              <Chart options={revenueData.options} series={revenueData.series} type="bar" height={"100%"} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TotalRevenueCard
              item={{
                title: "Total Revenue",
                value: "$2,500,000"
              }}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
            <TotalRevenueCard
              item={{
                title: "Average Payout",
                value: "$1,500.00",
                quantity: "100k"
              }}
              sx={{
                mt: "15px",
                background: (theme) => theme.palette.color.bg2,
              }}
            />
            <TotalRevenueCard
              item={{
                title: "Average Payout",
                value: "$1,500.00",
                quantity: "100k"
              }}
              sx={{
                mt: "15px",
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
        </Grid>

      </Box>
    </Box>
  );
};

export default Traders;


const FormSelect = ({ options, defaultValue, sx, ...props }) => {
  return (
    <Box sx={sx}>
      <Select
        defaultValue={defaultValue}
        {...props}
        sx={{
          borderRadius: "50px",
          "& .MuiSelect-outlined": {
            bgcolor: (theme) => theme.palette.color.border,
            color: (theme) => theme.palette.color.secondary,
            fontSize: "11.5px",
            padding: "7px 11.8px",
            border: (theme) =>
              `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
            borderRadius: "50px",
            ":hover": {
              bgcolor: (theme) => theme.palette.color.bg,
              border: (theme) =>
                `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
              cursor: "pointer",
              borderRadius: "50px",
              margin: "0px !important",
            },
            minWidth: "0",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        IconComponent={() => (
          <ExpandMoreIcon
            sx={{
              color: (theme) => theme.palette.color.secondary,
              fontSize: "15px",
              position: "absolute",
              right: "8px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        )}
        inputProps={{
          sx: {
            bgcolor: "transparent",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};


const TotalRevenueCard = ({ item, icon, sx }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "24px",
        width: "100%",
        height: "140px",
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="caption">{item.title}</Typography>
          <Tooltip title={item.tooltip} placement="top">
            {icon ? icon : <InfoIcon2 fill="none" />}
          </Tooltip>
        </Stack>
      </Box>
      <Stack direction="row" alignItems="center" gap={2}>
        {item.quantity && <Typography variant="h1" fontSize={isSmall ? "14px" : "20px"} color={(theme) => theme.palette.color.secondary}>{item.quantity}</Typography>}
        <Typography variant="h1" fontSize={isSmall ? "20px" : "25px"}>{item.value}</Typography>
      </Stack>
    </Box>
  );
};