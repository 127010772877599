import React from 'react';
import { Typography, Button, IconButton, Box, Stack, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TrashIcon from '../../components/Icons/Trash';

const ApiKeys = () => {
    const handleAddWebhook = () => {};
    const handleDeleteWebhook = (index) => {};

    const apis = [
        {
            name: 'Test',
            creation: 'Apr 19, 2024 11:05',
            expires: 'Apr 19, 2024 11:05',
            color: 'green',
        },
        {
            name: 'Test',
            creation: 'Apr 19, 2024 11:05',
            expires: 'Apr 19, 2024 11:05',
            color: 'red',
        },
        {
            name: 'Test',
            creation: 'Apr 19, 2024 11:05',
            expires: 'Apr 19, 2024 11:05',
            color: 'red',
        },
        {
            name: 'Test',
            creation: 'Apr 19, 2024 11:05',
            expires: 'Apr 19, 2024 11:05',
            color: 'red',
        },
        {
            name: 'Test',
            creation: 'Apr 19, 2024 11:05',
            expires: 'Apr 19, 2024 11:05',
            color: 'red',
        },
    ];

    return (
        <Box sx={{ padding: '20px', width: "100%" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"end"}>
                <Typography variant="h3" gutterBottom>
                    API Keys
                </Typography>
                <Button size='small' sx={{ p: "5px", px: "10px", borderRadius: "32px", bgcolor: (theme) => theme.palette.color.active, color: "white", ":hover": { background: (theme) => theme.palette.color.active } }} startIcon={<AddIcon />} onClick={handleAddWebhook}>
                    API Key
                </Button>
            </Stack>
            <Stack direction={"row"} width={"70%"} mt={1}>
                <Typography variant="caption" gutterBottom>
                    Create a new API key to authenticate your app .  Help ↗
                </Typography>
            </Stack>
            <TableContainer style={{ marginTop: '30px', color: (theme) => theme.palette.color.border }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '60%' }}>Name</TableCell>
                            <TableCell style={{ width: '15%' }}>Creation date</TableCell>
                            <TableCell style={{ width: '15%' }}>Expires</TableCell>
                            <TableCell style={{ width: '10%' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apis.map((api, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ color: (theme) => theme.palette.color.secondary }}>{api.name}</TableCell>
                                <TableCell sx={{ color: (theme) => theme.palette.color.secondary }}>{api.creation}</TableCell>
                                <TableCell sx={{ color: (theme) => theme.palette.color.secondary }}>{api.expires}</TableCell>
                                <TableCell>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteWebhook(index)}>
                                        <TrashIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ApiKeys;