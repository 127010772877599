import { Autocomplete, TextField } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { alpha, styled } from "@mui/material/styles";
import { backend_url } from "../../static/backendurl";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: "13px",
    color: theme.palette.color.secondary,
    fontWeight: "500",
    paddingRight: "32px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: alpha(theme.palette.color.border, 0.65),
    borderRadius: "14px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: alpha(theme.palette.color.border, 0.8),
    borderRadius: "14px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: alpha(theme.palette.color.border, 1),
    borderRadius: "14px",
  },
  "& .MuiAutocomplete-clearIndicator": {
    display: "none",
  },
  "& fieldset": {
    border: "none",
  },
}));

const CountrySelect = ({ countries, country_id, onChange, sx }) => {
  const selectedCountry = countries.find(
    (country) => country.id === country_id
  );

  return (
    <StyledAutocomplete
      options={countries}
      value={
        selectedCountry
          ? {
              ...selectedCountry,
              label: selectedCountry?.name,
            }
          : null
      }
      onChange={(event, newValue) => {
        onChange(newValue.id);
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            display: "flex",
            alignItems: "center",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <img
            src={`${backend_url}storage/${option.flag}`}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: 8 }}
          />

          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select country"
          InputProps={{
            ...params.InputProps,
            value: selectedCountry?.name,
            startAdornment: selectedCountry && (
              <img
                src={`${backend_url}storage/${selectedCountry?.flag}`}
                alt=""
                style={{ width: "20px", height: "20px", marginRight: 8 }}
              />
            ),
          }}
          sx={{
            background: (theme) => alpha(theme.palette.color.border, 0.65),
            border: (theme) =>
              `1px solid ${alpha(theme.palette.color.border, 0.65)}`,
            borderRadius: "14px",
            ...sx,
          }}
        />
      )}
      popupIcon={
        <ExpandMoreIcon
          sx={{
            color: (theme) => theme.palette.color.secondary,
            fontSize: "15px",
          }}
        />
      }
      sx={{
        ...sx,
      }}
      fullWidth
    />
  );
};

export default CountrySelect;
