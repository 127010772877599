import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Stack, useMediaQuery, Tooltip, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import ExportSection from '../../components/ui/ExportSection';
import CalendarPopup from '../../components/ui/CalendarPopup';
import InfoIcon2 from '../../components/Icons/Info2';
import LineChart from '../../components/Charts/LineChart';
import RoundFormSelect from '../../components/ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const Demographic = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [startDate, setStartDate] = useState(dayjs().startOf("week"));
    const [endDate, setEndDate] = useState(dayjs().endOf("week"));

    const challengeSalesData = {
        series: [{
            data: [70, 140, 100, 60, 30]
        }],
        options: {
            chart: { type: 'bar', width: '100%', toolbar: { show: false } },
            plotOptions: { 
                bar: { 
                    horizontal: true, 
                    barHeight: '70%', 
                    borderRadius: 4,
                    distributed: true,
                    dataLabels: {
                        position: 'bottom'
                    }
                } 
            },
            colors: ['#5F55EE', '#5EEDF8', '#5F55EE', '#5EEDF8', '#5F55EE'],
            dataLabels: { 
                enabled: false,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                formatter: function (val, opt) {
                    return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
            xaxis: {
                categories: ['18-25', '26-35', '36-45', '46-55', '56+'],
                labels: { show: true, style: { colors: ['#8A96A6'] } },
                axisBorder: { show: false },
                axisTicks: { show: false },
            },
            yaxis: { labels: { show: true, style: { colors: ['#8A96A6'] } }, },
            grid: { show: false },
            tooltip: { enabled: false },
            legend: { show: false },
            theme: {
                mode: 'light',
                palette: 'palette1',
                monochrome: {
                    enabled: false,
                    color: '#255aee',
                    shadeTo: 'light',
                    shadeIntensity: 0.65
                },
            }
        }
    };

    const revenueData = {
        series: [{
            data: [80, 120, 80, 140, 80]
        }],
        options: {
            chart: { type: 'bar', toolbar: { show: false } },
            plotOptions: { 
                bar: {
                    horizontal: true,
                    borderRadius: 4,
                    barHeight: '70%',
                    distributed: true,
                    colors: {
                      backgroundBarColors: [theme.palette.mode === 'dark' ? '#12181b' : '#f0f0f0'],
                      backgroundBarOpacity: 1,
                    },
                },
            },
            legend: {
                show: false
            },
            colors: ['#5F55EE'],
            dataLabels: {
                enabled: true,
                formatter: (val) => `${val}%`,
                style: { colors: ['#8A96A6'], fontWeight: 'bold', align: 'right' },
                offsetX: 850
            },
            xaxis: {
                min: 0,
                max: 200,
                tickAmount: 10,
                categories: ['18-25', '26-35', '36-45', '46-55', '56+'],
                labels: { show: true, style: { colors: ['#8A96A6'] } },
                axisBorder: { show: false },
                axisTicks: { show: false },
            },
            yaxis: { labels: { show: true, style: { colors: ['#8A96A6'] } }, },
            grid: { show: false },
            tooltip: { enabled: false },
        }
    };

    const series = [
        {
            name: 'Age 18-25',
            data: [780, 920, 1000, 1130, 1250, 1350, 1300, 1220, 1180, 1080, 1060, 980],
        },
        {
            name: 'Age 26-35',
            data: [980, 940, 920, 880, 840, 820, 940, 920, 960, 1020, 1080, 1140],
        },
        {
            name: 'Age 36-45',
            data: [560, 640, 740, 840, 920, 1000, 980, 900, 900, 880, 900, 860],
        },
        {
            name: 'Age 46-55',
            data: [1180, 1320, 1440, 1540, 1260, 1040, 800, 580, 600, 620, 660],
        },
        {
            name: 'Age 56+',
            data: [280, 340, 420, 480, 480, 500, 500, 480, 400, 340, 440, 500],
        },
    ];

    const options = {
        markers: {
            size: 4,
            colors: undefined,
            strokeWidth: 0,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 0.8,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        yaxis: {
            tickAmount: 8,
            min: 200,
            max: 1600,
            labels: {
                style: {
                    colors: theme.palette.color.secondary,
                    fontSize: "13px",
                },
                formatter: function (val) {
                    return val;
                },
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                    colors: theme.palette.color.secondary,
                    fontSize: "13px",
                },
            },
            axisTicks: {
                show: false,
            },
            tickAmount: 12,
        },
        stroke: {
            curve: "straight",
            width: 4,
        },
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Stack
                bgcolor={(theme) => theme.palette.color.bg}
                direction={isSmall ? "column" : "row"}
                justifyContent="space-between"
                pl={1}
                position={"sticky"}
                zIndex={10}
                p={3}
                pb={1}
                top={0}
                sx={{ width: "100%" }}
            >
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5">Demographic</Typography>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <img src="/svg/leftArrow.svg" alt="leftArrow" style={{ width: "6px", height: "10px", cursor: "pointer" }} />
                        <Typography variant="caption">June 2024</Typography>
                        <img src="/svg/rightArrow.svg" alt="RightArrow" style={{ width: "6px", height: "10px", transform: "rotate(180deg)", cursor: "pointer" }} />
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    mt={isSmall ? 1 : 0}
                    justifyContent={isSmall ? "start" : "flex-end"}
                    sx={{
                        fontSize: "11.5px",
                        color: (theme) => theme.palette.color.secondary,
                    }}
                >
                    <ExportSection />
                    <CalendarPopup
                        mainEndDate={endDate}
                        mainStartDate={startDate}
                        setMainEndDate={setEndDate}
                        setMainStartDate={setStartDate}
                    />
                    <Button
                        disableElevation
                        variant="contained"
                        size="small"
                        sx={{
                            py: "2px",
                            bgcolor: (theme) => theme.palette.color.lightgray,
                            borderRadius: "32px",
                            border: "1px solid",
                            borderColor: (theme) => theme.palette.color.border,
                            color: (theme) => theme.palette.color.secondary,
                            ":hover": {
                                bgcolor: (theme) => theme.palette.color.lightgray,
                            },
                        }}
                    >
                        <img
                            src="/svg/stars.svg"
                            alt="Stars"
                            style={{ width: 24, height: 24, marginRight: 8 }}
                        />
                        AI Analyze
                    </Button>
                </Stack>
            </Stack>

            <Grid container spacing={3} p={3}>
                <Grid item xs={12}>
                    <Box sx={{ p: 2, bgcolor: (theme) => theme.palette.color.lightgray, borderRadius: "16px" }}>
                        <Stack sx={{ bgcolor: (theme) => theme.palette.color.bg2, borderRadius: "12px", p: "20px", border: "1px solid", borderColor: (theme) => theme.palette.color.gray }}>
                            <Typography variant="h6" gutterBottom>Challenge Sales by age Group</Typography>
                            <ReactApexChart 
                                options={challengeSalesData.options} 
                                series={challengeSalesData.series} 
                                type="bar" 
                                height={250} 
                            />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" width="100%" mt={2} gap={2}>
                            <Stack sx={{ flex: 1, bgcolor: (theme) => theme.palette.color.bg2, borderRadius: "12px", p: "20px", border: "1px solid", borderColor: (theme) => theme.palette.color.gray, height: "132px", justifyContent: "space-between" }}>
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                    <Typography variant="subtitle1" color={(theme) => theme.palette.color.secondary}>Top Performing Age Group</Typography>
                                    <Tooltip title="Top Performing Age Group">
                                        <InfoIcon2 style={{ width: 20, height: 20 }} />
                                    </Tooltip>
                                </Stack>
                                <Typography variant="h1">25-34</Typography>
                            </Stack>
                            <Stack sx={{ flex: 1, bgcolor: (theme) => theme.palette.color.bg2, borderRadius: "12px", p: "20px", border: "1px solid", borderColor: (theme) => theme.palette.color.gray, height: "132px", justifyContent: "space-between" }}>
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                    <Typography variant="subtitle1" color={(theme) => theme.palette.color.secondary}>Total Sales Across All Age Groups</Typography>
                                    <Tooltip title="Total Sales Across All Age Groups">
                                        <InfoIcon2 style={{ width: 20, height: 20 }} />
                                    </Tooltip>
                                </Stack>
                                <Typography variant="h1">$20,000</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        p: "24px",
                        width: "100%",
                        background: (theme) => theme.palette.color.bg5,
                        borderRadius: "18px",
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.color.border,
                    }}>
                        <Typography variant="h6" gutterBottom>Revenue by age Group</Typography>
                        <Chart options={revenueData.options} series={revenueData.series} type="bar" height={250} />
                    </Box>
                </Grid>


                <Grid item xs={12}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        p: "24px",
                        width: "100%",
                        background: (theme) => theme.palette.color.bg5,
                        borderRadius: "18px",
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.color.border,
                    }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",

                                color: (theme) => theme.palette.color.secondary,
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h5">Sales Trends by Demographics Groups</Typography>
                            </Stack>

                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                <Stack direction="row" spacing={0.7} alignItems="center">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="3" width="6" height="6" fill="#2FE0A2" />
                                        <rect y="2.5" width="12" height="1" fill="#2FE0A2" />
                                    </svg>
                                    <Typography variant="caption">Age 18-25</Typography>
                                </Stack>
                                <Stack direction="row" spacing={0.7} alignItems="center">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="3" r="3" fill="#5F55EE" />
                                        <rect y="2.5" width="12" height="1" fill="#5F55EE" />
                                    </svg>
                                    <Typography variant="caption">Age 26-35</Typography>
                                </Stack>
                                <Stack direction="row" spacing={0.7} alignItems="center">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 0L9 3L6 6L3 3L6 0Z" fill="#5EEDF8" />
                                        <rect y="2.5" width="12" height="1" fill="#5EEDF8" />
                                    </svg>

                                    <Typography variant="caption">Age 36-45</Typography>
                                </Stack>
                                <Stack direction="row" spacing={0.7} alignItems="center">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="3" r="3" fill="#ED55D4" />
                                        <rect y="2.5" width="12" height="1" fill="#ED55D4" />
                                    </svg>

                                    <Typography variant="caption">Age 46-55</Typography>
                                </Stack>
                                <Stack direction="row" spacing={0.7} alignItems="center">
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="3" width="6" height="6" fill="#FFC452" />
                                        <rect y="2.5" width="12" height="1" fill="#FFC452" />
                                    </svg>

                                    <Typography variant="caption">Age 56+</Typography>
                                </Stack>
                                <RoundFormSelect
                                    options={[{ value: "monthly", label: "Monthly" }]}
                                    defaultValue="monthly"
                                />
                            </Stack>
                        </Box>
                        <LineChart
                            height="300"
                            categories={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                            colors={[
                                "#2FE0A2",
                                "#5F55EE",
                                "#5EEDF8",
                                "#ED55D4",
                                "#FFC452"
                            ]}
                            options={options}
                            series={series}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Demographic;