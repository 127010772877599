import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import CustomTable from '../../Firm/Orders/Table';
import RoundFormSelect from '../../ui/RoundFormSelect';
import ExportSection from '../../ui/ExportSection';

const RiskSummaryTable = () => {
    const data = [
        {
            accountId: "102911050",
            country: "Switzerland",
            countryFlag: "/images/country-icons/chf.svg",
            month: "Aug, 2024",
            totalDrawdown: "$15,000",
            maximumDrawdown: 500,
            breachesType: "HFT Breaches",
            breaches: 45,
        },
        {
            accountId: "102911050",
            country: "Switzerland",
            countryFlag: "/images/country-icons/chf.svg",
            month: "May, 2024",
            totalDrawdown: "$20,000",
            maximumDrawdown: 300,
            breachesType: "Arbitrage Breaches",
            breaches: 30,
        },
        {
            accountId: "102911050",
            country: "USA",
            countryFlag: "/images/country-icons/usd.svg",
            month: "Aug, 2024",
            totalDrawdown: "$20,000",
            maximumDrawdown: 500,
            breachesType: "Copy Trading Breaches",
            breaches: 45,
        },
        {
            accountId: "102911050",
            country: "Austria",
            countryFlag: "/images/country-icons/aus.svg",
            month: "Aug, 2024",
            totalDrawdown: "$15,000",
            maximumDrawdown: 100,
            breachesType: "HFT Breaches",
            breaches: 30,
        },
        {
            accountId: "102911050",
            country: "Switzerland",
            countryFlag: "/images/country-icons/chf.svg",
            month: "Aug, 2024",
            totalDrawdown: "$20,000",
            maximumDrawdown: 500,
            breachesType: "Copy Trading Breaches",
            breaches: 45,
        },
        {
            accountId: "102911050",
            country: "Spain",
            countryFlag: "/images/country-icons/chf.svg",
            month: "Aug, 2024",
            totalDrawdown: "$15,000",
            maximumDrawdown: 400,
            breachesType: "Arbitrage Breaches",
            breaches: 30,
        },
    ];

    const headcells = [
        {
            id: "accountId",
            label: "Account ID",
            getCell: (row) => row.accountId,
        },
        {
            id: "country",
            label: "Country",
            getCell: (row) => (
                <Stack direction="row" alignItems="center">
                    <Avatar src={row.countryFlag} sx={{ width: 20, height: 20, mr: 1 }} />
                    <Typography>{row.country}</Typography>
                </Stack>
            ),
        },
        {
            id: "month",
            label: "Month",
            getCell: (row) => row.month,
        },
        {
            id: "totalDrawdown",
            label: "Total Drawdown",
            getCell: (row) => row.totalDrawdown,
        },
        {
            id: "maximumDrawdown",
            label: "Maximum Drawdown",
            getCell: (row) => `$${row.maximumDrawdown}`,
        },
        {
            id: "breachesType",
            label: "Breaches Type",
            getCell: (row) => row.breachesType,
        },
        {
            id: "breaches",
            label: "Breaches",
            getCell: (row) => row.breaches,
        }
    ];

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.color.bg5,
                border: '1px solid',
                borderColor: (theme) => theme.palette.color.strockborder,
                p: '10px',
                borderRadius: '16px',
                mt: 3,
            }}
        >
            <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Risk Summary
                </Typography>
                <Stack direction="row" gap={2}>
                    <RoundFormSelect
                        options={[{ value: "monthly", label: "Monthly" }]}
                        defaultValue="monthly"
                    />
                    <ExportSection />
                </Stack>
            </Stack>

            <Box sx={{ overflowX: "auto", width: "100%" }}>
                <CustomTable
                    headcells={headcells}
                    rows={data}
                />
            </Box>
        </Box>
    );
};

export default RiskSummaryTable;
