import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import NavItem2 from "./NavItem2";
import { Typography } from "@mui/material";
import SmallTreeIcon from "../../Icons/SmallTree";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            px: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function NavGroup2({ navItem }) {
  return (
    <Box sx={{ width: "100%", overflowY: "hidden" }}>
      {navItem.children.map((item, index) => (
        <Box
          sx={{
            px: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          {item.path ? (
            <NavItem2 item={item} key={index} sx={{ px: 0.5 }} />
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 1.5,
                alignItems: "center",
                px: 1,
                height: "40px",
              }}
            >
              {item.icon && <item.icon size={18} />}
              <Typography
                variant="heading_400"
                sx={{
                  fontWeight: "400",
                  color: (theme) => theme.palette.color.secondary,
                }}
              >
                {item.title}
              </Typography>
            </Box>
          )}

          {item.children?.map((child, index) => (
            <Box sx={{ paddingLeft: "16px", position: "relative" }}>
              <Box sx={{ position: "absolute", top: "-8px" }}>
                <SmallTreeIcon />
              </Box>
              <Box sx={{ paddingLeft: "15px", width: "100%" }}>
                <NavItem2 item={child} key={index} />
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}
