import { useTheme } from "@mui/material";
import * as React from "react";

const IPAddressIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.92139 7.48047V10.8415"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.1616 10.843V9.72266"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14.3547 17.9102C15.7325 18.2101 16.643 18.7287 16.643 19.3182C16.643 20.2463 14.3859 20.9986 11.6016 20.9986C8.81723 20.9986 6.56006 20.2463 6.56006 19.3182C6.56006 18.7287 7.47059 18.2101 8.84837 17.9102"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M11.6017 3C14.6954 3 17.2033 5.50794 17.2033 8.60166C17.2033 9.92522 16.7443 11.1416 15.9767 12.1002L11.6017 17.639L7.22658 12.1002C6.45904 11.1416 6 9.92522 6 8.60166C6 5.50794 8.50794 3 11.6017 3Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M13.562 9.16097H12.1616V7.48047H13.562C14.0261 7.48047 14.4023 7.85668 14.4023 8.32072C14.4023 8.78476 14.0261 9.16097 13.562 9.16097Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default IPAddressIcon;