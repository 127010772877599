import {
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import ChevronRightIcon from "../../components/Icons/ChevronRight";
import MetatraderIcon from "../../components/Icons/MetaTrader";
import FolderIcon from "../../components/Icons/Folder";
import {
  Facebook,
  Instagram,
  PlayArrow,
  X,
  YouTube,
} from "@mui/icons-material";

const iconStyle = {
  fontSize: "18px",
  color: (theme) => theme.palette.color.secondary,
  cursor: "pointer",
  transition: "all 0.3s",
  "&:hover": {
    fontSize: "20px",
  },
};

const items = [
  {
    tooltip: "KYC",
    icon: (
      <Box sx={{ width: "29px" }}>
        <img src="/images/kyc.avif" alt="kyc" width="100%" />
      </Box>
    ),
  },
  {
    tooltip: "Chargebee",
    icon: (
      <Box sx={{ width: "29px" }}>
        <img src="/images/kyc.avif" alt="kyc" width="100%" />
      </Box>
    ),
  },
  {
    tooltip: "Stripe",
    icon: (
      <Box sx={{ width: "29px" }}>
        <img src="/images/kyc.avif" alt="kyc" width="100%" />
      </Box>
    ),
  },
  {
    tooltip: "Active Campaign",
    icon: (
      <Box sx={{ width: "29px" }}>
        <img src="/images/kyc.avif" alt="kyc" width="100%" />
      </Box>
    ),
  },
  {
    tooltip: "Vuetra",
    icon: (
      <Box sx={{ width: "29px" }}>
        <img src="/images/kyc.avif" alt="kyc" width="100%" />
      </Box>
    ),
  },
  {
    tooltip: "Tradelocker",
    icon: (
      <Box sx={{ width: "29px" }}>
        <img src="/images/kyc.avif" alt="kyc" width="100%" />
      </Box>
    ),
  },
  {
    tooltip: "Metatrader",
    icon: <MetatraderIcon />,
  },
  {
    tooltip: "Matchtrade",
    icon: (
      <Box sx={{ width: "29px" }}>
        <img src="/images/kyc.avif" alt="kyc" width="100%" />
      </Box>
    ),
  },
];

const DocsHome = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "20px",
          height: "244px",
          display: "flex",
          alignItems: "center",
          gap: "24px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "117px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ p: "12px", width: "43px", pt: "24px" }}>
            <img src="/images/logo.avif" alt="logo" width="100%" />
          </Box>
        </Box>
        <Stack
          spacing={2}
          sx={{
            width: "397px",
          }}
        >
          <Typography
            sx={{
              fontSize: "26px",
              fontWeight: "600",
            }}
          >
            Unifunded Documentation
          </Typography>
          <Typography variant="caption_500" sx={{ lineHeight: "24px" }}>
            Learn how to get up and running with Unifunded through tutorials,
            APIs and platform resources.
          </Typography>
        </Stack>
        <Box
          sx={{
            position: "absolute",
            right: "20px",
            height: "261px",
            left: "620px",
            bottom: "-131px",
            padding: "16px",
            zIndex: "2",
            // width: "100%",
            background: (theme) => theme.palette.color.borderpure,
            borderRadius: "18px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h5">Integrations</Typography>
            <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <Typography
                variant="caption_500"
                sx={{
                  color: (theme) => theme.palette.color.active,
                  fontWeight: "400",
                }}
              >
                Explore more
              </Typography>
              <ChevronRightIcon stroke={theme.palette.color.active} />
            </Box>
          </Box>
          <Grid container spacing={2} sx={{ height: "100%", pt: "24px" }}>
            {items.map((item) => (
              <Grid item xs={3} sx={{ height: "50%" }}>
                <Tooltip title={item.tooltip} placement="top">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      background: (theme) => theme.palette.color.bg5,
                      borderRadius: "18px",
                      cursor: "pointer",
                    }}
                  >
                    {item.icon}
                  </Box>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          pt: "170px",
          background: (theme) => theme.palette.color.bg2,
        }}
      >
        <Stack spacing={4} sx={{ width: "100%" }}>
          <CRMCards />
          <Divider />
          <PlayCards title="Policies" />
          <Divider />
          <PlayCards title="Integrations" />
          <Divider />
          <CRMCards />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <Box sx={{ p: "12px", width: "43px" }}>
                <img src="/images/logo.avif" alt="logo" width="100%" />
              </Box>
              {[
                "Privacy Policy",
                "Risk Disclosure",
                "Performance Disclosure",
                "Trading Disclaimer",
              ].map((item) => (
                <Typography
                  sx={{
                    "& a": {
                      fontSize: "11.5px",
                      color: (theme) => theme.palette.color.secondary,
                      fontWeight: "400",
                      textDecoration: "none",
                      "&:hover": {
                        color: (theme) => theme.palette.color.primary,
                        textDecoration: "underline",
                      },
                    },
                  }}
                >
                  <a href="/">{item}</a>
                </Typography>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Facebook sx={iconStyle} />
              <X
                sx={{
                  ...iconStyle,
                  fontSize: "16px",
                  "&:hover": {
                    fontSize: "18px",
                  },
                }}
              />
              <Instagram sx={iconStyle} />
              <YouTube sx={iconStyle} />
              {/* <Discord /> */}
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default DocsHome;

const CRMCards = () => {
  const theme = useTheme();
  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "20px",
        }}
      >
        <Typography variant="h2">CRM</Typography>
        <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <Typography
            variant="caption_500"
            sx={{
              color: (theme) => theme.palette.color.active,
              fontWeight: "400",
            }}
          >
            Explore more
          </Typography>
          <ChevronRightIcon stroke={theme.palette.color.active} />
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ pr: "24px", pl: "8px", width: "100%" }}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs={12} md={6} lg={4}>
            <Card
              sx={{
                padding: "28px",
                borderRadius: "18px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                background: (theme) => theme.palette.color.borderpure,
                border: (theme) =>
                  `1px solid ${theme.palette.color.borderpure}`,
                cursor: "pointer",

                "&:hover": {
                  border: (theme) => `1px solid ${theme.palette.color.active}`,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Box
                  sx={{
                    height: "36px",
                    width: "36px",
                    background: (theme) => theme.palette.color.border,
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FolderIcon stroke={theme.palette.color.primary} />
                </Box>
                <Typography variant="h4">Title</Typography>
              </Box>
              <Typography variant="caption_500" sx={{ lineHeight: "24px" }}>
                Learn how to get up and running with Unifunded through
                tutorials, APIs and…
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

const PlayCards = ({ title }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        px: "20px",
      }}
    >
      <Stack spacing={2} sx={{ width: "100%", flexGrow: "0", pr: "24px" }}>
        <Typography variant="h2">{title}</Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
          }}
        >
          Learn how to get up and running with Unifunded through tutorials, APIs
          and…
        </Typography>
        <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <Typography
            variant="caption_500"
            sx={{
              color: (theme) => theme.palette.color.active,
              fontWeight: "400",
            }}
          >
            Explore more
          </Typography>
          <ChevronRightIcon stroke={theme.palette.color.active} />
        </Box>
      </Stack>
      <Box
        sx={{
          width: "683px",
          flexShrink: "0",
          height: "100%",
        }}
      >
        <Grid container sx={{ pr: "24px", pl: "8px", width: "100%" }}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Grid item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  height: "100px",
                }}
              >
                <Box
                  sx={{
                    height: "36px",
                    width: "36px",
                    background: (theme) => theme.palette.color.border,
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FolderIcon stroke={theme.palette.color.primary} />
                </Box>
                <Typography variant="heading">Title</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            overflowX: "auto",
          }}
        >
          {Array.from({ length: 4 }).map((_, index) => (
            <Box
              sx={{
                width: "273px",
                height: "186px",
                background: (theme) => theme.palette.color.borderpure,
                borderRadius: "18px",
                display: "flex",
                flexDirection: "column",
                flexShrink: "0",
                "&:hover": {
                  "& .play-button": {
                    border: (theme) =>
                      `1px solid ${theme.palette.color.active}`,
                  },
                },
              }}
            >
              <Box
                sx={{
                  p: "4px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  className="play-button"
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: (theme) => theme.palette.color.bg5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "18px",
                    transition: "all 0.3s",
                    border: (theme) =>
                      `1px solid ${theme.palette.color.borderpure}`,
                    cursor: "pointer",
                  }}
                >
                  <PlayArrow />
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "12px 12px 16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack spacing={1}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Title
                  </Typography>
                  <Typography variant="body3">
                    Collection · sub-collection
                  </Typography>
                </Stack>
                <Typography
                  variant="body3"
                  sx={{
                    padding: "2px 12px",
                    background: (theme) => theme.palette.color.lightgray,
                    borderRadius: "8px",
                  }}
                >
                  11:05
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
