import { useTheme } from "@mui/material";
import * as React from "react";

const HedgeIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_3983_32609"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={18}
        height={18}
      >
        <path d="M3 3H21V21H3V3Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3983_32609)">
        <path
          d="M12 3.70312L5.10938 6.56404V10.0419C5.10938 14.5427 7.83289 18.596 12 20.2969C16.1671 18.596 18.8906 14.5427 18.8906 10.0419V6.56404L12 3.70312Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.25781 11.4412L11.2228 13.4062L14.7422 9.88693"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default HedgeIcon;