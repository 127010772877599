import { Box, Typography } from "@mui/material";
import React from "react";
import { isPathMatching } from "../../utils/getNavData";
import ChevronRightIcon from "../Icons/ChevronRight";
import { useNavigate } from "react-router-dom";

const NavItem = ({ item, active, children, sx = {} }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        p: "3px",
        pr: "16px",
        paddingLeft: "16px",
        borderLeft: `2px solid transparent`,
        ...(active && {
          borderLeft: (theme) => `2px solid ${theme.palette.color.active}`,
          background: (theme) => theme.palette.color.lightgray,
        }),
        ":hover": {
          pr: "12px",
        },
        transition: "all 0.5s ",
        cursor: "pointer",
        my: "1px",
        height: "44px",
        ...sx,
      }}
      onClick={() => navigate(item.to || item.path)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: (theme) =>
              theme.palette.color[active ? "primary" : "secondary"],
            mr: 1,
            ml: 0,
          }}
        >
          {item.icon && <item.icon size={14} />}
        </Box>
        <Typography
          variant="caption"
          sx={{
            ...(active && {
              color: (theme) => theme.palette.color.primary,
              lineHeight: "24px",
            }),
          }}
        >
          {item.title}
        </Typography>
      </Box>
      {children && <ChevronRightIcon />}
    </Box>
  );
};

export default NavItem;
