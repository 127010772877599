import React, { useState } from 'react';
import { Box, Stack, Typography, Button, Tabs, Tab } from '@mui/material';
import dayjs from "dayjs";
import ExportSection from '../../components/ui/ExportSection';
import CalendarPopup from '../../components/ui/CalendarPopup';
import SalesAndMarketing from '../../components/Statistics/Sales/SalesAndMarketing';
import InsightsAndNotifications from '../../components/Statistics/Sales/InsightsAndNotifications';
import TimeBased from '../../components/Statistics/Sales/TimeBased';
import AnalysisAndReporting from '../../components/Statistics/Sales/AnalysisAndReporting';

const StatisticsSales = () => {
    const [startDate, setStartDate] = useState(dayjs().startOf("week"));
    const [endDate, setEndDate] = useState(dayjs().endOf("week"));
    const [selectedTab, setSelectedTab] = useState("1");

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} p={3} pb={10}>
            <Stack bgcolor={(theme) => theme.palette.color.bg} direction="row" justifyContent="space-between" pl={1} position={"sticky"} zIndex={10} top={0} py={1}>
                <Typography variant="h5">Sales</Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                        fontSize: "11.5px",
                        color: (theme) => theme.palette.color.secondary,
                    }}
                >
                    <ExportSection />
                    <CalendarPopup
                        mainEndDate={endDate}
                        mainStartDate={startDate}
                        setMainEndDate={setEndDate}
                        setMainStartDate={setStartDate}
                    />
                    <Button variant="contained" size="small" disableElevation sx={{
                        py: "2px",
                        bgcolor: (theme) => theme.palette.color.lightgray,
                        borderRadius: "32px",
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.color.border,
                        color: (theme) => theme.palette.color.secondary,
                        ":hover": {
                            bgcolor: (theme) => theme.palette.color.lightgray,
                        }
                    }}>
                        <img src="/svg/stars.svg" alt="Stars" style={{ width: 24, height: 24, marginRight: 8 }} />
                        AI Analyze
                    </Button>
                </Stack>
            </Stack>

            <Box sx={{ width: 'fit-content' }}>
                <Stack direction="row">
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                    >
                        <Tab value="1" label="Sales and marketing" />
                        <Tab value="2" label="Insights and notifications" />
                        <Tab value="3" label="Time-based" />
                        <Tab value="4" label="Analysis and Reporting" />
                    </Tabs>
                </Stack>
            </Box>

            {selectedTab === "1" && (
                <Stack rowGap={1} mt={2}>
                    <SalesAndMarketing />
                </Stack>
            )}
            {selectedTab === "2" && (
                <Stack rowGap={1} mt={2}>
                    <InsightsAndNotifications />
                </Stack>
            )}
            {selectedTab === "3" && (
                <Stack rowGap={1} mt={2}>
                    <TimeBased />
                </Stack>
            )}
            {selectedTab === "4" && (
                <Stack rowGap={1}>
                    <AnalysisAndReporting />
                </Stack>
            )}

        </Box>
    );
};

export default StatisticsSales;