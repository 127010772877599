import { useTheme } from "@mui/material";
import * as React from "react";

const ClearIcon = ({ stroke, strokeWidth = 1.5, ...props }) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      {...props}
    >
      <path
        stroke={stroke || theme.palette.color.secondary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m8 16.325 6.173 2.925 1.431-2.965a3.207 3.207 0 0 0-1.989-4.473M8 16.325 4.75 15c1.982 0 3.069-1.29 3.91-2.502.779-1.125 2.116-1.515 3.43-1.132l1.525.447M8 16.325c.72.341 2.5-.325 3.25-1.575m2.365-2.938L18.25 4.75"
      />
    </svg>
  );
};

export default ClearIcon;
