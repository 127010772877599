import React from "react";
import { Box, Tooltip } from "@mui/material";

import { BiLogoDeviantart } from "react-icons/bi";
import { FaArrowRightLong } from "react-icons/fa6";
import Chat from "./Chat";
import ProfileSection from "./ProfileSection";
import { navItems, navItems2 } from "../../../static/navItems";
import { useLocation, useNavigate } from "react-router-dom";

export default function SideBar() {
  const { pathname } = useLocation();
  const isPathMatching = (path) => {
    if (path === "/") return pathname === path;
    return pathname.startsWith(path);
  };

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: { xs: "none", lg: "flex" },
        alignItems: "start",
        flexGrow: "0",
        flexShrink: "0",
        flexDirection: "row",
        gap: "0",
        minHeight: "100vh",
        overflow: "visible",
        p: "0",
        position: "relative",
        width: "fit-content",
        // borderRight: (theme) => `2px solid ${theme.palette.color.border}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "space-between",
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          p: "0",
          height: "auto",
          overflow: "visible",
          width: "fit-content",
          color: (theme) => theme.palette.color.secondary,
          backgroundColor: (theme) => theme.palette.color.bg2,
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: "0",
          }}
        >
          <Box sx={{ p: "12px", width: "43px", pt: "24px" }}>
            <img src="/images/logo.avif" alt="logo" width="100%" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              gap: "1",
              backgroundColor: (theme) => theme.palette.color.bg2,
            }}
          >
            {navItems.map((item) => {
              const isActive = isPathMatching(item.path);
              return (
                <Tooltip
                  key={item.title}
                  title={item.tooltip}
                  placement="right"
                >
                  <Box
                    onClick={() => navigate(item.to || item.path)}
                    sx={{
                      display: "flex",

                      color: (theme) => theme.palette.color.secondary,
                      py: "12px",
                      width: "43px",
                      alignItems: "center",
                      justifyContent: "center",
                      ...(isActive && {
                        backgroundColor: (theme) => theme.palette.color.gray,
                        borderLeft: (theme) =>
                          `2px solid ${theme.palette.color.active}`,
                        color: (theme) => theme.palette.color.primary,
                      }),
                      cursor: "pointer",
                    }}
                  >
                    <item.icon size={17} />
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "3",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: "0",
              flexShrink: "0",
              overflow: "visible",
              position: "relative",
            }}
          >
            <Box sx={{ p: "12px" }}>
              <FaArrowRightLong size={17} />
            </Box>
            {navItems2.map((item) => {
              const isActive = isPathMatching(item.path);
              return (
                <Tooltip
                  key={item.title}
                  title={item.tooltip}
                  placement="right"
                >
                  <Box
                    onClick={() => navigate(item.to || item.path)}
                    sx={{
                      display: "flex",

                      color: (theme) => theme.palette.color.secondary,
                      py: "12px",
                      width: "43px",
                      alignItems: "center",
                      justifyContent: "center",
                      ...(isActive && {
                        backgroundColor: (theme) => theme.palette.color.gray,
                        borderLeft: (theme) =>
                          `2px solid ${theme.palette.color.active}`,
                        color: (theme) => theme.palette.color.primary,
                      }),
                      cursor: "pointer",
                    }}
                  >
                    <item.icon size={16} />
                  </Box>
                </Tooltip>
              );
            })}
            <Chat />
          </Box>
          <ProfileSection />
        </Box>
      </Box>
    </Box>
  );
}
