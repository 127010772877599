import React from "react";
import { List, ListItem, Avatar, Box, Typography, Badge, Stack, styled, IconButton } from "@mui/material";

const StatusDot = styled(Box)(({ theme, status }) => ({
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: status === true ? "#2EE0A1" : "yellow",
    position: "absolute",
    bottom: 0,
    right: 0,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const MessageList = ({ messages, onSelect }) => {
    return (
        <List>
            {messages.map((message, index) => (
                <ListItem
                    key={index}
                    button
                    onClick={() => onSelect(message)}
                    sx={{
                        display: 'flex',
                        alignItems: 'start',
                        justifyContent: 'space-between',
                        padding: '12px 24px',
                        borderBottom: '1px solid',
                        borderColor: (theme) => theme.palette.color.borderpure,
                        position: 'relative',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&:hover .action-icons': {
                            display: 'flex',
                        },
                        '&:hover .message-info': {
                            display: 'none',
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Stack direction="row" alignItems="center">
                            <Box sx={{ position: "relative", display: "inline-block" }}>
                                <Avatar alt="User Avatar" src={message.avatar} sx={{ width: 40, height: 40 }} />
                                {<StatusDot status={message.isOnline} />}
                            </Box>
                        </Stack>
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: "16px", mb: 0.5 }}>{message.name}</Typography>
                            <Typography variant="caption">{message.text}</Typography>
                        </Box>
                    </Box>
                    <Stack className="message-info" sx={{ direction: "column", gap: 2, alignItems: 'center', justifyContent: "start", textAlign: 'right' }}>
                        <Typography variant="caption">
                            {message.time}
                        </Typography>
                        {message.unread > 0 && (
                            <Badge
                                badgeContent={message.unread}
                                color="primary"
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: (theme) => theme.palette.color.green,
                                        color: '#000',
                                        fontWeight: 600,
                                    },
                                }}
                            />
                        )}
                    </Stack>
                    <Box
                        className="action-icons"
                        sx={{
                            display: 'none',
                            position: 'absolute',
                            top: '50%',
                            right: '16px',
                            transform: 'translateY(-50%)',
                            gap: '8px',
                        }}
                    >
                        <IconButton sx={{ color: '#fff', backgroundColor: (theme) => theme.palette.color.lightgray, '&:hover': { backgroundColor: (theme) => theme.palette.color.lightgray } }}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.2406 5.93182H1.75879V3.47727C1.75879 2.79948 2.30827 2.25 2.98606 2.25H15.0133C15.6911 2.25 16.2406 2.79948 16.2406 3.47727V5.93182Z" stroke="#CAD0DB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.0136 5.93164V14.5225C15.0136 15.2003 14.4641 15.7498 13.7863 15.7498H4.2136C3.53581 15.7498 2.98633 15.2003 2.98633 14.5225V5.93164" stroke="#CAD0DB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.77246 10.6362H10.227" stroke="#CAD0DB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </IconButton>
                        <IconButton sx={{ color: '#fff', backgroundColor: (theme) => theme.palette.color.lightgray, '&:hover': { backgroundColor: (theme) => theme.palette.color.lightgray } }}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.9375 5.0625L5.0625 12.9375" stroke="#CAD0DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.0625 5.0625L12.9375 12.9375" stroke="#CAD0DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </IconButton>
                    </Box>
                </ListItem>
            ))}
        </List>
    );
};

export default MessageList;