import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import InfoIcon from "@mui/icons-material/Info";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  TextareaAutosize,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { toggleOpen } from "../../../slices/openModalSlice";
import { Close, Done } from "@mui/icons-material";
import { TbMessageCircleCheck } from "react-icons/tb";
import { useChangePayoutStatusMutation } from "../../../slices/api/firmSlice";
import ArrowRightIcon from "../../../components/Icons/ArrowRight";
import CalendarIcon from "../../../components/Icons/Calendar";
import UserIcon from "../../../components/Icons/User";
import EditIcon from "../../../components/Icons/Edit";

const DotIcon = () => {
  return (
    <Box
      sx={{
        width: "24px",
        height: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "7px",
          height: "7px",
          borderRadius: "50%",
          background: (theme) => theme.palette.color.secondary,
          flexShrink: 0,
        }}
      ></Box>
    </Box>
  );
};
const items = [
  {
    title: "Profit",
    value: "10,000.00",
    currency: "US$",
  },
  {
    title: "Profit split",
    value: "80/20",
  },
  {
    title: "Today’s client",
    value: "8,000.00",
    currency: "US$",
  },
];

const details = [
  {
    label: "Profile number",
    value: "1050304506",
  },
  {
    label: "Customer name",
    value: "Jens van der Blij",
  },
  {
    label: "Account",
    value: "10506054",
  },
  {
    label: "Platform",
    value: "Vuetra",
  },
  {
    label: "Date of request",
    value: "June 15, 2024 11:05",
  },
  {
    label: "KYC",
    value: (
      <Stack
        direction="row"
        spacing={0.7}
        alignItems="center"
        justifyContent={"center"}
        sx={{
          borderRadius: "7px",
          width: "fit-content",
        }}
      >
        <Box
          sx={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            background: (theme) => theme.palette.color.green,
            flexShrink: 0,
          }}
        ></Box>
        <Typography
          sx={{
            color: (theme) => theme.palette.color.green,
            lineHeight: "16px",
            fontSize: "10px",
          }}
        >
          Verified
        </Typography>
      </Stack>
    ),
  },
  {
    label: "Payout method",
    value: "Rise",
  },
  {
    label: "Verification",
    value: (
      <Stack
        direction="row"
        spacing={0.7}
        alignItems="center"
        justifyContent={"center"}
        sx={{
          borderRadius: "7px",
          width: "fit-content",
        }}
      >
        <Box
          sx={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            background: (theme) => theme.palette.color.green,
            flexShrink: 0,
          }}
        ></Box>
        <Typography
          sx={{
            color: (theme) => theme.palette.color.green,
            lineHeight: "16px",
            fontSize: "10px",
          }}
        >
          Verified
        </Typography>
      </Stack>
    ),
  },
];

const details1 = [
  {
    label: "Challenge type",
    value: "Type",
  },
  {
    label: "In. balance",
    value: "100K",
  },
  {
    label: "Open trades",
    value: "-",
  },
  {
    label: "Platform",
    value: "MT4",
  },
  {
    label: "Floating PNL",
    value: "-",
  },
  {
    label: "Open trades",
    value: "None",
  },
];

const approvedDetails = [
  {
    label: "Approval date",
    value: "June 15, 2024 11:05",
    icon: CalendarIcon,
  },
  {
    label: "Agent Responsible",
    value: "Jens",
    icon: UserIcon,
  },
  {
    label: "Date of payment",
    value: "June 15, 2024 11:05",
    icon: CalendarIcon,
  },
  {
    label: "Status",
    value: (
      <Stack direction="row" spacing={0.7} alignItems="center">
        <Box
          sx={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            background: (theme) => theme.palette.color.green,
            flexShrink: 0,
          }}
        ></Box>
        <Typography
          sx={{
            color: (theme) => theme.palette.color.green,
            lineHeight: "16px",
            fontSize: "10px",
          }}
        >
          Paid
        </Typography>
      </Stack>
    ),
    icon: DotIcon,
  },
  {
    label: "Message for client",
    icon: EditIcon,
    value1: (
      <TextField
        sx={{
          width: "100%",
          marginLeft: "24px",
          background: (theme) => theme.palette.color.borderpure,
          "& .MuiInputBase-root": {
            border: "none",
            outline: "none",
            color: (theme) => theme.palette.color.secondary,
          },
          "& fieldset": {
            border: "none",
          },
        }}
        multiline
        rows={4}
      />
    ),
    colspan: 12,
  },
];
const deniedDetails = [
  {
    label: "Denial date",
    value: "June 15, 2024 11:05",
    icon: CalendarIcon,
  },
  {
    label: "Customer name",
    value: "Jens",
    icon: UserIcon,
  },
  {
    label: "Contact client",
    value: "Yes",
    icon: CalendarIcon,
  },
  {
    label: "Message for client",
    icon: EditIcon,
    value1: (
      <TextField
        sx={{
          width: "100%",
          marginLeft: "24px",
          background: (theme) => theme.palette.color.borderpure,
          "& .MuiInputBase-root": {
            border: "none",
            outline: "none",
            color: (theme) => theme.palette.color.secondary,
          },
          "& fieldset": {
            border: "none",
          },
        }}
        multiline
        rows={4}
      />
    ),
    colspan: 12,
  },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const statuses = [
  { value: "pending", label: "Pending", color: "yellow" },
  { value: "approved", label: "Approved", color: "green" },
  { value: "denied", label: "Denied", color: "red" },
];

const ViewPayout = ({ selected, setSelected }) => {
  const [page, setPage] = React.useState(0);
  const { open } = useSelector((state) => state.openModal);
  const [comment, setComment] = React.useState("");
  const [value, setValue] = React.useState(0);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const [changeStatus] = useChangePayoutStatusMutation();

  const handleSubmit = async (data) => {
    await changeStatus(data);
    dispatch(toggleOpen());
  };

  const status = statuses.find((status) => status.value === selected?.status);

  const isPending = selected?.status === "pending";

  return (
    <React.Fragment>
      <Drawer
        sx={
          {
            // borderRadius: "14px 0 14px 0",
            // overflow: "hidden",
          }
        }
        open={open}
        onClose={() => {
          dispatch(toggleOpen());
          setPage(0);
          setComment("");
        }}
        anchor="right"
      >
        <Box
          sx={{
            position: "fixed",
            top: "18px",
            left: "760px",
          }}
        >
          <IconButton
            sx={{
              width: "56px",
              height: "56px",
              background: (theme) => theme.palette.color.borderpure,
              transition: "all 0.5s",
              "&:hover": {
                rotate: "180deg",
              },
            }}
            onClick={() => dispatch(toggleOpen())}
          >
            <Close
              sx={{
                color: (theme) => theme.palette.color.primary,
              }}
            />
          </IconButton>
        </Box>
        <Paper
          sx={{
            width: "720px",
            height: "100vh",
            overflowY: "auto",
            background: (theme) => theme.palette.color.bg2,
          }}
        >
          <Box sx={{ px: "20px" }}>
            <Box
              sx={{
                background: (theme) => theme.palette.color.bg2,
                py: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Stack spacing={0.5}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="h3">Payout request</Typography>
                  <Stack
                    direction="row"
                    spacing={0.7}
                    alignItems="center"
                    justifyContent={"center"}
                    sx={{
                      borderRadius: "7px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        width: "7px",
                        height: "7px",
                        borderRadius: "50%",
                        background: (theme) =>
                          theme.palette.color[status?.color || "yellow"],
                        flexShrink: 0,
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        color: (theme) =>
                          theme.palette.color[status?.color || "yellow"],
                        lineHeight: "16px",
                        fontSize: "10px",
                      }}
                    >
                      {status?.label}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography
                  variant="caption2"
                  sx={{
                    color: (theme) => theme.palette.color.secondary,
                  }}
                >
                  June 15, 2024 11:05 - 10506054
                </Typography>
              </Stack>
              <Grid container spacing={2} sx={{ width: "100%" }}>
                {items.map((item) => (
                  <Grid item xs={4}>
                    <Card
                      sx={{
                        width: "100%",
                        border: (theme) =>
                          `1px solid ${theme.palette.color.border}`,
                        background: (theme) => theme.palette.color.borderpure,
                        backgroundSize: "cover",
                        opacity: 0.8,
                        borderRadius: "16px",
                        overflow: "hidden",
                        padding: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <Stack spacing={2}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography
                            variant="heading"
                            sx={{
                              fontWeight: "400",
                              color: (theme) => theme.palette.color.secondary,
                            }}
                          >
                            {item.title}
                          </Typography>
                        </Stack>

                        <Typography
                          variant="h3"
                          sx={{
                            "& span": {
                              color: (theme) => theme.palette.color.secondary,
                            },
                          }}
                        >
                          <span>{item.currency}</span> {item.value}
                        </Typography>
                      </Stack>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
            {selected?.status === "approved" && (
              <Grid
                sx={{
                  width: "100%",
                  padding: "12px 6px",
                  border: (theme) => `1px solid ${theme.palette.color.green}`,
                  borderRadius: "16px",
                  overflow: "hidden",
                  mb: "20px",
                }}
                container
              >
                {approvedDetails.map((item) => (
                  <Grid item xs={item.colspan || 6}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{
                        padding: "16px",
                      }}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{
                          flexShrink: 0,
                        }}
                      >
                        {<item.icon stroke={theme.palette.color.secondary} />}
                        <Typography variant="caption2">{item.label}</Typography>
                      </Stack>
                      {item.value1 ? (
                        item.value1
                      ) : (
                        <Typography
                          variant="caption2"
                          sx={{
                            color: (theme) => theme.palette.color.secondary,
                          }}
                        >
                          {item.value}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            )}
            {selected?.status === "denied" && (
              <Grid
                sx={{
                  width: "100%",
                  padding: "12px 6px",
                  border: (theme) => `1px solid ${theme.palette.color.red}`,
                  borderRadius: "16px",
                  overflow: "hidden",
                  mb: "20px",
                }}
                container
              >
                {deniedDetails.map((item) => (
                  <Grid item xs={item.colspan || 6}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{
                        padding: "16px",
                      }}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{
                          flexShrink: 0,
                        }}
                      >
                        {<item.icon stroke={theme.palette.color.secondary} />}
                        <Typography variant="caption2">{item.label}</Typography>
                      </Stack>
                      {item.value1 ? (
                        item.value1
                      ) : (
                        <Typography
                          variant="caption2"
                          sx={{
                            color: (theme) => theme.palette.color.secondary,
                          }}
                        >
                          {item.value}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid
              container
              sx={{
                width: "100%",
                background: (theme) => theme.palette.color.borderpure,
                borderRadius: "16px",
                overflow: "hidden",
                padding: "12px 6px",
              }}
            >
              {details.map((item) => (
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      padding: "16px",
                    }}
                  >
                    <Typography variant="caption2">{item.label}</Typography>
                    <Typography
                      variant="caption2"
                      sx={{
                        color: (theme) => theme.palette.color.secondary,
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ py: "20px" }}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Acc. Overview" {...a11yProps(0)} />
                  <Tab label="Objectives" {...a11yProps(1)} />
                  <Tab label="Rules" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <Box
                sx={{
                  background: (theme) => theme.palette.color.borderpure,
                  padding: "20px",
                  m: "12px",
                  borderRadius: "16px",
                }}
              >
                <Stack direction="row" spacing={4} alignItems="center">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography variant="caption2">Profile number</Typography>
                    <Typography
                      variant="caption2"
                      sx={{
                        color: (theme) => theme.palette.color.secondary,
                      }}
                    >
                      Profile number
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      flexShrink: 0,
                    }}
                  >
                    <ArrowRightIcon width="20" height="20" />
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography variant="caption2">Profile number</Typography>
                    <Typography
                      variant="caption2"
                      sx={{
                        color: (theme) => theme.palette.color.secondary,
                      }}
                    >
                      Profile number
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Divider sx={{ my: 3 }} />
              <Grid
                container
                sx={{
                  width: "100%",
                  borderRadius: "16px",
                  overflow: "hidden",
                }}
              >
                {details1.map((item) => (
                  <Grid item xs={6}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        padding: "16px",
                      }}
                    >
                      <Typography variant="caption2">{item.label}</Typography>
                      <Typography
                        variant="caption2"
                        sx={{
                          color: (theme) => theme.palette.color.secondary,
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Stack
            spacing={1}
            sx={{
              padding: "6px 32px",
              background: (theme) => theme.palette.color.gray,
              position: "sticky",
              height: "60px",
              bottom: 0,
            }}
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
          >
            <Button
              sx={{
                background: "transparent",
                color: (theme) => theme.palette.color.secondary,
                borderRadius: "9px",
                fontSize: "13px",
                fontWeight: "500",
                ":hover": {
                  background: "transparent",
                },
              }}
              onClick={() => {
                if (isPending)
                  handleSubmit({ id: selected?.id, status: "denied" });
                else dispatch(toggleOpen());
              }}
            >
              {!isPending ? "Close" : "Deny"}
            </Button>

            <Button
              sx={{
                borderRadius: "18px",
                fontSize: "13px",
                fontWeight: "500",
                px: "24px",

                boxShadow: "none",
              }}
              variant="contained"
              onClick={() => {
                if (isPending)
                  handleSubmit({ id: selected?.id, status: "approved" });
                else dispatch(toggleOpen());
              }}
            >
              {!isPending ? "Done" : "Approve"}
            </Button>
          </Stack>
        </Paper>
      </Drawer>
    </React.Fragment>
  );
};

export default ViewPayout;
