import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import React, { useState } from "react";
import CustomSwitch from "./Switch";

const RadioContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

const HiddenRadio = styled("input")({
  position: "absolute",
  opacity: 0,
  cursor: "pointer",
  height: 0,
  width: 0,
});

const CustomRadio = styled("div")(({ theme, checked }) => ({
  height: "26px",
  width: "26px",

  borderRadius: "50%",
  border: `1px solid ${theme.palette.color.lightgray}`,
  padding: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:after": {
    content: '""',
    display: checked ? "block" : "none",
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    backgroundColor: theme.palette.color.active,
  },
}));

const Label = styled(Typography)(({ theme, checked }) => ({
  marginLeft: "8px",
  color: theme.palette.color[checked ? "primary" : "secondary"],
  fontSize: "13px",
  fontWeight: "500",
  lineHeight: "2em",
}));

const CustomRadioButton = ({ label, checked, onChange }) => (
  <RadioContainer onClick={onChange}>
    <HiddenRadio type="radio" checked={checked} onChange={onChange} />
    <CustomRadio checked={checked} />
    <Label checked={checked}>{label}</Label>
  </RadioContainer>
);

const FormRadio = ({
  label,
  label2,
  helperTexts,
  sx,
  checkbox,
  options,
  dir = "row",
  value,
  onChange,
  ...rest
}) => {
  const [selectedValue, setSelectedValue] = useState(options[0].value);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: dir,
        alignItems: "center",
        gap: "24px",
      }}
    >
      {options.map((option) => (
        <CustomRadioButton
          label={option.label}
          checked={value === option.value}
          onChange={() => {
            setSelectedValue(option.value);
            if (onChange) onChange(option.value);
          }}
          {...rest}
        />
      ))}
    </Box>
  );
};

export default FormRadio;
