import { ChevronRight, Home } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";

const Breadcrumbs = ({ path, title }) => {
  return (
    <Stack direction="row" spacing={0.4} alignItems="center">
      <Home sx={{fontSize: "16px", color: (theme) => theme.palette.color.secondary }}/>
      {path.split("/").map((item, index) => (
        <React.Fragment key={index}>
          <ChevronRight
            sx={{
              fontSize: "16px",
              color: (theme) => theme.palette.color.secondary,
            }}
          />
          <Typography variant="body3">{item}</Typography>
        </React.Fragment>
      ))}
      <ChevronRight
        sx={{
          fontSize: "16px",
          color: (theme) => theme.palette.color.secondary,
        }}
      />
      <Typography variant="body1">{title}</Typography>
    </Stack>
  );
};

export default Breadcrumbs;