import React from 'react'
import PerformanceAndBenchmarksTable from '../../components/Risk-Management/Performance-And-Benchmarks/PerformanceAndBenchmarksTable'
import { Stack, Typography } from '@mui/material'
import SuccessfulUnsuccessfulTable from '../../components/Risk-Management/Performance-And-Benchmarks/SuccessfulUnsuccessfulTable'

const PerformanceAndBenchmarks = () => {
  return (
    <Stack sx={{ width: '100%', p: 3, pb: 10, spacing: 4 }}>
        <Typography variant="h3" sx={{ fontSize: "18px", fontWeight: "500"}}>Performance and Benchmarks</Typography>
        <PerformanceAndBenchmarksTable />
        <SuccessfulUnsuccessfulTable />
    </Stack>
  )
}

export default PerformanceAndBenchmarks