import { alpha, Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";

const AccountVerification = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.color.bg,
                overflow: "hidden",
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: 2,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    zIndex: 2,
                }}
            >
                <img
                    src="/svg/loginIcon.svg"
                    alt="UNI"
                    style={{ width: "78px", height: "29px" }}
                />
                <Box
                    sx={{
                        gap: "10px",
                        alignItems: "center",
                        display: isSmall ? "none" : "flex"
                    }}
                >
                    <Button size='small'
                        sx={{
                            height: "36px",
                            borderRadius: "32px",
                            bgcolor: (theme) => theme.palette.color.bg5,
                            color: (theme) => theme.palette.color.secondary,
                            border: (theme) => `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                            ":hover": { background: (theme) => theme.palette.color.bg5 }
                        }}>
                        Don't have an account?
                    </Button>
                    <Button size='small'
                        sx={{
                            height: "36px",
                            borderRadius: "32px",
                            bgcolor: (theme) => theme.palette.color.active,
                            color: (theme) => theme.palette.color.primary,
                            border: (theme) => `1px solid ${alpha(theme.palette.color.active, 0.15)}`,
                            ":hover": { background: (theme) => theme.palette.color.active }
                        }}>
                        Contact sales
                    </Button>
                </Box>
            </Box>
            <Stack
                spacing={7}
                alignItems="center"
                sx={{
                    background: "#0b0d0f",
                    padding: "24px",
                    width: "520px",
                    borderRadius: "12px",
                    border: `1px solid rgba(21, 25, 31, 0.65)`,
                    zIndex: 2,
                }}
            >
                <Stack spacing={2}>
                    <Typography variant="h1" sx={{ fontSize: "24px",  textAlign: "center", }}>Verify your Account</Typography>
                    <Stack spacing={1}>
                        <Typography variant="body3" sx={{ textAlign: "center", fontSize: "14px" }}>
                            Hi Jens, Please verify your Account,
                        </Typography>
                        <Typography variant="body3" sx={{ textAlign: "center", fontSize: "14px" }}>
                            The verification link sent to example@site.com
                        </Typography>
                    </Stack>
                </Stack>


                <Stack spacing={3} width={"100%"}>

                    <Typography
                        variant="body3"
                        sx={{
                            textAlign: "center",
                            "& a": {
                                textDecoration: "none",
                                color: (theme) => theme.palette.color.active,
                            },
                        }}
                    >
                        Didn't receive the email?{" "}
                        <a href="mailto://help@abc.com">
                            Resend
                        </a>
                    </Typography>

                    <Button
                        variant="contained"
                        sx={{ borderRadius: "32px", height: "50px", fontWeight: "15px" }}
                        fullWidth
                    >
                        Open Email
                    </Button>
                </Stack>
                <Typography
                    variant="body3"
                    mt={5}
                    sx={{
                        textAlign: "center",
                        "& a": {
                            textDecoration: "none",
                            color: (theme) => theme.palette.color.primary,
                        },
                    }}
                >
                    Question?{" "} Email us at{" "}
                    <a href="mailto://help@abc.com">
                        help@abc.com
                    </a>
                </Typography>
            </Stack>
            <Box
                sx={{
                    position: "absolute",
                    right: "-270px",
                    bottom: "-150px",
                    opacity: "0.1",
                    left: "544px",
                    zIndex: 1,
                }}
            >
                <img src="/images/background.png" alt="background" height="1166px" />
            </Box>
        </Box>
    );
};

export default AccountVerification;
