import { Box, Divider, Stack, Typography } from "@mui/material";

import NavItem2 from "./NavItem2";

export default function NavGroup1({ navItem }) {
  return (
    <Stack spacing={1.5} sx={{ pb: "12px" }}>
      <Typography variant="caption" sx={{ fontWeight: "400", px: "12px" }}>
        {navItem.title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "12px",
          gap: "3px",
        }}
      >
        {navItem.children
          .filter((subChild) => !subChild.notVisible)
          .map((subChild) => (
            <NavItem2 key={subChild.title} item={subChild} />
          ))}
      </Box>
      <Divider />
    </Stack>
  );
}
