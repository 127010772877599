import React from 'react'
import { Card, Stack, Typography, Tooltip } from '@mui/material'
import HelpIcon from "@mui/icons-material/Help";

const RiskManagementInfoCard = ({ item }) => {
    return (
        <Card
          sx={{
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
            height: '126px',
            p: '14px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 1
          }}
        >
          <Stack spacing={5}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption">{item.title}</Typography>
              <Tooltip title={item.tooltip} placement="top">
                <HelpIcon
                  sx={{
                    fontSize: "15px",
                    cursor: "pointer",
                    color: (theme) => theme.palette.color.secondary,
                  }}
                />
              </Tooltip>
            </Stack>
    
            <Typography variant="h2">{item.value}</Typography>
          </Stack>
        </Card>
      );
}

export default RiskManagementInfoCard