import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  Divider,
  TextField,
  FormControlLabel,
  Switch,
  Stack,
  useMediaQuery,
  styled,
  InputAdornment,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { ClockIcon } from "@mui/x-date-pickers";
import CalendarIcon from "../../../components/Icons/Calendar";
import EditIcon from "../../../components/Icons/Edit";
import TrashIcon from "../../../components/Icons/Trash";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const Availability = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [addTimeOff, setAddTimeOff] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [planOffDates, setPlanOffDates] = useState([]);
  const [availability, setAvailability] = useState({
    Sunday: { enabled: false, times: [{ start: "08:00", end: "18:00" }] },
    Monday: { enabled: true, times: [{ start: "08:00", end: "18:00" }] },
    Tuesday: { enabled: true, times: [{ start: "08:00", end: "18:00" }] },
    Wednesday: { enabled: true, times: [{ start: "08:00", end: "18:00" }] },
    Thursday: { enabled: true, times: [{ start: "08:00", end: "18:00" }] },
    Friday: { enabled: true, times: [{ start: "08:00", end: "18:00" }] },
    Saturday: { enabled: false, times: [{ start: "08:00", end: "18:00" }] },
  });

  const handleRemovePlanOffDate = (index) => {
    const updatedDates = [...planOffDates];
    updatedDates.splice(index, 1);
    setPlanOffDates(updatedDates);
  };

  const handleSwitchChange = (day) => {
    const newAvailability = {
      ...availability,
      [day]: {
        ...availability[day],
        enabled: !availability[day].enabled,
        times: !availability[day].enabled
          ? [{ start: "08:00", end: "18:00" }]
          : [],
      },
    };
    setAvailability(newAvailability);
  };

  const handleTimeChange = (day, index, type, event) => {
    const newTimes = availability[day].times.map((time, idx) => {
      if (idx === index) {
        return { ...time, [type]: event.target.value };
      }
      return time;
    });
    const updatedAvailability = {
      ...availability,
      [day]: {
        ...availability[day],
        times: newTimes,
      },
    };
    if (newTimes.length === 0 && availability[day].enabled) {
      updatedAvailability[day].times = [{ start: "08:00", end: "18:00" }];
    }
    setAvailability(updatedAvailability);
  };

  const removeTimeSlot = (day, index) => {
    const newTimes = availability[day].times.filter((_, idx) => idx !== index);
    const updatedAvailability = {
      ...availability,
      [day]: {
        ...availability[day],
        times: newTimes,
        enabled: newTimes.length > 0,
      },
    };
    setAvailability(updatedAvailability);
  };

  const addTimeSlot = (day) => {
    setAvailability({
      ...availability,
      [day]: {
        ...availability[day],
        times: [...availability[day].times, { start: "08:00", end: "18:00" }],
      },
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          px: "24px",
          py: "10px",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"end"}
        >
          <Typography variant="h3" gutterBottom>
            Availability
          </Typography>
          <Button
            size="small"
            sx={{
              p: "5px",
              px: "10px",
              borderRadius: "32px",
              bgcolor: (theme) => theme.palette.color.active,
              color: "white",
              ":hover": { background: (theme) => theme.palette.color.active },
            }}
            onClick={() => {}}
          >
            Save Changes
          </Button>
        </Stack>
        <Stack direction={"row"} width={"70%"} mt={1}>
          <Typography variant="caption">
            Let the team and customers know when you are out of office
          </Typography>
        </Stack>
      </Box>
      <Divider />

      <Box sx={{ px: "24px", py: "10px" }}>
        <Stack
          display={"flex"}
          spacing={2}
          p={3}
          borderRadius={"10px"}
          direction={isSmall ? "column" : "row"}
          justifyContent={"space-between"}
          bgcolor={(theme) => theme.palette.color.bg2}
        >
          <Box flex={1}>
            <Typography variant="h5">Default availability</Typography>
            <Typography variant="caption">
              Set when you regularly available. We will prevent any new tasks
              within these hours.
            </Typography>
          </Box>
          <Box flex={1} pl={isSmall ? 2 : 0}>
            {daysOfWeek.map((day) => (
              <Box key={day} sx={{ marginBottom: 3 }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <CustomSwitch
                      label={day}
                      checked={availability[day].enabled}
                      onChange={() => handleSwitchChange(day)}
                    />
                  </Grid>
                  {availability[day].enabled &&
                    availability[day].times.map((time, index) => (
                      <React.Fragment key={index}>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", alignItems: "center", mt: 1 }}
                        >
                          <TimePickerComponent
                            label="Start"
                            value={time.start}
                            onChange={(event) =>
                              handleTimeChange(day, index, "start", event)
                            }
                          />
                          <Typography sx={{ mx: 1 }}>-</Typography>
                          <TimePickerComponent
                            label="End"
                            value={time.end}
                            onChange={(event) =>
                              handleTimeChange(day, index, "end", event)
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              ml: 1,
                            }}
                          >
                            <IconButton
                              onClick={() => removeTimeSlot(day, index)}
                            >
                              <Remove sx={{ color: "#fff" }} />
                            </IconButton>
                            <IconButton onClick={() => addTimeSlot(day)}>
                              <Add sx={{ color: "#fff" }} />
                            </IconButton>
                          </Box>
                        </Grid>
                      </React.Fragment>
                    ))}
                </Grid>
              </Box>
            ))}
          </Box>
        </Stack>

        <Stack
          display={"flex"}
          mt={2}
          spacing={2}
          p={3}
          borderRadius={"10px"}
          direction={isSmall ? "column" : "row"}
          justifyContent={"space-between"}
          bgcolor={(theme) => theme.palette.color.bg2}
        >
          <Box flex={1}>
            <Typography variant="h5">Time Off Planning</Typography>
            <Typography variant="caption">
              Set the dates and times when you will be on vacation or
              unavailable. We will ensure that no new tasks are assigned to you
              during these periods.
            </Typography>
          </Box>
          <Box flex={1}>
            {planOffDates?.length !== 0 ? (
              <Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  pr={2}
                >
                  <Typography variant="body2" fontSize={"15px"}>
                    Start of calendar week
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ cursor: "pointer" }}
                    fontSize={"30px"}
                    onClick={() => {
                      /* Handle click event */
                    }}
                  >
                    +
                  </Typography>
                </Stack>
                {planOffDates.map((item, index) => (
                  <Stack
                    key={index}
                    direction={"row"}
                    justifyContent={"space-between"}
                    mt={1}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                  >
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <CalendarIcon stroke={"#8A96A6"} />
                      <Typography variant="body2" fontSize={"13px"}>
                        {item.start}
                      </Typography>
                      <Typography variant="body2" fontSize={"13px"}>
                        -
                      </Typography>
                      <Typography variant="body2" fontSize={"13px"}>
                        {item.end}
                      </Typography>
                    </Stack>
                    {hovered && (
                      <Stack direction={"row"} spacing={1}>
                        <Box onClick={() => handleRemovePlanOffDate(index)}>
                          <TrashIcon />
                        </Box>
                        <Box
                          onClick={() => {
                            setAddTimeOff(true);
                            setPlanOffDates([]);
                            setIsSave(true);
                          }}
                        >
                          <EditIcon />
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                ))}
              </Stack>
            ) : (
              <>
                {!addTimeOff ? (
                  <Stack
                    spacing={2}
                    direction={"row"}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setAddTimeOff(!addTimeOff);
                      setIsSave(false);
                    }}
                  >
                    <Typography variant="body2" fontSize={"24px"}>
                      +
                    </Typography>
                    <Typography variant="body2" fontSize={"15px"}>
                      Add time off
                    </Typography>
                  </Stack>
                ) : (
                  <Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      pr={2}
                    >
                      <Typography variant="body2" fontSize={"15px"}>
                        Add time off
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ cursor: "pointer" }}
                        fontSize={"30px"}
                        onClick={() => {
                          setAddTimeOff(!addTimeOff);
                        }}
                      >
                        -
                      </Typography>
                    </Stack>
                    <DateRangePicker
                      setPlanOffDates={setPlanOffDates}
                      setAddTimeOff={setAddTimeOff}
                      isSave={isSave}
                      setIsSave={setIsSave}
                    />
                  </Stack>
                )}
              </>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Availability;

const TimePickerComponent = ({ label, value, onChange }) => {
  return (
    <TextField
      variant="standard"
      type="time"
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 minutes
      }}
      sx={{
        width: "100%",
        borderRadius: "35px",
        backgroundColor: (theme) => theme.palette.color.bg3,
        borderColor: (theme) => theme.palette.color.bg2,
        "& .MuiInputBase-root": {
          borderRadius: "35px",
          p: 1,
          backgroundColor: (theme) => theme.palette.color.bg3,
          border: "1px solid",
          borderColor: (theme) => theme.palette.color.border,
        },
        "& .MuiInputBase-root::before": {
          borderColor: (theme) => theme.palette.color.bg2,
        },
        "& .MuiInputBase-root::hover": {
          borderColor: (theme) => theme.palette.color.bg2,
        },
        "& .MuiInputBase-root::after": {
          borderColor: (theme) => theme.palette.color.bg2,
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <ClockIcon sx={{ color: (theme) => theme.palette.color.active }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

const CustomSwitch = styled((props) => (
  <FormControlLabel
    control={
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
      />
    }
    label={props.label}
    sx={{
      ".MuiFormControlLabel-label": {
        marginLeft: "8px",
      },
    }}
  />
))(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        // backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        backgroundColor: theme.palette.color.green,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 15,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.color.secondary,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const DateRangePicker = ({
  setPlanOffDates,
  setAddTimeOff,
  isSave,
  setIsSave,
}) => {
  const [startDate, setStartDate] = useState("10/03/24");
  const [endDate, setEndDate] = useState("15/03/24");
  console.log(setStartDate, setEndDate);

  const handleSetDate = () => {
    setPlanOffDates((prevDates) => [
      ...prevDates,
      { start: startDate, end: endDate },
    ]);
    setAddTimeOff(false);
    setIsSave(false);
  };

  return (
    <Stack>
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          bgcolor: (theme) => theme.palette.color.darkgray,
          borderRadius: "10px",
          p: "10px",
        }}
      >
        <Stack
          direction={"row"}
          flex={1}
          px={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <Typography
              sx={{
                color: (theme) => theme.palette.color.active,
                fontSize: "13px",
              }}
              gutterBottom
            >
              Start
            </Typography>
            <Typography variant="body2" mt={1} fontSize={"13px"}>
              {startDate}
            </Typography>
          </Box>
          <CalendarIcon stroke={"#8A96A6"} />
        </Stack>
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Typography
            sx={{
              color: (theme) => theme.palette.color.active,
              fontSize: "13px",
            }}
            gutterBottom
          >
            Days
          </Typography>
          <Typography variant="body2" mt={1} fontSize={"13px"}>
            5
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          flex={1}
          px={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <Typography
              sx={{
                color: (theme) => theme.palette.color.active,
                fontSize: "13px",
              }}
              gutterBottom
            >
              End
            </Typography>
            <Typography variant="body2" mt={1} fontSize={"13px"}>
              {endDate}
            </Typography>
          </Box>
          <CalendarIcon stroke={"#8A96A6"} />
        </Stack>
      </Stack>
      <Stack alignItems={"flex-end"} mt={2}>
        <Button
          sx={{
            height: "33px",
            borderRadius: "8px",
            color: (theme) => theme.palette.color.darkText,
          }}
          onClick={handleSetDate}
        >
          {isSave ? "Save" : "Set date"}
        </Button>
      </Stack>
    </Stack>
  );
};
