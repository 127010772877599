import { useTheme } from "@mui/material";

export default function BackwardIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.95 12.5L5.45005 7L10.95 1.5M6.55005 12.5L1.05005 7L6.55005 1.5"
        stroke={stroke || theme.palette.color.secondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
