import { BiEditAlt, BiSolidNavigation } from "react-icons/bi";
import { FaBookOpen, FaRegEyeSlash, FaRegUser } from "react-icons/fa";
import { GrAnnounce } from "react-icons/gr";
import AffiliateIcon from "../components/Icons/AffiliateIcon";
import { HiMiniChartBar, HiMiniUsers } from "react-icons/hi2";
import { IoBookOutline, IoDocumentOutline, IoSettings } from "react-icons/io5";
import {
  MdDone,
  MdHomeFilled,
  MdLandscape,
  MdOutlineFolderOpen,
} from "react-icons/md";
import { PiUsersBold } from "react-icons/pi";
import { RiShoppingBag2Fill, RiShoppingBasket2Line } from "react-icons/ri";
import { TbPercentage, TbPhotoCircle, TbShield } from "react-icons/tb";
import RiskManagementIcon from "../components/Icons/RiskManagementIcon";
import PayoutIcon from "../components/Icons/PayoutsIcon";
import { LuCalendarClock, LuPlaySquare } from "react-icons/lu";
import { HiCubeTransparent } from "react-icons/hi";
import BranchIcon from "../components/Icons/BranchIcon";
import DedicatedIcon from "../components/Icons/DedicatedIcon";
import PerformanceAndBenchmarksIcon from "../components/Icons/PerformanceAndBenchmarksIcon";
import AnalysisAndReportingIcon from "../components/Icons/AnalysisAndReporting";
import TimeBasedStatisticsIcon from "../components/Icons/TimeBasedStatistics";
import ComplianceAndMonitoringIcon from "../components/Icons/ComplianceAndMonitoring";
import FinancialStatisticsIcon from "../components/Icons/FinancialStatistics";
import CopyTraderIcon from "../components/Icons/CopyTrader";
import HedgeIcon from "../components/Icons/Hedge";
import HighFrequencyIcon from "../components/Icons/HighFrequency";
import IPAddressIcon from "../components/Icons/IPAddress";
import DemographicIcon from "../components/Icons/Demographic";
import TradersIcon from "../components/Icons/Traders";
import OverviewIcon from "../components/Icons/OverviewIcon";
import GeographicIcon from "../components/Icons/GeographicIcon";
import FlagIcon from "../components/Icons/Flag";
import FolderIcon from "../components/Icons/Folder";
import MetricsIcon from "../components/Icons/Metrics";
import ProductsIcon from "../components/Icons/ProductsIcon";
import SalesIcon from "../components/Icons/SalesIcon";
// import FlagIcon from "../components/Icons/Flag";
// import MetricsIcon from "../components/Icons/MetricsIcon";

export const navItems = [
  {
    title: "Home",
    icon: MdHomeFilled,
    path: "/",
    tooltip: "Dashboard",
  },
  {
    title: "Your Firm",
    icon: RiShoppingBag2Fill,
    path: "/firm",
    tooltip: "Your Firm",
    to: "/firm/challenges",
    children: [
      {
        title: "Firm",
        path: "/firm",
        children: [
          {
            title: "Challenges",
            path: "/firm/challenges",
            heading: "Challenge",
            button: "New Challenge",
            icon: MdLandscape,
          },
          {
            title: "Orders",
            path: "/firm/orders",
            icon: RiShoppingBasket2Line,
          },
          {
            title: "Customers",
            path: "/firm/customers",
            icon: PiUsersBold,
          },
          {
            title: "Products",
            path: "/firm/customer",
            notVisible: true,
            noSidebar: true,
          },
          {
            title: "Discounts",
            path: "/firm/discounts",
            button: "Add New Discount",
            icon: TbPercentage,
          },
          { title: "Payouts", path: "/firm/payouts", icon: PayoutIcon },
        ],
      },
      {
        title: "Content Management",
        path: "/firm/content-management",
        children: [
          {
            title: "Docs",
            path: "/firm/content-management",
            to: "/firm/content-management/all-articles",
            backurl: "/firm/challenges",
            children: [],
            children3: [
              [
                {
                  title: "All articles",
                  path: "/firm/content-management/all-articles",
                  icon: IoDocumentOutline,
                },
                {
                  title: "Public articles",
                  path: "/firm/content-management/public-articles",
                  icon: TbPhotoCircle,
                },
                {
                  title: "Private articles",
                  path: "/firm/content-management/private-articles",
                  icon: TbShield,
                },
              ],

              [
                {
                  title: "Collections",
                  path: "/firm/content-management/collections",
                  icon: MdOutlineFolderOpen,
                },
                {
                  title: "Video's",
                  path: "/firm/content-management/videos",
                  icon: LuPlaySquare,
                },
              ],
              [
                {
                  title: "Visibility",
                  children: [
                    {
                      title: "Published",
                      icon: MdDone,
                      path: "/firm/content-management/published-articles",
                    },
                    {
                      title: "UnPublished",
                      path: "/firm/content-management/unpublished-articles",
                      icon: FaRegEyeSlash,
                    },
                    {
                      title: "Scheduled",
                      icon: LuCalendarClock,
                      path: "/firm/content-management/scheduled-articles",
                    },
                    {
                      title: "Draft",
                      path: "/firm/content-management/draft-articles",
                      icon: BiEditAlt,
                    },
                  ],
                },
              ],
            ],
            icon: FaBookOpen,
          },
          {
            title: "Announcements",
            path: "/firm/content-management/announcements",
            button: "Add New Announcement",
            icon: GrAnnounce,
          },
        ],
      },
    ],
    button: "Add New Product",
  },
  {
    title: "Trading Accounts",
    icon: HiMiniUsers,
    path: "/trading-accounts",
    tooltip: "Trading Accounts",
    to: "/trading-accounts/all",
    children: [
      {
        title: "All Accounts",
        icon: IoSettings,
        path: "/trading-accounts/all",
        children: [
          { title: "All Accounts", path: "/trading-accounts/all" },
          { title: "Performance", path: "/trading-accounts/performance" },
          { title: "Activity", path: "/trading-accounts/activity" },
          {
            title: "Evaluations",
            path: "/trading-accounts/evaluations",
          },
          {
            title: "Funded (sim)",
            path: "/trading-accounts/funded",
          },
        ],
      },
      {
        title: "A-book",
        path: "/statistics",
        children: [
          {
            title: "Dedicated (live)",
            path: "/trading-accounts/dedicated",
            icon: DedicatedIcon,
          },
          {
            title: "Shared (live)",
            path: "/trading-accounts/shared",
            icon: BranchIcon,
          },
        ],
      },
      // {
      //   title: "Platforms",
      //   path: "/trading-accounts",
      //   children: [
      //     { title: "Metatrader", path: "/trading-accounts/metatrader" },
      //     { title: "CTrader", path: "/trading-accounts/ctrader" },
      //     { title: "Tradelocker", path: "/trading-accounts/tradelocker" },
      //   ],
      // },
      // {
      //   title: "Settings",
      //   icon: IoSettings,
      //   path: "/trading-accounts/settings",
      // },
    ],
  },
  {
    title: "Statistics",
    icon: HiMiniChartBar,
    path: "/statistics",
    tooltip: "Statistics",
    to: "/statistics/overview",
    children: [
      {
        title: "Statistics",
        path: "/statistics",
        children: [
          {
            title: "Overview",
            path: "/statistics/overview",
            icon: OverviewIcon,
          },
          {
            title: "Sales",
            path: "/statistics/sales",
            icon: SalesIcon,
          },
          {
            title: "Products",
            path: "/statistics/products",
            icon: ProductsIcon,
          },
          {
            title: "Geographic",
            path: "/statistics/geographic",
            icon: GeographicIcon,
          },
          {
            title: "Demographic",
            path: "/statistics/demographic",
            icon: DemographicIcon,
          },
          {
            title: "Traders",
            path: "/statistics/traders",
            icon: TradersIcon,
          },
          // { title: "Performance", path: "/statistics/store-performance" },
          // { title: "Customer service", path: "/statistics/customer-service" },
        ],
      },
      // {
      //   title: "Traders",
      //   path: "/statistics/traders",
      //   icon: TradersIcon,
      //   children: [
      //     {
      //       title: "Traders",
      //       path: "/statistics/traders",
      //       icon: TradersIcon,
      //     },
      //     {
      //       title: "Sales Channel and Marketing",
      //       path: "/statistics/traders/sales-channel-and-marketing",
      //       icon: SalesChannelAndMarketingIcon,
      //     },
      //     {
      //       title: "Product Performance",
      //       path: "/statistics/traders/product-performance",
      //       icon: ProductPerformanceIcon,
      //     },
      //     {
      //       title: "Time-based Statistics",
      //       path: "/statistics/traders/time-based-statistics",
      //       icon: TimeBasedStatisticsIcon,
      //     },
      //     {
      //       title: "Financial Performance",
      //       path: "/statistics/traders/financial-performance",
      //       icon: FinancialPerformanceIcon,
      //     },
      //     {
      //       title: "Real-time Insights and Notifications",
      //       path: "/statistics/traders/real-time-insights-and-notifications",
      //       icon: RealTimeInsightsIcon,
      //     },
      //     {
      //       title: "Analysis and Reporting",
      //       path: "/statistics/traders/analysis-and-reporting",
      //       icon: AnalysisAndReportingIcon,
      //     },
      //   ],
      // },
      // {
      //   title: "AI-Assistant",
      //   icon: MdChatBubble,
      //   path: "/statistics/ai-assistant",
      //   notVisible: true,
      //   noSidebar: true,
      //   noHeader: true,
      // },
    ],
  },
  {
    title: "Risk Management",
    icon: RiskManagementIcon,
    path: "/risk-management",
    tooltip: "Risk Management",
    to: "/risk-management/flagged-accounts-hedge",
    type2: true,
    children: [
      {
        title: "Flags",
        icon: FlagIcon,
        children: [
          {
            title: "Copy traders",
            path: "/risk-management/flagged-accounts-copy-traders",
            icon: CopyTraderIcon,
          },
          {
            title: "Hedge",
            path: "/risk-management/flagged-accounts-hedge",
            icon: HedgeIcon,
          },

          {
            title: "High Frequency",
            path: "/risk-management/flagged-accounts-hft",
            icon: HighFrequencyIcon,
          },
          {
            title: "IP-Adresses",
            path: "/risk-management/flagged-accounts-IPadresses",
            icon: IPAddressIcon,
          },
        ],
      },
      {
        title: "Metrics",
        icon: MetricsIcon,
        path: "/risk-management/metrics",
        children: [
          {
            title: "Financial statistics",
            path: "/risk-management/financial-statistics",
            icon: FinancialStatisticsIcon,
          },
          {
            title: "Compliance and Monitoring",
            path: "/risk-management/compliance-and-monitoring",
            icon: ComplianceAndMonitoringIcon,
          },
          {
            title: "Time-based Statistics",
            path: "/risk-management/time-based-statistics",
            icon: TimeBasedStatisticsIcon,
          },
          {
            title: "Analysis and Reporting",
            path: "/risk-management/analysis-and-reporting",
            icon: AnalysisAndReportingIcon,
          },
          {
            title: "Performance and Benchmarks",
            path: "/risk-management/performance-and-benchmarks",
            icon: PerformanceAndBenchmarksIcon,
          },
        ],
      },
    ],
  },
  {
    title: "Customer Support",
    icon: BiSolidNavigation,
    path: "/customer-services",
    to: "/customer-services/overview",
    tooltip: "Customer Services",
    type2: true,
    children: [
      {
        title: "Overview",
        path: "/customer-services/overview",
        icon: OverviewIcon,
        button: "Add new account",
      },
      {
        title: "Tickets",
        icon: FolderIcon,
        children: [
          {
            title: "Open",
            path: "/customer-services/open-tickets",
            button: "Add new account",
          },
          // { title: "All Tickets", path: "/customer-services/all" },
          {
            title: "Closed",
            path: "/customer-services/closed-tickets",
          },
          {
            title: "Your conversations",
            path: "/customer-services/your-conversations",
            noSidebar: true,
          },
        ],
      },
      {
        title: "Metrics",
        icon: MetricsIcon,
        path: "/customer-services/metrics",
      },
    ],
    children2: [
      {
        title: "Settings",
        path: "/customer-services/settings",
        to: "/customer-services/settings/overview",
        icon: IoSettings,
        children: [
          {
            title: "Settings",
            path: "/customer-services/settings/overview",
            noHeader: true,
            children: [
              {
                title: "Overview",
                path: "/customer-services/settings/overview",
                noHeader: true,
              },
              {
                title: "Data sources",
                path: "/customer-services/settings/data-sources",
              },
              {
                title: "Custom answers",
                path: "/customer-services/settings/custom-answers",
              },
              {
                title: "Settings",
                path: "/customer-services/settings/settings",
              },
            ],
          },
          // {
          //   title: "Overview",
          //   path: "/customer-services/settings/overview",
          //   noHeader: true,
          // },
          // {
          //   title: "Data sources",
          //   path: "/customer-services/settings/data-sources",
          // },
          // {
          //   title: "Custom answers",
          //   path: "/customer-services/settings/custom-answers",
          // },
          // {
          //   title: "Settings",
          //   path: "/customer-services/settings/settings",
          // },
        ],
      },
    ],
  },
  {
    title: "Affiliate center",
    icon: AffiliateIcon,
    path: "/affiliate-center",
    tooltip: "Affiliate Center",
    to: "/affiliate-center/overview",
    children: [
      {
        title: "All",
        path: "/affiliate-center",
        children: [
          {
            title: "Overview",
            path: "/affiliate-center/overview",
            // icon: TbPhotoCircle,
            button: "Add affiliate",
          },
          {
            title: "Affiliates",
            path: "/affiliate-center/affiliates",
            // icon: PiCirclesFourBold,
            button: "Add affiliate",
          },
          {
            title: "Programs",
            path: "/affiliate-center/programs",
            // icon: TbLayoutList,
            heading: "Programs",
            button: "Add Campaign",
          },
          {
            title: "Reports",
            path: "/affiliate-center/reports",
            // icon: BiBarChartSquare,
            button: "Add affiliate",
          },
          {
            title: "Payouts",
            path: "/affiliate-center/payouts",
            // icon: TbRepeat,
            button: "Add affiliate",
          },
          {
            title: "General Performance Statistics",
            path: "/affiliate-center/general-performance-statistics",
          },
          {
            title: "Traffic Statistics",
            path: "/affiliate-center/traffic-statistics",
          },
          {
            title: "Conversion Statistics",
            path: "/affiliate-center/conversion-statistics",
          },
          {
            title: "Revenue and Earnings",
            path: "/affiliate-center/revenue-and-earnings",
          },
          {
            title: "Geographic Statistics",
            path: "/affiliate-center/geographic-statistics",
          },
          {
            title: "Individual Affiliate Statistics",
            path: "/affiliate-center/individual-affiliate-statistics",
          },
          // {
          //   title: "Announcement",
          //   path: "/affiliate-center/announcement",
          //   icon: GrAnnounce,
          //   button: "Add affiliate",
          // },
        ],
        button: "Add affiliate",
      },
    ],
  },
  //     title: 'Customer Service',
  //     icon: 'NavigationIcon',
  //     path: '/customer-service',
  //     tooltip: 'Customer Service'
  // }
];

export const navItems2 = [
  {
    title: "Docs",
    icon: FaBookOpen,
    to: "/docs/home",
    tooltip: "Docs",
    path: "/docs/home",
    nestedChildren: true,
    children: [
      {
        title: "",
        path: "/docs",
        children: [
          { title: "Home", icon: IoBookOutline, path: "/docs/home" },
          {
            title: "Videos",
            icon: LuPlaySquare,
            path: "/firm/content-management/docs/videos",
          },
          {
            title: "AI Assistant",
            icon: HiCubeTransparent,
            path: "/docs/ai-assistant",
          },
        ],
      },
      {
        title: "CRM",
        path: "/docs/crm",
        children: [
          {
            title: "Challenges",
            icon: MdOutlineFolderOpen,
            path: "/docs/crm/challenges",
          },
          {
            title: "Orders",
            icon: MdOutlineFolderOpen,
            path: "/docs/crm/orders",
          },
          {
            title: "Customers",
            icon: MdOutlineFolderOpen,
            path: "/docs/crm/customers",
          },
          {
            title: "Statistics",
            icon: MdOutlineFolderOpen,
            path: "/docs/crm/statistics",
          },
          {
            title: "Affiliate",
            icon: MdOutlineFolderOpen,
            path: "/docs/crm/affiliate",
          },
        ],
      },
      {
        title: "Policies",
        path: "/docs/crm",
        children: [
          {
            title: "Refunds",
            icon: MdOutlineFolderOpen,
            path: "/docs/policies/refunds",
          },
          {
            title: "Breaches",
            icon: MdOutlineFolderOpen,
            path: "/docs/refunds/breaches",
          },
          {
            title: "Affiliate",
            icon: MdOutlineFolderOpen,
            path: "/docs/policies/affiliate",
          },
        ],
      },
      {
        title: "Integrations",
        path: "/docs/crm",
        children: [
          {
            title: "Refunds",
            icon: MdOutlineFolderOpen,
            path: "/docs/policies/refunds",
          },
          {
            title: "Breaches",
            icon: MdOutlineFolderOpen,
            path: "/docs/refunds/breaches",
          },
          {
            title: "Affiliate",
            icon: MdOutlineFolderOpen,
            path: "/docs/policies/affiliate",
          },
        ],
      },
    ],
  },
  {
    title: "Settings",
    icon: IoSettings,
    path: "/settings",
    tooltip: "Settings",
    to: "/settings/general",
    tabs: true,
    children: [
      {
        title: "Firm",
        icon: RiShoppingBag2Fill,
        path: "/settings",
        children: [
          { title: "General", path: "/settings/general" },
          { title: "Domains", path: "/settings/domains" },
          {
            title: "Trading Platform",
            path: "/settings/brokers-tradingplatforms",
          },
          { title: "Payment providers", path: "/settings/payment-providers" },
          { title: "Integrations", path: "/settings/integrations" },
          { title: "Webhooks", path: "/settings/webhooks" },
          { title: "Emails", path: "/settings/emails" },
          { title: "Certificates", path: "/settings/certificates" },
          { title: "Affiliates", path: "/settings/affiliates" },
          { title: "Recovery", path: "/settings/recovery" },
          { title: "Users", path: "/settings/users" },
          { title: "Team", path: "/settings/team" },
          { title: "Billing", path: "/settings/billing" },
          { title: "API (coming soon)", path: "/settings/api" },
        ],
      },
      {
        title: "Profile",
        path: "/settings/account-settings",
        icon: FaRegUser,
        children: [
          { title: "Profile", path: "/settings/account-settings/profile" },
          {
            title: "Availability",
            path: "/settings/account-settings/availability",
          },
          {
            title: "Notifications",
            path: "/settings/account-settings/notifications",
          },
          {
            title: "Privacy and Security",
            path: "/settings/account-settings/privacy-security",
          },
        ],
      },
    ],
  },
];
