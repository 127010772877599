import React from 'react';
import { Grid, Box, Typography, Tooltip, Stack, useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { FiArrowUpRight } from 'react-icons/fi';
import InfoIcon2 from '../../Icons/Info2';
import RoundFormSelect from '../../ui/RoundFormSelect';

const CampaignPerformance = () => {
    const theme = useTheme();

    const series = [
        {
            name: 'Current Period Sales',
            data: [1000, 980, 960, 940, 920, 900, 850, 860],
        },
        {
            name: 'Previous Period Sales',
            data: [600, 650, 720, 770, 850, 920, 950, 940],
        },
    ];

    const options = {
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            curve: 'straight',
            width: 3,
        },
        markers: {
            size: 3,
            strokeColors: 'transparent',
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
            labels: {
                show: true,
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            min: 200,
            max: 1600,
            tickAmount: 7,
            labels: {
                show: true,
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        grid: {
            show: false,
        },
        colors: ['#5F55EE', '#5EEDF8'],
        tooltip: {
            show: false,
            theme: 'dark',
        },
        legend: {
            show: false,
        },
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>Marketing campaign performance</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <InfoBox
                                title="Total Revenue Generated"
                                value="$250,000"
                                previousPeriod="+3.8%"
                                previousYear="+3.8%"
                                tooltip="Total Revenue Generated"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InfoBox
                                title="Total Conversions"
                                value="1,500"
                                previousPeriod="+3.8%"
                                previousYear="+3.8%"
                                tooltip="Total Conversions"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InfoBox
                                title="Average CPC"
                                value="$12.50"
                                previousPeriod="+3.8%"
                                previousYear="+3.8%"
                                tooltip="Average CPC"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box sx={{
                        background: (theme) => theme.palette.color.bg5,
                        borderRadius: "18px",
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.color.border,
                        p: 2,
                    }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography variant="h6" color={(theme) => theme.palette.text.primary}>
                                Revenue and Conversions by Campaign
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6.75" cy="3" r="3" fill="#5F55EE" />
                                        <rect x="0.75" y="2.5" width="12" height="1" fill="#5F55EE" />
                                    </svg>
                                    <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                                    Revenue
                                    </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 0L9.75 3L6.75 6L3.75 3L6.75 0Z" fill="#5EEDF8" />
                                        <rect x="0.75" y="2.5" width="12" height="1" fill="#5EEDF8" />
                                    </svg>
                                    <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                                    Conversation
                                    </Typography>
                                </Stack>
                                <RoundFormSelect
                                    options={[{ value: 'monthly', label: 'Monthly' }]}
                                    defaultValue="monthly"
                                />
                            </Stack>
                        </Stack>
                        <ReactApexChart options={options} series={series} type="line" height={350} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CampaignPerformance;

const InfoBox = ({ title, value, previousPeriod, previousYear, tooltip }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: "24px",
                width: "100%",
                height: "174px",
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
            }}
        >
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="caption">{title}</Typography>
                <Tooltip title={tooltip} placement="top">
                    <InfoIcon2 />
                </Tooltip>
            </Stack>

            <Box sx={{ mt: 2 }}>
                <Typography variant="h1" component="span">{value}</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Box>
                    <Typography variant="body2" sx={{ color: '#8A96A6' }}>Previous Period</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: (theme) => theme.palette.color.green, gap: 1 }}>
                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.green }}>{previousPeriod}</Typography>
                        <FiArrowUpRight size={16} />
                    </Box>
                </Box>
                <Box>
                    <Typography variant="body2" sx={{ color: '#8A96A6' }}>Previous Year</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: (theme) => theme.palette.color.red, gap: 1 }}>
                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.red }}>{previousYear}</Typography>
                        <FiArrowUpRight size={16} style={{ transform: 'rotate(180deg)' }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};