import Firm from "../pages/Firm";
import Customers from "../pages/Firm/customers";
import Discounts from "../pages/Firm/discounts";
import Orders from "../pages/Firm/orders";
import Home from "../pages/Home";
import TradingAccounts from "../pages/Trading-accounts";
import FlaggedAccounts from "../pages/Trading-accounts/flaggedaccounts";
import Traders from "../pages/Statistics/traders";
import AllTickets from "../pages/Customer-service";
import OpenTickets from "../pages/Customer-service/openTickets";
import ClosedTickets from "../pages/Customer-service/closedtickets";
import Product from "../components/Firm/Products/Single-Product";
import Payouts from "../pages/Firm/Payouts";
import AllArticles from "../pages/Firm/Content-management/Docs/all-artices";
import Announcement from "../pages/Firm/Content-management/Annoucements";
import Overview from "../pages/Affiliates/overview";
import Affiliates from "../pages/Affiliates/affiliates";
import General from "../pages/Settings/general";
import Domains from "../pages/Settings/domains";
import Videos from "../pages/Firm/Content-management/Collections/videos";
import Collections from "../pages/Firm/Content-management/Collections/collections";
import PublicArticles from "../pages/Firm/Content-management/Docs/public-articles";
import PrivateArticles from "../pages/Firm/Content-management/Docs/private-articls";
import PublishedArticles from "../pages/Firm/Content-management/Docs/published-articles";
import UnPublishedArticles from "../pages/Firm/Content-management/Docs/unpublished-articles";
import ScheduledArticles from "../pages/Firm/Content-management/Docs/scheduled-articles";
import DraftArticles from "../pages/Firm/Content-management/Docs/draft-articles";
import Announcements from "../pages/Affiliates/announcements";
import AffiliatePayouts from "../pages/Affiliates/payouts";
import Programs from "../pages/Affiliates/programs";
import EditCampaign from "../pages/Affiliates/EditCampaign";
import Stats from "../pages/Affiliates/stats";
import Commissions from "../pages/Affiliates/commissions";
import Conversations from "../pages/Customer-service/Conversations";
import SettingsOverview from "../pages/Customer-service/AI-Settings/overview";
import Reports from "../pages/Affiliates/reports";
import CustomAnswers from "../pages/Customer-service/AI-Settings/custom-answers";
import DataSources from "../pages/Customer-service/AI-Settings/data-sources";
import AISettings from "../pages/Customer-service/AI-Settings/settings";
import AiAssistant from "../pages/AI-Assistant/aiAssistant";
import Challenges from "../pages/Firm/challenges";
import Hedge from "../pages/Trading-accounts/flaggedaccounts/Hedge";
import CopyTraders from "../pages/Trading-accounts/flaggedaccounts/CopyTraders";
import HFT from "../pages/Trading-accounts/flaggedaccounts/HFT";
import IpAddress from "../pages/Trading-accounts/flaggedaccounts/IpAddress";
import Customer from "../pages/Firm/Customer/overview";
import Integrations from "../pages/Settings/integrations";
import TradingPlatform from "../pages/Settings/trading-platform";
import Certificates from "../pages/Settings/certificates";
import ProgramSettings from "../pages/Settings/program-settings";
import Webhooks from "../pages/Settings/webhooks";
import PaymentProviders from "../pages/Settings/payment-providers";

import CustomerStatistics from "../pages/Firm/Customer/statistics";
import CustomerTradingAccounts from "../pages/Firm/Customer/trading-accounts";
import ABook from "../pages/Firm/Customer/a-book";
import CustomerOrders from "../pages/Firm/Customer/orders";
import CustomerTickets from "../pages/Firm/Customer/tickets";
import CustomeFlags from "../pages/Firm/Customer/flags";

import ApiKeys from "../pages/Settings/api-keys";
import Recovery from "../pages/Settings/recovery";
import Login from "../pages/Auth/login";
import EmailSettings from "../pages/Settings/email-settings";
import Users from "../pages/Settings/users";
import Billing from "../pages/Settings/billing";
import Teams from "../pages/Settings/teams";
import StatisticsOverview from "../pages/Statistics/overview";
import Geographic from "../pages/Statistics/geographic";
import YourConversations from "../pages/Customer-service/YourConversations";
import Profile from "../pages/Settings/Account-Settings/Profile";
import Availability from "../pages/Settings/Account-Settings/Availability";
import PrivacySecurity from "../pages/Settings/Account-Settings/PrivacySecurity";
import Notifications from "../pages/Settings/Account-Settings/Notifications";
import DocsHome from "../pages/Docs/home";
import WhatsNew from "../pages/WhatsNew";
import CustomerPayouts from "../pages/Firm/Customer/payouts";
import Register from "../pages/Auth/register";
import AccountVerification from "../pages/Auth/account-verification";
import ForgetPassword from "../pages/Auth/forget-password";
import ResetPassword from "../pages/Auth/reset-password";
import Evaluation from "../pages/Trading-accounts/evaluation";
import Funded from "../pages/Trading-accounts/funded";
import Dedicated from "../pages/Trading-accounts/dedicated";
import Shared from "../pages/Trading-accounts/shared";
import FinancialStatistics from "../pages/Risk-Management/FinancialStatistics";
import ComplianceAndMonitoring from "../pages/Risk-Management/ComplianceAndMonitoring";
import PerformanceAndBenchmarks from "../pages/Risk-Management/PerformanceAndBenchmarks";
import AnalysisAndReporting from "../pages/Risk-Management/AnalysisAndReporting";
import TimeBasedStatistics from "../pages/Risk-Management/TimeBasedStatistics";
import Metrics from "../pages/Trading-accounts/metrics";
import Activity from "../pages/Trading-accounts/activity";
import Demographic from "../pages/Statistics/demographic";
import FinancialPerformance from "../pages/Statistics/financial-performance";
import CustomerServiceOverview from "../pages/Customer-service/overview";
import CustomerServiceMetrics from "../pages/Customer-service/metrics";
import StatisticsProducts from "../pages/Statistics/products";
import StatisticsSales from "../pages/Statistics/sales";
import IndividualAffiliateStatistics from "../pages/Affiliates/IndividualAffiliateStatistics";
import GeneralPerformanceStatistics from "../pages/Affiliates/GeneralPerformanceStatistics";
import TrafficStatistics from "../pages/Affiliates/TrafficStatistics";
import ConversionStatistics from "../pages/Affiliates/ConversionStatistics";
import RevenueAndEarnings from "../pages/Affiliates/RevenueAndEarnings";
import GeographicStatistics from "../pages/Affiliates/GeographicStatistics";
import RiskManagementMetrics from "../pages/Risk-Management/RiskManagementMetrics";

export const navItems = [
  { path: "/", element: <Home />, isPrivate: true, layout: true },
  {
    path: "/firm/products",
    element: <Firm />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/challenges",
    element: <Challenges />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/products/:id",
    element: <Product />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/orders",
    element: <Orders />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/customers",
    element: <Customers />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/customer/overview",
    element: <Customer />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/customer/statistics",
    element: <CustomerStatistics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/customer/trading-accounts",
    element: <CustomerTradingAccounts />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/customer/payouts",
    element: <CustomerPayouts />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/customer/orders",
    element: <CustomerOrders />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/customer/tickets",
    element: <CustomerTickets />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/customer/flag",
    element: <CustomeFlags />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/customer/a-book",
    element: <ABook />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/discounts",
    element: <Discounts />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/payouts",
    element: <Payouts />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/content-management/all-articles",
    element: <AllArticles />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/content-management/public-articles",
    element: <PublicArticles />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/content-management/private-articles",
    element: <PrivateArticles />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/content-management/published-articles",
    element: <PublishedArticles />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/content-management/unpublished-articles",
    element: <UnPublishedArticles />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/content-management/scheduled-articles",
    element: <ScheduledArticles />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/content-management/draft-articles",
    element: <DraftArticles />,
    isPrivate: true,
    layout: true,
  },

  {
    path: "/firm/content-management/announcements",
    element: <Announcement />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/content-management/collections",
    element: <Collections />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/firm/content-management/videos",
    element: <Videos />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/all",
    element: <TradingAccounts />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/performance",
    element: <Metrics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/activity",
    element: <Activity />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/evaluations",
    element: <Evaluation />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/funded",
    element: <Funded />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/dedicated",
    element: <Dedicated />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/shared",
    element: <Shared />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/flagged-accounts/copy-traders",
    element: <CopyTraders />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/flagged-accounts/hedge",
    element: <Hedge />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/flagged-accounts/high-frequency",
    element: <HFT />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/flagged-accounts/ip-adresses",
    element: <IpAddress />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/flagged-accounts-copy-traders",
    element: <CopyTraders />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/flagged-accounts-hedge",
    element: <Hedge />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/flagged-accounts-hft",
    element: <HFT />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/flagged-accounts-IPadresses",
    element: <IpAddress />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/metrics",
    element: <RiskManagementMetrics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/financial-statistics",
    element: <FinancialStatistics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/compliance-and-monitoring",
    element: <ComplianceAndMonitoring />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/time-based-statistics",
    element: <TimeBasedStatistics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/analysis-and-reporting",
    element: <AnalysisAndReporting />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/risk-management/performance-and-benchmarks",
    element: <PerformanceAndBenchmarks />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/flagged-accounts",
    element: <FlaggedAccounts />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/metatrader",
    element: <div></div>,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/ctrader",
    element: <div></div>,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/tradelocker",
    element: <div></div>,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/settings",
    element: <div></div>,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/trading-accounts/settings/account",
    element: <div></div>,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/statistics/overview",
    element: <StatisticsOverview />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/statistics/sales",
    element: <StatisticsSales />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/statistics/products",
    element: <StatisticsProducts />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/statistics/geographic",
    element: <Geographic />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/statistics/demographic",
    element: <Demographic />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/statistics/traders",
    element: <Traders />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/statistics/financial-performance",
    element: <FinancialPerformance />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/statistics/customer-service",
    element: <div></div>,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/statistics/ai-assistant",
    element: <AiAssistant />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/all",
    element: <AllTickets />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/overview",
    element: <CustomerServiceOverview />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/metrics",
    element: <CustomerServiceMetrics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/conversation",
    element: <Conversations />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/open-tickets",
    element: <OpenTickets />,
    isPrivate: true,
    layout: true,
  },

  {
    path: "/customer-services/your-conversations",
    element: <YourConversations />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/closed-tickets",
    element: <ClosedTickets />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/settings/overview",
    element: <SettingsOverview />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/settings/custom-answers",
    element: <CustomAnswers />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/settings/data-sources",
    element: <DataSources />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/customer-services/settings/settings",
    element: <AISettings />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/overview",
    element: <Overview />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/programs",
    element: <Programs />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/programs/edit-campaign",
    element: <EditCampaign />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/programs/stats",
    element: <Stats />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/programs/commissions",
    element: <Commissions />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/affiliates",
    element: <Affiliates />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/compaigns",
    element: <div></div>,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/reports",
    element: <Reports />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/payouts",
    element: <AffiliatePayouts />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/announcement",
    element: <Announcements />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/general-performance-statistics",
    element: <GeneralPerformanceStatistics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/traffic-statistics",
    element: <TrafficStatistics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/conversion-statistics",
    element: <ConversionStatistics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/revenue-and-earnings",
    element: <RevenueAndEarnings />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/geographic-statistics",
    element: <GeographicStatistics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/affiliate-center/individual-affiliate-statistics",
    element: <IndividualAffiliateStatistics />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/general",
    element: <General />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/domains",
    element: <Domains />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/integrations",
    element: <Integrations />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/brokers-tradingplatforms",
    element: <TradingPlatform />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/webhooks",
    element: <Webhooks />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/api",
    element: <ApiKeys />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/brokers-platforms",
    element: <div></div>,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/users",
    element: <Users />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/payment-providers",
    element: <PaymentProviders />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/emails",
    element: <EmailSettings />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/certificates",
    element: <Certificates />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/affiliates",
    element: <ProgramSettings />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/recovery",
    element: <Recovery />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/billing",
    element: <Billing />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/team",
    element: <Teams />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/account-settings/profile",
    element: <Profile />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/account-settings/availability",
    element: <Availability />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/account-settings/notifications",
    element: <Notifications />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/settings/account-settings/privacy-security",
    element: <PrivacySecurity />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/docs/home",
    element: <DocsHome />,
    isPrivate: true,
    layout: true,
  },
  {
    path: "/login",
    element: <Login />,
    isPrivate: false,
    layout: false,
  },
  {
    path: "/register",
    element: <Register />,
    isPrivate: false,
    layout: false,
  },
  {
    path: "/account-verification",
    element: <AccountVerification />,
    isPrivate: false,
    layout: false,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
    isPrivate: false,
    layout: false,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    isPrivate: false,
    layout: false,
  },
  {
    path: "/whats-new",
    element: <WhatsNew />,
    isPrivate: true,
    layout: true,
  },
  //   { path: "*", element: <Home />, isPrivate: false, layout: false },
];
