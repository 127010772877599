import React from "react";

const UserMenuCirlceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34">
      <path
        d="M 26.232 26.277 C 23.76 28.748 20.476 30.109 16.981 30.109 C 9.772 30.109 3.906 24.246 3.899 17.04 L 4 15 L 0.133 19.073 C 0.048 19.158 0 19.273 0 19.392 C 0 19.512 0.048 19.627 0.133 19.711 C 0.216 19.796 0.331 19.843 0.45 19.843 C 0.569 19.843 0.684 19.796 0.768 19.711 L 3.014 17.464 C 3.249 24.972 9.413 31.011 16.976 31.011 C 20.713 31.011 24.224 29.556 26.867 26.914 C 27.043 26.738 27.043 26.453 26.867 26.277 C 26.696 26.1 26.409 26.1 26.232 26.277 Z M 33.867 14.289 C 33.691 14.113 33.406 14.113 33.23 14.289 L 30.986 16.535 C 30.75 9.027 24.586 2.989 17.024 2.989 C 13.285 2.989 9.774 4.444 7.131 7.086 C 7.047 7.17 6.999 7.285 6.999 7.404 C 6.999 7.524 7.047 7.638 7.131 7.722 C 7.216 7.807 7.33 7.855 7.45 7.855 C 7.569 7.855 7.684 7.807 7.768 7.722 C 10.24 5.252 13.525 3.891 17.02 3.891 C 24.228 3.891 30.095 9.752 30.104 16.96 L 30 18.5 L 33.869 14.927 C 33.953 14.842 34 14.727 34 14.607 C 34 14.488 33.952 14.373 33.867 14.289 Z"
        fill="rgb(95, 85, 238)"
      ></path>
    </svg>
  );
};

export default UserMenuCirlceIcon;
