import React from "react";
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon2 from "../../Icons/Info2";
import { useTheme } from "@emotion/react";
import { FiArrowUpRight } from "react-icons/fi";

const BackgroundBox = styled(Box)(({ theme }) => ({
    background: theme.palette.color.bg5,
    height: "100%",
    border: "1px solid rgba(140,173,255,0.1)",
    borderRadius: "18px",
    padding: theme.spacing(3),
    color: 'white',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const BackgroundImage = styled('div')(({ reverseImage }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'url("/svg/statsCardBg.svg")',
    backgroundSize: 'cover',
    opacity: 0.8,
    transform: reverseImage ? 'scaleY(-1)' : 'scaleY(1)',
}));

const StatCard = ({ title, tooltip, value, reverseImage }) => {
    const theme = useTheme();
    return (
        <BackgroundBox>
            <BackgroundImage reverseImage={reverseImage} />
            <Box zIndex={1} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="subtitle2">{title}</Typography>
                        <Tooltip title={tooltip} placement="top">
                            <InfoIcon2 stroke={theme.palette.color.secondary} />
                        </Tooltip>
                    </Stack>
                    <Typography variant="h2" mt={3}>{value}</Typography>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ alignItems: "center", color: (theme) => theme.palette.color.secondary }}>
                    <FiArrowUpRight size={16} />
                    <Typography variant="caption">{"+3.7%"}</Typography>
                </Stack>
            </Box>
        </BackgroundBox>
    );
}

export default StatCard;