import {
  Paper,
  Stack,
  Typography,
  IconButton,
  Box,
  Grid,
  Divider,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../components/Firm/Orders/Table";
import { Add } from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { RiFileList2Line } from "react-icons/ri";
import { LuCalendar } from "react-icons/lu";
import { discounts } from "../../static/firmItems";
import AddDiscount from "../../components/Firm/addDiscount";
import SearchInput from "../../components/ui/NewSearch";
import ExportSection from "../../components/ui/ExportSection";
import DisplayColumns from "../../components/ui/DisplayColumns";
import CopyIcon from "../../components/Icons/Copy";
import { useDispatch } from "react-redux";
import { toggleOpen } from "../../slices/openModalSlice";
import PlusIcon from "../../components/Icons/Plus";
import { useGetDiscountsQuery } from "../../slices/api/firmSlice";

const CodeBox = ({ code }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.lightgray,
        p: 1,
        border: (theme) => `1px solid ${theme.palette.color.border}`,
        borderRadius: "7px",
        width: "146px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        ":hover": {
          ".copyIcon": {
            opacity: 1,
          },
        },
      }}
    >
      <Typography
        sx={{
          color: (theme) => theme.palette.color.active,
        }}
      >
        {code}
      </Typography>
      <Box
        className="copyIcon"
        sx={{
          opacity: 0,
          position: "absolute",
          right: "8px",
          top: "6px",
        }}
      >
        <CopyIcon stroke={theme.palette.color.active} />
      </Box>
    </Box>
  );
};

const Action = ({ row }) => {
  const dispatch = useDispatch();
  return (
    <IconButton onClick={() => dispatch(toggleOpen())}>
      <Add
        sx={{
          fontSize: "22px",
          color: (theme) => theme.palette.color.primary,
        }}
      />
    </IconButton>
  );
};

const headcells = [
  {
    id: "name",
    label: "Name",
    getCell: (row) => row.name,
    padding: "12px",
  },
  {
    id: "code",
    label: "Code",
    getCell: (row) => <CodeBox code={row.code} />,
    padding: "12px",
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   getCell: (row) => {
  //     const status = statutes.find((statute) => statute.value === row.status);
  //     return (
  //       <Typography
  //         sx={{
  //           background: (theme) =>
  //             alpha(theme.palette.color[status.color], 0.15),
  //           lineHeight: "28px",
  //           textAlign: "center",
  //           borderRadius: "7px",
  //           width: "104px",

  //           color: (theme) => theme.palette.color[status.color],
  //         }}
  //       >
  //         {status.label}
  //       </Typography>
  //     );
  //   },
  // },
  {
    id: "products",
    label: "Products",
    getCell: (row) => row.products || "-",
    padding: "12px",
  },

  {
    id: "amount",
    label: "Amount",
    getCell: (row) => row.discount,
    padding: "12px",
  },
  {
    id: "redemptions",
    label: "Redemptions",
    getCell: (row) => "-",
    padding: "12px",
  },
  {
    id: "action",
    label: "",
    label2: <Action />,
    getCell: (row) => {},
    padding: "12px",
  },
];

const Discounts = () => {
  const [type, setType] = useState("table");
  const startDate = new Date(2024, 3, 1);
  const endDate = new Date(2024, 3, 16);
  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(headcells.map((cell) => cell.id));
  const dispatch = useDispatch();
  const { data } = useGetDiscountsQuery();

  const getDates = () => {
    const dates = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate
        .toLocaleString("default", { weekday: "short" })
        .charAt(0);
      const date = currentDate.getDate().toString();
      dates.push({ day: dayOfWeek.toLowerCase(), date });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const dates = getDates();
  return (
    <Paper
      sx={{
        p: { xs: "12px 24px", sm: "0px 24px" },
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: "12px",
          mt: { xs: "0px", sm: "24px" },
          mb: "16px",
          fontSize: "11.5px",
          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <Typography variant="h4">Discounts</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />
          <ExportSection />

          <DisplayColumns
            columns={columns}
            setColumns={setColumns}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              height: "30px",
              padding: "4px",
              borderRadius: "8px",
              background: (theme) => theme.palette.color.bg2,
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => setType("table")}
              sx={{
                p: "4px",
                borderRadius: "4px",
                background:
                  type === "table"
                    ? (theme) => theme.palette.color.bg3
                    : "transparent",
                color: (theme) => theme.palette.color.secondary,
                cursor: type === "table" ? "default" : "pointer",
              }}
            >
              <FormatListBulletedIcon sx={{ fontSize: "13px" }} />
            </IconButton>
            <IconButton
              onClick={() => setType("list")}
              sx={{
                p: "4px",
                borderRadius: "4px",
                background:
                  type === "list"
                    ? (theme) => theme.palette.color.bg3
                    : "transparent",
                color: (theme) => theme.palette.color.secondary,
                cursor: type === "list" ? "default" : "pointer",
              }}
            >
              <RiFileList2Line size={12} />
            </IconButton>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ px: "6px" }} />
          <IconButton
            sx={{
              p: "4px",
            }}
            onClick={() => dispatch(toggleOpen())}
          >
            <PlusIcon />
          </IconButton>
        </Stack>
      </Box>
      <Divider />

      {type === "list" && <Divider />}

      {type === "list" && (
        <Paper sx={{ maxWidth: "1100px", mx: "auto" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                borderRight: (theme) =>
                  `1px solid ${theme.palette.color.border}`,
              }}
            >
              <Box>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="flex-end"
                  justifyContent={"space-between"}
                  sx={{
                    px: "12px",
                    pb: "10px",
                    height: "60px",
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.color.border}`,
                  }}
                >
                  <Typography variant="subtitle1">Discount</Typography>
                  <Typography variant="caption" sx={{ fontWeight: "500" }}>
                    Duration
                  </Typography>
                </Stack>
              </Box>
              <Stack spacing={6} sx={{ p: "12px" }}>
                {discounts.map((discount, index) => (
                  <Stack spacing={2.3} key={index}>
                    <Typography variant="caption" sx={{ fontWeight: "500" }}>
                      {discount.month}
                    </Typography>
                    {discount.discounts.map((d, i) => (
                      <Stack spacing={2} pb={1}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "500",
                              "& span": {
                                color: (theme) => theme.palette.color.green,
                                ml: "10px",
                              },
                            }}
                          >
                            {d.name} <span>25%</span>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "500",
                            }}
                          >
                            {d.duration}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            color: (theme) => theme.palette.color.secondary,
                          }}
                        >
                          <LuCalendar size={18} />
                          <Typography variant="caption">
                            {d.startDate} - {d.endDate}
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                maxWidth: "100%",
                overflowX: "auto",
              }}
            >
              <Stack
                justifyContent={"space-around"}
                sx={{
                  px: "12px",
                  // pb: "10px",

                  height: "60px",
                  borderBottom: (theme) =>
                    `1px solid ${theme.palette.color.border}`,
                }}
              >
                <Typography variant="subtitle1">April 2024</Typography>
                <Stack direction="row" spacing={4}>
                  {dates.map((date) => (
                    <Stack direction="row" spacing={1} key={date.date}>
                      <Typography variant="caption" sx={{ fontWeight: "500" }}>
                        {date.day}
                      </Typography>
                      <Typography variant="subtitle1">{date.date}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Typography
                sx={{
                  fontSize: "13px",
                  mt: "50px",
                  mx: "12px",
                  height: "48px",
                  px: "24px",
                  borderRadius: "24px",
                  background: (theme) => theme.palette.color.green,
                  color: (theme) => theme.palette.color.bg3,
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                Discount name
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      <AddDiscount />
      {type === "table" && (
        <CustomTable
          headcells={headcells}
          rows={[...(data?.data || [])]}
          fontSize="13px"
        />
      )}
    </Paper>
  );
};

export default Discounts;
