import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';
import RoundFormSelect from '../../ui/RoundFormSelect';

const TradingAccountChart = () => {
  const options = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      background: 'transparent',
    },
    stroke: {
      curve: 'straight',
      width: 3,
    },
    markers: {
      size: 4,
      colors: ['#6366F1'],
      strokeColors: '#6366F1',
      strokeWidth: 2,
      hover: {
        size: 6,
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: '#9CA3AF',
        },
      },
    },
    yaxis: {
      title: {
        text: 'New Account',
        style: {
          color: '#8A96A6',
        },
      },
      min: 0,
      max: 1200,
      show: true,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: '#9CA3AF',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    colors: ['#6366F1'],
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      labels: {
        colors: '#9CA3AF',
      },
      markers: {
        radius: 12,
      },
    },
  };

  const series = [
    {
      name: 'New Trading Account',
      data: [300, 400, 350, 500, 400, 300, 500, 600, 700, 800, 900, 1000],
    },
  ];

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        p: '10px',
        borderRadius: '16px',
      }}>
      <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h5">
        New Trading Account over Time
        </Typography>
        <Stack direction="row" gap={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">New Trading Account</Typography>
          </Box>
          <RoundFormSelect
            options={[{ value: "yearly", label: "Yearly" },{ value: "monthly", label: "Monthly" },{ value: "weekly", label: "Weekly" },{ value: "daily", label: "Daily" },]}
            defaultValue="yearly"
          />
        </Stack>
      </Stack>
      <Chart options={options} series={series} type="line" height={350} />
    </Box>
  );
};

export default TradingAccountChart;