import React, { useEffect, useState } from "react";
import {
  MoreVertOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Avatar, Box, Button, Divider, IconButton, Popover, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { BiTransfer } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Bars } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../Icons/Edit";

export default function AffilateTicket() {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCopyClick = () => {
    navigator.clipboard.writeText("{{ ticket ID }}")
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  useEffect(() => {
    if (tooltipOpen) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [tooltipOpen]);

  return (
    <Box
      sx={{
        borderRadius: 4,
        background: (theme) => theme.palette.color.borderpure,
        padding: "16px",
        m: "20px",
        border: '1px solid transparent', 
        transition: 'border-color 0.3s',
        borderColor: (theme) => isEditing ? theme.palette.color.active : 'transparent',
        '&:hover': {
          borderColor: (theme) => theme.palette.color.active,
        },
      }}
    >
      <Stack className="affilate_ticket_heading">
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6"
            sx={{
              borderBottom: isEditing ? `1px dashed ${theme.palette.color.active}` : 'none',
              cursor: isEditing ? 'text' : 'pointer',
              marginBottom: isEditing ? '10px' : '0',
              paddingBottom: isEditing ? '4px' : '0',
              paddingRight: isEditing ? '140px' : '0',
            }}
          >Ticket summary title</Typography>
          <Stack
            direction={"row"}
            gap={0.5}
            sx={{
              alignItems: "center",
            }}
            className="affilate_ticket_actions"
          >
            <IconButton onClick={handleEditClick}>
              <EditIcon height={18} width={18}/>
            </IconButton>
            <IconButton onClick={() => { }}>
              <VisibilityOutlined style={{ fontSize: "18px", color: theme.palette.color.secondary }} />
            </IconButton>
            <IconButton aria-describedby={id} onClick={handleClick}>
              <MoreVertOutlined style={{ fontSize: "18px", color: theme.palette.color.secondary }} />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Stack alignItems="flex-start">
                <IconButton
                  sx={{
                    borderRadius: "0px",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => { }}
                >
                  <ContentCopyIcon
                    style={{ fontSize: "14px", marginRight: "8px", color: theme.palette.color.secondary }}
                  />
                  <Typography variant="caption">Copy ticket ID</Typography>
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: "0px",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => { }}
                >
                  <BiTransfer
                    style={{ fontSize: "14px", marginRight: "8px", color: theme.palette.color.secondary }}
                  />
                  <Typography variant="caption">Transfer</Typography>
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: "0px",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => { }}
                >
                  <IoMdClose
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                      color: "#F55F82",
                    }}
                  />
                  <Typography variant="caption" sx={{ color: (theme)=>theme.palette.color.red }}>
                    Close ticket
                  </Typography>
                </IconButton>
              </Stack>
            </Popover>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          gap={"2px"}
          sx={{
            alignItems: "center",
            position: "relative",
            "&:hover .copyIcon": {
              display: "inline-block",
            },
          }}
        >
          <Typography
            variant="caption"
            onClick={handleCopyClick}
            sx={{ color: copied ? (theme) => theme.palette.color.green : (theme) => theme.palette.color.secondary, cursor: "pointer", }}
          >
            {copied ? "Copied to clipboard" : "{{ ticket ID }}"}
          </Typography>
          <Box
            className="copyIcon"
            sx={{
              color: copied ? (theme) => theme.palette.color.green : (theme) => theme.palette.color.secondary,
              ml: 1,
              fontSize: "12px",
              display: "none",
              cursor: "pointer",
            }}
          >

            <ContentCopyIcon sx={{ fontSize: "12px" }} />
          </Box>
        </Stack>

        <Divider sx={{ mt: 2 }} />
        <Stack direction={"row"} gap={3} mt={2}>
          <Box width={"100%"}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mb={1}
            >
              <Typography variant="body1">Open date</Typography>
              <Typography variant="caption">11:05 July 15</Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">Collection</Typography>
              <Typography variant="caption">Collection name</Typography>
            </Stack>
          </Box>
          <Box width={"100%"}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mb={1}
            >
              <Typography variant="body1">Time open</Typography>
              <Typography variant="caption">1h 15m</Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="body1">Collection</Typography>
              <Stack direction={"row"} gap={1}>
                <Tooltip
                  open={tooltipOpen}
                  onOpen={() => setTooltipOpen(true)}
                  onClose={() => setTooltipOpen(false)}
                  title={
                    loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: 2,
                          minWidth: "293px",
                          minHeight: "250px",
                        }}
                      >
                        <Bars
                          height="24"
                          width="24"
                          color="#8A96A6"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </Box>
                    ) : (
                      <Box sx={{ padding: 2, minWidth: "293px" }}>
                        <Stack direction="row">
                          <Avatar
                            sx={{
                              bgcolor: (theme) => theme.palette.color.pink,
                              width: "50px",
                              height: "50px",
                              fontSize: "18px",
                              cursor: "pointer",
                            }}
                          >
                            J
                          </Avatar>
                          <Stack sx={{ ml: "20px", gap: 1 }}>
                            <Typography variant="h5" onClick={() => navigate("/firm/customer/overview")} sx={{ "cursor": "pointer", ":hover": { textDecoration: "underline" } }}>Jens van der Blij</Typography>
                            <Typography variant="body2" onClick={() => navigate("/firm/customer/overview")} sx={{ "cursor": "pointer", ":hover": { textDecoration: "underline" } }}>105005060</Typography>
                            <Stack direction="row" gap={1} alignItems={"center"}>
                              <Box
                                sx={{
                                  width: "6px",
                                  height: "6px",
                                  borderRadius: "50%",
                                  background: (theme) => theme.palette.color.green,
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  color: (theme) => theme.palette.color.green,
                                }}
                              >
                                Verified
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack gap={1} mt="20px">
                          <Typography variant="body2" fontSize="13px">
                            info@email.com
                          </Typography>
                          <Typography variant="body2" fontSize="13px">
                            1:50 pm GMT +1
                          </Typography>
                          <Typography variant="body2" fontSize="13px">
                            (+31) 6 22 02 36 57
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{ mt: 2 }}
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography
                            color={(theme) => theme.palette.color.secondary}
                            onClick={() => navigate("/firm/customer/overview")}
                            sx={{
                              fontSize: "13px",
                              cursor: "pointer",
                              ":hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            Visit account
                          </Typography>
                          <Button
                            onClick={() => navigate("/customer-services/your-conversations")}
                            sx={{
                              borderRadius: "18px",
                            }}
                            endIcon={<ArrowForwardIcon />}
                          >
                            Go to ticket
                          </Button>
                        </Stack>
                      </Box>
                    )
                  }
                  arrow={false}
                  placement="top-start"
                >
                  <Avatar
                    sx={{
                      bgcolor: (theme) => theme.palette.color.pink,
                      width: "24px",
                      height: "24px",
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                  >
                    J
                  </Avatar>
                </Tooltip>
                <Typography variant="caption" onClick={() => navigate("/firm/customer/overview")} sx={{ "cursor": "pointer", ":hover": { textDecoration: "underline" } }}>105040205</Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
