import React, { useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Paper,
  Tabs,
  Tab,
  Divider,
  Avatar,
  useTheme,
  IconButton,
} from "@mui/material";
import CustomAccordion from "../../ui/CustomAccordion";
import SearchSection from "../../Layout/Header/SearchSection";
import ThemeSwitch from "../../Layout/Header/ThemeSwitch";
import Notifications from "../../Layout/Header/Notifications";
import ProfileSection from "../../Layout/Header/ProfileSection";
import Menu from "../../Layout/Header/Menu";
import AIAssistantIcon from "../../Icons/AIAssistant";
import StarIcon from "../../Icons/Star";
import GraphicIcon from "../../Icons/Graphic";
import FlagIcon1 from "../../Icons/Flag1";
import ChevronDownIcon from "../../Icons/ChevronDown";
import FormatIcon from "../../Icons/Format";

const CustomerDetails = ({ customer, showCustomerInfo }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const items = [
    {
      title: "Visitor",
      date: "24 Apr, 2024",
      icon: (
        <AIAssistantIcon
          width="16"
          height="16"
          stroke={theme.palette.color.bg}
          fill={theme.palette.color.bg}
        />
      ),
    },
    {
      title: "Qualified lead",
      date: "24 Apr, 2024",
      icon: (
        <StarIcon
          width="12"
          height="12"
          stroke={theme.palette.color.bg}
          fill={theme.palette.color.bg}
        />
      ),
    },
    {
      title: "Paying customer",
      date: "28 Apr, 2024",
      icon: (
        <GraphicIcon
          width="12"
          height="12"
          stroke={theme.palette.color.bg}
          fill={theme.palette.color.bg}
        />
      ),
    },
    {
      title: "Funded Trader",
      date: "",
      icon: <FlagIcon1 width="12" height="12" />,
      disabled: true,
    },
  ];

  return (
    <Paper
      sx={{
        bgcolor: "#090B0D",
        width: showCustomerInfo ? "336px" : "0px",
        transition: "width 0.5s",
        height: "99.8vh",
        overflow: "hidden",
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          p: "6px 12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              height: "21px",
              my: "auto",
              borderRightWidth: "2px",
            }}
          />
          <SearchSection />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              height: "21px",
              my: "auto",
              borderRightWidth: "2px",
            }}
          />
          <ThemeSwitch />
          <IconButton>
            <FormatIcon />
          </IconButton>
          <Notifications />
          <ProfileSection />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              height: "21px",
              my: "auto",
              borderRightWidth: "2px",
              display: { xs: "block", lg: "none" },
            }}
          />
          <Menu />
        </Box>
      </Box>
      <Stack
        sx={{
          padding: "20px",
          paddingBottom: "10px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Customer details</Typography>
          <Typography variant="body3">Local time: 11:05AM</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            borderRadius: "7px",
          }}
        >
          <Box
            sx={{
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              background: (theme) => theme.palette.color.green,
            }}
          ></Box>
          <Typography
            sx={{
              color: (theme) => theme.palette.color.green,
              lineHeight: "2.2em",
              fontWeight: "400",
            }}
          >
            Verified
          </Typography>
        </Stack>
      </Stack>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="client details tabs"
        sx={{ borderBottom: "none", px: "20px" }}
        className="client-info-tabs"
      >
        <Tab label="Overview" {...a11yProps(0)} />
        <Tab label="Accounts (5)" {...a11yProps(1)} />
        <Tab label="Payouts (5)" {...a11yProps(2)} />
        <Tab label="Flags (5)" {...a11yProps(3)} />
        <Tab label="Tickets (5)" {...a11yProps(4)} />
        <Tab label="Orders (5)" {...a11yProps(4)} />
      </Tabs>
      <Box sx={{ padding: "20px", height: "100%", overflowY: "auto" }}>
        {selectedTab === 0 && (
          <Box
            sx={{
              py: "5px",
              backgroundColor: "#101419",
              borderRadius: "18px",
            }}
          >
            <CustomAccordion
              icon={
                <ChevronDownIcon
                  stroke={theme.palette.color.primary}
                  strokeWidth="2"
                />
              }
              title={"Key information"}
              defaultExpanded={true}
              background="transparent"
              accordionSummarysx={{
                "& .MuiButtonBase-root": {
                  flexDirection: "row-reverse !important",
                  gap: "10px",
                },
              }}
            >
              <Stack spacing={1.5} pl="8px">
                <Typography variant="body3" sx={{ fontWeight: "400" }}>
                  Jens van der Blij
                </Typography>
                <Typography variant="body3" sx={{ fontWeight: "400" }}>
                  English (United Kingdom)
                </Typography>
                <Typography variant="body3" sx={{ fontWeight: "400" }}>
                  GMT +1
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Avatar
                    sx={{
                      width: "15px",
                      height: "15px",
                    }}
                    src="/images/country-icons/uk.svg"
                  />
                  <Typography variant="body3" sx={{ fontWeight: "400" }}>
                    UK
                  </Typography>
                </Box>
              </Stack>
            </CustomAccordion>
            <CustomAccordion
              icon={
                <ChevronDownIcon
                  stroke={theme.palette.color.primary}
                  strokeWidth="2"
                />
              }
              title={"Status"}
              background="transparent"
              accordionSummarysx={{
                "& .MuiButtonBase-root": {
                  flexDirection: "row-reverse !important",
                  gap: "10px",
                },
              }}
            >
              <Stack>
                {items.map((item, index) => (
                  <Box sx={{ pl: "10px", pr: "20px" }}>
                    {index !== 0 && (
                      <Box
                        sx={{
                          width: "0.5px",
                          height: "24px",
                          backgroundColor: (theme) =>
                            theme.palette.color[
                              item.disabled ? "lightgray" : "green"
                            ],
                          marginLeft: "11.5px",
                        }}
                      />
                    )}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          ...(item.disabled && {
                            opacity: "0.5",
                          }),
                        }}
                      >
                        <Box
                          sx={{
                            width: "24px",
                            height: "24px",
                            borderRadius: "50%",
                            backgroundColor: (theme) =>
                              theme.palette.color[
                                item.disabled ? "lightgray" : "green"
                              ],
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.icon}
                        </Box>
                        <Typography>{item.title}</Typography>
                      </Stack>
                      <Typography variant="body3">{item.date}</Typography>
                    </Stack>
                  </Box>
                ))}
              </Stack>
              {/* <Box
                sx={{
                  paddding: "20px",
                }}
              >
                <Stack
                  gap={2}
                  sx={
                    {
                      // marginLeft: "25px",
                    }
                  }
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.color.greenShade,
                        borderColor: (theme) => theme.palette.color.greenShade,
                        borderRadius: "18px",
                        width: "24px",
                        height: "24px",
                        position: "absolute",
                        left: 0,
                        "&::after": {
                          content: "''",
                          width: "1px",
                          height: "20px",
                          backgroundColor: (theme) =>
                            theme.palette.color.greenShade,
                          position: "absolute",
                          bottom: "-20px",
                          left: "50%",
                          zIndex: 0,
                        },
                      }}
                    />
                    <Typography>Visitor</Typography>
                    <Typography variant="body3">24 Apr, 2024</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.color.greenShade,
                        borderColor: (theme) => theme.palette.color.greenShade,
                        borderRadius: "18px",
                        width: "24px",
                        height: "24px",
                        position: "absolute",
                        left: 0,
                        "&::after": {
                          content: "''",
                          width: "1px",
                          height: "20px",
                          backgroundColor: (theme) =>
                            theme.palette.color.greenShade,
                          position: "absolute",
                          bottom: "-20px",
                          left: "50%",
                          zIndex: 0,
                        },
                      }}
                    />
                    <Typography>Qualified lead</Typography>
                    <Typography variant="body3">24 Apr, 2024</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.color.greenShade,
                        borderColor: (theme) => theme.palette.color.greenShade,
                        borderRadius: "18px",
                        width: "24px",
                        height: "24px",
                        position: "absolute",
                        left: 0,
                        "&::after": {
                          content: "''",
                          width: "1px",
                          height: "20px",
                          backgroundColor: (theme) =>
                            theme.palette.color.greenShade,
                          position: "absolute",
                          bottom: "-20px",
                          left: "50%",
                          zIndex: 0,
                        },
                      }}
                    />
                    <Typography>Paying customer</Typography>
                    <Typography variant="body3">28 Apr, 2024</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.color.lightgray,
                        borderColor: (theme) => theme.palette.color.lightgray,
                        borderRadius: "18px",
                        width: "24px",
                        height: "24px",
                        position: "absolute",
                        left: 0,
                      }}
                    />
                    <Typography>Funded Trader</Typography>
                    <Typography></Typography>
                  </Stack>
                </Stack>
              </Box> */}
            </CustomAccordion>
            <CustomAccordion
              icon={
                <ChevronDownIcon
                  stroke={theme.palette.color.primary}
                  strokeWidth="2"
                />
              }
              title={"Activity"}
              background="transparent"
              accordionSummarysx={{
                "& .MuiButtonBase-root": {
                  flexDirection: "row-reverse !important",
                  gap: "10px",
                },
              }}
            ></CustomAccordion>
            <CustomAccordion
              icon={
                <ChevronDownIcon
                  stroke={theme.palette.color.primary}
                  strokeWidth="2"
                />
              }
              title={"Notes"}
              background="transparent"
              accordionSummarysx={{
                "& .MuiButtonBase-root": {
                  flexDirection: "row-reverse !important",
                  gap: "10px",
                },
              }}
            ></CustomAccordion>
          </Box>
        )}
        {selectedTab === 1 && "Accounts"}
        {selectedTab === 2 && "Payouts"}
        {selectedTab === 3 && "Flags"}
        {selectedTab === 4 && "Tickets"}
        {selectedTab === 5 && "Orders"}
      </Box>
    </Paper>
  );
};

export default CustomerDetails;
