import React, { useState } from 'react';
import { Box, Stack, Grid, useMediaQuery, Tabs, Tab } from '@mui/material';
import ReportCard from '../../components/Affiliates/Reports/reportCard';
import StatCard from '../../components/Affiliates/Reports/statCard';
import InfoBox from '../../components/ui/InfoBox';
import SalesPerformance from '../../components/Statistics/Overview/SalesPerformance';
import AccountSizeAllocation from '../../components/Statistics/Overview/AccountSizeAllocation';
import ChallengeTypeAllocation from '../../components/Statistics/Overview/ChallengeTypeAllocation';
import Flag from '../../components/Icons/Flag';
import PayoutsIcon from '../../components/Icons/Payouts1';
import { BiSolidNavigation } from 'react-icons/bi';
import { RevenueChart } from '../../components/Statistics/Overview/RevenueChart';
import ChallengeSalesTotalRevenue from '../../components/Statistics/Overview/ChallengeSalesTotalRevenue';
import AverageTimeToCompletePhases from '../../components/Statistics/Overview/AverageTimeToCompletePhases';
import SuccessRatesByPhase from '../../components/Statistics/Overview/SuccessRatesByPhase';
import CustomerBehaviourAndRetention from '../../components/Statistics/Overview/CustomerBehaviourAndRetention';
import ShoppingBag from '../../components/Icons/ShoppingBag';

const StatisticsGeneral = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [selectedTab, setSelectedTab] = useState("1");

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

  return (
    <Box>
         <Grid container spacing={3} mt={1}>
                <Grid item sm={12} md={4}>
                    <ReportCard />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <StatCard title="New Clients" tooltip="Total amount of new clients" value="153,000" />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <StatCard title="Total active clients" tooltip="Total amount of active clients" value="53,000" />
                </Grid>
            </Grid>

            <Grid container spacing={3} mt={0.2}>
                <Grid item xs={6} sm={6} md={4}>
                    <InfoBox
                        item={{
                            title: "Total Revenue",
                            value: "$10,000.00",
                        }}
                        sx={{
                            background: (theme) => theme.palette.color.bg2,
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <InfoBox
                        item={{
                            title: "Total Sales",
                            value: "45",
                        }}
                        sx={{
                            background: (theme) => theme.palette.color.bg2,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <InfoBox
                        item={{
                            title: "Total Refunds",
                            value: "21",
                        }}
                        sx={{
                            background: (theme) => theme.palette.color.bg2,
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} mt={0.2}>
                <Grid item xs={12}>
                    <SalesPerformance />
                </Grid>
            </Grid>

            <Grid container spacing={1} mt={2}>
                <Grid item xs={12} sx={{ background: (theme) => theme.palette.color.lightgray, p: 2, borderRadius: "10px" }}>
                    <Box sx={{ width: 'fit-content', mt: 1 }}>
                        <Stack
                            direction="row"
                        >
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                aria-label="rules and objective"
                            >
                                <Tab value="1" label="Products" icon={<ShoppingBag />} iconPosition="start" />
                                <Tab value="2" label="Payout insights" icon={<PayoutsIcon />} iconPosition="start" />
                                <Tab value="3" label="Customer Support" icon={<BiSolidNavigation />} iconPosition="start" />
                                <Tab value="4" label="Flagged profiles" icon={<Flag />} iconPosition="start" />
                            </Tabs>
                        </Stack>
                    </Box>
                    {selectedTab === "1" && (
                        <Stack rowGap={1} mt={2}>
                            <RevenueChart />
                            <Grid container spacing={3} mt={0.1}>
                                <Grid item xs={6} sm={6} md={4}>
                                    <InfoBox
                                        item={{
                                            title: "Total Revenue",
                                            value: "$10,000.00",
                                        }}
                                        noStat={true}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <InfoBox
                                        item={{
                                            title: "Total Sales",
                                            value: "45",
                                        }}
                                        noStat={true}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <InfoBox
                                        item={{
                                            title: "Total Refunds",
                                            value: "21",
                                        }}
                                        noStat={true}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    )}
                    {selectedTab === "2" && (
                        <Stack rowGap={1} mt={2}>
                            <RevenueChart />
                            <Grid container spacing={3} mt={0.5}>
                                <Grid item xs={6} sm={6} md={4}>
                                    <InfoBox
                                        item={{
                                            title: "Total Revenue",
                                            value: "$10,000.00",
                                        }}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <InfoBox
                                        item={{
                                            title: "Total Sales",
                                            value: "45",
                                        }}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <InfoBox
                                        item={{
                                            title: "Total Refunds",
                                            value: "21",
                                        }}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    )}
                    {selectedTab === "3" && (
                        <Stack rowGap={1} mt={2} >
                            <RevenueChart />
                            <Grid container spacing={3} mt={0.5}>
                                <Grid item xs={6} sm={6} md={4}>
                                    <InfoBox
                                        item={{
                                            title: "Total Revenue",
                                            value: "$10,000.00",
                                        }}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <InfoBox
                                        item={{
                                            title: "Total Sales",
                                            value: "45",
                                        }}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <InfoBox
                                        item={{
                                            title: "Total Refunds",
                                            value: "21",
                                        }}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    )}
                    {selectedTab === "4" && (
                        <Stack rowGap={1} >
                            <SalesPerformance />
                            <Grid container spacing={3} mt={0.5}>
                                <Grid item xs={6} sm={6}>
                                    <InfoBox
                                        item={{
                                            title: "Total Revenue",
                                            value: "$10,000.00",
                                        }}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <InfoBox
                                        item={{
                                            title: "Total Sales",
                                            value: "45",
                                        }}
                                        sx={{
                                            background: (theme) => theme.palette.color.bg2,
                                        }}
                                    />
                                </Grid>

                            </Grid>
                        </Stack>
                    )}
                </Grid>

                <Grid item xs={12} mt={2}>
                    <RevenueChart />
                </Grid>

                <Grid item xs={12}>
                    <Stack display={"flex"} direction={isSmall ? "column" : "row"} gap={5} mt={3}>
                        <Box flex={3}>
                            <AccountSizeAllocation />
                        </Box>
                        <Box flex={2}>
                            <ChallengeTypeAllocation />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>


            <Grid container spacing={3} mt={0.2}>
                <Grid item xs={12}>
                    <CustomerBehaviourAndRetention />
                </Grid>
            </Grid>

            <Grid container spacing={3} mt={0.2}>
                <Grid item xs={12}>
                    <ChallengeSalesTotalRevenue />
                </Grid>
            </Grid>

            <Grid container spacing={3} mt={0.2}>
                <Grid item xs={12} md={7}>
                    <AverageTimeToCompletePhases />
                </Grid>
                <Grid item xs={12} md={5}>
                    <SuccessRatesByPhase />
                </Grid>
            </Grid>
    </Box>
  )
}

export default StatisticsGeneral