import { Box, Typography, Divider } from "@mui/material";
import React from "react";
import CheckIcon from "../../../Icons/Check";
import PayoutsIcon1 from "../../../Icons/Payouts1";
import FlagIcon from "../../../Icons/Flag";
import AffiliateIcon from "../../../Icons/AffiliateIcon";
import SupportIcon from "../../../Icons/Support";

const iconMapping = {
    payout: PayoutsIcon1,
    ticket: SupportIcon,
    copyTrader: FlagIcon,
    complete: AffiliateIcon,
  };

const CompletedNotification = ({ notification }) => {
    const IconComponent = iconMapping[notification.type] || CheckIcon;

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pt: "20px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5
                    }}
                >
                    <IconComponent />
                    <Box
                        sx={{
                            marginLeft: "5px",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            background: (theme) => theme.palette.color.green,
                        }}
                    ></Box>
                    <Typography variant="heading" sx={{ ml: 1 }}>
                        {notification.type === "payout" && "New Payout Request"}
                        {notification.type === "ticket" && "New Ticket Assigned"}
                        {notification.type === "copyTrader" && "Copy Trader Detected"}
                    </Typography>
                </Box>
                <Typography
                    variant="body3"
                    sx={{
                        whiteSpace: "nowrap",
                    }}
                >
                    · 4h ago
                </Typography>
            </Box>
            <Box
                sx={{
                    pl: "35px",
                    pt: "6px",
                }}
            >
                <Typography
                    variant="caption_500"
                    sx={{
                        "& span": {
                            color: (theme) => theme.palette.color.primary,
                            mx: "6px",
                        },
                    }}
                >
                    {notification.type === "payout" && "User"}
                    {notification.type === "ticket" && "Ticket"}
                    <span>{notification.user || notification.ticket}</span>
                    {notification.type === "payout" && "completed a payout of US"}
                    {notification.type === "ticket" && "has been resolved"}
                    {notification.type === "copyTrader" && "review has been completed"}
                    <span>{notification.amount}</span>
                </Typography>
                <Divider sx={{ mt: "24px" }} />
            </Box>
        </Box>
    );
};

export default CompletedNotification;