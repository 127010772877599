import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const SalesCompressionOverPeriod = () => {
    const theme = useTheme();

    const series = [
        {
            name: 'Current Period Sales',
            data: [1000, 980, 960, 940, 920, 900, 850, 860],
        },
        {
            name: 'Previous Period Sales',
            data: [600, 650, 720, 770, 850, 920, 950, 940],
        },
    ];

    const options = {
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            curve: 'straight',
            width: 3,
        },
        markers: {
            size: 3,
            strokeColors: 'transparent',
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
            labels: {
                show: true,
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            min: 200,
            max: 1600,
            tickAmount: 7,
            labels: {
                show: true,
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        grid: {
            show: false,
        },
        colors: ['#5F55EE', '#5EEDF8'],
        tooltip: {
            show: false,
            theme: 'dark',
        },
        legend: {
            show: false,
        },
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: "24px",
                width: "100%",
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
                mt: 2,
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" color={theme.palette.text.primary}>
                    Sales Compression over Period
                </Typography>
                <RoundFormSelect
                    options={[{ value: 'monthly', label: 'Monthly' }]}
                    defaultValue="monthly"
                />
            </Stack>

            <ReactApexChart options={options} series={series} type="line" height={300} />

            <Stack direction="row" justifyContent="center" mt={2} spacing={4}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6.75" cy="3" r="3" fill="#5F55EE" />
                        <rect x="0.75" y="2.5" width="12" height="1" fill="#5F55EE" />
                    </svg>
                    <Typography variant="caption" color={theme.palette.text.secondary}>
                        Current Period Sales
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.75 0L9.75 3L6.75 6L3.75 3L6.75 0Z" fill="#5EEDF8" />
                        <rect x="0.75" y="2.5" width="12" height="1" fill="#5EEDF8" />
                    </svg>
                    <Typography variant="caption" color={theme.palette.text.secondary}>
                        Previous Period Sales
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    );
};

export default SalesCompressionOverPeriod;