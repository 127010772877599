import { useTheme } from "@mui/material";

export default function LiveAccount({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.75 0.75H3.75C3.21957 0.75 2.71086 0.960714 2.33579 1.33579C1.96071 1.71086 1.75 2.21957 1.75 2.75V6.25M8.75 0.75V4.25C8.75 4.78043 8.96071 5.28914 9.33579 5.66421C9.71086 6.03929 10.2196 6.25 10.75 6.25H14.25M8.75 0.75L14.25 6.25M14.25 6.25V13.25C14.25 13.7804 14.0393 14.2891 13.6642 14.6642C13.2891 15.0393 12.7804 15.25 12.25 15.25H8.75M5.25 8.75H4.75C3.95435 8.75 3.19129 9.06607 2.62868 9.62868C2.06607 10.1913 1.75 10.9544 1.75 11.75V12.25C1.75 13.0456 2.06607 13.8087 2.62868 14.3713C3.19129 14.9339 3.95435 15.25 4.75 15.25H5.25M0.75 12H4.25"
        stroke={stroke || theme.palette.color.secondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
