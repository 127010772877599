import { alpha, Box, Stack, Typography } from "@mui/material";
import React from "react";

export default function StepperHeading({
  heading,
  subHeading,
  align = "center",
  mainHeadingSize = "18px",
  subHeadingSize = "13px",
  recommended = false,
}) {
  return (
    <Box>
      {recommended ? (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography
            variant="h4"
            component="h2"
            align={align}
            sx={{
              fontSize: mainHeadingSize,
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {heading}
          </Typography>
          {recommended && (
            <Typography
              sx={{
                color: (theme) => theme.palette.color.active,
                fontSize: "10px",
                lineHeight: "18px",
                fontWeight: "500",
                borderRadius: "8px",
                border: (theme) => `1px solid ${theme.palette.color.active}`,
                background: (theme) => alpha(theme.palette.color.active, 0.1),
                px: "4px",
              }}
            >
              Recommended
            </Typography>
          )}
        </Stack>
      ) : (
        <Typography
          variant="h4"
          component="h2"
          align={align}
          sx={{
            fontSize: mainHeadingSize,
            fontWeight: "500",
          }}
        >
          {heading}
        </Typography>
      )}
      <Typography
        variant="caption_500"
        align={align}
        sx={{
          display: "block",
          fontSize: subHeadingSize,
          fontWeight: 400,
          width: "100%",
          lineHeight: "18px",
          margin: "10px auto 0",
        }}
        mt={1}
      >
        {subHeading}
      </Typography>
    </Box>
  );
}
