import { useTheme } from "@mui/material";

export default function PhoneIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_3027_81728)">
        <path
          d="M15.4116 13.6256C15.5154 13.5565 15.6349 13.5144 15.7591 13.5031C15.8834 13.4918 16.0085 13.5117 16.1231 13.5609L20.5444 15.5419C20.6934 15.6056 20.8177 15.7158 20.8989 15.8561C20.98 15.9963 21.0135 16.1591 20.9944 16.32C20.8487 17.4085 20.3127 18.407 19.486 19.1299C18.6593 19.8528 17.5982 20.2509 16.5 20.25C13.1185 20.25 9.87548 18.9067 7.48439 16.5156C5.0933 14.1245 3.75 10.8815 3.75 7.50001C3.74916 6.40183 4.1472 5.34071 4.87009 4.51401C5.59298 3.68731 6.59152 3.15129 7.68 3.00563C7.84091 2.98652 8.00368 3.02003 8.14395 3.10115C8.28422 3.18227 8.39444 3.30664 8.45813 3.45563L10.4391 7.88063C10.4877 7.99429 10.5076 8.11821 10.4968 8.24137C10.486 8.36454 10.4449 8.48312 10.3772 8.58657L8.37375 10.9688C8.30269 11.076 8.26066 11.1998 8.25179 11.3282C8.24291 11.4565 8.26749 11.585 8.32313 11.7009C9.09844 13.2881 10.7391 14.9091 12.3309 15.6769C12.4475 15.7323 12.5766 15.7563 12.7053 15.7466C12.834 15.7369 12.958 15.6938 13.065 15.6216L15.4116 13.6256Z"
          stroke={stroke || theme.palette.color.secondary}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3027_81728">
          <rect width="24" height="24" fill="white" {...props} />
        </clipPath>
      </defs>
    </svg>
  );
}
