import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomTable from '../../Firm/Orders/Table';

const SuspiciousActivitiesTable = () => {

    const data = [
        {
            accountNumber: "102911050",
            accountSize: "100k",
            country: "Switzerland",
            countryFlag: "/images/country-icons/czk.svg",
            stage: "Phase 1",
            date: "2 Aug,2024 11:05 AM",
            activityType: "High-Frequency Trading",
        },
        {
            accountNumber: "102911050",
            accountSize: "400k",
            country: "Switzerland",
            countryFlag: "/images/country-icons/chf.svg",
            stage: "Live accounts",
            date: "2 Aug,2024 11:05 AM",
            activityType: "News Trading",
        },
        {
            accountNumber: "102911050",
            accountSize: "200k",
            country: "USA",
            countryFlag: "/images/country-icons/usd.svg",
            stage: "Phase 1",
            date: "2 Aug,2024 11:05 AM",
            activityType: "High-Frequency Trading",
        },
        {
            accountNumber: "102911050",
            accountSize: "400k",
            country: "Austria",
            countryFlag: "/images/country-icons/aus.svg",
            stage: "Phase 2",
            date: "2 Aug,2024 11:05 AM",
            activityType: "High-Frequency Trading",
        },
        {
            accountNumber: "102911050",
            accountSize: "400k",
            country: "Switzerland",
            countryFlag: "/images/country-icons/czk.svg",
            stage: "Phase 1",
            date: "2 Aug,2024 11:05 AM",
            activityType: "High-Frequency Trading",
        },
        {
            accountNumber: "102911050",
            accountSize: "200k",
            country: "Spain",
            countryFlag: "/images/country-icons/chf.svg",
            stage: "Phase 1",
            date: "2 Aug,2024 11:05 AM",
            activityType: "High-Frequency Trading",
        },
    ];

    const headcells = [
        {
            id: "accountNumber",
            label: "Account number",
            getCell: (row) => row.accountNumber,
        },
        {
            id: "accountSize",
            label: "Acc. size",
            getCell: (row) => row.accountSize,
        },
        {
            id: "country",
            label: "Country",
            getCell: (row) => (
                <Stack direction="row" alignItems="center">
                    <Avatar src={row.countryFlag} sx={{ width: 20, height: 20, mr: 1 }} />
                    <Typography>{row.country}</Typography>
                </Stack>
            ),
        },
        {
            id: "stage",
            label: "Stage",
            getCell: (row) => row.stage,
        },
        {
            id: "date",
            label: "Date",
            getCell: (row) => row.date,
        },
        {
            id: "activityType",
            label: "Activity Type",
            getCell: (row) => row.activityType,
        }
    ];

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.color.bg5,
                border: '1px solid',
                borderColor: (theme) => theme.palette.color.strockborder,
                p: '10px',
                borderRadius: '16px',
                mt: 3,
            }}
        >
            <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Reports of Suspicious Trading Activities
                </Typography>
            </Stack>

            <Box sx={{ overflowX: "auto", width: "100%" }}>
                <CustomTable
                    headcells={headcells}
                    rows={data}
                />
            </Box>
        </Box>
    );
};

export default SuspiciousActivitiesTable;