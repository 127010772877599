import React, { useState } from "react";
import { Box, Menu, MenuItem, Divider, Stack, Typography } from "@mui/material";
import { CreditCard, Settings } from "@mui/icons-material";

const style = {
  transition: "all 500ms ease-in-out",
  padding: "12px",
  borderRadius: "18px",
  ":hover": {
    background: (theme) => theme.palette.color.gray,
  },
};

const ProfileSection = ({ name }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "fit-content", position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          py: "12px",
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: (theme) => theme.palette.color.active,
            borderRadius: "50%",
          }}
        >
          <img src="/logo.avif" alt="logo" width="13px" />
        </Box>
        {name && <Typography variant="caption">{name}</Typography>}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          ".MuiPaper-root": {
            background: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.color.bg3
                : theme.palette.color.bg2,
            zIndex: 9999,
            boxShadow: "none",
            border: (theme) => `1px solid ${theme.palette.color.border}`,
            borderRadius: "12px",
            width: "282px",
            transition: "all 500ms ease-in-out",
            padding: "6px 3px",
          },
          marginLeft: { xs: "0px", lg: "40px" },
        }}
      >
        <MenuItem
          sx={{
            background: "transparent !important",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              sx={{
                width: "33px",
                height: "33px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: (theme) => theme.palette.color.active,
                borderRadius: "50%",
              }}
            >
              <img src="/logo.avif" alt="logo" width="14px" />
            </Box>
            <Stack spacing={0.8}>
              <Typography variant="heading">Unimetrics</Typography>
              <Typography
                variant="caption_500"
                sx={{
                  "& span": {
                    color: (theme) => theme.palette.color.green,
                  },
                }}
              >
                Online <span>• 12</span>
              </Typography>
            </Stack>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem sx={style}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Settings
              sx={{
                fontSize: "16px",
                color: (theme) => theme.palette.color.secondary,
              }}
            />
            <Typography sx={{ fontSize: "13px" }}>Settings</Typography>
          </Stack>
        </MenuItem>

        <MenuItem sx={style}>
          <Stack direction="row" spacing={1} alignItems="center">
            <CreditCard
              sx={{
                fontSize: "16px",
                color: (theme) => theme.palette.color.secondary,
              }}
            />
            <Typography sx={{ fontSize: "13px" }}>Billing</Typography>
          </Stack>
        </MenuItem>
        {/* Settings Your account Billing Vuetra help center Status page Terms and
        policies */}
        <Divider />
        <MenuItem sx={style}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Help Center
          </Typography>
        </MenuItem>
        <MenuItem sx={style}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Terms and Policies
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileSection;
