import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utils/getBaseQuery";

export const customerApis = createApi({
  reducerPath: "customerApis",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: () => `get-customers`,
    }),

    getCustomer: builder.query({
      query: (id) => `get-customer-by-id?id=${id}`,
    }),

    getCustomerAdditionInfo: builder.query({
      query: (id) => `get-customer-additional-info?id=${id}`,
    }),

    getCustomerStatistics: builder.query({
      query: (id) => `get-customer-statistics?id=${id}`,
    }),

    getCustomerOrders: builder.query({
      query: (id) => `get-customer-orders?id=${id}`,
    }),

    getCustomerPayouts: builder.query({
      query: (id) => `get-customer-payouts?id=${id}`,
    }),

    getCustomerSolvedTickets: builder.query({
      query: (id) => `get-customer-solved-tickets?id=${id}`,
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useGetCustomerAdditionInfoQuery,
  useGetCustomerStatisticsQuery,
  useGetCustomerOrdersQuery,
  useGetCustomerPayoutsQuery,
  useGetCustomerSolvedTicketsQuery,
} = customerApis;
