import * as React from "react";

import {
  Avatar,
  Box,
  Divider,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import TranslateIcon from "@mui/icons-material/Translate";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import { ChevronRight, ExpandMore, Settings } from "@mui/icons-material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import UserMenuCirlceIcon from "../../Icons/UserMenuCirlce";
import KeyBoardShortcuts from "./KeyBoardShortcuts";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearToken } from "../../../slices/authSlice";

export default function ProfileSection() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [shortcutsOpen, setShortcutsOpen] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const items = [
    {
      label: "Theme",
      icon: (
        <ImagesearchRollerIcon
          sx={{
            color: "inherit",
            fontSize: "16px",
          }}
        />
      ),
      arrow: true,
    },
    {
      label: "Change Language",
      icon: (
        <TranslateIcon
          sx={{
            color: "inherit",
            fontSize: "16px",
          }}
        />
      ),
      arrow: true,
    },
    {
      label: "Profile Settings",
      icon: (
        <Settings
          sx={{
            color: "inherit",
            fontSize: "16px",
          }}
        />
      ),
    },
    {
      divider: true,
    },
    {
      label: "Keyboard Shortcuts",
      icon: (
        <KeyboardCommandKeyIcon
          sx={{
            color: "inherit",
            fontSize: "16px",
          }}
        />
      ),
      onClick: () => setShortcutsOpen(true),
    },
    {
      label: "What's new",
      icon: (
        <BoltOutlinedIcon
          sx={{
            color: "inherit",
            fontSize: "16px",
          }}
        />
      ),
      onClick: () => navigate("/whats-new"),
    },
    {
      label: "Logout",
      icon: (
        <LogoutOutlinedIcon
          sx={{
            color: "inherit",
            fontSize: "16px",
          }}
        />
      ),
      onClick: () => {
        dispatch(clearToken());
        navigate("/login");
      },
    },
  ];

  return (
    <div>
      <Stack
        sx={{
          padding: "9px",
          background: (theme) => theme.palette.color.lightgray,
          borderRadius: "100px",
          cursor: "pointer",
          display: { xs: "none", lg: "flex" },
        }}
        onClick={handleClick}
        direction="row"
        spacing={0.5}
        alignItems="center"
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Avatar
            src="/images/person.avif"
            sx={{
              //   margin: "8px 0 8px 8px !important",
              cursor: "pointer",
              width: "25px",
              height: "25px",
            }}
            color="inherit"
          />
          <Box
            sx={{
              width: "9px",
              height: "9px",
              background: (theme) => theme.palette.color.cyan,
              borderRadius: "50%",
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          ></Box>
        </Box>
        <ExpandMore
          fontSize="small"
          sx={{
            transition: "all 0.3s ease",
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: "9px", width: "297px", py: "14px" }}>
          <Stack
            sx={{
              background: (theme) => theme.palette.color.gray,
              borderRadius: "12px",
              padding: "12px",
            }}
            spacing={3}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Avatar
                  src="/images/person.avif"
                  sx={{
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                  }}
                  color="inherit"
                />
                <Box
                  sx={{
                    width: "9px",
                    height: "9px",
                    background: (theme) => theme.palette.color.cyan,
                    borderRadius: "50%",
                    position: "absolute",
                    right: "0",
                    bottom: "0",
                  }}
                ></Box>
              </Box>
              <Stack spacing={0.7}>
                <Typography variant="subtitle1_500" sx={{ fontSize: "15px" }}>
                  Jens
                </Typography>
                <Typography variant="caption_500" sx={{ fontSize: "13px" }}>
                  Online
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box
                sx={{
                  position: "relative",
                  width: "36px",
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "24px",
                    borderRadius: "50%",
                    height: "24px",
                    background: (theme) => theme.palette.color.lightgray,
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "0.7px",
                    right: "0.7px",
                    cursor: "pointer",
                    "& svg": {
                      transition: "all 0.5s ease-in-out",
                      ":hover": {
                        rotate: "360deg",
                      },
                    },
                  }}
                >
                  <UserMenuCirlceIcon />
                </Box>
              </Box>
              <Typography variant="subtitle1">Firm name</Typography>
            </Box>
          </Stack>
          <Stack sx={{ mt: "12px" }} spacing={1}>
            {items.map((item, index) =>
              item.divider ? (
                <Box sx={{ p: "8px", width: "100%" }}>
                  <Divider />
                </Box>
              ) : (
                <Box
                  sx={{
                    px: "7px",
                    py: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    transition: "all 0.5s ease-in-out",
                    color: (theme) => theme.palette.color.secondary,
                    cursor: "pointer",
                    ":hover": {
                      pr: "0px",
                      color: (theme) => theme.palette.color.tertiary,
                    },
                  }}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick();
                    }
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    {item.icon}
                    <Typography variant="caption_500" sx={{ color: "inherit" }}>
                      {item.label}
                    </Typography>
                  </Stack>
                  {item.arrow && (
                    <ChevronRight sx={{ fontSize: "16px", color: "inherit" }} />
                  )}
                </Box>
              )
            )}
          </Stack>
        </Box>
      </Popover>
      <KeyBoardShortcuts open={shortcutsOpen} setOpen={setShortcutsOpen} />
    </div>
  );
}
