import { Box, Card, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { dashboardItems } from "../../static/dashboardItem";
import { useNavigate } from "react-router-dom";

const GradientBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(152deg, rgba(95, 85, 237, 0.47) 0%, rgb(0, 0, 0) 64.178%, rgba(95, 85, 237, 0.2) 100%)`,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  //   alignItems: "center",
  padding: "20px 0",
}));

const DashboardCards = () => {
  const navigate = useNavigate();
  return (
    <GradientBox>
      <Box
        sx={{
          width: "910px",
          height: "100%",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, lg: 3 }}
          sx={{
            height: "100%",
          }}
        >
          {dashboardItems.map((item, index) => (
            <Grid item xs={6} lg={4} key={item.title}>
              <Card
                key={item.title}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  height: "90px",
                  width: "100%",
                  cursor: "pointer",
                  position: "relative",
                  padding: "2px",
                  color: (theme) => theme.palette.color.secondary,
                  background: (theme) => theme.palette.color.bg3,
                  border: `1px solid transparent`,
                  ":hover": {
                    border: (theme) =>
                      `1px solid ${theme.palette.color.active}`,
                    color: (theme) => theme.palette.color.active,
                  },
                }}
                onClick={() => navigate(item.page)}
              >
                <item.icon size={20} />
                <Typography variant="h6">{item.title}</Typography>
                {item.count && (
                  <Typography
                    variant="caption_500"
                    sx={{
                      position: "absolute",
                      top: "12px",
                      right: "12px",
                    }}
                  >
                    ({item.count})
                  </Typography>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </GradientBox>
  );
};

export default DashboardCards;
