import React from 'react'
import { Grid, Box, Typography, useMediaQuery, Stack, Tooltip } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import InfoIcon2 from '../../Icons/Info2';
import { FiArrowUpRight } from 'react-icons/fi';
import RoundFormSelect from '../../ui/RoundFormSelect';

const RevenuePerSalesChannel = () => {
    const infoData = [
        {
            title: "Total Revenue",
            tooltip: "Total Revenue across all channels",
            value: "$500,000",
        },
        {
            title: "Top Performing Channel",
            tooltip: "Channel with the highest revenue",
            value: "$20,000",
            subtitle: "Social Media"
        },
        {
            title: "Total Revenue",
            tooltip: "Revenue change compared to last month",
            color: (theme) => theme.palette.color.green,
            value: "+15%",
            subtitle: "compared to last month",
        },
    ]

    const series = [
        {
            name: 'Partnerships',
            data: [12, 19, 27, 10]
        },
        {
            name: 'Direct Sales',
            data: [22, 25, 12, 8]
        },
        {
            name: 'Ads',
            data: [16, 23, 31, 10]
        }
    ];

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            },
            background: 'transparent',
        },
        colors: ['#5EEDF8', '#3B82F6', '#16E0BD'],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 6,
                columnWidth: '20%',
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: ['Website', 'Affiliate', 'Social Media', 'others'],
            labels: {
                style: {
                    colors: ['#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6'],
                }
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#8A96A6']
                },
                formatter: function (value) {
                    return '$' + value + 'k';
                }
            },
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return '$' + value + 'K';
                }
            }
        }
    };

    return (
        <Box>
            <Typography variant="h3" mb={2}>Revenue per sales channel</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                        {infoData.map((item, index) => (
                            <Grid item xs={12} key={index}>
                                <InfoBox item={item} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box sx={{
                        background: (theme) => theme.palette.color.bg5,
                        borderRadius: "18px",
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.color.border,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: 2,
                            }}
                        >
                            <Stack direction="row" spacing={2} alignItems="center">
                                {['Partnerships', 'Direct Sales', 'Ads'].map((item, index) => (
                                    <Stack key={index} direction="row" spacing={0.7} alignItems="center">
                                        <Box
                                            sx={{
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50%",
                                                backgroundColor: options.colors[index],
                                            }}
                                        />
                                        <Typography variant="caption">{item}</Typography>
                                    </Stack>
                                ))}
                            </Stack>
                            <RoundFormSelect
                                options={[{ value: "lastMonth", label: "last Month" }]}
                                defaultValue="lastMonth"
                            />
                        </Stack>
                        <Box sx={{ flexGrow: 1, p: 2, pt: 0 }}>
                            <ReactApexChart options={options} series={series} type="bar" height="100%" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default RevenuePerSalesChannel;

const InfoBox = ({ item, icon, sx }) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: "24px",
                width: "100%",
                height: "150px",
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",

                    color: (theme) => theme.palette.color.secondary,
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        alignItems: "center",
                    }}
                >
                    <Typography variant="caption">{item.title}</Typography>
                    <Tooltip title={item.tooltip} placement="top">
                        {icon ? icon : <InfoIcon2 fill="none" />}
                    </Tooltip>
                </Stack>
            </Box>
            <Box>
                <Typography variant="h1" fontSize={isSmall ? "20px" : "28px"} color={item.color ? item.color : (theme) => theme.palette.color.primary} mt={3}>{item.value}</Typography>
                {item?.subtitle && <Typography variant="body2" mt={1}>{item.subtitle}</Typography>}
            </Box>
            {item.percent && (
                <Stack
                    direction="row"
                    spacing={1}
                    mt={1}
                    sx={{
                        alignItems: "center",
                        color: (theme) => theme.palette.color.green,
                    }}
                >
                    <FiArrowUpRight size={16} />
                    <Typography variant="caption">{item.percent}</Typography>
                </Stack>
            )}
        </Box>
    );
};