import { Stack } from '@mui/material'
import React from 'react'
import AccountPerformanceTable from '../../components/Risk-Management/Time-Based-Statistics/AccountPerformanceTable'
import TrendAnalysisTable from '../../components/Risk-Management/Time-Based-Statistics/TrendAnalysisTable'

const TimeBasedStatistics = () => {
    return (
        <Stack sx={{ width: '100%', p: 3, pb: 10, spacing: 4 }}>
            <AccountPerformanceTable />
            <TrendAnalysisTable />
        </Stack>
    )
}

export default TimeBasedStatistics