import { Stack } from '@mui/material';
import React from 'react'
import ComplianceChart from '../../components/Risk-Management/Compliance-And-Monitoring/ComplianceChart';
import SuspiciousActivitiesTable from '../../components/Risk-Management/Compliance-And-Monitoring/SuspiciousActivitiesTable';
import ComplianceVoilation from '../../components/Risk-Management/Compliance-And-Monitoring/ComplianceVoilation';

const ComplianceAndMonitoring = () => {
    return (
        <Stack sx={{ width: '100%', p: 3, pb: 10, spacing: 4 }}>
            <ComplianceChart />
            <SuspiciousActivitiesTable />
            <ComplianceVoilation />
        </Stack>
    )
}

export default ComplianceAndMonitoring