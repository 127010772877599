import * as React from "react";
import Button from "@mui/material/Button";
import { Box, Popover, Stack, Typography, useTheme } from "@mui/material";
import { Done } from "@mui/icons-material";
import ChevronDownIcon from "../Icons/ChevronDown";

export default function TeamSelect({ type, types, onTypeChange, sx = {} }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeChange = (selectedType) => {
    onTypeChange(selectedType);
    handleClose();
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ borderRadius: "16px", p: 1, backgroundColor: theme.palette.color.bg }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          background: "transparent",
          py: "0",
          color: (theme) => theme.palette.color.secondary,
          borderLeft: `2px solid`,
          borderColor: type.color,
          borderRadius: "0px",
          fontSize: "13px",
          fontWeight: 500,
          width: "fit-content",
          flexGrow: 1,
          justifyContent: "space-between",
          "&:hover": {
            background: "transparent",
          },
          ...sx,
        }}
        endIcon={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg": {
                transition: "transform 0.3s",
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
              },
            }}
          >
            <ChevronDownIcon stroke={theme.palette.color.primary} />
          </Box>
        }
      >
        {type.label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            marginTop: "7px",
            padding: "12px",
            borderRadius: "20px",
            width: "240px",
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack spacing={2}>
          {types.map((t) => (
            <Stack
              key={t.value}
              spacing={1}
              onClick={() => handleTypeChange(t)}
              sx={{
                p: "12px",
                cursor: "pointer",
                borderRadius: "8px",
                "&:hover": {
                  background: (theme) => theme.palette.color.lightgray,
                },
                ...(t.value === type.value && {
                  background: (theme) => theme.palette.color.lightgray,
                }),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    color: (theme) => theme.palette.color[t.color],
                  }}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    {t.label}
                  </Typography>
                </Box>
                {t.value === type.value && (
                  <Done
                    sx={{
                      fontSize: "14px",
                      color: (theme) => theme.palette.color.active,
                    }}
                  />
                )}
              </Box>
              <Typography variant="body3">{t.description}</Typography>
            </Stack>
          ))}
        </Stack>
      </Popover>
    </Box>
  );
}