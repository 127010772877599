import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Close, InfoOutlined } from "@mui/icons-material";
import StepperHeading from "./Challenges/StepperHeading";
import StepperCard from "./Challenges/StepperCard";
import { BiPlus } from "react-icons/bi";
import ObjectiveAndRulesTabs from "./Challenges/ObjectiveAndRulesTabs";
import { PiCheckLight } from "react-icons/pi";
import ChallengeSettingsTab from "./Challenges/ChallengeSettingsTab";
import ProgressBar from "../ui/ProgressBar";
import ChevronRightIcon from "../Icons/ChevronRight";
import ChevronLeftIcon from "../Icons/ChevronLeft";
import FormInput from "../ui/FormInput";
import {
  useAddChallengesMutation,
  useGetLevaragesQuery,
} from "../../slices/api/firmSlice";
import SelectCurrency from "./Challenges/SelectCurreny";
import AccountSizes from "./Challenges/AccountSizes";

const countryData = [
  {
    icon: "/images/country-icons/usd.svg",
    value: "USD",
    code: "$",
  },
  {
    icon: "/images/country-icons/gbp.svg",
    value: "GBP",
    code: "GB£",
  },
  {
    icon: "/images/country-icons/eur.svg",
    value: "EUR",
    code: "€",
  },
  {
    icon: "/images/country-icons/czk.svg",
    value: "CZK",
    code: "Kč",
  },
  {
    icon: "/images/country-icons/cad.svg",
    value: "CAD",
    code: "CA$",
  },
  {
    icon: "/images/country-icons/aud.svg",
    value: "AUD",
    code: "AU$",
  },
  {
    icon: "/images/country-icons/chf.svg",
    value: "CHF",
    code: "CHF",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "841px",

  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "12px",
  overflow: "hidden",

  // overflowY: "auto",
  // "-ms-overflow-style": "none",
  // "scrollbar-width": "none",
  // "&::-webkit-scrollbar": {
  //   display: "none",
  // },
};

const steps = ["", "", "", "", ""];

const AddChallengesModal = ({ open, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    challenge: "",
    field2: "",
    field3: "",
  });
  const theme = useTheme();
  const { data } = useGetLevaragesQuery();

  const [formValues, setFormValues] = useState({
    name: "",
    leverage_id: 0,
    monthly_recurring: 0,
    refundable_fee: 0,
    scaling_plan: 0,
    profit_split: 0,
    before: "",
    after: "",
    news_limitations: "",
    funded_phase: 1,
  });
  const [phases, setPhases] = useState([]);
  const [phaseValues, setPhaseValues] = useState({
    name: "",
    profit_target: 0,
    min_trading_days: 0,
    min_volume_of_lots: 0,
    consecutive_wins: 0,
    min_trades: 0,
  });
  const [accountSizes, setAccountSizes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [index, setIndex] = useState(0);
  const [addChallenge] = useAddChallengesMutation();

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (formValues.name === "") {
          break;
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 0.5);
        }
        break;
      case 0.5:
        if (formValues.leverage_id === 0) {
          break;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 0.5);
        break;

      case 1:
        if (!currencies.length === 0) {
          break;
        }
        const currency = countryData.find((c) => c.value === currencies[index]);
        if (index < currencies.length) {
          setCurrency(currency);
          setIndex(index + 1);
          break;
        }
        setIndex(0);
        setCurrency(null);
        setActiveStep((prevActiveStep) => prevActiveStep + 0.5);
        break;
      case 1.5:
        if (phaseValues.name === "") break;
        else setActiveStep((prevActiveStep) => prevActiveStep + 0.5);
        break;
      case 2:
        setPhases([...phases, phaseValues]);
        setPhaseValues({
          name: "",
          profit_target: 0,
          min_trading_days: 0,
          min_volume_of_lots: 0,
          consecutive_wins: 0,
          min_trades: 0,
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;

      case 4:
        handleSubmit();
        break;

      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
    }

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    // phases.forEach((phase, index) => {
    //    obj = {
    //     ...obj,
    //     `phase[${index}][name]`: phase,
    //    }
    // })
    await addChallenge({
      ...formValues,
      phase: phases.map((p) => ({
        ...p,
        consistency_rule: JSON.stringify({
          phase: [
            {
              name: "Dollar",
              symbol: "$",
              code: "USD",
            },
            {
              name: "Euro",
              symbol: "€",
              code: "EUR",
            },
          ],
        }),
        daily_risk_control: 1,
        profit_protection: 1,
        drawdow_managment: 1,
        exposure_control: 1,
      })),
      account_size: JSON.stringify({ account_sizes: accountSizes }),
      currency: JSON.stringify({
        currency: countryData
          .filter((c) => currencies.includes(c.value))
          .map((a) => ({
            code: a.value,
            symbol: a.code,
          })),
      }),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleModalClose = () => {
    setActiveStep(0);
    setFormValues({
      name: "",
      leverage_id: 0,
      monthly_recurring: "",
      refundable_fee: "",
      scaling_plan: "",
      profit_split: 0,
      before: "",
      after: "",
      news_limitations: "",
      funded_phase: 1,
    });
    setPhaseValues({
      name: "",
      profit_target: 0,
      min_trading_days: 0,
      min_volume_of_lots: 0,
      consecutive_wins: 0,
      min_trades: 0,
    });
    setPhases([]);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      className="create_challenge_modal"
    >
      <Box sx={style}>
        <Box
          sx={{
            height: "636px",
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            background: (theme) => theme.palette.color.bg5,
          }}
        >
          <Stack spacing={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              sx={{
                p: "18px 12px",
                background: (theme) => theme.palette.color.bg2,
                position: "sticky",
                top: 0,
                zIndex: "999",
              }}
            >
              <Typography
                variant="h3"
                component="h2"
                mb={2}
                sx={{
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                Create Challenge
              </Typography>
              <IconButton
                onClick={() => {
                  handleModalClose();
                }}
              >
                <Close
                  sx={{
                    color: (theme) => theme.palette.color.secondary,
                    fontSize: "18px",
                  }}
                />
              </IconButton>
            </Stack>

            <Box
              sx={{
                px: "16px",
              }}
            >
              <ProgressBar steps={steps} page={activeStep} />
            </Box>
          </Stack>
          <Box
            // minHeight={370}
            // overflow={"auto"}
            sx={{
              px: "16px",
              paddingBottom: "32px",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <form style={{ width: "100%" }}>
                {activeStep === 0 && (
                  <Stack spacing={3}>
                    <StepperHeading
                      heading={"Name your Challenge"}
                      subHeading={
                        "Give your challenge a name that clients will remember"
                      }
                    />
                    {/* <TextField
                        name="challenge"
                        label=""
                        placeholder="Name challenge"
                        value={formData.challenge}
                        onChange={handleChange}
                        margin="auto"
                        className="custom_textfield"
                      /> */}
                    <FormInput
                      sx={{
                        width: "420px",
                        borderRadius: "18px",
                        mx: "auto",
                        height: "49px",
                        background: (theme) => theme.palette.color.borderpure,
                        fontSize: "13px",
                        // mt:
                      }}
                      placeholder="Name challenge"
                      value={formValues.name}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          name: e.target.value,
                        });
                      }}
                    />
                  </Stack>
                )}
                {activeStep === 0.5 && (
                  <>
                    <StepperHeading
                      heading={"Leverage"}
                      subHeading={
                        "Lastly, choose your desired leverage for the challenge."
                      }
                    />

                    <Grid
                      container
                      spacing={1.5}
                      sx={{ px: "20px", width: "100%" }}
                      mt={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {data?.data?.map((levrage, index) => {
                        return (
                          <Grid
                            item
                            xs={3}
                            key={index}
                            onClick={() => {
                              setFormValues({
                                ...formValues,
                                leverage_id: levrage.id,
                              });
                            }}
                          >
                            <StepperCard
                              sx={{
                                cursor: "pointer",
                              }}
                              isActive={formValues.leverage_id === levrage.id}
                            >
                              <Typography variant="subtitle1_500">
                                {levrage.leverage}
                              </Typography>
                            </StepperCard>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
                {
                  activeStep === 1 &&
                    (currency ? (
                      <AccountSizes
                        accountSizes={accountSizes}
                        setAccountSizes={setAccountSizes}
                        currencies={currencies}
                        currency={currency}
                      />
                    ) : (
                      <SelectCurrency
                        accountSizes={accountSizes}
                        currencies={currencies}
                        setCurrencies={setCurrencies}
                        setAccountSizes={setAccountSizes}
                        currency={currency}
                        setCurrency={setCurrency}
                      />
                    ))

                  // <>
                  //   <StepperHeading
                  //     heading={"Select your Currency"}
                  //     subHeading={
                  //       "You can select multiple currencies after completing your first!"
                  //     }
                  //   />
                  //   <Grid
                  //     container
                  //     spacing={1.5}
                  //     sx={{ px: "20px", width: "100%" }}
                  //     mt={2}
                  //   >
                  //     {countryData.map((country, index) => {
                  //       const isActive = currencies.includes(country.value);
                  //       return (
                  //         <Grid item xs={3} key={index}>
                  //           <StepperCard
                  //             isActive={currencies.includes(country.value)}
                  //             onClick={() => {
                  //               if (currencies.includes(country.value)) {
                  //                 setCurrencies(
                  //                   currencies.filter(
                  //                     (item) => item !== country.value
                  //                   )
                  //                 );
                  //               } else {
                  //                 setCurrencies([...currencies, country.value]);
                  //               }
                  //             }}
                  //             sx={{
                  //               cursor: "pointer",
                  //               "&:hover": {
                  //                 ...(!isActive && {
                  //                   border: (theme) =>
                  //                     `1px solid ${theme.palette.color.active}`,
                  //                 }),
                  //                 "& .MuiAvatar-root": {
                  //                   transition: "transform 0.3s",
                  //                   transform: "rotateX(360deg)",
                  //                 },
                  //               },
                  //             }}
                  //           >
                  //             <Stack
                  //               direction="row"
                  //               alignItems="center"
                  //               spacing={1.5}
                  //             >
                  //               {currencies.includes(country.value) && (
                  //                 <Box
                  //                   sx={{
                  //                     width: "9px",
                  //                     height: "9px",
                  //                     borderRadius: "50%",
                  //                     border: (theme) =>
                  //                       `1px solid ${theme.palette.color.greenShade}`,
                  //                     boxShadow:
                  //                       "rgba(47, 224, 162, 0.16) 0px 1px 13px 3px",
                  //                   }}
                  //                 />
                  //               )}
                  //               <Stack
                  //                 direction="row"
                  //                 alignItems="center"
                  //                 spacing={1}
                  //               >
                  //                 <Avatar
                  //                   alt={country.value}
                  //                   src={country.icon}
                  //                   sx={{
                  //                     width: 16,
                  //                     height: 16,
                  //                   }}
                  //                 />
                  //                 <Typography
                  //                   variant="caption_500"
                  //                   align="center"
                  //                   sx={{
                  //                     ...(currencies.includes(
                  //                       country.value
                  //                     ) && {
                  //                       color: (theme) =>
                  //                         theme.palette.color.primary,
                  //                     }),
                  //                   }}
                  //                 >
                  //                   {country.value}
                  //                 </Typography>
                  //               </Stack>
                  //             </Stack>
                  //           </StepperCard>
                  //         </Grid>
                  //       );
                  //     })}
                  //   </Grid>
                  // </>
                }
                {activeStep === 1.5 && (
                  <Stack spacing={3}>
                    <StepperHeading
                      heading={"Name your Phase"}
                      subHeading={
                        "To brand your Phases type for your customers."
                      }
                    />
                    <FormInput
                      sx={{
                        width: "420px",
                        borderRadius: "18px",
                        mx: "auto",
                        height: "49px",
                        background: (theme) => theme.palette.color.borderpure,
                        fontSize: "13px",
                        // mt:
                      }}
                      value={phaseValues.name}
                      onChange={(e) => {
                        setPhaseValues({
                          ...phaseValues,
                          name: e.target.value,
                        });
                      }}
                      placeholder="Ex. Phase 1, Instant Funded"
                    />
                  </Stack>
                )}
                {activeStep === 2 && (
                  <>
                    <ObjectiveAndRulesTabs
                      values={phaseValues}
                      setValues={setPhaseValues}
                    />
                  </>
                )}
                {activeStep === 3 && (
                  <>
                    <StepperHeading
                      heading={"Phases"}
                      subHeading={
                        "Congratulations! Your first phase is created. You can now add another phase or proceed to finalize"
                      }
                    />
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 3 }}
                      justifyContent="center"
                      alignItems="center"
                      mt={1}
                    >
                      {phases.map((phase, index) => (
                        <Grid item xs={4}>
                          <StepperCard
                            isActive={true}
                            sx={{
                              borderTop: "none",
                              borderBottom: "none",
                              background: "rgba(47, 224, 162, 0.05)",
                              width: "248px",
                              height: "70px",
                            }}
                          >
                            <Typography
                              variant="h3"
                              component="h2"
                              align={"center"}
                              sx={{
                                fontSize: 16,
                                marginRight: 1,
                              }}
                            >
                              <PiCheckLight color="#2FE0A2" />
                            </Typography>
                            <Typography
                              variant="body3"
                              align="center"
                              sx={{
                                display: "block",
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#2FE0A2",
                              }}
                            >
                              {phase.name}
                            </Typography>
                          </StepperCard>
                        </Grid>
                      ))}
                      <Grid item xs={4}>
                        <StepperCard
                          sx={{
                            width: "248px",
                            height: "70px",
                          }}
                          onClick={() => {
                            setActiveStep(1.5);
                          }}
                        >
                          <IconButton onClick={() => {}}>
                            <BiPlus color="#CAD0DB" size="18px" />
                          </IconButton>
                        </StepperCard>
                      </Grid>
                    </Grid>
                  </>
                )}
                {activeStep === 4 && (
                  <ChallengeSettingsTab
                    accountSizes={accountSizes}
                    setAccountSizes={setAccountSizes}
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
                )}
              </form>
            </Stack>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{
              px: "12px",
              background: "#101419",
              position: "sticky",
              bottom: 0,
              zIndex: "999",
              height: "56px",
              flexShrink: 0,
            }}
          >
            <Button
              sx={{
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
                color: (theme) => theme.palette.color.secondary,
                fontSize: "13px",
                fontWeight: "500",
              }}
              endIcon={<InfoOutlined />}
            >
              Help Center
            </Button>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                sx={{
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                  },
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "13px",
                  fontWeight: "500",
                  borderRadius: 20,
                }}
                startIcon={
                  <ChevronLeftIcon
                    stroke={theme.palette.color.secondary}
                    strokeWidth="2"
                  />
                }
                onClick={handlePrev}
              >
                Previous
              </Button>

              <Button
                variant="contained"
                endIcon={
                  <ChevronRightIcon
                    stroke={theme.palette.color.bg5}
                    strokeWidth="2"
                  />
                }
                onClick={handleNext}
                sx={{
                  mt: 2,
                  backgroundColor: (theme) => theme.palette.color.active,
                  color: (theme) => theme.palette.color.bg5,
                  fontSize: "13px",
                  fontWeight: "500",
                  "&:hover": {
                    color: (theme) => theme.palette.color.bg5,
                    backgroundColor: (theme) => theme.palette.color.active,
                    opacity: 0.8,
                  },
                  "& span": {
                    marginRight: "0px",
                  },
                  py: "4px",
                  borderRadius: 20,
                }}
              >
                {activeStep + 1 === steps.length ? "Finish" : "Next Step"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddChallengesModal;
