import React from 'react';
import { Box, Typography, Stack, Grid } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import InfoIcon2 from '../../Icons/Info2';
import RoundFormSelect from '../../ui/RoundFormSelect';

const ChallengeSalesTotalRevenue = () => {

    const series = [{
        name: 'Account Type 25k',
        data: [5000, 3000, 15000, 3000, 2000, 8000]
    }, {
        name: 'Account Type 50k',
        data: [19000, 12000, 10000, 22000, 22500, 9000]
    }, {
        name: 'Account Type 100k',
        data: [10000, 26000, 16000, 17000, 17000, 23000]
    }];

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            },
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 4,
                columnWidth: '25%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Country 1', 'Country 2', 'Country 3', 'Country 4', 'Country 5', 'Country 6'],
            labels: {
                style: {
                    colors: '#8A96A6'
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            title: {
                text: ''
            },
            labels: {
                formatter: function (val) {
                    return '$' + val + 'k'
                },
                style: {
                    colors: '#8A96A6'
                }
            }
        },
        fill: {
            opacity: 1,
            colors: ['#5EEDF8', '#5F55EE', '#FF69B4']
        },
        legend: {
            show: false
        },
        tooltip: {
            enabled: false,
        },
        grid: {
            show: false
        }
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.lightgray,
            borderRadius: '12px',
            p: 2,
            color: 'white'
        }}>
            <Box sx={{
                background: (theme) => theme.palette.color.bg2,
                borderRadius: '12px',
                p: 3,
                color: 'white'
            }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
                    <Typography variant="h5">Challenge sales-Total Revenue</Typography>
                    <Stack direction="row" spacing={2}>
                        <RoundFormSelect
                            options={[{ value: "countryWise", label: "Country wise" }]}
                            defaultValue="countryWise"
                        />
                        <RoundFormSelect
                            options={[{ value: "week", label: "Week" }]}
                            defaultValue="week"
                        />
                    </Stack>
                </Stack>

                <ReactApexChart options={options} series={series} type="bar" height={350} />

                <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account Type 25k</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account Type 50k</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#FF69B4', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Account Type 100k</Typography>
                    </Box>
                </Stack>

            </Box>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={4}>
                    <InfoBox title="Total Revenue" value="$10,000.00" icon={<InfoIcon2 />} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoBox
                        title="Revenue by Country"
                        value="$10,000.00"
                        icon={<InfoIcon2 />}
                    >
                        <RoundFormSelect
                            options={[{ value: "country", label: "Select country" }]}
                            defaultValue="country"
                            sx={{
                                border: "1px solid #5F55EE",
                                "& .MuiSelect-outlined": {
                                    border: "1px solid #5F55EE"
                                }
                            }}
                        />
                    </InfoBox>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InfoBox
                        title="Revenue by Account Type"
                        value="$10,000.00"
                        icon={<InfoIcon2 />}
                    >
                        <RoundFormSelect
                            options={[{ value: "accountType", label: "Account type" }]}
                            defaultValue="accountType"
                            sx={{
                                border: "1px solid #5F55EE",
                                "& .MuiSelect-outlined": {
                                    border: "1px solid #5F55EE"
                                }
                            }}
                        />
                    </InfoBox>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChallengeSalesTotalRevenue;


const InfoBox = ({ title, value, icon, children }) => (
    <Box sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        height: '126px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 1
    }}>
        <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2" color="#8A96A6">{title}</Typography>
            {icon}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
            {children}
            <Typography variant="h4">{value}</Typography>
        </Stack>
    </Box>
);