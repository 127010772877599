import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utils/getBaseQuery";

export const articleApis = createApi({
  reducerPath: "articleApis",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getArticles: builder.query({
      query: () => `get-articles`,
      providesTags: (result) => {
        return result
          ? [
              ...(result?.data?.data || [])?.map(({ id }) => ({
                type: "Articles",
                id,
              })),
              { type: "Articles", id: "ALL_ARTICLES" },
            ]
          : [{ type: "Articles", id: "ALL_ARTICLES" }];
      },
    }),
    addArticles: builder.mutation({
      query: (data) => ({
        url: "create-article",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Articles", id: "ALL_ARTICLES" }],
    }),

    updateArticle: builder.mutation({
      query: (data) => ({
        url: `update-article-by-id`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Articles", id: "ALL_ARTICLES" }],
    }),
    changeArticleStatus: builder.mutation({
      query: (data) => ({
        url: `update-article-status`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Articles", id: "ALL_ARTICLES" }],
    }),
    changeArticleVisibility: builder.mutation({
      query: (data) => ({
        url: `update-article-visibility`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Articles", id: "ALL_ARTICLES" }],
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useAddArticlesMutation,
  useChangeArticleStatusMutation,
  useChangeArticleVisibilityMutation,
  useUpdateArticleMutation,
} = articleApis;
