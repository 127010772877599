import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

import ChevronRightIcon from "../../../components/Icons/ChevronRight";
import HomeIcon from "../../../components/Icons/Home";
import CustomTable from "../../../components/Firm/Orders/Table";

const statuses = [
  { label: "Complete", value: "completed", color: "green" },
  { label: "Closed", value: "closed", color: "red" },
  { label: "Open", value: "open", color: "yellow" },
];

const headcells = [
  {
    id: "type",
    label: "Type",
    getCell: (row) => row.type,
  },
  {
    id: "status",
    label: "Status",
    getCell: (row) => {
      const status = statuses.find((statute) => statute.value === row.status);
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            borderRadius: "7px",
            width: "91px",
          }}
        >
          <Box
            sx={{
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              background: (theme) => theme.palette.color[status.color],
            }}
          ></Box>
          <Typography
            sx={{
              color: (theme) => theme.palette.color[status.color],
              lineHeight: "2.2em",
              fontWeight: "500",
            }}
          >
            {status.label}
          </Typography>
        </Stack>
      );
    },
  },
  {
    id: "match",
    label: "Match",
    getCell: (row) => row.match,
  },
  {
    id: "platform",
    label: "Platform",
    getCell: (row) => row.platform,
  },

  {
    id: "copiedAcc",
    label: "Copied acc.",
    getCell: (row) => row.copiedAcc,
  },
  {
    id: "date",
    label: "Date",
    getCell: (row) => row.date,
  },
];

const data = [
  {
    type: "Type A",
    status: "completed",
    match: "Match 1",
    platform: "Platform X",
    copiedAcc: "Acc-001",
    date: "11:05 24 Apr, 2024",
  },
  {
    type: "Type B",
    status: "closed",
    match: "Match 2",
    platform: "Platform Y",
    copiedAcc: "Acc-002",
    date: "12:10 25 Apr, 2024",
  },
  {
    type: "Type C",
    status: "open",
    match: "Match 3",
    platform: "Platform Z",
    copiedAcc: "Acc-003",
    date: "13:15 26 Apr, 2024",
  },
  {
    type: "Type D",
    status: "completed",
    match: "Match 4",
    platform: "Platform X",
    copiedAcc: "Acc-004",
    date: "14:20 27 Apr, 2024",
  },
  {
    type: "Type E",
    status: "closed",
    match: "Match 5",
    platform: "Platform Y",
    copiedAcc: "Acc-005",
    date: "15:25 28 Apr, 2024",
  },
  {
    type: "Type F",
    status: "open",
    match: "Match 6",
    platform: "Platform Z",
    copiedAcc: "Acc-006",
    date: "16:30 29 Apr, 2024",
  },
  {
    type: "Type G",
    status: "completed",
    match: "Match 7",
    platform: "Platform X",
    copiedAcc: "Acc-007",
    date: "17:35 30 Apr, 2024",
  },
  {
    type: "Type H",
    status: "closed",
    match: "Match 8",
    platform: "Platform Y",
    copiedAcc: "Acc-008",
    date: "18:40 01 May, 2024",
  },
  {
    type: "Type I",
    status: "open",
    match: "Match 9",
    platform: "Platform Z",
    copiedAcc: "Acc-009",
    date: "19:45 02 May, 2024",
  },
  {
    type: "Type J",
    status: "completed",
    match: "Match 10",
    platform: "Platform X",
    copiedAcc: "Acc-010",
    date: "20:50 03 May, 2024",
  },
];

const CustomeFlags = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const theme = useTheme();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  return (
    <Paper
      sx={{
        width: "100%",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          p: "24px",
          background: (theme) => theme.palette.color.active,
          width: "100%",
        }}
      >
        <Typography variant="h1">Flags ({sortedData.length})</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <HomeIcon
            width="20px"
            height="20px"
            fill={theme.palette.color.secondary}
          />
          <ChevronRightIcon />
          <Typography variant="body3">Your Firm</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Customers</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">11020022</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Flags</Typography>
        </Stack>
      </Stack>

      <Box
        sx={{
          p: "24px",
        }}
      >
        <CustomTable
          headcells={headcells}
          rows={sortedData}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          fontSize="11.5px"
        />
      </Box>
    </Paper>
  );
};

export default CustomeFlags;
