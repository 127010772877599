import { navItems, navItems2 } from "../static/navItems";

export const isPathMatching = (path, pathname) => {
  if (path === "/") return pathname === path;
  return pathname?.startsWith(path);
};

export const getNavItem = (pathname) => {
  const navItem = [...navItems, ...navItems2].find((item) =>
    isPathMatching(item.path, pathname)
  );

  return navItem;
};

export const getChildNavItem = (pathname) => {
  const navItem = [...navItems, ...navItems2].find((item) =>
    isPathMatching(item.path, pathname)
  );

  let childItem;
  let nestedChildItem;

  const ch = [...(navItem?.children || []), ...(navItem?.children2 || [])];

  ch?.forEach((child) => {
    if (isPathMatching(child.path, pathname)) {
      childItem = child;
    }

    child.children?.forEach((child1) => {
      if (child1.children) {
        child1.children.forEach((c) => {
          if (c.children) {
            c.children.forEach((cc) => {
              if (cc.path === pathname) {
                childItem = child1;
                nestedChildItem = cc;
              } else if (isPathMatching(cc.path, pathname) && !childItem) {
                childItem = {
                  ...child,
                  nested: true,
                };
                nestedChildItem = cc;
              }
            });
          } else {
            if (c.path === pathname) {
              childItem = child;
              nestedChildItem = c;
            } else if (isPathMatching(c.path, pathname) && !childItem) {
              childItem = {
                ...child,
                nested: true,
              };
              nestedChildItem = c;
            }
          }
        });
      } else {
        if (child1.path === pathname) {
          childItem = child1;
        } else if (isPathMatching(child1.path, pathname) && !childItem) {
          childItem = {
            ...child1,
            nested: true,
          };
        }
      }
      if (child1.children3) {
        child1.children3.forEach((c) => {
          c.forEach((cc) => {
            if (cc.children) {
              cc.children.forEach((ccc) => {
                if (ccc.path === pathname) {
                  childItem = child1;
                  nestedChildItem = ccc;
                } else if (isPathMatching(ccc.path, pathname) && !childItem) {
                  childItem = {
                    ...child,
                    nested: true,
                  };
                  nestedChildItem = ccc;
                }
              });
            } else {
              if (cc.path === pathname) {
                childItem = child1;
                nestedChildItem = cc;
              } else if (isPathMatching(cc.path, pathname) && !childItem) {
                childItem = {
                  ...child,
                  nested: true,
                };
                nestedChildItem = cc;
              }
            }
          });
        });
      }
    });
  });

  return { childItem, navItem, nestedChildItem };
};
