import * as React from "react";
import { Avatar, Box, Divider, InputBase, Popover, Stack, styled, Typography, useTheme } from "@mui/material";
import { AddCircle, PersonOutline } from "@mui/icons-material";
import { CiSearch } from "react-icons/ci";

const StatusDot = styled(Box)(({ theme, status }) => ({
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: status === true ? "#2EE0A1" : "yellow",
    position: "absolute",
    bottom: 0,
    right: 0,
    border: `2px solid ${theme.palette.background.paper}`,
}));

export default function AddMemberMenu({ members, allMembers }) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const id = open ? "simple-popover" : undefined;

    return (
        <Box sx={{ borderRadius: "16px", p: 1, backgroundColor: theme.palette.color.bg }}>
            <Stack direction="row" alignItems="center">
                {members.slice(0, 3).map((member, index) => (
                    <Box ml={"-5px"} key={index} sx={{ position: "relative", display: "inline-block", zIndex: 2 }}>
                        <Avatar src={member.image} sx={{ width: 35, height: 35, border: "2px solid black" }}>
                            {!member.image && member.name.charAt(0)}
                        </Avatar>
                        {!member.isAI && <StatusDot status={member.online} />}
                    </Box>
                ))}
                <Box sx={{ ml: "-5px", position: "relative", display: "inline-block" }} onClick={handleClick}>
                    <Avatar sx={{
                        width: 35,
                        height: 35,
                        border: "1px dotted gray",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "transparent",
                        color: "gray"
                    }}>
                        <PersonOutline fontSize="10px" />
                    </Avatar>
                    <Box sx={{
                        position: "absolute",
                        bottom: -6,
                        right: -6,
                    }}>
                        <AddCircle fontSize="5px" sx={{ color: (theme) => theme.palette.color.active }} />
                    </Box>
                </Box>
            </Stack>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        marginTop: "7px",
                        padding: "12px",
                        borderRadius: "20px",
                        width: "240px",
                    },
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Stack spacing={2}>
                    <Box display="flex" alignItems="center" mb={2} sx={{ color: (theme)=>theme.palette.color.secondary, fontSize: "13px"}}>
                        <CiSearch />
                        <InputBase
                            placeholder="Search or enter email"
                            sx={{ ml: 1, flex: 1 }}
                            inputProps={{ 'aria-label': 'search or enter email' }}
                        />
                    </Box>
                    <Divider />
                    <Stack spacing={0.5}>
                        {allMembers.map((member, index) => (
                            <Stack key={index} direction="row" alignItems="center" py={1} sx={{ ":hover": {
                                backgroundColor: theme.palette.color.lightgray,
                                borderRadius: "8px",
                                cursor: "pointer",
                            }}}>
                                <Avatar src={member.image} sx={{ width: 40, height: 40, backgroundColor: "#5EEDF8", color: "#FFFFFF", ml: 1 }}>
                                    {!member.image && member.name.charAt(0)}
                                </Avatar>
                                <Typography ml={2}>{member.name}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </Popover>
        </Box>
    );
}