import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';

const ActivityBox = ({ value, color }) => (
  <Box
    sx={{
      bgcolor: color,
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '52px',
      color: '#fff',
      fontWeight: 'bold',
    }}
  >
    {value}
  </Box>
);

const DailyTradingActivities = () => {
  const data = [
    { country: 'Germany', values: [20, 10, 25] },
    { country: 'Canada', values: [5, 12, 35] },
    { country: 'UK', values: [24, 30, 5] },
    { country: 'USA', values: [10, 17, 16] },
    { country: 'Germany', values: [16, 35, 28] },
    { country: 'Netherland', values: [24, 10, 35] },
  ];

  const colors = ['#8176FF', '#2FE0A2', '#ED55D4', '#5F55EE', '#8CADFF'];
  const accountTypes = ['25K', '50K', '100K'];

  const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

  return (
    <Box sx={{
      background: (theme) => theme.palette.color.bg5,
      border: '1px solid',
      borderColor: (theme) => theme.palette.color.strockborder,
      p: '10px',
      borderRadius: '16px',
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h3" sx={{ color: (theme) => theme.palette.color.primary, fontSize: "16px", fontWeight: 500 }}>Daily Trading Activities</Typography>
        <RoundFormSelect
          options={[{ value: "accountType", label: "Account type" }]}
          defaultValue="accountType"
        />
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={2}>
          {data.map((item, index) => (
            <Box key={index} sx={{ height: '48px', display: 'flex', alignItems: 'center', color: '#8A96A6' }}>
              {item.country}
            </Box>
          ))}
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={0.1}>
            {data.map((item, rowIndex) => (
              <Grid item xs={12} key={rowIndex}>
                <Grid container spacing={0.1}>
                  {item.values.map((value, colIndex) => (
                    <Grid item xs={4} key={colIndex}>
                      <ActivityBox value={value} color={getRandomColor()} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', mt: 1}}>
          {accountTypes.map((label) => (
            <Typography key={label} sx={{ color: (theme) => theme.palette.color.secondary}}>{label}</Typography>
          ))}
        </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DailyTradingActivities;