import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid, Stack, Typography } from "@mui/material";
import SupportIcon from "../../../Icons/Support";
import EyeOpenIcon from "../../../Icons/EyeOpen";
import { useSearchParams } from "react-router-dom";
import { useGetCustomerAdditionInfoQuery } from "../../../../slices/api/customerSlice";

const ticketDetails = [
  { title: "Open date", value: "11:05 July 15" },

  { title: "Time open", value: "1h 15min" },
  {
    title: "Assigned to",
    value: (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "18px",
            height: "18px",
            borderRadius: "50%",
            background: (theme) => theme.palette.color.active,
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: (theme) => theme.palette.color.green,
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          />
        </Box>
        <Typography variant="subtitle1_500">Agent</Typography>
      </Box>
    ),
  },
  { title: "Collection name", value: "Collection" },
];

const accounts = [
  {
    title: "Platform",
    value: (
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography variant="caption_500">Vuetra</Typography>
      </Box>
    ),
  },
  { title: "Challenge", value: "Type 1" },
  { title: "Acc. size", value: "200K" },
  { title: "Balance", value: "104,050.00 US$" },
];

const items = [
  {
    title: "Ticket summary title",
    icon: SupportIcon,
    value: 0,
    children: ticketDetails,
  },
  { title: "#1050105040", value: 1, children: accounts },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabSection() {
  const [value, setValue] = React.useState(0);
  let [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data: additionalInfo } = useGetCustomerAdditionInfoQuery(id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", p: { xs: "18px", lg: "24px" }, pt: "32px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Open tickets (1)" {...a11yProps(0)} />
        <Tab label="Active accounts (1)" {...a11yProps(1)} />
      </Tabs>

      {items.map((item) => (
        <CustomTabPanel value={value} index={item.value}>
          <Stack
            sx={{
              background: (theme) => theme.palette.color.borderpure,

              borderRadius: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "18px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                {item.icon && <item.icon />}
                <Typography variant="h4">{item.title}</Typography>
              </Box>
              <EyeOpenIcon />
            </Box>
            <Grid
              container
              sx={{
                p: "12px",
              }}
            >
              {item.children.map((item, index) => (
                <Grid item xs={12} md={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: "10px" }}
                  >
                    <Typography variant="subtitle1_500">
                      {item.title}
                    </Typography>
                    <Typography variant="caption_500">{item.value}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </CustomTabPanel>
      ))}
    </Box>
  );
}
