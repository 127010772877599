import { Box, Stack, Typography, useTheme } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const TicketsVolumeTrendsChart = () => {
  const theme = useTheme();
  const series = [
    {
      name: "Open Tickets",
      type: "line",
      data: [70, 120, 60, 100, 80, 30, 80],
    },
    {
      name: "New Tickets",
      type: "line",
      data: [65, 60, 80, 55, 70, 40, 70],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      background: "transparent",
      toolbar: {
        show: false,
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
    colors: [theme.palette.color.active, theme.palette.color.cyan],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [2, 2, 2],
    },
    xaxis: {
      categories: [
        "Day 1",
        "Day 2",
        "Day 3",
        "Day 4",
        "Day 5",
        "Day 6",
        "Day 7",
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      legend: {
        show: false,
      },
      labels: {
        style: {
          colors: "#8A96A6",
        },
      },
    },
    yaxis: {
      min: 20,
      max: 140,
      tickAmount: 6,
      title: {
        text: "Number of Tickets",
        style: {
          color: "#8A96A6",
        },
      },
      labels: {
        formatter: (val) => `${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    tooltip: {
      enabled: false,
      shared: true,
      intersect: false,
      y: {
        formatter: (val) => `$${val}`,
      },
    },
    theme: {
      mode: "dark",
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    markers: {
      size: 0,
    },
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        p: 2,
        mt: 3,
      }}
    >
      <Stack
        direction="row"
        pl={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5">Ticket Volume Trends</Typography>
        <Stack direction="row" gap={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.color.active,
                width: 14,
                height: 14,
                borderRadius: "50%",
                mr: 1,
              }}
            />
            <Typography variant="caption_400">Open Tickets</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.color.cyan,
                width: 14,
                height: 14,
                borderRadius: "50%",
                mr: 1,
              }}
            />
            <Typography variant="caption_400">New Tickets</Typography>
          </Box>
        </Stack>
      </Stack>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </Box>
  );
};

export default TicketsVolumeTrendsChart;
