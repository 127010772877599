import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import SearchIcon from "../../Icons/Search";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "623px",
  border: (theme) => `1px solid ${theme.palette.color.border}`,
  background: (theme) => theme.palette.color.bg2,
  borderRadius: "5px",
  p: "14px 24px",
  maxHeight: "386px",
  overflowY: "auto",
};

const StyledInput = styled(InputBase)(({ theme }) => ({
  border: `1px solid transparent`,
  outline: "none",
  padding: "10px 12px",
  backgroundColor: "#131517",
  borderRadius: "50px",
  fontSize: "16px",
  fontWeight: 500,
  width: "100%",
  transition: "border 0.3s ease",
  ":focus-within": {
    border: `1px solid ${theme.palette.color.active}`,
  },
  "&::placeholder": {
    color: "inherit",
    fontSize: "13px",
    fontWeight: 400,
  },
  "& input": {
    fontSize: "13px",
    fontWeight: 500,
    color: "inherit",
  },
}));

const items = [
  {
    title: "Create announcement",
    shortcut: "Use + to quickly create an announcement",
  },
  {
    title: "Create coupon",
    shortcut: "Use $ to quickly create a coupon",
  },
  {
    title: "Find Client",
    shortcut: "Use /client to search or @ to direct search",
  },
  {
    title: "Find Trading account",
    shortcut: "Use /account to search or # to direct search",
  },
  {
    title: "Find Ticket",
    shortcut: "Use /ticket to search or & to direct search",
  },
];

export default function SearchSection() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <IconButton onClick={handleOpen}>
        <SearchIcon width="18" height="18" />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        <Paper sx={style}>
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                fontSize: "10px",
              }}
            >
              <StyledInput
                placeholder="/Search and run a command"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                }}
              />
            </Box>

            <Stack spacing={2} sx={{ my: 3 }}>
              {items.map((item, index) => (
                <Stack spacing={3}>
                  <Stack spacing={1.5}>
                    <Typography variant="subtitle1_500">
                      {item.title}
                    </Typography>
                    <Typography variant="caption_500">
                      {item.shortcut}
                    </Typography>
                  </Stack>
                  <Divider />
                </Stack>
              ))}
            </Stack>
            <Box
              sx={{
                background:
                  "linear-gradient(rgba(12, 13, 15, 0) 0%, rgb(13, 14, 16) 100%)",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "50px",
              }}
            />
          </Box>
        </Paper>
      </Modal>
    </div>
  );
}
