import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import SearchInput from "../../components/ui/NewSearch";
import HelpCenterIcon from "../../components/Icons/HelpCenter";
import FormSelect from "../../components/ui/FormSelect1";
import FormInput from "../../components/ui/FormInput";
import dayjs from "dayjs";
import { useState } from "react";
import CalendarPopup from "../../components/ui/CalendarPopup";
import ResolutionTimeChart from "../../components/Customer-service/Metrics/ResolutionTime";
import TicketsByCategoryChart from "../../components/Customer-service/Metrics/TicketsByCategory";
import TestChart from "../../components/Customer-service/Metrics/testing";
import TicketsByPriorityLevelChart from "../../components/Customer-service/Metrics/TicketsByPriorityLevel";
import EscalationsChart from "../../components/Customer-service/Metrics/Escalations";
import TicketsResolvedChart from "../../components/Customer-service/Metrics/TicketsResolved";
import TicketsByChannelChart from "../../components/Customer-service/Metrics/TicketsByChannel";
import ResponseAndResolutionChart from "../../components/Customer-service/Metrics/ResponseAndResolutionTime";
import TicketsVolumeTrendsChart from "../../components/Customer-service/Metrics/TicketsVolumeTrends";
import TicketsDistributionByCategory from "../../components/Customer-service/Metrics/TicketsDistributionCategory";
import CustomTable from "../../components/Firm/Orders/Table";

const headcells = [
  { id: "ticketId", label: "Ticket ID", getCell: (row) => row.ticketId },
  { id: "type", label: "Alert Type", getCell: (row) => row.type },
  { id: "details", label: "Details", getCell: (row) => row.details },
  { id: "date", label: "Date", getCell: (row) => row.date },
];

const data = [
  {
    ticketId: "1050400",
    type: "SLA Breach",
    details: "Response Time Breach",
    date: "June 13, 2024 11:05",
  },
  {
    ticketId: "1050400",
    type: "SLA Breach",
    details: "Response Time Breach",
    date: "June 13, 2024 11:05",
  },
  {
    ticketId: "1050400",
    type: "SLA Breach",
    details: "Response Time Breach",
    date: "June 13, 2024 11:05",
  },
  {
    ticketId: "1050400",
    type: "SLA Breach",
    details: "Response Time Breach",
    date: "June 13, 2024 11:05",
  },
  {
    ticketId: "1050400",
    type: "SLA Breach",
    details: "Response Time Breach",
    date: "June 13, 2024 11:05",
  },
  {
    ticketId: "1050400",
    type: "SLA Breach",
    details: "Response Time Breach",
    date: "June 13, 2024 11:05",
  },
  {
    ticketId: "1050400",
    type: "SLA Breach",
    details: "Response Time Breach",
    date: "June 13, 2024 11:05",
  },
];

const data1 = [
  { title: "First Response Time", value: "1h" },
  { title: "Response Time Between Messages", value: "1m" },
  { title: "Time to Resolution", value: "12h" },
];

const data2 = [
  { title: "Customer Satisfaction Score", value: "9/10" },
  { title: "Net promoter score (NPS) ", value: "8/10" },
];

const CustomCard = ({ item }) => {
  return (
    <Card
      sx={{
        p: "24px",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        background: (theme) => theme.palette.color.bg5,
        border: (theme) => `1px solid ${theme.palette.color.border}`,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          variant="heading_400"
          sx={{
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          {item.title}
        </Typography>
        <HelpCenterIcon />
      </Stack>
      {item.value && (
        <Typography variant="h3" sx={{ fontWeight: "500" }}>
          {item.value}
        </Typography>
      )}
    </Card>
  );
};

export default function CustomerServiceMetrics() {
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  return (
    <Box
      sx={{
        padding: "20px",
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Typography variant="h4" sx={{ fontWeight: "500" }}>
          Metrics
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />
          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
        </Stack>
      </Stack>
      <Typography
        variant="h4"
        sx={{ fontWeight: "500", mt: "16px", mb: "12px" }}
      >
        Response and Processing Times
      </Typography>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        {data1.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CustomCard item={item} />
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="h4"
        sx={{ fontWeight: "500", mt: "16px", mb: "12px" }}
      >
        Satisfaction and Efficiency
      </Typography>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        {data2.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <CustomCard item={item} />
          </Grid>
        ))}
      </Grid>

      <ResolutionTimeChart />
      <Stack direction="row" spacing={2} mt={3}>
        {/* <TestChart /> */}
        <TicketsByCategoryChart />
        <TicketsByPriorityLevelChart />
      </Stack>
      <EscalationsChart />
      <TicketsResolvedChart />
      <Typography variant="h4" sx={{ my: "24px" }}>
        Channel Statistics
      </Typography>

      <TicketsByChannelChart />
      <ResponseAndResolutionChart />
      <Typography variant="h4" sx={{ my: "24px" }}>
        Real-time Monitoring and Alerts
      </Typography>
      <TicketsVolumeTrendsChart />
      <TicketsDistributionByCategory />

      <Typography variant="h4" sx={{ my: "24px" }}>
        Alerts for SLA Breaches or Escalations
      </Typography>
      <CustomTable headcells={headcells} rows={data} />
      <Box sx={{ height: "24px" }} />
    </Box>
  );
}
