import { Box, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import RoundFormSelect from '../../ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const TradedPositions = () => {

  const ChartContainer = styled(Box)({
    width: '100%',
    '& .apexcharts-toolbar': {
      display: 'none !important',
    },
  });

  const series = [
    { name: 'USA (18-25)', data: [6, 7, 1, 9] },
    { name: 'USA (26-35)', data: [1, 4, 7, 6] },
    { name: 'Canada (18-25)', data: [2, 8, 4, 5] },
    { name: 'Canada (26-35)', data: [4, 6, 3, 7] },
    { name: 'UK (18-25)', data: [9, 4, 3, 3] },
    { name: 'UK (26-35)', data: [7, 5, 9, 1] },
  ];

  const options = {
    siblingSpacing: 50,
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '60%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#2FE0A2', '#F55F82', '#FFC452', '#5EEDF8', '#5F55EE', '#ED55D4'],
    },
    xaxis: {
      categories: ['Account Type 1', 'Account Type 2', 'Account Type 3', 'Account Type 4'],
      labels: {
        style: {
          colors: ['#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6'],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      padding: {
        left: 10,
        right: 10,
      },
    },
    yaxis: {
      min: 0,
      max: 9,
      labels: {
        formatter: (val) => `${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    fill: {
      opacity: 1,
      colors: ['#1D232B', '#1D232B'],
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: ['#1D232B'],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        p: '10px',
        borderRadius: '16px',
      }}
    >
      <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h5">
        Traded Positions
        </Typography>
        <Stack direction="row" gap={2}>
          <RoundFormSelect
            options={[{ value: "selectAge", label: "Select Age" }]}
            defaultValue="selectAge"
          />
          <RoundFormSelect
            options={[{ value: "countryWise", label: "Country Wise" }]}
            defaultValue="countryWise"
          />
        </Stack>
      </Stack>

      <ChartContainer>
        <ReactApexChart
          options={options}
          series={series.map(({ name, data }, index) => ({
            name,
            data,
            type: 'column',
            fillColor: index === 0 ? {
              colors: ['#5EEDF8'],
              borderColor: '#5EEDF8',
              backgroundBarColors: ['#5EEDF8'],
              backgroundBarOpacity: 1,
            } : index === 1 ? {
              colors: ['#5F55EE'],
              borderColor: '#5F55EE',
              backgroundBarColors: ['#5F55EE'],
              backgroundBarOpacity: 1,
            } : {
              colors: ['#2FE0A2'], 
              borderColor: '#2FE0A2',
              backgroundBarColors: ['#2FE0A2'],
              backgroundBarOpacity: 1,
            },
          }))}
          type="bar"
          height={350}
        />
      </ChartContainer>

      <Stack direction="row" gap={2} alignItems={"center"} justifyContent={"center"}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">USA (18-25)</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#F55F82', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">USA (26-35)</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#FFC452', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Canada (18-25)</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Canada (26-35)</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">UK (18-25)</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#ED55D4', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">UK (26-35)</Typography>
          </Box>
        </Stack>

    </Box>
  );
};

export default TradedPositions;