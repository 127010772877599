import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import {
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NorthEastIcon from "@mui/icons-material/NorthEast";

import zIndex from "@mui/material/styles/zIndex";
import FormInput from "../../ui/FormInput";
import RetryIcon from "../../Icons/Retry";
import FormRadio from "../../ui/Radio";
import FormSwitch from "../../ui/FormSwitch";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FormSelect from "../../ui/FormSelect";
import EditIcon from "../../Icons/Edit";
import ExpandIcon from "../../Icons/Expand";
import CustomSwitch from "../../ui/Switch";
import VisibilityMenu from "./VisibilityMenu";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const labelStyle = {
  fontSize: "13px",
  fontWeight: "400",
};

const inputStyle = {
  fontSize: "13px",
  borderRadius: "14px",
};

const ViewArticle = ({ open, setOpen }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Drawer
        sx={
          {
            // borderRadius: "14px 0 14px 0",
            // overflow: "hidden",
          }
        }
        open={open}
        onClose={handleClose}
        anchor="right"
        // position="relative"
      >
        <Box
          sx={{
            position: "fixed",
            top: "18px",
            left: "700px",
          }}
        >
          <IconButton
            sx={{
              width: "56px",
              height: "56px",
              background: (theme) => theme.palette.color.borderpure,
              transition: "all 0.5s",
              "&:hover": {
                rotate: "180deg",
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon
              sx={{
                color: (theme) => theme.palette.color.primary,
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: "fixed",
            top: "24px",
            left: "-24px",
          }}
        >
          <IconButton
            sx={{
              width: "56px",
              height: "56px",
              background: (theme) => theme.palette.color.borderpure,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Paper
          sx={{
            width: "721px",
            height: "100vh",
            overflow: "hidden",
            background: (theme) => theme.palette.color.bg5,
          }}
        >
          <Box
            sx={{
              height: "100vh ",
              overflowY: "auto",
            }}
          >
            <Typography
              variant="h2"
              sx={{ fontWeight: "600", px: "24px", pt: "24px" }}
            >
              Title
            </Typography>
            <Box
              sx={{
                pt: "12px",
                px: "24px",
                borderBottom: (theme) =>
                  `1px solid ${theme.palette.color.borderpure}`,
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Settings" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Grid
                container
                rowSpacing={2}
                sx={{
                  px: "24px",
                  pb: "24px",
                }}
              >
                <Grid item xs={6} sm={3}>
                  <Typography variant="caption" sx={{ fontWeight: "400" }}>
                    Content type
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="heading" sx={{ fontWeight: "400" }}>
                    Article
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="caption" sx={{ fontWeight: "400" }}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormSelect
                    spacing={0}
                    sx={{
                      borderRadius: "18px",
                      marginTop: "0 !important",
                      fontSize: "13px",
                      py: 0,
                    }}
                    options={[
                      {
                        value: "draft",
                        label: "Draft",
                      },
                      {
                        value: "published",
                        label: "Published",
                      },
                    ]}
                    defaultValue="draft"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="caption" sx={{ fontWeight: "400" }}>
                    State
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormSwitch
                    flexDirection="row-reverse"
                    label="Used by AI"
                    flex
                    sx={{
                      my: 0,
                    }}
                    justifyContent="start"
                    labelsx={{
                      color: (theme) => theme.palette.color.green,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="caption" sx={{ fontWeight: "400" }}>
                    Last Edited
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="heading" sx={{ fontWeight: "400" }}>
                    Content type
                  </Typography>
                </Grid>
              </Grid>

              <Box
                sx={{
                  background: (theme) => theme.palette.color.borderpure,
                  padding: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body3">CONTENT</Typography>
                  <Stack direction="row" spacing={1}>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                    <IconButton>
                      <ExpandIcon />
                    </IconButton>
                  </Stack>
                </Box>
                <Stack spacing={1.5} sx={{ py: "12px" }}>
                  <Typography variant="h2">Bonorum et Malorum</Typography>
                  <Typography
                    variant="caption_500"
                    sx={{
                      lineHeight: "24px",
                      paragraphSpacing: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Typography>
                  <Typography variant="h5">Bonorum et Malorum</Typography>
                  <ul style={{ paddingInlineStart: "12px" }}>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <Typography
                        as="li"
                        variant="caption_500"
                        sx={{
                          lineHeight: "24px",
                          paragraphSpacing: "20px",
                          fontWeight: "400",
                        }}
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </Typography>
                    ))}
                  </ul>
                  <Typography variant="h5">Bonorum et Malorum</Typography>
                  <Typography
                    variant="caption_500"
                    sx={{
                      lineHeight: "24px",
                      paragraphSpacing: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Typography>
                </Stack>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Stack spacing={2} sx={{ p: "12px 24px" }}>
                <Typography variant="heading">
                  Add article to collection
                </Typography>
                <Typography
                  variant="caption_500"
                  sx={{
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  You can add your article to a collection. If an article isn't
                  added to any collection it won't be displayed on your Help
                  Center website. However, you can still share a direct link
                  with your customers.
                </Typography>
                <Stack direction="row" spacing={8} alignItems="center">
                  <Typography
                    variant="caption_500"
                    sx={{
                      fontWeight: "400",
                    }}
                  >
                    Collection
                  </Typography>
                  <FormSelect
                    options={[
                      { value: "collection1", label: "Collection 1" },
                      { value: "collection2", label: "Collection 2" },
                      { value: "collection3", label: "Collection 3" },
                    ]}
                    placeholder="Select collection"
                    sx={{
                      borderRadius: "18px",
                      py: 0,
                      marginTop: "0 !important",
                      fontSize: "13px",
                      width: "170px",
                    }}
                  />
                </Stack>
              </Stack>
              <Divider sx={{ my: "12px" }} />
              <Stack spacing={2} sx={{ p: "12px 24px" }}>
                <Typography variant="heading">Article visibility</Typography>
                <Typography
                  variant="caption_500"
                  sx={{
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  Determine who can view this article: everyone or only
                  company-level access.
                </Typography>
                <VisibilityMenu value="private" />
              </Stack>
              <Divider sx={{ my: "12px" }} />
              <Stack spacing={2} sx={{ p: "12px 24px" }}>
                <Typography variant="heading">Article suggestions</Typography>
                <Typography
                  variant="caption_500"
                  sx={{
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  Exclude from Article suggestions on the Messenger Home Screen
                  and Related Articles If this option is checked, we won’t
                  suggest this article to your visitors, leads and users.
                </Typography>
                <FormSwitch
                  label="Exclude from article suggestions"
                  flex
                  flexDirection="row-reverse"
                  justifyContent="start"
                />
              </Stack>
            </CustomTabPanel>
          </Box>
        </Paper>
      </Drawer>
    </React.Fragment>
  );
};

export default ViewArticle;
