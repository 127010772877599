import * as React from "react";
import Button from "@mui/material/Button";
import { Box, Popover, Stack, Typography, useTheme } from "@mui/material";
import ChevronDownIcon from "../../Icons/ChevronDown";
import { Done } from "@mui/icons-material";

export default function PublishedMenu({ type, types, id, changeStatus }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(type);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const popoverId = open ? "simple-popover" : undefined;

  const handleSubmit = async (data) => {
    if (changeStatus) await changeStatus(data);
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={
          <Box sx={{ color: (theme) => theme.palette.color[value.color] }}>
            {value.icon && (
              <value.icon
                sx={{
                  color: (theme) => theme.palette.color[value.color],
                  fontSize: "14px",
                }}
                width="16"
                height="16"
              />
            )}
          </Box>
        }
        sx={{
          background: "transparent",
          py: "0",
          color: (theme) => theme.palette.color[value.color],
          fontSize: "11.5px",
          fontWeight: 500,
          "&:hover": {
            background: "transparent",
            "& svg": {
              opacity: 1,
            },
          },
        }}
        endIcon={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: (theme) => theme.palette.color[value.color],
              "& svg": {
                opacity: anchorEl ? 1 : 0,
                transition: "transform 0.3s",
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
              },
            }}
          >
            <ChevronDownIcon stroke={theme.palette.color[value.color]} />
          </Box>
        }
      >
        {value.selectedLabel || value.label}
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            padding: "12px",
            borderRadius: "18px",
            width: "240px",
            background: (theme) => theme.palette.color.bg2,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack spacing={2}>
          {types.map((t) => (
            <Stack
              spacing={1}
              sx={{
                p: "12px",
                cursor: "pointer",
                borderRadius: "8px",
                "&:hover": {
                  background: (theme) => theme.palette.color.gray,
                },
                ...(t.value === value.value && {
                  background: (theme) => theme.palette.color.gray,
                }),
              }}
              onClick={() => {
                setValue(t);
                handleSubmit({ id, status: t.value });
                handleClose();
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    color: (theme) => theme.palette.color[t.color],
                  }}
                >
                  {t.value === value.value && (
                    <Done
                      sx={{
                        fontSize: "14px",
                        color: (theme) => theme.palette.color.green,
                      }}
                    />
                  )}
                  <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    {value.value === t.value
                      ? t.selectedLabel || t.label
                      : t.label}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body3">
                {value.value === t.value
                  ? t.selectedDescription
                  : t.description}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Popover>
    </div>
  );
}
