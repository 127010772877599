import { useTheme } from "@mui/material";
import * as React from "react";

const HighFrequencyIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.75 16.25C14.139 16.25 16 11.687 16 8.5V5.75M16 5.75L13.75 8.25M16 5.75L18.25 8.25M4.75 4.75V19.25H19.25"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HighFrequencyIcon;