import React from "react";
import { Box, Button, Drawer, IconButton, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FormatIcon from "../../Icons/Format";
import MessageList from "./Inbox/MessageList";
import MessageDetail from "./Inbox/MessageDetail";
import { FaAngleRight } from "react-icons/fa";

const iconStyle = {
  color: (theme) => theme.palette.color.secondary,
  fontSize: "22px",
};

const archiveMessages = [
  { name: "Name", text: "This is a Message", time: "Just now", unread: 0, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
];

const messages = [
  { name: "Name", text: "This is a Message", time: "11:05", unread: 2, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 2, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 2, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
  { name: "Name", text: "This is a Message", time: "11:05", unread: 0, avatar: "/images/person.avif", isOnline: true },
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ width: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default function Inbox() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [selectedMessage, setSelectedMessage] = React.useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTabChange = (event, newValue) => setValue(newValue);

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <FormatIcon />
      </IconButton>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            background: "transparent",
            boxShadow: "none",
            height: "fit-content",
            padding: "24px",
          },
        }}
        open={open}
        onClose={handleClose}
        anchor="right"
      >
        <Paper
          sx={{
            width: "460px",
            height: "90vh",
            overflow: "hidden",
            background: (theme) => theme.palette.color.bg5,
            border: (theme) => `1px solid ${theme.palette.color.strockborder}`,
            borderRadius: "24px",
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {selectedMessage ? (
            <>
              <MessageDetail message={selectedMessage} onClose={() => setSelectedMessage(null)} />
            </>
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  padding: "18px 24px",
                }}
              >
                <Box sx={{ display: "flex", gap: "12px" }}>
                  <Typography variant="h6">Inbox</Typography>
                  <Box
                    sx={{
                      width: "24px",
                      height: "24px",
                      background: (theme) => theme.palette.color.lightgray,
                      borderRadius: "4px",
                      border: `1px solid #15191F`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="heading">9</Typography>
                  </Box>
                </Box>
                <IconButton onClick={handleClose}>
                  <CloseIcon sx={iconStyle} />
                </IconButton>
              </Stack>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleTabChange} aria-label="Inbox tabs" sx={{ width: '100%' }}>
                  <Tab label="Inbox" sx={{ width: '50%' }} />
                  <Tab label="Archive" sx={{ width: '50%' }} />
                </Tabs>
              </Box>
              <Box sx={{ flex: 1, overflowY: 'auto' }}>
                <TabPanel value={value} index={0}>
                  <MessageList messages={messages} onSelect={setSelectedMessage} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <MessageList messages={archiveMessages} onSelect={setSelectedMessage} />
                </TabPanel>
              </Box>
              <Box sx={{ padding: "12px 24px", position: 'sticky', bottom: 0, background: (theme) => theme.palette.color.darkgray, py: 2, alignItems: "center" }}>
                <Stack display="flex" direction="row" fontSize="15px" justifyContent="center" alignItems="center" gap={1.5}>
                  <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.secondary, fontSize: "14px", fontWeight: "500" }}>View All</Typography>
                  <FaAngleRight />
                </Stack>
              </Box>
            </>
          )}
        </Paper>
      </Drawer>
    </Box>
  );
}