import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FormInput from "../../components/ui/FormInput";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FormSelect from "../../components/ui/FormSelect";
import CustomSwitch from "../../components/ui/Switch";
import CountrySelect from "../../components/ui/CountrySelect";
import {
  useGetCountriesQuery,
  useGetCurrenciesQuery,
} from "../../slices/api/generalSlice";

import { backend_url } from "../../static/backendurl";
import {
  useGetTenantSettingsQuery,
  useUpdateTenantSettingsMutation,
} from "../../slices/api/settingSlice";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const General = () => {
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState("");
  const [savedLogo, setSavedLogo] = useState("");
  const [formValues, setFormValues] = useState({
    store_name: "",
    contact_email: "",
    country_id: "",
    currency_id: "",
    tax_inclusive: 0,
    apple_pay: 0,
    google_pay: 0,
    subscription_renewal_email: 0,
  });

  const { data: countries } = useGetCountriesQuery();
  const { data } = useGetTenantSettingsQuery();
  const { data: currencies } = useGetCurrenciesQuery();
  const [updateSettings] = useUpdateTenantSettingsMutation();

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);

      const reader = new FileReader();
      reader.onload = () => {
        // setLogo(reader.result);
        setLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("logo", logo);
    formData.append("store_name", formValues.store_name);
    formData.append("contact_email", formValues.contact_email);
    formData.append("country_id", formValues.country_id);
    formData.append("currency_id", formValues.currency_id);
    formData.append("tax_inclusive", formValues.tax_inclusive);
    formData.append("apple_pay", formValues.apple_pay);
    formData.append("google_pay", formValues.google_pay);
    formData.append(
      "subscription_renewal_email",
      formValues.subscription_renewal_email
    );

    await updateSettings(formData);
  };

  useEffect(() => {
    setFormValues({
      store_name: data?.data?.store_name || "",
      contact_email: data?.data?.contact_email || "",
      country_id: data?.data?.country_id || "",
      currency_id: data?.data?.currency_id || "",
      tax_inclusive: data?.data?.tax_inclusive || 0,
      apple_pay: data?.data?.apple_pay || 0,
      google_pay: data?.data?.google_pay || 0,
      subscription_renewal_email: data?.data?.subscription_renewal_email || 0,
    });
    setSavedLogo(data?.data?.logo || "");
  }, [data]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "24px",
          py: "7px",
          mt: "15px",
        }}
      >
        <Box>
          <Typography variant="h2" sx={{ mb: 2 }}>
            General
          </Typography>
          <Typography variant="caption">
            View and update your store details
          </Typography>
        </Box>
        <Button
          size="small"
          sx={{
            p: "5px",
            px: "10px",
            borderRadius: "32px",
            bgcolor: (theme) => theme.palette.color.active,
            color: "white",
            ":hover": { background: (theme) => theme.palette.color.active },
          }}
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </Box>

      <Divider />
      <Box
        sx={{
          p: "24px",
        }}
      >
        <Grid container spacing={2} rowSpacing={3}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1} justifyContent={"center"}>
              <Typography variant="heading">Logo</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                gap: "14px",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  ...(!(savedLogo || logoUrl) && {
                    bgcolor: "#07B869",
                  }),
                  color: (theme) => theme.palette.color.primary,
                  fontWeight: "600",
                  fontSize: "28.5px",
                  padding: savedLogo || logoUrl ? "5px" : "10px",
                  width: "50px",
                  height: "50px",
                  "& img": {
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  },
                }}
                src={
                  (savedLogo ? `${backend_url}${savedLogo}` : logoUrl) ||
                  undefined
                }
              >
                {!(savedLogo || logoUrl) && "J"}
              </Avatar>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                sx={{
                  borderRadius: "32px",
                  background: (theme) => theme.palette.color.bg4,
                  ":hover": {
                    background: (theme) => theme.palette.color.bg2,
                  },
                  color: (theme) => theme.palette.color.primary,
                  height: "fit-content",
                }}
              >
                Choose
                <VisuallyHiddenInput type="file" onChange={handleLogoChange} />
              </Button>
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", fontWeight: "500" }}
              >
                JPG, GIF or PNG. 1MB Max.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Store name</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Appears on receipts, invoices, and more
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              placeholder="Enter store name"
              value={formValues.store_name}
              onChange={(e) =>
                setFormValues({ ...formValues, store_name: e.target.value })
              }
              sx={{
                background: (theme) => alpha(theme.palette.color.border, 0.65),
                border: (theme) =>
                  `1px solid ${alpha(theme.palette.color.border, 0.65)}`,
                borderRadius: "14px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Store URL *</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Your store is visible at this address
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              placeholder="Enter store url"
              defaultValue="www.yourdomain.com"
              sx={{
                background: (theme) => alpha(theme.palette.color.border, 0.65),
                border: (theme) => `1px solid ${alpha(theme.palette.color.border, 0.65)}`,
                borderRadius: "14px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Contact email</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Where customers can contact you for support. Appears on receipts
                and invoices.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              startAdornment={
                <InputAdornment position="end">
                  <MailOutlineIcon
                    sx={{
                      color: (theme) => theme.palette.color.secondary,
                      fontSize: "18px",
                    }}
                  />
                </InputAdornment>
              }
              placeholder="example@site.com"
              value={formValues.contact_email}
              onChange={(e) =>
                setFormValues({ ...formValues, contact_email: e.target.value })
              }
              sx={{
                background: (theme) => alpha(theme.palette.color.border, 0.65),
                border: (theme) =>
                  `1px solid ${alpha(theme.palette.color.border, 0.65)}`,
                borderRadius: "14px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Country</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Where your business is registered
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <CountrySelect
              countries={[...(countries?.data || [])]}
              country_id={formValues.country_id}
              onChange={(id) =>
                setFormValues({ ...formValues, country_id: id })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Currency</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Your store's main currency
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              placeholder="Select currency"
              value={formValues.currency_id}
              onChange={(e) =>
                setFormValues({ ...formValues, currency_id: e.target.value })
              }
              options={[...(currencies?.data || [])].map((currency) => ({
                value: currency.id,
                label: `${currency.code} - ${currency.name}`,
              }))}
              fullWidth
              sx={{
                background: (theme) => alpha(theme.palette.color.border, 0.65),
                border: (theme) =>
                  `1px solid ${alpha(theme.palette.color.border, 0.65)}`,
                borderRadius: "14px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Tax-inclusive pricing</Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: "500", width: "90%" }}
              >
                Should prices include sales tax and be deducted from the total,
                or calculated separately and charged on top? · Help↗
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <CustomSwitch
                checked={formValues.tax_inclusive === 1}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    tax_inclusive: formValues.tax_inclusive === 1 ? 0 : 1,
                  });
                }}
              />
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Enable tax-inclusive pricing
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Apple Pay</Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: "500", width: "90%" }}
              >
                Allow customers to pay using Apple Pay
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <CustomSwitch
                checked={formValues.apple_pay === 1}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    apple_pay: formValues.apple_pay === 1 ? 0 : 1,
                  });
                }}
              />
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Accept Apple Pay payments
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Google Pay</Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: "500", width: "90%" }}
              >
                Allow customers to pay using Google Pay
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <CustomSwitch
                checked={formValues.google_pay === 1}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    google_pay: formValues.google_pay === 1 ? 0 : 1,
                  });
                }}
              />
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Accept Google Pay payments
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">
                Subscription renewal emails
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: "500", width: "90%" }}
              >
                Send customers email receipts for subscription payments
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <CustomSwitch
                checked={formValues.subscription_renewal_email === 1}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    subscription_renewal_email:
                      formValues.subscription_renewal_email === 1 ? 0 : 1,
                  });
                }}
              />
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Enable subscription renewal emails
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default General;
