import React from 'react';
import ApexCharts from 'react-apexcharts';
import { Box, Stack, Typography } from '@mui/material';

const PayoutsChart = () => {
  const series = [
    {
      name: 'Total Payout',
      type: 'area',
      data: [250, 300, 370, 200, 350, 250, 300, 350, 250, 300, 350],
    },
    {
      name: 'Per Click',
      data: [200, 250, 300, 250, 200, 250, 200, 250, 300, 250, 200],
    },
    {
      name: 'Per Sale',
      data: [50, 80, 90, 130, 150, 200, 180, 100, 110, 120, 130],
    },
  ];

  const options = {
    chart: {
      type: 'line',
      background: 'transparent',
      toolbar: {
        show: false,
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
    xaxis: {
      categories: ['12 May', '13 May', '14 May', '15 May', '16 May', '17 May', '18 May', '19 May', '20 May', '21 May', '22 May'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#8A96A6",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 400,
      labels: {
        formatter: (val) => `$${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    colors: ['#5F55EE', '#CF6307', '#4B5565'],
    stroke: {
      width: [3, 3, 1],
      curve: 'smooth',
      dashArray: [0, 0, 5],
    },
    fill: {
      type: 'gradient',
      opacity: [0.15, 1, 1],
      gradient: {
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: [0.28, 1, 1],
        opacityTo: [0.05, 1, 1],
        type: 'vertical',
        stops: [0, 100, 100],
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false,
    },
    toolbar: {
      show: false
    }
  };

  return (
    <Box sx={{
      border: '1px solid',
      borderColor: (theme) => theme.palette.color.darkgray,
      background: (theme) => theme.palette.color.bg2,
      borderRadius: "18px",
      color: 'white',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: '0 auto',
      width: '100%',
      mt: 3,
    }}>
      <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h2" sx={{ fontSize: "18px" }}>
          Payouts
        </Typography>
        <Stack direction="row">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 14, height: 14, border: '2px solid #5F55EE', borderRadius: '50%', mr: 1, backgroundColor: 'transparent' }} />
            <Typography variant="body2">Total Payout</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <Box sx={{ width: 14, height: 14, border: '2px solid #CF6307', borderRadius: '50%', mr: 1, backgroundColor: 'transparent' }} />
            <Typography variant="body2">Per Click</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <Box sx={{ width: 14, height: 14, border: '2px dashed #4B5565', borderRadius: '50%', mr: 1, backgroundColor: 'transparent' }} />
            <Typography variant="body2">Per Sale</Typography>
          </Box>
        </Stack>
      </Stack>
      <ApexCharts options={options} series={series} type="line" height={350} />
    </Box>
  );
};

export default PayoutsChart;
