import { Avatar, Box, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import CustomTable from '../../Firm/Orders/Table';
import RoundFormSelect from '../../ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const ComplianceVoilation = () => {

  const ChartContainer = styled(Box)({
    width: '100%',
    '& .apexcharts-toolbar': {
      display: 'none !important',
    },
  });

  const series = [
    {
      name: 'Account type A',
      data: [27, 17, 20, 25],
    },
    {
      name: 'Account type B',
      data: [15, 31, 35, 30],
    },
    {
      name: 'Account type C',
      data: [25, 25, 25, 35],
    }
  ];

  const options = {
    siblingSpacing: 50,
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '40%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#5EEDF8', '#5F55EE', '#2FE0A2'],
    },
    xaxis: {
      categories: ['Account type A', 'Account type B', 'Account type C', 'Account type D'],
      labels: {
        style: {
          colors: ['#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6'],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      padding: {
        left: 10,
        right: 10,
      },
    },
    yaxis: {
      min: 0,
      max: 35,
      title: {
        text: "Number of Violations",
        style: {
          color: '#8A96A6',
        },
      },
      labels: {
        formatter: (val) => `${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    fill: {
      opacity: 1,
      colors: ['#1D232B', '#1D232B'],
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: ['#1D232B'],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
  };

  const data = [
    {
      accountType: "Account type A",
      violationType: "Rule Breach",
      country: "Switzerland",
      countryFlag: "/images/country-icons/chf.svg",
      age: 20,
      actionsTaken: "Action",
    },
    {
      accountType: "Account type B",
      violationType: "Rule Breach",
      country: "Austria",
      countryFlag: "/images/country-icons/aus.svg",
      age: 25,
      actionsTaken: "Action",
    },
    {
      accountType: "Account type C",
      violationType: "Risk Parameter Violation",
      country: "Denmark",
      countryFlag: "/images/country-icons/den.svg",
      age: 23,
      actionsTaken: "Action",
    },
    {
      accountType: "Account type C",
      violationType: "Risk Parameter Violation",
      country: "Hungary",
      countryFlag: "/images/country-icons/hun.svg",
      age: 25,
      actionsTaken: "Action",
    },
    {
      accountType: "Account type A",
      violationType: "Other Violations",
      country: "Spain",
      countryFlag: "/images/country-icons/es.svg",
      age: 20,
      actionsTaken: "Action",
    },
    {
      accountType: "Account type A",
      violationType: "Other Violations",
      country: "Germany",
      countryFlag: "/images/country-icons/de.svg",
      age: 25,
      actionsTaken: "Action",
    }
  ];

  const headcells = [
    {
      id: "accountType",
      label: "Account type",
      getCell: (row) => row.accountType,
    },
    {
      id: "violationType",
      label: "Violation Type",
      getCell: (row) => row.violationType,
    },
    {
      id: "country",
      label: "Country",
      getCell: (row) => (
        <Stack direction="row" alignItems="center">
          <Avatar src={row.countryFlag} sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography>{row.country}</Typography>
        </Stack>
      ),
    },
    {
      id: "age",
      label: "Age",
      getCell: (row) => row.age,
    },
    {
      id: "actionsTaken",
      label: "Actions Taken",
      getCell: (row) => row.actionsTaken,
    }
  ];

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        p: '10px',
        borderRadius: '16px',
        mt: 3,
      }}
    >
      <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h5">
          Compliance Violations and Subsequent Actions
        </Typography>
        <Stack direction="row" gap={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 14, height: 14, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Rules Breaches</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 14, height: 14, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Risk Parameter Violations</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 14, height: 14, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Other Violations</Typography>
          </Box>
          <RoundFormSelect
            options={[{ value: "accountType", label: "Account Type" }]}
            defaultValue="accountType"
          />
        </Stack>
      </Stack>

      <ChartContainer>
        <ReactApexChart
          options={options}
          series={series.map(({ name, data }, index) => ({
            name,
            data,
            type: 'column',
            fillColor: index === 0 ? {
              colors: ['#5EEDF8'], // Gradient color for profit
              borderColor: '#5EEDF8',
              backgroundBarColors: ['#5EEDF8'],
              backgroundBarOpacity: 1,
            } : index === 1 ? {
              colors: ['#5F55EE'], // Gradient color for loss
              borderColor: '#5F55EE',
              backgroundBarColors: ['#5F55EE'],
              backgroundBarOpacity: 1,
            } : {
              colors: ['#2FE0A2'], // Gradient color for index 2
              borderColor: '#2FE0A2',
              backgroundBarColors: ['#2FE0A2'],
              backgroundBarOpacity: 1,
            },
          }))}
          type="bar"
          height={350}
        />
      </ChartContainer>

      <Box sx={{ overflowX: "auto", width: "100%", border: '1px solid', borderColor: (theme) => theme.palette.color.strockborder, borderRadius: '16px', }}>
        <CustomTable
          headcells={headcells}
          rows={data}
        />
      </Box>
    </Box>
  );
};

export default ComplianceVoilation;