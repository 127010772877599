import { useTheme } from "@mui/material";
import * as React from "react";

const FingerprintIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.52415 7.53906V11.0555C9.52415 12.4473 8.39185 13.5796 7 13.5796"
        stroke={stroke || theme.palette.color.primary}
        strokeWidth={1.25}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.62354 6.0922C4.97373 5.11826 5.90672 4.41992 6.99974 4.41992C7.56084 4.41992 8.07979 4.60397 8.49935 4.91484"
        stroke={stroke || theme.palette.color.primary}
        strokeWidth={1.25}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.47607 11.0553V8.80078"
        stroke={stroke || theme.palette.color.primary}
        strokeWidth={1.25}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.72735V8.27344"
        stroke={stroke || theme.palette.color.primary}
        strokeWidth={1.25}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4682 8.07162V6.96826C12.4682 3.94822 10.02 1.5 6.99996 1.5C3.97993 1.5 1.53174 3.94822 1.53174 6.96826V11.0317C1.53174 14.0518 3.97996 16.5 6.99996 16.5C9.80991 16.5 12.1248 14.3805 12.4333 11.653"
        stroke={stroke || theme.palette.color.primary}
        strokeWidth={1.25}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FingerprintIcon;