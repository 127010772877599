import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import SearchInput from "../../components/ui/NewSearch";
import HelpCenterIcon from "../../components/Icons/HelpCenter";
import FormSelect from "../../components/ui/FormSelect1";
import FormInput from "../../components/ui/FormInput";
import dayjs from "dayjs";
import { useState } from "react";
import CalendarPopup from "../../components/ui/CalendarPopup";

const data1 = [
  { title: "Resolved Tickets", value: "120", input: true },
  { title: "Open Tickets", value: "100" },
  { title: "My Tickets", value: "6" },
  { title: "Closed Tickets", value: "120" },
  { title: "Solved Tickets", value: "20" },
  { title: "Email Collected", value: "140" },
];

const data2 = [
  { title: "Tickets Handled", value: "230" },
  { title: "Customer Satisfaction Score", value: "9/10" },
  { title: "Resolution Time", value: "4h" },
  { title: "Reopened Tickets", value: "8" },
];

const data3 = [
  {
    title: "CRM Integration Status",
    value1: (
      <Stack spacing={1}>
        <Typography
          variant="caption_400"
          sx={{
            color: (theme) => theme.palette.color.primary,
            "& span": {
              color: (theme) => theme.palette.color.secondary,
            },
          }}
        >
          <span>Active Tickets: </span> 123
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant="caption_400"
            sx={{
              color: (theme) => theme.palette.color.secondary,
            }}
          >
            Status:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                background: (theme) => theme.palette.color.green,
              }}
            ></Box>
            <Typography
              sx={{
                color: (theme) => theme.palette.color.green,
                fontSize: "10px",
              }}
            >
              Connected
            </Typography>
          </Box>
        </Box>
      </Stack>
    ),
  },
  {
    title: "Ticketing System Integration",
    value1: (
      <Stack spacing={1}>
        <Typography
          variant="caption_400"
          sx={{
            color: (theme) => theme.palette.color.primary,
            "& span": {
              color: (theme) => theme.palette.color.secondary,
            },
          }}
        >
          <span>Last Sync Time:</span> August 12, 2024, 14:30
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="caption_400">Status:</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                background: (theme) => theme.palette.color.green,
              }}
            ></Box>
            <Typography
              sx={{
                color: (theme) => theme.palette.color.green,
                fontSize: "10px",
              }}
            >
              Connected
            </Typography>
          </Box>
        </Box>
      </Stack>
    ),
  },
];

const data4 = [
  {
    title: "Knowledge Base",
    value1: (
      <FormInput
        placeholder="https://www.example.com"
        sx={{
          "& .MuiInputBase-input": {
            px: "6px",
          },
        }}
        startAdornment={
          <Typography
            sx={{
              fontSize: "inherit",
              color: (theme) => theme.palette.color.secondary,
              textAlign: "center",
              pl: "10px",
            }}
          >
            Link:
          </Typography>
        }
      />
    ),
  },
  {
    title: "Self-Help Portal",
    value1: (
      <FormInput
        placeholder="https://www.example.com"
        sx={{
          "& .MuiInputBase-input": {
            px: "6px",
          },
        }}
        startAdornment={
          <Typography
            sx={{
              fontSize: "inherit",
              color: (theme) => theme.palette.color.secondary,
              textAlign: "center",
              pl: "10px",
            }}
          >
            Link:
          </Typography>
        }
      />
    ),
  },
];

const data312 = [
  {
    title: "CRM and Ticketing Systems Integration",
    children: [
      {
        title: "CRM Integration Status",
        value1: (
          <Stack spacing={1}>
            <Typography
              variant="caption_400"
              sx={{
                color: (theme) => theme.palette.color.primary,
                "& span": {
                  color: (theme) => theme.palette.color.secondary,
                },
              }}
            >
              <span>Active Tickets: </span> 123
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="caption_400"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                }}
              >
                Status:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    background: (theme) => theme.palette.color.green,
                  }}
                ></Box>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.color.green,
                    fontSize: "10px",
                  }}
                >
                  Connected
                </Typography>
              </Box>
            </Box>
          </Stack>
        ),
      },
      {
        title: "Ticketing System Integration",
        value1: (
          <Stack spacing={1}>
            <Typography
              variant="caption_400"
              sx={{
                color: (theme) => theme.palette.color.primary,
                "& span": {
                  color: (theme) => theme.palette.color.secondary,
                },
              }}
            >
              <span>Last Sync Time:</span> August 12, 2024, 14:30
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="caption_400">Status:</Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    background: (theme) => theme.palette.color.green,
                  }}
                ></Box>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.color.green,
                    fontSize: "10px",
                  }}
                >
                  Connected
                </Typography>
              </Box>
            </Box>
          </Stack>
        ),
      },
    ],
  },
  {
    title: "Knowledge Base and Self-Help Portals Links",
    children: [
      {
        title: "Knowledge Base",
        value1: (
          <FormInput
            placeholder="https://www.example.com"
            sx={{
              "& .MuiInputBase-input": {
                px: "6px",
              },
            }}
            startAdornment={
              <Typography
                sx={{
                  fontSize: "inherit",
                  color: (theme) => theme.palette.color.secondary,
                  textAlign: "center",
                  pl: "10px",
                }}
              >
                Link:
              </Typography>
            }
          />
        ),
      },
      {
        title: "Self-Help Portal",
        value1: (
          <FormInput
            placeholder="https://www.example.com"
            sx={{
              "& .MuiInputBase-input": {
                px: "6px",
              },
            }}
            startAdornment={
              <Typography
                sx={{
                  fontSize: "inherit",
                  color: (theme) => theme.palette.color.secondary,
                  textAlign: "center",
                  pl: "10px",
                }}
              >
                Link:
              </Typography>
            }
          />
        ),
      },
    ],
  },
];

const CustomCard = ({ item }) => {
  return (
    <Card
      sx={{
        p: "24px",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        background: (theme) => theme.palette.color.bg5,
        border: (theme) => `1px solid ${theme.palette.color.border}`,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          variant="heading_400"
          sx={{
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          {item.title}
        </Typography>
        <HelpCenterIcon />
        {item.input && (
          <FormSelect
            options={[
              { value: "week", label: "Week" },
              { value: "month", label: "Month" },
              { value: "year", label: "Year" },
            ]}
            defaultValue="week"
            sx={{
              border: (theme) => `1px solid ${theme.palette.color.active}`,
              borderRadius: "24px",
              fontSize: "12px",
              "& .MuiInputBase-input": {
                padding: "6px 12px",
              },
            }}
          />
        )}
      </Stack>
      {item.value && (
        <Typography variant="h3" sx={{ fontWeight: "500" }}>
          {item.value}
        </Typography>
      )}
      {item.value1 && item.value1}
    </Card>
  );
};

export default function CustomerServiceOverview() {
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  return (
    <Box
      sx={{
        padding: "20px",
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Typography variant="h4" sx={{ fontWeight: "500" }}>
          Overview
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />
          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
        </Stack>
      </Stack>
      <Grid container spacing={2} sx={{ my: "6px", width: "100%" }}>
        {data1.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CustomCard item={item} />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h4" sx={{ fontWeight: "500", my: "24px" }}>
        Employee Performance
      </Typography>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        {data2.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <CustomCard item={item} />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h4" sx={{ fontWeight: "500", my: "24px" }}>
        CRM and Ticketing Systems Integration
      </Typography>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        {data3.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <CustomCard item={item} />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h4" sx={{ fontWeight: "500", my: "24px" }}>
        Knowledge Base and Self-Help Portals Links
      </Typography>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        {data4.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <CustomCard item={item} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ height: "24px" }} />
    </Box>
  );
}
