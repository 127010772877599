import { Box, Grid, Stack, styled, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import LineChart from '../../components/Charts/LineChart';
import RoundFormSelect from '../../components/ui/RoundFormSelect';
import { FiArrowUpRight } from "react-icons/fi";
import InfoIcon2 from '../../components/Icons/Info2';

const FinancialPerformance = () => {
    const infoBoxData = [
        {
            title: "Total Profit",
            value: "$734,567",
            percent: "+3.5%",
            tooltip: "Total Profit"
        },
        {
            title: "Total Revenue",
            value: "$17,000",
            percent: "+3.5%",
            tooltip: "Total Revenue"
        },
        {
            title: "Average Order Value (AOV)",
            value: "$23,000",
            percent: "+7.5%",
            tooltip: "Average Order Value (AOV)"
        },
    ];

    return (
        <Box sx={{ width: '100%' }} pb={10} mt={3}>
            <Grid container spacing={3}>
                {infoBoxData.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <InfoBox
                            item={item}
                            sx={{
                                background: (theme) => theme.palette.color.bg2,
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            <GrossProfitMarginChart />
            <RefundStatisticsChart />
        </Box>
    )
}

export default FinancialPerformance;

const GrossProfitMarginChart = () => {
    const ChartContainer = styled(Box)({
        width: '100%',
        height: '100%',
        '& .apexcharts-toolbar': {
            display: 'none !important',
        },
    });

    const series = [
        {
            name: 'Revenue',
            data: [26000, 19000, 21000, 12000, 21000, 14000],
        },
        {
            name: 'Cost of Goods Sold',
            data: [10000, 15000, 23000, 15000, 16000, 25000],
        },
        {
            name: 'Gross Profit Margin',
            data: [18000, 13000, 17000, 18000, 12000, 12000],
        },
    ];

    const options = {
        chart: {
            type: 'bar',
            stacked: false,
            toolbar: {
                show: false,
            },
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#5EEDF8', '#5F55EE', '#ED55D4'],
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            labels: {
                style: {
                    colors: '#8A96A6',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#8A96A6",
                },
                formatter: function (val) {
                    return '$' + val;
                },
            },
            min: 0,
            max: 30000,
            tickAmount: 6,
        },
        fill: {
            opacity: 1,
            colors: ['#1D232B', '#1D232B'],
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: "vertical",
                shadeIntensity: 1,
                gradientToColors: ['#1D232B'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        tooltip: {
            enabled: false,
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        colors: ['#5EEDF8', '#5F55EE', '#FF69B4'],
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 1,
                },
            },
        },
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
            color: 'white',
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            margin: '0 auto',
            width: '100%',
            mt: 2,
        }}>
            <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Gross Profit Margin
                </Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Revenue</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Cost of Goods Sold</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 14, height: 14, backgroundColor: '#FF69B4', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Gross Profit Margin</Typography>
                    </Box>
                    <RoundFormSelect
                        options={[{ value: "month", label: "Month" }]}
                        defaultValue="month"
                    />
                </Stack>
            </Stack>
            <ChartContainer>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                />
            </ChartContainer>
        </Box>
    );
};

const RefundStatisticsChart = () => {
    const theme = useTheme();

    return (
        <Grid item xs={12} mt={3}
            sx={{
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
            }}
        >
            <Stack spacing={3} sx={{ p: "4px" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        color: (theme) => theme.palette.color.secondary,
                    }}
                >
                    <Typography variant="h4">Refund Statistics</Typography>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 12, height: 12, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
                            <Typography variant="body2">Value</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
                            <Typography variant="body2">Count</Typography>
                        </Box>
                        <RoundFormSelect
                            options={[{ value: "country", label: "Country" }]}
                            defaultValue="country"
                        />
                    </Stack>
                </Box>
                <LineChart
                    height="300"
                    categories={['Austria', 'Switzerland', 'Denmark', 'Hungary', 'Spain', 'Germany', 'France', 'Norway']}
                    colors={['#5EEDF8', '#5F55EE']}
                    options={{
                        chart: {
                            background: 'transparent',
                            width: '100%',
                            type: 'line',
                            toolbar: {
                                show: false
                            }
                        },
                        markers: {
                            size: 5,
                            colors: ['#5EEDF8', '#5F55EE'],
                            strokeWidth: 0,
                            strokeOpacity: 0.9,
                            strokeDashArray: 0,
                            fillOpacity: 1,
                            discrete: [],
                            shape: "circle",
                            radius: 2,
                            offsetX: 0,
                            offsetY: 0,
                            showNullDataPoints: true,
                        },
                        yaxis: [
                            {
                                title: {
                                    text: "Value",
                                    style: {
                                        color: '#8A96A6',
                                    },
                                },
                                labels: {
                                    style: {
                                        colors: '#8A96A6',
                                    },
                                    formatter: function (val) {
                                        return '$' + val;
                                    },
                                },
                                min: 0,
                                max: 7000,
                                tickAmount: 7,
                            },
                            {
                                opposite: true,
                                title: {
                                    text: "Count",
                                    style: {
                                        color: '#8A96A6',
                                    },
                                },
                                labels: {
                                    style: {
                                        colors: '#8A96A6',
                                    },
                                },
                                min: 0,
                                max: 350,
                                tickAmount: 7,
                            }
                        ],
                        xaxis: {
                            categories: ['Austria', 'Switzerland', 'Denmark', 'Hungary', 'Spain', 'Germany', 'France', 'Norway'],
                            labels: {
                                style: {
                                    colors: theme.palette.color.secondary,
                                    fontSize: "13px",
                                },
                            },
                            axisBorder: {
                                show: false,
                            },
                            axisTicks: {
                                show: false,
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        stroke: {
                            curve: "straight",
                            width: [3, 3],
                        },
                        grid: {
                            show: false,
                        },
                        tooltip: {
                            enabled: false,
                            theme: 'dark',
                        },
                    }}
                    series={[
                        {
                            name: 'Value',
                            type: 'line',
                            data: [3300, 4500, 2200, 1500, 4400, 3300, 2300, 4300],
                        },
                        {
                            name: 'Count',
                            type: 'line',
                            data: [230, 190, 350, 150, 280, 80, 180, 250],
                        }
                    ]}
                />
            </Stack>
        </Grid>
    );
};

const InfoBox = ({ item, icon, sx }) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: "24px",
                width: "100%",
                height: "164px",
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    color: (theme) => theme.palette.color.secondary,
                }}
            >
                <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{
                        alignItems: "center",
                    }}
                >
                    <Typography variant="caption">{item.title}</Typography>
                    <Tooltip title={item.tooltip} placement="top">
                        <Box component="span" sx={{ cursor: "pointer" }}>
                            {icon ? icon : <InfoIcon2 style={{ width: 20, height: 20 }} />}
                        </Box>
                    </Tooltip>
                </Stack>
            </Box>
            <Box>
                <Typography variant="h1" fontSize={isSmall ? "20px" : "28px"}>{item.value}</Typography>
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        mt: 1,
                        alignItems: "center",
                        color: (theme) => theme.palette.color.green,
                    }}
                >
                    <FiArrowUpRight size={16} />
                    <Typography variant="caption">{item.percent}</Typography>
                </Stack>
            </Box>
        </Box>
    );
};