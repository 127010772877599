import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Stack, styled, Typography, useTheme } from "@mui/material";

const TicketsDistributionByCategory = () => {
  const theme = useTheme();

  const ChartContainer = styled(Box)({
    width: "100%",
    height: "100%",
    "& .apexcharts-toolbar": {
      display: "none !important",
    },
    padding: "20px 0px 0px 20px",
  });

  const colors = [
    theme.palette.color.pink,
    theme.palette.color.active,
    theme.palette.color.greenShade,
    theme.palette.color.orange,
  ];

  const series = [
    {
      data: [
        { x: "Technical Issues", y: 15, fillColor: "#1D232B" },
        { x: "Billing", y: 25, fillColor: "#1D232B" },
        { x: "General Questions", y: 25, fillColor: "#1D232B" },
        { x: "Other", y: 20, fillColor: "#1D232B" },
      ],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "36px",
        distributed: true,
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: colors,
    fill: {
      opacity: 1,
      colors: ["#1D232B", "#1D232B", "#1D232B", "#1D232B"],
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: ["#1D232B", "#1D232B", "#1D232B", "#1D232B"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    xaxis: {
      categories: ["Technical Issues", "Billing", "General Questions", "Other"],
      labels: {
        style: {
          colors: ["#8A96A6", "#8A96A6", "#8A96A6", "#8A96A6"],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    stroke: {
      width: 1,
      colors: colors,
    },
    yaxis: {
      min: 0,
      max: 35,
      tickAmount: 7,
      title: {
        text: "Number of Tickets",
        style: {
          color: "#8A96A6",
        },
      },
      labels: {
        formatter: (val) => `${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
  };

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        color: "white",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "0 auto",
        width: "100%",
        mt: 3,
      }}
    >
      <Stack
        direction="row"
        p={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5">Ticket Distribution by Category</Typography>
      </Stack>
      <ChartContainer>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </ChartContainer>
    </Box>
  );
};

export default TicketsDistributionByCategory;
