import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utils/getBaseQuery";

export const videoApis = createApi({
  reducerPath: "videoApis",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    addVideo: builder.mutation({
      query: (data) => ({
        url: "create-video",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Videos", id: "ALL_VIDEOS" }],
    }),
    editVideo: builder.mutation({
      query: (data) => ({
        url: `edit-video`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Videos", id: "ALL_VIDEOS" }],
    }),
    changeVideoStatus: builder.mutation({
      query: (data) => ({
        url: `change-video-status`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Videos", id: "ALL_VIDEOS" }],
    }),
    changeVideoVisibility: builder.mutation({
      query: (data) => ({
        url: `change-video-visibility`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Videos", id: "ALL_VIDEOS" }],
    }),
    getVideos: builder.query({
      query: () => `get-videos`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({ type: "Videos", id })),
              { type: "Videos", id: "ALL_VIDEOS" },
            ]
          : [{ type: "Videos", id: "ALL_VIDEOS" }],
    }),
  }),
});

export const {
  useChangeVideoStatusMutation,
  useChangeVideoVisibilityMutation,
  useAddVideoMutation,
  useGetVideosQuery,
  useEditVideoMutation,
} = videoApis;
