import { useTheme } from "@mui/material";
import * as React from "react";

const ExpandIcon = ({ stroke, ...props }) => {
  const theme = useTheme();

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icons">
        <path
          id="Vector"
          d="M11.229 10.7708L7.104 14.8958M10.7707 11.2292L14.8957 7.10416M4.354 6.18749V15.8125C4.354 16.2987 4.54716 16.765 4.89097 17.1089C5.23479 17.4527 5.70111 17.6458 6.18734 17.6458H15.8123C16.2986 17.6458 16.7649 17.4527 17.1087 17.1089C17.4525 16.765 17.6457 16.2987 17.6457 15.8125V6.18749C17.6457 5.70126 17.4525 5.23494 17.1087 4.89113C16.7649 4.54731 16.2986 4.35416 15.8123 4.35416H6.18734C5.70111 4.35416 5.23479 4.54731 4.89097 4.89113C4.54716 5.23494 4.354 5.70126 4.354 6.18749Z"
          stroke={stroke || theme.palette.color.secondary}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.104 11.6875V14.8958H10.3123M14.8957 10.3125V7.10416H11.6873"
          stroke={stroke || theme.palette.color.secondary}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ExpandIcon;
