import { configureStore } from "@reduxjs/toolkit";
import {
  authSliceReducer,
  openModalSliceReducer,
  themeSliceReducer,
} from "./slices";
import { setupListeners } from "@reduxjs/toolkit/query";
import loginApi from "./slices/api/loginSlice";
import { generalApi } from "./slices/api/generalSlice";
import { customerApis } from "./slices/api/customerSlice";
import { articleApis } from "./slices/api/articleSlice";
import { videoApis } from "./slices/api/videoSlice";
import { announcementApi } from "./slices/api/announcementSlice";
import { firmApi } from "./slices/api/firmSlice";
import { settingsApi } from "./slices/api/settingSlice";

export const store = configureStore({
  reducer: {
    theme: themeSliceReducer,
    openModal: openModalSliceReducer,

    auth: authSliceReducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [customerApis.reducerPath]: customerApis.reducer,
    [articleApis.reducerPath]: articleApis.reducer,
    [videoApis.reducerPath]: videoApis.reducer,
    [announcementApi.reducerPath]: announcementApi.reducer,
    [firmApi.reducerPath]: firmApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      loginApi.middleware,
      generalApi.middleware,
      customerApis.middleware,
      articleApis.middleware,
      videoApis.middleware,
      announcementApi.middleware,
      firmApi.middleware,
      settingsApi.middleware
    ),
});

setupListeners(store.dispatch);
