import React from 'react';
import { Grid, Box, Typography, Tooltip, Stack } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import InfoIcon2 from '../../Icons/Info2';
import { FiArrowUpRight } from 'react-icons/fi';
import RoundFormSelect from '../../ui/RoundFormSelect';

const AcquisitionCost = () => {
    const series = [
        {
            name: 'Cost per Acquisition',
            data: [10, 20, 5, 8]
        }
    ];

    const options = {
        chart: {
            type: 'line',
            toolbar: {
                show: false
            },
            background: 'transparent',
        },
        colors: ['#5EEDF8'],
        stroke: {
            curve: 'straight',
            width: 2
        },
        xaxis: {
            categories: ['Website', 'Affiliate', 'Social Media', 'Others'],
            labels: {
                style: {
                    colors: '#8A96A6'
                }
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false, 
            },
        },
        yaxis: {
            min: 0,
            max: 25,
            tickAmount: 5,
            labels: {
                style: {
                    colors: '#8A96A6'
                }
            },
        },
        grid: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },
        markers: {
            size: 5,
            colors: ['#5EEDF8'],
            strokeWidth: 0,
            hover: {
                size: 7,
            },
        },
        dataLabels: {
            enabled: true,
            offsetY: -10,
            style: {
                fontSize: '12px',
                colors: ['#CAD0DB']
            },
            formatter: function (val) {
                return '$' + val.toFixed(2);
            },
            background: {
                enabled: false,
            },
        },
    };

    return (
        <Box sx={{ mt: 2}}>
            <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>Cost per acquisition (CPA) per channel</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InfoBox
                                title="Lowest CPA Channel"
                                value="$5.00"
                                subtitle="Social Media"
                                previousPeriod="+3.8%"
                                previousYear="+3.8%"
                                tooltip="Cost Per Acquisition (CPA) for different marketing channels"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoBox
                                title="Highest CPA Channel"
                                value="$20.00"
                                subtitle="Affiliate"
                                previousPeriod="+3.8%"
                                previousYear="+3.8%"
                                tooltip="Cost Per Acquisition (CPA) for different marketing channels"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoBox
                                title="Average CPA"
                                value="$12.50"
                                subtitle="per Acquisition"
                                previousPeriod="+3.8%"
                                previousYear="+3.8%"
                                tooltip="Average Cost Per Acquisition across all channels"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box sx={{
                        background: (theme) => theme.palette.color.bg5,
                        borderRadius: "18px",
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.color.border,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                alignItems: "center",
                                justifyContent: "flex-end",
                                p: 2,
                            }}
                        >
                            <Stack direction="row" spacing={0.7} alignItems="center">
                                <Box
                                    sx={{
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: "#5EEDF8",
                                    }}
                                />
                                <Typography variant="caption">Cost per Acquisition</Typography>
                            </Stack>
                            <RoundFormSelect
                                options={[{ value: "monthly", label: "Monthly" }]}
                                defaultValue="monthly"
                            />
                        </Stack>
                        <Box sx={{ flexGrow: 1, p: 2 }}>
                            <ReactApexChart options={options} series={series} type="line" height="100%" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AcquisitionCost;

const InfoBox = ({ title, value, subtitle, previousPeriod, previousYear, tooltip }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: "24px",
                width: "100%",
                height: "174px",
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
            }}
        >
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="caption">{title}</Typography>
                <Tooltip title={tooltip} placement="top">
                    <InfoIcon2 />
                </Tooltip>
            </Stack>

            <Box sx={{ mt: 2 }}>
                <Typography variant="h1" component="span">{value}</Typography>
                {subtitle && <Typography variant="body2" component="span" sx={{ ml: 1, color: (theme) => theme.palette.color.secondary }}>{subtitle}</Typography>}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Box>
                    <Typography variant="body2" sx={{ color: '#8A96A6' }}>Previous Period</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: (theme) => theme.palette.color.green, gap: 1 }}>
                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.green }}>{previousPeriod}</Typography>
                        <FiArrowUpRight size={16} />
                    </Box>
                </Box>
                <Box>
                    <Typography variant="body2" sx={{ color: '#8A96A6' }}>Previous Year</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: (theme) => theme.palette.color.red, gap: 1 }}>
                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.red }}>{previousYear}</Typography>
                        <FiArrowUpRight size={16} style={{ transform: 'rotate(180deg)' }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};