const preprocessJSON = (data) => {
  let jsonData = data.replace(/'/g, '"');

  jsonData = jsonData.replace(/(\w+):/g, '"$1":');

  jsonData = jsonData.replace(/,\s*([}\]])/g, "$1");

  try {
    const parsedData = JSON.parse(jsonData);
    return parsedData;
  } catch (error) {
    console.error("Failed to parse JSON:", error, data);
    return;
  }
};

export const getChallengeData = ({ challenges, selected }) => {
  return challenges.map((challenge) => {
    const accountSizes =
      preprocessJSON(challenge.account_size)?.account_sizes || [];

    const account_size = accountSizes.find(
      (size) => size.code === selected.symbol
    ) || {
      sizes: [],
    };

    return {
      ...challenge,
      children: account_size?.sizes?.map((size) => ({
        name: size.value,
        price: size.price,
      })),
    };
  });
};
