import React, { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { BsThreeDots } from "react-icons/bs";
import { FaMeta } from "react-icons/fa6";
import { AiOutlineLink } from "react-icons/ai";
import { LuUnlink } from "react-icons/lu";
import { FaShareFromSquare } from "react-icons/fa6";
import { MdBlock } from "react-icons/md";
import { IoIosArrowRoundForward } from "react-icons/io";
import MatchStrength from "./MatchStrength";
import { useDispatch } from "react-redux";
import { toggleOpen } from "../../slices/openModalSlice";
import { useNavigate } from "react-router-dom";

const CopyTraderCard = ({ value }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [hovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        width: "100%",
        background: "#090B0E",
        borderRadius: "5px",
        overflow: "hidden",
        padding: "12px",
      }}
    >
      <Stack
        display="flex"
        gap={3}
        direction={isSmall ? "Column" : "row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box
          sx={{ flex: 3, display: "flex" }}
          direction={isSmall ? "row" : "column"}
        >
          <Typography variant="caption" display={isSmall ? "block" : "none"}>
            From
          </Typography>
          <Box
            sx={{
              mt: "10px",
              borderRadius: "15px",
              padding: "24px",
              background: "#14181F",
            }}
          >
            <Typography
              variant="h3"
              onClick={() => {
                navigate("/firm/customer/overview");
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              95035393
            </Typography>
            <Stack
              direction="row"
              color="#8996A5"
              alignItems="center"
              gap={1}
              mt={1}
            >
              <FaMeta size={15} />
              <Typography variant="caption">Metatrader 4</Typography>
              <Typography variant="caption">·</Typography>
              <Typography variant="caption">105,000.50 USD</Typography>
            </Stack>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 0.4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="caption"
            display={isSmall ? "block" : "none"}
            sx={{ alignSelf: "center", fontSize: "25px" }}
          >
            <IoIosArrowRoundForward />
          </Typography>
          <Box
            onClick={() => {
              dispatch(toggleOpen());
            }}
            sx={{
              marginTop: "10px",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              background: "#14181F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              "&:hover": {
                borderColor: "#15191F",
                borderWidth: "1px",
                borderStyle: "solid",
              },
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {hovered ? (
              <LuUnlink size={20} color="#F45F82" />
            ) : (
              <AiOutlineLink size={20} />
            )}
          </Box>
          <Box sx={{ height: "40px" }}></Box>
        </Box>

        <Box
          sx={{
            flex: 3,
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Box sx={{ alignItems: "flex-end" }}>
            <Typography variant="caption" display={isSmall ? "block" : "none"}>
              To
            </Typography>
          </Box>
          <Box
            sx={{
              mt: "10px",
              borderRadius: "15px",
              padding: "24px",
              background: "#14181F",
            }}
          >
            <Typography
              variant="h3"
              onClick={() => {
                navigate("/firm/customer/overview");
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              95035393
            </Typography>
            <Stack
              direction="row"
              color="#8996A5"
              alignItems="center"
              gap={1}
              mt={1}
            >
              <FaMeta size={15} />
              <Typography variant="caption">Metatrader 4</Typography>
              <Typography variant="caption">·</Typography>
              <Typography variant="caption">105,000.50 USD</Typography>
            </Stack>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "end",
            // alignSelf: "start",
          }}
        >
          <Box onClick={handleClick}>
            <BsThreeDots style={{ color: "rgb(138, 150, 166)" }} />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              color: "#8996A5",
            }}
            PaperProps={{
              sx: {
                border: "1px solid #ccc",
                marginTop: "0px",
                transform: "translateY(8px)",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleClose} color="#8996A5">
              <FaShareFromSquare
                style={{ marginRight: "20px", color: "#8996A5" }}
              />
              <Typography variant="caption">Resend invitation</Typography>
            </MenuItem>
            <MenuItem onClick={handleClose} color="#8996A5">
              <MdBlock style={{ marginRight: "20px", color: "#8996A5" }} />
              <Typography variant="caption">Ban user IP</Typography>
            </MenuItem>
          </Menu>
          <Box sx={{ mt: "10px", position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                width: "400px",
                height: "400px",
                borderRadius: "50%",
                // background: "#14181F",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                right: "-50px",
                top: "-200px",
              }}
            >
              <MatchStrength percentage={value ? value : 80} />
            </Box>
          </Box>
        </Box>
      </Stack>

      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 0.5,
        }}
      >
        <Box
          sx={{
            height: "10px",
            width: "10px",
            backgroundColor: (theme) => theme.palette.color.orange,
            borderRadius: "50%",
            display: "inline-block",
          }}
        ></Box>

        <Typography variant="caption">11:05 5 Apr, 2024</Typography>
      </Box>
    </Box>
  );
};

export default CopyTraderCard;
