import { useTheme } from "@mui/material";
import * as React from "react";

const RetryIcon = ({ fill, stroke, ...props }) => {
  const theme = useTheme();

  return (
    <svg width="24" height="24" fill="none" {...props}>
      <path
        stroke={stroke || theme.palette.color.active}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.25 4.75 8.75 7l2.5 2.25m1.5 10 2.5-2.25-2.5-2.25"
      />
      <path
        stroke={stroke || theme.palette.color.active}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.75 7h3.5a6 6 0 0 1 6 6v.25m-5 3.75h-3.5a6 6 0 0 1-6-6v-.25"
      />
    </svg>
  );
};

export default RetryIcon;
