import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

import CopyIcon from "../../../components/Icons/Copy";
import ChevronRightIcon from "../../../components/Icons/ChevronRight";
import HomeIcon from "../../../components/Icons/Home";
import HelpIcon from "../../../components/Icons/HelpCenter";
import MenuIcon from "../../../components/Icons/MenuIcon";
import OrdersIcon from "../../../components/Icons/Orders";
import PayoutIcon from "../../../components/Icons/Payouts1";
import FlagIcon from "../../../components/Icons/Flag";
import AllIcon from "../../../components/Icons/All";
import TabSection from "../../../components/Firm/Customers/SingleCustomer/TabSection";
import Actions from "../../../components/Firm/Customers/SingleCustomer/Actions";
import BillDetails from "../../../components/Firm/Customers/SingleCustomer/BillDetails";
import BillDetails1 from "../../../components/Firm/Customers/SingleCustomer/BillingDetails2";
import Notes from "../../../components/Firm/Customers/SingleCustomer/Notes";
import SideBar1 from "../../../components/Firm/Customers/SingleCustomer/Sidebar";
import { useSearchParams } from "react-router-dom";
import {
  useGetCustomerAdditionInfoQuery,
  useGetCustomerQuery,
  useGetCustomerStatisticsQuery,
} from "../../../slices/api/customerSlice";

const Customer = () => {
  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data } = useGetCustomerQuery(id);
  const { data: statistics } = useGetCustomerStatisticsQuery(id);
  const { data: additionalInfo } = useGetCustomerAdditionInfoQuery(id);

  const customerDetails = [
    {
      title: "Full name",
      value: data?.data?.first_name + " " + data?.data?.last_name,
    },
    { title: "Country", value: data?.data?.country || "-" },
    { title: "Timezone", value: data?.data?.timezone || "-" },
    { title: "Affiliate", value: data?.data?.affiliate || "-" },
  ];

  const dashboardItems = [
    {
      title: "Tickets",
      value: statistics?.data?.total_tickets || 0,
      color: "cyan",
      icon: HelpIcon,
    },
    {
      title: "Accounts",
      value: statistics?.data?.accounts || 0,
      color: "active",
      icon: AllIcon,
    },
    {
      title: "Orders",
      value: statistics?.data?.orders || 0,
      color: "pink",
      icon: OrdersIcon,
    },
    {
      title: "Payouts",
      value: statistics?.data?.payments || 0,
      color: "purple",
      icon: PayoutIcon,
    },
    { title: "Flags", value: 5, color: "red", icon: FlagIcon },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          background: (theme) =>
            `linear-gradient(143deg, ${theme.palette.color.active} 4%, rgba(0, 0, 0, 0.68) 77%, rgba(0, 0, 0, 0.38) 129%);`,
          height: "184px",
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Stack spacing={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography variant="h1">
              {data?.data?.first_name + " " + data?.data?.last_name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                border: (theme) => "1px solid " + theme.palette.color.green,
                borderRadius: "24px",
                padding: "4px 10px",
              }}
            >
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  borderRadius: "50%",
                  background: (theme) => theme.palette.color.green,
                }}
              />

              <Typography
                sx={{
                  fontSize: "11.5px",
                  color: (theme) => theme.palette.color.green,

                  fontWeight: "600",
                }}
              >
                Verified
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: (theme) => theme.palette.color.secondary,
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "500" }}>
              105066050
            </Typography>
            <CopyIcon />
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <HomeIcon
              width="20px"
              height="20px"
              fill={theme.palette.color.secondary}
            />
            <ChevronRightIcon
              sx={{
                fontSize: "16px",
                color: (theme) => theme.palette.color.secondary,
              }}
            />
            <Typography variant="body3">Your Firm</Typography>
            <ChevronRightIcon
              sx={{
                fontSize: "16px",
                color: (theme) => theme.palette.color.secondary,
              }}
            />
            <Typography variant="body1">Customers</Typography>
            <ChevronRightIcon
              sx={{
                fontSize: "16px",
                color: (theme) => theme.palette.color.secondary,
              }}
            />
            <Typography variant="body1">{data?.data?.id || "-"}</Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            // width: "584px",
            // backgroundImage: "url(/images/background.avif)",
            position: "absolute",
            right: "-119px",
            top: "-174px",
            opacity: "0.5",
          }}
        >
          <img src="/images/background.png" alt="background" width="584px" />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          px: { xs: "12px", lg: "24px" },
          width: "100%",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box
          sx={{
            width: "100%",

            position: "relative",
          }}
        >
          <Box
            sx={{
              position: { xs: "relative", lg: "absolute" },
              top: { xs: "-20px", lg: "-40px" },

              width: "100%",
              background: (theme) => theme.palette.color.bg5,
              borderRadius: "24px",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                height: { xs: "auto", lg: "270px" },
                padding: { xs: "0px 18px", lg: "24px" },
              }}
            >
              {dashboardItems.map((item, index) => (
                <Grid item xs={6} lg={4}>
                  <Card
                    sx={{
                      width: "100%",
                      background: (theme) => theme.palette.color.borderpure,
                      border: "1px solid transparent",
                      borderRadius: "12px",
                      overflow: "hidden",
                      padding: "18px",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: "100%",
                      cursor: "pointer",
                      ":hover": {
                        border: (theme) =>
                          `1px solid ${theme.palette.color[item.color]}`,
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <item.icon
                        width="18px"
                        stroke={theme.palette.color[item.color]}
                        // fill={theme.palette.color[item.color]}
                      />
                      <Typography variant="caption_500">
                        {item.title}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "26px",
                        fontWeight: "600",
                        color: theme.palette.color.primary,
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <TabSection />
            <Divider />
            <Actions />
            <Divider />
            <BillDetails />
            <Divider />
            <BillDetails1 />
          </Box>
        </Box>
        <Box
          sx={{
            // width: "100%",

            width: { xs: "100%", lg: "600px" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: { xs: "relative", lg: "absolute" },
              top: 0,
              width: "100%",
              borderRadius: "24px",
            }}
          >
            <Box
              sx={{
                position: { xs: "relative", lg: "absolute" }, // { xs: "relative", lg: "absolute"
                top: { xs: "-20px", lg: "-40px" },
                right: "0",
                borderRadius: "24px",
              }}
            >
              <Stack
                spacing={1}
                sx={{
                  background: (theme) => theme.palette.color.bg5,
                  borderRadius: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    padding: "24px",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      width: "71px",
                      height: "71px",
                      borderRadius: "50%",
                      background: theme.palette.color.active,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexShrink: 0,
                    }}
                  >
                    <Typography variant="h2">J</Typography>
                  </Box>
                  <Stack spacing={2.5} sx={{ width: "100%", mt: "6px" }}>
                    {customerDetails.map((item, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography variant="subtitle1_500">
                          {item.title}
                        </Typography>
                        <Typography variant="caption_500">
                          {item.value}
                        </Typography>
                      </Box>
                    ))}
                    <Button
                      sx={{
                        background: (theme) => theme.palette.color.borderpure,
                        padding: "6px 12px",
                        borderRadius: "24px",
                        color: theme.palette.color.secondary,
                        ":hover": {
                          background: (theme) => theme.palette.color.borderpure,
                          color: theme.palette.color.primary,
                        },
                      }}
                    >
                      See More
                    </Button>
                  </Stack>
                </Box>
                <Grid container spacing={2} sx={{ pr: "24px", pb: "24px" }}>
                  {[
                    { title: "Contracts", value: "5" },
                    { title: "Certificates", value: "5" },
                  ].map((item) => (
                    <Grid item xs={12} lg={6}>
                      <Card
                        sx={{
                          width: "100%",
                          background: (theme) => theme.palette.color.borderpure,
                          border: "1px solid transparent",
                          borderRadius: "12px",
                          overflow: "hidden",
                          padding: "18px",
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          cursor: "pointer",
                          gap: 1,
                          ":hover": {
                            border: (theme) =>
                              `1px solid ${theme.palette.color[item.color]}`,
                          },
                        }}
                      >
                        <Typography variant="caption_500">
                          {item.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "26px",
                            fontWeight: "600",
                            color: theme.palette.color.primary,
                          }}
                        >
                          {item.value}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
              <Stack spacing={1} sx={{ mt: "24px" }}>
                <Notes />
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Customer;
