import { useTheme } from "@mui/material";
import * as React from "react";

const CheckFillIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  return (
    <svg width="24" height="24" fill="none" {...props}>
      <path
        stroke={stroke || theme.palette.color.secondary}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M4.75 12A7.25 7.25 0 0 1 12 4.75v0A7.25 7.25 0 0 1 19.25 12v0A7.25 7.25 0 0 1 12 19.25v0A7.25 7.25 0 0 1 4.75 12v0Z"
      />
      <path
        stroke={stroke || theme.palette.color.secondary}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="m9.75 12.75.434.924a1 1 0 0 0 1.772.073L14.25 9.75"
      />
    </svg>
  );
};

export default CheckFillIcon;
