import { useTheme } from "@mui/material";
import * as React from "react";

const MailIcon = ({ stroke, ...props }) => {
  const theme = useTheme();

  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.56304 8.81299C6.56304 8.41516 6.72107 8.03363 7.00237 7.75233C7.28368 7.47102 7.66521 7.31299 8.06304 7.31299H15.938C16.3359 7.31299 16.7174 7.47102 16.9987 7.75233C17.28 8.03363 17.438 8.41516 17.438 8.81299V15.188C17.438 15.5858 17.28 15.9673 16.9987 16.2486C16.7174 16.53 16.3359 16.688 15.938 16.688H8.06103C7.66321 16.688 7.28168 16.53 7.00038 16.2486C6.71907 15.9673 6.56104 15.5858 6.56104 15.188V8.81099L6.56304 8.81299Z"
        stroke={stroke || theme.palette.color.secondary}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.125 7.875L12 12.188L16.875 7.875"
        stroke={stroke || theme.palette.color.secondary}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MailIcon;
