import { useTheme } from "@mui/material";

export default function FlagIcon1({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3035 12.336H1.67951V17.1825C1.67351 17.64 1.29701 18.006 0.839512 18C0.382012 18.006 0.00701172 17.64 -0.000488281 17.1825V0.817512C0.00701172 0.360012 0.382012 -0.00598831 0.839512 1.16933e-05H11.3035C12.403 1.16933e-05 13.2505 0.660012 13.6735 1.48201C14.1295 2.36401 14.107 3.41851 13.6135 4.27951L13.1095 5.15851C12.7585 5.78551 12.7585 6.55051 13.1095 7.17751L13.612 8.05651C14.1415 8.98051 14.0965 10.0335 13.6735 10.854C13.2505 11.6775 12.4045 12.336 11.3035 12.336Z"
        fill={stroke || theme.palette.color.secondary}
      />
    </svg>
  );
}
