import React from 'react';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

const CardBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: "12px",
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.color.bg5,
    border: "1px solid", 
    borderColor: theme.palette.color.strockborder,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
}));

const PaymentCard = ({ brandIcon, cardNumber, expiry, isPrimary, onMakePrimary }) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <CardBox>
            <Stack spacing={2} direction={"row"}>
                <img src={brandIcon} alt="Card Brand" style={{ height: 40, borderRadius: '6px' }} />
                <Box ml={1}>
                    <Stack spacing={1} direction={"row"} alignItems={isSmall ? "start" : "center"}>
                        <Typography variant="h6">Card ending in {cardNumber}</Typography>
                        {isPrimary ? (
                            <Box color={(theme) => theme.palette.color.green} bgcolor={(theme) => theme.palette.color.lightgray} borderRadius={"8px"} p={"4px 8px"} ml={2}>Primary</Box>
                        ) : (
                            <Typography variant="body2" sx={{ textDecoration: "underline", cursor: "pointer" }} ml={2}>Make Primary</Typography>
                        )}
                    </Stack>
                    <Typography variant="body2" mt={1}>Expiry {expiry}</Typography>
                </Box>
            </ Stack>
            <Typography variant="caption" sx={{ cursor: "pointer" }} ml={2}>Edit</Typography>
        </CardBox>
    );
};

export default PaymentCard;