import { Stack } from '@mui/material'
import PerformanceSummaryTable from '../../components/Risk-Management/Analysis-And-Reporting/PerformanceSummaryTable'
import RiskSummaryTable from '../../components/Risk-Management/Analysis-And-Reporting/RiskSummaryTable'

const AnalysisAndReporting = () => {
    return (
        <Stack sx={{ width: '100%', p: 3, pb: 10, spacing: 4 }}>
            <PerformanceSummaryTable />
            <RiskSummaryTable />
        </Stack>
    )
}

export default AnalysisAndReporting