import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const details = [
  { title: "Customer name", value: "Jens van der Bij" },
  { title: "Address", value: "10600 N Tantau Ave" },
  { title: "City", value: "Silicon Valley - 9021" },
  { title: "State", value: "California" },
  { title: "Country", value: "US" },
  { title: "Phone", value: "(+31) (0)6 0000000" },
];

const BillDetails = () => {
  return (
    <Stack spacing={3} sx={{ padding: "24px" }}>
      <Typography variant="h6" sx={{ px: "24px" }}>
        Bill Details
      </Typography>
      <Stack
        spacing={3}
        sx={{
          mt: "24px",
          padding: "24px",
          background: (theme) => theme.palette.color.borderpure,
          borderRadius: "24px",
        }}
      >
        {details.map((detail) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1_500">{detail.title}</Typography>
            <Typography variant="caption_500">{detail.value}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default BillDetails;
