import React from "react";
import { Box, useMediaQuery } from "@mui/material";

import DashboardCards from "../components/Home/DashboardCards";
import InfoBox from "../components/ui/InfoBox";
import Chat from "../components/Home/Chat";
import ActionRequired from "../components/Home/ActionRequired";
import { useSelector } from "react-redux";

const Home = () => {
  const token = useSelector((state) => state.auth.token);
  console.log(token);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <DashboardCards />

      <Box
        sx={{
          display: "flex",
          flexDirection: isSmall ? "column" : "row",
          padding: "12px",
          gap: "16px",
          alignItems: "stretch",
        }}
      >
        <Box
          sx={{
            width: isSmall ? "100%" : "690px",
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box sx={{ display: "flex", gap: "12px", width: "100%", py: "12px" }}>
            <InfoBox
              item={{
                title: "Today's Clients",
                value: "111",
                text: "",
              }}
              sx={{
                background: "transparent",
              }}
            />
            <InfoBox
              item={{
                title: "Today's Revenue",
                value: "US$ 5,100.10",
              }}
              sx={{
                background: (theme) => theme.palette.color.gray,
              }}
            />
          </Box>
          <Chat />
        </Box>
        <ActionRequired />
      </Box>
    </Box>
  );
};

export default Home;
