import {
  alpha,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Done } from "@mui/icons-material";
import InfoFillIcon from "../../components/Icons/InfoFill";
import { IoIosMore } from "react-icons/io";
import FormInput from "../../components/ui/FormInput";

const Domains = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box width={"100%"}>

      <Stack sx={{ px: "24px", py: "16px" }} spacing={2} direction={isSmall ? "column" : "row"} justifyContent={"space-between"} alignItems={isSmall ? "start" : "end"}>
        <Box>
          <Typography variant="h3" gutterBottom>
            Domains
          </Typography>
          <Typography variant="caption" gutterBottom>
            Manage the domains for your online store
          </Typography>
        </Box>
        <Stack direction={"row"} spacing={2} alignItems={"flex-end"}>
          <Button size='small' 
            sx={{ 
              height: "35px", 
              borderRadius: "32px", 
              bgcolor: (theme) => theme.palette.color.bg5, 
              border: (theme) => `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`, 
              ":hover": { background: (theme) => theme.palette.color.bg5 }
              }}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography variant="caption">How does it work</Typography>
              <Tooltip title={"How does it work"} placement="top">
                <Typography variant="caption" fontSize={"1px"}><InfoFillIcon /></Typography>
              </Tooltip>
            </Stack>
          </Button>
          <Button size='small' sx={{ height: "35px", borderRadius: "32px", bgcolor: (theme) => theme.palette.color.active, color: "white", ":hover": { background: (theme) => theme.palette.color.active } }}>
            Save changes
          </Button>
        </Stack>
      </Stack>

      <Box p={isSmall ? "15px" : "20px"}>
        <Grid container columnSpacing={3} width={"100%"}>
          <Grid item xs={12} md={6} width={"100%"}>
            <Box sx={{ width: "100%", p: "24px", bgcolor: (theme) => theme.palette.color.lightgray, borderRadius: "18px" }}>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack spacing={1}>
                  <Typography variant="h5">Client dashboard</Typography>
                  <Typography variant="subtitle2">Domain settings for client dashboard</Typography>
                </Stack>
                <IoIosMore size={"15px"} />
              </Stack>

              <Stack spacing={1} mt={3} direction="row" alignItems="center">
                <Done sx={{ color: (theme) => theme.palette.color.green, fontSize: "15px" }} />{" "}
                <Typography variant="subtitle2"> dashboard.vuetra.com </Typography>
              </Stack>
              <Stack direction="row" spacing={1.5} mt={2} pl={3} alignItems="center">
                <Box
                  sx={{
                    width: "4px",
                    height: "4px",
                    borderRadius: "50%",
                    background: (theme) => theme.palette.color.green,
                  }}
                ></Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.color.green,
                  }}
                >
                  Connected
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h5" mt={"48px"} mb={"24px"}>Add-on domains</Typography>

        <Grid container columnSpacing={3} width={"100%"} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ width: "100%", height: "100%", p: "24px", bgcolor: (theme) => theme.palette.color.bg5, border: "1px solid", borderColor: (theme) => theme.palette.color.strockborder, borderRadius: "16px" }}>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack spacing={1}>
                  <Typography variant="h5">Help Center</Typography>
                  <Typography variant="subtitle2">Domain settings for your Help Center</Typography>
                </Stack>
                <IoIosMore size={"15px"} />
              </Stack>

              <Stack spacing={1} mt={3} direction="row" alignItems="center">
                <Done sx={{ color: (theme) => theme.palette.color.green, fontSize: "15px" }} />{" "}
                <Typography variant="subtitle2"> dashboard.vuetra.com </Typography>
              </Stack>
              <Stack direction="row" spacing={1.5} mt={2} pl={3} alignItems="center">
                <Box
                  sx={{
                    width: "4px",
                    height: "4px",
                    borderRadius: "50%",
                    background: (theme) => theme.palette.color.green,
                  }}
                ></Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.color.green,
                  }}
                >
                  Connected
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ width: "100%", height: "100%", p: "24px", bgcolor: (theme) => theme.palette.color.bg5, border: "1px solid", borderColor: (theme) => theme.palette.color.strockborder, borderRadius: "16px" }}>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack spacing={1}>
                  <Typography variant="h5">Blog</Typography>
                  <Typography variant="subtitle2">Domain settings for your Blog Page</Typography>
                </Stack>
                <Button sx={{
                  borderRadius: "50px",
                  height: "32px",
                  fontSize: "13px",
                  fontWeight: "500",
                  bgcolor: (theme) => theme.palette.color.green,
                  ":hover": {
                    bgcolor: (theme) => theme.palette.color.green
                  }
                }}>
                  Connect
                </Button>
              </Stack>

              <Stack spacing={1} mt={3} direction="row" alignItems="center">
                <FormInput
                  defaultValue="blog"
                  endAdornment={
                    <InputAdornment position="end">
                      <Box display="flex" alignItems="center">
                        <Typography variant="caption" ml={2} mr={1} color="#4E5661">.staging.vuetra.com</Typography>
                      </Box>
                    </InputAdornment>
                  }
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Domains;