import React from 'react'
import { Box, Stack, Typography } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const SalesReportByPeriod = () => {
    const series = [
        {
          name: 'Gross Revenue',
          type: 'area',
          data: [600, 1000, 580, 630, 680, 380, 320, 800, 770, 600, 650, 800],
        }
      ];
    
      const options = {
        chart: {
          height: 350,
          type: 'line',
          background: 'transparent',
          toolbar: {
            show: false,
          },
          padding: {
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          },
        },
        colors: ['#5EEDF8', '#5EEDF833'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: [2, 2],
        },
        fill: {
          type: 'gradient',
          opacity: [0.45, 1],
          gradient: {
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: [0.38, 1],
            opacityTo: [0.25, 1],
            shade: 'dark',
            type: 'vertical',
            stops: [0, 100],
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: "#8A96A6",
            },
          },
        },
        yaxis: {
          min: 200,
          max: 1600,
          tickAmount: 8,
          labels: {
            formatter: (val) => `${val}`,
            style: {
              colors: "#8A96A6",
            },
          },
          title: {
            text: 'Total Sales',
            style: {
              color: '#8A96A6',
            },
          }
        },
        tooltip: {
          enabled: false,
          shared: true,
          intersect: false,
          y: {
            formatter: (val) => `${val}`,
          },
        },
        theme: {
          mode: 'dark',
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        markers: {
          size: 2,
          colors: undefined,
          strokeWidth: 0,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "square",
          radius: 5,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
      };
    
      return (
        <Box sx={{
          background: (theme) => theme.palette.color.bg5,
          border: '1px solid',
          borderColor: (theme) => theme.palette.color.strockborder,
          p: '20px',
          borderRadius: '12px',
          mt: 2,
        }}>
          <Stack direction="row" pl={2} alignItems={"center"} justifyContent={"space-between"}>
            <Typography variant="h5">
              Sales Reports by Period
            </Typography>
            <Stack direction="row" gap={2}>
              <RoundFormSelect
                options={[{ value: "monthly", label: "Monthly" }]}
                defaultValue="monthly"
              />
            </Stack>
          </Stack>
          <ReactApexChart options={options} series={series} type="line" height={350} />
        </Box>
      );
}

export default SalesReportByPeriod;