import { useTheme } from "@mui/material";
import * as React from "react";

const RiskManagementIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        d="M12 4.75L4.75 8S4 19.25 12 19.25 19.25 8 19.25 8L12 4.75Z"
        stroke={stroke || theme.palette.color.secondary}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 15a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
        stroke={stroke || theme.palette.color.secondary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9v2"
        stroke={stroke || theme.palette.color.secondary}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RiskManagementIcon;