import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';

const CustomPieChart = ({ data }) => {
  return (
    <svg width="317" height="308" viewBox="0 0 317 308" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7" filter="url(#filter0_f_4383_632346)">
        <mask id="path-1-inside-1_4383_632346" fill="white">
          <path d="M178.711 243.547C179.599 249.158 175.768 254.48 170.097 254.815C158.357 255.509 146.559 254.227 135.199 250.999C120.534 246.831 106.953 239.52 95.3985 229.574C83.8443 219.627 74.5947 207.284 68.2926 193.402C61.9904 179.52 58.7869 164.432 58.9047 149.187C59.0225 133.941 62.4587 118.905 68.9746 105.122C75.4905 91.3385 84.9298 79.1396 96.6363 69.3729C108.343 59.6062 122.036 52.5059 136.763 48.5655C148.171 45.5133 159.988 44.4138 171.716 45.2891C177.381 45.7118 181.129 51.0922 180.155 56.6886C179.18 62.285 173.847 65.965 168.174 65.6776C159.406 65.2333 150.601 66.1583 142.08 68.4381C130.238 71.6065 119.228 77.3157 109.815 85.1689C100.402 93.0221 92.812 102.831 87.5727 113.914C82.3333 124.996 79.5703 137.087 79.4756 149.346C79.3809 161.604 81.9568 173.736 87.0242 184.898C92.0917 196.061 99.5291 205.986 108.82 213.983C118.11 221.981 129.031 227.86 140.823 231.211C149.308 233.622 158.097 234.683 166.871 234.374C172.548 234.174 177.823 237.936 178.711 243.547Z" />
        </mask>
        <path d="M178.711 243.547C179.599 249.158 175.768 254.48 170.097 254.815C158.357 255.509 146.559 254.227 135.199 250.999C120.534 246.831 106.953 239.52 95.3985 229.574C83.8443 219.627 74.5947 207.284 68.2926 193.402C61.9904 179.52 58.7869 164.432 58.9047 149.187C59.0225 133.941 62.4587 118.905 68.9746 105.122C75.4905 91.3385 84.9298 79.1396 96.6363 69.3729C108.343 59.6062 122.036 52.5059 136.763 48.5655C148.171 45.5133 159.988 44.4138 171.716 45.2891C177.381 45.7118 181.129 51.0922 180.155 56.6886C179.18 62.285 173.847 65.965 168.174 65.6776C159.406 65.2333 150.601 66.1583 142.08 68.4381C130.238 71.6065 119.228 77.3157 109.815 85.1689C100.402 93.0221 92.812 102.831 87.5727 113.914C82.3333 124.996 79.5703 137.087 79.4756 149.346C79.3809 161.604 81.9568 173.736 87.0242 184.898C92.0917 196.061 99.5291 205.986 108.82 213.983C118.11 221.981 129.031 227.86 140.823 231.211C149.308 233.622 158.097 234.683 166.871 234.374C172.548 234.174 177.823 237.936 178.711 243.547Z" stroke="url(#paint0_linear_4383_632346)" stroke-width="16" stroke-linejoin="round" mask="url(#path-1-inside-1_4383_632346)" />
      </g>
      <mask id="path-2-inside-2_4383_632346" fill="white">
        <path d="M178.711 243.547C179.599 249.158 175.768 254.48 170.097 254.815C158.424 255.505 146.693 254.241 135.393 251.053C120.785 246.932 107.244 239.693 95.7034 229.835C84.1627 219.977 74.8965 207.734 68.5431 193.95C62.1898 180.166 58.9004 165.168 58.9016 149.99C58.9027 134.812 62.1944 119.815 68.5499 106.031C74.9054 92.248 84.1735 80.0064 95.7157 70.15C107.258 60.2936 120.8 53.0567 135.408 48.9379C146.709 45.7516 158.441 44.49 170.113 45.1818C175.784 45.5178 179.614 50.8402 178.725 56.4509C177.836 62.0615 172.561 65.8227 166.884 65.6221C158.164 65.3139 149.428 66.3586 140.991 68.7375C129.244 72.0493 118.355 77.8684 109.075 85.7937C99.7938 93.7191 92.3415 103.562 87.2311 114.645C82.1208 125.728 79.474 137.787 79.4731 149.991C79.4722 162.196 82.1171 174.255 87.2257 185.339C92.3343 196.422 99.7851 206.267 109.065 214.194C118.344 222.12 129.232 227.941 140.978 231.255C149.415 233.635 158.151 234.681 166.871 234.374C172.548 234.174 177.823 237.936 178.711 243.547Z" />
      </mask>
      <path d="M178.711 243.547C179.599 249.158 175.768 254.48 170.097 254.815C158.424 255.505 146.693 254.241 135.393 251.053C120.785 246.932 107.244 239.693 95.7034 229.835C84.1627 219.977 74.8965 207.734 68.5431 193.95C62.1898 180.166 58.9004 165.168 58.9016 149.99C58.9027 134.812 62.1944 119.815 68.5499 106.031C74.9054 92.248 84.1735 80.0064 95.7157 70.15C107.258 60.2936 120.8 53.0567 135.408 48.9379C146.709 45.7516 158.441 44.49 170.113 45.1818C175.784 45.5178 179.614 50.8402 178.725 56.4509C177.836 62.0615 172.561 65.8227 166.884 65.6221C158.164 65.3139 149.428 66.3586 140.991 68.7375C129.244 72.0493 118.355 77.8684 109.075 85.7937C99.7938 93.7191 92.3415 103.562 87.2311 114.645C82.1208 125.728 79.474 137.787 79.4731 149.991C79.4722 162.196 82.1171 174.255 87.2257 185.339C92.3343 196.422 99.7851 206.267 109.065 214.194C118.344 222.12 129.232 227.941 140.978 231.255C149.415 233.635 158.151 234.681 166.871 234.374C172.548 234.174 177.823 237.936 178.711 243.547Z" stroke="url(#paint1_linear_4383_632346)" stroke-width="16" stroke-linejoin="round" mask="url(#path-2-inside-2_4383_632346)" />
      <g opacity="0.7" filter="url(#filter1_f_4383_632346)">
        <mask id="path-3-inside-3_4383_632346" fill="white">
          <path d="M192.041 59.5317C193.723 54.1057 199.511 51.0238 204.746 53.2283C222.013 60.4984 237.083 72.285 248.321 87.4303C259.559 102.576 266.473 120.414 268.427 139.047C269.02 144.696 264.393 149.343 258.712 149.38C253.032 149.417 248.461 144.825 247.734 139.191C245.894 124.935 240.434 111.323 231.801 99.6889C223.168 88.0542 211.722 78.8846 198.611 72.9918C193.43 70.6631 190.359 64.9578 192.041 59.5317Z" />
        </mask>
        <path d="M192.041 59.5317C193.723 54.1057 199.511 51.0238 204.746 53.2283C222.013 60.4984 237.083 72.285 248.321 87.4303C259.559 102.576 266.473 120.414 268.427 139.047C269.02 144.696 264.393 149.343 258.712 149.38C253.032 149.417 248.461 144.825 247.734 139.191C245.894 124.935 240.434 111.323 231.801 99.6889C223.168 88.0542 211.722 78.8846 198.611 72.9918C193.43 70.6631 190.359 64.9578 192.041 59.5317Z" stroke="url(#paint2_linear_4383_632346)" stroke-width="16" stroke-linejoin="round" mask="url(#path-3-inside-3_4383_632346)" />
      </g>
      <mask id="path-4-inside-4_4383_632346" fill="white">
        <path d="M187.554 58.261C188.966 52.7588 194.596 49.3958 199.933 51.3398C218.465 58.089 234.793 69.9508 246.966 85.6438C259.139 101.337 266.568 120.101 268.497 139.729C269.052 145.383 264.395 149.999 258.714 149.999C253.034 149.999 248.493 145.377 247.803 139.738C245.959 124.684 240.084 110.336 230.711 98.2524C221.338 86.1688 208.901 76.9102 194.778 71.3813C189.489 69.3105 186.141 63.7633 187.554 58.261Z" />
      </mask>
      <path d="M187.554 58.261C188.966 52.7588 194.596 49.3958 199.933 51.3398C218.465 58.089 234.793 69.9508 246.966 85.6438C259.139 101.337 266.568 120.101 268.497 139.729C269.052 145.383 264.395 149.999 258.714 149.999C253.034 149.999 248.493 145.377 247.803 139.738C245.959 124.684 240.084 110.336 230.711 98.2524C221.338 86.1688 208.901 76.9102 194.778 71.3813C189.489 69.3105 186.141 63.7633 187.554 58.261Z" stroke="url(#paint3_linear_4383_632346)" stroke-width="16" stroke-linejoin="round" mask="url(#path-4-inside-4_4383_632346)" />
      <g opacity="0.7" filter="url(#filter2_f_4383_632346)">
        <mask id="path-5-inside-5_4383_632346" fill="white">
          <path d="M258.156 159.322C263.809 159.881 267.99 164.933 266.88 170.504C263.401 187.981 255.516 204.346 243.901 218.008C232.285 231.671 217.404 242.087 200.715 248.335C195.395 250.327 189.735 247.014 188.274 241.524C186.812 236.035 190.11 230.458 195.381 228.34C208.039 223.254 219.326 215.155 228.227 204.684C237.129 194.213 243.305 181.77 246.288 168.459C247.53 162.915 252.503 158.763 258.156 159.322Z" />
        </mask>
        <path d="M258.156 159.322C263.809 159.881 267.99 164.933 266.88 170.504C263.401 187.981 255.516 204.346 243.901 218.008C232.285 231.671 217.404 242.087 200.715 248.335C195.395 250.327 189.735 247.014 188.274 241.524C186.812 236.035 190.11 230.458 195.381 228.34C208.039 223.254 219.326 215.155 228.227 204.684C237.129 194.213 243.305 181.77 246.288 168.459C247.53 162.915 252.503 158.763 258.156 159.322Z" stroke="url(#paint4_linear_4383_632346)" stroke-width="16" stroke-linejoin="round" mask="url(#path-5-inside-5_4383_632346)" />
      </g>
      <mask id="path-6-inside-6_4383_632346" fill="white">
        <path d="M258.371 156.809C264.037 157.217 268.351 162.156 267.39 167.755C264.243 186.097 256.264 203.34 244.192 217.665C232.12 231.989 216.478 242.774 198.934 248.983C193.579 250.878 187.981 247.464 186.618 241.949C185.256 236.434 188.654 230.918 193.963 228.895C207.302 223.813 219.191 215.408 228.461 204.408C237.732 193.407 244.001 180.266 246.75 166.259C247.844 160.684 252.705 156.401 258.371 156.809Z" />
      </mask>
      <path d="M258.371 156.809C264.037 157.217 268.351 162.156 267.39 167.755C264.243 186.097 256.264 203.34 244.192 217.665C232.12 231.989 216.478 242.774 198.934 248.983C193.579 250.878 187.981 247.464 186.618 241.949C185.256 236.434 188.654 230.918 193.963 228.895C207.302 223.813 219.191 215.408 228.461 204.408C237.732 193.407 244.001 180.266 246.75 166.259C247.844 160.684 252.705 156.401 258.371 156.809Z" stroke="url(#paint5_linear_4383_632346)" stroke-width="16" stroke-linejoin="round" mask="url(#path-6-inside-6_4383_632346)" />
      <path d="M281 66C281 63.7909 282.791 62 285 62H312C314.209 62 316 63.7909 316 66V76C316 78.2091 314.209 80 312 80H285C282.791 80 281 78.2091 281 76V66Z" fill="#1D232B" />
      <text x="298.5" y="74" textAnchor="middle" fill="#8A96A6" fontSize="14">
        {data[1].value}%
      </text>
      <path d="M226.519 288C226.519 285.791 228.309 284 230.519 284H258.519C260.728 284 262.519 285.791 262.519 288V298C262.519 300.209 260.728 302 258.519 302H230.519C228.309 302 226.519 300.209 226.519 298V288Z" fill="#1D232B" />
      <text x="244.519" y="296" textAnchor="middle" fill="#8A96A6" fontSize="14">
        {data[2].value}%
      </text>
      <path d="M0.678711 38C0.678711 35.7909 2.46957 34 4.67871 34H32.6787C34.8879 34 36.6787 35.7909 36.6787 38V48C36.6787 50.2091 34.8879 52 32.6787 52H4.67871C2.46957 52 0.678711 50.2091 0.678711 48V38Z" fill="#1D232B" />
      <text x="18.6787" y="46" textAnchor="middle" fill="#8A96A6" fontSize="14">
        {data[0].value}%
      </text>
      <path d="M39.4976 42.2578H69.625L88.5513 69.2954" stroke="url(#paint6_linear_4383_632346)" stroke-linejoin="round" />
      <path d="M300.019 83.3936V113.521L272.981 132.447" stroke="url(#paint7_linear_4383_632346)" stroke-linejoin="round" />
      <path d="M247.037 277.202V250.426L220 233.605" stroke="url(#paint8_linear_4383_632346)" stroke-linejoin="round" />
      <defs>
        <filter id="filter0_f_4383_632346" x="38.9016" y="24.9979" width="161.407" height="250" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_4383_632346" />
        </filter>
        <filter id="filter1_f_4383_632346" x="171.579" y="32.5095" width="116.9" height="136.871" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_4383_632346" />
        </filter>
        <filter id="filter2_f_4383_632346" x="151.926" y="123.271" width="151.133" height="161.647" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="18" result="effect1_foregroundBlur_4383_632346" />
        </filter>
        <linearGradient id="paint0_linear_4383_632346" x1="177.402" y1="45.9976" x2="174.902" y2="251.998" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5F55EE" />
          <stop offset="1" stop-color="#5F55EE" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_4383_632346" x1="177.402" y1="45.9976" x2="164" y2="256.596" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5F55EE" />
          <stop offset="1" stop-color="#5F55EE" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_4383_632346" x1="259" y1="146.5" x2="191.5" y2="54.9995" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5EEDF8" />
          <stop offset="1" stop-color="#5EEDF8" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_4383_632346" x1="259" y1="146.5" x2="192.598" y2="55.0981" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5EEDF8" />
          <stop offset="1" stop-color="#5EEDF8" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint4_linear_4383_632346" x1="198.576" y1="238.515" x2="262.654" y2="144.586" gradientUnits="userSpaceOnUse">
          <stop stop-color="#ED55D4" />
          <stop offset="1" stop-color="#ED55D4" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint5_linear_4383_632346" x1="198.576" y1="238.515" x2="257.5" y2="159.598" gradientUnits="userSpaceOnUse">
          <stop stop-color="#ED55D4" />
          <stop offset="1" stop-color="#ED55D4" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint6_linear_4383_632346" x1="39.568" y1="54.9981" x2="88.8526" y2="54.2964" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5F55EE" />
          <stop offset="1" stop-color="#5F55EE" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint7_linear_4383_632346" x1="287.278" y1="83.464" x2="287.98" y2="132.749" gradientUnits="userSpaceOnUse">
          <stop stop-color="#60EBF7" />
          <stop offset="1" stop-color="#60EBF7" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint8_linear_4383_632346" x1="234.297" y1="277.14" x2="234.851" y2="233.335" gradientUnits="userSpaceOnUse">
          <stop stop-color="#ED55D4" />
          <stop offset="1" stop-color="#ED55D4" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SuccessRatesByPhase = () => {
  const data = [
    { phase: 'Phase 1', value: 60, color: '#5F55EE' },
    { phase: 'Phase 2', value: 21, color: '#5EEDF8' },
    { phase: 'Live Phase', value: 19, color: '#2FE0A2' },
  ];

  return (
    <Box sx={{
      background: (theme) => theme.palette.color.bg5,
      border: '1px solid',
      borderColor: (theme) => theme.palette.color.strockborder,
      borderRadius: "18px",
      p: 3,
      color: 'white',
      height: '100%',
    }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Success Rates by Phase</Typography>
        <RoundFormSelect
          options={[{ value: "accountType", label: "Account Type" }]}
          defaultValue="accountType"
        />
      </Box>
      <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <CustomPieChart data={data} />
      </Box>
      <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
        {data.map((item) => (
          <Box key={item.phase} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 14, height: 14, backgroundColor: item.color, borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">{item.phase}</Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default SuccessRatesByPhase;