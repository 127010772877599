import { useTheme } from "@mui/material";
import * as React from "react";

const FinancialStatisticsIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.75 14.75L7.5 16.5M7.5 16.5L9.25 18.25M7.5 16.5L9.25 14.75M7.5 16.5L5.75 18.25M10.25 8H5.75M18.25 7.75H14.75M18.25 14.75H14.75M18.25 18.25H14.75M8 10.25V5.75"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FinancialStatisticsIcon;