import React from "react";
import {
  Box,
  Divider,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";

import ChevronLeftIcon from "../../../Icons/ChevronLeft";

import { customerNav } from "../../../../static/customers";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export default function SideBar() {
  let [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { pathname } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: { xs: "none", lg: "block" },
        flexGrow: "0",
        flexShrink: "0",
        width: "min-content",
        background: theme.palette.color.bg5,
        position: "sticky",
        top: 0,
        padding: "20px",
      }}
    >
      <Stack spacing={0}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: "24px" }}>
          <ChevronLeftIcon />
          <Typography variant="h4">105066050</Typography>
        </Box>
        {customerNav.map((item) => (
          <Stack>
            {item.map((item) => {
              const active = pathname === item.path;
              return (
                <Box
                  onClick={() => {
                    navigate(item.path + `?id=${id}`);
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "relative",
                    p: "3px",
                    pr: "16px",
                    paddingLeft: "16px",
                    borderLeft: `2px solid transparent`,
                    ...(active && {
                      borderLeft: (theme) =>
                        `2px solid ${theme.palette.color.active}`,
                      background: (theme) => theme.palette.color.lightgray,
                    }),
                    ":hover": {
                      borderLeft: (theme) =>
                        `2px solid ${theme.palette.color.active}`,
                      background: (theme) => theme.palette.color.lightgray,
                    },
                    transition: "all 0.5s ",
                    cursor: "pointer",
                    my: "1px",
                    height: "40px",
                    width: "235px",
                    borderRadius: "0 6px 6px 0",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.icon ? (
                      <Box
                        sx={{
                          color: (theme) =>
                            theme.palette.color[
                              active ? "primary" : "secondary"
                            ],
                          mr: 1,
                          ml: 0,
                        }}
                      >
                        <item.icon
                          size={16}
                          width="20px"
                          height="20px"
                          stroke={
                            theme.palette.color[
                              active ? "primary" : "secondary"
                            ]
                          }
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: 10,
                          height: 18,
                          border: (theme) =>
                            `1px solid ${alpha(
                              theme.palette.color.secondary,
                              0.15
                            )}`,
                          borderTop: 0,
                          borderRight: 0,
                          borderRadius: "0 0 0 6px",
                          mr: "12px",
                          mt: "-18px",
                          ml: "20px",
                          ...(active && { opacity: 0 }),
                        }}
                      ></Box>
                    )}

                    <Typography
                      variant="caption_500"
                      sx={{
                        ...(active && {
                          color: (theme) => theme.palette.color.primary,
                          lineHeight: "22px",
                        }),
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
            <Divider
              sx={{
                my: "14px !important",
              }}
            />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
