import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { VscMention } from "react-icons/vsc";
import { MdOutlineInsertPhoto } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FiThumbsUp } from "react-icons/fi";
import { PiFlagPennant, PiIntersectThree } from "react-icons/pi";
import {
  Avatar,
  Chip,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { BsThreeDots } from "react-icons/bs";
import { toggleOpen } from "../../slices/openModalSlice";
import { BsGrid1X2 } from "react-icons/bs";
import GeneralTab from "../../pages/Trading-accounts/generalTab";
import TradersActivity from "../../pages/Trading-accounts/TradersActivity";
import { FaArrowRightLong, FaShareFromSquare } from "react-icons/fa6";
import { MdBlock } from "react-icons/md";
import LogIcon from "../Icons/Log";
import CalendarIcon from "../Icons/Calendar";
import { Done } from "@mui/icons-material";
import InfoFillIcon from "../Icons/InfoFill";
import FormInput from "../ui/FormInput";
import VideoIcon from "../Icons/Video";
import { LuArrowUpDown } from "react-icons/lu";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReviewFlaggedTraders = () => {
  const [page, setPage] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { open } = useSelector((state) => state.openModal);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Drawer
        open={open}
        onClose={() => dispatch(toggleOpen())}
        anchor="right"
        sx={{
          borderRadius: "0px",
        }}
      >
        <Paper
          sx={{
            width: "644px",
            height: "100vh",
            overflow: "hidden",
            background: (theme) => theme.palette.color.bg2,
            overflowY: "auto",
          }}
        >
          <Stack direction="row" mt={3} justifyContent={"space-between"} mx={3}>
            <Typography variant="h2">Review flagged traders</Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              gap={2}
              fontSize={"15px"}
            >
              <FiThumbsUp />
              <RxCross2 />
              <Box sx={{ cursor: "pointer" }}>
                <Box onClick={handleClick}>
                  <BsThreeDots />
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    color: "#8996A5",
                  }}
                  PaperProps={{
                    sx: {
                      border: "1px solid #ccc",
                      marginTop: "0px",
                      transform: "translateY(8px)",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={handleClose} color="#8996A5">
                    <FaShareFromSquare
                      style={{ marginRight: "20px", color: "#8996A5" }}
                    />
                    <Typography variant="caption">Resend invitation</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleClose} color="#8996A5">
                    <MdBlock
                      style={{ marginRight: "20px", color: "#8996A5" }}
                    />
                    <Typography variant="caption">Ban user IP</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
          </Stack>

          <Box
          // sx={{
          //     maxHeight: "calc(100vh - 50px)",
          //     overflowY: "auto",
          // }}
          >
            {/* <Stack direction="row" alignItems="center" spacing={1}>
                            <Box
                                sx={{
                                    width: "9px",
                                    height: "9px",
                                    borderRadius: "50%",
                                    background: (theme) => theme.palette.color.green,
                                }}
                            ></Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    transition: "all 0.3s ease",
                                    ":hover": {
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        opacity: 0.35,
                                    },
                                }}
                            >
                                Review Flagged Traders
                            </Typography>
                        </Stack> */}
            {/* <Grid container spacing={1} sx={{ mt: "12px" }}>
                            <Grid item xs={12} sm={8}>
                                <Stack spacing={1.5} sx={{ px: "24px" }}>
                                    <Typography variant="heading">102911050</Typography>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="caption" sx={{ fontWeight: "500" }}>
                                            Tradelocker
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Stack spacing={1}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="caption" sx={{ fontWeight: "500" }}>
                                            Balance:
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                                            $110.000.00
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="caption" sx={{ fontWeight: "500" }}>
                                            Equity:
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                                            $110.000.00
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="caption" sx={{ fontWeight: "500" }}>
                                            Floating:
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Divider sx={{ my: 1 }} />
                        <Box sx={{}}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label="General" {...a11yProps(0)} />
                                <Tab label="Statistics" {...a11yProps(1)} />
                                <Tab label="User information" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <GeneralTab />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            Item Two
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            Item Three
                        </CustomTabPanel>
                        <TradersActivity /> */}

            <Stack
              p={"24px"}
              direction={"row"}
              justifyContent={"space-between"}
              px={3}
              mt={3}
            >
              <Stack spacing={1}>
                <Typography variant="body2">Matches</Typography>
                <Typography variant="h6">8</Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2">Match strength</Typography>
                <Typography variant="h6">85%</Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2">Same user</Typography>
                <Typography variant="h6">-</Typography>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
              px={"24px"}
              pb={2}
              alignItems={"center"}
              fontSize={"15px"}
            >
              <BsGrid1X2 />
              <Typography variant="h6">Overview</Typography>
              <LuArrowUpDown style={{ marginLeft: "30px", color: "#8A96A6" }} />
              <Typography
                variant="h6"
                color={(theme) => theme.palette.color.secondary}
              >
                Positions
              </Typography>
              <Avatar sx={{ height: 15, width: 15, p: 2, bgcolor: "#FF7595" }}>
                8
              </Avatar>
            </Stack>

            <Box
              bgcolor={(theme) => theme.palette.color.darkgray}
              sx={{
                padding: "20px 20px 24px",
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Box
                  sx={{
                    borderBottom: "2px solid",
                    borderColor: (theme) => theme.palette.color.active,
                  }}
                  mr={2}
                  pb={1}
                >
                  <Typography variant="subtitle1_500">
                    From - 10405003
                  </Typography>
                </Box>
                <Box
                  px={2}
                  sx={{ color: (theme) => theme.palette.color.secondary }}
                >
                  <FaArrowRightLong />
                </Box>
                <Box pb={1}>
                  <Typography variant="caption_500">To - 95035393</Typography>
                </Box>
              </Stack>
              <Box
                p={2}
                sx={{ bgcolor: (theme) => theme.palette.color.borderpure }}
              >
                <Stack direction="row" alignItems="center">
                  <Typography variant="h1" mr={2}>
                    Jens van der Blij
                  </Typography>
                  <Chip
                    label="Verified"
                    sx={{
                      color: (theme) => theme.palette.color.green,
                      borderColor: (theme) => theme.palette.color.green,
                      pl: "6px",
                    }}
                    icon={
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          bgcolor: (theme) => theme.palette.color.green,
                        }}
                      />
                    }
                    variant="outlined"
                  />
                </Stack>
                <Stack direction={"row"} mt={2}>
                  <Typography
                    variant="heading"
                    color={(theme) => theme.palette.color.secondary}
                  >
                    · Flag record:
                  </Typography>
                  <Typography
                    variant="heading"
                    ml={5}
                    color={(theme) => theme.palette.color.secondary}
                  >
                    0
                  </Typography>
                </Stack>

                <Divider sx={{ my: 2 }} />
                <UserInformation />
                <ChallengeInformation />
                <ActivityComponent />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Drawer>
    </React.Fragment>
  );
};

export default ReviewFlaggedTraders;

const UserInformation = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack direction="row" spacing={1}>
          <InfoFillIcon />
          <Typography
            variant="h6"
            sx={{ color: (theme) => theme.palette.color.primary }}
          >
            User profile
          </Typography>
        </Stack>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Overview" />
          <Tab label="Details" />
          <Tab label="Account" />
        </Tabs>
      </Stack>

      <Box
        sx={{
          bgcolor: (theme) => theme.palette.color.bg,
          borderRadius: "15px",
          p: "20px",
        }}
      >
        {value === 0 && <OverViewContent />}
        {value === 1 && <OverViewContent />}
        {value === 2 && <OverViewContent />}
      </Box>
    </Stack>
  );
};

const OverViewContent = () => {
  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Full name
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            Jens van der Blij
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Country
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            United Kingdom
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} alignItems="center">
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Timezone
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            London GMT +1
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Member since
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            15 June, 2024
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} alignItems="center">
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Funded
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            -
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Av. Payout
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            -
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} alignItems="center">
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Total payout
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            $1,500.50
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            TLV
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            $15,200.15
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Total purchases
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            15
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} alignItems="center">
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Open tickets
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            -
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Active accounts
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            align="right"
          >
            1
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const ChallengeInformation = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Stack mt={5}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack direction="row" spacing={1}>
          <InfoFillIcon />
          <Typography
            variant="h6"
            sx={{ color: (theme) => theme.palette.color.primary }}
          >
            Challenge information
          </Typography>
        </Stack>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Overview" />
          <Tab label="Objectives" />
          <Tab label="Rules" />
          <Tab label="Statistics" />
        </Tabs>
      </Stack>

      <Box
        sx={{
          bgcolor: (theme) => theme.palette.color.bg,
          borderRadius: "15px",
          p: "20px",
        }}
      >
        {value === 0 && (
          <Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3} display="flex" alignItems="center">
                <CalendarIcon />
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary, ml: 1 }}
                >
                  Start date
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  Apr 04, 2024 11:05 AM
                </Typography>
              </Grid>
              <Grid item xs={1} display="flex" justifyContent="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  ➔
                </Typography>
              </Grid>
              <Grid item xs={2} display="flex" alignItems="center">
                <CalendarIcon />
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary, ml: 1 }}
                >
                  Due date
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  -
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Size
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  100K
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Platform
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  <PiIntersectThree size={12} /> MT4
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} alignItems="center">
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Challenge type
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  Type
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Phase
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  1
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Floating PNL
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  -
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Open trades
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  None
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} alignItems="center">
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Balance
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  111,500.00
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Equity
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  align="right"
                >
                  111,500.00
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {value === 1 && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Overall Drawdown
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  10%{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Daily Drawdown
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  5%{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Min. Days
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  5{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Max. Days
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  ∞{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {value === 2 && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  EA
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  No{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  News
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  No{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Copy-trading
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  No{" "}
                  <PiFlagPennant style={{ color: "red", marginLeft: "4px" }} />
                </Typography>
              </Grid>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Heding
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  No{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  HFT
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  No{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {value === 3 && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Overall Drawdown
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  10%{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Daily Drawdown
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  5%{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Min. Days
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  5{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={3} container alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.primary }}
                >
                  Max. Days
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  ∞{" "}
                  <Done
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: "13px",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

const ActivityComponent = () => {
  const activities = [
    {
      id: 1,
      account: "95035393",
      tradeAccount: "10405003",
      pair: "EURUSD",
      timeAgo: "15 mins ago",
    },
    {
      id: 2,
      account: "95035393",
      tradeAccount: "10405003",
      pair: "GBPUSD",
      timeAgo: "15 mins ago",
    },
    {
      id: 3,
      account: "95035393",
      tradeAccount: "10405003",
      pair: "GBPUSD",
      timeAgo: "15 mins ago",
    },
    {
      id: 4,
      account: "95035393",
      tradeAccount: "10405003",
      pair: "GBPUSD",
      timeAgo: "15 mins ago",
    },
  ];

  return (
    <Stack mt={5}>
      <Stack direction={"row"} spacing={1}>
        <LogIcon />
        <Typography
          variant="h5"
          sx={{ color: (theme) => theme.palette.color.primary }}
        >
          Activity
        </Typography>
      </Stack>
      <Stack
        sx={{
          bgcolor: (theme) => theme.palette.color.bg,
          mt: 1,
          borderRadius: "15px",
          p: "20px",
        }}
      >
        {activities.map((activity) => (
          <Stack
            key={activity.id}
            direction="row"
            spacing={1}
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Stack direction={"row"} spacing={1}>
              <LogIcon />
              <Box>
                <Typography variant="h6" gutterBottom>
                  {" "}
                  Copy trading - detected
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {" "}
                  Account {activity.account} opened same trade as{" "}
                  {activity.tradeAccount} on {activity.pair}{" "}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {" "}
                  on almost the exact same time{" "}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  · by system{" "}
                </Typography>
              </Box>
            </Stack>
            <Typography variant="subtitle2">{activity.timeAgo}</Typography>
          </Stack>
        ))}
        <Stack direction="row" spacing={1} alignItems="center" width={"100%"}>
          <Box
            width={"100%"}
            p={2}
            borderRadius={"10px"}
            border={"1px solid"}
            borderColor={(theme) => theme.palette.color.strockborder}
          >
            <FormInput
              multiline
              rows={4}
              sx={{
                width: "100%",
                background: (theme) => theme.palette.color.bg3,
                border: "none",
                ":focus": {
                  border: "none",
                  outline: "none",
                },
                ":focus-within": {
                  border: "none",
                  outline: "none",
                },
              }}
              placeholder="Leave a comment"
            />
            <Stack
              direction="row"
              spacing={2}
              mt={2}
              alignItems={"center"}
              color={(theme) => theme.palette.color.primary}
              sx={{ fontSize: 22 }}
            >
              <VideoIcon />
              <MdOutlineInsertPhoto />
              <VscMention />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
