import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import BrowseIcon from "../../../components/Icons/Browse";
import SMSIcon from "../../../components/Icons/SMS";
import MailIcon from "../../../components/Icons/Mail";
import FormatIcon from "../../../components/Icons/Format";
import DropdownMenu from "../../../components/Settings/DropdownMenu";

const Notifications = () => {
    return (
        <Box width={"100%"}>
            <Box
                sx={{
                    px: "24px",
                    py: "10px",
                }}
            >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"end"}>
                    <Typography variant="h3" gutterBottom>
                        Notifications
                    </Typography>
                    <Button size='small' sx={{ p: "5px", px: "10px", borderRadius: "32px", bgcolor: (theme) => theme.palette.color.active, color: "white", ":hover": { background: (theme) => theme.palette.color.active } }} onClick={() => { }}>
                        Save Changes
                    </Button>
                </Stack>
                <Stack direction={"row"} width={"70%"} mt={1}>
                    <Typography variant="caption">View and update your notification preferences</Typography>
                </Stack>
            </Box>
            <Divider />
            <Box
                sx={{
                    p: "24px",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SettingOption
                            icon={<FormatIcon stroke="#8A96A6" />}
                            title="Time & date format"
                            description="Default · Our recommended settings"
                            defaultValue="Default"
                        />
                        <SettingOption
                            icon={<MailIcon stroke="#8A96A6" />}
                            title="Email"
                            description="Default · Our recommended settings"
                            defaultValue="Default"
                        />
                        <SettingOption
                            icon={<BrowseIcon stroke="#8A96A6" />}
                            title="Browser"
                            description="Default · Our recommended settings"
                            defaultValue="Default"
                        />
                        <SettingOption
                            icon={<SMSIcon stroke="#8A96A6" />}
                            title="SMS"
                            description="Default · Our recommended settings"
                            defaultValue="Default"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Notifications;

const SettingOptionContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.color.strockborder,
    border: '1px solid',
    borderColor: theme.palette.color.lightgray,
    borderRadius: "18px",
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

const types = [
    {
        value: "Default",
        label: "Default",
        color: "cyan",
        description: "Recommended  settings",
    },
    {
        value: "Custom",
        label: "Custom",
        color: "pink",
        description: "Configure custom settings for this channel",
    },
];

const SettingOption = ({ icon, title, description, defaultValue }) => {
    const theme = useTheme();
    const [selectedType, setSelectedType] = useState(
        types.find((type) => type.value === defaultValue)
    );

    return (
        <SettingOptionContainer>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Box display="flex" alignItems="center">
                        <IconButton sx={{ color: theme.palette.color.secondary, fontSize: "20px" }}>
                            {icon}
                        </IconButton>
                        <Box ml={2}>
                            <Typography variant="h6">
                                {title}
                            </Typography>
                            <Typography variant="caption" mt={1}>
                                {description}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <DropdownMenu
                        types={types}
                        type={selectedType}
                        onTypeChange={setSelectedType}
                    />
                </Grid>
            </Grid>
        </SettingOptionContainer>
    );
};