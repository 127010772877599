import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Paper,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ExportSection from "../../../components/ui/ExportSection";
import CalendarPopup from "../../../components/ui/CalendarPopup";
import DisplayColumns from "../../../components/ui/DisplayColumns";
import dayjs from "dayjs";
import FilterPopup from "../../../components/ui/FilterPopup";
import { FiDownloadCloud } from "react-icons/fi";
// import { ipaddressdata } from "../../../static/flaggedaccounts";
import SmallSearchBar from "../../../components/Affiliates/smallSearchBar";
import CopyTraderCard from "../../../components/Trading-accounts/CopyTraderCard";
import ReviewFlaggedTraders from "../../../components/Trading-accounts/ReviewFlaggedTraders";
import RiskManagementInfoCard from "../../../components/Risk-Management/RiskManagementInfoCard";

const Items = [
  {
    title: "Total scanned",
    tooltip: "Active accounts that are doing a evaluation, funded are on trial",
    value: "1841",
    key: "active",
  },
  {
    title: "Total copy traders",
    tooltip: "All inactive accounts",
    value: "340",
    key: "inactive",
  },
  {
    title: "Total passed",
    tooltip: "All inactive accounts",
    value: "120",
    key: "inactive",
  },
];

const Items2 = [
  {
    title: "Total Flagged Accounts",
    tooltip: "Total Flagged Accounts",
    value: "1841"
  },
  {
    title: "Flagged for Breach",
    tooltip: "Flagged for Breach",
    value: "340"
  },
  {
    title: "Flagged for Inactivity",
    tooltip: "Flagged for Inactivity",
    value: "120"
  },
  {
    title: "Flagged for Verification",
    tooltip: "Flagged for Verification",
    value: "25"
  },
];

const headcells = [
  {
    id: "ipaddress",
    label: "IP-Address",
    default: true,
    getCell: (row) => row.ipaddress,
  },
  {
    id: "customer",
    label: "Customer",
    default: true,
    getCell: (row) => row.customer,
  },
  {
    id: "countries",
    label: "Countries",
    default: true,
    getCell: (row) => row.countries,
  },
  {
    id: "accounts",
    label: "Accounts",
    default: true,
    getCell: (row) => row.accounts,
  },
  {
    id: "purchase",
    label: "Purchase",
    default: true,
    getCell: (row) => row.purchase,
  },
  {
    id: "active",
    label: "Active",
    default: true,
    getCell: (row) => row.active,
  },
  {
    id: "action",
    label: "Action",
    default: true,
    getCell: (row) => (
      <Box
        sx={{
          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <FiDownloadCloud size={18} />
      </Box>
    ),
  },
];

const CopyTraders = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const [status, setStatus] = useState("");
  const [heads, setHeads] = React.useState(
    headcells.filter((cell) => cell.default).map((cell) => cell.id)
  );
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [rangeValue, setRangeValue] = useState([20, 37]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedPurchase, setSelectedPurchase] = useState([]);
  const [cols, setCols] = useState(headcells);

  // const parseRange = (rangeStr) => {
  //   const [min, max] = rangeStr.split(" - ").map(Number);
  //   return { min, max };
  // };

  // const filterData = () => {
  //   return ipaddressdata.filter((d) => {
  //     if (d.countries < rangeValue[0] || d.countries > rangeValue[1])
  //       return false;

  //     if (selectedAccounts.length > 0) {
  //       const accountMatch = selectedAccounts.some((range) => {
  //         const { min, max } = parseRange(range);
  //         return d.accounts >= min && d.accounts <= max;
  //       });
  //       if (!accountMatch) return false;
  //     }

  //     if (selectedPurchase.length > 0) {
  //       const purchaseMatch = selectedPurchase.some((range) => {
  //         const { min, max } = parseRange(range);
  //         return d.purchase >= min && d.purchase <= max;
  //       });
  //       if (!purchaseMatch) return false;
  //     }

  //     return true;
  //   });
  // };
  return (
    <Paper
      sx={{
        px: "12px",
        py: "24px",
        width: "100%",
      }}
    >
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        justifyContent={isSmallScreen ? "flex-start" : "space-between"}
        alignItems={isSmallScreen ? "flex-start" : "center"}
        gap={2}
      >
        <Box
          sx={{
            ml: "15px",
            display: "flex",
            alignItems: "start",
          }}
        >
          <Typography variant="subtitle1" mb={2}>
            Copy Traders
          </Typography>
        </Box>
        <Stack
          direction="row"
          spacing={isSmallScreen ? 1 : 2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            mb: "24px",
            fontSize: "11.5px",
            pr: "24px",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <SmallSearchBar />
          <ExportSection />
          <CalendarPopup
            mainStartDate={startDate}
            mainEndDate={endDate}
            setMainStartDate={setStartDate}
            setMainEndDate={setEndDate}
          />
          <DisplayColumns
            columns={cols}
            setColumns={setCols}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
          <FilterPopup
            rangeFilter={{
              label: "Countries",
              ariaLabel: "Countries",
              value: rangeValue,
              onChange: setRangeValue,
            }}
            accordions={[
              {
                title: "Accounts",
                defaultExpanded: true,
                items: ["0 - 10", "10 - 30", "30 - 50"],
                selectedItems: selectedAccounts,
                onChange: setSelectedAccounts,
              },
              {
                title: "Purchase",
                defaultExpanded: true,
                items: ["0 - 10", "10 - 30", "30 - 50"],
                selectedItems: selectedPurchase,
                onChange: setSelectedPurchase,
              },
            ]}
          />
        </Stack>
      </Stack>

      <Grid container spacing={2}>
        {Items.map((item, index) => (
          <Grid
            item
            xs={index === 2 ? 12 : 6}
            md={4}
            lg={4}
            spacing={3}
            key={index}
          >
            <RiskManagementInfoCard item={item}/>
          </Grid>
        ))}
        {Items2.map((item, index) => (
          <Grid
            item
            xs={6}
            md={3}
            lg={3}
            spacing={3}
            key={index}
          >
            <RiskManagementInfoCard item={item}/>
          </Grid>
        ))}
      </Grid>

      <Stack direction="row" gap={5} mt={3} sx={{ overflowX: "auto" }}>
        <Grid
          container
          spacing={2}
          sx={{ display: { xs: "block", sm: "flex" } }}
        >
          {[...Array(3)].map((_, index) => (
            <Grid key={index} item xs={12} md={12} lg={12}>
              <CopyTraderCard value={index === 0 ? "80" : index === 1 ? "65" : "23"} />
            </Grid>
          ))}
        </Grid>
      </Stack>

      <ReviewFlaggedTraders />
      {/* <CustomTable headcells={cols} rows={filterData()} /> */}
    </Paper>
  );
};

export default CopyTraders;