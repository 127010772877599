import { Box } from "@mui/material";
import Header from "./Header";
import SideBar from "./Sidebar";
import { Outlet } from "react-router-dom";
import SideBar1 from "./Sidebar/Sidebar2";

export default function Layout() {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: (theme) => theme.palette.color.bg,
      }}
    >
      <SideBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxHeight: "100vh",
        }}
      >
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflowY: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              overflowY: "auto",
              height: "100%",
            }}
          >
            <SideBar1 />
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
