import { Box } from "@mui/material";
import FileIcon from "../components/Icons/File";
import { BiBarChartSquare } from "react-icons/bi";
import PayoutIcon from "../components/Icons/PayoutsIcon";
import OrdersIcon from "../components/Icons/Orders";
import FlagIcon from "../components/Icons/Flag";
import LogIcon from "../components/Icons/Log";
import FolderIcon from "../components/Icons/Folder";
import AllIcon from "../components/Icons/All";

export const overviewItems = [
  {
    title: "Active accounts",
    value: "1",
    tooltip: "Amount of unresolved open tickets",
  },
  {
    title: "Book",
    value: "A",
    tooltip: "Amount of unresolved open tickets",
  },
  {
    title: "Total Payouts",
    value: "4",
    tooltip: "Amount of unresolved open tickets",
  },
  {
    title: "Total Payouts $",
    value: "$4,500.50",
    tooltip: "Amount of unresolved open tickets",
  },
  {
    title: "Total Orders",
    value: "5",
    tooltip: "Amount of unresolved open tickets",
  },
  {
    title: "Open Tickets",
    value: "$1,500.15",
    tooltip: "Amount of unresolved open tickets",
  },
  {
    title: "Type Trader",
    value: "Swinger",
    tooltip: "Amount of unresolved open tickets",
  },
  {
    title: "Risk",
    value: "Low",
    tooltip: "Amount of unresolved open tickets",
  },
];

export const accounts = [
  {
    id: "#10505200 - product group",
    color: "yellow",
    equity: "110,000.00",
    createdAt: "24 Apr 2024, 11:04",
  },
  {
    id: "#10505200 - product group",
    color: "yellow",
    equity: "110,000.00",
    createdAt: "3 May 2024, 11:04",
  },
  {
    id: "#10505200 - product group",
    color: "green",
    equity: "90,000.00",
    createdAt: "1 May 2024, 11:04",
  },
  {
    id: "#10505200 - product group",
    color: "green",
    equity: "90,000.00",
    createdAt: "2 May 2024, 11:04",
  },
  {
    id: "#10505200 - product group",
    color: "red",
    equity: "110,000.00",
    createdAt: "5 May 2024, 11:04",
  },
];

export const customerNav = [
  [
    { title: "Overview", path: "/firm/customer/overview", icon: FileIcon },
    {
      title: "Statistics",
      path: "/firm/customer/statistics",
      icon: ({ stroke, ...props }) => (
        <Box sx={{ color: stroke }}>
          <BiBarChartSquare {...props} />
        </Box>
      ),
    },
  ],
  [
    {
      title: "Trading accounts",
      path: "/firm/customer/trading-accounts",
      icon: AllIcon,
    },
    { title: "A-book", path: "/firm/customer/a-book" },
    { title: "Payouts", path: "/firm/customer/payouts", icon: PayoutIcon },
    { title: "Orders", path: "/firm/customer/orders", icon: OrdersIcon },
    { title: "Tickets", path: "/firm/customer/tickets", icon: FolderIcon },
    { title: "Flag", path: "/firm/customer/flag", icon: FlagIcon },
  ],
  [
    {
      title: "Activity/ Log",
      path: "/firm/customer/activity-log",
      icon: LogIcon,
    },
  ],
];
