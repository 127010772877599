import { Box, Stack, styled, Typography } from '@mui/material';
import React from 'react'
import RoundFormSelect from '../../ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const FailedAccountsByPhaseAndType = () => {
  const ChartContainer = styled(Box)({
    width: '100%',
    height: '100%',
    '& .apexcharts-toolbar': {
      display: 'none !important',
    },
  });

  const series = [
    {
      name: 'Account type A',
      data: [120, 130, 130],
    },
    {
      name: 'Account type B',
      data: [110, 140, 180],
    },
    {
      name: 'Account type C',
      data: [250, 130, 140],
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '20%',
        spacing: 10,
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    colors: ['#2FE0A2', '#F55F82', '#FFC452'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Phase 1', 'Phase 2', 'Live'],
      labels: {
        style: {
          colors: ['#8A96A6', '#8A96A6', '#8A96A6'],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      max: 500,
      title: {
        text: "Number of Successful Accounts",
        style: {
          color: '#8A96A6',
        },
      },
      labels: {
        formatter: (val) => `${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
      tickAmount: 5,
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
  };

  return (
    <Box sx={{
      background: (theme) => theme.palette.color.bg5,
      border: '1px solid',
      borderColor: (theme) => theme.palette.color.strockborder,
      p: '10px',
      borderRadius: '16px',
    }}>
      <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h5">
        Number of Failing Accounts
        </Typography>
        <Stack direction="row" gap={2}>
          <RoundFormSelect
            options={[{ value: "accountType", label: "Account type" }]}
            defaultValue="accountType"
          />
        </Stack>
      </Stack>
      <ChartContainer>
        <ReactApexChart
          options={options}
          series={series.map(({ name, data }, index) => ({
            name,
            data,
            type: 'column'
          }))}
          type="bar"
          height={350}
        />
      </ChartContainer>
      <Stack direction="row" spacing={2} p={2} alignItems={"center"} justifyContent={"center"}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 12, height: 12, backgroundColor: '#FFC452', borderRadius: '50%', mr: 1 }} />
          <Typography variant="body2" sx={{ color: '#FFFFFF' }}>Account type A</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 12, height: 12, backgroundColor: '#F55F82', borderRadius: '50%', mr: 1 }} />
          <Typography variant="body2" sx={{ color: '#FFFFFF' }}>Account type B</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 12, height: 12, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
          <Typography variant="body2" sx={{ color: '#FFFFFF' }}>Account type C</Typography>
        </Box>
      </Stack>
    </Box>
  );
}

export default FailedAccountsByPhaseAndType