import React from 'react';
import { Grid, Typography, Box, Stack } from '@mui/material';

const items = [
    {
        title: "Changelog",
        date: "June 12, 2024",
        image: "/images/whats-new/1.avif",
        content: "Today were introducing performance improvements to project loading. When opening your projects today you might see a little modal asking you to optimize your project. This is safe, and we wont touch your layers or content. It will make your projects up 30% faster to load. Framer used to do some heavy computation when loading projects, mainly computing hashes for SVG graphics, so we knew which ones were unique. With this new update we can now skip these, resulting in a big speed-up. Please let us know if you encounter any issues.",
    },
    {
        title: "Changelob",
        date: "June 12, 2024",
        image: "/images/whats-new/2.avif",
        content: "Were incredibly excited to introduce 3D Transforms, our biggest creative leap forward yet. This update brings over 10 new properties, improved Effects, redesigned selection controls, and more. Place any layer in 3D space by editing properties like Rotation, Skew, Origin, Depth, and Perspective. For the first time on a freeform Canvas, all 3D layers remain selectable and editable, resulting in an unparalleled experience. These new features work seamlessly with Effects, Components, Cursors, and Overlays, letting you craft animations and interactions of the highest fidelity. We think youll love it. Watch the video for more, or explore our demos.",
    },
    {
        title: "Changelob",
        date: "June 12, 2024",
        image: "/images/whats-new/3.avif",
        content: "Were incredibly excited to introduce 3D Transforms, our biggest creative leap forward yet. This update brings over 10 new properties, improved Effects, redesigned selection controls, and more. Place any layer in 3D space by editing properties like Rotation, Skew, Origin, Depth, and Perspective. For the first time on a freeform Canvas, all 3D layers remain selectable and editable, resulting in an unparalleled experience. These new features work seamlessly with Effects, Components, Cursors, and Overlays, letting you craft animations and interactions of the highest fidelity. We think youll love it. Watch the video for more, or explore our demos.",
    },
    {
        title: "Changelob",
        date: "June 12, 2024",
        image: "/images/whats-new/4.avif",
        content: "Were incredibly excited to introduce 3D Transforms, our biggest creative leap forward yet. This update brings over 10 new properties, improved Effects, redesigned selection controls, and more. Place any layer in 3D space by editing properties like Rotation, Skew, Origin, Depth, and Perspective. For the first time on a freeform Canvas, all 3D layers remain selectable and editable, resulting in an unparalleled experience. These new features work seamlessly with Effects, Components, Cursors, and Overlays, letting you craft animations and interactions of the highest fidelity. We think youll love it. Watch the video for more, or explore our demos.",
    },
    {
        title: "Changelob",
        date: "June 12, 2024",
        image: "/images/whats-new/5.avif",
        content: "Were incredibly excited to introduce 3D Transforms, our biggest creative leap forward yet. This update brings over 10 new properties, improved Effects, redesigned selection controls, and more. Place any layer in 3D space by editing properties like Rotation, Skew, Origin, Depth, and Perspective. For the first time on a freeform Canvas, all 3D layers remain selectable and editable, resulting in an unparalleled experience. These new features work seamlessly with Effects, Components, Cursors, and Overlays, letting you craft animations and interactions of the highest fidelity. We think youll love it. Watch the video for more, or explore our demos.",
    },
    {
        title: "Changelob",
        date: "June 12, 2024",
        image: "/images/whats-new/6.avif",
        content: "Were incredibly excited to introduce 3D Transforms, our biggest creative leap forward yet. This update brings over 10 new properties, improved Effects, redesigned selection controls, and more. Place any layer in 3D space by editing properties like Rotation, Skew, Origin, Depth, and Perspective. For the first time on a freeform Canvas, all 3D layers remain selectable and editable, resulting in an unparalleled experience. These new features work seamlessly with Effects, Components, Cursors, and Overlays, letting you craft animations and interactions of the highest fidelity. We think youll love it. Watch the video for more, or explore our demos.",
    },
    {
        title: "Changelob",
        date: "June 12, 2024",
        image: "/images/whats-new/7.avif",
        content: "Were incredibly excited to introduce 3D Transforms, our biggest creative leap forward yet. This update brings over 10 new properties, improved Effects, redesigned selection controls, and more. Place any layer in 3D space by editing properties like Rotation, Skew, Origin, Depth, and Perspective. For the first time on a freeform Canvas, all 3D layers remain selectable and editable, resulting in an unparalleled experience. These new features work seamlessly with Effects, Components, Cursors, and Overlays, letting you craft animations and interactions of the highest fidelity. We think youll love it. Watch the video for more, or explore our demos.",
    },
    {
        title: "Changelob",
        date: "June 12, 2024",
        image: "/images/whats-new/8.avif",
        content: "Weve collaborated with the team at Contra to create a new component that links your Contra profile with your Framer site. With the Contra component, you can display the services from your Contra profile on your site and ensure that the data will always stay up to date. This can lead to more companies hiring you for your skills. You can find the Contra component in the Insert Panel under the Utilities section. This component provides you with extended design control through its properties to match the look and feel of your beautiful site, built in Framer.",
    }
]

const WhatsNew = () => {
    return (
        <Box sx={{ padding: "20px", bgcolor: (theme) => theme.palette.color.bg, height: '100vh', overflowY: 'auto' }}>
            <Stack py={{ xs: '20px', md: '96px' }} sx={{ alignItems: "center", justifyContent: "center" }}>
                <Typography variant="h3" gutterBottom>Updates</Typography>
                <Typography variant="h5" color={(theme) => theme.palette.color.secondary}>What's new.</Typography>
            </Stack>
            {items.map((item, index) => (
                <Box key={index}>
                    <ChangelogSection item={item} />
                </ Box>
            ))}
        </Box>
    );
};

export default WhatsNew;


const ChangelogSection = ({ item }) => {
    return (
        <Grid container spacing={2} mb={{ xs: "42px", md: "100px" }} direction={{ xs: 'column', md: 'row' }}>
            <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom>
                    {item.title}
                </Typography>
                <Typography variant="subtitle1" color={(theme) => theme.palette.color.secondary}>
                    {item.date}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    component="img"
                    src={item.image}
                    alt="whatsnew"
                    sx={{ width: '100%', borderRadius: '8px' }}
                />
                <Typography variant="body1" color={(theme) => theme.palette.color.secondary} fontSize={"15.5px"} lineHeight={"25px"} mt={2}>
                    {item.content}
                </Typography>
            </Grid>
        </Grid>
    )
}
