import { Box, Typography, useTheme } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const TicketsByPriorityLevelChart = () => {
  const theme = useTheme();
  const options = {
    labels: ["High", "Medium", "Low"],
    colors: [
      theme.palette.color.active,
      theme.palette.color.greenShade,
      theme.palette.color.cyan,
    ],
    legend: {
      show: false,
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.labels[opts.seriesIndex];
      },
      style: {
        fontSize: "20px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "500",
        colors: ["#8A96A6"],
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.6,
        dataLabels: {
          offset: 98,
          minAngleToShowLabel: 10,
        },
      },
    },
  };

  const series = [60, 25, 15];
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        p: 3,
        my: 3,
        width: "100%",
      }}
    >
      <Typography variant="h5">Tickets by Priority Level</Typography>

      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        height={350}
      />
    </Box>
  );
};

export default TicketsByPriorityLevelChart;
