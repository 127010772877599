import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://apipfsdev.unbelievable.digital/api/",
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
    }),
    sendPasswordRecoveryEmail: builder.mutation({
      query: (email) => ({
        url: "send-password-recovery-email",
        method: "POST",
        body: {
          email,
        },
      }),
    }),
  }),
});

export const { useLoginMutation, useSendPasswordRecoveryEmailMutation } =
  loginApi;
export default loginApi;
