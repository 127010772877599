import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utils/getBaseQuery";

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getEmailSettings: builder.query({
      query: () => `get-email-settings`,
    }),
    updateEmailSettings: builder.mutation({
      query: (data) => ({
        url: `update-email-settings`,
        method: "POST",
        body: data,
      }),
    }),
    getWebHooks: builder.query({
      query: () => `get-webhooks`,
    }),
    createWebHook: builder.mutation({
      query: (data) => ({
        url: `create-webhook`,
        method: "POST",
        body: data,
      }),
    }),
    updateBillingDetails: builder.mutation({
      query: (data) => ({
        url: `update-billing-details`,
        method: "POST",
        body: data,
      }),
    }),
    getBillingDetails: builder.query({
      query: () => `get-billing-details`,
    }),

    getTenantSettings: builder.query({
      query: () => `get-tenant-settings`,
    }),
    updateTenantSettings: builder.mutation({
      query: (data) => ({
        url: `update-tenant-settings`,
        method: "POST",
        body: data,
      }),
    }),
    updateUserProfile: builder.mutation({
      query: (data) => ({
        url: `update-user-profile`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetEmailSettingsQuery,
  useUpdateEmailSettingsMutation,
  useGetWebHooksQuery,
  useCreateWebHookMutation,
  useUpdateBillingDetailsMutation,
  useGetBillingDetailsQuery,
  useGetTenantSettingsQuery,
  useUpdateTenantSettingsMutation,
  useUpdateUserProfileMutation,
} = settingsApi;
