import {
  alpha,
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FormInput from "../../components/ui/FormInput";
import MailIcon from "../../components/Icons/Mail";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSendPasswordRecoveryEmailMutation } from "../../slices/api/loginSlice";

const ForgetPassword = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [sendMail] = useSendPasswordRecoveryEmailMutation();

  const resetPassword = async () => {
    const res = await sendMail(email);
    if (res.error) {
      if (res.error.data.message.email)
        setErrorMessage(res.error.data.message.email[0]);
      else setErrorMessage(res.error.data.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    } else {
      setEmailSent(true);
    }

    // setEmailSent(true);
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg,
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: 2,
        }}
      >
        <img
          src="/svg/loginIcon.svg"
          alt="UNI"
          style={{ width: "78px", height: "29px" }}
        />
        <Box
          sx={{
            gap: "10px",
            alignItems: "center",
            display: isSmall ? "none" : "flex",
          }}
        >
          <Button
            size="small"
            sx={{
              height: "36px",
              borderRadius: "32px",
              bgcolor: (theme) => theme.palette.color.bg5,
              color: (theme) => theme.palette.color.secondary,
              border: (theme) =>
                `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
              ":hover": { background: (theme) => theme.palette.color.bg5 },
            }}
          >
            Don't have an account?
          </Button>
          <Button
            size="small"
            sx={{
              height: "36px",
              borderRadius: "32px",
              bgcolor: (theme) => theme.palette.color.active,
              color: (theme) => theme.palette.color.primary,
              border: (theme) =>
                `1px solid ${alpha(theme.palette.color.active, 0.15)}`,
              ":hover": { background: (theme) => theme.palette.color.active },
            }}
          >
            Contact sales
          </Button>
        </Box>
      </Box>
      <Stack
        spacing={6}
        alignItems="center"
        sx={{
          background: "#0b0d0f",
          padding: "24px",
          width: "520px",
          borderRadius: "12px",
          border: `1px solid rgba(21, 25, 31, 0.65)`,
          zIndex: 2,
        }}
      >
        <Stack spacing={2}>
          <Typography
            variant="h1"
            sx={{ fontSize: "24px", textAlign: "center" }}
          >
            Forgot your password
          </Typography>
          {emailSent ? (
            <>
              <Typography
                variant="body3"
                sx={{
                  textAlign: "center",
                  "& a": {
                    textDecoration: "none",
                    color: (theme) => theme.palette.color.primary,
                  },
                }}
              >
                We sent a password reset link to{" "}
                <a href="mailto://example@site.com">example@site.com</a>
              </Typography>
            </>
          ) : (
            <Typography
              variant="body3"
              sx={{ textAlign: "center", fontSize: "14px" }}
            >
              No worries, we'll send you reset instructions.
            </Typography>
          )}
        </Stack>

        {emailSent ? (
          <Stack spacing={3} width={"100%"}>
            <Typography
              variant="body3"
              sx={{
                textAlign: "center",
                "& a": {
                  textDecoration: "none",
                  color: (theme) => theme.palette.color.active,
                },
              }}
            >
              Didn't receive the email?{" "}
              <a href="mailto://help@abc.com">Resend</a>
            </Typography>
            <Button
              variant="contained"
              sx={{ borderRadius: "32px", height: "50px", fontWeight: "15px" }}
              fullWidth
            >
              Open Email
            </Button>
          </Stack>
        ) : (
          <Stack spacing={3} width={"100%"}>
            <Stack spacing={1} width={"100%"}>
              <FormInput
                label="Email Address"
                placeholder="example@site.com"
                type="email"
                startAdornment={<MailIcon width="36" height="36" />}
                sx={{ px: "12px", borderRadius: "16px" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errorMessage && (
                <Typography
                  variant="body3"
                  sx={{
                    color: (theme) => theme.palette.color.red,
                    fontWeight: "400",
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Stack>
            <Button
              onClick={resetPassword}
              variant="contained"
              sx={{ borderRadius: "32px", height: "50px", fontWeight: "15px" }}
              fullWidth
            >
              Reset Password
            </Button>
          </Stack>
        )}
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/login");
          }}
        >
          <Typography
            variant="body3"
            sx={{ fontSize: "14px", textAlign: "center" }}
          >
            <IoArrowBackOutline />
          </Typography>
          <Typography
            variant="body3"
            sx={{ fontSize: "14px", textAlign: "center" }}
          >
            Back to login
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{
          position: "absolute",
          right: "-270px",
          bottom: "-150px",
          opacity: "0.1",
          left: "544px",
          zIndex: 1,
        }}
      >
        <img src="/images/background.png" alt="background" height="1166px" />
      </Box>
    </Box>
  );
};

export default ForgetPassword;
