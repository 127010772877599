import { IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../components/Firm/Orders/Table";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { MdOutlineMoreHoriz } from "react-icons/md";
import TeamSelect from "../../components/Settings/TeamSelect";
import AddMemberMenu from "../../components/Settings/AddMemberMenu";

const types = [
  {
    value: "Customer support",
    label: "Customer support",
    color: "#2EE0A1",
    description: "Access to tickets and client data",
  },
  {
    value: "Data Analyst",
    label: "Data Analyst",
    color: "#2EE0A1",
    description: "Access to statistics",
  },
  {
    value: "Finance",
    label: "Finance",
    color: "#2EE0A1",
    description: "Access to all dashboards for finance",
  },
  {
    value: "Risk Analyst",
    label: "Risk Analyst",
    color: "#2EE0A1",
    description: "Able to manage people",
  },
  {
    value: "Admin",
    label: "Admin",
    color: "#5E54ED",
    description: "Access to all",
  },
];

const allMembers = [
  {
    image: "/images/person.avif",
    isAI: true,
    name: "Jeans",
    online: true,
  },
  {
    image: "/images/person.avif",
    isAI: true,
    name: "Jeans",
    online: true,
  },
  {
    image: "/images/person.avif",
    isAI: true,
    name: "Jeans",
    online: true,
  },
];

const headcells = [
  {
    id: "alias",
    label: "Team",
    getCell: (row) => row.team,
  },
  {
    id: "teamRights",
    label: "Team rights",
    getCell: (row) => {
      const type = types.find((type) => type.value === row.teamRights);
      return <TeamSelect types={types} type={type} onTypeChange={() => {}} />;
    },
  },
  {
    id: "alias",
    label: "Alias",
    getCell: (row) => row.alias,
  },
  {
    id: "Members",
    label: "Name",
    getCell: (row) => {
      const members = row.members;
      return <AddMemberMenu members={members} allMembers={allMembers} />;
    },
  },
  {
    id: "action",
    label: "+",
    getCell: () => {
      return (
        <Typography
          sx={{
            fontSize: "20px",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <MdOutlineMoreHoriz />
        </Typography>
      );
    },
  },
];

const data = [
  {
    name: "Jens V.",
    team: "Name",
    teamRights: "Finance",
    teamDsc: "Access to tickets and client data",
    alias: "@design",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
    members: [
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
    ],
  },
  {
    name: "Jens V.",
    team: "Name",
    teamRights: "Admin",
    alias: "@design",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
    members: [
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
    ],
  },
  {
    name: "Jens V.",
    team: "Name",
    teamRights: "Data Analyst",
    alias: "@design",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
    members: [],
  },
  {
    name: "Jens V.",
    team: "Name",
    teamRights: "Customer support",
    alias: "@design",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
    members: [
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
    ],
  },
  {
    name: "Jens V.",
    team: "Name",
    teamRights: "Finance",
    alias: "@design",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
    members: [
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
    ],
  },
  {
    name: "Jens V.",
    team: "Name",
    teamRights: "Finance",
    alias: "@design",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
    members: [
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
      {
        image: "/images/person.avif",
        isAI: true,
        name: "Jeans",
        online: true,
      },
    ],
  },
];

const Teams = () => {
  const [selectedType, setSelectedType] = useState(
    types.find((type) => type.value === "Finance")
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const handleChangePage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const [popupOpen, setPopupOpen] = useState(false);

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(headcells.map((cell) => cell.id));

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  // const filteredData = sortedData.filter((d) =>
  //     filterData(duration, d.createdAt)
  // );
  const filteredData = sortedData;
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    Math.min(currentPage * itemsPerPage, filteredData.length)
  );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Paper
      sx={{
        p: "24px",
        width: "100%",
      }}
    >
      {/* <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: "24px",
                    py: "10px",
                    mb: "20px",
                }}
            >
                <Typography variant="h3">Teams</Typography>
            </Box> */}

      <CustomTable
        headcells={columns.filter((cell) => heads.includes(cell.id))}
        rows={paginatedData}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        checkbox={true}
        selected={selected}
        setSelected={setSelected}
        fontSize="13px"
      />
      {/* {totalPages > 0 && (
                <Stack direction="row" justifyContent="end" mt={3}>
                    <IconButton
                        onClick={() => handleChangePage(currentPage - 1)}
                        disabled={currentPage === 1}
                        sx={{ mx: 0.5, p: 1, borderRadius: "50%", color: "#8A96A6" }}
                    >
                        <ChevronLeft />
                    </IconButton>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <IconButton
                            key={index}
                            onClick={() => handleChangePage(index + 1)}
                            sx={{
                                mx: 1,
                                p: 1,
                                borderRadius: "50%",
                                width: "36px",
                                height: "36px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    color: currentPage === index + 1 ? (theme) => theme.palette.color.white : (theme) => theme.palette.color.secondary,
                                }}
                            >
                                {index + 1}
                            </Typography>
                        </IconButton>
                    ))}
                    <IconButton
                        onClick={() => handleChangePage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        sx={{ mx: 0.5, p: 1, borderRadius: "50%", color: "#8A96A6" }}
                    >
                        <ChevronRight />
                    </IconButton>
                </Stack>
            )} */}
    </Paper>
  );
};

export default Teams;
