import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import PlusIcon from "../../../Icons/Plus";
import CustomerDetailsPopup from "../CustomerDetailsPopup";

const Notes = () => {
  return (
    <Box
      sx={{
        padding: "24px",
        background: (theme) => theme.palette.color.borderpure,
        borderRadius: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: "12px",
        }}
      >
        <Typography variant="h6">Notes</Typography>
        <PlusIcon />
      </Box>
      <Stack
        sx={{
          height: "150px",
          overflowY: "auto",
        }}
      >
        {Array.from({ length: 10 }).map((_, index) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Stack
              sx={{
                height: "100%",
                ...(index === 0 && {
                  paddingTop: "10px",
                }),
              }}
              alignItems="center"
            >
              {index !== 0 && (
                <Box
                  sx={{
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.color.lightgray}`,
                    height: "10px",
                  }}
                />
              )}
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  background: (theme) => theme.palette.color.secondary,
                  flexShrink: 0,
                }}
              ></Box>
              <Box
                sx={{
                  borderLeft: (theme) =>
                    `1px solid ${theme.palette.color.lightgray}`,
                  height: "100%",
                }}
              />
            </Stack>
            {/* <Box sx={{ position: "relative", height: "fit-content" }}>
              <Avatar
                sx={{
                  width: "28px",
                  height: "28px",
                }}
                src="/images/person.avif"
              ></Avatar>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0",

                  right: 0,
                  height: "8px",
                  width: "8px",
                  borderRadius: "50%",
                  background: (theme) => theme.palette.color.green,
                }}
              />
            </Box> */}
            <CustomerDetailsPopup />
            <Stack spacing={1} sx={{ pb: "24px" }}>
              <Typography variant="body1" sx={{ lineHeight: "20px" }}>
                Client has successfully implemented sub-collections in their
                help center.
              </Typography>

              <Typography variant="body3">June 13, 2024</Typography>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Notes;
