import {
  alpha,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import FormInput from "../../components/ui/FormInput";
import MailIcon from "../../components/Icons/Mail";
import PasswordIcon from "../../components/Icons/Password";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../slices/api/loginSlice";
import { setToken } from "../../slices/authSlice";
import { useDispatch } from "react-redux";

const Login = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const [email, setEmail] = React.useState("admin@admin.com");
  const [password, setPassword] = React.useState("adminTenant1");
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg,
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: 2,
        }}
      >
        <img
          src="/svg/loginIcon.svg"
          alt="UNI"
          style={{ width: "78px", height: "29px" }}
        />
        <Box
          sx={{
            gap: "10px",
            alignItems: "center",
            display: isSmall ? "none" : "flex",
          }}
        >
          <Button
            size="small"
            sx={{
              height: "36px",
              borderRadius: "32px",
              bgcolor: (theme) => theme.palette.color.bg5,
              color: (theme) => theme.palette.color.secondary,
              border: (theme) =>
                `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
              ":hover": { background: (theme) => theme.palette.color.bg5 },
            }}
          >
            Don't have an account?
          </Button>
          <Button
            size="small"
            sx={{
              height: "36px",
              borderRadius: "32px",
              bgcolor: (theme) => theme.palette.color.active,
              color: (theme) => theme.palette.color.primary,
              border: (theme) =>
                `1px solid ${alpha(theme.palette.color.active, 0.15)}`,
              ":hover": { background: (theme) => theme.palette.color.active },
            }}
          >
            Contact sales
          </Button>
        </Box>
      </Box>
      <Stack
        spacing={3}
        alignItems="center"
        sx={{
          background: "#0b0d0f",
          padding: "24px",
          width: "520px",
          borderRadius: "12px",
          border: `1px solid rgba(21, 25, 31, 0.65)`,
          zIndex: 2,
        }}
      >
        <Typography variant="h1" sx={{ fontSize: "24px" }}>
          Welcome back
        </Typography>
        <Stack spacing={2} alignItems="center" width="100%">
          <Stack direction="row" gap={1} width="100%">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    gap: "8px",
                    width: "100%",
                    height: "44px",
                    background: (theme) => theme.palette.color.lightgray,
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px",
                  }}
                >
                  <Box
                    sx={{
                      background: "white",
                      height: "22px",
                      width: "22px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/images/google.avif"
                      alt="google"
                      style={{ maxHeight: "80%", maxWidth: "80%" }}
                    />
                  </Box>
                  <Typography
                    variant="heading_500"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    Google
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    gap: "8px",
                    width: "100%",
                    height: "44px",
                    background: (theme) => theme.palette.color.lightgray,
                    borderRadius: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px",
                  }}
                >
                  <Box
                    sx={{
                      background: "white",
                      height: "22px",
                      width: "22px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/svg/apple.svg"
                      alt="google"
                      style={{ maxHeight: "70%", maxWidth: "70%" }}
                    />
                  </Box>
                  <Typography
                    variant="heading_500"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    Apple
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "100%", py: 2 }}
            spacing={2}
          >
            <Box
              sx={{
                flex: 1,
                height: "1px",
                backgroundColor: (theme) =>
                  alpha(theme.palette.color.active, 0.15),
              }}
            />
            <Typography variant="caption_500">Or continue with</Typography>
            <Box
              sx={{
                flex: 1,
                height: "1px",
                backgroundColor: (theme) =>
                  alpha(theme.palette.color.active, 0.15),
              }}
            />
          </Stack>
          <FormInput
            label="Email Address : "
            placeholder="example@site.com"
            type="email"
            startAdornment={<MailIcon width="36" height="36" />}
            sx={{ px: "12px", borderRadius: "16px" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormInput
            label="Password :"
            placeholder="Enter your password"
            type="password"
            startAdornment={<PasswordIcon width="36" height="36" />}
            sx={{
              px: "12px",
              borderRadius: "16px",
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Stack justifyContent="end" direction="row" sx={{ width: "100%" }}>
            <Typography
              onClick={() => {
                navigate("/forget-password");
              }}
              sx={{
                color: (theme) => theme.palette.color.active,
                cursor: "pointer",
                whiteSpace: "nowrap",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              Forgot password ?
            </Typography>
          </Stack>
          <Button
            variant="contained"
            sx={{ borderRadius: "32px", height: "50px", fontWeight: "15px" }}
            fullWidth
            onClick={async () => {
              const res = await login({
                email,
                password,
                api_id:
                  "c74444bdd6f23e967440fff51287b140c25b147d04d1b84d7a224ffd1247",
              }).unwrap();
              dispatch(setToken(res.token));
              navigate("/");
            }}
          >
            Login
          </Button>
        </Stack>
        <Stack>
          <Typography variant="body3" sx={{ textAlign: "center" }}>
            Don't have an account?
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mt: 1,
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/register");
            }}
          >
            Sign Up
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{
          position: "absolute",
          right: "-270px",
          bottom: "-450px",
          opacity: "0.1",
          left: "544px",
          zIndex: 1,
        }}
      >
        <img src="/images/background.png" alt="background" height="1166px" />
      </Box>
    </Box>
  );
};

export default Login;
