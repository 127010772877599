import React, { useState } from 'react';
import { Grid, Box, Typography, Switch, styled, Stack } from '@mui/material';
import { MdInfoOutline } from 'react-icons/md';
import { TbSettings2 } from 'react-icons/tb';

const TradingPlatform = () => {
    const [integrations, setIntegrations] = useState([
        { name: 'Vuetra', description: 'This is a description of the integration', logo: "/images/logos/trading-platforms/Vuetra.png", enabled: true, color: 'green' },
        { name: 'MetaTrader', description: 'This is a description of the integration', logo: "/images/logos/trading-platforms/MetaTraders.png", enabled: false, color: 'secondary' },
        { name: 'DxTrade', description: 'This is a description of the integration', logo: "/images/logos/trading-platforms/DXTrade.png", enabled: false, color: 'secondary' },
        { name: 'TradeLocker', description: 'This is a description of the integration', logo: "/images/logos/trading-platforms/TradeLocker.png", enabled: false, color: 'secondary' },
        { name: 'Rithmic', description: 'This is a description of the integration', logo: "/images/logos/trading-platforms/Rithmic.png", enabled: true, color: 'green' },
        { name: 'Match-Trader', description: 'This is a description of the integration', logo: "/images/logos/trading-platforms/MatchTrader.png", enabled: true, color: 'red' },
    ]);

    const handleToggle = (index) => {
        const newIntegrations = [...integrations];
        newIntegrations[index].enabled = !newIntegrations[index].enabled;
        setIntegrations(newIntegrations);
    };

    return (
        <Box sx={{ p: 3, minHeight: '100vh' }}>
            <Typography variant="h2" sx={{ mb: 2 }}>Trading platforms</Typography>
            <Typography variant="caption">Connect your store to third-party trading platforms</Typography>
            <Grid container spacing={3} mt={2}>
                {integrations.map((integration, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <IntegrationCard
                            name={integration.name}
                            description={integration.description}
                            logo={integration.logo}
                            enabled={integration.enabled}
                            onToggle={() => handleToggle(index)}
                            color={integration.color}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default TradingPlatform;


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2EE0A1' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const IntegrationCard = ({ name, description, logo, enabled, onToggle, color }) => {
    return (
        <Box
            sx={{
                bgcolor: (theme) => theme.palette.color.bg5,
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.strockborder,
                color: 'white',
                borderRadius: "16px",
                height: 150,
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <img src={logo} alt={name} style={{ height: '30px', width: '80px' }} />
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={1.2}>
                    <MdInfoOutline size={18} color={"#8A96A6"} />
                    <TbSettings2 size={18} color={"#8A96A6"} />
                    <Box sx={{ width: "8px", height: "8px", borderRadius: "50%", background: (theme) => theme.palette.color[color], my: "14px", mx: "12px", }}></Box>
                </Stack>
            </Stack>
            <Typography variant="body2" mt={2}>
                {description}
            </Typography>
            <IOSSwitch sx={{ mt: 2 }} checked={enabled} onChange={onToggle} />
        </Box>
    );
};  