import {
  Avatar,
  Box,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import InfoIcon2 from "../../Icons/Info2";
import { FiArrowUpRight } from "react-icons/fi";
import ReactApexChart from "react-apexcharts";
import RoundFormSelect from "../../ui/RoundFormSelect";
import CustomTable from "../../Firm/Orders/Table";

const AnalysisAndReporting = () => {
  const infoData = [
    {
      title: "Total Sales",
      tooltip: "Total Sales",
      value: "$1,234,567",
      percent: "3.5%",
    },
    {
      title: "Average Order Value",
      tooltip: "Average Order Value",
      value: "$1,240",
      percent: "1.5%",
    },
  ];

  const infoData2 = [
    {
      title: "Forecasted Sales Growth",
      tooltip: "Forecasted Sales Growth",
      value: "+ 24%",
      color: (theme) => theme.palette.color.green,
    },
    {
      title: "Sales Forecast Accuracy",
      tooltip: "Sales Forecast Accuracy",
      value: "92%",
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3} mt={0.5}>
        {infoData.map((item, index) => (
          <Grid item xs={6} sm={6} md={6} key={index}>
            <InfoBox
              item={item}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <SalesOverTimeChart />
      <ProcessingTimeChart />
      <Typography variant="h4" my={2}>
        Trend Analysis and Predictive Models to Forecast Future Sales
        Performance
      </Typography>
      <Grid container spacing={3} mb={3}>
        {infoData2.map((item, index) => (
          <Grid item xs={6} sm={6} md={6} key={index}>
            <InfoBox
              item={item}
              sx={{
                background: (theme) => theme.palette.color.bg2,
                height: "136px",
              }}
            />
          </Grid>
        ))}
      </Grid>
      <TradeSizeFrequency />
    </Box>
  );
};

export default AnalysisAndReporting;

const InfoBox = ({ item, icon, sx }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "24px",
        width: "100%",
        height: "164px",
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",

          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="caption">{item.title}</Typography>
          <Tooltip title={item.tooltip} placement="top">
            {icon ? icon : <InfoIcon2 fill="none" />}
          </Tooltip>
        </Stack>
      </Box>
      <Typography
        variant="h1"
        fontSize={isSmall ? "20px" : "28px"}
        color={item.color ? item.color : (theme) => theme.palette.color.primary}
        mt={3}
      >
        {item.value}
      </Typography>
      {item.percent && (
        <Stack
          direction="row"
          spacing={1}
          mt={1}
          sx={{
            alignItems: "center",
            color: (theme) => theme.palette.color.green,
          }}
        >
          <FiArrowUpRight size={16} />
          <Typography variant="caption">{item.percent}</Typography>
        </Stack>
      )}
    </Box>
  );
};

const SalesOverTimeChart = () => {
  const series = [
    {
      name: "Gross Revenue",
      type: "area",
      data: [140, 130, 180, 120, 100, 160, 120, 100, 120, 150, 160, 120],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      background: "transparent",
      toolbar: {
        show: false,
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
    colors: ["#5F55EE", "#2FE0A2"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [2, 2],
    },
    fill: {
      type: "gradient",
      opacity: [0.15, 1],
      gradient: {
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: [0.38, 1],
        opacityTo: [0.05, 1],
        shade: "dark",
        type: "vertical",
        stops: [0, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#8A96A6",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 200,
      tickAmount: 8,
      labels: {
        formatter: (val) => `${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
      title: {
        text: "Total Sales",
        style: {
          color: "#8A96A6",
        },
      },
    },
    tooltip: {
      enabled: false,
      shared: true,
      intersect: false,
      y: {
        formatter: (val) => `${val}`,
      },
    },
    theme: {
      mode: "dark",
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    markers: {
      size: 0,
    },
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.strockborder,
        p: "20px",
        borderRadius: "12px",
        mt: 2,
      }}
    >
      <Typography variant="h5">Sales Over Time</Typography>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </Box>
  );
};

const ProcessingTimeChart = () => {
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        p: 3,
        my: 3,
      }}
    >
      <Stack
        direction="row"
        pl={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5">Processing Time for Payouts</Typography>
        <Stack direction="row" gap={2}>
          <RoundFormSelect
            options={[{ value: "accountType", label: "Account Type" }]}
            defaultValue="accountType"
          />
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box sx={{ width: "40%" }}>
          <PayoutsPieChart />
        </Box>
        <Box sx={{ width: "70%" }}>
          <SuspiciousActivitiesTable />
        </Box>
      </Stack>
    </Box>
  );
};

const PayoutsPieChart = () => {
  const options = {
    labels: ["Account Type A", "Account Type B", "Account Type C"],
    colors: ["#5F55EE", "#5EEDF8", "#2FE0A2"],
    legend: {
      show: false,
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.labels[opts.seriesIndex] + ": 30%";
      },
      style: {
        fontSize: "15px",
        fontFamily: "Arial, sans-serif",
        fontWeight: "500",
        colors: ["#8A96A6"],
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.6,
        dataLabels: {
          offset: 98,
          minAngleToShowLabel: 10,
        },
      },
    },
  };

  const series = [30, 30, 30];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        height={350}
      />
    </div>
  );
};

const SuspiciousActivitiesTable = () => {
  const data = [
    {
      accountType: "Account type A",
      age: "20",
      country: "Switzerland",
      countryFlag: "/images/country-icons/czk.svg",
      payoutAmount: "489.90 US$",
    },
    {
      accountType: "Account type B",
      age: "20",
      country: "Switzerland",
      countryFlag: "/images/country-icons/chf.svg",
      payoutAmount: "489.90 US$",
    },
    {
      accountType: "Account type C",
      age: "20",
      country: "USA",
      countryFlag: "/images/country-icons/usd.svg",
      payoutAmount: "489.90 US$",
    },
    {
      accountType: "Account type C",
      age: "20",
      country: "Austria",
      countryFlag: "/images/country-icons/aus.svg",
      payoutAmount: "489.90 US$",
    },
    {
      accountType: "Account type C",
      age: "20",
      country: "Switzerland",
      countryFlag: "/images/country-icons/czk.svg",
      payoutAmount: "489.90 US$",
    },
    {
      accountType: "Account type C",
      age: "20",
      country: "Spain",
      countryFlag: "/images/country-icons/chf.svg",
      payoutAmount: "489.90 US$",
    },
  ];

  const headcells = [
    {
      id: "accountType",
      label: "Account Type",
      getCell: (row) => row.accountType,
    },
    {
      id: "country",
      label: "Country",
      getCell: (row) => (
        <Stack direction="row" alignItems="center">
          <Avatar src={row.countryFlag} sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography>{row.country}</Typography>
        </Stack>
      ),
    },
    {
      id: "age",
      label: "Age",
      getCell: (row) => row.age,
    },
    {
      id: "payoutAmount",
      label: "Payout Amount",
      getCell: (row) => row.payoutAmount,
    },
  ];

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.strockborder,
        p: "10px",
        borderRadius: "16px",
        mt: 3,
      }}
    >
      <Box sx={{ overflowX: "auto", width: "100%" }}>
        <CustomTable headcells={headcells} rows={data} minWidth={200} />
      </Box>
    </Box>
  );
};

const TradeSizeFrequency = () => {
  const ChartContainer = styled(Box)({
    width: "100%",
    "& .apexcharts-toolbar": {
      display: "none !important",
    },
  });

  const series = [
    {
      name: "Actual Sales",
      type: "column",
      data: [4000, 2500, 5900, 2500, 3500, 4000],
    },
    {
      name: "Forecasted Sales",
      type: "line",
      data: [5800, 4200, 5700, 4100, 5500, 4900],
    },
  ];

  const options = {
    chart: {
      type: "line",
      toolbar: { show: false },
      background: "transparent",
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      labels: { style: { colors: "#8A96A6" } },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      min: 0,
      max: 8000,
      labels: {
        formatter: (val) => `$${(val / 1000).toFixed(0)}k`,
        style: { colors: "#8A96A6" },
      },
      title: {
        text: "Sales Volume",
        style: { color: "#8A96A6" },
      },
    },
    stroke: {
      width: [1, 3],
      curve: "smooth",
    },
    colors: ["#5F55EE", "#5EEDF8"],
    plotOptions: {
      bar: {
        columnWidth: "20%",
        borderRadius: 4,
      },
    },
    dataLabels: { enabled: false },
    grid: { show: false },
    legend: { show: false },
    tooltip: { enabled: false },
    fill: {
      type: "gradient",
      opacity: [0.15, 1],
      gradient: {
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: [0.38, 1],
        opacityTo: [0.05, 1],
        shade: "light",
        type: "vertical",
        stops: [0, 100],
      },
    },
    markers: {
      size: 2,
      colors: undefined,
      strokeWidth: 0,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "square",
      radius: 5,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.strockborder,
        p: "10px",
        borderRadius: "16px",
        mb: 3,
      }}
    >
      <Stack
        direction="row"
        p={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Sales Trends and Forecast</Typography>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: "#5F55EE",
                borderRadius: "50%",
                mr: 1,
              }}
            />
            <Typography variant="body2">Actual Sales</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: "#5EEDF8",
                borderRadius: "50%",
                mr: 1,
              }}
            />
            <Typography variant="body2">Forecasted Sales</Typography>
          </Box>
        </Stack>
      </Stack>

      <ChartContainer>
        <ReactApexChart
          options={options}
          series={series.map(({ name, data, type }, index) => ({
            name,
            data,
            type,
            fillColor: {
              colors: ["transparent"],
              borderColor: options.colors[index],
              backgroundBarColors: ["transparent"],
              backgroundBarOpacity: 1,
            },
          }))}
          type="line"
          height={350}
        />
      </ChartContainer>
    </Box>
  );
};
