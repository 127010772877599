import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Typography,
  alpha,
} from "@mui/material";

import React from "react";
import AllIcon from "../../Icons/All";
import Close from "@mui/icons-material/Close";
import { navItems, navItems2 } from "../../../static/navItems";
import { isPathMatching } from "../../../utils/getNavData";
import ChevronRightIcon from "../../Icons/ChevronRight";
import NavItem from "../NavItem";
import { MdChatBubble } from "react-icons/md";
import ProfileSection from "../Sidebar/ProfileSection";
import { useLocation } from "react-router-dom";

const Menu = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { pathname } = useLocation();
  return (
    <>
      <Box
        sx={{
          display: { xs: "block", lg: "none" },
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        <AllIcon />
      </Box>
      <Drawer open={open} onClose={handleClose} anchor="right" sx={{}}>
        <Paper
          sx={{
            width: "246px",
            height: "100vh",
            overflowY: "auto",
            background: (theme) => theme.palette.color.bg2,
            borderRadius: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "16px 12px",
            }}
          >
            <img src="/logo.avif" alt="logo" width="20px" height="20px" />
            <IconButton
              onClick={handleClose}
              sx={{
                background: (theme) => theme.palette.color.darkgray,
                padding: "5px",
                ":hover": {
                  background: (theme) => theme.palette.color.darkgray,
                },
              }}
            >
              <Close
                sx={{
                  fontSize: "16px",
                  color: (theme) => theme.palette.color.secondary,
                }}
              />
            </IconButton>
          </Box>
          <Stack
            justifyContent="space-between"
            sx={{ height: "100%" }}
            spacing={3}
          >
            <Stack>
              {navItems.map((item) => (
                // <Box
                //   sx={{
                //     display: "flex",
                //     alignItems: "center",
                //     justifyContent: "space-between",
                //     position: "relative",
                //     p: "3px",
                //     pr: "16px",
                //     paddingLeft: "16px",
                //     borderLeft: `2px solid transparent`,
                //     ...(item.path === pathname && {
                //       borderLeft: (theme) =>
                //         `2px solid ${theme.palette.color.active}`,
                //       background: (theme) => theme.palette.color.lightgray,
                //     }),
                //     ":hover": {
                //       borderLeft: (theme) =>
                //         `2px solid ${theme.palette.color.active}`,
                //       background: (theme) => theme.palette.color.lightgray,
                //       ".border-box": {
                //         opacity: 0,
                //       },
                //       pr: "12px",
                //     },
                //     transition: "all 0.5s ",
                //     cursor: "pointer",
                //     my: "1px",
                //     height: "44px",
                //   }}
                // >
                //   <Box
                //     sx={{
                //       display: "flex",
                //       alignItems: "center",
                //     }}
                //   >
                //     <Box
                //       sx={{
                //         color: (theme) =>
                //           theme.palette.color[
                //             isPathMatching(item.path, pathname)
                //               ? "primary"
                //               : "secondary"
                //           ],
                //         mr: 1,
                //         ml: 0,
                //       }}
                //     >
                //       <item.icon size={16} />
                //     </Box>
                //     <a
                //       style={{ textDecoration: "none" }}
                //       href={item.to ?? item.path}
                //     >
                //       <Typography
                //         variant="caption"
                //         sx={{
                //           ...(isPathMatching(item.path, pathname) && {
                //             color: (theme) => theme.palette.color.primary,
                //             lineHeight: "24px",
                //           }),
                //         }}
                //       >
                //         {item.title}
                //       </Typography>
                //     </a>
                //   </Box>
                //   {item.children && <ChevronRightIcon />}
                // </Box>
                <NavItem
                  key={item.title}
                  item={item}
                  active={isPathMatching(item.path, pathname)}
                  children={item.children}
                />
              ))}
            </Stack>
            <Stack>
              {navItems2.map((item) => (
                // <Box
                //   sx={{
                //     display: "flex",
                //     alignItems: "center",
                //     justifyContent: "space-between",
                //     position: "relative",
                //     p: "3px",
                //     paddingLeft: "16px",
                //     borderLeft: `2px solid transparent`,
                //     ...(item.path === pathname && {
                //       borderLeft: (theme) =>
                //         `2px solid ${theme.palette.color.active}`,
                //       background: (theme) => theme.palette.color.lightgray,
                //     }),
                //     ":hover": {
                //       borderLeft: (theme) =>
                //         `2px solid ${theme.palette.color.active}`,
                //       background: (theme) => theme.palette.color.lightgray,
                //       ".border-box": {
                //         opacity: 0,
                //       },
                //       pr: "12px",
                //     },
                //     transition: "all 0.5s ",
                //     cursor: "pointer",
                //     my: "1px",
                //     height: "44px",
                //   }}
                // >
                //   <Box
                //     sx={{
                //       display: "flex",
                //       alignItems: "center",
                //     }}
                //   >
                //     <Box
                //       sx={{
                //         color: (theme) =>
                //           theme.palette.color[
                //             isPathMatching(item.path, pathname)
                //               ? "primary"
                //               : "secondary"
                //           ],
                //         mr: 1,
                //         ml: 0,
                //       }}
                //     >
                //       <item.icon size={16} />
                //     </Box>
                //     <a
                //       style={{ textDecoration: "none" }}
                //       href={item.to ?? item.path}
                //     >
                //       <Typography
                //         variant="caption"
                //         sx={{
                //           ...(isPathMatching(item.path, pathname) && {
                //             color: (theme) => theme.palette.color.primary,
                //             lineHeight: "24px",
                //           }),
                //         }}
                //       >
                //         {item.title}
                //       </Typography>
                //     </a>
                //   </Box>
                // </Box>
                <NavItem
                  key={item.title}
                  item={item}
                  active={isPathMatching(item.path, pathname)}
                />
              ))}
              <NavItem
                item={{
                  title: "AI Assistant",
                  icon: MdChatBubble,
                }}
              />
              <Divider />
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  p: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: (theme) => theme.palette.color.active,
                    borderRadius: "50%",
                  }}
                >
                  <img src="logo.avif" alt="logo" width="13px" />
                </Box>
                <Typography variant="caption">Jens</Typography>
              </Box> */}
              <ProfileSection name="Jens" />
            </Stack>
          </Stack>
        </Paper>
      </Drawer>
    </>
  );
};

export default Menu;
