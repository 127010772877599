import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://apipfsdev.unbelievable.digital/api/",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const generalApi = createApi({
  reducerPath: "generalApi",
  baseQuery,
  endpoints: (builder) => ({
    refreshToken: builder.mutation({
      query: (data) => ({
        url: "refresh",
        method: "POST",
        body: data,
      }),
    }),
    getCountries: builder.query({
      query: () => `get-countries`,
    }),
    getCurrencies: builder.query({
      query: () => `get-currencies`,
    }),
  }),
});

export const {
  useRefreshTokenMutation,
  useGetCountriesQuery,
  useGetCurrenciesQuery,
} = generalApi;
