import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import {
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toggleOpen } from "../../../slices/openModalSlice";
import FormInput from "../../ui/FormInput";
import FormRadio from "../../ui/Radio";
import FormSwitch from "../../ui/FormSwitch";
import FormSelect from "../../ui/FormSelect";
import {
  useAddArticlesMutation,
  useUpdateArticleMutation,
} from "../../../slices/api/articleSlice";
import { useGetCollectionsQuery } from "../../../slices/api/firmSlice";

const labelStyle = {
  fontSize: "13px",
  fontWeight: "600",
};

const inputStyle = {
  fontSize: "13px",
  borderRadius: "14px",
};

const AddArticle = ({ refetch, article }) => {
  const [formValues, setFormValues] = useState({
    name: article?.name || "",
    content: article?.content || "",
    status: article?.published || "published",
    scheduled_at: article?.scheduled_at || "2024-07-04",
    collection_id: article?.collection_id || 0,
    published_at: article?.published_at || "2024-07-04",
    visibility: article?.visibility || "public",
    state: article?.state || 1,
    excluded_from_suggestions: article?.excluded_from_suggestions || 0,
  });
  const { open } = useSelector((state) => state.openModal);
  const { data } = useGetCollectionsQuery();
  const [addArticle] = useAddArticlesMutation();
  const [editArticle] = useUpdateArticleMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    setFormValues({
      name: article?.name || "",
      content: article?.content || "",
      status: article?.published || "published",
      scheduled_at: article?.scheduled_at || "2024-07-04",
      collection_id: article?.collection_id || 0,
      published_at: article?.published_at || "2024-07-04",
      visibility: article?.visibility || "public",

      state: article?.state || 1,
      excluded_from_suggestions: article?.excluded_from_suggestions || 0,
    });
  }, [article]);

  const handleSubmit = async () => {
    if (article)
      await editArticle({
        ...formValues,
        id: article.id,
      });
    else await addArticle(formValues);
    if (refetch) refetch();
    dispatch(toggleOpen());
  };

  return (
    <React.Fragment>
      <Drawer
        sx={
          {
            // borderRadius: "14px 0 14px 0",
            // overflow: "hidden",
          }
        }
        open={open}
        onClose={() => dispatch(toggleOpen())}
        anchor="right"
        // position="relative"
      >
        <Box
          sx={{
            position: "fixed",
            top: "18px",
            left: "900px",
          }}
        >
          <IconButton
            sx={{
              width: "56px",
              height: "56px",
              background: (theme) => theme.palette.color.borderpure,
              transition: "all 0.5s",
              "&:hover": {
                rotate: "180deg",
              },
            }}
            onClick={() => dispatch(toggleOpen())}
          >
            <CloseIcon
              sx={{
                color: (theme) => theme.palette.color.primary,
              }}
            />
          </IconButton>
        </Box>

        <Paper
          sx={{
            width: "558px",
            height: "100vh",
            overflow: "hidden",
            background: (theme) => theme.palette.color.bg5,
          }}
        >
          <Box
            sx={{
              height: "calc(100vh - 70px)",
              overflowY: "auto",
              padding: "24px",
            }}
          >
            <Stack
              spacing={1.5}
              sx={{
                position: "fixed",
                top: "0",
                background: (theme) => theme.palette.color.bg2,
                py: "24px",
                zIndex: "100",
                width: "100%",
              }}
            >
              <Typography variant="h2" sx={{ fontWeight: "600" }}>
                Add Article
              </Typography>

              <Typography variant="caption">
                Create new article for your knowledge base
              </Typography>
            </Stack>

            <Stack spacing={4} sx={{ pb: "48px", pt: "100px" }}>
              <FormInput
                label="Article Title"
                placeholder="Enter Article Title"
                labelStyle={labelStyle}
                sx={inputStyle}
                required
                value={formValues.name}
                onChange={(e) =>
                  setFormValues({ ...formValues, name: e.target.value })
                }
              />
              <FormInput
                label="Article Content"
                placeholder="Enter the article content"
                labelStyle={labelStyle}
                sx={inputStyle}
                required
                multiline
                minRows={4}
                value={formValues.content}
                onChange={(e) =>
                  setFormValues({ ...formValues, content: e.target.value })
                }
              />
              {/* <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Typography variant="subtitle1">
                    Announcement Audience
                  </Typography>
                  <Typography variant="body3">
                    Choose the audience for this announcement. Select whether it
                    should be sent to the Client Dashboard, kept Private, or
                    made available to Everyone.
                  </Typography>
                </Stack>
                <FormRadio
                  options={[
                    { value: "dashboard", label: "Client Dashboard" },
                    { value: "team", label: "Team" },
                    { value: "everyone", label: "Everyone" },
                  ]}
                />
              </Stack> */}

              <FormSelect
                label="Add Article to Collection"
                options={[...(data?.data?.data || [])].map((c) => ({
                  value: c.id,
                  label: c.name,
                }))}
                labelStyle={labelStyle}
                sx={inputStyle}
                defaultValue="vuetra"
                helperTexts1={[
                  "You can add your article to a collection. If an article isn't added to any collection, it won't be displayed on your Help Center website. However, you can still share a direct link with your customers.",
                ]}
                value={formValues.collection_id}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    collection_id: e.target.value,
                  })
                }
              />
              <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Typography variant="subtitle1">Visibility</Typography>
                  <Typography variant="body3">
                    Determine who can view this article: everyone or only
                    company-level access.
                  </Typography>
                </Stack>
                <FormRadio
                  value={formValues.visibility}
                  onChange={(v) =>
                    setFormValues({ ...formValues, visibility: v })
                  }
                  options={[
                    { value: "private", label: "Private" },
                    { value: "public", label: "Everyone" },
                  ]}
                />
              </Stack>
              {/* <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Typography variant="subtitle1">
                    Exclude from Article Suggestions
                  </Typography>
                  <Typography variant="body3">
                    Exclude from Article suggestions on the Messenger Home
                    Screen and Related Articles. If this option is checked, we
                    won't suggest this article to your visitors, leads, and
                    users.
                  </Typography>
                </Stack>
                <FormRadio
                  value={formValues.excluded_from_suggestions === 1}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      excluded_from_suggestions:
                        formValues.excluded_from_suggestions === 0 ? 1 : 0,
                    })
                  }
                  options={[
                    { value: "notifications", label: "Within notifications" },
                    { value: "popoup", label: "Popup modal" },
                  ]}
                />
              </Stack> */}

              <Divider />

              <FormSwitch
                value={formValues.excluded_from_suggestions === 1}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    excluded_from_suggestions:
                      formValues.excluded_from_suggestions === 0 ? 1 : 0,
                  })
                }
                label="Exclude from Article Suggestions"
                flex
                helperTexts={[
                  "Exclude from Article suggestions on the Messenger Home Screen and Related Articles. If this option is checked, we won't suggest this article to your visitors, leads, andusers.",
                ]}
              />
            </Stack>
          </Box>
          <Stack
            spacing={1}
            sx={{
              padding: "6px 32px",
              background: (theme) => theme.palette.color.bg2,
              position: "sticky",
              height: "70px",
              bottom: 0,
            }}
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
          >
            <Button
              sx={{
                background: "transparent",
                color: (theme) => theme.palette.color.primary,
                borderRadius: "9px",
                fontSize: "13px",
                fontWeight: "500",
                ":hover": {
                  background: "transparent",
                },
              }}
              onClick={() => {
                dispatch(toggleOpen());
              }}
            >
              Cancel
            </Button>

            <Button
              sx={{
                background: (theme) => theme.palette.color.border,
                color: (theme) => theme.palette.color.primary,
                borderRadius: "9px",
                fontSize: "13px",
                fontWeight: "500",

                boxShadow: "none",
                ":hover": {
                  background: (theme) => theme.palette.color.border,
                },
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              Publish
            </Button>
          </Stack>
        </Paper>
      </Drawer>
    </React.Fragment>
  );
};

export default AddArticle;
