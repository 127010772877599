import {
  Avatar,
  Box,
  Grid,
  InputBase,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import StepperCard from "./StepperCard";
import StepperHeading from "./StepperHeading";
import { useEffect, useState } from "react";
import PlusIcon from "../../Icons/Plus";

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: "100%",
  border: "none",
  background: "none",
  outline: "none",
  fontSize: "13px",
  color: theme.palette.color.secondary,
}));

const defaultAccountSizes = [500000, 200000, 100000, 50000, 25000, 10000, 5000];

export default function AccountSizes({
  accountSizes,
  setAccountSizes,
  currency,
}) {
  const [sizes, setSizes] = useState(defaultAccountSizes);
  const theme = useTheme();

  useEffect(() => {
    setSizes(defaultAccountSizes);
  }, [currency]);

  const accountSize = accountSizes.find((a) => a.currency === currency.value);

  return (
    <>
      <StepperHeading
        heading={"Set Account Sizes"}
        subHeading={"Select one of our suggested sizes or create your own"}
      />

      <Grid container spacing={1.5} sx={{ px: "20px", width: "100%" }} mt={1}>
        {sizes.map((size, index) => {
          const isActive = accountSize?.sizes.find((s) => s.value === size);
          return (
            <Grid
              item
              xs={3}
              key={index}
              onClick={() => {
                if (accountSize) {
                  if (isActive) {
                    setAccountSizes(
                      accountSizes.map((a) => {
                        if (a.currency === currency.value) {
                          return {
                            ...a,
                            sizes: a.sizes.filter((s) => s.value !== size),
                          };
                        }
                        return a;
                      })
                    );
                  } else {
                    setAccountSizes(
                      accountSizes.map((a) => {
                        if (a.currency === currency.value) {
                          return {
                            ...a,
                            sizes: [...a.sizes, { value: size, price: 0 }],
                          };
                        }
                        return a;
                      })
                    );
                  }
                  //  setAccountSizes({
                  //     ...accountSizes,
                  //     {
                  //         ...accountSize,
                  //         currency: currency.value,

                  //     }
                  //  })
                } else {
                  setAccountSizes([
                    ...accountSizes,
                    {
                      currency: currency?.value || "USD",
                      code: currency?.code || "$",
                      sizes: [{ value: size, price: 0 }],
                    },
                  ]);
                }
              }}
            >
              <StepperCard
                sx={{
                  cursor: "pointer",
                  border: (theme) =>
                    isActive
                      ? `1px solid ${theme.palette.color.active}`
                      : "1px solid transparent",
                }}
                // isActive={accountSize?.sizes.find(
                //   (s) => s.value === size
                // )}
              >
                {/* <Typography variant="subtitle1_500">{size}</Typography> */}
                <StyledInput
                  autoFocus={sizes.length - 1 === index}
                  value={size}
                  onChange={(e) => {
                    setSizes(
                      sizes.map((s, i) =>
                        i === index ? parseInt(e.target.value) : s
                      )
                    );
                  }}
                  type="number"
                  startAdornment={
                    <Typography variant="subtitle1_500" sx={{ mx: "4px" }}>
                      {currency.code || "$"}
                    </Typography>
                  }
                />
              </StepperCard>
            </Grid>
          );
        })}
        <Grid item xs={3}>
          <StepperCard
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              setSizes([...sizes, 0]);
            }}
          >
            <PlusIcon
              width="36"
              height="36"
              stroke={theme.palette.color.primary}
            />
          </StepperCard>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              gap: "2px",
              alignItems: "center",
            }}
          >
            <Avatar
              alt={"country"}
              src={`/images/country-icons/${currency.value.toLowerCase()}.svg`}
              sx={{
                width: 14,
                height: 14,
              }}
            />
            <Typography
              variant="body3"
              align="center"
              sx={{
                display: "block",
                fontSize: 11.5,
                fontWeight: 400,
                color: (theme) => theme.palette.color.primary,
              }}
            >
              {currency.value}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
