import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

import ChevronRightIcon from "../../../components/Icons/ChevronRight";
import HomeIcon from "../../../components/Icons/Home";
import CustomTable from "../../../components/Firm/Orders/Table";

const statuses = [
  { label: "Complete", value: "completed", color: "green" },
  { label: "Failed", value: "failed", color: "red" },
  { label: "Pending", value: "pending", color: "secondary" },
];

const headcells = [
  {
    id: "status",
    label: "",
    getCell: (row) => {
      const status = statuses.find((statute) => statute.value === row.status);
      return (
        <Box
          sx={{
            width: "9px",
            height: "9px",
            borderRadius: "50%",
            background: (theme) => theme.palette.color[status.color],
          }}
        ></Box>
      );
    },
  },
  {
    id: "stage",
    label: "Stage",
    getCell: (row) => row.stage,
  },
  {
    id: "challenge",
    label: "Challenge",
    getCell: (row) => row.challenge,
  },
  {
    id: "accId",
    label: "Acc.ID",
    getCell: (row) => row.accId,
  },
  {
    id: "platform",
    label: "Platform",
    getCell: (row) => row.platform,
  },
  {
    id: "abookacc",
    label: "A-book Acc.",
    getCell: (row) => row.abookacc,
  },
  {
    id: "toplatform",
    label: "To Platform",
    getCell: (row) => row.toplatform,
  },
  {
    id: "balance",
    label: "Balance",
    getCell: (row) => row.balance,
  },
  {
    id: "createdAt",
    label: "Created Date",
    getCell: (row) => row.createdAt,
  },
];

const data = [
  {
    status: "completed",
    stage: "Stage 1",
    challenge: "Challenge A",
    accId: "ACC12345",
    platform: "Platform X",
    abookacc: "A-123",
    toplatform: "Platform Y",
    balance: "$1,500",
    createdAt: "2023-01-15",
  },
  {
    status: "failed",
    stage: "Stage 2",
    challenge: "Challenge B",
    accId: "ACC12346",
    platform: "Platform Y",
    abookacc: "A-124",
    toplatform: "Platform X",
    balance: "$400",
    createdAt: "2023-02-20",
  },
  {
    status: "pending",
    stage: "Stage 3",
    challenge: "Challenge C",
    accId: "ACC12347",
    platform: "Platform Z",
    abookacc: "A-125",
    toplatform: "Platform X",
    balance: "$800",
    createdAt: "2023-03-10",
  },
  {
    status: "completed",
    stage: "Stage 4",
    challenge: "Challenge D",
    accId: "ACC12348",
    platform: "Platform X",
    abookacc: "A-126",
    toplatform: "Platform Z",
    balance: "$1,800",
    createdAt: "2023-04-05",
  },
  {
    status: "failed",
    stage: "Stage 5",
    challenge: "Challenge E",
    accId: "ACC12349",
    platform: "Platform Y",
    abookacc: "A-127",
    toplatform: "Platform Z",
    balance: "$600",
    createdAt: "2023-05-15",
  },
  {
    status: "pending",
    stage: "Stage 6",
    challenge: "Challenge F",
    accId: "ACC12350",
    platform: "Platform Z",
    abookacc: "A-128",
    toplatform: "Platform Y",
    balance: "$1,500",
    createdAt: "2023-06-20",
  },
  {
    status: "completed",
    stage: "Stage 7",
    challenge: "Challenge G",
    accId: "ACC12351",
    platform: "Platform X",
    abookacc: "A-129",
    toplatform: "Platform Z",
    balance: "$2,500",
    createdAt: "2023-07-25",
  },
  {
    status: "failed",
    stage: "Stage 8",
    challenge: "Challenge H",
    accId: "ACC12352",
    platform: "Platform Y",
    abookacc: "A-130",
    toplatform: "Platform X",
    balance: "$800",
    createdAt: "2023-08-10",
  },
  {
    status: "pending",
    stage: "Stage 9",
    challenge: "Challenge I",
    accId: "ACC12353",
    platform: "Platform Z",
    abookacc: "A-131",
    toplatform: "Platform Y",
    balance: "$1,000",
    createdAt: "2023-09-15",
  },
  {
    status: "completed",
    stage: "Stage 10",
    challenge: "Challenge J",
    accId: "ACC12354",
    platform: "Platform X",
    abookacc: "A-132",
    toplatform: "Platform Z",
    balance: "$3,500",
    createdAt: "2023-10-20",
  },
];

const ABook = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const theme = useTheme();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  return (
    <Paper
      sx={{
        width: "100%",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          p: "24px",
          background: (theme) => theme.palette.color.active,
          width: "100%",
        }}
      >
        <Typography variant="h1">A-book account</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <HomeIcon
            width="20px"
            height="20px"
            fill={theme.palette.color.secondary}
          />
          <ChevronRightIcon />
          <Typography variant="body3">Your Firm</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Customers</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">11020022</Typography>
          <ChevronRightIcon />
          <Typography variant="body1">Trading accounts</Typography>
        </Stack>
      </Stack>

      <Box
        sx={{
          p: "24px",
        }}
      >
        <CustomTable
          headcells={headcells}
          rows={sortedData}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          fontSize="11.5px"
        />
      </Box>
    </Paper>
  );
};

export default ABook;
