import { color } from "chart.js/helpers";

export default function componentStyleOverrides(theme) {
  return {
    MuiSlider: {
      styleOverrides: {
        root: {
          "& .MuiSlider-rail": {
            background: "rgba(0, 149, 255, 0.5)",
          },
          "& .MuiSlider-track": {
            background: theme.colors.blue,
            border: "none",
          },
          "& .MuiSlider-thumb": {
            width: "12px",
            height: "12px",

            backgroundColor: theme.colors.blue,
            border: `1px solid ${theme.colors.border}`,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 10px 20px 4px",
            background: theme.colors.bg2,
            border: `1px solid ${theme.colors.border}`,
            borderRadius: "10px",
            "& ul": {
              padding: "0px",
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root ": {
            fontSize: "14px",
            fontWeight: "500",
            color: theme.colors.primary,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: "600",
          "&.Mui-focused": {
            color: theme.colors.primary,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .MuiIconButton-root": {
            padding: "8px",
          },
          padding: "8px",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiDrawer-paper": {
            borderRadius: "10px 0 0 10px ",
            background: theme.colors.bg,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: "transparent",
          "& .MuiTableRow-root": {
            background: "transparent !important",
          },
          "& .MuiTableCell-root": {
            borderBottom: `1px solid ${theme.colors.border}`,
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "22px",
            paddingRight: "22px",
            fontSize: "11.5px",
            fontWeight: 400,
          },

          "& .MuiTableCell-head": {
            color: theme.colors.secondary,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "&.client-info-tabs .MuiTabs-scroller": {
            overflow: "auto !important",
          },
          "& .MuiTab-root": {
            minHeight: "40px !important",
            minWidth: "auto",
            padding: "0px 16px",
            fontSize: "13px",
            fontWeight: "500",
            color: theme.colors.secondary,
            textTransform: "none",
            transition: "all 500ms ease-in-out",
            ":hover": {
              opacity: 0.35,
            },
          },
          "& .Mui-selected": {
            color: theme.colors.primary + "!important",
            fontSize: "13px",
            ":hover": {
              opacity: 1,
            },
          },
          borderBottom: "0",
          minHeight: "40px !important",
        },

        indicator: {
          backgroundColor: theme.colors.active,
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            background: theme.colors.bg2,
            border: "1px solid rgba(49, 50, 54, 0.25)",
            borderRadius: "18px",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: "4px",
          background: theme.colors.active,

          color: "white",
          textTransform: "none",
          padding: "8px 16px",
          "&:hover": {
            background: theme.colors.active,
          },
          "&.cust_icon_button span": {
            marginRight: "0px",
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
          background: theme.colors.bg,
          "&:focus-visible": {
            outline: "none",
          },
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          color: theme.colors?.primary,
          padding: "24px",
          borderRadius: "20px",
          boxShadow: "none",
          border: `1px solid ${theme.colors?.lightgray}`,

          background: theme.colors?.bg3,
          transition: "all 0.5s ease-in-out",
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: 1,
          borderColor: theme.colors.border,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.colors.secondary,
          background: theme.colors.bg2,
          border: `1px solid ${theme.colors.border}`,
          padding: "12px",
          borderRadius: "8px",
        },
      },
    },
    MuiAccordian: {
      styleOverrides: {
        root: {
          "&.MuiPaper-elevation0::before": {
            content: "none",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.custom_textfield ": {
            maxWidth: "450px",
            width: "100%",
            margin: "20px auto",
            display: "flex",
          },
          "&.custom_textfield.search_field ": {
            maxWidth: "240px",
            margin: "0px 0 0 auto",
          },
          "&.custom_textfield .MuiOutlinedInput-notchedOutline": {
            opacity: 0,
          },
          "&.custom_textfield input": {
            borderRadius: 24,
            background: theme.colors.borderpure,
            border: "1px solid #15191F",
            fontSize: 12,
            fontWeight: "400",
            color: "#8A96A6",
          },
          "&.custom_textfield.search_field input": {
            padding: "10px",
          },
          "&.custom_textfield input::placeholder": {
            fontSize: 12,
            fontWeight: "400",
            color: "#8A96A6",
          },
          "&.textField_with_icon .MuiOutlinedInput-root": {
            borderRadius: "24px",
            backgroundColor: theme.colors.lightgray,
          },
          "&.textField_with_icon .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
            {
              borderColor: theme.colors.borderpure,
            },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.switch_with_label": {
            marginLeft: 0,
          },
          "&.switch_with_label .MuiTypography-body1": {
            marginLeft: 5,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.custom_accordion": {
            background: "#14181F",
            borderRadius: "16px",
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          "&.affilate_ticket_heading .affilate_ticket_actions": {
            opacity: "0",
          },
          "&.affilate_ticket_heading:hover .affilate_ticket_actions": {
            opacity: "1",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "&.chat_list_component .Mui-selected, &.chat_list_component .MuiListItemButton-root:hover":
            {
              backgroundColor: "#101419",
              borderRadius: "18px",
            },
          "&.chat_list_component .MuiListItemButton-root": {
            padding: "12px",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.conversation_grid_cont": {
            padding: 0,
          },
        },
      },
    },
  };
}
