import React, { useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Avatar,
  Stack,
  Typography,
  IconButton,
  ListItemAvatar,
  Tooltip,
  Paper,
  ListItemButton,
  Popover,
  Collapse,
} from "@mui/material";
import { EditOutlined, FilterList, InfoOutlined } from "@mui/icons-material";
import { stringAvatar } from "../../../utils/randomColorString";
import CustomSwitch from "../../ui/Switch";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IoFilterOutline } from "react-icons/io5";
import DashboardIcon from "../../Icons/Dashboard";
import CustomMenu from "../../ui/CustomMenu";
import CopyIcon from "../../Icons/Copy";
import { LuCopy } from "react-icons/lu";
import LiveAccount from "../../Icons/LiveAccount";
import EditIcon from "../../Icons/Edit";
import { FiSlash } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import SmallTreeIcon from "../../Icons/SmallTree";

const options = [
  [
    {
      label: "Copy member ID",
      icon: <LuCopy size={16} />,
    },
    {
      label: "De-activate a-book",
      icon: <LiveAccount width="14" height="14" />,
    },
    { label: "Edit Customer", icon: <EditIcon width="20" height="20" /> },
  ],
  [
    { label: "Ban user", icon: <FiSlash size={16} /> },
    { label: "Clear Personal Data", icon: <AiOutlineDelete size={18} /> },
    {
      label: "Delete Customer",
      icon: <AiOutlineDelete size={18} />,
      color: "red",
    },
  ],
];

const chats = [
  {
    id: 1,
    name: "Jens",
    lastMessage: "Hello!",
    avatar: "https://via.placeholder.com/40",
    lastMessageTime: "5m ago",
    color: "active",
  },
  {
    id: 2,
    name: "Victoria",
    lastMessage: "How are you?",
    avatar: "https://via.placeholder.com/40",
    lastMessageTime: "10m ago",
    color: "pink",
  },
  {
    id: 3,
    name: "Stefan",
    lastMessage: "Hello!",
    avatar: "https://via.placeholder.com/40",
    lastMessageTime: "5m ago",
    color: "cyan",
  },
  {
    id: 4,
    name: "Sem",
    lastMessage: "How are you?",
    avatar: "https://via.placeholder.com/40",
    lastMessageTime: "10m ago",
    color: "purple",
  },
  // Add more chats here
];

const ChatList = ({ detailedView, onDetailView, onShowClientInfo }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [transition, setTransition] = React.useState(true);
  const [selected, setSelected] = React.useState(1);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(detailedView);
    }, 300);
  }, [detailedView]);

  return (
    <Paper
      sx={{
        bgcolor: "#090B0D",
        width: detailedView ? "350px" : "70px",
        transition: "width 0.5s",
        height: "99.8vh",
        padding: "0",
        overflow: "hidden",
        flexShrink: "0",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        sx={{
          ...(!detailedView && {
            alignItems: "center",
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          mb="20px"
          p="20px"
        >
          {detailedView && (
            <Box
              sx={{
                width: transition ? "100%" : "0px",
                overflow: "hidden",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap="3px"
                sx={{ pb: 1, flexWrap: "nowrap" }}
              >
                <IconButton onClick={() => {}} sx={{ p: 0 }}>
                  <KeyboardArrowLeftOutlinedIcon style={{ fontSize: "14px" }} />
                </IconButton>
                <Typography
                  variant="h5"
                  sx-={{
                    fontSize: "15.5px",
                    fontWeight: "400",
                    whiteSpace: "nowrap",
                  }}
                >
                  All conversations
                </Typography>
              </Stack>
              <Typography
                variant="body3"
                sx={{ mx: 0.5, fontWeight: "400", whiteSpace: "nowrap" }}
              >
                You have 11 conversations
              </Typography>
            </Box>
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            {detailedView && (
              <CustomMenu options={options}>
                <IconButton
                  sx={{
                    background: "rgba(21, 25, 31, 0.65)",
                  }}
                  aria-describedby={id}
                >
                  <IoFilterOutline size={16} sx={{ fontSize: "25px" }} />
                </IconButton>
              </CustomMenu>
            )}

            {/* {detailedView && (
              <>
                <IconButton
                  sx={{
                    background: "rgba(21, 25, 31, 0.65)",
                  }}
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  <IoFilterOutline size={16} sx={{ fontSize: "25px" }} />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Stack alignItems="flex-start">
                    <IconButton onClick={() => {}}>
                      <ContentCopyIcon
                        style={{ fontSize: "14px", marginRight: "8px" }}
                      />
                      <Typography variant="caption">Copy member ID</Typography>
                    </IconButton>
                    <IconButton onClick={() => {}}>
                      <ContentCopyIcon
                        style={{ fontSize: "14px", marginRight: "8px" }}
                      />
                      <Typography variant="caption">
                        De-activate a-book
                      </Typography>
                    </IconButton>
                    <IconButton onClick={() => {}}>
                      <ContentCopyIcon
                        style={{ fontSize: "14px", marginRight: "8px" }}
                      />
                      <Typography variant="caption">Edit Customer</Typography>
                    </IconButton>
                    <IconButton onClick={() => {}}>
                      <ContentCopyIcon
                        style={{ fontSize: "14px", marginRight: "8px" }}
                      />
                      <Typography variant="caption">Ban user</Typography>
                    </IconButton>
                    <IconButton onClick={() => {}}>
                      <ContentCopyIcon
                        style={{ fontSize: "14px", marginRight: "8px" }}
                      />
                      <Typography variant="caption">
                        Clear Personal Data
                      </Typography>
                    </IconButton>
                    <IconButton onClick={() => {}}>
                      <ContentCopyIcon
                        style={{ fontSize: "14px", marginRight: "8px" }}
                      />
                      <Typography variant="caption">Delete Customer</Typography>
                    </IconButton>
                  </Stack>
                </Popover>
              </>
            )} */}
            <IconButton onClick={() => onDetailView()}>
              <DashboardIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          sx={{
            padding: detailedView ? "0px 20px" : "0px",
            width: "100%",
          }}
          spacing={1}
        >
          {chats.map((chat) => (
            <Box onClick={() => setSelected(chat.id)}>
              {detailedView && transition && (
                <Stack
                  sx={{
                    padding: "12px",
                    borderRadius: "18px",
                    ...(selected === chat.id && {
                      background: (theme) => theme.palette.color.borderpure,
                    }),
                    "&:hover": {
                      background: (theme) => theme.palette.color.borderpure,
                    },
                    cursor: "pointer",
                  }}
                  spacing={0.5}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{}}
                  >
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      <Avatar
                        alt="User Avatar"
                        sx={{
                          width: 30,
                          height: 30,
                          background: (theme) =>
                            theme.palette.color[chat.color],
                          border: (theme) =>
                            `1px solid ${theme.palette.color.border}`,
                          color: (theme) => theme.palette.color.bg,
                          padding: "7px",
                          fontSize: "11.5px",
                        }}
                      >
                        {chat.name.charAt(0)}
                      </Avatar>
                      <Typography variant="heading">{chat.name}</Typography>
                    </Stack>
                    <Typography variant="body3" sx={{ fontWeight: "400" }}>
                      · {chat.lastMessageTime}
                    </Typography>
                  </Stack>
                  <Typography
                    variant="caption_500"
                    sx={{
                      paddingLeft: "40px",
                      fontWeight: "400",
                    }}
                  >
                    5 word summary of conversation
                  </Typography>
                </Stack>
              )}
              {!detailedView && (
                <Box
                  sx={{
                    width: "100%",
                    py: "6px",
                    display: "flex",
                    justifyContent: "center",
                    borderLeft: "4px solid transparent",
                    cursor: "pointer",
                    ...(selected === chat.id && {
                      background: (theme) => theme.palette.color.borderpure,
                      borderLeft: (theme) =>
                        `4px solid ${theme.palette.color.active}`,
                    }),
                    "&:hover": {
                      background: (theme) => theme.palette.color.borderpure,
                      borderLeft: (theme) =>
                        `4px solid ${theme.palette.color.active}`,
                    },
                  }}
                  onClick={() => setSelected(chat.id)}
                >
                  <Avatar
                    alt="User Avatar"
                    sx={{
                      width: 30,
                      height: 30,
                      background: (theme) => theme.palette.color[chat.color],
                      border: (theme) =>
                        `1px solid ${theme.palette.color.border}`,
                      color: (theme) => theme.palette.color.bg,
                      padding: "7px",
                      fontSize: "11.5px",
                    }}
                  >
                    {chat.name.charAt(0)}
                  </Avatar>
                </Box>
              )}
              <Collapse in={selected === chat.id && detailedView}>
                <Stack direction="row" sx={{ px: "20px" }}>
                  <SmallTreeIcon />
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1.5}
                    sx={{
                      alignSelf: "center",
                      py: "12px",
                      px: "8px",
                    }}
                  >
                    <Avatar
                      alt="User Avatar"
                      sx={{
                        width: 30,
                        height: 30,
                        background: (theme) => theme.palette.color.active,
                        border: (theme) =>
                          `1px solid ${theme.palette.color.border}`,
                        color: (theme) => theme.palette.color.bg,
                        padding: "7px",
                        fontSize: "11.5px",
                      }}
                      src="/logo.avif"
                    >
                      J
                    </Avatar>
                    <Typography variant="heading">AI Assistant</Typography>
                  </Stack>
                </Stack>
              </Collapse>
            </Box>
          ))}
        </Stack>

        {/* <List className="chat_list_component">
        
          {chats.map((chat, index) => (
            <ListItemButton
              key={chat.id}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
              sx={{
                mb: "5px",
              }}
            >
              <ListItemAvatar>
                <Avatar {...stringAvatar(chat.name)}>
                  <Typography>{chat.name.charAt(0)}</Typography>
                </Avatar>
              </ListItemAvatar>
              {detailedView && (
                <ListItemText
                  primary={
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mb="5px"
                    >
                      <Typography>{chat.name}</Typography>
                      <Typography variant="body3">5m ago</Typography>
                    </Stack>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        variant="body3"
                        sx={{
                          fontSize: "13px",
                          color: (theme) => theme.palette.color.secondary,
                        }}
                      >
                        {chat.lastMessage}
                      </Typography>
                    </React.Fragment>
                  }
                />
              )}
            </ListItemButton>
          ))}
        </List> */}
        <Stack spacing={1}></Stack>
      </Box>
      {/* {detailedView && ( */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          position: "sticky",
          bottom: 0,
          bgcolor: "#090B0D",
          padding: detailedView ? "20px" : "20px 5px",
        }}
      >
        <Stack direction="row" alignItems="center" gap="5px">
          <Tooltip title={"tooltip"} placement="top">
            <InfoOutlined style={{ fontSize: "16px", color: "#8A96A6" }} />
          </Tooltip>
          <Typography
            variant="body3"
            sx={{
              opacity: 1,
              width: transition ? "100%" : "0px",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            Client information
          </Typography>
        </Stack>
        <CustomSwitch onChange={onShowClientInfo} defaultChecked />
      </Stack>
      {/* )} */}
    </Paper>
  );
};

export default ChatList;
