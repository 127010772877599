import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import {
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toggleOpen } from "../../../slices/openModalSlice";
import FormInput from "../../ui/FormInput";
import FormRadio from "../../ui/Radio";
import FormSwitch from "../../ui/FormSwitch";
import FormSelect from "../../ui/FormSelect";
import { useAddAnnouncementMutation } from "../../../slices/api/announcementSlice";

const labelStyle = {
  fontSize: "13px",
  fontWeight: "600",
};

const inputStyle = {
  fontSize: "13px",
  borderRadius: "14px",
};

const AddAnnouncement = () => {
  const { open } = useSelector((state) => state.openModal);
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    audience: "dashboard",
    status: "high",
    priority: "vuetra",
    collection_id: "vuetra",
    display_option: "",
    efficiency: 0,
    published_on: "",
    scheduled_at: "",
  });
  const [addAnnouncement] = useAddAnnouncementMutation();

  const handleSubmit = async () => {
    await addAnnouncement(formValues);
    dispatch(toggleOpen());
  };

  return (
    <React.Fragment>
      <Drawer
        sx={
          {
            // borderRadius: "14px 0 14px 0",
            // overflow: "hidden",
          }
        }
        open={open}
        onClose={() => dispatch(toggleOpen())}
        anchor="right"
        // position="relative"
      >
        <Box
          sx={{
            position: "fixed",
            top: "18px",
            left: "900px",
          }}
        >
          <IconButton
            sx={{
              width: "56px",
              height: "56px",
              background: (theme) => theme.palette.color.borderpure,
              transition: "all 0.5s",
              "&:hover": {
                rotate: "180deg",
              },
            }}
            onClick={() => dispatch(toggleOpen())}
          >
            <CloseIcon
              sx={{
                color: (theme) => theme.palette.color.primary,
              }}
            />
          </IconButton>
        </Box>

        <Paper
          sx={{
            width: "558px",
            height: "100vh",
            overflow: "hidden",
            background: (theme) => theme.palette.color.bg5,
          }}
        >
          <Box
            sx={{
              height: "calc(100vh - 70px)",
              overflowY: "auto",
              padding: "24px",
            }}
          >
            <Stack
              spacing={1.5}
              sx={{
                position: "fixed",
                top: "0",
                background: (theme) => theme.palette.color.bg2,
                py: "24px",
                zIndex: "100",
                width: "100%",
              }}
            >
              <Typography variant="h2" sx={{ fontWeight: "600" }}>
                Add Announcement
              </Typography>

              <Typography variant="caption_500">
                Send a announcement to your team or customers
              </Typography>
            </Stack>

            <Stack spacing={4} sx={{ pb: "48px", pt: "100px" }}>
              <FormInput
                label="Title"
                placeholder="Enter Title"
                labelStyle={labelStyle}
                sx={inputStyle}
                required
                value={formValues.title}
                onChange={(e) =>
                  setFormValues({ ...formValues, title: e.target.value })
                }
              />
              <FormInput
                label="Description"
                placeholder="Enter the description"
                labelStyle={labelStyle}
                sx={inputStyle}
                required
                multiline
                minRows={4}
              />
              <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Typography variant="subtitle1">
                    Announcement Audience
                  </Typography>
                  <Typography variant="body3">
                    Choose the audience for this announcement. Select whether it
                    should be sent to the Client Dashboard, kept Private, or
                    made available to Everyone.
                  </Typography>
                </Stack>
                <FormRadio
                  options={[
                    { value: "dashboard", label: "Client Dashboard" },
                    { value: "team", label: "Team" },
                    { value: "everyone", label: "Everyone" },
                  ]}
                />
              </Stack>

              <FormSelect
                label="Priority"
                options={[
                  { value: "low", label: "Low" },
                  { value: "medium", label: "Medium" },
                  { value: "high", label: "High" },
                ]}
                labelStyle={labelStyle}
                sx={inputStyle}
                defaultValue="high"
                helperTexts1={[
                  "Select which priority this announcement is, this will show as a tag beside the announcement, this can help showing urgency to users",
                ]}
              />
              <FormSelect
                label="Collection"
                options={[
                  { value: "vuetra", label: "Trading Platforms - Vuetra" },
                ]}
                labelStyle={labelStyle}
                sx={inputStyle}
                defaultValue="vuetra"
                helperTexts1={[
                  "For announcements related to a specific topic, we recommend selecting an appropriate collection.",
                ]}
              />
              <Stack spacing={2}>
                <Stack spacing={0.5}>
                  <Typography variant="subtitle1">
                    Display Options for Your Announcement
                  </Typography>
                  <Typography variant="body3">
                    Select whether you want the announcement to appear as a
                    popup modal or be visible within notifications.
                  </Typography>
                </Stack>
                <FormRadio
                  options={[
                    { value: "notifications", label: "Within notifications" },
                    { value: "popoup", label: "Popup modal" },
                  ]}
                />
              </Stack>

              <Divider />

              <Stack spacing={2}>
                <Typography variant="h5" sx={{ fontWeight: "500" }}>
                  Customer Support Efficiency
                </Typography>
                <FormSwitch
                  label="AI"
                  flex
                  helperTexts={[
                    "By enabling this feature, you inform the AI about important topics, such as system issues, allowing it to address these automatically. This will help reduce the number of support tickets for your agents.",
                  ]}
                />
              </Stack>
            </Stack>
          </Box>
          <Stack
            spacing={1}
            sx={{
              padding: "6px 32px",
              background: (theme) => theme.palette.color.bg2,
              position: "sticky",
              height: "70px",
              bottom: 0,
            }}
            justifyContent="flex-end"
            alignItems="center"
            flexDirection="row"
          >
            <Button
              sx={{
                background: "transparent",
                color: (theme) => theme.palette.color.primary,
                borderRadius: "9px",
                fontSize: "13px",
                fontWeight: "500",
                ":hover": {
                  background: "transparent",
                },
              }}
              onClick={() => {
                dispatch(toggleOpen());
              }}
            >
              Cancel
            </Button>

            <Button
              sx={{
                background: (theme) => theme.palette.color.border,
                color: (theme) => theme.palette.color.primary,
                borderRadius: "9px",
                fontSize: "13px",
                fontWeight: "500",

                boxShadow: "none",
                ":hover": {
                  background: (theme) => theme.palette.color.border,
                },
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              Publish
            </Button>
          </Stack>
        </Paper>
      </Drawer>
    </React.Fragment>
  );
};

export default AddAnnouncement;
