import { useTheme } from "@mui/material";
import * as React from "react";

const ArrowRight = ({ stroke, ...props }) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        d="M13.75 6.75L19.25 12L13.75 17.25"
        stroke={stroke || theme.palette.color.secondary}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 12H4.75"
        stroke={stroke || theme.palette.color.secondary}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
