import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import RoundFormSelect from '../../ui/RoundFormSelect';

const SalesGrowthByPeriod = () => {
  const theme = useTheme();

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '90%',
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return val !== 0 ? `${val}%` : '';
      },
      style: {
        colors: ['#FFFFFF', '#0B0D0F'],
        fontSize: '14px',
      },
      offsetX: -5,
    },
    xaxis: {
      categories: ['Q1', 'Q2', 'Q3', 'Q4'],
      labels: {
        show: true,
        style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px',
          },
      },
      axisBorder: {
        show: false, 
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: true, 
        style: {
          colors: theme.palette.text.secondary,
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false, 
      },
    },
    colors: ['#F55F82', '#5EEDF8'],
    grid: {
      show: false, 
    },
    tooltip: {
      enabled: false,
      theme: 'dark',
    },
    legend: {
      show: false,
    },
  };

  const series = [
    {
      name: 'Negative Growth',
      data: [0, 0, -8, 0],
    },
    {
      name: 'Positive Growth',
      data: [16, 9.35, 0, 23],
    },
  ];

  return (
    <Box
      sx={{
        background: theme.palette.color.bg5,
        border: '1px solid',
        borderColor: theme.palette.color.strockborder,
        p: '20px',
        borderRadius: '12px',
        mt: 2,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" color={theme.palette.text.primary}>
          Sales Growth by Period
        </Typography>
        <RoundFormSelect
          options={[{ value: "quarter", label: "Quarter" }]}
          defaultValue="quarter"
        />
      </Stack>

      <ReactApexChart options={options} series={series} type="bar" height={300} />

      <Stack direction="row" justifyContent="center" mt={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            sx={{
              width: 10,
              height: 10,
              backgroundColor: '#F55F82',
              borderRadius: '50%',
            }}
          />
          <Typography variant="caption" color={theme.palette.text.secondary}>
            Negative Growth
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} ml={3}>
          <Box
            sx={{
              width: 10,
              height: 10,
              backgroundColor: '#5EEDF8',
              borderRadius: '50%',
            }}
          />
          <Typography variant="caption" color={theme.palette.text.secondary}>
            Positive Growth
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SalesGrowthByPeriod;