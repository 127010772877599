import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Stack, styled, Typography } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';

const TradeSizeFrequency = () => {
  const ChartContainer = styled(Box)({
    width: '100%',
    '& .apexcharts-toolbar': {
      display: 'none !important',
    },
  });

  const series = [
    {
      name: 'Average Trade Size (USA, 18-25)',
      type: 'column',
      data: [2400, 3500, 2000, 2100, 4400, 2400, 1900, 2900, 1900, 3400, 1300, 700],
    },
    {
      name: 'Average Trade Size (UK, 26-35)',
      type: 'column',
      data: [3000, 1500, 1700, 2100, 3400, 200, 1900, 700, 3000, 1600, 700, 1600],
    },
    {
      name: 'Trade Frequency (USA, 18-25)',
      type: 'line',
      data: [3050, 2950, 2850, 2750, 2650, 2550, 2700, 2850, 3000, 3150, 3300, 3550],
    },
    {
      name: 'Trade Frequency (UK, 26-35)',
      type: 'line',
      data: [1800, 2000, 2300, 2700, 3100, 3000, 3050, 3000, 2900, 2800, 2700, 2650],
    },
  ];

  const options = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      background: 'transparent',
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: { style: { colors: '#8A96A6' } },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      min: 0,
      max: 4500,
      labels: {
        formatter: (val) => `${val}`,
        style: { colors: "#8A96A6" },
      },
    },
    stroke: {
      width: [1, 1, 3, 3],
      curve: 'smooth',
    },
    colors: ['#5EEDF8', '#5F55EE', '#F55F82', '#2FE0A2'],
    plotOptions: {
      bar: {
        columnWidth: '50%',
        borderRadius: 4,
      },
    },
    dataLabels: { enabled: false },
    grid: { show: false },
    legend: { show: false },
    tooltip: { enabled: false },
    fill: {
      type: 'gradient',
      opacity: [0.15, 0.15, 1, 1],
      gradient: {
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: [0.38, 0.38, 1, 1],
        opacityTo: [0.05, 0.05, 1, 1],
        shade: 'light',
        type: 'vertical',
        stops: [0, 100],
      },
    },
    markers: {
      size: 2,
      colors: undefined,
      strokeWidth: 0,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "square",
      radius: 5,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        p: '10px',
        borderRadius: '16px',
        mb: 3,
      }}
    >
      <Stack direction="row" p={2} alignItems="center" justifyContent="space-between">
        <Typography variant="h5">Trade Size and Frequency</Typography>
        <RoundFormSelect
          options={[{ value: "yearly", label: "Yearly" }]}
          defaultValue="yearly"
        />
      </Stack>

      <ChartContainer>
        <ReactApexChart
          options={options}
          series={series.map(({ name, data, type }, index) => ({
            name,
            data,
            type,
            fillColor: {
              colors: ['transparent'],
              borderColor: options.colors[index],
              backgroundBarColors: ['transparent'],
              backgroundBarOpacity: 1,
            },
          }))}
          type="line"
          height={350}
        />
      </ChartContainer>

      <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 12, height: 12, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
          <Typography variant="body2">Average Trade Size (USA, 18-25)</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
          <Typography variant="body2">Average Trade Size (UK, 26-35)</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6.5" cy="3" r="3" fill="#ED55D4" />
            <rect x="0.5" y="2.5" width="12" height="1" fill="#ED55D4" />
          </svg>
          <Typography variant="body2" ml={1}>Trade Frequency (USA, 18-25)</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 0L9.5 3L6.5 6L3.5 3L6.5 0Z" fill="#2FE0A2" />
            <rect x="0.5" y="2.5" width="12" height="1" fill="#2FE0A2" />
          </svg>
          <Typography variant="body2" ml={1}>Trade Frequency (UK, 26-35)</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default TradeSizeFrequency;