import { BiCommand } from "react-icons/bi";
import { IoDocumentOutline } from "react-icons/io5";

export const cards = [
  {
    title: "Speed up research",
    items: [
      { title: "AI Assistant chat", description: "Ask Vue Assistant anything" },
      {
        title: "Ecommerce calendar",
        description: "Show important global days",
      },
      {
        title: "Planned discounts",
        description: "Show important global days",
      },
    ],
  },
  {
    title: "Gain insights",
    items: [
      { title: "All time revenue" },
      {
        title: "Total breached",
      },
      {
        title: "Total funded",
      },
      { title: "Active traders" },
      {
        title: "Best performing product",
      },
      {
        title: "Best performing acc. size",
      },
      { title: "Active traders" },
      {
        title: "Best performing product",
      },
      {
        title: "Best performing acc. size",
      },
    ],
  },
];

export const searchItems = [
  {
    title: "Most used",
    items: [
      { title: "Add account", icon: <BiCommand size={12} />, shortcut: "A" },
      { title: "Payouts", icon: <IoDocumentOutline size={12} /> },
    ],
  },
  {
    title: "Firm",
    items: [
      { title: "Add Product", icon: <BiCommand size={12} /> },
      { title: "Add user", icon: <BiCommand size={12} />, shortcut: "D" },
      { title: "text", icon: <IoDocumentOutline size={12} /> },
      { title: "text", icon: <IoDocumentOutline size={12} /> },
    ],
  },
  {
    title: "Trading accounts",
    items: [
      { title: "text", icon: <IoDocumentOutline size={12} /> },
      { title: "text", icon: <IoDocumentOutline size={12} /> },
    ],
  },
];

export const notifications = [
  {
    time: "Today",
    notifications: [
      {
        user: "John Doe",
        status: "pending",
        type: "payout",
        amount: "$100",
      },
      {
        userId: "12345",
        user: "Jane Smith",
        payoutId: "67890",
        status: "rejected",
        type: "payout",
        amount: "$200",
      },
      {
        user: "Alice Johnson",
        status: "pending",
        type: "copyTrader",
        amount: "Copy Trader",
      },
      {
        user: "Emily Clark",
        status: "complete",
        type: "payout",
        amount: "$150",
      },
      {
        user: "Frank Green",
        status: "complete",
        type: "ticket",
      },
    ],
  },
  {
    time: "Yesterday",
    notifications: [
      {
        ticket: "001",
        status: "pending",
        type: "ticket",
      },
      {
        user: "Bob Brown",
        customer: "Customer 1",
        status: "pending",
        type: "waiting",
      },
      {
        user: "Charlie Davis",
        status: "pending",
        type: "payout",
        amount: "$150",
      },
      {
        user: "Diana Evans",
        status: "pending",
        type: "copyTrader",
        amount: "Copy Trader",
      },
      {
        ticket: "002",
        status: "pending",
        type: "ticket",
      },
      {
        user: "George Harris",
        status: "complete",
        type: "ticket",
      },
    ],
  },
  {
    time: "July 22, 2024",
    notifications: [
      {
        userId: "67890",
        user: "George Harris",
        payoutId: "34567",
        status: "rejected",
        type: "payout",
        amount: "$300",
      },
      {
        userId: "78901",
        user: "Helen Johnson",
        id: "89012",
        status: "closed",
        type: "ticket",
      },
      {
        userId: "90123",
        user: "Ian King",
        payoutId: "01234",
        status: "approved",
        type: "payout",
      },
      {
        userId: "23456",
        user: "Jessica Lee",
        payoutId: "56789",
        status: "assigned",
        type: "ticket",
      },
      {
        userId: "34567",
        user: "Kevin Miller",
        payoutId: "67890",
        status: "rejected",
        type: "payout",
        amount: "$400",
      },
      {
        userId: "45678",
        user: "Laura Nelson",
        payoutId: "78901",
        status: "banned",
        type: "ipAddress",
      },
      {
        userId: "56789",
        user: "Michael Owens",
        payoutId: "89012",
        status: "rejected",
        type: "payout",
        amount: "$500",
      },
      {
        userId: "67890",
        user: "Nancy Parker",
        payoutId: "90123",
        status: "complete",
        type: "ticket",
      },
    ],
  },
];