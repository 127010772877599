import { useTheme } from "@mui/material";
import * as React from "react";

const ExportIcon = ({ stroke, ...props }) => {
  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.12988 16.833H15.1299"
        stroke={stroke || theme.palette.color.primary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6299 14.167V7.16699"
        stroke={stroke || theme.palette.color.primary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.79688 10.167L11.6299 7.16699L14.4629 10.167"
        stroke={stroke || theme.palette.color.primary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ExportIcon;
