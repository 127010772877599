import { Box } from "@mui/material";
import React from "react";

export default function StepperCard({
  children,
  horizontalAlign = "center",
  isActive = false,
  sx = {},
  ...rest
}) {
  return (
    <Box
      sx={{
        minHeight: "80px",
        borderRadius: 4,
        background: "#101419",
        display: "flex",
        alignItems: "center",
        justifyContent: horizontalAlign,
        padding: "16px",
        border: isActive ? "1px solid #2FE0A2" : "1px solid transparent",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
