import React from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import RoundFormSelect from '../../ui/RoundFormSelect'
import LineChart from '../../Charts/LineChart'

const PerformaceComparisionByPeriod = () => {
    const theme = useTheme();

    const series = [
        {
            name: 'Age 26-35',
            data: [980, 940, 920, 880, 840, 820, 940, 920, 960, 1020, 1080, 1140],
        },
        {
            name: 'Age 46-55',
            data: [1180, 1320, 1440, 1540, 1260, 1040, 800, 580, 600, 620, 660],
        },
        {
            name: 'Age 56+',
            data: [280, 340, 420, 480, 480, 500, 500, 480, 400, 340, 440, 500],
        },
    ];

    const options = {
        markers: {
            size: 3,
            colors: undefined,
            strokeWidth: 0,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 0.8,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        yaxis: {
            tickAmount: 8,
            min: 200,
            max: 1600,
            labels: {
                style: {
                    colors: theme.palette.color.secondary,
                    fontSize: "13px",
                },
                formatter: function (val) {
                    return val;
                },
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                    colors: theme.palette.color.secondary,
                    fontSize: "13px",
                },
            },
            axisTicks: {
                show: false,
            },
            tickAmount: 12,
        },
        stroke: {
            curve: "straight",
            width: 4,
        },
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            p: "24px",
            width: "100%",
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
            mt: 2,
        }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",

                    color: (theme) => theme.palette.color.secondary,
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5">Sales Trends by Demographics Groups</Typography>
                </Stack>

                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        alignItems: "center",
                    }}
                >
                    <Stack direction="row" spacing={0.7} alignItems="center">
                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="3" r="3" fill="#5F55EE" />
                            <rect y="2.5" width="12" height="1" fill="#5F55EE" />
                        </svg>
                        <Typography variant="caption">Sales</Typography>
                    </Stack>
                    <Stack direction="row" spacing={0.7} alignItems="center">
                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 0L9 3L6 6L3 3L6 0Z" fill="#5EEDF8" />
                            <rect y="2.5" width="12" height="1" fill="#5EEDF8" />
                        </svg>

                        <Typography variant="caption">Revenue</Typography>
                    </Stack>
                    <Stack direction="row" spacing={0.7} alignItems="center">
                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" width="6" height="6" fill="#FFC452" />
                            <rect y="2.5" width="12" height="1" fill="#FFC452" />
                        </svg>

                        <Typography variant="caption">Customer Satisfaction</Typography>
                    </Stack>
                    <RoundFormSelect
                        options={[{ value: "monthly", label: "Monthly" }]}
                        defaultValue="monthly"
                    />
                </Stack>
            </Box>
            <LineChart
                height="300"
                categories={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                colors={[
                    "#5F55EE",
                    "#5EEDF8",
                    "#FFC452",
                ]}
                options={options}
                series={series}
            />
        </Box>
    );
}

export default PerformaceComparisionByPeriod