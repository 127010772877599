import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import React from 'react'
import RoundFormSelect from '../../ui/RoundFormSelect';
import LineChart from '../../Charts/LineChart';

const RevenueTrendsByCountry = () => {
    const theme = useTheme();

    const ChartContainer = styled(Box)({
        width: '100%',
        height: '100%',
        '& .apexcharts-toolbar': {
            display: 'none !important',
        },
    });

    const series = [
        {
            name: 'USA',
            data: [35000, 37000, 42000, 45000, 47000, 52000, 50000, 48000, 46000, 45000, 44000, 43000],
        },
        {
            name: 'Canada',
            data: [38000, 37000, 36000, 35000, 36000, 35000, 37000, 39000, 41000, 43000, 45000, 46000],
        },
        {
            name: 'UK',
            data: [33000, 35000, 37000, 39000, 41000, 43000, 45000, 43000, 41000, 39000, 41000, 39000],
        },
        {
            name: 'Germany',
            data: [36000, 39000, 42000, 46000, 49000, 46000, 43000, 40000, 38000, 39000, 40000, 41000],
        },
        {
            name: 'Australia',
            data: [31000, 33000, 36000, 34000, 33000, 35000, 34000, 36000, 33000, 32000, 34000, 35000],
        },
    ];

    const options = {
        chart: {
            type: 'line',
            toolbar: { show: false },
            background: 'transparent',
        },
        markers: {
            size: 4,
            strokeWidth: 0,
            hover: {
                size: 7,
            },
        },
        yaxis: {
            tickAmount: 5,
            min: 30000,
            max: 55000,
            labels: {
                style: {
                    colors: theme.palette.color.secondary,
                    fontSize: "13px",
                },
                formatter: function (val) {
                    return '$' + val.toLocaleString();
                },
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                    colors: theme.palette.color.secondary,
                    fontSize: "13px",
                },
            },
            axisTicks: {
                show: false,
            },
        },
        stroke: {
            curve: "straight",
            width: 2,
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return '$' + value.toLocaleString();
                }
            }
        },
        legend: {
            show: false,
        },
        grid: {
            show: false,
        },
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            border: '1px solid',
            borderColor: (theme) => theme.palette.color.strockborder,
            p: '10px',
            borderRadius: '16px',
            mt: 2
        }}>
            <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                    Revenue Trends by Country
                </Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" width="6" height="6" fill="#2FE0A2" />
                            <rect y="2.5" width="12" height="1" fill="#2FE0A2" />
                        </svg>
                        <Typography variant="body2" ml={1}>USA</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="3" r="3" fill="#5F55EE" />
                            <rect y="2.5" width="12" height="1" fill="#5F55EE" />
                        </svg>
                        <Typography variant="body2" ml={1}>Canada</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 0L9 3L6 6L3 3L6 0Z" fill="#5EEDF8" />
                            <rect y="2.5" width="12" height="1" fill="#5EEDF8" />
                        </svg>
                        <Typography variant="body2" ml={1}>UK</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="3" r="3" fill="#ED55D4" />
                            <rect y="2.5" width="12" height="1" fill="#ED55D4" />
                        </svg>
                        <Typography variant="body2" ml={1}>Germany</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" width="6" height="6" fill="#FFC452" />
                            <rect y="2.5" width="12" height="1" fill="#FFC452" />
                        </svg>
                        <Typography variant="body2" ml={1}>Australia</Typography>
                    </Box>
                    <RoundFormSelect
                        options={[{ value: "monthly", label: "Monthly" }]}
                        defaultValue="monthly"
                    />
                </Stack>
            </Stack>
            <ChartContainer>
                <LineChart
                    height="300"
                    categories={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                    colors={[
                        "#2FE0A2",
                        "#5F55EE",
                        "#5EEDF8",
                        "#ED55D4",
                        "#FFC452"
                    ]}
                    options={options}
                    series={series}
                />
            </ChartContainer>
        </Box>
    );
}

export default RevenueTrendsByCountry