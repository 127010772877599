import React from 'react'
import { Box } from '@mui/material';
import AcquisitionCost from './AcquisitionCost';
import CampaignPerformance from './CampaignPerformance';
import RevenuePerSalesChannel from './RevenuePerSalesChannel';
import ConversionRateChart from './ConversionRateChart';

const SalesAndMarketing = () => {
  return (
    <Box sx={{ padding: '20px', minHeight: '100vh' }}>
      <RevenuePerSalesChannel />
      <ConversionRateChart />
      <CampaignPerformance />
      <AcquisitionCost />
    </Box>
  )
}

export default SalesAndMarketing;