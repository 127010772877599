import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import FormInput from "../../components/ui/FormInput";
import CustomSwitch from "../../components/ui/Switch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ProgramSettings = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "24px",
          py: "10px",
        }}
      >
        <Box>
          <Typography variant="h3" sx={{ m: "3px", mb: "3px" }} gutterBottom>
            Program settings
          </Typography>
          <Typography variant="caption" gutterBottom>
            View and update your affiliate settings
          </Typography>
        </Box>
        <Button size='small' sx={{ p: "5px", px: "10px", borderRadius: "32px", bgcolor: (theme) => theme.palette.color.active, color: "white", ":hover": { background: (theme) => theme.palette.color.active } }} onClick={() => { }}>
          Save Changes
        </Button>
      </Box>

      <Divider />
      <Box
        sx={{
          p: "24px",
        }}
      >
        <Grid container spacing={2} rowSpacing={3}>
          {/* <Grid item xs={12} md={6}></Grid> */}
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Affiliate hub profile</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Activate your affiliate program and allow new affiliate applications
              </Typography>
            </Stack>
          </Grid>


          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <CustomSwitch />
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Enable affiliate program and hub profile
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Affiliate signup URL</Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: "500", width: "90%" }}
              >
                Anyone can apply to be your affiliate with this link
              </Typography>
            </Stack>
          </Grid>




          <Grid item xs={12} md={6}>
            <FormInput
              defaultValue="https://yourdomain.com/affiliate/sign-up/"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <ContentCopyIcon
                      sx={{
                        fontSize: "16px",
                        color: (theme) => theme.palette.color.secondary,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>


          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Affiliate approval</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Automatically approve all new affiliate applications
              </Typography>
            </Stack>
          </Grid>


          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <CustomSwitch />
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Auto approve new affiliates
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>


          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Affiliate referral URL</Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: "500", width: "90%" }}
              >
                Set your default affiliate URL
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              defaultValue="yourdomain.com"
              startAdornment={
                <InputAdornment position="start">
                  <Box display="flex" alignItems="center">
                    <Typography variant="caption" ml={2} mr={1}>https://</Typography>
                  </Box>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <ContentCopyIcon
                      sx={{
                        fontSize: "16px",
                        color: (theme) => theme.palette.color.secondary,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Affiliate tracking script</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Copy and paste the tracking code into the or before the closing body tag of your website · Help↗
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              defaultValue={`<script>window.lemonSqueezyAffiliateConfig = { store: "vuetra" }; </script> <script src="https://lmsqueezy.com/affiliate.js" defer> </script>`}
              multiline
              rows={3}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <ContentCopyIcon
                      sx={{
                        fontSize: "16px",
                        color: (theme) => theme.palette.color.secondary,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>


          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Minimum payout</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                The minimum balance affiliates must reach before receiving an automated payout
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              defaultValue="10"
              endAdornment={
                <InputAdornment position="end">
                  <Typography mr={3} variant="caption">EUR</Typography>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>


          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Tracking length</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                How many days tracking code should be valid for
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              defaultValue="7"
              endAdornment={
                <InputAdornment position="end">
                  <Typography mr={3} variant="caption">Days</Typography>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProgramSettings;
