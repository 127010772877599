import React, { useState } from "react";
import { useGetPayoutsQuery } from "../../../slices/api/firmSlice";
import { Box, IconButton, Stack } from "@mui/material";
import EyeOpenIcon from "../../Icons/EyeOpen";
import TableMenu from "../../ui/TableMenu";
import CustomTable from "../Orders/Table";
import { toggleOpen } from "../../../slices/openModalSlice";
import { useDispatch } from "react-redux";
import CopyIcon from "../../Icons/Copy";
import CheckFillIcon from "../../Icons/CheckFill";
import CircleCloseIcon from "../../Icons/CircleClose";
import FlagIcon from "../../Icons/Flag";

const statutes = [
  { value: "pending", label: "Pending", color: "yellow" },
  { value: "approved", label: "Approved", color: "green" },
  { value: "denied", label: "Denied", color: "red" },
];

const items = [
  { icon: CopyIcon, title: "Copy payout ID" },

  { icon: CheckFillIcon, title: "Approve" },
  { icon: CircleCloseIcon, title: "Disapprove" },
  { icon: FlagIcon, title: "Flag as fraud", color: "red" },
];

const PayoutTable = ({ headcells, value, setSelected }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const handleChangePage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const dispatch = useDispatch();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const status = statutes[value].value;
  const { data } = useGetPayoutsQuery({ tenant_id: 1, status: status });

  const handleRequestSort = (o, property) => {
    setOrder(o);
    setOrderBy(property);
  };
  //   const filteredData = [...(data?.data?.data || [])].filter(
  //     (d) =>
  //       filterData(duration, d.createdAt) && d.status === statutes[value].value
  //   );
  const totalPages = Math.ceil(
    [...(data?.data?.data || [])].length / itemsPerPage
  );

  const sortedData = [...(data?.data || [])].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    Math.min(currentPage * itemsPerPage, [...(data?.data?.data || [])].length)
  );

  return (
    <Box>
      <CustomTable
        headcells={headcells}
        rows={sortedData}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        actionComponent={(row) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              sx={{
                p: "6px",
              }}
              onClick={() => {
                setSelected(row);
                dispatch(toggleOpen());
              }}
            >
              <EyeOpenIcon />
            </IconButton>
            <TableMenu items={items} />
          </Stack>
        )}
        count={[...(data?.data?.data || [])].length}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        setItemsPerPage={setItemsPerPage}
      />
    </Box>
  );
};

export default PayoutTable;
