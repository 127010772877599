import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import React from "react";
import { RxCaretSort } from "react-icons/rx";
import CustomCheckbox from "../../ui/CustomCheckbox";
import { BorderTop } from "@mui/icons-material";
import { color } from "chart.js/helpers";
import UpDownArrowIcon from "../../Icons/UpAndDownArrow";
import ChevronRightIcon from "../../Icons/ChevronRight";
import ChevronLeftIcon from "../../Icons/ChevronLeft";
import BackwardIcon from "../../Icons/Backward";
import ForwardIcon from "../../Icons/Forward";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headcells,
    fontSize,
    checkbox,
    allSelected,
    borderTopValue = false,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(property);
  };
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow
        sx={{ borderTop: borderTopValue ? "1px solid #15191FA6" : "none" }}
      >
        {checkbox && (
          <TableCell
            sx={{
              paddingTop: "22px !important",
              paddingBottom: "22px !important",
            }}
          >
            <CustomCheckbox
              variant="contained"
              checked={allSelected}
              onChange={onSelectAllClick}
              background="bg2"
            />
          </TableCell>
        )}
        {headcells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              paddingTop: "22px !important",
              paddingBottom: "22px !important",
              ...(headCell.padding && {
                padding: headCell.padding + " !important",
              }),
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                color: (theme) => theme.palette.color.secondary + " !important",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  ...(fontSize && { fontSize: fontSize + " !important" }),
                  color: (theme) =>
                    theme.palette.color.secondary + " !important",
                }}
              >
                {headCell.label2 || headCell.label}
              </Typography>
              {headCell.label && (
                <UpDownArrowIcon
                  onClick={createSortHandler(headCell.id)}
                  stroke={
                    theme.palette.color[
                      orderBy === headCell.id ? "active" : "secondary"
                    ]
                  }
                />
              )}
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CustomTable = ({
  headcells,
  rows,
  onRequestSort,
  order,
  orderBy,
  onRowClick,
  fontSize,
  checkbox,
  selected = [],
  setSelected,
  actionComponent,
  borderTopValue = false,
  totalPages,
  currentPage,
  handleChangePage,
  minWidth = 750,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    if (onRequestSort) onRequestSort(isAsc ? "desc" : "asc", property);
  };

  const handleSelectAllClick = () => {
    if (selected.length !== rows.length) {
      const newSelected = rows.map((n, index) => index);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () =>
      orderBy
        ? stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, rows]
  );

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <>
      <TableContainer
        sx={{
          mb: 3,
        }}
      >
        <Table
          sx={{
            minWidth: minWidth,
            maxWidth: "100%",
            overflowX: "auto",
            color: (theme) => theme.palette.color.secondary,
          }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headcells={headcells}
            fontSize={fontSize}
            checkbox={checkbox}
            allSelected={selected.length === rows.length}
            borderTopValue={borderTopValue}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(index);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={() => onRowClick && onRowClick(row)}
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{
                    cursor: "pointer",
                    "&:hover .action": { opacity: 1 },
                    color: (theme) =>
                      theme.palette.color.secondary + " !important",
                  }}
                >
                  {checkbox && (
                    <TableCell
                      sx={{
                        color: (theme) =>
                          theme.palette.color.secondary + " !important",
                      }}
                    >
                      <CustomCheckbox
                        variant="contained"
                        checked={isItemSelected}
                        onChange={() => handleClick(index)}
                        background="bg2"
                      />
                    </TableCell>
                  )}
                  {headcells.map((headcell) => (
                    <TableCell
                      key={headcell.id}
                      align={headcell.align || "left"}
                      sx={{
                        ...(fontSize && {
                          fontSize: fontSize + " !important",

                          color: (theme) =>
                            theme.palette.color.secondary + " !important",
                        }),
                        color: (theme) => theme.palette.color.secondary,
                        py: "18px !important",
                        ...(headcell.padding && {
                          padding: headcell.padding + " !important",
                        }),
                      }}
                    >
                      <Stack
                        sx={{
                          color: (theme) =>
                            theme.palette.color.secondary + " !important",
                        }}
                      >
                        {" "}
                        {headcell.getCell(row, index)}
                      </Stack>
                    </TableCell>
                  ))}
                  {actionComponent && (
                    <TableCell align="right" className="action">
                      <Box
                        className="action"
                        sx={{
                          opacity: 0,
                        }}
                      >
                        {actionComponent(row)}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {rows.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={headcells.length}
                  align="left"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: (theme) =>
                      theme.palette.color.secondary + " !important",
                  }}
                >
                  <Typography variant="caption_500">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 0 && handleChangePage && (
        <Stack
          direction="row"
          justifyContent="end"
          mt={3}
          sx={{ px: "8px" }}
          spacing={1}
        >
          <IconButton
            sx={{
              background: (theme) => theme.palette.color.borderpure,
              border: "1px solid #212529",
              borderRadius: "8px",
              height: "32px",
              width: "32px",
            }}
            onClick={() => {
              handleChangePage(1);
            }}
          >
            <BackwardIcon />
          </IconButton>
          <IconButton
            sx={{
              background: (theme) => theme.palette.color.borderpure,
              border: "1px solid #212529",
              borderRadius: "8px",
              height: "32px",
              width: "32px",
            }}
            onClick={() => {
              handleChangePage(currentPage - 1);
            }}
          >
            <ChevronLeftIcon width="13" height="13" />
          </IconButton>
          {Array.from({ length: totalPages }, (_, index) => (
            <IconButton
              key={index}
              onClick={() => handleChangePage(index + 1)}
              sx={{
                background: (theme) =>
                  theme.palette.color[
                    currentPage === index + 1 ? "active" : "borderpure"
                  ],
                border: "1px solid #212529",
                borderRadius: "8px",
                height: "32px",
                width: "32px",
                fontSize: "14px",
                color: (theme) =>
                  theme.palette.color[
                    currentPage === index + 1 ? "primary" : "secondary"
                  ],
                ":hover": {
                  background: (theme) =>
                    theme.palette.color[
                      currentPage === index + 1 ? "active" : "borderpure"
                    ],
                },
              }}
            >
              {index + 1}
            </IconButton>
          ))}
          <IconButton
            sx={{
              background: (theme) => theme.palette.color.borderpure,
              border: "1px solid #212529",
              borderRadius: "8px",
              height: "32px",
              width: "32px",
            }}
            onClick={() => {
              handleChangePage(currentPage + 1);
            }}
          >
            <ChevronRightIcon width="13" height="13" />
          </IconButton>
          <IconButton
            sx={{
              background: (theme) => theme.palette.color.borderpure,
              border: "1px solid #212529",
              borderRadius: "8px",
              height: "32px",
              width: "32px",
            }}
            onClick={() => {
              handleChangePage(totalPages);
            }}
          >
            <ForwardIcon />
          </IconButton>
        </Stack>
      )}
    </>
  );
};

export default CustomTable;
