import { useTheme } from "@mui/material";
import React from "react";

const MetaTraderIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_627_1705)">
        <path
          d="M9.22297 18.499C8.61297 18.376 8.04797 18.081 7.59197 17.649C7.50097 17.576 7.40397 17.51 7.30197 17.452C7.50097 17.333 7.61297 17.268 7.72297 17.201C8.20997 16.896 8.60697 16.514 8.74297 15.922C8.98997 14.858 8.15097 13.984 7.07797 14.205C6.75597 14.272 6.45297 14.435 6.14597 14.562C6.03397 14.618 5.92697 14.681 5.82297 14.75C5.62497 13.89 5.63097 13.084 6.03697 12.332C6.90897 10.725 8.40497 10.149 10.036 9.81501L10.085 9.88401C9.90697 10.032 9.74097 10.196 9.58897 10.373C9.28697 10.782 9.01697 11.214 8.72897 11.633C8.66197 11.745 8.57797 11.845 8.47997 11.931C8.18197 12.156 7.88397 12.376 7.66497 12.764L8.47997 12.565V12.935C8.47997 14.056 8.90997 14.97 9.72397 15.714C9.91297 15.909 10.091 16.115 10.256 16.332L9.97097 16.577C10.409 16.458 10.542 16.833 10.772 17.053C11.054 17.327 11.355 17.577 11.663 17.848C11.207 18.216 10.64 18.344 10.083 18.498L9.22297 18.499ZM18.5 16.276C18.207 17.051 17.675 17.355 16.881 17.165C16.194 17.001 15.696 16.59 15.505 15.872C15.32 15.174 15.767 14.541 16.475 14.444C17.403 14.332 18.269 14.94 18.5 15.865V16.276ZM12.257 5.51001C13.324 5.85601 13.603 7.36401 12.921 8.24701C12.438 8.87201 11.666 8.89101 11.14 8.30201C10.48 7.56401 10.558 5.94801 11.743 5.51201L12.257 5.51001Z"
          fill={theme.palette.color.primary}
        />
        <path
          d="M5.5 15.867C5.879 14.847 6.587 14.344 7.497 14.45C7.85 14.477 8.17 14.674 8.356 14.982C8.542 15.29 8.573 15.671 8.438 16.006C8.118 16.833 7.271 17.314 6.414 17.153C5.916 17.041 5.633 16.704 5.5 16.218V15.867ZM14.256 9.30696L14.063 9.94897C13.513 9.77197 13 9.57997 12.472 9.44297C11.944 9.30697 11.434 9.44296 10.919 9.56196C10.599 9.63596 10.266 9.65897 9.919 9.70597L9.873 9.33496C9.757 9.77996 9.393 9.72997 9.084 9.81097C8.708 9.90997 8.346 10.061 7.977 10.19C7.481 8.89396 8.887 6.84796 10.463 6.61896C10.491 6.94296 10.501 7.26896 10.552 7.58896C10.621 8.05896 10.884 8.47696 11.276 8.73396C11.883 9.14496 12.557 9.03496 13.032 8.45596C13.368 8.04496 13.47 7.55596 13.498 7.04096C13.505 6.89496 13.498 6.74996 13.498 6.56396C13.591 6.58796 13.671 6.59996 13.744 6.62796C15.171 7.15796 15.899 8.23896 16.046 9.74696C16.136 10.655 16.022 11.573 15.714 12.429C15.683 12.51 15.649 12.59 15.611 12.667C15.601 12.691 15.576 12.708 15.537 12.752C15.489 12.514 15.482 12.284 15.397 12.09C15.193 11.623 14.948 11.175 14.728 10.714C14.652 10.556 14.592 10.391 14.549 10.221C14.477 9.91496 14.425 9.60296 14.365 9.29296L14.256 9.30696Z"
          fill={theme.palette.color.primary}
        />
        <path
          d="M18.161 14.681C17.778 14.525 17.42 14.336 17.039 14.234C16.425 14.068 15.892 14.275 15.464 14.746C15.175 15.065 15.149 15.746 15.393 16.239C15.603 16.636 15.921 16.962 16.308 17.179C16.424 17.254 16.552 17.323 16.707 17.417C15.906 18.172 15.011 18.58 13.936 18.429C12.538 18.231 11.474 17.449 10.576 16.386C10.548 16.348 10.523 16.309 10.5 16.267C10.849 16.354 11.174 16.472 11.502 16.505C11.895 16.542 12.296 16.498 12.694 16.505C12.849 16.505 13.003 16.523 13.153 16.558C13.42 16.624 13.683 16.713 13.948 16.796L13.98 16.714L13.54 16.238C13.656 16.164 13.737 16.107 13.823 16.052C14.713 15.486 15.242 14.674 15.461 13.627C15.527 13.311 15.694 13.017 15.821 12.698L16.16 12.83C15.81 12.523 16.077 12.22 16.148 11.91C16.238 11.518 16.302 11.119 16.381 10.72C17.801 11.292 18.804 13.042 18.161 14.681Z"
          fill={theme.palette.color.primary}
        />
        <path
          d="M11.977 16.169C11.034 16.16 10.145 15.721 9.554 14.972C8.962 14.223 8.729 13.242 8.919 12.299C9.245 10.679 10.718 9.57498 12.329 9.74398C13.939 9.91398 15.162 11.302 15.158 12.955C15.154 13.813 14.817 14.634 14.22 15.236C13.623 15.839 12.816 16.175 11.977 16.169ZM9.507 12.935C9.498 13.964 10.096 14.898 11.024 15.301C12.203 15.812 13.569 15.327 14.183 14.178C14.797 13.029 14.458 11.592 13.399 10.855C12.992 10.572 12.511 10.419 12.019 10.414C11.012 10.405 10.098 11.016 9.705 11.963C9.577 12.271 9.51 12.601 9.507 12.935Z"
          fill={theme.palette.color.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_627_1705">
          <rect width="13" height="13" transform="translate(5.5 5.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MetaTraderIcon;
