import { Box, Grid, Stack, styled, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import InfoIcon2 from '../../Icons/Info2'
import { FiArrowUpRight } from 'react-icons/fi'
import ReactApexChart from 'react-apexcharts'
import RoundFormSelect from '../../ui/RoundFormSelect'

const InsightsAndNotifications = () => {

  const infoData = [
    {
      title: "Total Sales",
      tooltip: "Total Sales",
      value: "$34,567",
      percent: "3.5%"
    },
    {
      title: "Total Revenue",
      tooltip: "Total Revenue",
      value: "$17,000",
      percent: "1.5%",
    },
    {
      title: "Active Transactions",
      tooltip: "Active Transactions",
      value: "125",
      percent: "25",
    },
  ]

  const infoData2 = [
    {
      title: "Sales Spike Detected",
      tooltip: "Sales Spike Detected",
      value: "Unexpected sales surge detected.",
    },
    {
      title: "Trend Deviation Detected",
      tooltip: "Trend Deviation Detected",
      value: "Deviation from usual sales trends observed."
    },
    {
      title: "System Warning Resolved",
      tooltip: "System Warning Resolved",
      value: "Previous system warning has been successfully resolved."
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" my={2}>Real-time dashboards with up-to-date sales figures and notifications</Typography>
      <Grid container spacing={3}>
        {infoData.map((item, index) => (
          <Grid item xs={12} sm={4} md={4} key={index}>
            <InfoBox
              item={item}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <SalesOverTimeChart />
      <Typography variant="h4" mt={3}>Alerts for important events, such as sales spikes or trend deviations</Typography>
      <Grid container spacing={3} mt={0.5}>
        {infoData2.map((item, index) => (
          <Grid item xs={12} sm={4} md={4} key={index}>
            <InfoBox
              item={item}
              fontSize={"18px"}
              sx={{
                background: (theme) => theme.palette.color.bg2,
                height: "156px",
              }}
            />
          </Grid>
        ))}
      </Grid>
      <HistoricalPerformanceTrends />
    </Box>
  )
}

export default InsightsAndNotifications


const InfoBox = ({ item, icon, sx, fontSize }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "24px",
        width: "100%",
        height: "164px",
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",

          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="caption">{item.title}</Typography>
          <Tooltip title={item.tooltip} placement="top">
            {icon ? icon : <InfoIcon2 fill="none" />}
          </Tooltip>
        </Stack>
      </Box>
      <Typography variant="h1" fontSize={fontSize ? fontSize : isSmall ? "20px" : "28px"} color={item.color ? item.color : (theme) => theme.palette.color.primary } mt={3}>{item.value}</Typography>
      {item.percent && (
      <Stack
        direction="row"
        spacing={1}
        mt={1}
        sx={{
          alignItems: "center",
          color: (theme) => theme.palette.color.green,
        }}
      >
        <FiArrowUpRight size={16} />
        <Typography variant="caption">{item.percent}</Typography>
      </Stack>
      )}
    </Box>
  );
};

const SalesOverTimeChart = () => {
  const series = [
    {
      name: 'Sales Figures',
      type: 'area',
      data: [3700, 3500, 5500, 2600, 1000, 4500, 1300, 600, 2000, 2800, 4000, 2400],
    }
  ];

  const options = {
    chart: {
      height: 350,
      type: 'area',
      background: 'transparent',
      toolbar: {
        show: false,
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
    colors: ['#5F55EE'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#8A96A6",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 7000,
      tickAmount: 7,
      title: {
        text: 'Sales Figures',
        style: {
          color: "#8A96A6",
        },
      },
      labels: {
        formatter: (val) => `$${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    tooltip: {
      x: {
        format: 'MMM yyyy'
      },
      y: {
        formatter: (val) => `$${val}`,
      },
      theme: 'dark',
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    markers: {
      size: 0,
    },
  };

  return (
    <Box sx={{
      background: (theme) => theme.palette.color.bg5,
      border: '1px solid',
      borderColor: (theme) => theme.palette.color.strockborder,
      p: '20px',
      borderRadius: '12px',
      mt: 2,
    }}>
      <Typography variant="h5">
        Sales Trends Over Time
      </Typography>
      <ReactApexChart options={options} series={series} type="area" height={350} />
    </Box>
  );
}

const HistoricalPerformanceTrends = () => {
  const data = [
    {
      product: 'Product A',
      sales: [12000, 15000, 14000, 16000, 13000, 17000, 15000, 14000],
    },
    {
      product: 'Product B',
      sales: [17000, 18000, 19000, 17000, 15000, 14000, 13000, 12000],
    },
    {
      product: 'Product C',
      sales: [13000, 12000, 11000, 10000, 9000, 11000, 12000, 13000],
    },
  ];

  const getColorIntensity = (sales) => {
    const maxSales = 20000;
    const intensity = Math.floor((sales / maxSales) * 255);
    return `rgba(95, 85, 238, ${intensity / 255})`;
  };

  const CustomTooltip = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '0px',
    borderRadius: '8px',
    boxShadow: theme.shadows[4],
  }));

  const boxSize = 108;

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        my: 2,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: '#fff', fontWeight: 500 }}
        >
          Historical Performance Trends
        </Typography>
        <RoundFormSelect
          options={[{ value: "weekly", label: "Weekly" }]}
          defaultValue="weekly"
        />
      </Box>

      <Grid container spacing={0.2}>
        <Grid item xs={1}>
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                height: `${boxSize}px`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#8A96A6',
              }}
            >
              {item.product}
            </Box>
          ))}
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={0.2}>
            {data.map((item, rowIndex) => (
              <Grid item xs={12} key={rowIndex}>
                <Grid container spacing={0.2} sx={{ gap: '0' }}>
                  {item.sales.map((sales, colIndex) => (
                    <Grid item xs={1.5} key={colIndex}>
                      <Tooltip
                        title={
                          <CustomTooltip>
                            <Typography variant="body2">
                              {item.product}
                            </Typography>
                            <Typography variant="body2">
                              Sales Revenue: ${sales}
                            </Typography>
                            <Typography variant="body2">
                              Time: Week {colIndex + 1}
                            </Typography>
                          </CustomTooltip>
                        }
                        arrow
                      >
                        <Box
                          sx={{
                            width: `${boxSize}px`,
                            height: `${boxSize}px`,
                            backgroundColor: getColorIntensity(sales),
                            borderRadius: '8px',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Grid container spacing={0.2}>
                {Array.from({ length: 8 }).map((_, index) => (
                  <Grid
                    item
                    xs={1.5}
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: `${boxSize}px`,
                    }}
                  >
                    <Typography variant="body2" sx={{ color: '#8A96A6' }}>
                      Week {index + 1}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};