import { useTheme } from "@emotion/react";
import * as React from "react";

const ProductsIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;

  return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M11.7942 21L4 16.5V7.49997L11.7942 3L19.5885 7.49997V16.5L11.7942 21Z"
      stroke={strokeColor}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M4 7.5L11.7942 12L19.5885 7.5"
      stroke={strokeColor}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M11.7942 12V21"
      stroke={strokeColor}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M14.5601 4.59668L6.76587 9.09669V13.5967L9.02832 14.9029V10.4029L16.8226 5.9029"
      stroke={strokeColor}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
  </svg>
  );
};

export default ProductsIcon;