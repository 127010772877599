import ReactApexChart from 'react-apexcharts';
import { Box, Stack, Typography } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';

const BreachedAccountTotal = () => {
    const series = [
        {
            name: 'Phase 1',
            type: 'line',
            data: [16, 15, 22, 7, 8, 11, 7],
        },
        {
            name: 'Phase 2',
            type: 'line',
            data: [11, 9, 14, 11, 11, 8, 10],
        },
        {
            name: 'Live Accounts',
            type: 'line',
            data: [19, 6, 12, 18, 2, 19, 17],
        },
    ];

    const options = {
        chart: {
            height: 350,
            type: 'line',
            background: 'transparent',
            toolbar: {
                show: false,
            },
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
            },
        },
        colors: ['#5F55EE', '#5EEDF8', '#F55F82'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 3,
        },
        xaxis: {
            categories: ['10-20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80'],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        yaxis: {
            min: 0,
            max: 24,
            title: {
                text: "Time To Breached",
                style: {
                    color: '#8A96A6',
                },
            },
            labels: {
                formatter: (val) => Math.round(val),
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            y: {
                formatter: (val) => Math.round(val),
            },
        },
        theme: {
            mode: 'dark',
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        markers: {
            size: 0,
        },
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
            p: 2,
            mt: 3,
        }}>
            <Stack direction="row" pl={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                Breached Account Total (All Rules)
                </Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ backgroundColor: "#5F55EE", width: 14, height: 14, borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Phase 1</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ backgroundColor: "#5EEDF8", width: 14, height: 14, borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Phase 2</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ backgroundColor: "#F55F82", width: 14, height: 14, borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Live Accounts</Typography>
                    </Box>
                    <RoundFormSelect
                        options={[{ value: "age", label: "Age" }]}
                        defaultValue="age"
                    />
                </Stack>
            </Stack>
            <ReactApexChart options={options} series={series} type="line" height={350} />
        </Box>
    );
};

export default BreachedAccountTotal;