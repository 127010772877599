import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper, Grid, Stack, alpha, useMediaQuery, Button } from "@mui/material";
import ExportSection from "../../../components/ui/ExportSection";
import CalendarPopup from "../../../components/ui/CalendarPopup";
import DisplayColumns from "../../../components/ui/DisplayColumns";
import dayjs from "dayjs";
import FilterPopup from "../../../components/ui/FilterPopup";
import SmallSearchBar from "../../../components/Affiliates/smallSearchBar";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import ReviewFlaggedTraders from "../../../components/Trading-accounts/ReviewFlaggedTraders";
import { HiOutlineRefresh } from "react-icons/hi";
import CopyTraderCard from "../../../components/Trading-accounts/CopyTraderCard";
import RiskManagementInfoCard from "../../../components/Risk-Management/RiskManagementInfoCard";

const Items = [
  {
    title: "Total scanned",
    tooltip: "Active accounts that are doing a evaluation, funded are on trial",
    value: "1841",
    key: "active",
  },
  {
    title: "Total HFT",
    tooltip: "All inactive accounts",
    value: "340",
    key: "inactive",
  },
  {
    title: "Total passed",
    tooltip: "All inactive accounts",
    value: "120",
    key: "inactive",
  },
];

// const data = [
//   {
//     name: "Kristin Watson",
//     customerId: "123456",
//     email: "danghoang87hl@gmail.com",
//     validationDate: "11:05 5 June, 2024",
//     account: "467328649",
//   },
//   {
//     name: "Marvin McKinney",
//     customerId: "69548215",
//     email: "thuhang.nute@gmail.com",
//     validationDate: "11:05 5 Apr, 2024",
//     account: "467328649",
//   },
//   {
//     name: "Darlene Robertson",
//     customerId: "32654817",
//     email: "manhhachkt08@gmail.com",
//     validationDate: "11:05 5 Apr, 2024",
//     account: "217328649",
//   },
//   {
//     name: "Leslie Alexander",
//     customerId: "65986412",
//     email: "binhan628@gmail.com",
//     validationDate: "11:05 5 Apr, 2024",
//     account: "107328649",
//   },
//   {
//     name: "Kristin Watson",
//     customerId: "123456",
//     email: "danghoang87hl@gmail.com",
//     validationDate: "11:05 25 May, 2024",
//     account: "107328649",
//   },
//   {
//     name: "Marvin McKinney",
//     customerId: "69548215",
//     email: "thuhang.nute@gmail.com",
//     validationDate: "11:05 25 May, 2024",
//     account: "107328649",
//   },
//   {
//     name: "Darlene Robertson",
//     customerId: "32654817",
//     email: "manhhachkt08@gmail.com",
//     validationDate: "11:05 31 May, 2024",
//     account: "217328649",
//   },
//   {
//     name: "Leslie Alexander",
//     customerId: "65986412",
//     email: "binhan628@gmail.com",
//     validationDate: "11:05 1 July, 2024",
//     account: "107328649",
//   },
//   {
//     name: "John Doe",
//     customerId: "789012",
//     email: "johndoe@example.com",
//     validationDate: "11:05 1 Mar, 2024",
//     account: "120000000",
//   },
//   {
//     name: "Jane Smith",
//     customerId: "890123",
//     email: "janesmith@example.com",
//     validationDate: "11:05 15 Apr, 2024",
//     account: "130000000",
//   },
//   {
//     name: "Emily Johnson",
//     customerId: "901234",
//     email: "emilyjohnson@example.com",
//     validationDate: "11:05 20 May, 2024",
//     account: "140000000",
//   },
//   {
//     name: "Michael Brown",
//     customerId: "912345",
//     email: "michaelbrown@example.com",
//     validationDate: "11:05 10 June, 2024",
//     account: "150000000",
//   },
//   {
//     name: "Jessica Williams",
//     customerId: "923456",
//     email: "jessicawilliams@example.com",
//     validationDate: "11:05 30 June, 2024",
//     account: "160000000",
//   },
//   {
//     name: "David Miller",
//     customerId: "934567",
//     email: "davidmiller@example.com",
//     validationDate: "11:05 5 July, 2024",
//     account: "170000000",
//   },
//   {
//     name: "Sarah Wilson",
//     customerId: "945678",
//     email: "sarahwilson@example.com",
//     validationDate: "11:05 15 July, 2024",
//     account: "180000000",
//   },
//   {
//     name: "Paul Martinez",
//     customerId: "956789",
//     email: "paulmartinez@example.com",
//     validationDate: "11:05 20 July, 2024",
//     account: "190000000",
//   },
// ];

const headcells = [
  {
    id: "name",
    label: "Name",
    default: true,
    getCell: (row) => row.name,
  },
  {
    id: "customerId",
    label: "Customer ID",
    default: true,
    getCell: (row) => row.customerId,
  },
  { id: "email", label: "Email", default: true, getCell: (row) => row.email },
  {
    id: "validationDate",
    label: "Violation date",
    default: true,
    getCell: (row) => row.validationDate,
  },

  {
    id: "action",
    label: "Action",
    default: true,
    getCell: (row) => (
      <RemoveRedEyeOutlined
        sx={{
          color: (theme) => theme.palette.color.secondary,
          cursor: "pointer",
        }}
      />
    ),
  },
];

const HFT = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // const [status, setStatus] = useState("");
  const [heads, setHeads] = React.useState(
    headcells.filter((cell) => cell.default).map((cell) => cell.id)
  );
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [rangeValue, setRangeValue] = useState([20, 37]);
  const [selectedPurchase, setSelectedPurchase] = useState([]);
  const [cols, setCols] = useState(headcells);
  // const [selectedCustomerids, setSelectedCustomerids] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  // const [name, setName] = useState("");

  // const parseRange = (rangeStr) => {
  //   const [min, max] = rangeStr.split(" - ").map(Number);
  //   return { min, max };
  // };

  // const filterData = () => {
  //   // return data.filter((row) => {
  //   //   if (startDate && dayjs(row.validationDate, 'HH:mm D MMM, YYYY').isBefore(startDate))
  //   //     return false;
  //   //   if (endDate && dayjs(row.validationDate, 'HH:mm D MMM, YYYY').isAfter(endDate)) return false;
  //   //   if (selectedAccounts.length > 0) {
  //   //     const accountMatch = selectedAccounts.some((range) => {
  //   //       const { min, max } = parseRange(range);
  //   //       return row.account >= min && row.account <= max;
  //   //     });
  //   //     if (!accountMatch) return false;
  //   //   }

  //   //   if (selectedCustomerids.length > 0) {
  //   //     const customerIds = selectedCustomerids.some((range) => {
  //   //       const { min, max } = parseRange(range);
  //   //       return row.customerId >= min && row.customerId <= max;
  //   //     });
  //   //     if (!customerIds) return false;
  //   //   }

  //   //   if (name && !row.name.toLowerCase().includes(name.toLowerCase()))
  //   //     return false;

  //   //   return true;
  //   // });
  //   return data;
  // };

  return (
    <Paper
      sx={{
        px: "12px",
        py: "24px",
        width: "100%",
      }}
    >

      <Stack
        direction={isSmallScreen ? "column" : "row"}
        justifyContent={isSmallScreen ? "flex-start" : "space-between"}
        alignItems={isSmallScreen ? "flex-start" : "center"}
        gap={2}
      >
        <Box
          sx={{
            ml: "15px",
            display: "flex",
            alignItems: "start",
          }}
        >
          <Typography variant="subtitle1" mb={2}>
            High Frequency
          </Typography>
        </Box>
        <Stack
          direction="row"
          spacing={isSmallScreen ? 1 : 2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            mb: "24px",
            fontSize: "11.5px",
            pr: "24px",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <SmallSearchBar />
          <ExportSection />
          <CalendarPopup
            mainStartDate={startDate}
            mainEndDate={endDate}
            setMainStartDate={setStartDate}
            setMainEndDate={setEndDate}
          />
          <DisplayColumns
            columns={cols}
            setColumns={setCols}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
          <FilterPopup
            rangeFilter={{
              label: "Countries",
              ariaLabel: "Countries",
              value: rangeValue,
              onChange: setRangeValue,
            }}
            accordions={[
              {
                title: "Accounts",
                defaultExpanded: true,
                items: ["0 - 10", "10 - 30", "30 - 50"],
                selectedItems: selectedAccounts,
                onChange: setSelectedAccounts,
              },
              {
                title: "Purchase",
                defaultExpanded: true,
                items: ["0 - 10", "10 - 30", "30 - 50"],
                selectedItems: selectedPurchase,
                onChange: setSelectedPurchase,
              },
            ]}
          />
          <Button size='small' 
            sx={{ 
              height: "35px", 
              borderRadius: "32px", 
              bgcolor: (theme) => theme.palette.color.bg5, 
              color: "white", 
              border: (theme) => `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`, 
              ":hover": { background: (theme) => theme.palette.color.bg5 }
              }}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography variant="caption">Last Scan: 1m ago </Typography>
              <Typography variant="caption" pt={1}><HiOutlineRefresh /></Typography>
            </Stack>
          </Button>
        </Stack>
      </Stack>


      <Grid container spacing={1}>
        {Items.map((item, index) => (
          <Grid
            item
            xs={index === 2 ? 12 : 6}
            md={4}
            lg={4}
            spacing={2}
            key={index}
          >
            <RiskManagementInfoCard item={item} />
          </Grid>
        ))}
      </Grid>

      <Stack direction="row" gap={5} mt={3} sx={{ overflowX: 'auto' }}>
      {/* Large Screen Grid */}
      <Grid container spacing={2} sx={{ display: { xs: 'block', sm: 'flex' } }}>
        {[...Array(3)].map((_, index) => (
          <Grid key={index} item xs={12} md={12} lg={12}>
            {/* <HFTCard /> */}
            <CopyTraderCard value={index === 0 ? "80" : index === 1 ? "65" : "23"} />
          </Grid>
        ))}
      </Grid>
    </Stack>

    <ReviewFlaggedTraders />
      {/* <CustomTable headcells={cols} rows={filterData()} /> */}
    </Paper>
  );
};

export default HFT;
