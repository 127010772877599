import { useTheme } from "@mui/material";

export default function EmojiIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 12C19.25 12.9521 19.0625 13.8948 18.6981 14.7745C18.3338 15.6541 17.7997 16.4533 17.1265 17.1265C16.4533 17.7997 15.6541 18.3338 14.7745 18.6981C13.8948 19.0625 12.9521 19.25 12 19.25C11.0479 19.25 10.1052 19.0625 9.22554 18.6981C8.34593 18.3338 7.5467 17.7997 6.87348 17.1265C6.20025 16.4533 5.66622 15.6541 5.30187 14.7745C4.93753 13.8948 4.75 12.9521 4.75 12C4.75 10.0772 5.51384 8.23311 6.87348 6.87348C8.23311 5.51384 10.0772 4.75 12 4.75C13.9228 4.75 15.7669 5.51384 17.1265 6.87348C18.4862 8.23311 19.25 10.0772 19.25 12Z"
        stroke={stroke || theme.palette.color.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 13.75C9.75 13.75 10 15.25 12 15.25C14 15.25 14.25 13.75 14.25 13.75"
        stroke={stroke || theme.palette.color.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 10C10.5 10.1326 10.4473 10.2598 10.3536 10.3536C10.2598 10.4473 10.1326 10.5 10 10.5C9.86739 10.5 9.74021 10.4473 9.64645 10.3536C9.55268 10.2598 9.5 10.1326 9.5 10C9.5 9.86739 9.55268 9.74021 9.64645 9.64645C9.74021 9.55268 9.86739 9.5 10 9.5C10.1326 9.5 10.2598 9.55268 10.3536 9.64645C10.4473 9.74021 10.5 9.86739 10.5 10ZM14.5 10C14.5 10.1326 14.4473 10.2598 14.3536 10.3536C14.2598 10.4473 14.1326 10.5 14 10.5C13.8674 10.5 13.7402 10.4473 13.6464 10.3536C13.5527 10.2598 13.5 10.1326 13.5 10C13.5 9.86739 13.5527 9.74021 13.6464 9.64645C13.7402 9.55268 13.8674 9.5 14 9.5C14.1326 9.5 14.2598 9.55268 14.3536 9.64645C14.4473 9.74021 14.5 9.86739 14.5 10Z"
        stroke={stroke || theme.palette.color.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
