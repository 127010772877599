import React from 'react';
import { Box, IconButton, Paper, Stack, Typography, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormInput from '../../../ui/FormInput';
import SendIcon from '../../../Icons/Send';

const iconStyle = {
    color: (theme) => theme.palette.color.secondary,
    fontSize: '22px',
};

const messages = [
    { text: 'Hi there\nIs everything going well?', time: '11:53', avatar: '/images/jens.avif', isSender: true },
    { text: 'Hi there\nIs everything going well?', time: '11:53', avatar: '/images/person.avif', isSender: false },
    { text: 'Hi there\nIs everything going well?', time: '11:54', avatar: '/images/jens.avif', isSender: true },
    { text: 'Hi there\nIs everything going well?', time: '11:54', avatar: '/images/person.avif', isSender: false },
    { text: 'Hi there\nIs everything going well?', time: '11:54', avatar: '/images/jens.avif', isSender: true },
    { text: 'Hi there\nIs everything going well?', time: '11:54', avatar: '/images/person.avif', isSender: false },
];

const MessageDetail = ({ message, onClose }) => {
    if (!message) {
        return null;
    }

    return (
        <Paper
            sx={{
                width: '100%',
                height: '90vh',
                background: (theme) => theme.palette.color.bg5,
                border: (theme) => `1px solid ${theme.palette.color.strockborder}`,
                borderRadius: '24px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: '13px 24px', borderBottom: '1px solid', borderColor: 'divider' }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <Box>
                        <Typography variant="h6">{message.name}</Typography>
                        <Stack direction="row" alignItems="center" mt={1}>
                            <Box
                                sx={{
                                    width: '8px',
                                    height: '8px',
                                    marginRight: '4px',
                                    borderRadius: '50%',
                                    background: (theme) => theme.palette.color.green,
                                }}
                            ></Box>
                            <Typography variant="body2" color="text.secondary">
                                {message.isOnline ? 'Online' : 'Offline'}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
                <IconButton onClick={onClose}>
                    <CloseIcon sx={iconStyle} />
                </IconButton>
            </Stack>
            <Box sx={{ flex: 1, overflowY: 'auto', padding: '24px' }}>
                {messages.map((msg, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            mb: 2,
                            justifyContent: msg.isSender ? 'flex-start' : 'flex-end',
                        }}
                    >
                        {msg.isSender && (
                            <Avatar alt="Jens" src={msg.avatar} sx={{ width: 30, height: 30, mr: 2, bgcolor: (theme) => theme.palette.color.active, }} />
                        )}
                        <Box sx={{ width: "50%", alignItems: "start" }}>
                            <Box
                                sx={{
                                    backgroundColor: (theme) => theme.palette.color.strockborder,
                                    borderRadius: '16px',
                                    padding: '15px 12px',
                                    maxWidth: '100%',
                                }}
                            >
                                <Typography variant="body1" sx={{ color: (theme) => theme.palette.color.primary, whiteSpace: 'pre-line', lineHeight: "20px", fontSize: "14px" }}>{msg.text}</Typography>
                            </Box>
                            <Typography variant="caption" sx={{ display: 'block', textAlign: 'left' }}>
                                {msg.time}
                            </Typography>
                        </Box>
                        {!msg.isSender && (
                            <Avatar alt="User Avatar" src={msg.avatar} sx={{ width: 30, height: 30, ml: 2 }} />
                        )}
                    </Box>
                ))}
            </Box>
            <Box sx={{ bgcolor: (theme) => theme.palette.color.borderpure, borderTopRightRadius: "16px", borderTopLeftRadius: "16px", border: "1px solid", borderColor: (theme)=>theme.palette.color.strockborder, display: 'flex', alignItems: 'center' }}>
                <FormInput
                    type="text"
                    placeholder="Type a message..."
                    sx={{
                        bgcolor: (theme) => theme.palette.color.borderpure,
                        ":focus-within": {
                            border: (theme) => `1px solid ${theme.palette.color.borderpure}`,
                        },
                    }}
                />
                <IconButton sx={{
                    backgroundColor: (theme) => theme.palette.color.active,
                    color: '#fff',
                    margin: "15px",
                    borderRadius: '50%',
                    padding: '8px',
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette.color.active,
                    },
                }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1084 5.99438C11.1088 6.14712 11.0683 6.2972 10.9913 6.42908C10.9142 6.56097 10.8033 6.66987 10.6701 6.74453L1.67256 11.8889C1.54344 11.9621 1.39767 12.0009 1.24926 12.0015C1.11251 12.0007 0.977922 11.9673 0.856731 11.9039C0.735539 11.8406 0.631259 11.7492 0.552596 11.6373C0.473933 11.5255 0.423169 11.3964 0.404543 11.2609C0.385917 11.1255 0.399969 10.9875 0.445525 10.8586L1.89224 6.57467C1.90638 6.53279 1.93312 6.4963 1.96881 6.47021C2.00449 6.44412 2.04737 6.4297 2.09157 6.42893H5.96448C6.02324 6.42906 6.08141 6.4171 6.13535 6.39381C6.1893 6.37051 6.23789 6.33637 6.27809 6.29351C6.31829 6.25065 6.34924 6.19998 6.36903 6.14465C6.38883 6.08932 6.39703 6.03051 6.39314 5.97187C6.38342 5.86165 6.33242 5.75916 6.25035 5.68494C6.16827 5.61072 6.06119 5.57024 5.95055 5.57161H2.09264C2.0478 5.57162 2.00408 5.55756 1.96765 5.53141C1.93122 5.50527 1.90391 5.46835 1.88956 5.42587L0.442846 1.14252C0.385264 0.978338 0.378997 0.800525 0.424878 0.632699C0.470759 0.464873 0.566616 0.314979 0.699715 0.202929C0.832815 0.0908787 0.996856 0.0219769 1.17005 0.0053767C1.34324 -0.0112235 1.51738 0.0252635 1.66934 0.109991L10.6711 5.24798C10.8036 5.32246 10.914 5.43083 10.9908 5.56199C11.0676 5.69315 11.1082 5.84237 11.1084 5.99438Z" fill="#CAD0DB" />
                    </svg>
                </IconButton>
            </Box>
        </Paper>
    );
};

export default MessageDetail;