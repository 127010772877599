import { useTheme } from "@mui/material";

export default function SmallTreeIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="14"
      height="30"
      viewBox="0 0 14 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1.00031V23.0003C1 26.314 3.68629 29.0003 7 29.0003H13"
        stroke={stroke || theme.palette.color.lightgray}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
