import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Typography } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';

const DailyTradingActivitiesPie = () => {
  const series = [25, 15, 10, 12, 8, 30];
  const labels = ['USA', 'UK', 'Canada', 'Germany', 'France', 'Others'];
  const colors = ['#5FE3EA', '#8176FF', '#FF9066', '#F7E6AD', '#ED55D4', '#4D5E80'];

  const options = {
    chart: {
      type: 'donut',
      background: 'transparent',
    },
    colors: colors,
    labels: labels,
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: false,
        donut: {
          size: '65%',
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: false,
            },
            total: {
              show: true,
              label: 'Win Ratio',
              formatter: function (w) {
                return 'USA (18-25)';
              },
              color: '#5FE3EA',
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
    },
    stroke: {
      width: 7,
      colors: ['#000000'],
    },
    theme: {
      mode: 'dark',
    },
  };

  return (
    <Box sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        p: '10px',
        borderRadius: '16px',
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Daily Trading Activities</Typography>
        <RoundFormSelect
          options={[{ value: "country", label: "Country Wise" }]}
          defaultValue="country"
        />
      </Box>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={350}
      />
    </Box>
  );
};

export default DailyTradingActivitiesPie;