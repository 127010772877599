import React from 'react';
import { Box, Grid, Typography, Stack, Avatar } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import InfoIcon2 from '../../components/Icons/Info2';
import RoundFormSelect from '../../components/ui/RoundFormSelect';
import CustomTable from '../../components/Firm/Orders/Table';

const GeographicStatistics = () => {
  const infoBoxData = [
    { title: "Clicks", value: "1,200", icon: <InfoIcon2 /> },
    { title: "Conversions", value: "512", icon: <InfoIcon2 /> },
    { title: "Revenue", value: "$27,000", icon: <InfoIcon2 /> },
  ];

  const chartSeries = [
    { name: 'Region A', data: [3500, 2800, 2000, 900, 2400], type: 'column' },
    { name: 'Region B', data: [1700, 2200, 1600, 2400, 2200], type: 'column' },
    { name: 'Region C', data: [2500, 2000, 2300, 1700, 1700], type: 'column' },
    { name: 'Click', data: [3400, 3200, 2000, 4000, 3400], type: 'line' },
    { name: 'Conversions', data: [2700, 3900, 3200, 2600, 3100], type: 'line' },
  ];

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: { show: false },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 2,
      },
    },
    dataLabels: { enabled: false },
    stroke: {
      width: [1, 1, 1, 3, 3],
      curve: 'straight',
      colors: ['#5EEDF8', '#FFC452', '#5F55EE', '#F55F82', '#2FE0A2'],
    },
    xaxis: {
      categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
      labels: { style: { colors: '#8A96A6' } },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      title: { text: '' },
      labels: { style: { colors: '#8A96A6' } },
    },
    tooltip: { enabled: false, },
    legend: {
      show: false,
    },
    colors: ['#5EEDF8', '#FFA500', '#5F55EE', '#FF69B4', '#2FE0A2'],
    fill: {
      type: 'gradient',
      opacity: [0.15, 0.15, 0.15, 1, 1],
      gradient: {
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: [0.38, 0.38, 0.38, 1, 1],
        opacityTo: [0.05, 0.05, 0.05, 0.9, 0.9],
        shade: 'light',
        type: 'vertical',
        stops: [0, 100],
      },
    },
    markers: {
      size: 2,
      colors: undefined,
      strokeWidth: 0,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "square",
      radius: 5,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    grid: { show: false },
  };

  const tableData = [
    { country: "Switzerland", countryFlag: "/images/country-icons/chf.svg", clicks: "25,000", conversions: "1,350", revenue: "$ 50,000", conversionRate: "5.40%", revenuePerClick: "2.02" },
    { country: "Switzerland", countryFlag: "/images/country-icons/chf.svg", clicks: "12,000", conversions: "835", revenue: "$ 65,000", conversionRate: "6.96%", revenuePerClick: "1.9" },
    { country: "USA", countryFlag: "/images/country-icons/usd.svg", clicks: "16,800", conversions: "721", revenue: "$ 85,005", conversionRate: "4.29%", revenuePerClick: "2.1" },
    { country: "Austria", countryFlag: "/images/country-icons/aus.svg", clicks: "25,000", conversions: "525", revenue: "$ 20,000", conversionRate: "2.10%", revenuePerClick: "3.0" },
    { country: "Switzerland", countryFlag: "/images/country-icons/chf.svg", clicks: "17,202", conversions: "265", revenue: "$ 50,000", conversionRate: "1.54%", revenuePerClick: "2.4" },
    { country: "Spain", countryFlag: "/images/country-icons/esp.svg", clicks: "16,748", conversions: "950", revenue: "$ 65,000", conversionRate: "5.67%", revenuePerClick: "2.1" },
  ];

  const headcells = [
    {
      id: "country",
      label: "Country",
      getCell: (row) => (
        <Stack direction="row" alignItems="center">
          <Avatar src={row.countryFlag} sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography>{row.country}</Typography>
        </Stack>
      ),
    },
    { id: "clicks", label: "Clicks", getCell: (row) => row.clicks },
    { id: "conversions", label: "Conversions", getCell: (row) => row.conversions },
    { id: "revenue", label: "Revenue ($)", getCell: (row) => row.revenue },
    { id: "conversionRate", label: "Conversion Rate(%)", getCell: (row) => row.conversionRate },
    { id: "revenuePerClick", label: "Revenue per Click ($)", getCell: (row) => row.revenuePerClick },
  ];

  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Typography variant="h3" mb={3}>Geographic Statistics</Typography>
      <Grid container spacing={2} mb={4}>
        {infoBoxData.map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <InfoBox title={item.title} value={item.value} icon={item.icon} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        p: 3,
        mb: 4,
      }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h3">Region-Specific Trends</Typography>
          <RoundFormSelect
            options={[{ value: "weekly", label: "Weekly" }]}
            defaultValue="weekly"
          />
        </Stack>
        <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={350} />
        <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Region A</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#FFC452', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Region B</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Region C</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="3" r="3" fill="#ED55D4" />
              <rect y="2.5" width="12" height="1" fill="#ED55D4" />
            </svg>
            <Typography variant="body2" ml={1}>Click</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 0L9 3L6 6L3 3L6 0Z" fill="#2FE0A2" />
              <rect y="2.5" width="12" height="1" fill="#2FE0A2" />
            </svg>
            <Typography variant="body2" ml={1}>Conversions</Typography>
          </Box>
        </Stack>
      </Box>
      <Typography variant="h3" mb={2}>Performance by country</Typography>
      <Box sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        p: 3,
      }}>
        <CustomTable headcells={headcells} rows={tableData} />
      </Box>
    </Box>
  );
};

export default GeographicStatistics;

const InfoBox = ({ title, value, icon }) => (
  <Box sx={{
    background: (theme) => theme.palette.color.bg5,
    borderRadius: "18px",
    border: "1px solid",
    borderColor: (theme) => theme.palette.color.border,
    height: '126px',
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 1
  }}>
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.secondary, fontSize: "14px" }}>{title}</Typography>
      {icon}
    </Box>
    <Typography variant="h2">{value}</Typography>
  </Box>
);