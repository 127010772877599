import React from 'react'
import { Grid, Box, Typography, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import InfoIcon2 from '../../Icons/Info2';
import { FiArrowUpRight } from 'react-icons/fi';
import RoundFormSelect from '../../ui/RoundFormSelect';

const ConversionRateChart = () => {
    const theme = useTheme();

    const infoData = [
        {
            title: "Overall Conversion Rate",
            tooltip: "Overall Conversion Rate",
            value: "3.5%",
        },
        {
            title: "Highest Converting Channel",
            tooltip: "Highest Converting Channel",
            value: "5.2%",
            subtitle: "Website"
        },
        {
            title: "Conversion Rate Change",
            tooltip: "Conversion Rate Change",
            color: (theme) => theme.palette.color.green,
            value: "+0.5%",
            subtitle: "compared to last month",
        },
    ]

    const series = [
        {
            name: 'Website',
            type: 'area',
            data: [70, 60, 70, 80, 90, 70, 60, 50, 40, 60, 70, 80, 90]
        },
        {
            name: 'Affiliate',
            type: 'area',
            data: [60, 50, 60, 70, 60, 50, 40, 60, 70, 80, 90, 70, 60]
        },
        {
            name: 'Social Media',
            type: 'area',
            data: [25, 30, 40, 50, 40, 30, 40, 50, 60, 70, 60, 50, 40]
        },
        {
            name: 'Other',
            type: 'area',
            data: [15, 20, 30, 40, 30, 20, 30, 40, 50, 60, 50, 40, 30]
        }
    ];

    const options = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false
            },
            background: 'transparent',
        },
        colors: ['#2FE0A2', '#5F55EE', '#5EEDF8', '#8CADFF'],
        stroke: {
            curve: 'stepline',
            width: 2
        },
        xaxis: {
            categories: ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                show: true,
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        fill: {
            type: 'gradient',
            opacity: [0.45, 0.45, 0.45, 0.45],
            gradient: {
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: [0.38, 0.38, 0.38, 0.38],
                opacityTo: [0.02, 0.02, 0.02, 0.02],
                shade: 'dark',
                type: 'vertical',
                stops: [0, 100],
            },
        },
    };

    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
                <Typography variant="h3">Revenue per sales channel</Typography>
            </Grid>
            {infoData.map((item, index) => (
                <Grid item xs={12} sm={4} md={4} key={index}>
                    <InfoBox
                        item={item}
                        sx={{
                            background: (theme) => theme.palette.color.bg2,
                        }}
                    />
                </Grid>
            ))}
            <Grid item xs={12}>
                <Box sx={{
                    background: (theme) => theme.palette.color.bg5,
                    borderRadius: "18px",
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.color.border,
                    padding: '20px',
                }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="h6" color={(theme) => theme.palette.text.primary}>
                            Conversation Rate per sales chanel
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="3.5" width="6" height="6" fill="#2FE0A2" />
                                    <rect x="0.5" y="2.5" width="12" height="1" fill="#2FE0A2" />
                                </svg>
                                <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                                    Website
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="6.5" cy="3" r="3" fill="#5F55EE" />
                                    <rect x="0.5" y="2.5" width="12" height="1" fill="#5F55EE" />
                                </svg>
                                <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                                    Affiliate
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.5 0L9.5 3L6.5 6L3.5 3L6.5 0Z" fill="#5EEDF8" />
                                    <rect x="0.5" y="2.5" width="12" height="1" fill="#5EEDF8" />
                                </svg>
                                <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                                    Social Media
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1} pr={3}>
                                <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="6.5" cy="3" r="3" fill="#ED55D4" />
                                    <rect x="0.5" y="2.5" width="12" height="1" fill="#ED55D4" />
                                </svg>
                                <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                                    Other
                                </Typography>
                            </Stack>
                            <RoundFormSelect
                                options={[{ value: 'monthly', label: 'Monthly' }]}
                                defaultValue="monthly"
                            />
                        </Stack>
                    </Stack>
                    <ReactApexChart options={options} series={series} type="line" height={350} />
                </Box>
            </Grid>
        </Grid>
    );
}

export default ConversionRateChart;


const InfoBox = ({ item, icon, sx }) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: "24px",
                width: "100%",
                height: "164px",
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",

                    color: (theme) => theme.palette.color.secondary,
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        alignItems: "center",
                    }}
                >
                    <Typography variant="caption">{item.title}</Typography>
                    <Tooltip title={item.tooltip} placement="top">
                        {icon ? icon : <InfoIcon2 fill="none" />}
                    </Tooltip>
                </Stack>
            </Box>
            <Box>
                <Typography variant="h1" fontSize={isSmall ? "20px" : "28px"} color={item.color ? item.color : (theme) => theme.palette.color.primary} mt={3}>{item.value}</Typography>
                {item?.subtitle && <Typography variant="body2" mt={1}>{item.subtitle}</Typography>}
            </Box>
            {item.percent && (
                <Stack
                    direction="row"
                    spacing={1}
                    mt={1}
                    sx={{
                        alignItems: "center",
                        color: (theme) => theme.palette.color.green,
                    }}
                >
                    <FiArrowUpRight size={16} />
                    <Typography variant="caption">{item.percent}</Typography>
                </Stack>
            )}
        </Box>
    );
};