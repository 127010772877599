import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomTable from '../../Firm/Orders/Table';
import RoundFormSelect from '../../ui/RoundFormSelect';

const AccountsTable = ({ title, count }) => {
  const data = [
    {
      accountNumber: "102911050",
      stage: "Phase 1",
      country: "Switzerland",
      countryFlag: "/images/country-icons/czk.svg",
      age: "1m ago",
    },
    {
      accountNumber: "102911050",
      country: "Switzerland",
      countryFlag: "/images/country-icons/chf.svg",
      stage: "Live",
      age: "1m ago",
    },
    {
      accountNumber: "102911050",
      country: "USA",
      countryFlag: "/images/country-icons/usd.svg",
      stage: "Phase 1",
      age: "1m ago",
    },
    {
      accountNumber: "102911050",
      country: "Austria",
      countryFlag: "/images/country-icons/aus.svg",
      stage: "Phase 2",
      age: "1m ago",
    },
    {
      accountNumber: "102911050",
      country: "Switzerland",
      countryFlag: "/images/country-icons/czk.svg",
      stage: "Phase 1",
      age: "1m ago",
    },
    {
      accountNumber: "102911050",
      country: "Spain",
      countryFlag: "/images/country-icons/chf.svg",
      stage: "Phase 1",
      age: "1m ago",
    },
  ];

  const headcells = [
    {
      id: "accountNumber",
      label: "Account number",
      getCell: (row) => `{{ acc. number }}`,
    },
    {
      id: "stage",
      label: "Stage",
      getCell: (row) => (
        <Stack direction="row" alignItems="center">
          <Typography sx={{ color: row.stage === "Live" ? (theme) => theme.palette.color.green : (theme) => theme.palette.color.primary }}>{row.stage}</Typography>
        </Stack>
      ),
    },
    {
      id: "country",
      label: "Country",
      getCell: (row) => (
        <Stack direction="row" alignItems="center">
          <Avatar src={row.countryFlag} sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography>{row.country}</Typography>
        </Stack>
      ),
    },
    {
      id: "age",
      label: "Age",
      getCell: (row) => row.age,
    }
  ];

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        p: '10px',
        borderRadius: '16px',
        mt: 1,
        mb: 3,
      }}
    >
      <Stack direction="row" p={2} alignItems={"center"}>
        <Typography variant="h5">
          {title}
        </Typography>
        <Box sx={{ ml: '10px', background: (theme) => theme.palette.color.lightgray, p: '3px', borderRadius: '5px'}}>
          <Typography variant="h5">{count}</Typography>
        </Box>
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          mb: "24px",
          fontSize: "11.5px",
          pl: "10px",
          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <RoundFormSelect
          options={[{ value: "accountType", label: "Account type" }]}
          defaultValue="accountType"
        />
        <RoundFormSelect
          options={[{ value: "country", label: "Country" }]}
          defaultValue="country"
        />
        <RoundFormSelect
          options={[{ value: "age", label: "Age" }]}
          defaultValue="age"
        />
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 1.3125V10.6875" stroke="#CAD0DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.6875 6H1.3125" stroke="#CAD0DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </Stack>

      <CustomTable
          headcells={headcells}
          rows={data}
          minWidth={350}
        />
    </Box>
  );
};

export default AccountsTable;