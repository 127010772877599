import { Box, Grid, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import InfoIcon2 from '../../Icons/Info2'
import { FiArrowUpRight } from 'react-icons/fi'
import SalesReportByPeriod from './SalesReportByPeriod'
import SeasonalSalesTrends from './SeasonalSalesTrends'
import PerformaceComparisionByPeriod from './PerformaceComparisionByPeriod'
import SalesCompressionOverPeriod from './SalesCompressionOverPeriod'
import SalesGrowthByPeriod from './SalesGrowthByPeriod'

const TimeBased = () => {

  const infoData = [
    {
      title: "Daily Sales Report",
      tooltip: "Daily Sales Report",
      value: "$50,000",
      percent: "+5% from the previous day"
    },
    {
      title: "Highest Sales Season",
      tooltip: "Highest Sales Season",
      value: "Summer",
      subtitle: "$450,000"
    },
    {
      title: "Seasonal Growth Rate",
      tooltip: "Seasonal Growth Rate",
      value: "+12.5%",
      subtitle: "Spring to Summer",
    },
  ]

  const infoData2 = [
    {
      title: 'Previous Week vs. Current Week',
      tooltip: 'Comparison of the previous and current week sales',
      percent: '+5.2%',
      previousWeek: '1200',
      currentWeek: '1500',
      color: '#5FE084',
    },
    {
      title: 'Previous Month vs. Current Month',
      tooltip: 'Comparison of the previous and current month sales',
      percent: '+8.4%',
      previousWeek: '1200',
      currentWeek: '1500',
      color: '#5FE084',
    },
    {
      title: 'Previous Quarter vs. Current Quarter',
      tooltip: 'Comparison of the previous and current month sales',
      percent: '+3.1%',
      previousWeek: '1200',
      currentWeek: '1500',
      color: '#5FE084',
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        {infoData.map((item, index) => (
          <Grid item xs={12} sm={4} md={4} key={index}>
            <InfoBox
              item={item}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <SalesReportByPeriod />
      <SeasonalSalesTrends />
      <Grid container spacing={3}>
        {infoData2.map((item, index) => (
          <Grid item xs={12} sm={4} md={4} key={index}>
            <InfoBox2
              item={item}
              sx={{
                background: (theme) => theme.palette.color.bg2,
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <SalesCompressionOverPeriod />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SalesGrowthByPeriod />
        </Grid>
      </Grid>

      <PerformaceComparisionByPeriod />
    </Box>
  )
}

export default TimeBased


const InfoBox = ({ item, icon, sx }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "24px",
        width: "100%",
        height: "164px",
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",

          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="caption">{item.title}</Typography>
          <Tooltip title={item.tooltip} placement="top">
            {icon ? icon : <InfoIcon2 fill="none" />}
          </Tooltip>
        </Stack>
      </Box>
      <Typography variant="h1" fontSize={isSmall ? "20px" : "28px"} color={item.color ? item.color : (theme) => theme.palette.color.primary} mt={3}>{item.value}</Typography>
      {item.percent && (
        <Stack
          direction="row"
          spacing={1}
          mt={1}
          sx={{
            alignItems: "center",
            color: (theme) => theme.palette.color.green,
          }}
        >
          <FiArrowUpRight size={16} />
          <Typography variant="caption">{item.percent}</Typography>
        </Stack>
      )}
    </Box>
  );
};

const InfoBox2 = ({ item, icon, sx }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "24px",
        width: "100%",
        height: "174px",
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="caption">{item.title}</Typography>
          <Tooltip title={item.tooltip} placement="top">
            {icon || <InfoIcon2 />}
          </Tooltip>
        </Stack>
      </Box>

      <Stack
        direction="row"
        spacing={1}
        mt={3}
        sx={{ alignItems: 'center', color: (theme) => theme.palette.success.main }}
      >
        <FiArrowUpRight size={20} />
        <Typography
          variant="h4"
          fontSize={isSmall ? '20px' : '28px'}>
          {item.percent}
        </Typography>
      </Stack>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 2,
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <Stack direction="column" spacing={1} sx={{ alignItems: 'flex-start' }}>
          <Typography variant="caption">Previous week</Typography>
          <Typography variant="body1">${item.previousWeek}</Typography>
        </Stack>

        <Stack direction="column" spacing={1} sx={{ alignItems: 'flex-start' }}>
          <Typography variant="caption">Current Week</Typography>
          <Typography variant="body1">${item.currentWeek}</Typography>
        </Stack>
      </Box>
      
    </Box>
  );
};