import { useTheme } from "@mui/material";
import * as React from "react";

const InfoFillIcon = ({ stroke, ...props }) => {
  const theme = useTheme();

  return (
    <svg width="24" height="24" fill="none" {...props}>
      <path
        stroke={stroke || theme.palette.color.secondary}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 13v2"
      />
      <circle
        cx="12"
        cy="9"
        r="1"
        fill={stroke || theme.palette.color.secondary}
      />
      <circle
        cx="12"
        cy="12"
        r="7.25"
        stroke={stroke || theme.palette.color.secondary}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default InfoFillIcon;
