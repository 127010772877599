import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../components/Firm/Orders/Table";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { BiImport } from "react-icons/bi";
import { filterData } from "../../utils/filterByDate";
import SearchInput from "../../components/ui/NewSearch";
import ExportSection from "../../components/ui/ExportSection";
import CalendarPopup from "../../components/ui/CalendarPopup";
import DisplayColumns from "../../components/ui/DisplayColumns";
import dayjs from "dayjs";
import AddUser from "../../components/Icons/AddUser";
import ChevronDownIcon from "../../components/Icons/ChevronDown";
import { useNavigate } from "react-router-dom";
import { MdOutlineMoreHoriz } from "react-icons/md";
import TeamSelect from "../../components/Settings/TeamSelect";

const StatusDot = styled(Box)(({ theme, status }) => ({
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  backgroundColor: status === true ? "#2EE0A1" : "yellow",
  position: "absolute",
  bottom: 0,
  right: 0,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const teams = [
  {
    value: "Customer support",
    label: "Customer support",
    color: "#2EE0A1",
    description: "Access to tickets and client data",
  },
  {
    value: "Data Analyst",
    label: "Data Analyst",
    color: "#2EE0A1",
    description: "Access to statistics",
  },
  {
    value: "Finance",
    label: "Finance",
    color: "#2EE0A1",
    description: "Access to all dashboards for finance",
  },
  {
    value: "Risk Analyst",
    label: "Risk Analyst",
    color: "#2EE0A1",
    description: "Able to manage people",
  },
  {
    value: "Admin",
    label: "Admin",
    color: "#5E54ED",
    description: "Access to all",
  },
];

const roles = [
  {
    value: "Member",
    label: "Member",
    color: "#5E54ED",
    description: "Access to public places and dashboards",
  },
  {
    value: "Admin",
    label: "Admin",
    color: "#5E54ED",
    description: "Manage people, tickets, billing and other firm settings",
  },
];

const headcells = [
  {
    id: "name",
    label: "Name",
    getCell: (row) => {
      const avatarSrc = row.image;
      const firstName = row.name.charAt(0);
      const isOnline = row.online;
      const colors = ["#F45F82", "#5EEDF8"];
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      const isAI = row.name === "AI";

      return (
        <Stack direction="row" alignItems="center">
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <Avatar
              src={avatarSrc}
              sx={{
                width: 25,
                height: 25,
                backgroundColor: randomColor,
                color: "#FFFFFF",
              }}
            >
              {!avatarSrc && firstName}
            </Avatar>
            {!isAI && <StatusDot status={isOnline} />}
          </Box>
          <Typography ml={1}>{row.name}</Typography>
        </Stack>
      );
    },
  },
  {
    id: "email",
    label: "Email",
    getCell: (row) => row.email,
  },
  {
    id: "role",
    label: "Role",
    getCell: (row) => {
      const type = roles.find((role) => role.value === row.role);
      return <TeamSelect types={roles} type={type} onTypeChange={() => {}} />;
    },
  },
  {
    id: "team",
    label: "Team",
    getCell: (row) => {
      const team = teams.find((team) => team.value === row.team);
      return (
        <TeamSelect
          types={teams}
          type={team}
          onTypeChange={() => {}}
          sx={{
            width: "160px",
          }}
        />
      );
    },
  },
  {
    id: "createdAt",
    label: "Creation date",
    getCell: (row) => row.createdAt,
  },
  {
    id: "lastActivity",
    label: "Last activity",
    getCell: (row) => row.lastActivity,
  },
  {
    id: "action",
    label: "",
    getCell: (row) => {
      return (
        <IconButton
          sx={{
            fontSize: "20px",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <MdOutlineMoreHoriz />
        </IconButton>
      );
    },
  },
];

const data = [
  {
    name: "Jens V.",
    email: "example@gmail.com",
    role: "Member",
    team: "Data Analyst",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Jens V.",
    email: "example@gmail.com",
    role: "Member",
    team: "Data Analyst",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Jens V.",
    email: "example@gmail.com",
    role: "Member",
    team: "Data Analyst",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Jens V.",
    email: "example@gmail.com",
    role: "Member",
    team: "Data Analyst",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Jens V.",
    email: "example@gmail.com",
    role: "Member",
    team: "Data Analyst",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Jens V.",
    email: "example@gmail.com",
    role: "Member",
    team: "Data Analyst",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Jens V.",
    email: "example@gmail.com",
    role: "Member",
    team: "Data Analyst",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Jens V.",
    email: "example@gmail.com",
    role: "Member",
    team: "Data Analyst",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Jens V.",
    email: "example@gmail.com",
    role: "Member",
    team: "Data Analyst",
    teamDsc: "Access to tickets and client data",
    createdAt: "2 June 2024",
    lastActivity: "2 June 2024",
    online: true,
    image: "/images/person.avif",
  },
];

const Users = () => {
  const [duration, setDuration] = useState("thisMonth");
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const handleChangePage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(headcells.map((cell) => cell.id));

  const navigate = useNavigate();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  const filteredData = sortedData.filter((d) =>
    filterData(duration, d.createdAt)
  );
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    Math.min(currentPage * itemsPerPage, filteredData.length)
  );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Paper
      sx={{
        p: "24px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "24px",
          py: "10px",
          mb: "40px",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Typography variant="h5">Users</Typography>
          <Typography
            variant="h5"
            color={(theme) => theme.palette.color.secondary}
            fontSize={"15px"}
          >
            (24)
          </Typography>
        </Stack>
        <Button
          size="small"
          sx={{
            p: "5px",
            px: "10px",
            borderRadius: "32px",
            bgcolor: (theme) => theme.palette.color.active,
            color: "white",
            ":hover": { background: (theme) => theme.palette.color.active },
          }}
          onClick={() => {}}
        >
          Invite Users
        </Button>
      </Box>

      <CustomTable
        headcells={columns.filter((cell) => heads.includes(cell.id))}
        rows={data}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        checkbox={true}
        selected={selected}
        setSelected={setSelected}
        fontSize="13px"
      />
      {totalPages > 0 && (
        <Stack direction="row" justifyContent="end" mt={3}>
          <IconButton
            onClick={() => handleChangePage(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ mx: 0.5, p: 1, borderRadius: "50%", color: "#8A96A6" }}
          >
            <ChevronLeft />
          </IconButton>
          {Array.from({ length: totalPages }, (_, index) => (
            <IconButton
              key={index}
              onClick={() => handleChangePage(index + 1)}
              sx={{
                mx: 1,
                p: 1,
                borderRadius: "50%",
                width: "36px",
                height: "36px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color:
                    currentPage === index + 1
                      ? (theme) => theme.palette.color.white
                      : (theme) => theme.palette.color.secondary,
                }}
              >
                {index + 1}
              </Typography>
            </IconButton>
          ))}
          <IconButton
            onClick={() => handleChangePage(currentPage + 1)}
            disabled={currentPage === totalPages}
            sx={{ mx: 0.5, p: 1, borderRadius: "50%", color: "#8A96A6" }}
          >
            <ChevronRight />
          </IconButton>
        </Stack>
      )}
    </Paper>
  );
};

export default Users;
