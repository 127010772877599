import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import RoundFormSelect from '../../ui/RoundFormSelect';

const AccountSizeAllocation = () => {
  const theme = useTheme();
  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 6,
        barHeight: '50%',
        distributed: true,
        colors: {
          backgroundBarColors: [theme.palette.mode === 'dark' ? '#12181b' : '#f0f0f0'],
          backgroundBarOpacity: 1,
        },
      },
    },
    colors: ['#8CADFF', '#8CADFF', '#05B868', '#8CADFF', '#8CADFF', '#8CADFF', '#8CADFF'],
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      style: {
        colors: ['#fff'],
        fontSize: '14px',
        fontWeight: 'bold',
      },
      offsetX: 20,
      textAnchor: 'end',
    },
    xaxis: {
      show: false,
      categories: ['200K', '100K', '50K', '25K', '10K', '5K'],
      labels: {
        show: false,
      },
      max: 100,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [theme.palette.color.secondary],
          fontSize: '14px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
  };

  const series = [{
    data: [7.5, 15, 35, 22.5, 15, 25],
  }];

  return (
    <Box sx={{
      background: (theme) => theme.palette.color.bg5,
      border: '1px solid',
      borderColor: (theme) => theme.palette.color.strockborder,
      borderRadius: "18px",
      color: 'white',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: '0 auto',
      width: '100%',
    }}>
      <Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Account Size Allocation
          </Typography>
          <RoundFormSelect
            options={[{ value: "accountType", label: "Account Type" }]}
            defaultValue="accountType"
          />
        </Stack>
      </Box>
      <Box sx={{ px: 2 }}>
        <Chart options={options} series={series} type="bar" />
      </Box>
    </Box>
  );
};

export default AccountSizeAllocation;