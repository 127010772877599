import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import LineChart from "../../Charts/LineChart";

const ResolutionTimeChart = () => {
  const theme = useTheme();
  const options = {
    markers: {
      size: 4,
      colors: undefined,
      strokeWidth: 0,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 0.8,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: "Average Resolution Time (Hours)",
        style: {
          color: "#8A96A6",
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          colors: theme.palette.color.secondary,
          fontSize: "13px",
        },
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: [
        "2 Aug",
        "3 Aug",
        "4 Aug",
        "5 Aug",
        "6 Aug",
        "7 Aug",
        "8 Aug",
        "9 Aug",
        "10 Aug",
      ],
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          colors: theme.palette.color.secondary,
          fontSize: "13px",
        },
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 12,
    },
    stroke: {
      curve: "straight",
      width: 4,
    },
  };

  const series = [
    {
      name: "Email",
      data: [2, 4, 2.5, 7.5, 2.7, 4, 2, 4.5, 1.5],
    },
    {
      name: "Chat",
      data: [3, 2, 3, 2.5, 3.3, 7, 1, 2.3, 3],
    },
    {
      name: "Phone",
      data: [6, 5, 6, 5.5, 7, 5.5, 4, 4.3, 7],
    },
    {
      name: "Social media",
      data: [4.3, 3.3, 2, 4, 4.5, 4.2, 2, 3, 3.8],
    },
  ];

  return (
    <Grid
      item
      xs={12}
      lg={6}
      mt={3}
      sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
      }}
    >
      <Stack spacing={3} sx={{ p: "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",

            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Typography variant="h5">Average Resolution Time</Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={0.7} alignItems="center">
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: (theme) => theme.palette.color.active,
                }}
              />
              <Typography variant="caption">Email</Typography>
            </Stack>
            <Stack direction="row" spacing={0.7} alignItems="center">
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: theme.palette.color.cyan,
                }}
              />
              <Typography variant="caption">Chat</Typography>
            </Stack>
            <Stack direction="row" spacing={0.7} alignItems="center">
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: theme.palette.color.pink,
                }}
              />
              <Typography variant="caption">Phone</Typography>
            </Stack>
            <Stack direction="row" spacing={0.7} alignItems="center">
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: theme.palette.color.orange,
                }}
              />
              <Typography variant="caption">Social media</Typography>
            </Stack>
            {/* <RoundFormSelect
              options={[{ value: "accountType", label: "Account Type" }]}
              defaultValue="accountType"
            /> */}
          </Stack>
        </Box>
        <LineChart
          height="300"
          categories={[
            "2 Aug",
            "3 Aug",
            "4 Aug",
            "5 Aug",
            "6 Aug",
            "7 Aug",
            "8 Aug",
            "9 Aug",
            "10 Aug",
          ]}
          colors={[
            theme.palette.color.active,
            theme.palette.color.cyan,
            theme.palette.color.pink,
            theme.palette.color.orange,
          ]}
          options={options}
          series={series}
        />
      </Stack>
    </Grid>
  );
};

export default ResolutionTimeChart;
