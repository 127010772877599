import { Avatar, Box, Menu, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ChevronDownIcon from "../../Icons/ChevronDown";
import CommentIcon from "../../Icons/Comments";
import CustomSwitch from "../../ui/Switch";
import HexagonIcon from "../../Icons/Hexagon";
import { currencies } from "../../../static/challenges";

export default function SelectCurrencies({
  selected,
  setSelected,
  activeCurrencies,
  setActiveCurrencies,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [edit, setEdit] = useState(false);

  // const { data } = usegetcurrenc

  return (
    <>
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          cursor: "pointer",
        }}
        spacing={0.5}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Avatar
          alt={"country"}
          src={`/images/country-icons/${selected.img}.svg`}
          sx={{
            width: 14,
            height: 14,
          }}
        />
        <Typography
          variant="body3"
          align="center"
          sx={{
            display: "block",
            fontSize: 11.5,
            fontWeight: 400,
            color: (theme) => theme.palette.color.primary,
          }}
        >
          {selected.symbol}
        </Typography>
        <ChevronDownIcon />
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Stack
          sx={{
            borderRadius: "10px",
            p: "4px 12px",
            background: (theme) => theme.palette.color.bg2,
            width: "240px",
          }}
          spacing={2}
        >
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "24px" }}
          >
            <Typography variant="body3">
              {edit ? "Settings" : "Currencies"}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={() => setEdit(!edit)}>
              {edit ? (
                <Typography
                  sx={{
                    fontSize: "11.5px",
                    fontWeight: "400",
                    color: (theme) => theme.palette.color.secondary,
                    textDecoration: "underline",
                  }}
                >
                  Close
                </Typography>
              ) : (
                <CommentIcon />
              )}
            </Box>
          </Stack>

          <Stack spacing={1}>
            {currencies.map((currency) => {
              const isActive = activeCurrencies.includes(currency.label);
              return (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  onClick={() => {
                    if (!edit && isActive) {
                      setSelected(currency);
                    }
                  }}
                  sx={{
                    padding: "10px 12px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    ...(selected.label === currency.label && {
                      background: (theme) => theme.palette.color.gray,
                    }),
                    ...(!isActive &&
                      !edit && {
                        opacity: 0.5,
                      }),
                    "&:hover": {
                      ...((isActive || edit) &&
                        !(selected.label === currency.label) && {
                          background: (theme) => theme.palette.color.gray,
                          "& .hexagon": {
                            marginRight: "0px !important",
                          },
                        }),
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ height: "24px" }}
                  >
                    <Avatar
                      alt={"country"}
                      src={`/images/country-icons/${currency.img}.svg`}
                      sx={{
                        width: 16,
                        height: 16,
                      }}
                    />
                    <Typography variant="subtitle1_500">
                      {currency.label}
                    </Typography>
                  </Stack>
                  {edit && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Box
                        className="hexagon"
                        sx={{
                          marginRight: "-40px !important",
                          transition: "all 200ms ease-in-out",
                          "&:hover": {
                            marginRight: "0px",
                          },
                          overflow: "hidden",
                        }}
                      >
                        <HexagonIcon />
                      </Box>
                      <Box
                        sx={{
                          opacity: "0",
                          transition: "all 100ms ease-in-out",
                          ...(edit && {
                            opacity: 1,
                          }),
                        }}
                      >
                        <CustomSwitch
                          checked={isActive}
                          onChange={(e) => {
                            if (isActive) {
                              setActiveCurrencies(
                                activeCurrencies.filter(
                                  (c) => c !== currency.label
                                )
                              );
                            } else {
                              setActiveCurrencies([
                                ...activeCurrencies,
                                currency.label,
                              ]);
                            }
                          }}
                        />
                      </Box>
                    </Stack>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Menu>
    </>
  );
}
