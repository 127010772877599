import { useTheme } from "@mui/material";

export default function StarIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00007 0.750671L9.75073 6.25067H15.2507L10.7507 9.75067L12.2507 15.2507L8.00007 11.7507L3.75073 15.2507L5.25073 9.75067L0.750732 6.25067H6.25073L8.00007 0.750671Z"
        fill={stroke || theme.palette.color.bg}
      />
    </svg>
  );
}
