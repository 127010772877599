import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utils/getBaseQuery";

export const firmApi = createApi({
  reducerPath: "firmApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getLevarages: builder.query({
      query: () => `get-leverages`,
    }),
    getOrders: builder.query({
      query: ({ tenant_id }) => `get-orders?tenant_id=${tenant_id}`,
    }),
    getPayouts: builder.query({
      query: ({ tenant_id, status }) =>
        `get-payouts?tenant_id=${tenant_id}&status=${status}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Payouts", id })),
              { type: "Payouts", id: "ALL_PAYOUTS" },
            ]
          : [{ type: "Payouts", id: "ALL_PAYOUTS" }],
    }),
    changePayoutStatus: builder.mutation({
      query: (data) => ({
        url: `update-payout-status`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Payouts", id: "ALL_PAYOUTS" }],
    }),
    getDiscounts: builder.query({
      query: () => `get-discounts`,
    }),
    addChallenges: builder.mutation({
      query: (data) => ({
        url: "create-challenge",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Challenges", id: "ALL_CHALLENGES" }],
    }),
    getChallenges: builder.query({
      query: () => `get-challenges`,
      providesTags: (result) =>
        result
          ? [
              ...(result?.data || []).map(({ id }) => ({
                type: "Challenges",
                id,
              })),
              { type: "Challenges", id: "ALL_CHALLENGES" },
            ]
          : [{ type: "Challenges", id: "ALL_CHALLENGES" }],
    }),

    getCollections: builder.query({
      query: () => `get-collections`,
    }),
  }),
});

export const {
  useGetLevaragesQuery,
  useGetOrdersQuery,
  useGetPayoutsQuery,
  useChangePayoutStatusMutation,
  useGetDiscountsQuery,
  useAddChallengesMutation,
  useGetChallengesQuery,

  useGetCollectionsQuery,
} = firmApi;
