import React from 'react';
import { Box, Grid, Typography, Stack } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import RoundFormSelect from '../../components/ui/RoundFormSelect';
import InfoIcon2 from '../../components/Icons/Info2';

const GeneralPerformanceStatistics = () => {

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: { show: false },
      background: 'transparent',
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'stepline',
      width: 2
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: { style: { colors: '#8A96A6' } },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      labels: { style: { colors: '#8A96A6' } },
    },
    tooltip: { enabled: false },
    legend: {
      show: false,
    },
    grid: { show: false },
    colors: ['#5F55EE', '#5EEDF8'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.3,
        opacityTo: 0.08,
        stops: [0, 90, 100]
      }
    },
  };

  const chartSeries = [
    {
      name: 'Traffic',
      data: [980, 950, 600, 1080, 1350, 1300, 700, 1150, 500, 1220, 1400, 1400]
    },
    {
      name: 'Conversions',
      data: [600, 400, 500, 800, 1300, 550, 300, 400, 800, 1200, 550, 700]
    }
  ];

  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Typography variant="h3" mb={3}>General Performance Statistics</Typography>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} md={6}>
          <InfoBox
            title="Total Number of Affiliates"
            value="160"
            icon={<InfoIcon2 />}
          >
            <RoundFormSelect
              options={[{ value: "week", label: "Week" }]}
              defaultValue="week"
              sx={{
                border: "1px solid #5F55EE",
                "& .MuiSelect-outlined": {
                  border: "1px solid #5F55EE"
                }
              }}
            />
          </InfoBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoBox
            title="New Affiliates"
            value="120"
            icon={<InfoIcon2 />}
          >
            <RoundFormSelect
              options={[{ value: "week", label: "Week" }]}
              defaultValue="week"
              sx={{
                border: "1px solid #5F55EE",
                "& .MuiSelect-outlined": {
                  border: "1px solid #5F55EE"
                }
              }}
            />
          </InfoBox>
        </Grid>
      </Grid>
      <Box sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        p: 3,
      }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="white">Active Affiliates Trend</Typography>
          <Stack direction="row" gap={2}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
              <Typography variant="body2">Traffic</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 12, height: 12, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
              <Typography variant="body2">Conversions</Typography>
            </Box>
            <RoundFormSelect
              options={[{ value: "monthly", label: "Monthly" }]}
              defaultValue="monthly"
            />
          </Stack>

        </Stack>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="area"
          height={350}
        />
      </Box>
    </Box>
  );
};

export default GeneralPerformanceStatistics;

const InfoBox = ({ title, value, icon, children }) => (
  <Box sx={{
    background: (theme) => theme.palette.color.bg5,
    borderRadius: "18px",
    border: "1px solid",
    borderColor: (theme) => theme.palette.color.border,
    height: '126px',
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 1
  }}>
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.secondary, fontSize: "14px" }}>{title}</Typography>
      {icon}
      {children}
    </Stack>
    <Typography variant="h2">{value}</Typography>
  </Box>
);