import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { FiArrowUpRight } from "react-icons/fi";
import InfoFillIcon from "../Icons/InfoFill";
import React from "react";

const InfoBox = ({ title, tooltip, text, percent, value, icon, sx }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "24px",
        width: "100%",
        height: "130px",
        background: (theme) => theme.palette.color.bg2,
        borderRadius: "18px",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",

          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="caption">{title}</Typography>

          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <div>{icon ? icon : <InfoFillIcon fill="none" />}</div>
            </Tooltip>
          )}
        </Stack>
        {text !== undefined ? (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            sx={{
              alignItems: "center",
              color: (theme) => theme.palette.color.green,
            }}
          >
          <Typography variant="caption">{text}</Typography>
          <FiArrowUpRight size={14} />
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
              color: (theme) => theme.palette.color.green,
            }}
          >
            {percent && (
              <>
                {" "}
                <Typography variant="caption">{percent || "+3.7%"}</Typography>
                <FiArrowUpRight size={16} />
              </>
            )}
          </Stack>
        )}
      </Box>
      <Typography variant="h1">{value}</Typography>
    </Box>
  );
};

export default InfoBox;
