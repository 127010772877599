import React from 'react';
import { Avatar, Box, Chip, Stack, Typography } from '@mui/material';
import CustomTable from '../../Firm/Orders/Table';
import RoundFormSelect from '../../ui/RoundFormSelect';
import ExportSection from '../../ui/ExportSection';

const AccountPerformanceTable = () => {
  const data = [
    {
      month: "Aug, 2024",
      accountNumber: "102911050",
      accSize: "100k",
      country: "Switzerland",
      countryFlag: "/images/country-icons/chf.svg",
      status: "Approved",
      statusColor: "green",
      activityType: "High-Frequency Trading",
      actionTaken: "Account suspension",
    },
    {
      month: "May, 2024",
      accountNumber: "102911050",
      accSize: "400k",
      country: "Switzerland",
      countryFlag: "/images/country-icons/chf.svg",
      status: "Pending",
      statusColor: "yellow",
      activityType: "News Trading",
      actionTaken: "Warning issued",
    },
    {
      month: "Aug, 2024",
      accountNumber: "102911050",
      accSize: "200k",
      country: "USA",
      countryFlag: "/images/country-icons/usd.svg",
      status: "Denied",
      statusColor: "red",
      activityType: "High-Frequency Trading",
      actionTaken: "Review required",
    },
    {
      month: "Aug, 2024",
      accountNumber: "102911050",
      accSize: "400k",
      country: "Austria",
      countryFlag: "/images/country-icons/aus.svg",
      status: "Approved",
      statusColor: "green",
      activityType: "High-Frequency Trading",
      actionTaken: "Temporary freeze",
    },
    {
      month: "Aug, 2024",
      accountNumber: "102911050",
      accSize: "400k",
      country: "Switzerland",
      countryFlag: "/images/country-icons/chf.svg",
      status: "Approved",
      statusColor: "green",
      activityType: "High-Frequency Trading",
      actionTaken: "Temporary freeze",
    },
    {
      month: "Aug, 2024",
      accountNumber: "102911050",
      accSize: "200k",
      country: "Spain",
      countryFlag: "/images/country-icons/esp.svg",
      status: "Approved",
      statusColor: "green",
      activityType: "High-Frequency Trading",
      actionTaken: "Account suspension",
    },
  ];

  const headcells = [
    {
      id: "month",
      label: "Month",
      getCell: (row) => row.month,
    },
    {
      id: "accountNumber",
      label: "Account Number",
      getCell: (row) => row.accountNumber,
    },
    {
      id: "accSize",
      label: "Acc. Size",
      getCell: (row) => row.accSize,
    },
    {
      id: "country",
      label: "Country",
      getCell: (row) => (
        <Stack direction="row" alignItems="center">
          <Avatar src={row.countryFlag} sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography>{row.country}</Typography>
        </Stack>
      ),
    },
    {
      id: "status",
      label: "Status",
      getCell: (row) => (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.color[row.statusColor],
            color: (theme) => theme.palette.color.primary,
            borderRadius: '16px',
            p: '5px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'black' }}>
            <Box sx={{ width: 8, height: 8, backgroundColor: 'black', borderRadius: '50%', mr: 1 }}/>
            <Typography sx={{ color: 'black'}}>{row.status}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      id: "activityType",
      label: "Activity Type",
      getCell: (row) => row.activityType,
    },
    {
      id: "actionTaken",
      label: "Action Taken",
      getCell: (row) => row.actionTaken,
    },
  ];

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        p: '10px',
        borderRadius: '16px',
      }}
    >
      <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h5">
          Account Performance and Risk Management
        </Typography>
        <Stack direction="row" gap={2}>
          <RoundFormSelect
            options={[{ value: "monthly", label: "Monthly" }]}
            defaultValue="monthly"
          />
          <ExportSection />
        </Stack>
      </Stack>

      <Box sx={{ overflowX: "auto", width: "100%" }}>
        <CustomTable
          headcells={headcells}
          rows={data}
        />
      </Box>
    </Box>
  );
};

export default AccountPerformanceTable;
