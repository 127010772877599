import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Typography, useTheme } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';

const PassedAccountChart = () => {
    const theme = useTheme();

    const series = [
        [22, 20, 23, 19, 24, 16],
        [22, 20, 23, 18, 24, 16],
        [22, 20, 23, 19, 24, 16]
    ];
    const labels = ['10-20', '20-30', '30-40', '40-50', '50-60', '60-70'];
    const colors = ['#2FE0A2', '#F55F82', '#FFC452', '#5EEDF8', '#5F55EE', '#ED55D4'];

    const createOptions = (title) => ({
        chart: {
            type: 'donut',
            background: 'transparent',
        },
        colors: colors,
        labels: labels,
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return Math.round(val)
            },
            style: {
                fontSize: '12px',
                colors: [theme.palette.color.text],
            },
            dropShadow: {
                enabled: false
            },
        },
        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                expandOnClick: false,
                donut: {
                    size: '65%',
                },
            },
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: true,
        },
        stroke: {
            width: 0,
        },
        theme: {
            mode: 'dark',
        },
    });

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            border: '1px solid',
            borderColor: (theme) => theme.palette.color.strockborder,
            p: '20px',
            borderRadius: '16px',
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Passed Account</Typography>
                <RoundFormSelect
                    options={[{ value: "age", label: "Age" }]}
                    defaultValue="age"
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {['Phase 1', 'Phase 2', 'Live accounts'].map((phase, index) => (
                    <Box key={phase} sx={{ width: '30%' }}>
                        <ReactApexChart
                            options={createOptions(phase)}
                            series={series[index]}
                            type="donut"
                            height={200}
                        />
                        <Typography variant="subtitle2" align="center" sx={{ mt: 2 }}>
                            {phase}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                {labels.map((label, index) => (
                    <Box key={label} sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                        <Box sx={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: colors[index], mr: 1 }} />
                        <Typography variant="caption">{label}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default PassedAccountChart;