import { Box, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { FiArrowUpRight } from "react-icons/fi";
import React from "react";
import InfoIcon2 from "../Icons/Info2";

const InfoBox = ({ item, icon, sx, noStat }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "24px",
        width: "100%",
        height: "140px",
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",

          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="caption">{item.title}</Typography>
          <Tooltip title={item.tooltip} placement="top">
            {icon ? icon : <InfoIcon2 fill="none" />}
          </Tooltip>
        </Stack>
        {noStat ? null : 
        <>
        {item.text !== undefined ? (
          <Typography variant="caption">{item.text}</Typography>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
              color: (theme) => theme.palette.color.green,
            }}
          >
            <Typography variant="caption">{item.percent || "+3.7%"}</Typography>
            <FiArrowUpRight size={16} />
          </Stack>
        )}
        </>
        }
      </Box>
      <Typography variant="h1" fontSize={isSmall ? "20px" : "28px" } mt={3}>{item.value}</Typography>
      {item?.subtitle && <Typography variant="caption" pt={1} pb={2}>{item?.subtitle}</Typography>}
    </Box>
  );
};

export default InfoBox;
