import React, { useState } from 'react';
import { Box, Stack, Typography, Button, Tabs, Tab } from '@mui/material';
import dayjs from "dayjs";
import ExportSection from '../../components/ui/ExportSection';
import CalendarPopup from '../../components/ui/CalendarPopup';
import StatisticsGeneral from './statistics-general';
import FinancialPerformance from './financial-performance';

const StatisticsOverview = () => {
    const [startDate, setStartDate] = useState(dayjs().startOf("week"));
    const [endDate, setEndDate] = useState(dayjs().endOf("week"));
    const [selectedTab, setSelectedTab] = useState("1");

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} p={3} pb={10}>
            <Stack bgcolor={(theme) => theme.palette.color.bg} direction="row" justifyContent="space-between" pl={1} position={"sticky"} zIndex={10} top={0} py={1}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5">Overview</Typography>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <img src="/svg/leftArrow.svg" alt="leftArrow" style={{ width: "6px", height: "10px", cursor: "pointer" }} />
                        <Typography variant="caption">June 2024</Typography>
                        <img src="/svg/rightArrow.svg" alt="RightArrow" style={{ width: "6px", height: "10px", transform: "rotate(180deg)", cursor: "pointer" }} />
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                        fontSize: "11.5px",
                        color: (theme) => theme.palette.color.secondary,
                    }}
                >
                    <ExportSection />
                    <CalendarPopup
                        mainEndDate={endDate}
                        mainStartDate={startDate}
                        setMainEndDate={setEndDate}
                        setMainStartDate={setStartDate}
                    />
                    <Button variant="contained" size="small" disableElevation sx={{
                        py: "2px",
                        bgcolor: (theme) => theme.palette.color.lightgray,
                        borderRadius: "32px",
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.color.border,
                        color: (theme) => theme.palette.color.secondary,
                        ":hover": {
                            bgcolor: (theme) => theme.palette.color.lightgray,
                        }
                    }}>
                        <img src="/svg/stars.svg" alt="Stars" style={{ width: 24, height: 24, marginRight: 8 }} />
                        AI Analyze
                    </Button>
                </Stack>
            </Stack>

            <Box sx={{ width: 'fit-content' }}>
                <Stack direction="row">
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                    >
                        <Tab value="1" label="General" />
                        <Tab value="2" label="Financial Performance" />
                    </Tabs>
                </Stack>
            </Box>

            {selectedTab === "1" && (
                <Stack rowGap={1}>
                    <StatisticsGeneral />
                </Stack>
            )}
            {selectedTab === "2" && (
                <Stack rowGap={1}>
                    <FinancialPerformance />
                </Stack>
            )}

        </Box>
    );
};

export default StatisticsOverview;