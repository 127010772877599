import {
  Box,
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import ExportSection from "../../components/ui/ExportSection";
import CalendarPopup from "../../components/ui/CalendarPopup";
import dayjs from "dayjs";
import World from "../../components/ui/globe";
import InfoIcon2 from "../../components/Icons/Info2";

const data = [
  {
    title: "Total Customers",
    value: 615,
    most: { country: "France", value: 5100 },
    least: { country: "Netherlands", value: 111 },
  },
  {
    title: "Total Affiliates",
    value: 615,
    most: { country: "Spain", value: 504 },
    least: { country: "Netherlands", value: 111 },
  },
  {
    title: "Av. Payout Value",
    value: "$1,500.15",
    most: { country: "France", value: "$1,205.00" },
    least: { country: "Poland", value: "$205.00" },
  },
  {
    title: "Av. Order Value",
    value: "$499.95",
    most: { country: "Spain", value: "$1,205.00" },
    least: { country: "Italy", value: "$1,205.00" },
  },
  {
    title: "Challenge Sales by Country",
    value: "$499.95",
    most: { country: "Germany", value: "$5,205.00" },
    least: { country: "Russia", value: "$301.90" },
  },
  {
    title: "Revenue by Country",
    value: "$499.95",
    most: { country: "Poland", value: "$5,205.00" },
    least: { country: "Spain", value: "$301.90" },
  },
  {
    title: "Regional Sales Performance",
    value: "$499.95",
    most: { country: "North America", value: "$800,000" },
    least: { country: "South America", value: "$150,000" },
  },
];

const globeConfig = {
  pointSize: 4,
  globeColor: "#000000",
  showAtmosphere: true,
  atmosphereColor: "#FFFFFF",
  atmosphereAltitude: 0.1,
  emissive: "#000000",
  emissiveIntensity: 0.1,
  shininess: 0.9,
  polygonColor: "rgba(255,255,255,0.9)",
  ambientLight: "#38bdf8",
  directionalLeftLight: "#ffffff",
  directionalTopLight: "#ffffff",
  pointLight: "#ffffff",
  arcTime: 1000,
  arcLength: 0.9,
  rings: 1,
  maxRings: 3,
  initialPosition: { lat: 22.3193, lng: 114.1694 },
  autoRotate: true,
  autoRotateSpeed: 0.5,
};

const sampleArcs = [];

const Geographic = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [selectedCard, setSelectedCard] = useState(0);

  const handleCardSelect = (index) => {
    setSelectedCard(index);
  };

  return (
    <Box
      sx={{
        width: "100%",
        py: "12px",
      }}
    >
      <Stack
        bgcolor={(theme) => theme.palette.color.bg}
        direction={isSmall ? "column" : "row"}
        justifyContent="space-between"
        pl={1}
        position={"sticky"}
        zIndex={10}
        top={0}
        py={1}
        px={3}
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="h5">Geographic</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          mt={isSmall ? 1 : 0}
          justifyContent={isSmall ? "start" : "flex-end"}
          sx={{
            fontSize: "11.5px",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <ExportSection />
          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
          <Button
            disableElevation
            variant="contained"
            size="small"
            sx={{
              py: "2px",
              bgcolor: (theme) => theme.palette.color.lightgray,
              borderRadius: "32px",
              border: "1px solid",
              borderColor: (theme) => theme.palette.color.border,
              color: (theme) => theme.palette.color.secondary,
              ":hover": {
                bgcolor: (theme) => theme.palette.color.lightgray,
              },
            }}
          >
            <img
              src="/svg/stars.svg"
              alt="Stars"
              style={{ width: 24, height: 24, marginRight: 8 }}
            />
            AI Analyze
          </Button>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 2 }} />

      <Stack direction={isSmall ? "column" : "row"} sx={{ width: "100%" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: isSmall ? "40vh" : "80vh",
            ...(isSmall ? {} : { position: "sticky", top: 0 }),
            p: 2,
          }}
        >
          <Box sx={{ height: "450px", width: "500px" }}>
            <World data={sampleArcs} globeConfig={globeConfig} />
          </Box>
        </Box>
        <Stack
          direction={"column"}
          flex={1}
          p={isSmall? 1 : 2}
          spacing={isSmall? 3 : 7}
          sx={{ maxHeight: "100vh", overflowY: "auto" }}
        >
          {data.map((item, index) => (
            <GeographicCard
              key={index}
              index={index}
              title={item.title}
              value={item.value}
              most={item.most}
              least={item.least}
              selected={selectedCard === index}
              onSelect={handleCardSelect}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Geographic;

const GeographicCard = ({
  index,
  title,
  value,
  most,
  least,
  selected,
  onSelect,
}) => {
  return (
    <Stack spacing={1} mt={1} px={2}>
      <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
        <Typography variant="h6" pl={1}>{title}</Typography>
        <Typography variant="subtitle2">{value}</Typography>
      </Stack>
      <Stack
        direction={"row"}
        bgcolor={(theme) => theme.palette.color.bg2}
        sx={{
          width: "100%",
          border: "1px solid",
          borderColor: selected
            ? (theme) => theme.palette.color.active
            : "transparent",
          cursor: "pointer",
          borderRadius: "15px",
        }}
        onClick={() => onSelect(index)}
      >
        <Stack
          direction={"column"}
          flex={1}
          p={2}
          sx={{
            backgroundImage: index === 0 ? 'url("/svg/statsCardBg.svg")' : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle2">Highest</Typography>
            <Tooltip title={most.value} placement="top">
              <InfoIcon2
                sx={{ fontSize: "15px", color: (theme) => theme.palette.text.secondary }}
              />
            </Tooltip>
          </Stack>
          <Typography variant="h2" mt={7}>
            {most.country}
          </Typography>
          <Typography
            variant="h6"
            color={(theme) => theme.palette.color.secondary}
            mt={1}
          >
            {most.value}
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack direction={"column"} flex={1} p={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle2">Lowest</Typography>
            <Tooltip title={least.value} placement="top">
              <InfoIcon2
                sx={{ fontSize: "15px", color: (theme) => theme.palette.text.secondary }}
              />
            </Tooltip>
          </Stack>
          <Typography variant="h2" mt={7}>
            {least.country}
          </Typography>
          <Typography
            variant="h6"
            color={(theme) => theme.palette.color.secondary}
            mt={1}
          >
            {least.value}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};