import { useTheme } from "@mui/material";

export default function UserIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg width="24" height="24" fill="none" {...props}>
      <circle
        cx="12"
        cy="8"
        r="3.25"
        stroke={stroke || theme.palette.color.secondary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        stroke={stroke || theme.palette.color.secondary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.848 19.25h10.305c1.141 0 2.021-.982 1.488-1.992C17.856 15.773 16.068 14 12 14s-5.856 1.773-6.64 3.258c-.534 1.01.346 1.992 1.487 1.992Z"
      />
    </svg>
  );
}
