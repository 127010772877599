import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utils/getBaseQuery";

export const announcementApi = createApi({
  reducerPath: "announcementApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAnnouncements: builder.query({
      query: () => `get-announcements`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: "Announcements",
                id,
              })),
              { type: "Announcements", id: "ALL_ANNOUNCEMENTS" },
            ]
          : [{ type: "Announcements", id: "ALL_ANNOUNCEMENTS" }],
    }),
    addAnnouncement: builder.mutation({
      query: (data) => ({
        url: "create-announcement",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Announcements", id: "ALL_ANNOUNCEMENTS" }],
    }),
    editAnnouncement: builder.mutation({
      query: (data) => ({
        url: `edit-announcement`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Announcements", id: "ALL_ANNOUNCEMENTS" }],
    }),
    changeAnnouncementStatus: builder.mutation({
      query: (data) => ({
        url: `change-announcement-status`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Announcements", id: "ALL_ANNOUNCEMENTS" }],
    }),
    changeAnnouncementAudience: builder.mutation({
      query: (data) => ({
        url: `change-announcement-audience`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Announcements", id: "ALL_ANNOUNCEMENTS" }],
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useAddAnnouncementMutation,
  useChangeAnnouncementAudienceMutation,
  useChangeAnnouncementStatusMutation,
  useEditAnnouncementMutation,
} = announcementApi;
