import React from 'react';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import RoundFormSelect from '../../components/ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';
import InfoIcon2 from '../../components/Icons/Info2';

const IndividualAffiliateStatistics = () => {
    return (
        <Box sx={{ p: 3, minHeight: '100vh', width: '100%' }}>
            <Typography variant="h4" color="white" mb={4}>Individual Affiliate Statistics</Typography>
            <TopAffiliates />
            <PerformanceTopAffiliatesChart />
            <LowPerformers />
            <PerformanceLowAffiliatesChart />
        </Box>
    );
};

export default IndividualAffiliateStatistics;


const InfoBox = ({ title, value, tooltip }) => (
    <Box sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        height: '126px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 1
    }}>
        <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.secondary, fontSize: '14px' }} >{title}</Typography>
            <Tooltip title={tooltip}>
                <InfoIcon2 fontSize="small" sx={{ color: (theme) => theme.palette.color.secondary }} />
            </Tooltip>
        </Box>
        <Typography variant="h2" sx={{ color: (theme) => theme.palette.color.primary }}>{value}</Typography>
    </Box>
);


const TopAffiliates = () => (
    <>
        <Typography variant="h6" color="white" mb={2}>Top Affiliates</Typography>
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <InfoBox title="Top Revenue Affiliate" value="$50,000" tooltip="Highest earning affiliate" />
            </Grid>
            <Grid item xs={12} md={4}>
                <InfoBox title="Top Clicks Affiliate" value="1,250" tooltip="Affiliate with most clicks" />
            </Grid>
            <Grid item xs={12} md={4}>
                <InfoBox title="Top Conversions Affiliate" value="350" tooltip="Affiliate with most conversions" />
            </Grid>
        </Grid>
    </>
);

const LowPerformers = () => (
    <>
        <Typography variant="h6" color="white" mb={2} mt={4}>Low Performers</Typography>
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <InfoBox title="Lowest Revenue Affiliate" value="$500" tooltip="Lowest earning affiliate" />
            </Grid>
            <Grid item xs={12} md={4}>
                <InfoBox title="Lowest Clicks Affiliate" value="55" tooltip="Affiliate with least clicks" />
            </Grid>
            <Grid item xs={12} md={4}>
                <InfoBox title="Lowest Conversions Affiliate" value="13" tooltip="Affiliate with least conversions" />
            </Grid>
        </Grid>
    </>
);

const PerformanceTopAffiliatesChart = () => {

    const topPerformanceData = {
        series: [
            { name: 'Clicks', data: [900, 400, 1000, 600, 700] },
            { name: 'Revenue', data: [2400, 1700, 1900, 2000, 2400] },
            { name: 'Conversions', data: [400, 300, 350, 550, 600] },
        ],
        categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        colors: ['#5F55EE', '#2FE0A2', '#FF69B4'],
    };

    const options = {
        chart: {
            type: 'bar',
            toolbar: { show: false },
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '40%',
                borderRadius: 4,
            },
        },
        dataLabels: { enabled: false },
        xaxis: {
            categories: topPerformanceData.categories,
            labels: { style: { colors: '#8A96A6' } },
            axisBorder: { show: false },
            axisTicks: { show: false },
        },
        yaxis: {
            labels: { style: { colors: '#8A96A6' } },
        },
        legend: {
            show: false,
        },
        fill: {
            opacity: 1,
            colors: ['#1D232B', '#1D232B'],
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: "vertical",
                shadeIntensity: 1,
                gradientToColors: ['#1D232B'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        colors: topPerformanceData.colors,
        grid: { show: false },
        tooltip: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#5F55EE', '#2FE0A2', '#ED55D4'],
        },
    };

    return (
        <Box sx={{
            p: 2,
            mt: 3,
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
        }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" color="white">Performance of Top Affiliates</Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Clicks</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Revenue</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#FF69B4', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Conversions</Typography>
                    </Box>
                    <RoundFormSelect options={[{ value: "weekly", label: "Weekly" }, { value: "monthly", label: "Monthly" }]} defaultValue="weekly" />
                </Stack>
            </Box>
            <ReactApexChart options={options} series={topPerformanceData.series} type="bar" height={350} />
        </Box>
    );
};

const PerformanceLowAffiliatesChart = () => {

    const lowPerformanceData = {
        series: [
            { name: 'Revenue', data: [2000, 1600, 1900, 2200, 2400, 2300] },
            { name: 'Clicks', data: [600, 900, 700, 1300, 1100, 1050] },
            { name: 'Conversions', data: [350, 450, 350, 550, 450, 650] },
        ],
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        colors: ['#FFA500', '#5EEDF8', '#5F55EE'],
    };

    const options = {
        chart: {
            type: 'line',
            toolbar: { show: false },
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '35%',
                borderRadius: 2,
            },
        },
        dataLabels: { enabled: false },
        xaxis: {
            categories: lowPerformanceData.categories,
            labels: { style: { colors: '#8A96A6' } },
            axisBorder: { show: false },
            axisTicks: { show: false },
        },
        yaxis: {
            labels: { style: { colors: '#8A96A6' } },
        },
        legend: {
            show: false,
        },
        stroke: {
            width: 3,
            curve: 'straight',
        },
        fill: { opacity: 1 },
        colors: lowPerformanceData.colors,
        grid: { show: false },
        markers: {
            size: 4,
            colors: undefined,
            strokeWidth: 0,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 0.8,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        tooltip: {
            enabled: false,
        },
    };

    return (
        <Box sx={{
            p: 2,
            mt: 3,
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
        }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" color="white">Performance of Low Affiliates</Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#FFC452', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Revenue</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Clicks</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Conversions</Typography>
                    </Box>
                    <RoundFormSelect options={[{ value: "weekly", label: "Weekly" }, { value: "monthly", label: "Monthly" }]} defaultValue="weekly" />
                </Stack>
            </Box>
            <ReactApexChart options={options} series={lowPerformanceData.series} type="line" height={350} />
        </Box>
    );
};