import { Box, Typography, alpha } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavItem2 = ({ item, sx }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <Box
      key={item.title}
      sx={{
        display: "flex",
        alignItems: "center",
        // mx: 1.5,
        px: 1.5,
        gap: 1,
        borderLeft: `2px solid transparent`,
        color: (theme) => theme.palette.color.secondary,
        ...(item.path === pathname && {
          borderLeft: (theme) => `2px solid ${theme.palette.color.active}`,
          background: (theme) => theme.palette.color.lightgray,
          color: (theme) => theme.palette.color.primary,
        }),
        width: "100%",
        flexShrink: 0,

        ":hover": {
          borderLeft: (theme) => `2px solid ${theme.palette.color.active}`,
          background: (theme) => theme.palette.color.lightgray,
        },
        borderRadius: "0px 8px 8px 0px",
        transition: "opacity 0.5s",
        cursor: "pointer",
        height: "40px",
        ...sx,
      }}
      onClick={() => navigate(item.path)}
    >
      {item.icon && <item.icon size={18} />}
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography
          variant="caption_400"
          sx={{
            py: "5px",
            ...(item.path === pathname && {
              color: (theme) => theme.palette.color.primary,
            }),
          }}
        >
          {item.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default NavItem2;
