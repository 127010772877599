import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import FormInput from "../../../components/ui/FormInput";
import FormSelect from "../../../components/ui/FormSelect1";
import { RxPencil1 } from "react-icons/rx";
import ClockIcon from "../../../components/Icons/Clock";
import GlobeIcon from "../../../components/Icons/Globe";
import { useUpdateUserProfileMutation } from "../../../slices/api/settingSlice";

// const StatusDot = styled(Box)(({ theme, status }) => ({
//     width: "22px",
//     height: "22px",
//     borderRadius: "50%",
//     backgroundColor: status === true ? "#2EE0A1" : "yellow",
//     position: "absolute",
//     bottom: 0,
//     right: 0,
//     border: `2px solid ${theme.palette.background.paper}`,
// }));

const EditIconContainer = styled(Box)(({ theme }) => ({
  width: "45px",
  height: "45px",
  borderRadius: "50%",
  backgroundColor: theme.palette.color.lightgray,
  position: "absolute",
  bottom: 0,
  right: 0,
  border: `2px solid ${theme.palette.background.paper}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CustomFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": {
    color: checked
      ? theme.palette.color.primary
      : theme.palette.color.secondary,
  },
}));

const CustomRadio = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    color: theme.palette.color.lightgray,
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    color: theme.palette.color.active,
  },
}));

const Profile = () => {
  const theme = useTheme();
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    team: "",
    language: "",
    time_zone: "",
    time_format: "24 hour",
    start_of_week: "Sunday",
    date_format: "mm/dd/yyyy",
  });

  const [updateProfile] = useUpdateUserProfileMutation();

  const handleSave = async () => {
    await updateProfile(formValues);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          px: "24px",
          py: "10px",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"end"}
        >
          <Typography variant="h2" gutterBottom>
            Profile Settings
          </Typography>
          <Button
            size="small"
            sx={{
              p: "5px",
              px: "10px",
              borderRadius: "32px",
              bgcolor: (theme) => theme.palette.color.active,
              color: "white",
              ":hover": { background: (theme) => theme.palette.color.active },
            }}
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </Stack>
        <Stack direction={"row"} width={"70%"} mt={1}>
          <Typography variant="caption">
            View and update your profile details
          </Typography>
        </Stack>
      </Box>

      <Divider />
      <Box
        sx={{
          p: "20px",
          pl: "30px",
        }}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={3}
          mt={"5px"}
          p={"10px"}
          borderRadius={"10px"}
          sx={{ bgcolor: (theme) => theme.palette.color.bg5 }}
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={1} justifyContent={"center"}>
              <Typography variant="heading">Profile</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Your personal information and account security settings.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack display={"flex"} direction={"column"} spacing={1}>
              <Stack direction="row" alignItems="center" mb={2}>
                <Box sx={{ position: "relative", display: "inline-block" }}>
                  <Avatar
                    src={"/images/person.avif"}
                    sx={{ width: 100, height: 100, color: "#FFFFFF" }}
                  />
                  {/* <StatusDot status={true} /> */}
                  <EditIconContainer
                    component="label"
                    role={undefined}
                    sx={{ fontSize: "20px", ml: "20px" }}
                  >
                    <RxPencil1 />
                    <VisuallyHiddenInput type="file" />
                  </EditIconContainer>
                </Box>
              </Stack>
              <Stack>
                <Typography variant="subtitle" sx={{ mt: "20px", mb: "5px" }}>
                  First name
                </Typography>
                <FormInput
                  value={formValues.first_name}
                  onChange={(e) =>
                    setFormValues({ ...formValues, first_name: e.target.value })
                  }
                  placeholder="Enter your First Name"
                  defaultValue="Jens"
                />
              </Stack>

              <Stack>
                <Typography variant="subtitle" sx={{ mt: "20px", mb: "5px" }}>
                  Last name
                </Typography>
                <FormInput
                  value={formValues.last_name}
                  onChange={(e) =>
                    setFormValues({ ...formValues, last_name: e.target.value })
                  }
                  placeholder="Enter your Last Name"
                  defaultValue="van der Blij"
                />
              </Stack>

              <Stack>
                <Typography variant="subtitle" sx={{ mt: "20px", mb: "5px" }}>
                  Team
                </Typography>
                <FormInput
                  value={formValues.team}
                  onChange={(e) =>
                    setFormValues({ ...formValues, team: e.target.value })
                  }
                  placeholder="Your Team"
                  defaultValue="Admin"
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          rowSpacing={3}
          mt={"30px"}
          p={"10px"}
          borderRadius={"10px"}
          sx={{ bgcolor: (theme) => theme.palette.color.bg5 }}
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Language & Region</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Customize your language and region.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack display={"flex"} direction={"column"} spacing={2}>
              <Stack>
                <Typography variant="subtitle" sx={{ pb: "5px" }}>
                  Language
                </Typography>
                <FormSelect
                  sx={{
                    backgroundColor: theme.palette.color.darkgray,
                    borderRadius: "16px",
                  }}
                  placeholder="Select Language"
                  value={formValues.language}
                  onChange={(e) =>
                    setFormValues({ ...formValues, language: e.target.value })
                  }
                  options={[
                    { value: "English", label: "English" },
                    { value: "French", label: "French" },
                  ]}
                  icon={GlobeIcon}
                  fullWidth
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle" sx={{ mt: "20px", pb: "5px" }}>
                  Timezone
                </Typography>
                <FormSelect
                  sx={{
                    backgroundColor: theme.palette.color.darkgray,
                    borderRadius: "16px",
                  }}
                  placeholder="Select Timezone"
                  value={formValues.time_zone}
                  onChange={(e) =>
                    setFormValues({ ...formValues, time_zone: e.target.value })
                  }
                  options={[
                    { value: "Europe/Amsterdam", label: "Europe/Amsterdam" },
                    { value: "Aisa/India", label: "Aisa/India" },
                  ]}
                  icon={ClockIcon}
                  fullWidth
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          rowSpacing={3}
          mt={"30px"}
          p={"10px"}
          borderRadius={"10px"}
          sx={{ bgcolor: (theme) => theme.palette.color.bg5 }}
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography variant="heading">Time & date format</Typography>
              <Typography variant="caption" sx={{ fontWeight: "500" }}>
                Select the way times & dates are displayed.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack display={"flex"} direction={"column"} spacing={5}>
              <FormControl component="fieldset" sx={{ mb: 4 }}>
                <Typography variant="body2" fontSize={"13px"}>
                  Start of calendar week
                </Typography>
                <RadioGroup
                  value={formValues.start_of_week}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      start_of_week: e.target.value,
                    })
                  }
                  name="startOfWeek"
                >
                  <CustomFormControlLabel
                    value="Sunday"
                    control={<CustomRadio />}
                    label="Sunday"
                    checked={formValues.start_of_week === "Sunday"}
                  />
                  <CustomFormControlLabel
                    value="Monday"
                    control={<CustomRadio />}
                    label="Monday"
                    checked={formValues.start_of_week === "Monday"}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset" sx={{ mb: 4 }}>
                <Typography variant="body2" fontSize={"13px"}>
                  Time format
                </Typography>
                <RadioGroup
                  value={formValues.time_format}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      time_format: e.target.value,
                    })
                  }
                  name="timeFormat"
                >
                  <CustomFormControlLabel
                    value="24 hour"
                    control={<CustomRadio />}
                    label="24 hour"
                    checked={formValues.time_format === "24 hour"}
                  />
                  <CustomFormControlLabel
                    value="12 hour"
                    control={<CustomRadio />}
                    label="12 hour"
                    checked={formValues.time_format === "12 hour"}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset" sx={{ mb: 4 }}>
                <Typography variant="body2" fontSize={"13px"}>
                  Date format
                </Typography>
                <RadioGroup
                  value={formValues.date_format}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      date_format: e.target.value,
                    })
                  }
                  name="dateFormat"
                >
                  <CustomFormControlLabel
                    value="mm/dd/yyyy"
                    control={<CustomRadio />}
                    label="mm/dd/yyyy"
                    checked={formValues.date_format === "mm/dd/yyyy"}
                  />
                  <CustomFormControlLabel
                    value="dd/mm/yyyy"
                    control={<CustomRadio />}
                    label="dd/mm/yyyy"
                    checked={formValues.date_format === "dd/mm/yyyy"}
                  />
                  <CustomFormControlLabel
                    value="yyyy/mm/dd"
                    control={<CustomRadio />}
                    label="yyyy/mm/dd"
                    checked={formValues.date_format === "yyyy/mm/dd"}
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;
