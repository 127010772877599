import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Add, Done, Edit } from "@mui/icons-material";
import Breadcrumbs from "../../../../components/ui/Breadcrumbs";
import CollapsibleTable from "../../../../components/ui/Collapsibletable";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import EyeOpenIcon from "../../../../components/Icons/EyeOpen";
import CommentsIcon from "../../../../components/Icons/Comments";
import TrashIcon from "../../../../components/Icons/Trash";
import PlusIcon from "../../../../components/Icons/Plus";
import SearchInput from "../../../../components/ui/NewSearch";
import ExportSection from "../../../../components/ui/ExportSection";
import RoundFormSelect from "../../../../components/ui/RoundFormSelect";

const ActionComponent = ({ row }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <IconButton>
        <PlusIcon width="28" height="28" stroke={theme.palette.color.primary} />
      </IconButton>
      <IconButton>
        <CommentsIcon />
      </IconButton>
      <IconButton>
        <EyeOpenIcon />
      </IconButton>
      <IconButton>
        <TrashIcon />
      </IconButton>
    </Stack>
  );
};

const statutes = [
  { value: "published", label: "Published", color: "green", icon: Done },
  { value: "draft", label: "Draft", color: "orange", icon: Edit },
];

const headcells = [
  {
    id: "name",
    label: "Collection Name",
    getCell: (row) => (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box
          sx={{
            borderRadius: "50%",
            background: (theme) => theme.palette.color.lightgray,
            width: "32px",
            height: "32px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FolderOutlinedIcon
            sx={{
              color: (theme) => theme.palette.color.secondary,
              fontSize: "16px",
            }}
          />
        </Box>
        <Typography variant="subtitle1">{row.name}</Typography>
      </Stack>
    ),
  },

  {
    id: "status",
    label: "Status",
    getCell: (row) => {
      const status = statutes.find((statute) => statute.value === row.status);
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            borderRadius: "7px",
            width: "91px",
          }}
        >
          {status.icon ? (
            <status.icon
              sx={{
                color: (theme) => theme.palette.color[status.color],
                fontSize: "16px",
              }}
            />
          ) : (
            <Box
              sx={{
                width: "9px",
                height: "9px",
                borderRadius: "50%",
                background: (theme) => theme.palette.color[status?.color],
              }}
            ></Box>
          )}
          <Typography
            sx={{
              color: (theme) => theme.palette.color[status.color],
              lineHeight: "2.2em",
            }}
          >
            {status.label}
          </Typography>
        </Stack>
      );
    },
    default: true,
  },
  {
    id: "strength",
    label: "Strength",
    getCell: (row) => (
      <Box
        sx={{
          padding: "0 12px",
          borderRadius: "16px",
          background: (theme) => alpha(theme.palette.color.active, 0.15),
          width: "fit-content",
        }}
      >
        <Typography
          variant="caption_500"
          sx={{
            color: (theme) => theme.palette.color.active,
            lineHeight: "24px",
          }}
        >
          Strong
        </Typography>
      </Box>
    ),
  },

  {
    id: "amount",
    label: "Articles",
    getCell: (row) => row.amount,
  },
  {
    id: "trained",
    label: "Trained",
    getCell: (row) => "Yes",
  },
];

const data = [
  {
    name: "Collection 1",
    amount: 21,
    collection: "Collection",
    status: "published",
    source: "Source",
    children: [
      {
        name: "Collection name",
        amount: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
      {
        name: "Collection name",
        amount: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
      {
        name: "Collection name",
        amount: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
      {
        name: "Collection name",
        amount: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
      {
        name: "Collection name",
        amount: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
    ],
  },
  {
    name: "Collection 2",
    amount: 21,
    collection: "Collection",
    status: "draft",
    source: "Source",
    children: [
      {
        name: "Collection name",
        amount: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
    ],
  },
  {
    name: "Collection 3",
    amount: 21,
    collection: "Collection",
    status: "published",
    source: "Source",
    children: [
      {
        name: "Collection name",
        amount: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
    ],
  },
  {
    name: "Collection 4",
    amount: 21,
    collection: "Collection",
    status: "draft",
    source: "Source",
    children: [
      {
        name: "Collection name",
        amount: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
    ],
  },
  {
    name: "Collection 5",
    amount: 21,
    collection: "Collection",
    status: "published",
    source: "Source",
    children: [
      {
        name: "Collection name",
        amount: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
    ],
  },
];

const Collections = () => {
  const [selectedColumns, setSelectedColumns] = useState(
    headcells.map((h) => h.id)
  );
  const [status, setStatus] = useState("all");
  const filteredData = data.filter(
    (d) => status === "all" || d.status === status
  );

  return (
    <Paper
      sx={{
        px: "12px",
        py: "24px",
        width: "100%",
      }}
    >
      <Typography variant="h4" sx={{ pl: "24px", mb: 1 }}>
        Collections
      </Typography>
      <Box
        sx={{
          fontSize: "11.5px",
          color: (theme) => theme.palette.color.secondary,
          px: { xs: "0px", lg: "24px" },
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "flex-start", lg: "space-between" },

          gap: 2,
        }}
      >
        <Breadcrumbs path={"Your Firm/Docs"} title={"Collections"} />
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />
          <ExportSection />
          <ExportSection isImport={true} />
          <RoundFormSelect
            options={[
              { value: "all", label: "All" },
              { value: "published", label: "Published" },
              { value: "draft", label: "Draft" },
            ]}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              ml: "18px !important",
              mr: "4px !important",
              border: (theme) => `1px solid ${theme.palette.color.lightgray}`,
              height: "21px",
              my: "auto !important",
            }}
          />
          <IconButton>
            <Add />
          </IconButton>
        </Stack>
      </Box>

      <CollapsibleTable
        headcells={headcells.filter((headcell) =>
          selectedColumns.includes(headcell.id)
        )}
        rows={filteredData}
        actionComponent={<ActionComponent />}
        fontSize="13px"
      />
    </Paper>
  );
};

export default Collections;
