import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  Divider,
  TextField,
  Button,
  Avatar,
  Link,
  IconButton,
  ButtonGroup,
  useTheme,
} from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import EyeOpenIcon from "../../Icons/EyeOpen";
import CheckIcon from "../../Icons/Check";
import PlusIcon from "../../Icons/Plus";
import LiveAccount from "../../Icons/LiveAccount";
import { LuCopy } from "react-icons/lu";
import EditIcon from "../../Icons/Edit";
import { FiSlash } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import CustomMenu from "../../ui/CustomMenu";
import AttachmentIcon from "../../Icons/Attachment";
import EmojiIcon from "../../Icons/Emoji";

const messages = [
  {
    sender: "client",
    message: `Hi there 👋
    Is everything going well?`,
    time: "11: 05 PM",
  },
];

const options = [
  [
    {
      label: "Copy member ID",
      icon: <LuCopy size={16} />,
    },
    {
      label: "De-activate a-book",
      icon: <LiveAccount width="14" height="14" />,
    },
    { label: "Edit Customer", icon: <EditIcon width="20" height="20" /> },
  ],
  [
    { label: "Ban user", icon: <FiSlash size={16} /> },
    { label: "Clear Personal Data", icon: <AiOutlineDelete size={18} /> },
    {
      label: "Delete Customer",
      icon: <AiOutlineDelete size={18} />,
      color: "red",
    },
  ],
];

const ClientConversation = () => {
  const [showHelp, setShowHelp] = useState(false);
  const theme = useTheme();

  const ref = useRef(null);

  const handleInputChange = (event) => {
    if (event.target.value.startsWith("/")) {
      setShowHelp(true);
    } else {
      setShowHelp(false);
    }
  };

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "99.8vh",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Stack
        direction="row"
        sx={{
          padding: "12px",
          paddingLeft: "30px",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #15191fa6",
          position: "sticky",
          top: "0",
          backgroundColor: "#000000",
          zIndex: "999",
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h6">Client Conversation</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Button
            variant="outlined"
            startIcon={<CheckIcon stroke={"#5f55ee"} />}
            sx={{
              backgroundColor: (theme) => theme.palette.color.borderpure,
              borderColor: (theme) => theme.palette.color.borderpure,
              borderRadius: "18px",
              padding: "4px 10px",
              color: "#5f55ee",
              fontSize: "11.5px",
              fontWeight: "400",
              transition: "all 0.3s",
              "&:hover": {
                opacity: "0.9",
                background: (theme) => theme.palette.color.borderpure,
                borderColor: (theme) => theme.palette.color.borderpure,
                color: "#5f55ee",
              },
            }}
            className="cust_icon_button"
          >
            Mark as done
          </Button>
          <CustomMenu options={options}>
            <IconButton
              sx={{
                backgroundColor: (theme) => theme.palette.color.borderpure,
                borderColor: (theme) => theme.palette.color.borderpure,
                borderRadius: "18px",
              }}
            >
              <BsThreeDotsVertical style={{ fontSize: "14px" }} />
            </IconButton>
          </CustomMenu>
        </Stack>
      </Stack>
      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",

          overflowY: "auto",
        }}
      >
        <Stack
          sx={{
            overflowY: "auto",
            flexGrow: 1,
            p: 2,
          }}
        >
          <Box sx={{ mb: 2, width: "82%", alignSelf: "end" }}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  p: 2,
                  py: 3,
                  backgroundColor: (theme) => theme.palette.color.border,
                  borderRadius: "18px 0px 18px 18px",
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Hi there 👋
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Is everything going well?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="User Avatar"
                  src="https://framerusercontent.com/images/earXybeYWT9rwQzSPFNGwhgaE9Y.jpg"
                  sx={{ width: 32, height: 32 }}
                />
              </Box>
            </Stack>
            <Stack direction="row" sx={{ mt: 1 }}>
              <Typography variant="caption">1m</Typography>
            </Stack>
          </Box>
          <Box sx={{ mb: 2, width: "82%", alignSelf: "end" }}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  p: 2,
                  py: 3,
                  backgroundColor: (theme) => theme.palette.color.border,
                  borderRadius: "18px 0px 18px 18px",
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Hi there 👋
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Is everything going well?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="User Avatar"
                  src="https://framerusercontent.com/images/earXybeYWT9rwQzSPFNGwhgaE9Y.jpg"
                  sx={{ width: 32, height: 32 }}
                />
              </Box>
            </Stack>
            <Stack direction="row" sx={{ mt: 1 }}>
              <Typography variant="caption">1m</Typography>
            </Stack>
          </Box>
          <Box sx={{ mb: 2, width: "82%", alignSelf: "end" }}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  p: 2,
                  py: 3,
                  backgroundColor: (theme) => theme.palette.color.border,
                  borderRadius: "18px 0px 18px 18px",
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Hi there 👋
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Is everything going well?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="User Avatar"
                  src="https://framerusercontent.com/images/earXybeYWT9rwQzSPFNGwhgaE9Y.jpg"
                  sx={{ width: 32, height: 32 }}
                />
              </Box>
            </Stack>
            <Stack direction="row" sx={{ mt: 1 }}>
              <Typography variant="caption">1m</Typography>
            </Stack>
          </Box>

          <Box sx={{ mb: 2, width: "100%" }}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="User Avatar"
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: (theme) => theme.palette.color.pink,
                    color: (theme) => theme.palette.color.primary,
                  }}
                >
                  J
                </Avatar>
              </Box>
              <Stack gap={1}>
                <Box
                  sx={{
                    p: 2,
                    py: 3,
                    backgroundColor: (theme) => theme.palette.color.border,
                    borderRadius: "0 18px 18px 18px",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                  >
                    I got a question about my evaluation. I bought it 2 days
                    ago, but I did not receive any account credentials yet.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                  >
                    Is it possible to send those again?
                  </Typography>
                </Box>
                <Typography variant="body3">11 min ago</Typography>
              </Stack>
            </Stack>
          </Box>
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Box sx={{ mb: 2, mt: 2, width: "70%", marginLeft: "auto" }}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Stack gap={1} alignItems="flex-start">
                  <Typography variant="caption" color="#5f55ee">
                    AI suggestions
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                  >
                    <EyeOpenIcon stroke={"#8a96a6"} />
                    <Typography variant="caption" color="caption">
                      You can only see this message
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Paper
                sx={{
                  p: 2,
                  mt: 1,
                  backgroundColor: (theme) => theme.palette.color.border,
                  width: "100%",
                  borderRadius: "18px",
                  border: "1px dashed #5f55ee59",
                }}
              >
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                  sx={{
                    color: (theme) => theme.palette.color.secondary,
                  }}
                >
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    sx={{ mb: 1, color: "#cad0db" }}
                  >
                    Account credentials account{" "}
                    <Link sx={{ color: "#cad0db", borderColor: "#cad0db" }}>
                      #1050405
                    </Link>
                  </Typography>
                  <LuCopy size={14} />
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontSize: "13px",
                      lineHeight: "24px",
                      color: "#cad0db",
                    }}
                  >
                    Trading platform
                  </Typography>
                  <Typography
                    variant="caption_500"
                    sx={{
                      fontWeight: "400",
                    }}
                  >
                    Vuetra
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontSize: "13px",
                      lineHeight: "24px",
                      color: "#cad0db",
                    }}
                  >
                    Account number
                  </Typography>
                  <Typography
                    variant="caption_500"
                    sx={{
                      fontWeight: "400",
                    }}
                  >
                    1050405
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontSize: "13px",
                      lineHeight: "24px",
                      color: "#cad0db",
                    }}
                  >
                    Password
                  </Typography>
                  <Typography
                    variant="caption_500"
                    sx={{
                      fontWeight: "400",
                    }}
                  >
                    45%tgL+4/1
                  </Typography>
                </Stack>
              </Paper>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="User Avatar"
                sx={{
                  width: 32,
                  height: 32,
                  background: (theme) => theme.palette.color.active,
                  padding: "7px",
                }}
                src="/logo.avif"
              />
            </Box>
            <Box ref={ref} />
          </Stack>
        </Stack>

        {showHelp && (
          <Box sx={{ mt: 2, mb: 2, position: "fixed", bottom: "55px" }}>
            <Paper
              sx={{
                p: 2,
                backgroundColor: (theme) => theme.palette.color.border,
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Typography variant="caption" color="textSecondary">
                /order-history
              </Typography>
              <Typography variant="body2" color="textSecondary">
                See client's order history
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="caption" color="textSecondary">
                /active-trading-accounts
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Current trading accounts
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="caption" color="textSecondary">
                /trade-history
              </Typography>
              <Typography variant="body2" color="textSecondary">
                See client's latest trade history
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="caption" color="textSecondary">
                /total-purchase-value
              </Typography>
              <Typography variant="body2" color="textSecondary">
                See total purchase value
              </Typography>
            </Paper>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          m: 2,
          p: 2,
          gap: 1.5,
          border: "1px solid #1d232b",
          backgroundColor: "#101419",
          borderRadius: "12px",
          // position: "sticky",
          bottom: "15px",
          zIndex: "9990",
        }}
      >
        <TextField
          fullWidth
          placeholder="Send message..."
          size="small"
          onChange={handleInputChange}
          sx={{
            border: 0,
            backgroundColor: "transparent",
            "& .MuiOutlinedInput-root.Mui-focused": {
              border: 0,
            },
            "& input": {
              padding: "0 !important",
            },
            "& fieldset": {
              border: "0 !important",
              padding: "0 !important",
            },
          }}
        />
        <Stack
          sx={{
            pt: 2,
          }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              sx={{
                padding: "0",
              }}
            >
              <PlusIcon stroke={theme.palette.color.primary} />
            </IconButton>
            <IconButton
              sx={{
                padding: "0",
              }}
            >
              <AttachmentIcon width="13" height="13" />
            </IconButton>
            <IconButton
              sx={{
                padding: "0",
              }}
            >
              <EmojiIcon />
            </IconButton>
          </Stack>
          <ButtonGroup
            variant="outlined"
            sx={{
              "& button": {
                background: (theme) => theme.palette.color.bg5,
                borderColor: (theme) => theme.palette.color.lightgray,
                padding: "4px 10px",
                color: "#cad0db",
                fontSize: "13px",
                fontWeight: "400",
              },
            }}
          >
            <Button
              sx={{
                borderRadius: "16px 0 0 16px",
              }}
            >
              Submit
            </Button>
            <Button
              sx={{
                borderRadius: "0 16px 16px 0",
                color: (theme) => theme.palette.color.secondary,
              }}
            >
              ⌘ Enter
            </Button>
          </ButtonGroup>
        </Stack>
      </Box>
    </Box>
  );
};

export default ClientConversation;
