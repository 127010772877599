import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import LogIcon from "../../../Icons/Log";

const ActivityNotification = ({ notification }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <LogIcon />
          <Typography
            variant="caption_500"
            sx={{
              ml: 1,
              wordSpacing: "0.5px",
              fontWeight: "400",
              lineHeight: "24px",
              maxWidth: "100%",
              pr: "20px",
              "& span": {
                color: (theme) => theme.palette.color.primary,
                mx: "4px",
                whiteSpace: "nowrap",
                fontWeight: "500",
              },
            }}
          >
            {notification.type === "payout" &&
              `You ${notification.status} payout request`}
            {notification.type === "ticket" &&
              `You ${notification.status} ticket`}
            {notification.type === "ipAddress" &&
              `You ${notification.status} IP-Adress: `}
            <span>{notification.id}</span> from{" "}
            <span>{"{{ user }} {{ User ID }}"}</span>
          </Typography>
        </Box>
        <Typography
          variant="body3"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          · 4h ago
        </Typography>
      </Box>
      <Divider sx={{ mt: "24px" }} />
    </Box>
  );
};

export default ActivityNotification;
