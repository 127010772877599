import React, { useState } from 'react';
import { Box, Typography, Stack, Grid, Tabs, Tab, useMediaQuery, Tooltip } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import InfoIcon2 from '../../Icons/Info2';
import { FiArrowUpRight } from 'react-icons/fi';
import PayoutsIcon1 from '../../Icons/Payouts1';
import { ClockIcon } from '@mui/x-date-pickers';
import ShoppingBag from '../../Icons/ShoppingBag';

const CustomerBehaviourAndRetention = () => {
    const [selectedTab, setSelectedTab] = useState("1");

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const infoData = [
        {
            title: "Customer Lifetime Value (CLV)",
            tooltip: "Customer Lifetime Value (CLV)",
            value: "$12,534",
            percent: "3.5%"
        },
        {
            title: "Average Order Value (AOV)",
            tooltip: "Average Order Value (AOV)",
            value: "$12,534",
            subtitle: "3.5%",
        },
        {
            title: "Customer Acquisition Cost (CAC)",
            tooltip: "Customer Acquisition Cost (CAC)",
            value: "$12,534",
            subtitle: "3.5%",
        },
    ]

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.lightgray,
            borderRadius: '12px',
            p: 2,
            color: 'white'
        }}>
            <Box sx={{ width: 'fit-content' }}>
                <Stack direction="row">
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                    >
                        <Tab value="1" label="Repeat Purchases" icon={<ShoppingBag />} iconPosition="start" />
                        <Tab value="2" label="Customer Retention Rate" icon={<PayoutsIcon1 />} iconPosition="start" />
                        <Tab value="3" label="Time to Purchase" icon={<ClockIcon />} iconPosition="start" />
                    </Tabs>
                </Stack>
            </Box>

            {selectedTab === "1" && (
                <Stack rowGap={1} mt={2}>
                    <RepeatPurchases />
                </Stack>
            )}
            {selectedTab === "2" && (
                <Stack rowGap={1} mt={2}>
                    <CustomerRetentionRate />
                </Stack>
            )}
            {selectedTab === "3" && (
                <Stack rowGap={1} mt={2}>
                    <TimeToPurchase />
                </Stack>
            )}

            <Grid container spacing={3} mt={0.2}>
                {infoData.map((item, index) => (
                    <Grid item xs={12} sm={12} md={4} key={index}>
                        <InfoBox
                            item={item}
                            sx={{
                                background: (theme) => theme.palette.color.bg2,
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default CustomerBehaviourAndRetention;


const RepeatPurchases = () => {
    const series = [70, 30];
  
    const options = {
      chart: {
        type: 'pie',
        background: 'transparent',
      },
      labels: ['Multiple Challenge', 'Single Challenge'],
      colors: ['#5F55EE', '#5EEDF8'],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.labels[opts.seriesIndex]
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
          fontWeight: 'normal',
          color: '#8A96A6'
        },
        dropShadow: {
          enabled: false
        },
        textAnchor: 'start',
        distributed: true,
        offsetX: 0,
        offsetY: 0,
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: '0%'
          },
          dataLabels: {
            offset: 20, 
            minAngleToShowLabel: 10
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        },
        active: {
          filter: {
            type: 'none'
          }
        }
      }
    };
  
    return (
      <Box sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        borderRadius: "18px",
        p: 3,
        color: 'white',
        height: '100%',
        width: '100%'
      }}>
        <Typography variant="h6" mb={2}>
          Repeat Purchases
        </Typography>
        <Box sx={{ height: 300, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReactApexChart
            options={options}
            series={series}
            type="pie"
            width="100%"
            height="100%"
          />
        </Box>
      </Box>
    );
  };

  const CustomerRetentionRate = () => {
    const series = [{
      name: 'Retention Rate',
      data: [88, 72, 68, 68, 34, 53, 65, 62.5, 48, 38, 15, 22]
    }];
  
    const options = {
      chart: {
        type: 'area',
        height: 350,
        toolbar: {
          show: false
        },
        background: 'transparent',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2,
        colors: ['#5EEDF8']
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        labels: {
          style: {
            colors: '#8A96A6'
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        max: 90,
        tickAmount: 9,
        labels: {
          style: {
            colors: '#8A96A6'
          },
          formatter: function (value) {
            return value.toFixed(0);
          }
        }
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
        style: {
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
          fontWeight: 'normal',
          color: '#8A96A6'
        },
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.1,
          stops: [0, 90, 100]
        }
      },
      grid: {
        show: false
      }
    };
  
    return (
      <Box sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        borderRadius: "18px",
        p: 3,
        color: 'white',
        height: '100%'
      }}>
        <Typography variant="h6" mb={2}>
          Customer Retention Rate
        </Typography>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </Box>
    );
  };

  const TimeToPurchase = () => {
    const series = [{
      name: 'Time to Purchase',
      data: [4.1, 3.9, 6.1, 3.0, 1.5, 4.9, 1.8, 1.1, 2.4, 3.1, 4.5, 1.2]
    }];
  
    const options = {
      chart: {
        type: 'area',
        height: 350,
        toolbar: {
          show: false
        },
        background: 'transparent',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2,
        colors: ['#5F55EE']
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'sep', 'Oct', 'Nov', 'Dec'],
        labels: {
          style: {
            colors: '#8A96A6'
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        max: 7,
        tickAmount: 7,
        labels: {
          style: {
            colors: '#8A96A6'
          },
          formatter: function (value) {
            return value.toFixed(0);
          }
        }
      },
      tooltip: {
        enabled: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0.1,
          stops: [0, 100]
        },
        colors: ['#5F55EE']
      },
      grid: {
        show: false
      }
    };
  
    return (
      <Box sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        borderRadius: "18px",
        p: 3,
        color: 'white',
        height: '100%'
      }}>
        <Typography variant="h6" mb={2}>
          Time to Purchase
        </Typography>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </Box>
    );
  };

const InfoBox = ({ item, icon, sx, noStat }) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: "24px",
                width: "100%",
                height: "140px",
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",

                    color: (theme) => theme.palette.color.secondary,
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        alignItems: "center",
                    }}
                >
                    <Typography variant="caption">{item.title}</Typography>
                    <Tooltip title={item.tooltip} placement="top">
                        {icon ? icon : <InfoIcon2 fill="none" />}
                    </Tooltip>
                </Stack>
            </Box>
            <Typography variant="h1" fontSize={isSmall ? "20px" : "28px"} mt={3}>{item.value}</Typography>
            <Stack
                direction="row"
                spacing={1}
                mt={1}
                sx={{
                    alignItems: "center",
                    color: (theme) => theme.palette.color.green,
                }}
            >
                <FiArrowUpRight size={16} />
                <Typography variant="caption">{item.percent || "+3.7%"}</Typography>
            </Stack>
        </Box>
    );
};
