import React, { useState, useEffect } from "react";
import { Box, styled } from "@mui/material";

const AnimatedBox = styled(Box)(({ delay = 0, theme }) => ({
  width: 6,
  height: 6,
  backgroundColor: theme.palette.color.secondary,
  animation: "boxAnimation 3s infinite",
  animationDelay: `${delay}s`,
  "@keyframes boxAnimation": {
    "0%, 20%": {
      height: 6,
      transform: "translateY(0)",
    },
    "40%, 60%": {
      height: 6,
      transform: "translateY(-24px)",
    },
    "80%": {
      height: 30,
      transform: "translateY(-12px)",
    },
    "100%": {
      height: 6,
      transform: "translateY(0)",
    },
  },
}));

const BoxAnimation = () => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, 12000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        height: "40px",
        padding: "50px",
      }}
    >
      {[0, 0.5, 1].map((delay, index) => (
        <AnimatedBox key={`${index}-${key}`} delay={delay} />
      ))}
    </Box>
  );
};

export default BoxAnimation;
