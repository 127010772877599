import React from 'react';
import { Stack } from '@mui/material';
import DailyDrawdownChart from '../../components/Risk-Management/Metrics/DailyDrawdownChart';
import OverallDrawdownChart from '../../components/Risk-Management/Metrics/OverallDrawdownChart';
import HFTChart from '../../components/Risk-Management/Metrics/HFTChart';
import ParsingRateAccount from '../../components/Risk-Management/Metrics/ParsingRateAccount';
import PassedAccountChart from '../../components/Risk-Management/Metrics/PassedAccountChart';
import FlaggedAccountsChart from '../../components/Risk-Management/Metrics/FlaggedAccountsChart';
import TimeToBreachAccountTotal from '../../components/Risk-Management/Metrics/TimeToBreachAccountTotal';
import BreachedAccountTotal from '../../components/Risk-Management/Metrics/BreachedAccountTotal';

const RiskManagementMetrics = () => {
  return (
    <Stack spacing={2} sx={{ width: '100%', padding: '20px' }}>
      <DailyDrawdownChart title="Breached Accounts Due to Daily Drawdown" />
      <OverallDrawdownChart title="Breached Accounts Due to Overall Drawdown" />
      <HFTChart title="Breached Accounts Due to HFT" />
      <DailyDrawdownChart title="Breached Accounts Due to News Trading" />
      <OverallDrawdownChart title="Breached Accounts Due to Copy Trading" />
      <HFTChart title="Breached Accounts Due to Hedging"/>
      <DailyDrawdownChart title="Breached Accounts Due to Arbitrage" />
      <BreachedAccountTotal />
      <TimeToBreachAccountTotal />
      <PassedAccountChart />
      <FlaggedAccountsChart />
      <ParsingRateAccount />
    </Stack>
  );
};

export default RiskManagementMetrics;