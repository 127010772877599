import {
    Box,
    Card,
    Paper,
    Stack,
    Tooltip,
    Typography,
    Grid,
    useMediaQuery,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import React, { useState } from "react";
import CustomTable from "../../components/Firm/Orders/Table";
import { PiIntersectThree } from "react-icons/pi";

import { useDispatch } from "react-redux";
import SmallSearchBar from "../../components/Affiliates/smallSearchBar";
import ExportSection from "../../components/ui/ExportSection";
import CalendarPopup from "../../components/ui/CalendarPopup";
import DisplayColumns from "../../components/ui/DisplayColumns";
import dayjs from "dayjs";
import ReviewFlaggedTraders from "../../components/Trading-accounts/ReviewFlaggedTraders";
import RoundFormSelect from "../../components/ui/RoundFormSelect";

const Items = [
    {
        title: "Today's client",
        tooltip: "1841",
        value: "1841",
        key: "active",
    },
    {
        title: "Total Evals",
        tooltip: "14",
        value: "14",
        key: "inactive",
    },
    {
        title: "Total Funded",
        tooltip: "41",
        value: "41",
        key: "inactive",
    },
    {
        title: "Total A-book",
        tooltip: "41",
        value: "41",
        key: "inactive",
    },
];

const statutes = [
    { value: "ongoing", label: "Ongoing", color: "secondary" },
    { value: "passed", label: "Passed", color: "green" },
    { value: "breached", label: "Breached", color: "red" },
];

const data = [
    {
        status: "passed",
        stage: "Funded sim",
        accountNumber: "{{ acc. number }}",
        name: "Bessie Cooper",
        title: "Collection 1",
        description: "Description",
        platform: "Metatrader",
        balance: "{{ in. balance }}",
        challenge: "{{ challenge }}",
        id: "102911050",
        phase: "Phase 1",
        program: "25K Evaluation Program",
        date: "25 Apr, 2024 11:05 AM",
    },
    {
        status: "breached",
        stage: "Funded sim",
        accountNumber: "{{ acc. number }}",
        name: "Bessie Cooper",
        title: "Collection 1",
        description: "Description",
        platform: "Metatrader",
        balance: "{{ in. balance }}",
        challenge: "{{ challenge }}",
        id: "102911050",
        phase: "Phase 1",
        program: "25K Evaluation Program",
        date: "25 Apr, 2024 11:05 AM",
    },
    {
        status: "breached",
        stage: "Funded sim",
        accountNumber: "{{ acc. number }}",
        name: "Bessie Cooper",
        title: "Collection 1",
        description: "Description",
        platform: "Metatrader",
        balance: "{{ in. balance }}",
        challenge: "{{ challenge }}",
        id: "102911050",
        phase: "Phase 1",
        program: "25K Evaluation Program",
        date: "25 Apr, 2024 11:05 AM",
    },
    {
        status: "ongoing",
        stage: "Funded sim",
        accountNumber: "{{ acc. number }}",
        name: "Bessie Cooper",
        title: "Collection 1",
        description: "Description",
        platform: "Metatrader",
        balance: "{{ in. balance }}",
        challenge: "{{ challenge }}",
        id: "102911050",
        phase: "Phase 1",
        program: "25K Evaluation Program",
        date: "25 Apr, 2024 11:05 AM",
    },
    {
        status: "breached",
        stage: "Funded sim",
        accountNumber: "{{ acc. number }}",
        name: "Bessie Cooper",
        title: "Collection 1",
        description: "Description",
        platform: "Metatrader",
        balance: "{{ in. balance }}",
        challenge: "{{ challenge }}",
        id: "102911050",
        phase: "Phase 1",
        program: "25K Evaluation Program",
        date: "25 Apr, 2024 11:05 AM",
    },
    {
        status: "passed",
        stage: "Funded sim",
        accountNumber: "{{ acc. number }}",
        name: "Bessie Cooper",
        title: "Collection 1",
        description: "Description",
        platform: "Metatrader",
        balance: "{{ in. balance }}",
        challenge: "{{ challenge }}",
        id: "102911050",
        phase: "Phase 1",
        program: "25K Evaluation Program",
        date: "25 Apr, 2024 11:05 AM",
    },
    {
        status: "passed",
        stage: "Funded sim",
        accountNumber: "{{ acc. number }}",
        name: "Bessie Cooper",
        title: "Collection 1",
        description: "Description",
        platform: "Metatrader",
        balance: "{{ in. balance }}",
        challenge: "{{ challenge }}",
        id: "102911050",
        phase: "Phase 1",
        program: "25K Evaluation Program",
        date: "25 Apr, 2024 11:05 AM",
    },
    {
        status: "passed",
        stage: "Funded sim",
        accountNumber: "{{ acc. number }}",
        name: "Bessie Cooper",
        title: "Collection 1",
        description: "Description",
        platform: "Metatrader",
        balance: "{{ in. balance }}",
        challenge: "{{ challenge }}",
        id: "102911050",
        phase: "Phase 1",
        program: "25K Evaluation Program",
        date: "25 Apr, 2024 11:05 AM",
    }
];

const headcells = [
    {
        id: "status",
        label: "Status",
        getCell: (row) => {
            const status = statutes.find((statute) => statute.value === row.status);
            return (
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{
                        py: "8px",
                        borderRadius: "20px",
                        width: "fit-content",
                    }}
                >
                    <Box
                        sx={{
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            background: (theme) => theme.palette.color[status.color],
                        }}
                    ></Box>
                    {/* <Typography
                        sx={{
                            fontSize: "11.5px",
                            color: (theme) => theme.palette.color[status.color],
                            fontWeight: 600,
                        }}
                    >
                        {status.label}
                    </Typography> */}
                </Stack>
            );
        },
    },
    {
        id: "stage",
        label: "Stage",
        getCell: (row) => {
            return (
                <Stack sx={{ width: "fit-content", minWidth: "106px" }}>
                    <Typography variant="body1" sx={{ color: (theme) => theme.palette.color.active }}>{row.stage}</Typography>
                </Stack>
            );
        },
    },
    {
        id: "accountNumber",
        label: "Account number",
        getCell: (row) => row.accountNumber,
    },
    {
        id: "name",
        label: "Name",
        getCell: (row) => row.name,
    },
    {
        id: "title",
        label: "Title",
        getCell: (row) => row.title,
    },
    {
        id: "description",
        label: "Description",
        getCell: (row) => row.description,
    },
    {
        id: "platform",
        label: "Platform",
        getCell: (row) => (
            <Stack direction="row" spacing={1} alignItems="center">
                <PiIntersectThree size={16} />
                <p>{row.platform}</p>
            </Stack>
        ),
    },
    {
        id: "balance",
        label: "In. Balance",
        getCell: (row) => row.balance,
    }
];

const Funded = () => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [status, setStatus] = useState("");
    const [heads, setHeads] = React.useState(
        headcells.filter((cell) => cell.default).map((cell) => cell.id)
    );
    const [startDate, setStartDate] = useState(dayjs().startOf("week"));
    const [endDate, setEndDate] = useState(dayjs().endOf("week"));

    return (
        <Paper
            sx={{
                p: "24px",
                width: "100%",
            }}
        >
            <Stack
                direction={isSmallScreen ? "column" : "row"}
                justifyContent={isSmallScreen ? "flex-start" : "space-between"}
                alignItems={isSmallScreen ? "flex-start" : "center"}
                gap={2}
            >
                <Box
                    sx={{
                        ml: "15px",
                        display: "flex",
                        alignItems: "start",
                    }}
                >
                    <Typography variant="h3" mb={2} sx={{ fontSize: "18px", fontWeight: "500"}}>
                        Funded sim
                    </Typography>
                </Box>
                <Stack
                    direction="row"
                    spacing={isSmallScreen ? 1 : 2}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                        mb: "24px",
                        fontSize: "11.5px",
                        pr: "24px",
                        color: (theme) => theme.palette.color.secondary,
                    }}
                >
                    <SmallSearchBar />
                    {/* <ExportSection />
                    <ExportSection isImport={true} /> */}
                    <CalendarPopup
                        mainStartDate={startDate}
                        mainEndDate={endDate}
                        setMainStartDate={setStartDate}
                        setMainEndDate={setEndDate}
                    />
                    {/* <DisplayColumns
                        columns={selectedColumns}
                        setColumns={setSelectedColumns}
                        selectedColumns={heads}
                        setSelectedColumns={setHeads}
                    /> */}
                    <RoundFormSelect
                        options={[{ value: "all", label: "All" }]}
                        defaultValue="all"
                    />
                     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 1.3125V10.6875" stroke="#CAD0DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.6875 6H1.3125" stroke="#CAD0DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Stack>
            </Stack>

            <Grid container spacing={1}>
                {Items.map((item, index) => (
                    <Grid
                        item
                        xs={6}
                        md={3}
                        lg={3}
                        spacing={2}
                        key={index}
                    >
                        <Card
                            // onClick={() => setStatus(item.key)}
                            sx={{
                                width: "100%",
                                border: "1px solid",
                                borderColor: (theme) => theme.palette.color.border,
                                background: (theme) => theme.palette.color.bg5,
                                borderRadius: "16px",
                                overflow: "hidden",
                                padding: "24px",
                                cursor: "pointer",
                            }}
                        >
                            <Stack spacing={3}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            ...(item.key === status && {
                                                color: (theme) => theme.palette.color.green,
                                            }),
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Tooltip title={item.tooltip} placement="top">
                                        <HelpIcon
                                            sx={{
                                                fontSize: "15px",
                                                cursor: "pointer",
                                                color: (theme) =>
                                                    theme.palette.color[
                                                    item.key === status ? "green" : "secondary"
                                                    ],
                                            }}
                                        />
                                    </Tooltip>
                                </Stack>

                                <Typography
                                    variant="h1"
                                    sx={{
                                        ...(item.key === status && {
                                            color: (theme) => theme.palette.color.green,
                                        }),
                                        fontWeight: "600",
                                    }}
                                >
                                    {item.value}
                                </Typography>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>


            <Stack sx={{ width: "100%", overflowX: "auto"}}>
            <CustomTable
                headcells={headcells}
                // rows={data.filter((data) => {
                //   if (!filterData(duration, data.date)) return false;
                //   if (status === "") return true;
                //   if (status === "active") return data.status === "ongoing";
                //   if (status === "inactive")
                //     return data.status === "passed" || data.status === "breached";
                //   return false;
                // })}
                rows={data}
            />
            </Stack>
            <ReviewFlaggedTraders />
        </Paper>
    );
};

export default Funded;
