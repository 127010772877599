import { Avatar, Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme";
import ExpandIcon from "../Icons/Expand";
import CopyIcon from "../Icons/Copy";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ActionRequired = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
        width: "100%",
        borderRadius: "16px",
        mt: "12px",
        overflow: "hidden",
        height: "100%",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
          pb: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "24px",
              color: (theme) => theme.palette.color.primary,
            }}
          >
            Action Required
          </Typography>
          <Box
            sx={{
              width: "24px",
              height: "24px",
              background: (theme) => theme.palette.color.lightgray,
              borderRadius: "4px",
              border: `1px solid #15191F`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="heading">11</Typography>
          </Box>
        </Box>
        <ExpandIcon />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ px: "16px", py: "12px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Open Conversation (3)" {...a11yProps(0)} />
            <Tab label="Payouts (2)" {...a11yProps(1)} />
            <Tab label="Affiliates (2)" {...a11yProps(2)} />
            <Tab label="Flagged (2)" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <Box
          sx={{
            px: 2,
            py: 1,
            minHeight: "420px",
            overflowY: "auto",
            height: "100%",
          }}
        >
          <CustomTabPanel value={value} index={0}>
            <Stack spacing={2} sx={{}}>
              {Array.from({ length: 3 }).map((_, index) => (
                <Box
                  sx={{
                    background: (theme) => theme.palette.color.borderpure,
                    borderRadius: "16px",
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <Stack spacing={2}>
                    <Typography variant="heading_500">
                      Ticket summary title
                    </Typography>
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: (theme) => theme.palette.color.secondary,
                          fontWeight: "400",
                          lineHeight: "18px",
                        }}
                      >
                        ticket id
                      </Typography>
                      {/* <CopyIcon width={20} height={20} viewBox="0 0 20 20" /> */}
                    </Box>
                  </Stack>
                  <Stack spacing={1}>
                    <Box sx={{ display: "flex", gap: "16px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="caption2">Open date</Typography>
                        <Typography variant="subtitle2">
                          11:05 July 15
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="caption2">Open Time</Typography>
                        <Typography variant="subtitle2">1h 15m</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", gap: "16px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="caption2">Collection</Typography>
                        <Typography variant="subtitle2">
                          Collection name
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="caption2">Customer</Typography>
                        {/* <Typography variant="subtitle2">11:05 July 15</Typography> */}
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Avatar
                            src="/images/person.avif"
                            sx={{
                              width: "18px",
                              height: "18px",
                            }}
                          ></Avatar>

                          <Typography variant="subtitle2">
                            10404226508
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default ActionRequired;
