import React from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const DailyDrawdownChart = ({ title }) => {

  const ChartContainer = styled(Box)({
    width: '100%',
    '& .apexcharts-toolbar': {
      display: 'none !important',
    },
  });

  const series = [
    { name: 'Account type A', data: [6, 5, 4] },
    { name: 'Account type B', data: [5, 7, 6] },
    { name: 'Account type C', data: [8, 6, 2] },
  ];

  const options = {
    siblingSpacing: 50,
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '25%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#5EEDF8', '#5F55EE', '#2FE0A2'],
    },
    xaxis: {
      categories: ['Phase 1', 'Phase 2', 'Live accounts'],
      labels: {
        style: {
          colors: ['#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6'],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      padding: {
        left: 10,
        right: 10,
      },
    },
    yaxis: {
      min: 0,
      max: 10,
      tickAmount: 5,
      title: {
        text: "Number of Breached Accounts",
        style: {
          color: '#8A96A6',
        },
      },
      labels: {
        formatter: (val) => `${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    fill: {
      opacity: 1,
      colors: ['#1D232B', '#1D232B'],
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: ['#1D232B'],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bg5,
        border: '1px solid',
        borderColor: (theme) => theme.palette.color.strockborder,
        p: '10px',
        borderRadius: '16px',
        mt: 3,
      }}
    >
      <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h5">
          {title}
        </Typography>
        <Stack direction="row" gap={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 14, height: 14, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Account type A</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 14, height: 14, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Account type B</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 14, height: 14, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Account type C</Typography>
          </Box>
          <RoundFormSelect
            options={[{ value: "accountType", label: "Account Type" }]}
            defaultValue="accountType"
          />
        </Stack>
      </Stack>

      <ChartContainer>
        <ReactApexChart
          options={options}
          series={series.map(({ name, data }, index) => ({
            name,
            data,
            type: 'column',
            fillColor: index === 0 ? {
              colors: ['#5EEDF8'],
              borderColor: '#5EEDF8',
              backgroundBarColors: ['#5EEDF8'],
              backgroundBarOpacity: 1,
            } : index === 1 ? {
              colors: ['#5F55EE'],
              borderColor: '#5F55EE',
              backgroundBarColors: ['#5F55EE'],
              backgroundBarOpacity: 1,
            } : {
              colors: ['#2FE0A2'],
              borderColor: '#2FE0A2',
              backgroundBarColors: ['#2FE0A2'],
              backgroundBarOpacity: 1,
            },
          }))}
          type="bar"
          height={350}
        />
      </ChartContainer>
    </Box>
  );
};

export default DailyDrawdownChart;