import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import FormInput from "../../components/ui/FormInput";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StepperCard from "../../components/Firm/Challenges/StepperCard";
import InfoBox from "../../components/Affiliates/InfoBox";
import AffilateTicket from "../../components/Affiliates/Overview/AffilateTicket";
import ExpandIcon from "../../components/Icons/Expand";
import AffiliateBreakdownByCountry from "../../components/Affiliates/Overview/AffiliateBreakdownByCountry";
import RevenueTrendsByCountry from "../../components/Affiliates/Overview/RevenueTrendsByCountry";

const Overview = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState("1");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const InfoData = [
    {
      title: "Top Affiliate by Revenue",
      tooltip: "Top Affiliate by Revenue",
      value: "$50,000",
    },
    {
      title: "Top Affiliate by Clicks",
      tooltip: "Top Affiliate by Clicks",
      value: "150,000",
    },
    {
      title: "Top Affiliate by Conversions",
      tooltip: "Top Affiliate by Conversions",
      value: "20",
    },
  ]

  return (
    <Box
      sx={{
        p: isSmall ? "15px" : "24px",
        width: "100%",
      }}
    >

      <Grid container spacing={isSmall ? 2 : 3}>
        <Grid item xs={12} sm={12} md={4}>
        <StepperCard horizontalAlign="flex-start"  sx={{
              background: (theme) => theme.palette.color.borderpure,
            }}>
            <Stack
              sx={{
                width: "100%",
              }}
            >
              <Typography variant="h5" >
                Sign up link
              </Typography>
              <Typography variant="caption" mb={"2px"}>
                Share your sign up link
              </Typography>
              <FormInput
                defaultValue="https://www.example.com/affiliate-sign-up"
                sx={{
                  background: (theme) => theme.palette.color.bg2,
                  marginTop: "5px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton>
                      <ContentCopyIcon
                        sx={{
                          fontSize: "16px",
                          color: "#45ccff",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Stack>
          </StepperCard>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <InfoBox
          title={"Active affiliates"}
          tooltip={"Amount of unresolved open tickets"}
          value={"1,305"}
          text={"+3.8%"}
            item={{
              title: "Total Sales",
              value: "45",
            }}
            sx={{
              background: (theme) => theme.palette.color.bg2,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <InfoBox
          title={"Total conversion"}
          tooltip={"Amount of unresolved open tickets"}
          value={"$150,105.00"}
          text={"+3.8%"}
            item={{
              title: "Total Refunds",
              value: "21",
            }}
            sx={{
              background: (theme) => theme.palette.color.bg2,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isSmall ? 2 : 3} mt={0.2}>
        {InfoData.map((item, index) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <InfoBox
              title={item.title}
              tooltip={item.tooltip}
              value={item.value}
            />
          </Grid>
        ))}
      </Grid>

      <AffiliateBreakdownByCountry />
      <RevenueTrendsByCountry />

      <Grid container sx={{ my: "20px" }}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              background: (theme) => theme.palette.color.bg2,
              borderRadius: "16px",
              mb: "20px",
              pb: "12px",
            }}
          >
            <Box
              position={"sticky"}
              top={"0"}
              sx={{
                background: (theme) => theme.palette.color.bg2,
                borderRadius: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "20px 20px 6px",
                  zIndex: "10 !important",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    alignItems: "center",
                    // p: "12px",
                    // pt: "24px",
                  }}
                >
                  <Typography variant="h4">Affilliates</Typography>
                  <Box
                    sx={{
                      width: "24px",
                      height: "24px",
                      background: (theme) => theme.palette.color.lightgray,
                      borderRadius: "4px",
                      border: `1px solid #15191F`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ color: (theme) => theme.palette.color.secondary }} variant="heading">11</Typography>
                  </Box>
                </Stack>
                <ExpandIcon />
              </Box>
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  pl: "20px",
                  zIndex: "10 !important",
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="affiliates"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "32px"
                  }}
                >
                  <Tab sx={{ pl: "0 !important" }} value="1" label="Pending affiliates" />
                  <Tab value="2" label={`Payouts (2)`} />
                  <Tab value="3" label={`Flagged (2)`} />
                </Tabs>
              </Stack>
            </Box>
            {selectedTab === "1" && (
              <>
                <AffilateTicket />
                <AffilateTicket />
                <AffilateTicket />
                <AffilateTicket />
                <AffilateTicket />
              </>
            )}
            {selectedTab === "2" && (
              <>
                <AffilateTicket />
              </>
            )}
            {selectedTab === "3" && (
              <>
                <AffilateTicket />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Overview;