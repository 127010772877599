import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import {
  Add,
  Done,
  Edit,
  EditCalendar,
  VisibilityOff,
  YouTube,
} from "@mui/icons-material";
import CustomTable from "../../../../components/Firm/Orders/Table";
import Breadcrumbs from "../../../../components/ui/Breadcrumbs";
import SearchInput from "../../../../components/ui/NewSearch";
import ExportSection from "../../../../components/ui/ExportSection";
import CalendarPopup from "../../../../components/ui/CalendarPopup";
import DisplayColumns from "../../../../components/ui/DisplayColumns";
import CommentsIcon from "../../../../components/Icons/Comments";
import DotsVerticalIcon from "../../../../components/Icons/Dotvertical";
import dayjs from "dayjs";
import VisibilityMenu from "../../../../components/Firm/Docs/VisibilityMenu";
import {
  useChangeVideoStatusMutation,
  useChangeVideoVisibilityMutation,
  useGetVideosQuery,
} from "../../../../slices/api/videoSlice";
import AddVideo from "../../../../components/Firm/Docs/addVideos";
import { useDispatch } from "react-redux";
import { toggleOpen } from "../../../../slices/openModalSlice";
import PublishedMenu from "../../../../components/Firm/Docs/publishedMenu";
import EyeCloseIcon from "../../../../components/Icons/EyeClose";
import { useGetCollectionsQuery } from "../../../../slices/api/firmSlice";

const statutes = [
  { value: "published", label: "Published", color: "green", icon: Done },
  { value: "draft", label: "Draft", color: "orange", icon: Edit },
  {
    value: "scheduled",
    label: "Scheduled",
    color: "secondary",
    icon: EditCalendar,
  },
  {
    value: "unpublished",
    label: "Unpublished",
    color: "secondary",
    icon: VisibilityOff,
  },
];

const data = [
  {
    name: "Title video",
    description: "Description",
    status: "unpublished",
    collection: "Collection",

    visibility: "public",
  },
  {
    name: "Title video",
    description: "Description",
    status: "unpublished",
    collection: "Collection",

    visibility: "public",
  },
  {
    name: "Title video",
    description: "Description",
    status: "unpublished",
    collection: "Collection",

    visibility: "public",
  },
  {
    name: "Title video",
    description: "Description",
    status: "unpublished",
    collection: "Collection",

    visibility: "public",
  },
  {
    name: "Title video",
    description: "Description",
    status: "unpublished",
    collection: "Collection",

    visibility: "public",
  },
  {
    name: "Title video",
    description: "Description",
    status: "unpublished",
    collection: "Collection",

    visibility: "public",
  },
  {
    name: "Title video",
    description: "Description",
    status: "unpublished",
    collection: "Collection",

    visibility: "public",
  },
  {
    name: "Title video",
    description: "Description",
    status: "unpublished",
    collection: "Collection",

    visibility: "public",
  },
  {
    name: "Title video",
    description: "Description",
    status: "unpublished",
    collection: "Collection",

    visibility: "public",
  },
];

const publishedTypes = [
  {
    value: "published",
    label: "Publish",
    selectedLabel: "Published",
    description: "Publish item to now",
    selectedDescription: "Item is live now",
    color: "green",
    icon: Done,
  },
  {
    value: "unpublished",
    label: "Unpublish",
    selectedLabel: "Unpublished",
    description: "Hide article from audience",
    selectedDescription: "Hide article from audience",
    color: "secondary",
    icon: EyeCloseIcon,
  },

  {
    value: "draft",
    label: "Save as Draft",
    selectedLabel: "Draft",
    description: "Save for further editing",
    selectedDescription: "Item is saved for future editing",
    color: "orange",
    icon: Edit,
  },
];

const Videos = () => {
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [video, setVideo] = useState(null);

  const dispatch = useDispatch();

  const { data } = useGetVideosQuery();
  const { data: collections } = useGetCollectionsQuery();
  const [changeStatus] = useChangeVideoStatusMutation();
  const [changeVisibility] = useChangeVideoVisibilityMutation();

  const headcells = [
    {
      id: "title",
      label: "Name",
      getCell: (row) => <Typography variant="body3">{row.title}</Typography>,
      default: true,
    },

    {
      id: "description",
      label: "Description",
      getCell: (row) => row.description,
      default: true,
    },
    {
      id: "collection",
      label: "Collection",
      getCell: (row) => {
        const collection = [...(collections?.data?.data || [])].find(
          (collection) => collection.id === row.collection_id
        );
        return collection?.name || "No Collection";
      },
      default: true,
    },
    {
      id: "source",
      label: "Source",
      getCell: (row) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <YouTube
            sx={{
              fontSize: "16px",
              color: (theme) => theme.palette.color.secondary,
            }}
          />
          <Typography variant="body3">YouTube</Typography>
        </Box>
      ),
      default: true,
    },

    {
      id: "status",
      label: "Status",
      getCell: (row) => {
        const type = publishedTypes.find((type) => type.value === row.status);
        return (
          <PublishedMenu
            changeStatus={changeStatus}
            id={row.id}
            types={publishedTypes}
            type={type}
          />
        );
      },
      default: true,
    },
    {
      id: "visibility",
      label: "Visibility",
      getCell: (row) => {
        return (
          <VisibilityMenu
            changeVisibility={changeVisibility}
            id={row.id}
            value={row.visibility}
          />
        );
      },
      default: true,
    },
  ];

  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(
    headcells.filter((cell) => cell.default).map((cell) => cell.id)
  );

  const ActionComponent = ({ row }) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton
          onClick={() => {
            setVideo(row);
            dispatch(toggleOpen());
          }}
        >
          <CommentsIcon />
        </IconButton>

        <IconButton>
          <DotsVerticalIcon />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Paper
      sx={{
        px: "0px",
        py: "24px",
        width: "100%",
      }}
    >
      <Typography variant="h4" sx={{ pl: "24px" }}>
        Videos
      </Typography>
      <Box
        sx={{
          fontSize: "11.5px",
          color: (theme) => theme.palette.color.secondary,
          px: { xs: "0px", lg: "24px" },
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "flex-start", lg: "space-between" },

          gap: 2,
        }}
      >
        <Breadcrumbs path={"Your Firm/Docs"} title={"Vidoe's"} />
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />
          <ExportSection />
          <ExportSection isImport={true} />

          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
          <DisplayColumns
            columns={columns}
            setColumns={setColumns}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              ml: "18px !important",
              mr: "4px !important",
              border: (theme) => `1px solid ${theme.palette.color.lightgray}`,
              height: "21px",
              my: "auto !important",
            }}
          />
          <IconButton
            onClick={() => {
              dispatch(toggleOpen());
            }}
          >
            <Add />
          </IconButton>
        </Stack>
      </Box>

      <CustomTable
        headcells={columns.filter((cell) => heads.includes(cell.id))}
        rows={[...(data?.data?.data || [])]}
        actionComponent={(row) => <ActionComponent row={row} />}
      />
      <AddVideo video={video} />
    </Paper>
  );
};

export default Videos;
