import * as React from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Collapse,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ChevronDownIcon from "../../Icons/ChevronDown";
import { Done } from "@mui/icons-material";
import { TbPhotoCircle, TbShield } from "react-icons/tb";
import FormSwitch from "../../ui/FormSwitch";
import ChevronRightIcon from "../../Icons/ChevronRight";

const types = [
  {
    value: "private",
    label: "Private",
    color: "pink",
    icon: TbShield,
    description: "Article is visible on client - and company level",
  },
  {
    value: "public",
    label: "Public",
    color: "cyan",
    icon: TbPhotoCircle,
    description: "Article is only visisble at company level",
  },
];

export default function VisibilityMenu({ value, id, changeVisibility }) {
  const theme = useTheme();
  const [v, setV] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id1 = open ? "simple-popover" : undefined;
  const type = types.find((t) => t.value === v);

  const handleSubmit = async (data) => {
    if (changeVisibility) await changeVisibility(data);
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={
          <Box
            sx={{
              color: (theme) => theme.palette.color[type?.color],
              lineHeight: 0,
            }}
          >
            {type?.icon && <type.icon size={16} />}
          </Box>
        }
        sx={{
          background: "transparent",
          p: "0",
          pr: "6px",
          color: (theme) => theme.palette.color.primary,
          fontSize: "11.5px",
          fontWeight: 500,
          "&:hover": {
            background: "transparent",
          },
        }}
        endIcon={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg": {
                transition: "transform 0.3s",
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
              },
              lineHeight: 0,
            }}
          >
            <ChevronDownIcon stroke={theme.palette.color.primary} />
          </Box>
        }
      >
        {type?.label}
      </Button>
      <Popover
        id={id1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            padding: "12px",
            borderRadius: "18px",
            width: "240px",
            background: (theme) => theme.palette.color.bg2,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack spacing={2}>
          {types.map((t) => (
            <Box>
              <Stack
                onClick={() => {
                  setV(t.value);
                  handleSubmit({ id, visibility: t.value });
                }}
                spacing={1}
                sx={{
                  p: "12px",
                  cursor: "pointer",
                  borderRadius: "8px",
                  "&:hover": {
                    background: (theme) => theme.palette.color.gray,
                  },
                  ...(t.value === value && {
                    background: (theme) => theme.palette.color.gray,
                  }),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      color: (theme) => theme.palette.color[t.color],
                    }}
                  >
                    <t.icon size={16} />
                    <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                      {t.label}
                    </Typography>
                  </Box>
                  {t.value === value && (
                    <Done
                      sx={{
                        fontSize: "14px",
                        color: (theme) => theme.palette.color.active,
                      }}
                    />
                  )}
                </Box>
                <Typography variant="body3">{t.description}</Typography>
                {t.value === "private" && value === "private" && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderTop: (theme) =>
                          `1px solid ${theme.palette.color.lightgray}`,
                        paddingTop: "12px",
                        "& svg": {
                          transition: "transform 0.3s",
                          transform: checked ? "rotate(90deg)" : "rotate(0deg)",
                        },
                      }}
                    >
                      <FormSwitch
                        sx={{
                          m: "0px",
                        }}
                        label="Specific teams"
                        flex
                        flexDirection="row-reverse"
                        justifyContent="start"
                        gap={1}
                        value={checked}
                        onChange={() => setChecked(!checked)}
                      />
                      <ChevronRightIcon />
                    </Box>
                  </>
                )}
              </Stack>
              {t.value === "private" && value === "private" && (
                <Collapse in={checked}>
                  <Stack spacing={2} sx={{ py: "12px" }}>
                    {[
                      "Finance",
                      "Customer Support",
                      "Management",
                      "Development",
                      "Moderators",
                    ].map((team) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderRadius: "18px",
                          p: "10px 12px",
                          cursor: "pointer",
                          "&:hover": {
                            background: (theme) => theme.palette.color.gray,
                          },
                        }}
                        onClick={() => {
                          if (selected.includes(team)) {
                            setSelected(selected.filter((s) => s !== team));
                          } else {
                            setSelected([...selected, team]);
                          }
                        }}
                      >
                        <Typography variant="caption_500">{team}</Typography>
                        {selected.includes(team) && (
                          <Done
                            sx={{
                              color: (theme) => theme.palette.color.active,
                              fontSize: "14px",
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Stack>
                </Collapse>
              )}
            </Box>
          ))}
        </Stack>
      </Popover>
    </div>
  );
}
