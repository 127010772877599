import { useTheme } from "@mui/material";
import * as React from "react";

const ComplianceAndMonitoringIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_3983_32676"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={18}
        height={18}
      >
        <path d="M3 3H21V21H3V3Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3983_32676)">
        <path
          d="M15.3047 19.9453H8.69531C8.26816 19.9453 7.92188 19.599 7.92188 19.1719C7.92188 18.7447 8.26816 18.3984 8.69531 18.3984H15.3047C15.7318 18.3984 16.0781 18.7447 16.0781 19.1719C16.0781 19.599 15.7318 19.9453 15.3047 19.9453Z"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6411 18.3984H9.35889L9.86865 16.4297H14.1313L14.6411 18.3984Z"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.582 4.05469H19.2422C20.0188 4.05469 20.6484 4.6843 20.6484 5.46094V15.0234C20.6484 15.8001 20.0188 16.4297 19.2422 16.4297H5.86865"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.65623 15.8984C3.4655 15.6584 3.35156 15.3546 3.35156 15.0242V5.46174C3.35156 4.68507 3.98114 4.05549 4.75781 4.05549H10.418"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.72729 13.8984H3.50391"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.08507 14.2122L4.9646 17.3326C4.5956 17.7016 3.99731 17.7016 3.62831 17.3326C3.25931 16.9636 3.25931 16.3653 3.62831 15.9963L6.74878 12.8759"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1103 14.7422C7.96161 14.7422 6.21973 13.0003 6.21973 10.8516C6.21973 8.70287 7.96161 6.96098 10.1103 6.96098C12.259 6.96098 14.0009 8.70287 14.0009 10.8516C14.0009 13.0003 12.259 14.7422 10.1103 14.7422Z"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1018 8.03125H15.5649"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1016 6.34375H17.0664"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6936 11.4062H15.5649"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2402 11.4062H19.3982"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5635 13.8711H20.4026"
          stroke={strokeColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ComplianceAndMonitoringIcon;