import { Avatar, Box, Button, Popover, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MailIcon from "../../Icons/Mail";
import ClockIcon from "../../Icons/Clock";
import PhoneIcon from "../../Icons/PhoneIcon";
import LoadingScreen from "../../ui/Loadingscreen";

export default function CustomerDetailsPopup() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [anchorEl]);

  const handleMouseEnter = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        onMouseEnter={handleMouseEnter}
        sx={{ position: "relative", height: "fit-content" }}
      >
        <Avatar
          sx={{
            width: "28px",
            height: "28px",
          }}
          src="/images/person.avif"
        ></Avatar>
        <Box
          sx={{
            position: "absolute",
            bottom: "0",

            right: 0,
            height: "8px",
            width: "8px",
            borderRadius: "50%",
            background: (theme) => theme.palette.color.green,
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            background: (theme) => theme.palette.color.bg2,
            border: (theme) => `1px solid ${theme.palette.color.border}`,
            borderRadius: "10px",
            width: "293px",
            height: "317px",
            padding: "14px",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <LoadingScreen />
            </Box>
          ) : (
            <Stack
              spacing={2.5}
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Stack spacing={1}>
                  <Typography variant="h5">Evi Keller</Typography>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="body3"
                      sx={{
                        fontWeight: "400",
                      }}
                    >
                      @team Title
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box
                    sx={{
                      width: "6px",
                      height: "6px",
                      background: (theme) => theme.palette.color.green,
                      borderRadius: "50%",
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: "500",
                      color: (theme) => theme.palette.color.green,
                    }}
                  >
                    Online
                  </Typography>
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <Stack direction="row" spacing={0.6} alignItems="center">
                  <MailIcon width="25" height="28" />
                  <Typography variant="caption_500" sx={{ fontWeight: "400" }}>
                    info@email.com
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.6} alignItems="center">
                  <ClockIcon width="25" height="25" />
                  <Typography variant="caption_500" sx={{ fontWeight: "400" }}>
                    1:50 pm GMT +1
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.6} alignItems="center">
                  <PhoneIcon width="24" height="20" />
                  <Typography variant="caption_500" sx={{ fontWeight: "400" }}>
                    (+31) 6 22 02 36 57
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button
                  fullWidth
                  sx={{
                    borderRadius: "18px",
                    background: (theme) => theme.palette.color.bg5,
                    color: (theme) => theme.palette.color.secondary,
                    fontSize: "13px",
                    fontWeight: "400",
                    ":hover": {
                      background: (theme) => theme.palette.color.bg5,
                    },
                  }}
                >
                  View activity
                </Button>
                <Button
                  fullWidth
                  sx={{
                    borderRadius: "18px",
                    background: (theme) => theme.palette.color.borderpure,
                    color: (theme) => theme.palette.color.secondary,
                    fontSize: "13px",
                    fontWeight: "400",
                    ":hover": {
                      background: (theme) => theme.palette.color.borderpure,
                    },
                  }}
                >
                  View account
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </Popover>
    </>
  );
}
