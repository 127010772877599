import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Edit } from "@mui/icons-material";
import CollapsibleTable from "../../components/ui/Collapsibletable";
import { BiPlus } from "react-icons/bi";
import AddChallengesModal from "../../components/Firm/addChallenges";
import PlusIcon from "../../components/Icons/Plus";
import SelectCurrencies from "../../components/Firm/Challenges/SelectCurrencies";
import { currencies } from "../../static/challenges";
import { useGetChallengesQuery } from "../../slices/api/firmSlice";
import { getChallengeData } from "../../utils/getChallengesData";

const data = [
  {
    name: "Challenge 1",
    status: "published",
    activeDiscounts: 2,
    price: "",
    children: [
      {
        name: "1,000,000",
        status: "published",
        activeDiscounts: 2,
        price: "$1500.50",
      },
      {
        name: "500,000",
        status: "published",
        activeDiscounts: 2,
        price: "$1500.50",
      },
      {
        name: "250,000",
        status: "published",
        activeDiscounts: 2,
        price: "$1500.50",
      },
      {
        name: "100,000",
        status: "published",
        activeDiscounts: 2,
        price: "$1500.50",
      },
      {
        name: "50,000",
        status: "published",
        activeDiscounts: 2,
        price: "$1500.50",
      },
    ],
  },
  {
    name: "Challenge 2",
    status: "published",
    activeDiscounts: 2,
    price: "",
    children: [
      {
        name: "Challenge",
        status: "published",
        activeDiscounts: 2,
        price: "$1500.50",
      },
    ],
  },
  {
    name: "Challenge 3",
    status: "published",
    activeDiscounts: 2,
    price: "",
    children: [
      {
        name: "Challenge",
        status: "published",
        activeDiscounts: 2,
        price: "$1500.50",
      },
    ],
  },
  {
    name: "Challenge 4",
    status: "published",
    activeDiscounts: 2,
    price: "",
    children: [
      {
        name: "Challenge",
        status: "published",
        activeDiscounts: 2,
        price: "$1500.50",
      },
    ],
  },
  {
    name: "Challenge 5",
    status: "published",
    activeDiscounts: 2,
    price: "",
    children: [
      {
        name: "Challenge",
        activeDiscounts: 21,
        collection: "Collection",
        status: "published",
        source: "Source",
      },
    ],
  },
];

const Challenges = () => {
  const [selected, setSelected] = useState(currencies[0]);
  const [activeCurrencies, setActiveCurrencies] = useState(
    currencies.filter((c, i) => i < 4).map((c) => c.label)
  );
  // const { data: challenges } = useGetChallengesQuery();

  const headcells = [
    {
      id: "name",
      label: (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            minWidth: "300px",
          }}
        >
          <SelectCurrencies
            selected={selected}
            setSelected={setSelected}
            activeCurrencies={activeCurrencies}
            setActiveCurrencies={setActiveCurrencies}
          />
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: "inherit",
              color: (theme) => theme.palette.color.secondary,
            }}
          >
            Collections and size
          </Typography>
        </Stack>
      ),
      getCell: (row) => <Typography variant="heading">{row.name}</Typography>,
      noSort: true,
    },

    {
      id: "activeDiscounts",
      label: "Active discounts",
      getCell: (row) => row.activeDiscounts,
    },
    {
      id: "currencies",
      label: "Currencies",
      getCell: (row) => (
        <Box sx={{ display: "flex", mx: "4px" }}>
          {currencies.map((currency) => (
            <Box
              key={currency.label}
              sx={{
                marginLeft: "-3px",
                border: (theme) =>
                  `1px solid ${theme.palette.color.borderpure}`,
                height: "fit-content",
                lineHeight: 0,
                borderRadius: "50%",
              }}
            >
              <img
                src={`/images/country-icons/${currency.img}.svg`}
                alt={currency.label}
              />
            </Box>
          ))}
        </Box>
      ),
    },

    {
      id: "price",
      label: "Price",
      getCell: (row) => row.price,
    },
  ];
  const headcells2 = [
    {
      id: "name",
      label: "Collections and size",
      getCell: (row) => (
        <Typography
          sx={{
            fontSize: "inherit",
            color: (theme) => theme.palette.color.secondary,
            "& span": {
              color: (theme) => theme.palette.color.primary,
            },
          }}
        >
          <span>{selected.symbol}</span> {row.name}
        </Typography>
      ),
    },

    {
      id: "activeDiscounts",
      label: "Active discounts",
      getCell: (row) => row.activeDiscounts,
    },
    {
      id: "currencies",
      label: "Currencies",
      getCell: (row) => row.currencies,
    },

    {
      id: "price",
      label: "Price",
      getCell: (row) => row.price,
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    headcells.map((h) => h.id)
  );
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const theme = useTheme();

  const { data: challenges, isLoading, isError } = useGetChallengesQuery();

  const data = useMemo(
    () =>
      getChallengeData({ challenges: [...(challenges?.data || [])], selected }),
    [challenges, selected]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Failed to fetch challenges</div>;
  }

  return (
    <Paper
      sx={{
        px: "12px",
        py: "24px",
        width: "100%",
        background: "transparent",
      }}
    >
      <Stack
        // direction="row"
        justifyContent="space-between"
        // alignItems="center"
        sx={{
          px: { xs: 0, lg: "24px" },

          flexDirection: { xs: "column", lg: "row" },
          alignItems: { xs: "flex-start", lg: "center" },
          gap: { xs: 1, lg: 2 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "500",
          }}
        >
          Challenges
        </Typography>
        <Box display="flex">
          <Button
            variant="contained"
            startIcon={<PlusIcon stroke={theme.palette.color.bg5} />}
            onClick={handleOpen}
            sx={{
              mt: 2,
              backgroundColor: (theme) => theme.palette.color.active,
              color: (theme) => theme.palette.color.bg5,
              fontSize: "13px",
              fontWeight: "500",
              "&:hover": {
                color: (theme) => theme.palette.color.bg5,
                backgroundColor: (theme) => theme.palette.color.active,
                opacity: 0.8,
              },
              "& span": {
                marginRight: "0px",
              },
              py: "4px",
              borderRadius: 20,
            }}
          >
            Add challenge
          </Button>
        </Box>
      </Stack>
      <CollapsibleTable
        headcells={headcells.filter((headcell) =>
          selectedColumns.includes(headcell.id)
        )}
        rows={data}
        headcells2={headcells2}
        // onRowClick={(row) => {
        //   navigate(`/firm/customers/${row.name}`);
        // }}
        fontSize="13px"
      />
      <AddChallengesModal
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </Paper>
  );
};

export default Challenges;
