import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import RoundFormSelect from '../../ui/RoundFormSelect';

const OverallDrawdownChart = ({ title }) => {

  const lowPerformanceData = {
    series: [
      { name: 'Account type A', data: [2, 5, 4] },
      { name: 'Account type B', data: [5, 4, 6] },
      { name: 'Account type C', data: [3, 8, 5] },
    ],
    categories: ['Phase 1', 'Phase 2', 'Live accounts'],
    colors: ['#5EEDF8', '#2FE0A2', '#5F55EE'],
  };

  const options = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
        borderRadius: 2,
      },
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: lowPerformanceData.categories,
      labels: { style: { colors: '#8A96A6' } },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      min: 0,
      max: 10,
      tickAmount: 5,
      title: {
        text: "Number of Breached Accounts",
        style: {
          color: '#8A96A6',
        },
      },
      labels: {
        formatter: (val) => `${val}`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 3,
      curve: 'straight',
    },
    fill: { opacity: 1 },
    colors: lowPerformanceData.colors,
    grid: { show: false },
    markers: {
      size: 4,
      colors: undefined,
      strokeWidth: 0,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 0.8,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <Box sx={{
      p: 2,
      mt: 3,
      background: (theme) => theme.palette.color.bg5,
      borderRadius: "18px",
      border: "1px solid",
      borderColor: (theme) => theme.palette.color.border,
    }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">{title}</Typography>
        <Stack direction="row" gap={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Account type A</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Account type B</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Account type C</Typography>
          </Box>
          <RoundFormSelect options={[{ value: "weekly", label: "Weekly" }, { value: "monthly", label: "Monthly" }]} defaultValue="weekly" />
        </Stack>
      </Box>
      <ReactApexChart options={options} series={lowPerformanceData.series} type="line" height={350} />
    </Box>
  );
};

export default OverallDrawdownChart;