import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Add, Done, Edit, EditCalendar } from "@mui/icons-material";

import Breadcrumbs from "../../ui/Breadcrumbs";
import SearchInput from "../../ui/NewSearch";
import ExportSection from "../../ui/ExportSection";
import CalendarPopup from "../../ui/CalendarPopup";
import DisplayColumns from "../../ui/DisplayColumns";
import CommentsIcon from "../../Icons/Comments";
import EyeOpenIcon from "../../Icons/EyeOpen";
import EyeCloseIcon from "../../Icons/EyeClose";
import DotsVerticalIcon from "../../Icons/Dotvertical";
import dayjs from "dayjs";
import VisibilityMenu from "./VisibilityMenu";
import PublishedMenu from "./publishedMenu";
import AddArticle from "./addArticles";
import { useDispatch } from "react-redux";
import ViewArticle from "./ViewArticle";

import moment from "moment";
import CustomTable from "../Orders/Table";
import {
  useChangeArticleStatusMutation,
  useChangeArticleVisibilityMutation,
} from "../../../slices/api/articleSlice";
import { toggleOpen } from "../../../slices/openModalSlice";
import { useGetCollectionsQuery } from "../../../slices/api/firmSlice";

const publishedTypes = [
  {
    value: "published",
    label: "Publish",
    selectedLabel: "Published",
    description: "Publish item to now",
    selectedDescription: "Item is live now",
    color: "green",
    icon: Done,
  },
  {
    value: "unpublished",
    label: "Unpublish",
    selectedLabel: "Unpublished",
    description: "Hide article from audience",
    selectedDescription: "Hide article from audience",
    color: "secondary",
    icon: EyeCloseIcon,
  },
  {
    value: "scheduled",
    label: "Schedule",
    selectedLabel: "Scheduled",
    description: "Publish item on a scheduled date",
    selectedDescription: "Item is schedule on " + dayjs().format("DD MMM YYYY"),
    color: "secondary",
    icon: EditCalendar,
  },
  {
    value: "draft",
    label: "Save as Draft",
    selectedLabel: "Draft",
    description: "Save for further editing",
    selectedDescription: "Item is saved for future editing",
    color: "orange",
    icon: Edit,
  },
];

const Articles = ({ rows }) => {
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));

  const [open, setOpen] = useState(false);
  const { data: collectionsData } = useGetCollectionsQuery();
  const [article, setArticle] = useState(null);
  const [changeStatus] = useChangeArticleStatusMutation();
  const [changeVisibility] = useChangeArticleVisibilityMutation();

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = Math.ceil(rows?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const handleChangePage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const collections = collectionsData?.data?.data || [];

  const dispatch = useDispatch();

  const headcells = [
    {
      id: "name",
      label: "Name",
      getCell: (row) => row.name,
      default: true,
    },

    {
      id: "status",
      label: "Status",
      getCell: (row) => {
        const type = publishedTypes.find((type) => type.value === row.status);
        return (
          <PublishedMenu
            changeStatus={changeStatus}
            id={row.id}
            types={publishedTypes}
            type={type}
          />
        );
      },
      default: true,
    },
    {
      id: "date",
      label: "Date",
      getCell: (row) => moment(row.created_at).format("HH:mm DD MMM, YYYY"),
      default: true,
    },
    {
      id: "collection",
      label: "Collection",
      getCell: (row) => {
        const collection = [...(collections?.data?.data || [])].find(
          (collection) => collection.id === row.collection_id
        );
        return collection?.name || "No Collection";
      },
      default: true,
    },

    {
      id: "published",
      label: "Published",
      getCell: (row) => moment(row.published_at).format("HH:mm DD MMM, YYYY"),
      default: true,
    },
    {
      id: "visibility",
      label: "Visibility",
      getCell: (row) => {
        return (
          <VisibilityMenu
            changeVisibility={changeVisibility}
            id={row.id}
            value={row.visibility}
          />
        );
      },
      default: true,
    },
  ];

  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(
    headcells.filter((cell) => cell.default).map((cell) => cell.id)
  );

  const paginatedData = rows?.slice(
    (currentPage - 1) * itemsPerPage,
    Math.min(currentPage * itemsPerPage, rows?.length)
  );

  const ActionComponent = ({ row }) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton
          onClick={() => {
            setArticle(row);
            dispatch(toggleOpen());
          }}
        >
          <CommentsIcon />
        </IconButton>
        <IconButton onClick={() => setOpen(true)}>
          <EyeOpenIcon />
        </IconButton>
        <IconButton>
          <DotsVerticalIcon />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Paper
      sx={{
        px: "0px",
        py: "24px",
        width: "100%",
      }}
    >
      <Typography variant="h4" sx={{ pl: "24px" }}>
        All Articles
      </Typography>
      <Box
        sx={{
          fontSize: "11.5px",
          color: (theme) => theme.palette.color.secondary,
          px: { xs: "0px", lg: "24px" },
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "flex-start", lg: "space-between" },

          gap: 2,
        }}
      >
        <Breadcrumbs path={"Your Firm/Docs"} title={"All articles"} />
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />
          <ExportSection />
          <ExportSection isImport={true} />

          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
          <DisplayColumns
            columns={columns}
            setColumns={setColumns}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              ml: "18px !important",
              mr: "4px !important",
              border: (theme) => `1px solid ${theme.palette.color.lightgray}`,
              height: "21px",
              my: "auto !important",
            }}
          />
          <IconButton onClick={() => dispatch(toggleOpen())}>
            <Add />
          </IconButton>
        </Stack>
      </Box>

      <CustomTable
        headcells={columns.filter((cell) => heads.includes(cell.id))}
        rows={[...(paginatedData || [])]}
        actionComponent={(row) => <ActionComponent row={row} />}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        handleChangePage={handleChangePage}
        totalPages={totalPages}
        setItemsPerPage={setItemsPerPage}
      />
      <AddArticle article={article} />
      <ViewArticle open={open} setOpen={setOpen} />
    </Paper>
  );
};

export default Articles;
