import { useTheme } from "@mui/material";

export default function AttachmentIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6721 7.951L8.8007 13.861C6.95942 15.713 3.97382 15.713 2.13254 13.861C1.69448 13.4269 1.346 12.9043 1.10842 12.3251C0.870834 11.746 0.749131 11.1224 0.750748 10.4926C0.752366 9.86281 0.87727 9.23995 1.11783 8.66213C1.35838 8.08431 1.70953 7.56366 2.14982 7.132L7.56806 1.701C8.82854 0.433 10.8705 0.433 12.13 1.701C12.4295 1.99804 12.6677 2.35554 12.8301 2.75169C12.9926 3.14784 13.0758 3.57431 13.0747 4.00508C13.0736 4.43585 12.9883 4.86187 12.8239 5.25712C12.6595 5.65238 12.4194 6.00859 12.1185 6.304L6.63878 11.786C6.30679 12.1152 5.86596 12.2989 5.40758 12.2989C4.9492 12.2989 4.50837 12.1152 4.17638 11.786C4.01515 11.6262 3.88678 11.4339 3.79906 11.2208C3.71135 11.0078 3.66612 10.7783 3.66612 10.5465C3.66612 10.3147 3.71135 10.0852 3.79906 9.87217C3.88678 9.6591 4.01515 9.46682 4.17638 9.307L8.71334 4.741"
        stroke={stroke || theme.palette.color.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
