import React from "react";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import LineChart from "../../Charts/LineChart";

const ResponseAndResolutionChart = () => {
  const theme = useTheme();
  const options = {
    markers: {
      size: 4,
      colors: undefined,
      strokeWidth: 0,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 0.8,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    yaxis: {
      min: 5,
      max: 35,
      tickAmount: 6,
      labels: {
        style: {
          colors: theme.palette.color.secondary,
          fontSize: "13px",
        },
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: ["", "Email", "Chat", "Phone", "Social media", ""],
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          colors: theme.palette.color.secondary,
          fontSize: "13px",
        },
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 12,
    },
    stroke: {
      curve: "straight",
      width: 4,
      colors: [theme.palette.color.active, theme.palette.color.cyan],
      dropShadow: {
        enabled: true,
        top: 3,
        left: 3,
        blur: 3,
        opacity: 0.2,
      },
    },
  };

  const series = [
    {
      name: "Average Response Time",
      data: [null, 10, 26, 14, 22, null],
    },
    {
      name: "Average Resolution Time",
      data: [null, 22, 14, 25, 14, null],
    },
  ];

  return (
    <Grid
      item
      xs={12}
      lg={6}
      mt={3}
      sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
      }}
    >
      <Stack spacing={3} sx={{ p: "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              Average Response And Resolution Time
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={0.7} alignItems="center">
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: (theme) => theme.palette.color.active,
                }}
              />
              <Typography variant="caption">Average Response Time</Typography>
            </Stack>
            <Stack direction="row" spacing={0.7} alignItems="center">
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: theme.palette.color.cyan,
                }}
              />
              <Typography variant="caption">Average Resolution Time</Typography>
            </Stack>
          </Stack>
        </Box>
        <LineChart
          height="300"
          categories={["", "Email", "Chat", "Phone", "Social media", ""]}
          colors={[theme.palette.color.active, theme.palette.color.cyan]}
          options={options}
          series={series}
        />
      </Stack>
    </Grid>
  );
};

export default ResponseAndResolutionChart;
