import { Box, Paper, Stack, Typography, Modal } from "@mui/material";

import React from "react";

import ChevronDown from "../../Icons/ChevronDown";
import NavGroup from "../Sidebar/NavGroup";

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: (theme) => `2px solid ${theme.palette.color.borderpure}`,
  borderRadius: "10px",
  margin: "0",
  outline: "none",
};

const SubMenu = ({ childItem, navItem }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          cursor: "pointer",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 12px",
          background: (theme) => theme.palette.color.bg2,
          borderRadius: "10px",
          border: (theme) => `2px solid ${theme.palette.color.borderpure}`,
        }}
        onClick={() => setOpen(true)}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {childItem.icon && <childItem.icon size={22} />}
          {/* <childItem.icon size={22} /> */}
          <Typography variant="h5" sx={{ ml: "3" }}>
            {childItem.title}
          </Typography>
        </Box>
        <ChevronDown />
      </Box>
      <Modal open={open} onClose={handleClose} anchor="right" sx={{}}>
        <Box sx={style}>
          <Paper
            sx={{
              width: "246px",
              height: "80vh",
              overflowY: "auto",
              background: (theme) => theme.palette.color.bg2,
              borderRadius: "none",
            }}
          >
            <Stack
              justifyContent="space-between"
              sx={{ height: "100%" }}
              spacing={3}
            >
              <Stack>
                {navItem.children.map((item) => (
                  // <NavItem
                  //   key={item.title}
                  //   item={item}
                  //   active={isPathMatching(item.path, pathname)}
                  //   children={item.children}
                  // />
                  <NavGroup key={item.title} navItem={item} />
                ))}
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

export default SubMenu;
