import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import NavItem2 from "./NavItem2";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            px: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function NavTabs({ navItem }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", overflowY: "auto", maxHeight: "83vh" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          background: (theme) => theme.palette.color.bg2,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ pb: "24px" }}
        >
          {navItem.children.map((item, index) => (
            <Tab
              label={item.title}
              icon={<item.icon />}
              iconPosition="start"
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {navItem.children.map((item, index) => (
        <CustomTabPanel value={value} index={index}>
          {item.children.map((child, index) => (
            <NavItem2 item={child} key={index} />
          ))}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
