import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import StepperCard from "./StepperCard";
import StepperHeading from "./StepperHeading";

const countryData = [
  {
    icon: "/images/country-icons/usd.svg",
    value: "USD",
    code: "$",
  },
  {
    icon: "/images/country-icons/gbp.svg",
    value: "GBP",
    code: "GB£",
  },
  {
    icon: "/images/country-icons/eur.svg",
    value: "EUR",
    code: "€",
  },
  {
    icon: "/images/country-icons/czk.svg",
    value: "CZK",
    code: "Kč",
  },
  {
    icon: "/images/country-icons/cad.svg",
    value: "CAD",
    code: "CA$",
  },
  {
    icon: "/images/country-icons/aud.svg",
    value: "AUD",
    code: "AU$",
  },
  {
    icon: "/images/country-icons/chf.svg",
    value: "CHF",
    code: "CHF",
  },
];
export default function SelectCurrency({ currencies, setCurrencies }) {
  return (
    <>
      <StepperHeading
        heading={"Select your Currency"}
        subHeading={
          "You can select multiple currencies after completing your first!"
        }
      />
      <Grid container spacing={1.5} sx={{ px: "20px", width: "100%" }} mt={2}>
        {countryData.map((country, index) => {
          const isActive = currencies.includes(country.value);
          return (
            <Grid item xs={3} key={index}>
              <StepperCard
                isActive={currencies.includes(country.value)}
                onClick={() => {
                  if (currencies.includes(country.value)) {
                    setCurrencies(
                      currencies.filter((item) => item !== country.value)
                    );
                  } else {
                    setCurrencies([...currencies, country.value]);
                  }
                }}
                sx={{
                  cursor: "pointer",
                  position: "relative",
                  overflow: "hidden",
                  "&:hover": {
                    ...(!isActive && {
                      border: (theme) =>
                        `1px solid ${theme.palette.color.active}`,
                    }),

                    "& .MuiAvatar-root": {
                      transition: "transform 0.3s",
                      transform: "rotateX(360deg)",
                    },
                  },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                  {currencies.includes(country.value) && (
                    <Box
                      sx={{
                        width: "9px",
                        height: "9px",
                        borderRadius: "50%",
                        border: (theme) =>
                          `1px solid ${theme.palette.color.greenShade}`,
                      }}
                    />
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar
                      alt={country.value}
                      src={country.icon}
                      sx={{
                        width: 16,
                        height: 16,
                      }}
                    />
                    <Typography
                      variant="caption_500"
                      align="center"
                      sx={{
                        ...(currencies.includes(country.value) && {
                          color: (theme) => theme.palette.color.primary,
                        }),
                      }}
                    >
                      {country.value}
                    </Typography>
                  </Stack>
                </Stack>
              </StepperCard>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
