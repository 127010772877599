import { useTheme } from "@mui/material";
import * as React from "react";

const CircleCloseIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  return (
    <svg width="24" height="24" fill="none" {...props}>
      <path
        stroke={stroke || theme.palette.color.secondary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.75 12A7.25 7.25 0 0 1 12 4.75v0A7.25 7.25 0 0 1 19.25 12v0A7.25 7.25 0 0 1 12 19.25v0A7.25 7.25 0 0 1 4.75 12v0Zm5-2.25 4.5 4.5m0-4.5-4.5 4.5"
      />
    </svg>
  );
};

export default CircleCloseIcon;
