import { useTheme } from "@mui/material";
import * as React from "react";

const AnalysisAndReportingIcon = ({ stroke, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_3983_32734"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={18}
        height={18}
      >
        <path d="M3 3H21V21H3V3Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3983_32734)">
        <path
          d="M18.8002 9.85547C16.4904 7.54567 15.5921 6.69141 14.1447 6.69141C12.6891 6.69141 11.7747 7.57007 9.48926 9.85547C12.1556 12.5218 12.9005 13.0195 14.1447 13.0195C15.3035 13.0195 16.0037 12.6519 18.8002 9.85547Z"
          stroke={strokeColor}
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1992 9.85547C15.1992 10.4379 14.727 10.9102 14.1445 10.9102C13.5621 10.9102 13.0898 10.4379 13.0898 9.85547C13.0898 9.273 13.5621 8.80078 14.1445 8.80078C14.727 8.80078 15.1992 9.273 15.1992 9.85547Z"
          stroke={strokeColor}
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4727 9.85547C20.4727 13.3504 17.6395 16.1836 14.1445 16.1836C10.6496 16.1836 7.81641 13.3504 7.81641 9.85547C7.81641 6.36055 10.6496 3.52734 14.1445 3.52734C17.6395 3.52734 20.4727 6.36055 20.4727 9.85547Z"
          stroke={strokeColor}
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.56211 16.9293L7.07072 15.4379L3.83624 18.6724C3.42438 19.0842 3.42438 19.7519 3.83624 20.1638C4.24809 20.5756 4.91578 20.5756 5.32763 20.1638L8.56211 16.9293Z"
          stroke={strokeColor}
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.81641 16.1836L9.66988 14.3301"
          stroke={strokeColor}
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default AnalysisAndReportingIcon;