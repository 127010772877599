import { Paper, Stack, Typography, useMediaQuery, IconButton } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../components/Firm/Orders/Table";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ExportSection from "../../components/ui/ExportSection";
import DisplayColumns from "../../components/ui/DisplayColumns";
import SmallSearchBar from "../../components/Affiliates/smallSearchBar";
import { useDispatch } from "react-redux";
import { toggleOpen } from "../../slices/openModalSlice";
import AddDiscount from "../../components/Firm/addDiscount";
import Tooltip from '@mui/material/Tooltip';
import CalendarPopup from "../../components/ui/CalendarPopup";
import dayjs from "dayjs";

const Action = ({ row }) => {
  const dispatch = useDispatch();
  return (
    <IconButton onClick={() => dispatch(toggleOpen())}>
      <Add
        sx={{
          fontSize: "22px",
          color: (theme) => theme.palette.color.primary,
        }}
      />
    </IconButton>
  );
};

const headcells = [
  {
    id: "name",
    label: "Name",
    getCell: (row) => row.name,
  },
  {
    id: "company",
    label: "Company",
    getCell: (row) => row.company,
  },
  {
    id: "country",
    label: "Country",
    getCell: (row) => (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ my: "10px" }}
      >
        <p>{row.country}</p>
      </Stack>
    ),
  },
  {
    id: "email",
    label: "Email address",
    getCell: (row) => row.email,
  },
  {
    id: "phone",
    label: "Phone",
    getCell: (row) => row.phone,
  },
  {
    id: "parent",
    label: "Parent",
    getCell: (row) => row.parent,
  },
  {
    id: "createdAt",
    label: "Created",
    getCell: (row) => row.createdAt,
  },
  // {
  //   id: "account",
  //   label: "Account",
  //   getCell: (row) => row.account,
  //   default: false,
  // },
  {
    id: "action",
    label: "",
    label2: <Tooltip title="Add Discount" ><Action /></Tooltip>,
    getCell: (row) => { },
    padding: "0px",
  },
];

const data = [
  {
    name: "Jens V.",
    country: "NL",
    email: "example@gmail.com",
    phone: "+31 433242455",
    createdAt: "24 Apr 2024, 11:04",
    parent: "parent",
    company: "Company",
    account: "account",
  },
  {
    name: "Jens V.",
    country: "NL",
    email: "example@gmail.com",
    phone: "+31 433242455",
    createdAt: "24 Apr 2024, 11:04",
    parent: "parent",
    company: "Company",
    account: "account",
  },
  {
    name: "Jens V.",
    country: "NL",
    email: "example@gmail.com",
    phone: "+31 433242455",
    createdAt: "24 Apr 2024, 11:04",
    parent: "parent",
    company: "Company",
    account: "account",
  },
  {
    name: "Jens V.",
    country: "NL",
    email: "example@gmail.com",
    phone: "+31 433242455",
    createdAt: "2 May 2024, 11:04",
    parent: "parent",
    company: "Company",
    account: "account",
  },
  {
    name: "Jens V.",
    country: "NL",
    email: "example@gmail.com",
    phone: "+31 433242455",
    createdAt: "2 May 2024, 11:04",
    parent: "parent",
    company: "Company",
    account: "account",
  },
  {
    name: "Jens V.",
    country: "NL",
    email: "example@gmail.com",
    phone: "+31 433242455",
    createdAt: "2 May 2024, 11:04",
    parent: "parent",
    company: "Company",
    account: "account",
  },
  {
    name: "Jens V.",
    country: "NL",
    email: "example@gmail.com",
    phone: "+31 433242455",
    createdAt: "2 May 2024, 11:04",
    parent: "parent",
    company: "Company",
    account: "account",
  },
  {
    name: "Jens V.",
    country: "NL",
    email: "example@gmail.com",
    phone: "+31 433242455",
    createdAt: "2 May 2024, 11:04",
    parent: "parent",
    company: "Company",
    account: "account",
  },
  {
    name: "Jens V.",
    country: "NL",
    email: "example@gmail.com",
    phone: "+31 433242455",
    createdAt: "2 May 2024, 11:04",
    parent: "parent",
    company: "Company",
    account: "account",
  },
];

const Customers = () => {
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [columns, setColumns] = useState(headcells);
  const [selectedColumns, setSelectedColumns] = useState(columns.map((col) => col.id));
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        px: "12px",
        py: "24px",
        width: "100%",
      }}
    >
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
        alignItems={isSmallScreen ? "flex-start" : "center"}
        gap={"12px"}
      >
        <Stack direction="row" gap={1} alignItems={"center"}>
          <Typography variant="h6" color={(theme) => theme.palette.color.primary} mb={"5px"}>
            {data.length} affiliates
          </Typography>
          <Typography variant="h6" color={(theme) => theme.palette.color.secondary} mb={"5px"}>
            match of {data.length}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={isSmallScreen ? 1 : 2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            mb: "12px",
            fontSize: "11.5px",
            pr: "24px",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <Stack direction="row" alignItems="center" gap={"6px"}>
            <SmallSearchBar />
            <ExportSection />
          </Stack>
          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
          {/* <Stack direction="row" alignItems="center" sx={{ marginLeft: "12px !important" }}>
            <ExportSection isImport={true} />
          </Stack> */}
          <Stack sx={{ marginLeft: "6px !important" }}>
            <DisplayColumns
              columns={columns}
              setColumns={setColumns}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              title={"Display"}
            />
          </Stack>
          <Stack sx={{ marginLeft: "6px !important", }}>
            <DisplayColumns
              columns={columns}
              setColumns={setColumns}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              title={"All"}
            />
          </Stack>
        </Stack>
      </Stack>
      <CustomTable
        borderTopValue={true}
        headcells={columns.filter((col) => selectedColumns.includes(col.id))}
        rows={data}
        onRowClick={(row) => {
          navigate(`/firm/customers/${row.name}`);
        }}
        fontSize="13px"
      />
      <AddDiscount />
    </Paper>
  );
};

export default Customers;