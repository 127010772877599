import { Grid, Stack, Typography, useTheme } from "@mui/material";
import LineChart from "../../Charts/LineChart";

const EscalationsChart = () => {
  const theme = useTheme();
  const options = {
    markers: {
      size: 4,
      colors: undefined,
      strokeWidth: 0,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 0.8,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    yaxis: {
      min: 5,
      max: 35,
      tickAmount: 6,
      title: {
        text: "Number Of Escalations",
        style: {
          color: "#8A96A6",
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          colors: theme.palette.color.secondary,
          fontSize: "13px",
        },
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          colors: theme.palette.color.secondary,
          fontSize: "13px",
        },
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 12,
    },
    stroke: {
      curve: "straight",
      width: 4,
    },
  };

  const series = [
    {
      name: "Escalations",
      data: [null, 15, 25, 10, 15, 22, 19],
    },
  ];

  return (
    <Grid
      item
      xs={12}
      lg={6}
      mt={3}
      sx={{
        background: (theme) => theme.palette.color.bg5,
        borderRadius: "18px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.border,
      }}
    >
      <Stack spacing={3} sx={{ p: "24px" }}>
        <Typography variant="h5">Escalations</Typography>

        <LineChart
          height="300"
          categories={["", "Jan", "Feb", "Mar", "Apr", "May", "Jun"]}
          colors={[theme.palette.color.greenShade]}
          options={options}
          series={series}
        />
      </Stack>
    </Grid>
  );
};

export default EscalationsChart;
