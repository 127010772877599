import { useTheme } from "@mui/material";

export default function DashboardIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.75 4.75V15M1 4.25H15M0.75 2.75C0.75 2.21957 0.960714 1.71086 1.33579 1.33579C1.71086 0.960714 2.21957 0.75 2.75 0.75H13.25C13.7804 0.75 14.2891 0.960714 14.6642 1.33579C15.0393 1.71086 15.25 2.21957 15.25 2.75V13.25C15.25 13.7804 15.0393 14.2891 14.6642 14.6642C14.2891 15.0393 13.7804 15.25 13.25 15.25H2.75C2.21957 15.25 1.71086 15.0393 1.33579 14.6642C0.960714 14.2891 0.75 13.7804 0.75 13.25V2.75Z"
        stroke={theme.palette.color[stroke || "primary"]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
