import { useState, useEffect } from "react";
import { alpha, Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import FormInput from "../../components/ui/FormInput";
import PasswordIcon from "../../components/Icons/Password";

const ResetPassword = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [emailSent, setEmailSent] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const isPasswordValid = password.length >= 8;

    useEffect(() => {
        if (password.length > 0 && confirmPassword.length > 0) {
            setPasswordsMatch(password === confirmPassword);
        }
    }, [password, confirmPassword]);

    const resetPassword = () => {
        if (isPasswordValid && passwordsMatch) {
            setEmailSent(true);
        }
    }

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.color.bg,
                overflow: "hidden",
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: 2,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    zIndex: 2,
                }}
            >
                <img
                    src="/svg/loginIcon.svg"
                    alt="UNI"
                    style={{ width: "78px", height: "29px" }}
                />
                <Box
                    sx={{
                        gap: "10px",
                        alignItems: "center",
                        display: isSmall ? "none" : "flex"
                    }}
                >
                    <Button size='small'
                        sx={{
                            height: "36px",
                            borderRadius: "32px",
                            bgcolor: (theme) => theme.palette.color.bg5,
                            color: (theme) => theme.palette.color.secondary,
                            border: (theme) => `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                            ":hover": { background: (theme) => theme.palette.color.bg5 }
                        }}>
                        Don't have an account?
                    </Button>
                    <Button size='small'
                        sx={{
                            height: "36px",
                            borderRadius: "32px",
                            bgcolor: (theme) => theme.palette.color.active,
                            color: (theme) => theme.palette.color.primary,
                            border: (theme) => `1px solid ${alpha(theme.palette.color.active, 0.15)}`,
                            ":hover": { background: (theme) => theme.palette.color.active }
                        }}>
                        Contact sales
                    </Button>
                </Box>
            </Box>
            <Stack
                spacing={5}
                alignItems="center"
                sx={{
                    background: "#0b0d0f",
                    padding: "32px",
                    width: "520px",
                    borderRadius: "12px",
                    border: `1px solid rgba(21, 25, 31, 0.65)`,
                    zIndex: 2,
                }}
            >
                <Stack spacing={2}>
                    {!emailSent
                        ? <>
                            <Typography variant="h1" sx={{ fontSize: "24px", textAlign: "center" }}>Set new password</Typography>
                            <Typography variant="body3" sx={{ textAlign: "center", fontSize: "14px" }}>
                                Your new password must be different from previously used passwords.
                            </Typography>
                        </> :
                        <>

                            <Typography variant="h1" sx={{ fontSize: "24px", textAlign: "center" }}>Password reset</Typography>
                            <Stack spacing={1}>
                                <Typography variant="body3" sx={{ textAlign: "center", fontSize: "14px" }}>
                                    Your password has been successfully reset.
                                </Typography>
                                <Typography variant="body3" sx={{ textAlign: "center", fontSize: "14px" }}>
                                    Click below to log in magically.
                                </Typography>
                            </Stack>
                        </>}
                </Stack>

                {emailSent ?
                    <Stack spacing={3} width={"100%"}>
                        <Button
                            variant="contained"
                            sx={{ borderRadius: "32px", height: "50px", fontWeight: "15px" }}
                            fullWidth
                        >
                            Reset Password
                        </Button>
                    </Stack> :
                    <Stack spacing={2} width={"100%"}>
                        <FormInput
                            label="Password"
                            placeholder="************"
                            type={showPassword ? "text" : "password"}
                            startAdornment={<PasswordIcon width="36" height="36" />}
                            endAdornment={
                                showPassword
                                    ? <AiOutlineEyeInvisible
                                        onClick={togglePasswordVisibility}
                                        style={{ width: "25px", height: "25px", color: "#8A96A6" }}
                                    />
                                    : <AiOutlineEye
                                        onClick={togglePasswordVisibility}
                                        style={{ width: "25px", height: "25px", color: "#8A96A6" }}
                                    />
                            }
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            sx={{
                                px: "12px",
                                borderRadius: "16px"
                            }}
                        />
                        <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: "400", color: isPasswordValid ? (theme)=>theme.palette.color.green : (theme) => theme.palette.color.secondary }}>
                            Must be at least 8 characters.
                        </Typography>
                        <FormInput
                            label="Confirm Password"
                            placeholder="************"
                            type={showConfirmPassword ? "text" : "password"}
                            startAdornment={<PasswordIcon width="36" height="36" />}
                            endAdornment={
                                showConfirmPassword
                                    ? <AiOutlineEyeInvisible
                                        onClick={toggleConfirmPasswordVisibility}
                                        style={{ width: "25px", height: "25px", color: "#8A96A6" }}
                                    />
                                    : <AiOutlineEye
                                        onClick={toggleConfirmPasswordVisibility}
                                        style={{ width: "25px", height: "25px", color: "#8A96A6" }}
                                    />
                            }
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            sx={{
                                px: "12px",
                                borderRadius: "16px"
                            }}
                        />
                        {!passwordsMatch && <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: "400", color: (theme)=>theme.palette.color.red }}>
                            Passwords do not match.
                        </Typography>}
                        <Button
                            onClick={() => {
                                if (isPasswordValid && passwordsMatch) {
                                    resetPassword();
                                }
                            }}
                            variant="contained"
                            sx={{
                                borderRadius: "32px",
                                height: "50px",
                                fontWeight: "15px",
                                cursor: isPasswordValid && passwordsMatch ? "pointer" : "not-allowed",
                            }}
                            fullWidth
                        >
                            Reset Password
                        </Button>
                    </Stack>
                }

                {!emailSent && (
                    <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", gap: 1, cursor: "pointer" }} onClick={() => { navigate("/login") }}>
                        <Typography variant="body3" sx={{ fontSize: "14px", textAlign: "center" }}><IoArrowBackOutline /></Typography>
                        <Typography variant="body3" sx={{ fontSize: "14px", textAlign: "center" }}>Back to login</Typography>
                    </Stack>
                )}
            </Stack>
            <Box
                sx={{
                    position: "absolute",
                    right: "-270px",
                    bottom: "-150px",
                    opacity: "0.1",
                    left: "544px",
                    zIndex: 1,
                }}
            >
                <img src="/images/background.png" alt="background" height="1166px" />
            </Box>
        </Box>
    );
};

export default ResetPassword;