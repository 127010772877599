import { Box, IconButton, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import React from "react";
import { RxCaretSort } from "react-icons/rx";

function Row(props) {
  const {
    row,
    headcells,
    handleClick,
    isItemSelected,
    fontSize,
    headcells2,
    actionComponent,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => {
          handleClick(event, row?.id, row);
        }}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row?.id}
        selected={isItemSelected}
        sx={{
          cursor: "pointer",
          ...(open && {
            background: (theme) => theme.palette.color.borderpure,
          }),

          "&:hover .action": { opacity: 1 },
          my: "10px",
        }}
      >
        {headcells.map((headcell, index) => (
          <TableCell
            key={headcell.id}
            align={headcell.align || "left"}
            sx={{
              ...(fontSize && { fontSize: fontSize + " !important" }),
              color: (theme) => theme.palette.color.secondary,
              height: "60px",
              transition: "all 0.5s ease-in-out",
              ...(open && {
                background: (theme) => theme.palette.color.borderpure,
              }),
              ...(index === 0 && {
                borderRadius: "24px 0 0 0",
              }),
              ...(index === headcells.length - 1 &&
                !actionComponent && {
                  borderRadius: "0 24px 0 0",
                }),
            }}
          >
            {index === 0 ? (
              <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                  sx={{
                    color: (theme) => theme.palette.color.secondary,
                    my: "auto",
                  }}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? "rotate(0deg)" : "rotate(-90deg)",
                      transition: "all 0.3s ease",
                      color: (theme) => theme.palette.color.secondary,
                      fontSize: "18px",
                    }}
                  />
                </IconButton>
                {headcell.getCell(row)}
              </Stack>
            ) : (
              headcell.getCell(row)
            )}
          </TableCell>
        ))}
        {actionComponent && (
          <TableCell
            align="right"
            sx={{
              ...(fontSize && { fontSize: fontSize + " !important" }),
              color: (theme) => theme.palette.color.secondary,
              transition: "all 0.5s ease-in-out",
              ...(open && {
                background: (theme) => theme.palette.color.borderpure,
              }),
              borderRadius: "0 24px 0 0",
            }}
          >
            <Box
              className="action"
              sx={{
                opacity: 0,
              }}
            >
              {actionComponent}
            </Box>
          </TableCell>
        )}
      </TableRow>
      {row?.children.map((childrenRow, i) => (
        <TableRow
          sx={{
            visibility: open ? "visible" : "collapse",
            opacity: open ? 1 : 0,
            transition: "all 0.5s ease-in-out",
            "&:hover .action": { opacity: 1 },
          }}
          key={childrenRow.date}
        >
          {(headcells2 || headcells).map((headcell, index) => (
            <TableCell
              key={headcell.id}
              align={headcell.align || "left"}
              sx={{
                ...(fontSize && {
                  fontSize: fontSize + " !important",
                }),
                position: "relative",
                ...(!open && {
                  borderBottom: "0 !important",
                }),
                color: (theme) => theme.palette.color.secondary,
                height: "50px",
                background: (theme) => theme.palette.color.borderpure,

                ...(i === row?.children.length - 1 && {
                  ...(index === 0 && {
                    borderRadius: "0 0 0 24px",
                  }),
                  ...(index === headcells.length - 1 &&
                    !actionComponent && {
                      borderRadius: "0 0 24px 0",
                    }),
                }),
              }}
            >
              {index === 0 ? (
                <Box
                  sx={{
                    pl: "48px",
                  }}
                >
                  <Box
                    sx={{
                      width: 20,
                      height: 25,
                      borderLeft: (theme) =>
                        `2px solid ${theme.palette.color.lightgray}`,
                      borderBottom: (theme) =>
                        `2px solid ${theme.palette.color.lightgray}`,
                      position: "absolute",
                      top: "-3px",
                      left: "30px",
                    }}
                  ></Box>
                  {headcell.getCell(childrenRow)}
                </Box>
              ) : (
                headcell.getCell(childrenRow)
              )}
            </TableCell>
          ))}
          {actionComponent && (
            <TableCell
              align="right"
              sx={{
                ...(fontSize && {
                  fontSize: fontSize + " !important",
                }),
                position: "relative",
                ...(!open && {
                  borderBottom: "0 !important",
                }),
                color: (theme) => theme.palette.color.secondary,
                background: (theme) => theme.palette.color.borderpure,
              }}
            >
              <Box
                className="action"
                sx={{
                  opacity: 0,
                }}
              >
                {actionComponent}
              </Box>
            </TableCell>
          )}
        </TableRow>
      ))}
      {row?.children.length === 0 && (
        <TableRow
          sx={{
            visibility: open ? "visible" : "collapse",
            opacity: open ? 1 : 0,
            transition: "all 0.5s ease-in-out",
          }}
        >
          <TableCell
            colSpan={headcells.length}
            sx={{
              ...(fontSize && {
                fontSize: fontSize + " !important",
              }),
              position: "relative",
              ...(!open && {
                borderBottom: "0 !important",
              }),
              color: (theme) => theme.palette.color.secondary,
              height: "50px",
              background: (theme) => theme.palette.color.borderpure,
              borderRadius: "0 0 24px 24px",
              pl: "48px",
            }}
          >
            <Typography variant="body3">No data available</Typography>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headcells,
    fontSize = "13px",
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell /> */}
        {headcells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Stack direction="row" alignItems="center">
              <Typography
                variant="subtitle2"
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: "400",
                  fontSize: "11.5px",
                }}
              >
                {headCell.label}
              </Typography>
              {headCell.label && !headCell.noSort && <RxCaretSort size={15} />}
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CollapsibleTable = ({
  headcells,
  rows,
  onRowClick,
  fontSize = "13px",
  headcells2,
  actionComponent,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id, row) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    if (onRowClick) onRowClick(row);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <TableContainer
      sx={{
        my: 3,
      }}
    >
      <Table
        sx={{
          minWidth: 750,
          maxWidth: "100%",
          overflowX: "auto",
        }}
        aria-labelledby="tableTitle"
        size="medium"
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          headcells={headcells}
          fontSize={fontSize}
        />
        <TableBody>
          {rows.map((row, index) => {
            const isItemSelected = isSelected(row?.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <Row
                key={index}
                row={row}
                headcells={headcells}
                isItemSelected={isItemSelected}
                labelId={labelId}
                fontSize={fontSize}
                handleClick={handleClick}
                headcells2={headcells2}
                actionComponent={actionComponent}
              />
              //   <TableRow
              //     hover
              //     onClick={(event) => {
              //       handleClick(event, row?.id);
              //       if (onRowClick) onRowClick(row);
              //     }}
              //     aria-checked={isItemSelected}
              //     tabIndex={-1}
              //     key={row?.id}
              //     selected={isItemSelected}
              //     sx={{ cursor: "pointer" }}
              //   >
              //     {headcells.map((headcell) => (
              //       <TableCell
              //         key={headcell.id}
              //         align={headcell.align || "left"}
              //         sx={{
              //           ...(fontSize && { fontSize: fontSize + " !important" }),
              //         }}
              //       >
              //         {headcell.getCell(row)}
              //       </TableCell>
              //     ))}
              //   </TableRow>
            );
          })}
          {rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={headcells.length}>
                <Typography variant="body3">No data available</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
