import React from 'react';
import ApexCharts from 'react-apexcharts';
import { Box, Stack, Typography, styled } from '@mui/material';
import RoundFormSelect from '../../ui/RoundFormSelect';

const SalesPerformance = () => {
  const ChartContainer = styled(Box)({
    width: '100%',
    height: '100%',
    '& .apexcharts-toolbar': {
      display: 'none !important',
    },
  });

  const series = [
    {
      name: 'Gross sales',
      data: [4, 3, 6, 2],
      rounded: true
    },
    {
      name: 'Click',
      data: [5, 2, 3, 6],
      rounded: true
    },
    {
      name: 'Net Sales',
      data: [3, 1, 5, 4],
      rounded: true
    }
  ];

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '40%',
        barSpacing: '20%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#5EEDF8', '#5F55EE', '#2FE0A2'],
    },
    xaxis: {
      categories: ['W1', 'W2', 'W3', 'W4'],
      labels: {
        style: {
          colors: ['#8A96A6', '#8A96A6', '#8A96A6', '#8A96A6'],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: '',
      },
      labels: {
        formatter: (val) => `${val}k`,
        style: {
          colors: "#8A96A6",
        },
      },
    },
    fill: {
      type: 'gradient',
      opacity: [0.15, 0.15, 0.15],
      gradient: {
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: [0.18, 0.18, 0.18],
        opacityTo: [0.05, 0.05, 0.05],
        type: 'vertical',
        stops: [0, 100, 100],
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: false,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        const seriesName = w.globals.seriesNames[seriesIndex];
        return (
          '<div style="background-color: #1D232B; border-radius: 4px; padding: 8px 12px; font-family: Arial, sans-serif;">' +
            '<div style="font-size: 14px; font-weight: bold; color: #ffffff;">' +
              formatCurrency(value * 1000) +
            '</div>' +
            '<div style="font-size: 12px; color: #8A96A6; margin-top: 4px;">' +
              'In ' + seriesName.toLowerCase() +
            '</div>' +
          '</div>'
        );
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
      },
      y: {
        formatter: undefined,
      },
      marker: {
        show: false,
      },
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: 'Arial, sans-serif',
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  // Determine the max and min values for border styling
  const maxIndex = series[1].data.indexOf(Math.max(...series[1].data));
  const minIndex = series[2].data.indexOf(Math.min(...series[2].data));

  return (
    <Box sx={{
      background: (theme) => theme.palette.color.bg5,
      borderRadius: "18px",
      border: "1px solid",
      borderColor: (theme) => theme.palette.color.border,
      color: 'white',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: '0 auto',
      width: '100%',
      mt: 3,
    }}>
      <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h5">
          Sales Performance
        </Typography>
        <Stack direction="row" gap={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#1D232B', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Gross sales</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Payouts</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2">Net Sales</Typography>
          </Box>
          <RoundFormSelect
            options={[{ value: "week", label: "Week" }]}
            defaultValue="week"
          />
        </Stack>
      </Stack>
      <ChartContainer>
        <ApexCharts
          options={options}
          series={series.map(({ name, data, color }, index) => ({
            name,
            data,
            type: 'column',
            color: index === 0 ? '#1D232B' : (index === 1 ? '#5F55EE' : '#2FE0A2'),
            borderColor: maxIndex === index ? '#3924f2' : (minIndex === index ? '#8aefcc' : ''),
          }))}
          type="bar"
          height={350}
        />
      </ChartContainer>
    </Box>
  );
};

export default SalesPerformance;