import { useTheme } from "@mui/material";

export default function ForwardIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.04995 12.5L6.54995 7L1.04995 1.5M5.44995 12.5L10.95 7L5.44995 1.5"
        stroke={stroke || theme.palette.color.secondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
