import { Box, Stack, styled, Typography } from '@mui/material';
import React from 'react'
import RoundFormSelect from '../../ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';

const AffiliateBreakdownByCountry = () => {
    const ChartContainer = styled(Box)({
        width: '100%',
        height: '100%',
        '& .apexcharts-toolbar': {
            display: 'none !important',
        },
    });

    const series = [
        {
            name: 'USA',
            data: [250, 200, 250, 100, 200],
        },
        {
            name: 'Canada',
            data: [150, 100, 150, 150, 150],
        },
        {
            name: 'UK',
            data: [100, 100, 100, 100, 100],
        },
        {
            name: 'Germany',
            data: [100, 50, 100, 100, 100],
        },
        {
            name: 'Australia',
            data: [250, 200, 150, 150, 150],
        },
    ];

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false,
            },
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                columnWidth: '20%',
                spacing: 10,
                endingShape: 'rounded',
            },
        },
        colors: ['#5EEDF8', '#5F55EE', '#2FE0A2', '#ED55D4', '#FFC452'],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
            labels: {
                style: {
                    colors: '#8A96A6',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            min: 0,
            max: 1000,
            tickAmount: 5,
            title: {
                text: "",
            },
            labels: {
                formatter: (val) => `${val}`,
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        tooltip: {
            enabled: false,
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
    };

    return (
        <Box sx={{
            background: (theme) => theme.palette.color.bg5,
            border: '1px solid',
            borderColor: (theme) => theme.palette.color.strockborder,
            p: '10px',
            borderRadius: '16px',
            mt: 2
        }}>
            <Stack direction="row" p={2} alignItems="center" justifyContent="space-between">
                <Typography variant="h5">
                    Affiliate Breakdown by Country
                </Typography>
                <Stack direction="row" gap={2} alignItems="center">
                    {series.map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 12, height: 12, backgroundColor: options.colors[index], borderRadius: '50%', mr: 1 }} />
                            <Typography variant="body2" sx={{ color: '#8A96A6' }}>{item.name}</Typography>
                        </Box>
                    ))}
                    <RoundFormSelect
                        options={[{ value: "weekly", label: "Weekly" }]}
                        defaultValue="weekly"
                    />
                </Stack>
            </Stack>
            <ChartContainer>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                />
            </ChartContainer>
        </Box>
    );
}

export default AffiliateBreakdownByCountry;