import { Avatar, Box, Divider, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../components/Firm/Orders/Table";
import { filterData } from "../../utils/filterByDate";
import SearchInput from "../../components/ui/NewSearch";
import ExportSection from "../../components/ui/ExportSection";
import CalendarPopup from "../../components/ui/CalendarPopup";
import DisplayColumns from "../../components/ui/DisplayColumns";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useGetCustomersQuery } from "../../slices/api/customerSlice";
import moment from "moment/moment";

const headcells = [
  {
    id: "status",
    label: "Status",
    getCell: () => (
      <Box
        sx={{
          width: "11px",
          height: "11px",
          background: (theme) => theme.palette.color.green,
          borderRadius: "50%",
        }}
      ></Box>
    ),
  },
  {
    id: "country",
    label: "Country",
    getCell: (row) =>
      row.country ? (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          // sx={{ my: "10px" }}
        >
          <Avatar
            sx={{
              width: "18px",
              height: "18px",
            }}
            src="/images/country-icons/nl.svg"
            alt="NL"
          />
          <p>{row.country}</p>
        </Stack>
      ) : (
        "-"
      ),
  },
  {
    id: "name",
    label: "Name",
    getCell: (row) => row.first_name + " " + row.last_name,
  },
  {
    id: "email",
    label: "Email address",
    getCell: (row) => row.email,
  },
  {
    id: "phone",
    label: "Phone",
    getCell: (row) => row.phone || "-",
  },
  {
    id: "ltv",
    label: "LTV",
    getCell: (row) => row.ltv,
  },
  {
    id: "createdAt",
    label: "Created",
    getCell: (row) => (
      <Typography
        sx={{
          whiteSpace: "nowrap",
          color: "inherit",
          fontSize: "inherit",
        }}
      >
        {moment(row.createAt).format("MMMM DD, YYYY HH:mm")}
      </Typography>
    ),
  },
  {
    id: "value",
    label: "Customer Value",
    getCell: (row) => row.value || "-",
  },
  {
    id: "customerId",
    label: "Customer Id",
    getCell: (row) => row.id,
  },
];

const Customers = () => {
  const [duration, setDuration] = useState("thisMonth");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const handleChangePage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("first_name");
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(headcells.map((cell) => cell.id));

  const { data } = useGetCustomersQuery();

  const navigate = useNavigate();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const customers = data?.data?.data || [];
  const sortedData = [...customers].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  const filteredData = sortedData.filter((d) =>
    filterData(duration, d.created_at)
  );
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    Math.min(currentPage * itemsPerPage, filteredData.length)
  );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Paper
      sx={{
        p: { xs: "12px 24px", sm: "24px" },
        width: { xs: "100%", md: "80%" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: "12px",
          mt: { xs: "0px", sm: "24px" },
          mb: "16px",
          fontSize: "11.5px",
          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <Typography variant="h4">Customers</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />
          <ExportSection />
          <ExportSection isImport={true} />

          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
          <DisplayColumns
            columns={columns}
            setColumns={setColumns}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
        </Stack>
      </Box>
      <Divider />
      <CustomTable
        headcells={columns.filter((cell) => heads.includes(cell.id))}
        rows={paginatedData}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        fontSize="11.5px"
        onRowClick={(row) => {
          navigate(`/firm/customer/overview?id=${row.id}`);
        }}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        handleChangePage={handleChangePage}
        totalPages={totalPages}
        setItemsPerPage={setItemsPerPage}
      />
    </Paper>
  );
};

export default Customers;
