import {
  Box,
  Collapse,
  InputAdornment,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import StepperCard from "./StepperCard";
import StepperHeading from "./StepperHeading";
import CustomSwitch from "../../ui/Switch";
import { TbPlaystationCircle } from "react-icons/tb";
import { FiSlash } from "react-icons/fi";
import SearchIcon from "../../Icons/Search";
import FormInput from "../../ui/FormInput";
import ScheduleIcon from "../../Icons/Schedule";
import OpenFullIcon from "@mui/icons-material/OpenInFull";
import { BiLineChart } from "react-icons/bi";

const items = [
  {
    id: "profit_target",
    label: "Profit Target",
    subLabel:
      "Specify a target percentage that users need to achieve to pass the challenge",
    recommended: true,
    collapse: true,
    startIcon: <Typography variant="caption_500">%</Typography>,
    tab: "1",
  },
  {
    label: "Min. Trading Days",
    id: "min_trading_days",
    subLabel:
      "The user needs to actively trade for at least {{ min_trading_days }} days to pass.",
    collapse: true,
    startIcon: <ScheduleIcon />,
    tab: "1",
  },
  {
    id: "min_trades",
    label: "Min. Trades",
    subLabel: "Set the minimum number of trades the user must execute",
    tab: "1",
    collapse: true,
  },
  // {
  //   label: "Overall Loss",
  //   subLabel:
  //     "Specify a target percentage that users need to achieve to pass the challenge",
  //   collapse: true,
  //   startIcon: <Typography variant="caption_500">%</Typography>,
  //   tab: "2",
  // },
  {
    id: "min_volume_of_lots",
    label: "Min. Volume of Lots",
    subLabel: "Set the minimum volume of lots the user must trade",
    collapse: true,
    startIcon: (
      <OpenFullIcon
        sx={{
          color: (theme) => theme.palette.color.secondary,
          fontSize: "16px",
        }}
      />
    ),
    tab: "2",
  },
  {
    label: "Consecutive Wins",
    id: "consecutive_wins",
    subLabel:
      "Set the minimum number of consecutive winning trades the user must achieve",
    collapse: true,
    startIcon: (
      <Box sx={{ color: (theme) => theme.palette.color.secondary }}>
        <BiLineChart size={18} />
      </Box>
    ),
    tab: "2",
  },
];

export default function ObjectiveAndRulesTabs({ values, setValues }) {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState("1");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", maxHeight: 400, overflow: "auto" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: theme.palette.color.bg5,
        }}
      >
        <Typography
          variant="h3"
          component="h2"
          mb={2}
          sx={{
            fontSize: 16,
            fontWeight: "600",
          }}
        >
          Rules and Objective
        </Typography>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="rules and objective"
          >
            <Tab
              value="1"
              label="Objectives"
              icon={<TbPlaystationCircle size={16} />}
              iconPosition="start"
            />
            <Tab
              value="2"
              label="Rules"
              icon={<FiSlash />}
              iconPosition="start"
            />
          </Tabs>

          <FormInput
            fullWidth={false}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon
                  stroke={theme.palette.color.secondary}
                  height="14"
                />
              </InputAdornment>
            }
            placeholder="Search..."
            sx={{
              width: "238px",
              borderRadius: "18px",
              padding: "8px 14px",
              "& .MuiInputBase-input": {
                padding: "0px",
              },
            }}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        {selectedTab === "1" && (
          <Stack spacing={2} mt={2}>
            {items
              .filter((i) => i.tab === "1")
              .map((item, index) => (
                <CollapseCard {...item} values={values} setValues={setValues} />
              ))}
          </Stack>
        )}
        {selectedTab === "2" && (
          <Stack spacing={2} mt={2}>
            {items
              .filter((i) => i.tab === "2")
              .map((item, index) => (
                <CollapseCard {...item} values={values} setValues={setValues} />
              ))}
          </Stack>
        )}
      </Box>
    </Box>
  );
}

const CollapseCard = ({
  label,
  subLabel,
  recommended,
  startIcon,
  collapse,
  id,
  values,
  setValues,
}) => {
  const [checked, setChecked] = useState(false);
  return (
    <StepperCard
      horizontalAlign="flex-start"
      sx={{
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <StepperHeading
          recommended={recommended}
          heading={label}
          subHeading={subLabel}
          align="flex-start"
          subHeadingSize="12px"
          mainHeadingSize="16px"
        />
        <CustomSwitch
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          {...label}
        />
      </Stack>
      {collapse && (
        <Collapse in={checked} sx={{ width: "100%" }}>
          <Box sx={{ pt: "12px" }}>
            <FormInput
              fullWidth={true}
              startAdornment={startIcon}
              sx={{
                background: (theme) => theme.palette.color.gray,
                borderRadius: "18px",
                width: "50%",
                px: "12px",
              }}
              value={values[id]}
              onChange={(e) => setValues({ ...values, [id]: e.target.value })}
            />
          </Box>
        </Collapse>
      )}
    </StepperCard>
  );
};
