import React from "react";
import { Box, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import HelpCenterIcon from "../Icons/HelpCenter";

const BackgroundBox = styled(Box)(({ theme }) => ({
  height: "100%",
  background: theme.palette.color.bg2,
  border: "1px solid rgba(140, 173, 255, 0.2)",
  borderRadius: "18px",
  padding: theme.spacing(3),
  color: "white",
  position: "relative",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const BackgroundImage = styled("div")({
  position: "absolute",
  right: "-84px",
  bottom: "10px",
  width: "100%",
  height: "100%",
  backgroundImage: 'url("/svg/cardBg.svg")',
  rotate: "90deg",
  backgroundSize: "cover",
  opacity: 0.7,
});

const MetricCard = ({ title, value }) => {
  const theme = useTheme();
  return (
    <BackgroundBox>
      <BackgroundImage />
      <Box
        zIndex={1}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
      >
        <Box display="flex" gap={2}>
          <Typography variant="caption_500">{title}</Typography>
          <HelpCenterIcon
            fill={theme.palette.color.secondary}
            stroke={theme.palette.color.bg}
          />
        </Box>
        <Typography variant="h1">{value}</Typography>
      </Box>
    </BackgroundBox>
  );
};

export default MetricCard;
