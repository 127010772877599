import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const ProgressBar = ({ steps, page }) => {
  return (
    <Box sx={{ display: "flex", width: "100%", pt: "18px", px: "10px" }}>
      {steps.map((step, index) => (
        <Stack alignItems="center" sx={{ width: "100%" }} spacing={3}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Box
              sx={{
                width: "100%",
                height: "1.5px",
                transition: "all 500ms ease-in-out",
                background: (theme) =>
                  index === 0
                    ? "linear-gradient(-90deg, rgb(56, 199, 182) 0%, rgba(15, 17, 18, 0) 100%)"
                    : index <= parseInt(page)
                    ? theme.palette.color.greenShade
                    : "transparent",
              }}
            />
            <Typography
              sx={{
                color: (theme) =>
                  theme.palette.color[
                    index <= parseInt(page)
                      ? index === parseInt(page)
                        ? "primary"
                        : "bg5"
                      : "secondary"
                  ],

                border: (theme) =>
                  `1px solid ${
                    index === parseInt(page)
                      ? theme.palette.color.greenShade
                      : "transparent"
                  }`,
                fontSize: "13px",
                lineHeight: "22px",
                borderRadius: "50%",
                transition: "all 500ms ease-in-out",
                background: (theme) =>
                  index === parseInt(page)
                    ? "transparent"
                    : theme.palette.color[
                        index <= parseInt(page) ? "greenShade" : "gray"
                      ],

                width: "24px",
                textAlign: "center",
                flexShrink: 0,
              }}
            >
              {index + 1}
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "1.5px",
                transition: "all 500ms ease-in-out",
                background: (theme) =>
                  index === parseInt(page)
                    ? "linear-gradient(90deg, rgb(56, 199, 182) 0%, rgba(15, 17, 18, 0) 100%) rgba(0, 0, 0, 0)"
                    : index <= parseInt(page)
                    ? theme.palette.color.greenShade
                    : "transparent",
              }}
            />
          </Stack>
          <Typography
            sx={{
              fontSize: "13px",
              transition: "all 500ms ease-in-out",
              color: (theme) =>
                index < parseInt(page)
                  ? theme.palette.color.greenShade
                  : index === parseInt(page)
                  ? theme.palette.color.primary
                  : theme.palette.color.secondary,
            }}
          >
            {step}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
};

export default ProgressBar;
