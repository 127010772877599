import { useTheme } from "@mui/material";

export default function GraphicIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5301 0.330009C10.5341 -0.110166 8.46613 -0.110166 6.4701 0.330009L6.0521 0.422009C4.92743 0.669148 3.87455 1.17134 2.97469 1.88983C2.07484 2.60833 1.35206 3.52393 0.862104 4.56601C0.715104 4.87901 0.956104 5.22201 1.3021 5.22201H17.6981C18.0441 5.22201 18.2851 4.87901 18.1381 4.56601C17.6482 3.52393 16.9254 2.60833 16.0255 1.88983C15.1257 1.17134 14.0728 0.669148 12.9481 0.422009L12.5311 0.330009H12.5301ZM0.378104 12.07C-0.0232642 10.4602 -0.104805 8.78724 0.138104 7.14601C0.156091 7.03058 0.214971 6.92547 0.304003 6.84983C0.393035 6.7742 0.506288 6.7331 0.623104 6.73401H18.3791C18.6211 6.73401 18.8291 6.90701 18.8641 7.14601C19.1071 8.78301 19.0271 10.456 18.6241 12.07C18.2844 13.4253 17.5733 14.659 16.5707 15.6322C15.5681 16.6055 14.3139 17.2797 12.9491 17.579L12.5321 17.671C10.5354 18.1115 8.46677 18.1115 6.4701 17.671L6.0531 17.579C4.68822 17.2795 3.43396 16.6051 2.43141 15.6317C1.42885 14.6583 0.71776 13.4255 0.378104 12.07ZM4.4421 8.24501C4.34261 8.24475 4.24404 8.2641 4.15203 8.30196C4.06002 8.33982 3.97638 8.39545 3.90588 8.46567C3.83539 8.53588 3.77943 8.6193 3.74121 8.71116C3.70298 8.80302 3.68324 8.90151 3.6831 9.00101C3.6831 9.41801 4.0231 9.75701 4.4421 9.75701H8.4891C8.9091 9.75701 9.2491 9.41801 9.2491 9.00101C9.24897 8.90143 9.2292 8.80285 9.19091 8.71093C9.15262 8.61901 9.09657 8.53554 9.02597 8.46531C8.95537 8.39508 8.87161 8.33948 8.77949 8.30167C8.68736 8.26387 8.58868 8.24461 8.4891 8.24501H4.4421Z"
        fill={stroke || theme.palette.color.bg}
      />
    </svg>
  );
}
