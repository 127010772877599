import * as React from "react";

const BranchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path 
      d="M5.79053 8.6875V10.3068C5.79053 11.4318 6.70249 12.3437 7.82744 12.3437H11.9998M18.2091 8.6875V10.3068C18.2091 11.4318 17.2972 12.3437 16.1722 12.3437H11.9998M11.9998 12.3437V14.7601" 
      stroke="#8A96A6" 
      strokeWidth="1.5"
    />
    <circle 
      cx="5.79053" 
      cy="6.29688" 
      r="2.54688" 
      stroke="#8A96A6" 
      strokeWidth="1.5"
    />
    <circle 
      cx="11.9995" 
      cy="17.7031" 
      r="2.54688" 
      stroke="#8A96A6" 
      strokeWidth="1.5"
    />
    <circle 
      cx="18.2095" 
      cy="6.29688" 
      r="2.54688" 
      stroke="#8A96A6" 
      strokeWidth="1.5"
    />
  </svg>
);

export default BranchIcon;