import { useTheme } from "@mui/material";

export default function MetricsIcon({ stroke, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C13.4183 17 17 13.4183 17 9M9 1C13.4183 1 17 4.58172 17 9M9 1V9H17"
        stroke={stroke || theme.palette.color.secondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
