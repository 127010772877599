import { Box, Paper, Stack, Typography, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import ViewPayout from "./ViewPayout";
import SearchInput from "../../../components/ui/NewSearch";
import dayjs from "dayjs";
import ExportSection from "../../../components/ui/ExportSection";
import CalendarPopup from "../../../components/ui/CalendarPopup";
import DisplayColumns from "../../../components/ui/DisplayColumns";
import ClockIcon from "../../../components/Icons/Clock";
import CheckFillIcon from "../../../components/Icons/CheckFill";
import CircleCloseIcon from "../../../components/Icons/CircleClose";
import PayoutTable from "../../../components/Firm/Payouts/PayoutTable";
// import { toggleOpen } from "src/slices/openModalSlice";

const statutes = [
  { value: "pending", label: "Pending", color: "yellow" },
  { value: "approved", label: "Approved", color: "green" },
  { value: "denied", label: "Denied", color: "red" },
];

const headcells = [
  {
    id: "account",
    label: "Account ID",
    getCell: (row) => row.account || "-",
    default: true,
  },
  {
    id: "amount",
    label: "Amount",
    getCell: (row) => row.amount || "-",
    default: true,
  },
  {
    id: "date",
    label: "Date",
    getCell: (row) => (
      <Typography
        sx={{
          fontSize: "inherit",
          whiteSpace: "nowrap",
        }}
      >
        {row.date}
      </Typography>
    ),
    default: true,
  },
  {
    id: "status",
    label: "Status",
    getCell: (row) => {
      const status = statutes.find((statute) => statute.value === row.status);
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            borderRadius: "18px",
            width: "fit-content",
            background: (theme) => theme.palette.color[status.color],
            px: "8px",
          }}
        >
          <Box
            sx={{
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: (theme) => theme.palette.color.bg,
            }}
          ></Box>
          <Typography
            sx={{
              color: (theme) => theme.palette.color.bg,
              lineHeight: "22px",
            }}
          >
            {status.label}
          </Typography>
        </Stack>
      );
    },
    default: true,
  },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Payouts = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(
    headcells.filter((cell) => cell.default).map((cell) => cell.id)
  );
  const [selected, setSelected] = useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setCurrentPage(1);
    setValue(newValue);
  };

  return (
    <Paper
      sx={{
        p: { xs: "12px 18px", sm: "18px" },
        width: "100%",
      }}
    >
      <Typography variant="h4">Payouts</Typography>
      <Box
        sx={{
          mt: { xs: "12px", sm: "24px" },
          mb: "16px",
          fontSize: "11.5px",
          color: (theme) => theme.palette.color.secondary,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "flex-start", lg: "space-between" },
          gap: 3,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            icon={<ClockIcon />}
            iconPosition="start"
            label="Pending"
            {...a11yProps(0)}
          />
          <Tab
            icon={<CheckFillIcon />}
            iconPosition="start"
            label="Approved"
            {...a11yProps(1)}
          />
          <Tab
            icon={<CircleCloseIcon />}
            iconPosition="start"
            label="Denied"
            {...a11yProps(2)}
          />
        </Tabs>
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />
          <ExportSection />
          <ExportSection isImport={true} />

          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
          <DisplayColumns
            columns={columns}
            setColumns={setColumns}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
        </Stack>
      </Box>
      <PayoutTable
        headcells={columns.filter((cell) => heads.includes(cell.id))}
        value={value}
        setSelected={setSelected}
        selected={{ status: "approved" }}
      />

      <ViewPayout selected={selected} setSelected={setSelected} />
    </Paper>
  );
};

export default Payouts;
