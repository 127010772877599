import { useTheme } from "@mui/material";
import * as React from "react";

const GeographicIcon = ({ stroke, fill, ...props }) => {
  const theme = useTheme();
  const strokeColor = stroke || theme.palette.color.secondary;
  const fillColor = fill || "#5F55EE";

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9999 4.74951C7.99551 4.74951 4.74951 7.99551 4.74951 11.9999C4.74951 16.0043 7.99551 19.2503 11.9999 19.2503C10.7567 19.2503 8.75031 16.4999 8.75031 11.9999M11.9999 4.74951C16.0043 4.74951 19.2503 7.99551 19.2503 11.9999M11.9999 4.74951C10.7567 4.74951 8.75031 7.49991 8.75031 11.9999M11.9999 4.74951C12.9527 4.74951 14.3567 6.36831 14.9591 9.15351L14.9843 9.27471M8.75031 11.9999H5.00031M8.75031 11.9999H11.2499"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6772 16.6164C17.382 16.3416 16.9212 16.35 16.6356 16.6356C16.35 16.9212 16.3416 17.382 16.6164 17.6772L17.6772 16.6164ZM18.72 19.7796C18.9072 19.9812 19.188 20.0628 19.4544 19.9956C19.7196 19.9272 19.9272 19.7196 19.9956 19.4544C20.0628 19.188 19.9812 18.9072 19.7796 18.72L18.72 19.7796ZM15.2352 18.4704C16.0932 18.4704 16.9164 18.1296 17.5236 17.5224C18.1296 16.9164 18.4716 16.0932 18.4704 15.2352H16.9704C16.9704 16.194 16.194 16.9704 15.2352 16.9704V18.4704ZM15.2352 13.5C16.194 13.5 16.9704 14.2776 16.9704 15.2352H18.4704C18.4716 14.3772 18.1296 13.554 17.5236 12.9468C16.9164 12.3408 16.0932 12 15.2352 12V13.5ZM15.2352 12C13.4484 12 12 13.4484 12 15.2352H13.5C13.5 14.2776 14.2776 13.5 15.2352 13.5V12ZM15.2352 16.9704C14.2764 16.9704 13.5 16.1928 13.5 15.2352H12C12 16.0932 12.3408 16.9164 12.9468 17.5236C13.554 18.1296 14.3772 18.4716 15.2352 18.4704V16.9704ZM16.6164 17.6772L18.72 19.7796L19.7796 18.72L17.6772 16.6164L16.6164 17.6772Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default GeographicIcon;