import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { RxCheck, RxCross2 } from "react-icons/rx";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomSwitch from "../../../components/ui/Switch";
import MailIcon from "../../../components/Icons/Mail";
import FingerprintIcon from "../../../components/Icons/FingerprintIcon";
import EyeOpenIcon from "../../../components/Icons/EyeOpen";
import EyeCloseIcon from "../../../components/Icons/EyeClose";

const PrivacySecurity = () => {
  return (
    <Box width={"100%"}>
      <Box
        sx={{
          px: "24px",
          py: "10px",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"end"}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "500",
            }}
            gutterBottom
          >
            Privacy and security
          </Typography>
          <Button
            size="small"
            sx={{
              p: "5px",
              px: "10px",
              borderRadius: "32px",
              bgcolor: (theme) => theme.palette.color.active,
              color: "white",
              ":hover": { background: (theme) => theme.palette.color.active },
            }}
            onClick={() => {}}
          >
            Save Changes
          </Button>
        </Stack>
        <Stack direction={"row"} width={"70%"}>
          <Typography
            variant="heading"
            sx={{
              fontWeight: "400",
              color: (theme) => theme.palette.color.secondary,
            }}
          >
            View and update your profile details
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box
        sx={{
          p: "24px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SecuritySection />
            <TwoFactorAuthenticationSection />
            <SessionHistorySection />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const SecuritySection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [showSpinner, setShowSpinner] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (step === 4) {
      setShowSpinner(true);
      setTimeout(() => {
        setShowSpinner(false);
        setTimeout(() => {
          setIsVisible(false);
          setStep(1);
        }, 1000);
      }, 1000);
    }
  }, [step]);

  const isPasswordValid = () => {
    return (
      hasMinLength() &&
      hasUppercase() &&
      hasLowercase() &&
      hasNumber() &&
      hasSpecialChar()
    );
  };

  const hasMinLength = () => password.length >= 12;
  const hasUppercase = () => /[A-Z]/.test(password);
  const hasLowercase = () => /[a-z]/.test(password);
  const hasNumber = () => /\d/.test(password);
  const hasSpecialChar = () => /[@$!%*?&]/.test(password);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <Box
      sx={{
        mb: 4,
        p: "24px",
        borderRadius: "18px",
        bgcolor: (theme) => theme.palette.color.bg2,
      }}
    >
      <Stack spacing={1} justifyContent={"space-between"} direction={"row"}>
        <Typography
          variant="heading"
          sx={{
            fontWeight: "400",
          }}
        >
          Security
        </Typography>
        <Typography
          variant="body"
          color={(theme) => theme.palette.color.secondary}
        >
          Last updated: 1h ago
        </Typography>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack spacing={1} justifyContent={"space-between"} direction={"row"}>
        <Typography
          variant="heading"
          sx={{
            fontWeight: "400",
          }}
        >
          Password
        </Typography>
        {isVisible ? (
          <Box
            onClick={() => {
              setIsVisible(false);
              setStep(1);
            }}
            sx={{
              fontSize: "20px",
              color: (theme) => theme.palette.color.secondary,
              cursor: "pointer",
            }}
          >
            <RxCross2 />
          </Box>
        ) : (
          <Button
            onClick={() => setIsVisible(true)}
            sx={{
              color: (theme) => theme.palette.color.primary,
              borderRadius: "24px",
              bgcolor: (theme) => theme.palette.color.lightgray,
              ":hover": {
                bgcolor: (theme) => theme.palette.color.lightgray,
              },
            }}
          >
            Change Password
          </Button>
        )}
      </Stack>
      {isVisible && step === 1 && (
        <Stack
          mt={3}
          bgcolor={(theme) => theme.palette.color.lightgray}
          borderRadius={"18px"}
          p={"24px"}
          alignItems={"center"}
        >
          <Box sx={{ minWidth: "500px" }}>
            <Stack direction={"row"} width={"100%"}>
              <Typography variant="heading_400">Old Password</Typography>
              <Box
                sx={{
                  bgcolor: (theme) => theme.palette.color.greenShade,
                  borderRadius: "50%",
                  p: "1px",
                  fontSize: "10px",
                  color: "white",
                  ml: "3px",
                  width: "14px",
                  height: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RxCheck />
              </Box>
            </Stack>
            <TextField
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              InputProps={{
                sx: {
                  borderRadius: "18px",
                  "& fieldset": {
                    borderColor: (theme) =>
                      theme.palette.color.greenShade + " !important",
                    "&:hover": {
                      borderColor: (theme) => theme.palette.color.greenShade,
                    },
                    "&.Mui-focused": {
                      borderColor: (theme) => theme.palette.color.greenShade,
                    },
                  },
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "13px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        color: (theme) => theme.palette.color.secondary,
                        padding: "4px",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      s
                    >
                      {!showPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 4,
                mt: 2,
              }}
            >
              <Typography
                variant="caption_500"
                onClick={() => setIsVisible(false)}
              >
                Cancel
              </Typography>
              <Button
                onClick={() => {
                  setStep(2);
                  setShowPassword(true);
                }}
                sx={{
                  px: "95px",
                  color: (theme) => theme.palette.color.bg,
                  borderRadius: "24px",
                  bgcolor: (theme) => theme.palette.color.greenShade,
                  ":hover": {
                    bgcolor: (theme) => theme.palette.color.greenShade,
                  },
                }}
              >
                Next
              </Button>
            </Box>
            <Box sx={{ display: "flex", mt: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                  textDecoration: "underline",
                }}
              >
                Forget Password?
              </Typography>
            </Box>
          </Box>
        </Stack>
      )}
      {isVisible && step === 2 && (
        <Stack
          mt={3}
          bgcolor={(theme) => theme.palette.color.bg4}
          borderRadius={"18px"}
          p={"24px"}
          alignItems={"center"}
        >
          <Box sx={{ minWidth: "500px" }}>
            <Stack direction={"row"} width={"100%"}>
              <Typography variant="heading_400">New Password</Typography>
            </Stack>
            <TextField
              type={showPassword ? "text" : "password"}
              placeholder="d@gfo!fp45MH"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              error={!isPasswordValid()}
              InputProps={{
                sx: {
                  borderRadius: "18px",
                  borderColor: isPasswordValid()
                    ? (theme) => theme.palette.color.cyan
                    : (theme) => theme.palette.color.red,
                  "&:hover": {
                    borderColor: isPasswordValid()
                      ? (theme) => theme.palette.color.cyan
                      : (theme) => theme.palette.color.red,
                  },
                  "&.Mui-focused": {
                    borderColor: isPasswordValid()
                      ? (theme) => theme.palette.color.cyan
                      : (theme) => theme.palette.color.red,
                  },
                  "& fieldset": {
                    borderColor: (theme) =>
                      theme.palette.color.greenShade + " !important",
                    "&:hover": {
                      borderColor: (theme) => theme.palette.color.greenShade,
                    },
                    "&.Mui-focused": {
                      borderColor: (theme) => theme.palette.color.greenShade,
                    },
                  },
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "13px",
                },
                // sx: {
                //   borderRadius: "18px",
                //   "& fieldset": {
                //     borderColor: (theme) =>
                //       theme.palette.color.greenShade + " !important",
                //     "&:hover": {
                //       borderColor: (theme) => theme.palette.color.greenShade,
                //     },
                //     "&.Mui-focused": {
                //       borderColor: (theme) => theme.palette.color.greenShade,
                //     },
                //   },
                //   color: (theme) => theme.palette.color.secondary,
                //   fontSize: "13px",
                // },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        color: (theme) => theme.palette.color.secondary,
                        padding: "4px",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      s
                    >
                      {!showPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Stack>
              <Typography
                variant="caption"
                sx={{
                  color: isPasswordValid()
                    ? (theme) => theme.palette.color.secondary
                    : (theme) => theme.palette.color.red,
                  fontWeight: "400",
                }}
              >
                Please add all necessary characters to create a safe password
              </Typography>
              <Typography
                mt={1}
                variant="caption"
                sx={{
                  color: hasMinLength()
                    ? (theme) => theme.palette.color.secondary
                    : (theme) => theme.palette.color.red,
                  fontWeight: "400",
                }}
              >
                • Minimum 12 characters
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: hasUppercase()
                    ? (theme) => theme.palette.color.secondary
                    : (theme) => theme.palette.color.red,
                  fontWeight: "400",
                }}
              >
                • One uppercase character
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: hasLowercase()
                    ? (theme) => theme.palette.color.secondary
                    : (theme) => theme.palette.color.red,
                  fontWeight: "400",
                }}
              >
                • One lowercase character
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: hasSpecialChar()
                    ? (theme) => theme.palette.color.secondary
                    : (theme) => theme.palette.color.red,
                  fontWeight: "400",
                }}
              >
                • One special character
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: hasNumber()
                    ? (theme) => theme.palette.color.secondary
                    : (theme) => theme.palette.color.red,
                  fontWeight: "400",
                }}
              >
                • One number
              </Typography>
            </Stack>
            <Stack direction={"row"} width={"100%"} mt={2}>
              <Typography variant="heading_400">
                Confirm New Password
              </Typography>
            </Stack>
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Enter your confirm new password"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              error={confirmPassword !== password}
              InputProps={{
                sx: {
                  borderRadius: "18px",
                  borderColor:
                    confirmPassword === password
                      ? (theme) => theme.palette.color.greenShade
                      : (theme) => theme.palette.color.red,
                  "&:hover": {
                    borderColor:
                      confirmPassword === password
                        ? (theme) => theme.palette.color.greenShade
                        : (theme) => theme.palette.color.red,
                  },
                  "&.Mui-focused": {
                    borderColor:
                      confirmPassword === password
                        ? (theme) => theme.palette.color.greenShade
                        : (theme) => theme.palette.color.red,
                  },
                  "& fieldset": {
                    borderColor: (theme) =>
                      (confirmPassword === password
                        ? theme.palette.color.greenShade
                        : theme.palette.color.red) + " !important",
                    "&:hover": {
                      borderColor:
                        confirmPassword === password
                          ? (theme) => theme.palette.color.greenShade
                          : (theme) => theme.palette.color.red,
                    },
                    "&.Mui-focused": {
                      borderColor:
                        confirmPassword === password
                          ? (theme) => theme.palette.color.greenShade
                          : (theme) => theme.palette.color.red,
                    },
                  },
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "13px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        color: (theme) => theme.palette.color.secondary,
                        padding: "4px",
                      }}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                      s
                    >
                      {!showConfirmPassword ? (
                        <EyeCloseIcon />
                      ) : (
                        <EyeOpenIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 4,
                mt: 2,
              }}
            >
              <Button
                onClick={() => {
                  if (confirmPassword === password && isPasswordValid()) {
                    handleNext();
                  }
                }}
                sx={{
                  px: "95px",
                  color: (theme) => theme.palette.color.bg,
                  borderRadius: "24px",
                  width: "100%",
                  cursor:
                    confirmPassword !== password || !isPasswordValid()
                      ? "not-allowed"
                      : "pointer",
                  bgcolor: (theme) => theme.palette.color.greenShade,
                  ":hover": {
                    bgcolor: (theme) => theme.palette.color.greenShade,
                  },
                }}
              >
                Change Password
              </Button>
            </Box>
          </Box>
        </Stack>
      )}
      {isVisible && step === 3 && (
        <Stack
          mt={3}
          bgcolor={(theme) => theme.palette.color.bg4}
          borderRadius={"18px"}
          p={"24px"}
          alignItems={"center"}
        >
          <Box sx={{ minWidth: "500px" }}>
            <Typography
              variant="heading"
              sx={{
                fontWeight: "400",
              }}
              align="center"
            >
              Verification
            </Typography>
            <Typography
              variant="body2"
              mt={1.5}
              fontSize={"14px"}
              align="center"
            >
              Enter the 6-digit code sent to j*****@****oud.com
            </Typography>
            <Stack direction="row" justifyContent="center" spacing={2} mt={3}>
              {[...Array(6)].map((_, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center" },
                  }}
                  sx={{
                    width: "71px !important",
                    height: "71px !important",
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "18px",
                    color: (theme) => theme.palette.color.primary,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      bgcolor: (theme) => theme.palette.color.bg2,
                      "& fieldset": {
                        borderColor: (theme) =>
                          theme.palette.color.bg2 + " !important",
                        outline: "none",
                      },
                    },
                  }}
                />
              ))}
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 4,
                mt: 4,
              }}
            >
              <Stack direction={"row"} spacing={"2px"} onClick={handleBack}>
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.palette.color.primary }}
                >
                  Resend Code
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.palette.color.secondary }}
                >
                  (59s)
                </Typography>
              </Stack>
              <Button
                onClick={handleNext}
                sx={{
                  px: "95px",
                  color: (theme) => theme.palette.color.white,
                  borderRadius: "24px",
                  bgcolor: (theme) => theme.palette.color.cyan,
                  ":hover": {
                    bgcolor: (theme) => theme.palette.color.cyan,
                  },
                }}
              >
                Verify
              </Button>
            </Box>
          </Box>
        </Stack>
      )}
      {isVisible && step === 4 && (
        <Stack
          mt={3}
          bgcolor={(theme) => theme.palette.color.bg4}
          borderRadius={"18px"}
          p={"24px"}
          alignItems={"center"}
        >
          <Box
            sx={{
              minWidth: "500px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {showSpinner ? (
              <>
                <CircularProgress
                  sx={{ color: (theme) => theme.palette.color.active, mb: 2 }}
                />
                <Typography variant="body1" fontSize={"18px"} sx={{ mb: 2 }}>
                  Password successfully changed
                </Typography>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    color: (theme) => theme.palette.color.active,
                    fontSize: "40px",
                    mb: 2,
                  }}
                >
                  <RxCheck />
                </Box>
                <Typography variant="body1" fontSize={"18px"} sx={{ mb: 2 }}>
                  Password successfully changed
                </Typography>
              </>
            )}
          </Box>
        </Stack>
      )}
    </Box>
  );
};

const TwoFactorAuthenticationSection = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [show2factor, setShow2factor] = React.useState(false);

  return (
    <Box sx={{ mb: 4, p: "24px" }}>
      <Stack spacing={1} direction={"row"} justifyContent={"space-between"}>
        <Stack>
          <Typography
            variant="heading"
            sx={{
              fontWeight: "400",
            }}
          >
            2-factor authentication
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Keep your account secure by enabling 2FA via Email or using a
            temporary one-time passcode (TOTP) from an authenticator app.
          </Typography>
        </Stack>
        <CustomSwitch
          checked={show2factor}
          onChange={() => setShow2factor(!show2factor)}
        />
      </Stack>
      {show2factor && (
        <>
          <Stack
            mt={4}
            spacing={1}
            justifyContent={"space-between"}
            direction={isSmall ? "column" : "row"}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <MailIcon stroke={"#5EEDF8"} />
              <Stack>
                <Typography variant="heading" sx={{ fontWeight: "500" }}>
                  Email authentication
                </Typography>
                <Typography variant="body2">
                  Retrieve codes from an authentication app on your device, like
                  Google Authenticator, Duo Mobile or Authy.
                </Typography>
              </Stack>
            </Stack>
            <CustomSwitch />
          </Stack>
          <Stack
            mt={4}
            spacing={1}
            justifyContent={"space-between"}
            direction={isSmall ? "column" : "row"}
          >
            <Stack direction="row" gap={1.5} pl={0.5} alignItems="center">
              <FingerprintIcon stroke={"#5EEDF8"} />
              <Stack>
                <Typography variant="heading" sx={{ fontWeight: "500" }}>
                  Authentication app
                </Typography>
                <Typography variant="body2">
                  Retrieve codes from an authentication app on your device, like
                  Google Authenticator, Duo Mobile or Authy.
                </Typography>
              </Stack>
            </Stack>
            <CustomSwitch />
          </Stack>
        </>
      )}
    </Box>
  );
};

const SessionHistorySection = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        mb: 4,
        p: "24px",
        borderRadius: "18px",
        bgcolor: (theme) => theme.palette.color.bg2,
      }}
    >
      <Stack spacing={1} direction={"column"}>
        <Typography
          variant="heading"
          sx={{
            fontWeight: "400",
          }}
        >
          Session History
        </Typography>
        <Typography variant="body3">
          Manage who can message you directly.
        </Typography>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack
        spacing={1}
        justifyContent={"space-between"}
        direction={isSmall ? "column" : "row"}
      >
        <Stack>
          <Typography variant="heading" sx={{ fontWeight: "500" }}>
            Mac, Mac OS X 10.15.7
          </Typography>
          <Typography variant="body2">
            Arnhem, The Netherlands · 82.172.181.198 · TV Desktop 2.7.7
          </Typography>
        </Stack>
        <Button
          sx={{
            width: "200px",
            color: (theme) => theme.palette.color.greenShade,
            borderColor: (theme) => theme.palette.color.greenShade,
            border: "1px solid",
            borderRadius: "24px",
            bgcolor: (theme) => theme.palette.color.bg,
            ":hover": {
              bgcolor: (theme) => theme.palette.color.bg,
            },
          }}
        >
          Active Now
        </Button>
      </Stack>
      <Stack
        mt={4}
        justifyContent={"space-between"}
        direction={isSmall ? "column" : "row"}
      >
        <Stack>
          <Typography variant="heading" sx={{ fontWeight: "500" }}>
            Iphone, IOS 13.01.5
          </Typography>
          <Typography variant="body2">
            Arnhem, The Netherlands · 82.172.181.198 · TV Desktop 2.7.7
          </Typography>
        </Stack>
        <Button
          sx={{
            width: "200px",
            color: (theme) => theme.palette.color.secondary,
            borderColor: (theme) => theme.palette.color.lightgray,
            border: "1px solid",
            borderRadius: "24px",
            bgcolor: (theme) => theme.palette.color.bg,
            ":hover": {
              bgcolor: (theme) => theme.palette.color.bg,
            },
          }}
        >
          Logged out
        </Button>
      </Stack>
    </Box>
  );
};

export default PrivacySecurity;
