import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import CustomSwitch from "../../components/ui/Switch";
import { FiSend } from "react-icons/fi";
import { IoIosMore } from "react-icons/io";

const Recovery = () => {
    return (
        <Box>
            <Box
                sx={{
                    px: "24px",
                    py: "10px",
                }}
            >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"end"}>
                    <Typography variant="h3" gutterBottom>
                        Payment recovery
                    </Typography>
                    <Button size='small' sx={{ p: "5px", px: "10px", borderRadius: "32px", bgcolor: (theme) => theme.palette.color.active, color: "white", ":hover": { background: (theme) => theme.palette.color.active } }} onClick={() => { }}>
                        Save Changes
                    </Button>
                </Stack>
                <Stack direction={"row"} width={"70%"} mt={1}>
                    <Typography variant="caption" gutterBottom>
                        Manage the payment recovery settings for your store
                    </Typography>
                </Stack>
            </Box>
            <Divider />
            <Box
                sx={{
                    p: "24px",
                }}
            >
                <Grid container spacing={2} rowSpacing={3}>
                    {/* <Grid item xs={12} md={6}></Grid> */}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <Typography variant="heading">Abandoned cart emails</Typography>
                            <Typography variant="caption" sx={{ fontWeight: "500" }}>
                                Send 3 reminder emails to customers who left a product in their cart but did not complete their purchase
                            </Typography>
                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            sx={{ height: "100%" }}
                        >
                            <CustomSwitch />
                            <Typography variant="caption" sx={{ fontWeight: "500" }}>
                                Enable automatic abandoned cart emails
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <Typography variant="heading">Customer reminders</Typography>
                            <Typography
                                variant="caption"
                                sx={{ fontWeight: "500", width: "90%" }}
                            >
                                Send up to 5 customer reminder emails
                            </Typography>
                        </Stack>
                    </Grid>




                    <Grid item xs={12} md={6}>
                        <MessageCard title={"Message 1"} caption={"Sent on day auto-recovery fails"} />
                        <MessageCard title={"Message 1"} caption={"Sent on day auto-recovery fails"} />
                        <MessageCard title={"Message 1"} caption={"Sent on day auto-recovery fails"} />
                        <MessageCard title={"Message 1"} caption={"Sent on day auto-recovery fails"} />
                        <MessageCard title={"Message 1"} caption={"Sent on day auto-recovery fails"} />
                    </Grid>


                </Grid>
            </Box>
        </Box>
    );
};

export default Recovery;



const MessageCard = ({ title, caption }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: (theme) => theme.palette.color.bg5,
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.strockborder,
                padding: '8px 16px',
                borderRadius: '16px',
                color: '#b0b0b0',
                width: '100%',
                mb: 2,
            }}
        >
            <Stack direction={"row"} justifyContent={"space-between"} p={1}>
                <Stack direction={"row"}>
                    <Box p={2} sx={{ bgcolor: (theme)=>theme.palette.color.lightgray , borderRadius: "10px", mr: 2 }}>
                        <FiSend
                            sx={{
                                fontSize: '35px',
                                color: '#6e6e6e',
                                marginRight: '16px',
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" sx={{ color: '#e0e0e0' }}>
                            {title}
                        </Typography>
                        <Typography variant="body2">{caption}</Typography>
                    </Box>

                </Stack>
            </Stack>
            <IconButton sx={{ color: '#b0b0b0' }}>
                <IoIosMore />
            </IconButton>
        </Box>
    )
}