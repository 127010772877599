import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import {
  Button,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import FormInput from "../ui/FormInput";
import CustomAccordion from "../ui/CustomAccordion";
import FormSwitch from "../ui/FormSwitch";
import { toggleOpen } from "../../slices/openModalSlice";
import FormRadio from "../ui/Radio";
import RetryIcon from "../Icons/Retry";
import SalesIcon from "../Icons/Sale";
import zIndex from "@mui/material/styles/zIndex";

const labelStyle = {
  fontSize: "13px",
  fontWeight: "400",
};

const inputStyle = {
  fontSize: "13px",
  borderRadius: "14px",
};

const AddDiscount = () => {
  const { open } = useSelector((state) => state.openModal);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    name: "",
    code: "",
    amount: "",
    type: "percentage",
  });

  return (
    <React.Fragment>
      <Drawer
        sx={
          {
            // borderRadius: "14px 0 14px 0",
            // overflow: "hidden",
          }
        }
        open={open}
        onClose={() => dispatch(toggleOpen())}
        anchor="right"
        // position="relative"
      >
        <Box
          sx={{
            position: "fixed",
            top: "18px",
            left: "760px",
          }}
        >
          <IconButton
            sx={{
              width: "56px",
              height: "56px",
              background: (theme) => theme.palette.color.borderpure,
              transition: "all 0.5s",
              "&:hover": {
                rotate: "180deg",
              },
            }}
            onClick={() => dispatch(toggleOpen())}
          >
            <CloseIcon
              sx={{
                color: (theme) => theme.palette.color.primary,
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: "fixed",
            top: "24px",
            left: "-24px",
          }}
        >
          <IconButton
            sx={{
              width: "56px",
              height: "56px",
              background: (theme) => theme.palette.color.borderpure,
            }}
            onClick={() => dispatch(toggleOpen())}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Paper
          sx={{
            width: "558px",
            height: "100vh",
            overflow: "hidden",
            background: (theme) => theme.palette.color.bg5,
          }}
        >
          <Box
            sx={{
              height: "calc(100vh - 70px)",
              overflowY: "auto",
              padding: "24px",
            }}
          >
            <Stack
              spacing={1.5}
              sx={{
                position: "fixed",
                top: "0",
                background: (theme) => theme.palette.color.bg2,
                py: "24px",
                zIndex: "100",
                width: "100%",
              }}
            >
              <Typography variant="h2" sx={{ fontWeight: "600" }}>
                Add Discount
              </Typography>

              <Typography variant="caption_500">
                Create a discount code for all or some of your products · Help↗
              </Typography>
            </Stack>

            <Stack spacing={4} sx={{ pb: "48px", pt: "100px" }}>
              <FormInput
                label="Name"
                placeholder="Enter Name"
                helperTexts={["This will appear on your customer’s invoice"]}
                labelStyle={labelStyle}
                sx={inputStyle}
                required
                value={formValues.name}
                onChange={(e) =>
                  setFormValues({ ...formValues, name: e.target.value })
                }
              />
              <FormInput
                label="Code"
                placeholder="Enter code"
                labelStyle={labelStyle}
                sx={inputStyle}
                required
                value={formValues.code}
                onChange={(e) =>
                  setFormValues({ ...formValues, code: e.target.value })
                }
                endAdornment={
                  <Box
                    sx={{
                      width: "60px",
                      background: (theme) => theme.palette.color.lightgray,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "40px",
                    }}
                  >
                    <RetryIcon />
                  </Box>
                }
              />
              <FormInput
                label="Amount"
                placeholder="Enter amount"
                type="number"
                labelStyle={labelStyle}
                sx={inputStyle}
                required
                value={formValues.amount}
                onChange={(e) =>
                  setFormValues({ ...formValues, amount: e.target.value })
                }
                endAdornment={
                  <Box
                    sx={{
                      width: "60px",
                      background: (theme) => theme.palette.color.lightgray,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "40px",
                      color: (theme) => theme.palette.color.secondary,
                    }}
                  >
                    %
                  </Box>
                }
              />

              <FormRadio
                options={[
                  { value: "percentage", label: "Percentage" },
                  { value: "fixed", label: "Fixed" },
                ]}
                value={formValues.type}
                onChange={(value) =>
                  setFormValues({ ...formValues, type: value })
                }
              />
              <Stack spacing={2}>
                <Typography variant="h5" sx={{ fontWeight: "500" }}>
                  Products
                </Typography>
                <FormSwitch
                  label="Discount applies to specific products or account sizes?"
                  flex
                  helperTexts={[
                    "Select which product(s) the discount applies to. Discounts apply to all products if left unchecked.",
                  ]}
                />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h5" sx={{ fontWeight: "500" }}>
                  Discounts
                </Typography>
                <FormSwitch
                  label="Discount has a start date?"
                  flex
                  helperTexts={[
                    "Schedule the discount to activate in the future",
                  ]}
                />
                <FormSwitch
                  label="Discount has an expiry date?"
                  flex
                  helperTexts={[
                    "Schedule the discount to deactivate in the future",
                  ]}
                />
                <FormSwitch
                  label="Limit the number of redemptions?"
                  flex
                  helperTexts={[
                    "Limit applies across all customers, not per customer",
                  ]}
                />
                <FormSwitch
                  label="Discount applies every month?"
                  flex
                  helperTexts={[
                    "Set the duration of the discount when applied to a subscription",
                  ]}
                />
              </Stack>
            </Stack>
          </Box>
          <Stack
            spacing={1}
            sx={{
              padding: "6px 32px",
              background: (theme) => theme.palette.color.bg2,
              position: "sticky",
              height: "70px",
              bottom: 0,
            }}
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
          >
            <Button
              sx={{
                background: "transparent",
                color: (theme) => theme.palette.color.primary,
                borderRadius: "9px",
                fontSize: "13px",
                fontWeight: "500",
                ":hover": {
                  background: "transparent",
                },
              }}
              onClick={() => {
                dispatch(toggleOpen());
              }}
            >
              Cancel
            </Button>

            <Button
              sx={{
                background: (theme) => theme.palette.color.border,
                color: (theme) => theme.palette.color.primary,
                borderRadius: "9px",
                fontSize: "13px",
                fontWeight: "500",

                boxShadow: "none",
                ":hover": {
                  background: (theme) => theme.palette.color.border,
                },
              }}
              variant="contained"
            >
              Done
            </Button>
          </Stack>
        </Paper>
      </Drawer>
    </React.Fragment>
  );
};

export default AddDiscount;
