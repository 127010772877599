import React from 'react'
import { Box, Stack, Typography, Grid } from '@mui/material'
import InfoIcon2 from '../../components/Icons/Info2';

const RevenueAndEarnings = () => {

  const revenueData = [
    { title: "Total Revenue", value: "$1,200,000" },
    { title: "Affiliate Earnings", value: "$300,000" },
    { title: "Revenue per affiliate", value: "$4,500" },
    { title: "Earnings per Click", value: "$0.75" }
  ];

  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Typography variant="h3" mb={3}>Revenue and Earnings</Typography>
      <Grid container spacing={2}>
        {revenueData.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <InfoBox
              title={item.title}
              value={item.value}
              icon={<InfoIcon2 />}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default RevenueAndEarnings

const InfoBox = ({ title, value, icon }) => (
  <Box sx={{
    background: (theme) => theme.palette.color.bg5,
    borderRadius: "18px",
    border: "1px solid",
    borderColor: (theme) => theme.palette.color.border,
    height: '126px',
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 1
  }}>
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.secondary, fontSize: "14px" }}>{title}</Typography>
      {icon}
    </Stack>
    <Typography variant="h2">{value}</Typography>
  </Box>
);