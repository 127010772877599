import React, { useState } from 'react';
import { Box, Stack, Typography, Button, Grid, useMediaQuery, styled, Tooltip } from '@mui/material';
import dayjs from "dayjs";
import ExportSection from '../../components/ui/ExportSection';
import CalendarPopup from '../../components/ui/CalendarPopup';
import RoundFormSelect from '../../components/ui/RoundFormSelect';
import ReactApexChart from 'react-apexcharts';
import InfoIcon2 from '../../components/Icons/Info2';
import { FiArrowUpRight } from 'react-icons/fi';


const StatisticsProducts = () => {
    const [startDate, setStartDate] = useState(dayjs().startOf("week"));
    const [endDate, setEndDate] = useState(dayjs().endOf("week"));

    const RevenueData = [
        {
            title: "Top Revenue Product",
            tooltip: "Top Revenue Product",
            value: "$250,000",
            subtitle: "100K Account Type"
        },
        {
            title: "Lowest Revenue Product",
            tooltip: "Lowest Revenue Product",
            value: "$50,000",
            subtitle: "25K Account Type"
        },
        {
            title: "Average Revenue per Product",
            tooltip: "Average Revenue per Product",
            value: "$120,000",
            subtitle: "per Product"
        },
    ];

    const PopularProductsData = [
        {
            title: "Top-Selling Challenge",
            tooltip: "Top-Selling Challenge",
            value: "500",
            subtitle: "Units Sold"
        },
        {
            title: "Top-Selling Account Type",
            tooltip: "Top-Selling Account Type",
            value: "100K Account",
            subtitle: "300 Accounts Sold"
        },
        {
            title: "Total Sales Across All Products",
            tooltip: "Total Sales Across All Products",
            value: "1200"        
        },
    ];

    const ItemData = [
        {
            title: "Average Customer Satisfaction",
            tooltip: "Average Customer Satisfaction",
            value: "4.5/5",
        },
        {
            title: "Most Reviewed Product",
            tooltip: "Advanced Trading Challenge",
            value: "Advanced Trading Challenge",
            subtitle: "75% Positive",
            SubtitleColor: (theme) => theme.palette.color.green
        },
        {
            title: "Top Rated Product",
            tooltip: "Top Rated Product",
            value: "4.8/5",
            subtitle: "100K Account Type"
        },
    ];

    return (
        <Box sx={{ width: '100%' }} p={3} pb={10}>
            <Stack bgcolor={(theme) => theme.palette.color.bg} direction="row" justifyContent="space-between" pl={1} position={"sticky"} zIndex={10} top={0} py={1}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5">Products</Typography>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <img src="/svg/leftArrow.svg" alt="leftArrow" style={{ width: "6px", height: "10px", cursor: "pointer" }} />
                        <Typography variant="caption">June 2024</Typography>
                        <img src="/svg/rightArrow.svg" alt="RightArrow" style={{ width: "6px", height: "10px", transform: "rotate(180deg)", cursor: "pointer" }} />
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                        fontSize: "11.5px",
                        color: (theme) => theme.palette.color.secondary,
                    }}
                >
                    <ExportSection />
                    <CalendarPopup
                        mainEndDate={endDate}
                        mainStartDate={startDate}
                        setMainEndDate={setEndDate}
                        setMainStartDate={setStartDate}
                    />
                    <Button variant="contained" size="small" disableElevation sx={{
                        py: "2px",
                        bgcolor: (theme) => theme.palette.color.lightgray,
                        borderRadius: "32px",
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.color.border,
                        color: (theme) => theme.palette.color.secondary,
                        ":hover": {
                            bgcolor: (theme) => theme.palette.color.lightgray,
                        }
                    }}>
                        <img src="/svg/stars.svg" alt="Stars" style={{ width: 24, height: 24, marginRight: 8 }} />
                        AI Analyze
                    </Button>
                </Stack>
            </Stack>

            <Typography variant="h3" sx={{ fontWeight: 500, fontSize: "18px", mt: 3 }}>Revenue per sales channel</Typography>
            <Grid container spacing={3} mt={0.1}>
                {RevenueData.map((item, index) => (
                    <Grid item xs={6} sm={6} md={4} key={index}>
                        <InfoBox
                            item={item}
                            sx={{
                                background: (theme) => theme.palette.color.bg2,
                            }}
                        />
                    </Grid>
                ))}
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <RevenueByProductType />
                </Grid>
            </Grid>

            <Typography variant="h3" sx={{ fontWeight: 500, fontSize: "18px", mt: 3 }}>Popular products</Typography>
            <Grid container spacing={3} mt={0.5}>
            {PopularProductsData.map((item, index) => (
                    <Grid item xs={6} sm={6} md={4} key={index}>
                        <InfoBox
                            item={item}
                            sx={{
                                background: (theme) => theme.palette.color.bg2,
                            }}
                        />
                    </Grid>
                ))}
            </Grid>

            <RevenueConversionsChart />

            <Typography variant="h3" sx={{ fontWeight: 500, fontSize: "18px", mt: 3 }}>Popular products</Typography>
            <Grid container spacing={3} my={0.5}>
                {ItemData.map((item, index) => (
                    <Grid item xs={6} sm={6} md={4} key={index}>
                        <InfoBox
                            item={item}
                            sx={{
                                background: (theme) => theme.palette.color.bg2,
                            }}
                        />
                    </Grid>
                ))}
            </Grid>

            <CustomerRatingDistribution />
        </Box>
    );
};

export default StatisticsProducts;

const InfoBox = ({ item, icon, sx }) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: "24px",
                width: "100%",
                height: "144px",
                background: (theme) => theme.palette.color.bg5,
                borderRadius: "18px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.border,
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",

                    color: (theme) => theme.palette.color.secondary,
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        alignItems: "center",
                    }}
                >
                    <Typography variant="caption">{item.title}</Typography>
                    <Tooltip title={item.tooltip} placement="top">
                        {icon ? icon : <InfoIcon2 fill="none" />}
                    </Tooltip>
                </Stack>
            </Box>
            <Typography variant="h1" fontSize={isSmall ? "20px" : "20px"} color={item.color ? item.color : (theme) => theme.palette.color.primary} mt={3}>{item.value}</Typography>
            {item?.subtitle && <Typography variant="caption" color={item.SubtitleColor ? item.SubtitleColor : (theme) => theme.palette.color.secondary} pt={1} pb={2}>{item?.subtitle}</Typography>}
            {item.percent && (
                <Stack
                    direction="row"
                    spacing={1}
                    mt={1}
                    sx={{
                        alignItems: "center",
                        color: (theme) => theme.palette.color.green,
                    }}
                >
                    <FiArrowUpRight size={16} />
                    <Typography variant="caption">{item.percent}</Typography>
                </Stack>
            )}
        </Box>
    );
};

const RevenueByProductType = () => {
    const ChartContainer = styled(Box)({
        width: '100%',
        height: '100%',
        '& .apexcharts-toolbar': {
            display: 'none !important',
        },
    });

    const series = [
        {
            name: '25K Account',
            data: [9, 12, 11],
        },
        {
            name: '50K Account',
            data: [12, 10, 8],
        },
        {
            name: '100K Account',
            data: [14, 8, 6],
        },
        {
            name: '100K Account',
            data: [14, 8, 6],
        },
    ];

    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                columnWidth: '30%',
                spacing: 10,
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#5EEDF8', '#5F55EE', '#2FE0A2', '#F55F82'],
        },
        xaxis: {
            categories: ['25K Account', '50K Account', '100K Account'],
            labels: {
                style: {
                    colors: ['#8A96A6', '#8A96A6', '#8A96A6'],
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            min: 0,
            max: 14,
            title: {
                text: "Amount",
                style: {
                    color: '#8A96A6',
                },
            },
            labels: {
                formatter: (val) => `${val}`,
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        fill: {
            opacity: 1,
            colors: ['#1D232B', '#1D232B'],
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: "vertical",
                shadeIntensity: 1,
                gradientToColors: ['#1D232B'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        tooltip: {
            enabled: false,
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 1,
                },
            },
        },
    };

    return (
        <Box sx={{
            bgcolor: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            color: 'white',
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            margin: '0 auto',
            width: '100%',
            mt: 3,
        }}>
            <Stack direction="row" p={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5">
                Revenue by Product Type
                </Typography>
                <Stack direction="row" gap={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#5EEDF8', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Q1</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#5F55EE', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Q2</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#2FE0A2', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Q3</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: '#F55F82', borderRadius: '50%', mr: 1 }} />
                        <Typography variant="body2">Q4</Typography>
                    </Box>
                    <RoundFormSelect
                        options={[{ value: "quater", label: "Quater" }]}
                        defaultValue="quater"
                    />
                </Stack>
            </Stack>
            <ChartContainer>
                <ReactApexChart
                    options={options}
                    series={series.map(({ name, data }, index) => ({
                        name,
                        data,
                        type: 'column',
                        fillColor: index === 0 ? {
                            colors: ['#5EEDF8'], // Gradient color for profit
                            borderColor: '#5EEDF8',
                            backgroundBarColors: ['#5EEDF8'],
                            backgroundBarOpacity: 1,
                        } : index === 1 ? {
                            colors: ['#5F55EE'], // Gradient color for loss
                            borderColor: '#5F55EE',
                            backgroundBarColors: ['#5F55EE'],
                            backgroundBarOpacity: 1,
                        } : {
                            colors: ['#2FE0A2'], // Gradient color for index 2
                            borderColor: '#2FE0A2',
                            backgroundBarColors: ['#2FE0A2'],
                            backgroundBarOpacity: 1,
                        },
                    }))}
                    type="bar"
                    height={350}
                />
            </ChartContainer>
        </Box>
    );
};

const CustomerRatingDistribution = () => {
    const chartData = {
        series: [{
            data: [600, 800, 440, 400, 810, 450]
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: { show: false }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    borderRadius: 4,
                    barHeight: '70%',
                    distributed: true,
                    colors: {
                        backgroundBarColors: ['#0D1117'],
                        backgroundBarOpacity: 1,
                    },
                },
            },
            legend: {
                show: false
            },
            colors: ['#5F55EE'],
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                min: 0,
                max: 1600,
                tickAmount: 8,
                categories: ['250K', '200K', '150K', '100K', '50K', '25K'],
                labels: {
                    show: true,
                    formatter: function (val) {
                        return val + "K";
                    },
                    style: {
                        colors: ['#8A96A6'],
                        padding: { top: 10, bottom: 10 }
                    }
                },
                axisBorder: { show: false },
                axisTicks: { show: false },
            },
            yaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: ['#8A96A6'],
                    }
                }
            },
            grid: {
                show: false
            },
            tooltip: {
                enabled: false
            },
        }
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            p: "14px",
            width: "100%",
            height: "396px",
            my: 2,
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
        }}>
            <Typography variant="h6" pl={2}>Customer Rating Distribution</Typography>
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={"100%"} />
        </Box>
    );
}

const RevenueConversionsChart = () => {
    const series = [
        {
            name: "Advanced Trading Challenge",
            data: [1200, 1000, 1100, 900, 1300, 1200, 1100, 900, 1000, 1100, 1200, 1300],
        },
        {
            name: "100k Account Type",
            data: [1000, 800, 900, 700, 1100, 1000, 900, 700, 800, 900, 1000, 1100],
        },
        {
            name: "Beginner Trading Challenge",
            data: [800, 600, 700, 500, 900, 800, 700, 500, 600, 700, 800, 900],
        },
    ];

    const options = {
        chart: {
            type: "area",
            toolbar: { show: false },
            background: "transparent",
        },
        colors: ["#726AE3", "#28B4C8", "#2FE0A2"],
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                gradientToColors: ["#3D38B3", "#28B4C8", "#34E89E"],
                shadeIntensity: 1,
                type: "vertical",
                opacityFrom: 0.6,
                opacityTo: 0.4,
                stops: [0, 90],
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
            width: 2,
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            labels: {
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        yaxis: {
            min: 200,
            max: 1600,
            labels: {
                style: {
                    colors: "#8A96A6",
                },
            },
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        markers: {
            size: 4,
            colors: ["#726AE3", "#28B4C8", "#2FE0A2"],
            strokeColors: "#0D1117",
            strokeWidth: 0,
        },
        tooltip: {
            theme: "dark",
        },
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            p: "14px",
            width: "100%",
            height: "450px",
            my: 2,
            background: (theme) => theme.palette.color.bg5,
            borderRadius: "18px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.border,
        }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" sx={{ color: "#CAD0DB" }}>
                    Revenue and Conversions by Campaign
                </Typography>
                <Stack direction="row" gap={2}>
                    <RoundFormSelect
                        options={[{ value: "monthly", label: "Monthly" }]}
                        defaultValue="monthly"
                    />
                </Stack>
            </Box>
            <ReactApexChart options={options} series={series} type="area" height={320} />
            <Stack direction="row" gap={2} justifyContent="center">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6" cy="3" r="3" fill="#5F55EE" />
                        <rect y="2.5" width="12" height="1" fill="#5F55EE" />
                    </svg>
                    <Typography variant="body2">Advanced Trading Challenge</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6" cy="3" r="3" fill="#8CADFF" />
                        <rect y="2.5" width="12" height="1" fill="#8CADFF" />
                    </svg>
                    <Typography variant="body2">Beginner Trading Challenge</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6" cy="3" r="3" fill="#5EEDF8" />
                        <rect y="2.5" width="12" height="1" fill="#5EEDF8" />
                    </svg>
                    <Typography variant="body2">Beginner Trading Challenge</Typography>
                </Box>
            </Stack>
        </Box>
    );
};