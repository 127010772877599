import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/Firm/Orders/Table";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import FormInput from "../../components/ui/FormInput";
import PaymentCard from "../../components/Settings/PaymentCard";
import ExportSection from "../../components/ui/ExportSection";
import { FaRegFilePdf } from "react-icons/fa6";
import SmallSearchBar from "../../components/Affiliates/smallSearchBar";
import DownloadIcon from "../../components/Icons/Download";
import {
  useGetBillingDetailsQuery,
  useUpdateBillingDetailsMutation,
} from "../../slices/api/settingSlice";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      amount="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const headcells = [
  {
    id: "name",
    label: "Invoice",
    getCell: (row) => {
      return (
        <Stack direction="row" alignItems="center">
          <Box
            sx={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "15px",
              bgcolor: (theme) => theme.palette.color.lightgray,
            }}
          >
            <FaRegFilePdf />
          </Box>
          <Typography ml={1} color={(theme) => theme.palette.color.secondary}>
            {row.name}
          </Typography>
        </Stack>
      );
    },
  },
  {
    id: "amount",
    label: "Amount",
    getCell: (row) => row.amount,
  },
  {
    id: "dueDate",
    label: "Due date",
    getCell: (row) => row.dueDate,
  },
  {
    id: "billingDate",
    label: "Billing Date",
    getCell: (row) => row.billingDate,
  },
  {
    id: "action",
    label: "",
    getCell: () => {
      return (
        <Typography
          sx={{
            fontSize: "18px",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <DownloadIcon width={18} height={18} />
        </Typography>
      );
    },
  },
];

const data = [
  {
    name: "Invoice.0105.pdf",
    email: "example@gmail.com",
    amount: "$1,500.50",
    team: "Customer support",
    teamDsc: "Access to tickets and client data",
    dueDate: "2 June 2024",
    billingDate: "2 June 2024 11:05",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Invoice.0105.pdf",
    email: "example@gmail.com",
    amount: "$1,500.50",
    team: "Customer support",
    teamDsc: "Access to tickets and client data",
    dueDate: "2 June 2024",
    billingDate: "2 June 2024 11:05",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Invoice.0105.pdf",
    email: "example@gmail.com",
    amount: "$1,500.50",
    team: "Customer support",
    teamDsc: "Access to tickets and client data",
    dueDate: "2 June 2024",
    billingDate: "2 June 2024 11:05",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Invoice.0105.pdf",
    email: "example@gmail.com",
    amount: "$1,500.50",
    team: "Customer support",
    teamDsc: "Access to tickets and client data",
    dueDate: "2 June 2024",
    billingDate: "2 June 2024 11:05",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Invoice.0105.pdf",
    email: "example@gmail.com",
    amount: "$1,500.50",
    team: "Customer support",
    teamDsc: "Access to tickets and client data",
    dueDate: "2 June 2024",
    billingDate: "2 June 2024 11:05",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Invoice.0105.pdf",
    email: "example@gmail.com",
    amount: "$1,500.50",
    team: "Customer support",
    teamDsc: "Access to tickets and client data",
    dueDate: "2 June 2024",
    billingDate: "2 June 2024 11:05",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Invoice.0105.pdf",
    email: "example@gmail.com",
    amount: "$1,500.50",
    team: "Customer support",
    teamDsc: "Access to tickets and client data",
    dueDate: "2 June 2024",
    billingDate: "2 June 2024 11:05",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Invoice.0105.pdf",
    email: "example@gmail.com",
    amount: "$1,500.50",
    team: "Customer support",
    teamDsc: "Access to tickets and client data",
    dueDate: "2 June 2024",
    billingDate: "2 June 2024 11:05",
    online: true,
    image: "/images/person.avif",
  },
  {
    name: "Invoice.0105.pdf",
    email: "example@gmail.com",
    amount: "$1,500.50",
    team: "Customer support",
    teamDsc: "Access to tickets and client data",
    dueDate: "2 June 2024",
    billingDate: "2 June 2024 11:05",
    online: true,
    image: "/images/person.avif",
  },
];

const Billing = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [duration, setDuration] = useState("thisMonth");
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleChangePage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(headcells.map((cell) => cell.id));

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  // Paginate the sorted data
  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    Math.min(currentPage * itemsPerPage, sortedData.length)
  );
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  return (
    <Paper
      sx={{
        p: "24px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: isSmall ? "2px" : "24px",
          py: "10px",
          mb: "30px",
        }}
      >
        <Typography variant="h3">Billing</Typography>
        <Stack direction={"row"} gap={1}>
          <SmallSearchBar />
          <ExportSection />
        </Stack>
      </Box>

      <Stack
        direction={isSmall ? "column" : "row"}
        display={"flex"}
        gap={isSmall ? 1 : 2}
        alignItems={"center"}
      >
        <PaymentCard
          brandIcon={"/images/logos/visa.png"}
          cardNumber="3652"
          expiry="10/03"
          isPrimary={true}
          onMakePrimary={() => {}}
        />
        <PaymentCard
          brandIcon={"/images/logos/mastercard.png"}
          cardNumber="3652"
          expiry="10/03"
          isPrimary={false}
          onMakePrimary={() => {}}
        />
        <Typography
          variant="caption"
          fontSize={"24px"}
          m={"17px"}
          sx={{
            color: (theme) => theme.palette.color.secondary,
            cursor: "pointer",
          }}
        >
          +
        </Typography>
      </Stack>

      <Box sx={{ width: "100%" }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Invoices" {...a11yProps(0)} defaultChecked={"true"} />
          <Tab label="Billing details" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={activeTab} index={0}>
          <CustomTable
            headcells={columns.filter((cell) => heads.includes(cell.id))}
            rows={paginatedData}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            checkbox={true}
            selected={selected}
            setSelected={setSelected}
            fontSize="13px"
          />
          {totalPages > 0 && (
            <Stack direction="row" justifyContent="end" mt={3}>
              <IconButton
                onClick={() => handleChangePage(currentPage - 1)}
                disabled={currentPage === 1}
                sx={{ mx: 0.5, p: 1, borderRadius: "50%", color: "#8A96A6" }}
              >
                <ChevronLeft />
              </IconButton>
              {Array.from({ length: totalPages }, (_, index) => (
                <IconButton
                  key={index}
                  onClick={() => handleChangePage(index + 1)}
                  sx={{
                    mx: 1,
                    p: 1,
                    borderRadius: "50%",
                    width: "36px",
                    height: "36px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color:
                        currentPage === index + 1
                          ? (theme) => theme.palette.color.white
                          : (theme) => theme.palette.color.secondary,
                    }}
                  >
                    {index + 1}
                  </Typography>
                </IconButton>
              ))}
              <IconButton
                onClick={() => handleChangePage(currentPage + 1)}
                disabled={currentPage === totalPages}
                sx={{ mx: 0.5, p: 1, borderRadius: "50%", color: "#8A96A6" }}
              >
                <ChevronRight />
              </IconButton>
            </Stack>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={1}>
          <BillingDetail />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
};

export default Billing;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2EE0A1" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BillingDetail = () => {
  const [formValues, setFormValues] = useState({
    company_name: "",
    company_address: "",
    vat_number: "",
    invoice_memo: "",
  });
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { data } = useGetBillingDetailsQuery();
  const [updateBilling] = useUpdateBillingDetailsMutation();

  const handleSubmit = async () => {
    await updateBilling(formValues);
  };

  useEffect(() => {
    if (data) {
      setFormValues({
        company_name: data?.data?.company_name,
        company_address: data?.data?.company_address,
        vat_number: data?.data?.vat_number,
        invoice_memo: data?.data?.invoice_m,
      });
    }
  }, [data]);

  return (
    <Box
      sx={{
        p: isSmall ? 2 : 3,
        bgcolor: (theme) => theme.palette.color.bg2,
        borderRadius: "10px",
        pb: 10,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack direction="row" alignItems="center" spacing={2} width="100%">
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", fontWeight: "500", minWidth: "120px" }}
            >
              Company name
            </Typography>
            <Box flex={1}>
              <FormInput
                fullWidth
                placeholder="Company name"
                value={formValues.company_name}
                onChange={(e) =>
                  setFormValues({ ...formValues, company_name: e.target.value })
                }
                sx={{ bgcolor: (theme) => theme.palette.color.border }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" alignItems="center" spacing={2} width="100%">
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", fontWeight: "500", minWidth: "120px" }}
            >
              Vat number
            </Typography>
            <Box flex={1}>
              <FormInput
                fullWidth
                placeholder="VAT number"
                value={formValues.vat_number}
                onChange={(e) =>
                  setFormValues({ ...formValues, vat_number: e.target.value })
                }
                sx={{ bgcolor: (theme) => theme.palette.color.border }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" alignItems="center" spacing={2} width="100%">
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", fontWeight: "500", minWidth: "120px" }}
            >
              Address
            </Typography>
            <Box flex={1}>
              <FormInput
                fullWidth
                placeholder="Company address"
                value={formValues.company_address}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    company_address: e.target.value,
                  })
                }
                sx={{ bgcolor: (theme) => theme.palette.color.border }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction="row"
            alignItems="flex-start"
            spacing={2}
            width="100%"
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", fontWeight: "500", minWidth: "120px" }}
            >
              Invoice memo
            </Typography>
            <Box flex={1}>
              <FormInput
                fullWidth
                placeholder="Invoice memo"
                value={formValues.invoice_memo}
                onChange={(e) =>
                  setFormValues({ ...formValues, invoice_memo: e.target.value })
                }
                multiline
                rows={4}
                sx={{ bgcolor: (theme) => theme.palette.color.border }}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Box
        mt={4}
        p={2}
        bgcolor={(theme) => theme.palette.color.border}
        borderRadius="10px"
        // border="1px solid"
        // borderColor={(theme) => theme.palette.color.lightgray}
      >
        <Stack
          direction={isSmall ? "column" : "row"}
          justifyContent={"space-between"}
        >
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Receive email notifications for new invoices
            </Typography>
            <Typography variant="caption" gutterBottom>
              An invoice will be sent to the email address listed below.
            </Typography>
          </Box>
          <IOSSwitch sx={{ mt: 2 }} checked={"true"} onChange={() => {}} />
        </Stack>
        <Divider sx={{ mt: "24px", mb: "24px" }} />
        <Typography variant="subtitle1" gutterBottom>
          Recipient email
        </Typography>
        <Stack direction={"row"} gap={1}>
          <Typography variant="caption">jens@vuetra.com</Typography>
          <Typography variant="caption" sx={{ color: "#3F4752" }}>
            | Added by default
          </Typography>
        </Stack>
      </Box>

      <Button
        sx={{
          borderRadius: "18px",
          float: "right",
          mt: 3,
        }}
        onClick={handleSubmit}
      >
        Save Changes
      </Button>
    </Box>
  );
};
