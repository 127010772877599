import { Box, Paper, Stack, Typography, Divider } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../components/Firm/Orders/Table";
import { filterData } from "../../utils/filterByDate";
import ExportSection from "../../components/ui/ExportSection";
import SearchInput from "../../components/ui/NewSearch";
import dayjs from "dayjs";
import CalendarPopup from "../../components/ui/CalendarPopup";
import DisplayColumns from "../../components/ui/DisplayColumns";
import { useGetOrdersQuery } from "../../slices/api/firmSlice";

const statuses = [
  { label: "Complete", value: "completed", color: "green" },
  { label: "Failed", value: "failed", color: "red" },
  { label: "Pending", value: "Filled", color: "yellow" },
];

const headcells = [
  {
    id: "status",
    label: "Status",
    getCell: (row) => {
      const status = statuses.find((statute) => statute.value === row.status);
      return (
        <Box
          sx={{
            width: "9px",
            height: "9px",
            borderRadius: "50%",
            background: (theme) =>
              theme.palette.color[status?.color || "secondary"],
          }}
        ></Box>
      );
    },
    default: true,
  },
  {
    id: "id",
    label: "ID",
    getCell: (row) => row.id,
    default: true,
  },
  {
    id: "name",
    label: "Name",
    getCell: (row) => row.name,
    default: true,
  },
  {
    id: "customerId",
    label: "Customer ID",
    getCell: (row) => row.customer_id,
    default: true,
  },
  {
    id: "platform",
    label: "Platform",
    getCell: (row) => row.platform,
    default: true,
  },
  {
    id: "challenge",
    label: "Challenge",
    getCell: (row) => row.challenge_id,
    default: true,
  },
  {
    id: "size",
    label: "Size",
    getCell: (row) => row.size,
    default: true,
  },
  {
    id: "value",
    label: "Value",
    getCell: (row) => row.value,
    default: true,
  },
];

const Orders = () => {
  const [duration, setDuration] = useState("thisMonth");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const handleChangePage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [columns, setColumns] = useState(headcells);
  const [heads, setHeads] = React.useState(
    headcells.filter((cell) => cell.default).map((cell) => cell.id)
  );

  const { data } = useGetOrdersQuery({ tenant_id: 1 });

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const orders = data?.data || [];

  const sortedData = [...orders].sort((a, b) => {
    if (orderBy === "value") {
      const valueA = parseFloat(a[orderBy].replace(/[$,]/g, ""));
      const valueB = parseFloat(b[orderBy].replace(/[$,]/g, ""));
      return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else {
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
  });

  const filteredData = sortedData.filter((d) =>
    filterData(duration, d.createdAt)
  );
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    Math.min(currentPage * itemsPerPage, filteredData.length)
  );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Paper
      sx={{
        p: { xs: "12px 24px", sm: "24px" },
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: "12px",
          mt: { xs: "0px", sm: "24px" },
          mb: "16px",
          fontSize: "11.5px",
          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <Typography variant="h4">Orders</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <SearchInput />
          <ExportSection />
          <ExportSection isImport={true} />

          <CalendarPopup
            mainEndDate={endDate}
            mainStartDate={startDate}
            setMainEndDate={setEndDate}
            setMainStartDate={setStartDate}
          />
          <DisplayColumns
            columns={columns}
            setColumns={setColumns}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
        </Stack>
      </Box>
      <Divider />
      <CustomTable
        headcells={columns.filter((cell) => heads.includes(cell.id))}
        rows={paginatedData}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        fontSize="11.5px"
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        handleChangePage={handleChangePage}
        totalPages={totalPages}
        setItemsPerPage={setItemsPerPage}
      />
    </Paper>
  );
};

export default Orders;
