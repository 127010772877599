import React from 'react';
import { Grid, Card, Typography, Box, Stack, Tooltip } from '@mui/material';
import InfoFillIcon from '../../Icons/InfoFill';

const stats = [
  { title: 'Total active trading accounts', value: '1,200', tooltip: "Total active trading accounts", },
  { title: 'New Trading accounts', value: '120', tooltip: "New Trading accounts", },
  {
    title: 'Successful Accounts',
    value: (
      <Stack sx={{ flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}>
        <Stack>
          <Typography variant="body2" gutterBottom>Phase-1</Typography>
          <Typography variant="h2">500</Typography>
        </Stack>
        <Stack>
          <Typography variant="body2" gutterBottom>Phase-2</Typography>
          <Typography variant="h2">300</Typography>
        </Stack>
        <Stack>
          <Typography variant="body2" gutterBottom>Phase-3</Typography>
          <Typography variant="h2">100</Typography>
        </Stack>
      </Stack>
    ),
    tooltip: "Successful Accounts",
  },
];

const StatsCards = () => {
  return (
    <Grid container spacing={2}>
      {stats.map((stat, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              p: "24px",
              width: "100%",
              height: "154px",
              background: (theme) => theme.palette.color.bg5,
              borderRadius: "18px",
              borderColor: (theme) => theme.palette.color.strockborder,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={{ color: (theme) => theme.palette.color.secondary, fontSize: '14px', fontWeight: 400 }}>
                {stat.title}
              </Typography>
              {stat.tooltip && (
                <Tooltip title={stat.tooltip} placement="top">
                  <div>{<InfoFillIcon fill="none" />}</div>
                </Tooltip>
              )}
            </Stack>

            {index === 2 ? (
              <Box>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  {stat.value}
                </Typography>
              </Box>
            ) : (
              <Typography variant="h3" sx={{ fontWeight: 500 }}>
                {stat.value}
              </Typography>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatsCards;