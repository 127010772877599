import React from "react";

import { useGetArticlesQuery } from "../../../../slices/api/articleSlice";
import Articles from "../../../../components/Firm/Docs/Articles";

const PublicArticles = () => {
  const { data } = useGetArticlesQuery();

  return (
    <Articles
      rows={[...(data?.data?.data || [])].filter(
        (row) => row.visibility === "public"
      )}
    />
  );
};

export default PublicArticles;
